<script>
import pagination from '@/mixins/pagination';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import {mapActions, mapMutations, mapState, mapGetters} from 'vuex';
import {PdlDialog} from '@pedal/pdl-dialog';
import TrekButton from '@/components/TrekButton';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import CartProductGrid from '@/components/cart/CartProductGrid';
import SavedListsPanel from '@/components/saved-lists/SavedListsPanel';
import AppleLabelGrid from '@/components/checkout/AppleLabelGrid';
import PaginationControls from '@/components/PaginationControls.vue';
import FormGroup from '@/components/FormGroup';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlSwatch} from '@pedal/pdl-swatch';
import swatchMixin from '@/mixins/swatches.js';
import {formatAppleData} from '@/utils/product-info';
import {ProductImages} from '@/components/containers/pdp/utils/product-images';

export default {
  components: {
    TrekButton,
    PaginationControls,
    PdlDialog,
    PdlCollapse,
    PdlCollapseItem,
    PdlTabs,
    PdlTabPane,
    CartProductGrid,
    SavedListsPanel,
    AppleLabelGrid,
    FormGroup,
    PdlCallout,
    PdlSwatch,
  },
  mixins: [pagination, swatchMixin],

  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    partTypeKey: {
      type: String,
      default: '',
    },
    itemIdKey: {
      type: String,
      default: '',
    },
    cartSummary: {
      type: Object,
      default: () => null,
    },
    size: {
      type: Number,
      required: false,
      default: ACC.cartItemsPageSize || 15,
    },
  },

  data() {
    return {
      isDialogVisible: false,
      isAllChecked: false,
    };
  },

  computed: {
    ...mapState('backend', ['cartToggle', 'appleLabelAccess', 'isConsumerFriendlyMode']),
    ...mapGetters('cart', ['selectedProductsBikeCount', 'selectedProductsAccessoriesCount', 'selectedProducts']),

    isDisabled() {
      return (
        (this.partTypeKey === 'bikes' && this.selectedProductsBikeCount === 0) ||
        (this.partTypeKey === 'projectOneBikes' && this.selectedProductsBikeCount === 0) ||
        (this.partTypeKey !== 'bikes' &&
          this.partTypeKey !== 'projectOneBikes' &&
          this.selectedProductsAccessoriesCount === 0) ||
        false
      );
    },
    collapseRef() {
      return `collapse-${this.partTypeKey}`;
    },
    checkboxId() {
      return `cart-checkbox-check-all-${this.partTypeKey}`;
    },
    checkboxValue() {
      return `CheckAll${this.partTypeKey}`;
    },
    isCustomWaterBottle() {
      return this.partTypeKey === 'customWaterBottles';
    },
    isProjectOne() {
      return this.partTypeKey === 'projectOneBikes';
    },
  },
  watch: {
    selectedProducts() {
      this.isAllChecked = this.getEntries().every(
        (entry) => !!this.selectedProducts.find((product) => entry.cartEntryPk === product.cartEntryPk)
      );
    },
  },
  methods: {
    resetDeleteData() {
      this.isDialogVisible = false;
    },
    confirmDelete() {
      this.deleteSelectedProducts(this.partTypeKey);
      this.isDialogVisible = false;
    },
    getAppleLabelData(item) {
      if (!this.displayAppleLabels) return [];

      return formatAppleData(item.entries);
    },

    updateSavedList() {
      if (this.partTypeKey === 'bikes') {
        this.setEntries(this.selectedProducts.filter((product) => product.productType === 'bike'));
      } else {
        this.setEntries(this.selectedProducts.filter((product) => product.productType !== 'bike'));
      }
    },

    getProductQuantity(product) {
      return reduce(product.warehouses, (result, warehouse) => result + parseInt(warehouse.orderedQty), 0);
    },

    displayAppleLabels(partTypeKey) {
      return this.appleLabelAccess[partTypeKey] && !isEmpty(this.listData[0].entries[0].appleLabels);
    },

    getPartKeyQAID(name) {
      return `cart.${this.partTypeKey}.tab.${name}`;
    },

    getGroupDisplayImage(product) {
      const transformations = {
        fl: 'strip_profile',
        w: 160,
        h: 120,
        c: 'pad',
      };

      const image = product?.entries?.[0]?.variantImage;

      if (this.isProjectOne) {
        return image ? image : null;
      } else {
        return image ? ProductImages.getAssetUrl(image, transformations) : null;
      }
    },
    getGroupDisplayColor(product) {
      return product?.entries?.[0]?.variantValueCategories?.[0]?.Color?.code;
    },
    getColorProps(product) {
      const code = this.getGroupDisplayColor(product);

      return this.processColorCode(code);
    },

    getGroupDisplayColorName(product) {
      return product?.entries?.[0]?.variantValueCategories?.[0]?.Color?.name;
    },

    getGroupingVariant(product) {
      const variantValueCategory = product?.entries?.[0]?.variantValueCategories?.[0];
      return variantValueCategory ? variantValueCategory[product.groupBy] : null;
    },

    getCollapseItemName(partTypeKey, itemIdKey, index) {
      return `${partTypeKey}-${itemIdKey}-${index}`;
    },

    getProductGridKey(item, index) {
      return `grid-${this.partTypeKey}-${item.entries[0].cartEntryPk}-${index}`;
    },

    getEntries() {
      const entries = [];
      this.listData.forEach((product) => {
        product.entries.forEach((entry) => {
          entries.push(entry);
        });
      });
      return entries;
    },

    updatePage(page) {
      if (page !== this.pageNumber) {
        this.pageNumber = page;
      }

      this.$nextTick(() => {
        if (this.$refs[this.collapseRef] !== null) {
          this.$refs[this.collapseRef][0].setAllActive();
        }
      });
    },

    checkAllItems() {
      this.isAllChecked = !this.isAllChecked;
      if (this.isAllChecked) {
        this.getEntries().forEach((entry) => {
          this.addSelectedProduct({
            sku: entry.sku,
            qty: this.getProductQuantity(entry),
            productType: entry.productType,
            lineNote: entry.lineNote,
            productRecipeID: entry.productRecipeID,
            cartEntryPk: entry.cartEntryPk,
          });
        });
      } else {
        this.clearSelectedProducts(this.partTypeKey);
      }
    },

    ...mapMutations('savedLists', ['setEntries']),

    ...mapActions('cart', [
      'removeProducts',
      'undoRemove',
      'clearSelectedProducts',
      'updateCart',
      'deleteSelectedProducts',
      'addSelectedProduct',
    ]),
  },
};
</script>
