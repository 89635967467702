<template>
  <div id="g-recaptcha" class="g-recaptcha" data-size="invisible" :data-sitekey="reCaptchaSiteKey" />
</template>

<script>
import {ReCaptchaHelpers} from '@/utils/recaptcha-helpers';

const ReCaptchaEvent = Object.freeze({
  TOKEN_SUCCESS: 'tokenSuccess',
  TOKEN_FAILURE: 'tokenFailure',
});

export default {
  props: {
    reCaptchaSiteKey: {
      type: String,
      default: '',
    },
    isReCaptchaToggleEnabled: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async execute() {
      const reCaptchaHelper = ReCaptchaHelpers();
      if (!reCaptchaHelper.isReCaptchaAvailable(this.isReCaptchaToggleEnabled)) {
        return this.$emit(ReCaptchaEvent.TOKEN_SUCCESS, null);
      }
      try {
        const token = await reCaptchaHelper.getReCaptchaToken(this.reCaptchaSiteKey);
        this.$emit(ReCaptchaEvent.TOKEN_SUCCESS, token);
      } catch (error) {
        this.$emit(ReCaptchaEvent.TOKEN_FAILURE, error);
      }
    },
  },
};
</script>
