<template>
  <pdl-drawer size="23rem" :is-open="isDrawerOpen" show-close @close="handleClose">
    <template #content>
      <div class="space-y-8">
        <pdl-heading size="md" qaid="address-drawer-header">{{ $t('fulfillment.shipToHome.zip.change') }}</pdl-heading>
        <form class="space-y-2" @submit.prevent>
          <label for="address-search" class="font-medium flex items-center" qaid="address-drawer-ship-to-text">
            {{ $t('fulfillment.shipToHome.location') }}
          </label>
          <div class="form-group--inline gap-x-4">
            <form-group
              id="address-search"
              v-model="input"
              v-mask
              :data-mask="postcodeMask"
              :show-error-messages="true"
              :force-display-error="true"
              :class="{'has-error': Boolean(fieldError)}"
              :maxlength="10"
              name="address-search"
              :placeholder="$t('address.zipcode')"
              qaid="address-drawer-input"
              @input="clearError"
              @keyup="handleKeyup"
              @keydown="handleKeydown"
            />
            <pdl-button
              primary
              small
              class="whitespace-nowrap button--inline"
              qaid="address-drawer-update-button"
              @click="handleAddressSelected"
            >
              {{ $t('fulfillment.shipToHome.zip.update') }}
            </pdl-button>
          </div>
          <span v-show="fieldError" class="text-red" qaid="address-drawer-error">{{ fieldError }}</span>
        </form>
      </div>
    </template>
  </pdl-drawer>
</template>

<script>
import {PdlDrawer} from '@pedal/pdl-drawer';
import {PdlHeading} from '@pedal/pdl-section-header';
import {PdlButton} from '@pedal/pdl-button';
import FormGroup from '@/components/FormGroup.vue';
import {getPostcodeFormatOptions} from '@/constants/postcode-fields-config';
import {mapGetters, mapMutations, mapState} from 'vuex';
import {mask} from '@/directives/masks';
import {getValidLocationFromPostcode} from '@/components/mapbox/utils/mapbox-api';

export default {
  components: {FormGroup, PdlDrawer, PdlButton, PdlHeading},
  directives: {mask},
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      input: '',
      fieldError: undefined,
    };
  },
  computed: {
    ...mapState('backend', ['mapboxApiKey', 'locale']),
    ...mapGetters('backend', ['countryCode']),
    postcodeMask() {
      return getPostcodeFormatOptions(this.locale);
    },
  },
  methods: {
    ...mapMutations('user', ['setSelectedLocation']),
    async handleAddressSelected() {
      const payload = {postalCode: this.input, accessToken: this.mapboxApiKey, country: this.countryCode};
      try {
        const location = await getValidLocationFromPostcode(payload);
        if (this.normalisePostalCode(this.input) !== this.normalisePostalCode(location.postcode))
          return this.displayError();

        this.setSelectedLocation(location);
        this.handleClose();
      } catch (error) {
        this.displayError();
      }
    },
    displayError() {
      this.fieldError = this.$t('address.postcode.invalid');
    },
    clearError() {
      this.fieldError = undefined;
    },
    handleKeyup(event) {
      if (event.key !== 'Enter') return;
      this.handleAddressSelected();
    },
    handleKeydown(event) {
      event.stopImmediatePropagation();
    },
    handleClose() {
      this.$emit('close');
    },
    normalisePostalCode(postcode) {
      return postcode.replace(/\s/g, '').toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.pdl-drawer {
  z-index: 16000009;
}

.form-group {
  margin-bottom: 0;
}
</style>
