<script>
import {PdlCallout} from '@pedal/pdl-callout';
import CartDetails from '@/components/cart/b2c/CartDetails';
import CartItem from '@/components/cart/b2c/CartItem';
import CartFooter from '@/components/cart/footer';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {PdlIcon} from '@pedal/pdl-icon';
import {PdlHeading} from '@pedal/pdl-section-header';
import {mapState} from 'vuex';
import {useApplePaySdk} from '@/composables/apple-pay-sdk.js';
import CartRestoration from '@/components/cart/header/CartRestoration';
import GlobalMessages from '@/components/GlobalMessages.vue';

export default {
  name: 'CartContainer',
  components: {
    CartDetails,
    CartItem,
    CartRestoration,
    PdlCallout,
    PdlCollapse,
    PdlCollapseItem,
    CartFooter,
    PdlIcon,
    PdlHeading,
    GlobalMessages,
  },
  setup() {
    useApplePaySdk();
  },
  computed: {
    ...mapState('backend', ['isUserLoggedIn']),
  },
};
</script>
