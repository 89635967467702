var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"mb-4 border-t border-gray-10"},_vm._l((_vm.items),function(item,itemIndex){return _c('li',{key:`item-${itemIndex}`,staticClass:"border-b border-gray-10 my-2 pb-2"},[_c('div',{staticClass:"flex"},[_c('label',{staticClass:"checkbox"},[_c('input',{attrs:{"id":`item-${itemIndex}`,"type":"checkbox"},domProps:{"checked":item.active}}),_vm._v(" "),_c('span',{staticClass:"control-indicator"})]),_vm._v(" "),_c('div',{staticClass:"notification__button mr-1 mt-25"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!item.read),expression:"!item.read"},{name:"tippy",rawName:"v-tippy",value:({
            theme: 'light',
            interactive: true,
            trigger: 'mouseenter focus',
            reactive: true,
          }),expression:"{\n            theme: 'light',\n            interactive: true,\n            trigger: 'mouseenter focus',\n            reactive: true,\n          }"}],staticClass:"notification__hitbox",attrs:{"title":"Mark as read"},on:{"click":function($event){item.read = true}}},[_c('span',{staticClass:"notification__indicator"})])]),_vm._v(" "),(_vm.image)?_c('div',{staticClass:"flex w-full"},[_vm._m(0,true),_vm._v(" "),_c('div',{staticClass:"flex-auto",class:{'font-bold': !item.read}},[_vm._v("\n          "+_vm._s(item.text)+"\n        ")])]):_c('div',{class:{'font-bold': !item.read}},[_vm._v("\n        "+_vm._s(item.text)+"\n      ")])])])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sm:hidden md:block flex-none md:w-1/5 lg:w-1/6 mr-2"},[_c('a',{staticClass:"block",attrs:{"href":""}},[_c('img',{staticClass:"w-full",attrs:{"src":"https://media.trekbikes.com/image/upload/w_400,h_300,f_auto,q_auto,c_pad/Trek_logo_B2B_news","alt":""}})])])
}]

export { render, staticRenderFns }