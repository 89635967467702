<template>
  <div v-if="canShow">
    <div :id="`${tooltipType}-tooltip-${typeId}-${rowId}`" v-tippy-html>
      {{ $t(`backordersAllocated.B2B.${tooltipType}`) }}
    </div>
    <span>
      <pdl-icon
        v-if="isGreenShipping"
        v-tippy="tippyOptions"
        class="cursor-pointer text-green"
        :qaid="`green-shipping-icon-${typeId}-${rowId}`"
        name="leaf"
        size="18"
      >
      </pdl-icon>
      <pdl-icon
        v-else
        v-tippy="tippyOptions"
        class="cursor-pointer"
        :qaid="`not-shippable-icon-${typeId}-${rowId}`"
        name="block"
        size="18"
      />
    </span>
  </div>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {
    PdlIcon,
  },
  props: {
    typeId: {
      type: String,
      required: true,
    },
    rowId: {
      type: String,
      default: undefined,
    },
    isGreenShipping: {
      type: Boolean,
      required: true,
    },
    isAllowedToShip: {
      type: Boolean,
      required: true,
    },
    quantityAllocatedOrShipped: {
      type: Number,
      required: true,
    },
  },
  computed: {
    canShow() {
      return this.isGreenShipping || (!this.isAllowedToShip && this.quantityAllocatedOrShipped > 0);
    },
    tooltipType() {
      return this.isGreenShipping ? 'greenShipping' : 'notShippable';
    },
    tippyOptions() {
      return {
        html: `#${this.tooltipType}-tooltip-${this.typeId}-${this.rowId}`,
        interactive: true,
        reactive: true,
        trigger: 'mouseenter focus',
        flip: true,
        sticky: true,
      };
    },
  },
};
</script>
