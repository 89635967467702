<template>
  <div>
    <!-- Loading Overlay -->
    <pdl-loading top :is-loading="loading">
      <table class="b2b-grid b2b-grid--collapse is-compact" qaid="businessUnitDetails.grid">
        <thead class="b2b-grid__header">
          <tr>
            <th class="b2b-grid__cell b2b-grid__head" qaid="businessUnitDetails.grid.header.storeName">
              {{ getBaseProperty('storeName') }}
            </th>
            <th class="b2b-grid__cell b2b-grid__head" qaid="businessUnitDetails.grid.header.address">
              {{ getBaseProperty('address') }}
            </th>
            <th class="b2b-grid__cell b2b-grid__head" qaid="businessUnitDetails.grid.header.townCity">
              {{ getBaseProperty('townCity') }}
            </th>
            <th class="b2b-grid__cell b2b-grid__head" qaid="businessUnitDetails.grid.header.state">
              {{ getBaseProperty('state') }}
            </th>
            <th class="b2b-grid__cell b2b-grid__head" qaid="businessUnitDetails.grid.header.country">
              {{ getBaseProperty('country') }}
            </th>
            <th class="b2b-grid__cell b2b-grid__head" qaid="businessUnitDetails.grid.header.empty"></th>
          </tr>
        </thead>
        <tbody>
          <!-- Each Row -->
          <template v-for="(store, rowIndex) in stores">
            <business-unit-details-table-item
              :key="rowIndex"
              :store="store"
              :base-properties="baseProperties"
              :view-page-url="viewPageUrl"
              @delete-store="$emit('delete-store', $event)"
            />
          </template>
        </tbody>
      </table>

      <div
        v-if="!stores || stores.length < 1"
        class="flex justify-center text-secondary text-md my-5"
        qaid="businessUnitDetails.noLocationsFound"
      >
        {{ bp('noLocationsSelected') }}
      </div>
    </pdl-loading>
  </div>
</template>

<script>
import {PdlLoading} from '@pedal/pdl-loading';

export default {
  components: {
    PdlLoading,
  },
  props: {
    baseProperties: {
      type: Array,
      default() {
        return [];
      },
    },
    stores: {
      type: Array,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    viewPageUrl: {
      type: String,
      default: undefined,
    },
  },
};
</script>
