<template>
  <pdl-icon size="48">
    <svg id="master-card-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 800 540">
      <rect width="789" height="529" x="5.5" y="5.5" fill="#fff" stroke="#D9D9D9" stroke-width="11" rx="54.5" />
      <path fill="#FF5F00" d="M471.313 397.331H328.539v-254.68h142.774v254.68Z" />
      <path
        fill="#EB001B"
        d="M337.687 269.971c0-51.663 24.37-97.684 62.321-127.34C372.255 120.944 337.23 108 299.164 108 209.05 108 136 180.516 136 269.971c0 89.454 73.05 161.97 163.164 161.97 38.066 0 73.091-12.943 100.844-34.63-37.951-29.657-62.321-75.678-62.321-127.34Z"
      />
      <path
        fill="#F79E1B"
        d="M663.87 269.971c0 89.454-73.049 161.97-163.164 161.97-38.066 0-73.091-12.943-100.854-34.63 37.962-29.657 62.331-75.678 62.331-127.34 0-51.663-24.369-97.684-62.331-127.34C427.615 120.944 462.64 108 500.706 108c90.115 0 163.164 72.516 163.164 161.971Z"
      />
    </svg>
  </pdl-icon>
</template>
<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {PdlIcon},
};
</script>
