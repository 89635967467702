import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';

function removeDuplicateProducts(bundles) {
  let lookUpTable = {};

  // Get all the base products from bundles
  let allBaseProducts = flatten(bundles.map((bundle) => bundle.baseProducts));

  // Get all the codes for all base products
  const allBaseProductCodes = allBaseProducts.map((product) => product.code);

  // Get all the duplicate codes. This will allways be (total occurances-1)
  const duplicateCodes = allBaseProductCodes.filter((item, index) => allBaseProductCodes.indexOf(item) !== index);

  // if we have duplicate code, build up a 'lookUpTable' with products code and variant code.
  if (duplicateCodes.length > 0) {
    duplicateCodes.forEach((code) => {
      // get all the products with duplicate code
      const duplicateProducts = allBaseProducts.filter((product) => product.code === code);

      // Take out all the variants out of them
      const allVariants = flatten(duplicateProducts.map((product) => product.variants));

      // Get all the variants codes
      const allVariantCodes = allVariants.map((variant) => variant.code);

      // Get all the duplicates. This will allways be (total occurances-1)
      const duplicateVariantCode = allVariantCodes.filter((item, index) => allVariantCodes.indexOf(item) !== index);

      // Builing up the lookUpTable
      lookUpTable[code] = duplicateVariantCode;
    });
  }

  // If we don't have duplicate code, 'lookUpTable' would be empty.
  if (!isEmpty(lookUpTable)) {
    Object.entries(lookUpTable).forEach((entry) => {
      const positionArray = [];

      // Get an itrator on 'allBaseProducts'
      const productIterator = allBaseProducts.filter((product) => product.code === entry[0]).entries();

      // Get all the indexes as position representing all the duplicate product are present in 'allBaseProducts'
      allBaseProducts.forEach((product, index) => product.code === entry[0] && positionArray.push(index));

      // Running for (total occurances-1)
      entry[1].forEach((code, index) => {
        const [, nextProduct] = productIterator.next().value;

        // get all other variants except the duplicate one.
        const filteredVariants = nextProduct.variants.filter((variant) => variant.code !== code);

        // Construct a new base product withiout duplicate variant
        const newProduct = {...nextProduct, variants: filteredVariants};

        // Replace the orignal base product with the constructed one. We dont want to fiddle with other properties like categorizationHierarchyValues
        allBaseProducts.splice(positionArray[index], 1, newProduct);
      });
    });
  }

  return allBaseProducts;
}

export default removeDuplicateProducts;
