<template>
  <div class="max-w-xs mb-6">
    <div class="savedList__name flex items-center mt-3">
      <div class="form-group grow mb-0">
        <input
          id="input-no-label"
          ref="nameInput"
          type="text"
          qaid="edit-list.input"
          maxlength="40"
          aria-label="List name"
          :value="list.name"
          class="form-control mb-0 border-none shadow-none"
          :disabled="!isEditing"
        />
      </div>
      <div class="inline-flex mb-0 ml-1">
        <trek-button
          v-if="!list.readOnly && !isEditing"
          tertiary
          icon-only
          form-inline
          icon="edit"
          aria-label="Edit list name"
          qaid="edit-list.button"
          @click="isEditing = true"
        >
        </trek-button>
        <trek-button
          v-if="isEditing"
          class="mr-1"
          success
          icon-only
          form-inline
          icon="done"
          aria-label="Save list name"
          qaid="save-list-name-button"
          @click="saveHandler"
        >
        </trek-button>
        <trek-button
          v-if="isEditing"
          alert
          icon-only
          form-inline
          icon="close"
          qaid="cancel-save-name-button"
          :aria-label="cancelListName"
          @click="cancelHandler"
        >
        </trek-button>
      </div>
    </div>
    <p class="mt-1 text-md text-gray-30" qaid="list-created-by">{{ createdByLabel }}: {{ savedBy }}</p>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import TrekButton from '@/components/TrekButton';

export default {
  components: {TrekButton},
  props: {
    list: {
      type: Object,
      default: () => null,
    },
    createdByTranslation: {
      type: String,
      default: 'Created by',
    },
    cancelListName: {
      type: String,
      default: 'Cancel',
    },
  },

  data() {
    return {
      isEditing: false,
      createdByLabel: this.createdByTranslation || 'Created by',
    };
  },

  computed: {
    savedBy() {
      return this.list.savedBy || '';
    },
  },

  methods: {
    ...mapActions('savedLists', ['updateListName']),

    saveHandler() {
      const name = this.$refs.nameInput.value;
      const {id} = this.list;

      if (name?.length) {
        this.updateListName({id, name}).then(() => {
          this.isEditing = false;
        });
      }
    },

    cancelHandler() {
      this.isEditing = false;
    },
  },
};
</script>
