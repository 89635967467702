<template>
  <div v-if="isVisible" class="bg-gray-02 cell mb-2 px-2 py-3 md:p-3 lg:p-4 xl:px-12 xl:py-6" :class="layoutClasses">
    <div class="flex lg:inline-flex flex-col lg:flex-row gap-2 mb-1 lg:mb-2 items-start">
      <bike-stand-icon />
      <pdl-heading qaid="product-detail-mechanic-notes" level="2" class="whitespace-nowrap" :size="headerSize">{{
        $t('product.detail.mechanicsNotes')
      }}</pdl-heading>
    </div>
    <ul class="list-disc ml-2 gap-x-8" :class="columnClass">
      <li
        v-for="(note, index) of mechanicNotes"
        :key="`mechanic-note-${index}`"
        :qaid="`mechanic-note-${index}`"
        class="break-inside-avoid"
      >
        {{ note }}
      </li>
    </ul>
  </div>
</template>

<script>
import BikeStandIcon from '@/icons/BikeStandIcon';
import {PdlHeading} from '@pedal/pdl-section-header';
import {mapGetters, mapState} from 'vuex';
import {ViewSize} from '@/constants/view-breakpoints';

export default {
  components: {
    PdlHeading,
    BikeStandIcon,
  },
  computed: {
    ...mapState('pdp', ['mechanicNotes']),
    ...mapGetters('viewSize', ['viewBreakpointMoreThan']),
    headerSize() {
      return this.viewBreakpointMoreThan(ViewSize.MEDIUM) ? 'lg' : 'md';
    },
    layoutClasses() {
      return this.mechanicNotes.length <= 3 && 'lg:flex lg:justify-center lg:gap-20';
    },
    columnClass() {
      return this.mechanicNotes.length > 3 && this.viewBreakpointMoreThan(ViewSize.MEDIUM) && 'columns-2';
    },
    isVisible() {
      return this.mechanicNotes.length;
    },
  },
};
</script>

<style lang="scss" scoped>
//Shims for missing tailwind classes - can be removed after upgrade to tailwind v3
.columns-2 {
  column-count: 2;
}

.break-inside-avoid {
  break-inside: avoid-column;
}
</style>
