<template>
  <div>
    <pdl-section-header size="lg" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1" qaid="registration-form-page-title">{{
          $t('productRegistration.B2B.completeRegistration')
        }}</pdl-heading>
      </template>
    </pdl-section-header>
    <p class="mb-3" qaid="registration-form-page-description">{{ $t('productRegistration.B2B.customerDetails') }}</p>
    <div class="grid-x grid-margin-x">
      <div class="cell medium-6">
        <form
          id="product-registration-form"
          ref="productRegistrationForm"
          :action="formActionUrl"
          method="post"
          qaid="registration-form"
          @submit.prevent="validateAndSubmit"
        >
          <fieldset class="max-w-sm">
            <input type="hidden" name="ignoreRedirectUrl" :value="ignoreRedirectUrl" />
            <input type="hidden" name="ignoreRedirectMode" value="Auto" />
            <input
              v-if="b2b"
              :value="currentBikeShop"
              name="trekRetailer"
              type="hidden"
              qaid="registration-form-current-bike-shop"
            />

            <input name="sku" type="hidden" qaid="registration-form-selected-bike-sku" :value="selectedBike.sku" />

            <div id="purchase-date-field" class="form-group" :class="datePickerContainerClass">
              <label class="font-medium" for="purchase-date-input">{{ $t('contactUS.purchaseDate') }}</label>
              <pdl-date-picker
                id="purchase-date-input"
                ref="purchaseDatePicker"
                class="mb-0"
                qaid="purchase-date-input"
                name="purchaseDate"
                :allow-input="true"
                @change="setDatePicker"
                @blur="checkDatePicker"
              />
              <div v-if="datePickerContainerClass === 'has-error'" class="form-feedback is-invalid">
                The {{ $t('contactUS.purchaseDate') }} field is required.
              </div>
            </div>

            <form-group
              id="serial_number"
              v-model="serialNumberField"
              type="text"
              :error="getErrorMessage('serialNumberField')"
              name="serialNumber"
              :label="$t('productRegistration.B2B.serialNumber')"
              qaid="registration-form-serial"
              required
              force-display-error
              show-error-messages
            />

            <form-group
              id="bike_description"
              v-model="bikeDescription"
              type="text"
              name="bikeDescription"
              :label="$t('contactUs.model')"
              :error="getErrorMessage('bikeDescription')"
              qaid="registration-form-model"
              required
              force-display-error
              show-error-messages
            />

            <form-group
              id="first_name"
              v-model="firstNameField"
              type="text"
              :error="getErrorMessage('firstNameField')"
              name="firstName"
              :label="$t('address.firstName')"
              qaid="registration-form-first-name"
              force-display-error
              show-error-messages
              required
            />

            <form-group
              id="last_name"
              v-model="lastNameField"
              type="text"
              :error="getErrorMessage('lastNameField')"
              name="lastName"
              :label="$t('address.surname')"
              show-error-messages
              force-display-error
              required
              qaid="registration-form-surname"
            />

            <form-group
              id="email"
              v-model="emailAddressField"
              type="email"
              :error="getErrorMessage('emailAddressField')"
              name="email"
              :label="$t('text.address.email')"
              show-error-messages
              force-display-error
              required
              qaid="registration-form-email"
            />

            <form-group
              id="address"
              v-model="addressLine1Field"
              type="text"
              :error="getErrorMessage('addressLine1Field')"
              name="address"
              :label="$t('productRegistration.B2B.addressLine1')"
              show-error-messages
              force-display-error
              required
              qaid="registration-form-address"
            />

            <form-group
              id="address2"
              v-model="addressLine2Field"
              type="text"
              name="address2"
              :label="$t('productRegistration.B2B.addressLine2')"
              qaid="registration-form-address2"
            />

            <form-group
              id="city"
              v-model="townCityField"
              type="text"
              name="city"
              :label="$t('address.townCity')"
              :error="getErrorMessage('townCityField')"
              show-error-messages
              force-display-error
              required
              qaid="registration-form-city"
            />

            <form-group
              id="state"
              v-model="stateField"
              type="text"
              name="state"
              :label="$t('address.selectState')"
              :error="getErrorMessage('stateField')"
              qaid="registration-form-state"
              show-error-messages
              force-display-error
              required
            />

            <label for="select" class="form-label">{{ $t('address.country') }}</label>
            <div class="select mb-3">
              <select
                id="country"
                v-model="countryField"
                name="country"
                qaid="registration-form-tech-country-drop-down"
                class="form-control"
                :class="countriesContainerClass"
                :error="getErrorMessage('countryField')"
                show-error-messages
                force-display-error
                required
                @blur="checkCountryField"
              >
                <option :disabled="true" :value="null">{{ $t('form.select.empty') }}</option>
                <option v-for="(country, index) in countriesList" :key="index" :value="country">
                  {{ country }}
                </option>
              </select>
            </div>

            <form-group
              id="zip"
              v-model="postalCodeField"
              type="text"
              :error="getErrorMessage('postalCodeField')"
              name="zip"
              :label="$t('address.postalcode')"
              qaid="registration-form-postal"
              show-error-messages
              force-display-error
              required
            />

            <form-group
              v-if="!b2b"
              id="trekRetailer"
              v-model="trekRetailerField"
              type="text"
              :error="getErrorMessage('trekRetailerField')"
              name="trekRetailer"
              :label="$t('productRegistration.trekRetailer')"
              qaid="registration-form-retailer"
              show-error-messages
              force-display-error
              required
            />

            <fieldset class="form-fieldset">
              <label for="opt_in" class="checkbox">
                <input
                  id="opt_in"
                  v-model="optInVal"
                  type="checkbox"
                  true-value="true"
                  false-value="false"
                  name="optIn"
                />
                <input id="optInValue" type="hidden" name="optInVal" :value="optInVal" />
                <span class="control-indicator" qaid="registration-form-optin"></span>
                <span>{{ $t('contactUs.emailOptIn') }}</span>
              </label>
              <form-group
                v-if="!b2b"
                id="privacy_policy"
                type="checkbox"
                name="hasAcceptedPrivacyPolicy"
                :multi-items="[
                  {
                    name: 'hasAcceptedPrivacyPolicy',
                    displayName: privacyPolicyText,
                    value: hasAcceptedPrivacyPolicy,
                    linkText: `${encodedContextPath}/company/legal_policies/privacy_policy_terms_of_use/`,
                  },
                ]"
                qaid="registration-form-privacy-accept"
                @change="handlePrivacyPolicyChecked"
              />
            </fieldset>
          </fieldset>
          <div class="buttons py-5">
            <trek-button
              primary
              qaid="registration-form-submit"
              :disabled="!isRegistrationEnabled"
              @click="executeRecaptcha()"
            >
              {{ $t('productRegistration.B2B.registerBike') }}
            </trek-button>
            <trek-link button secondary href="/" qaid="registration-form-cancel">
              {{ $t('text.cancel') }}
            </trek-link>
          </div>
          <trek-recaptcha
            ref="recaptcha"
            :re-captcha-site-key="reCaptchaSiteKey"
            :is-re-captcha-toggle-enabled="isReCaptchaToggleEnabled"
            @tokenSuccess="validateAndSubmit"
            @tokenFailure="handleTokenFailure"
          ></trek-recaptcha>
          <input id="reCaptchaToken" type="hidden" name="reCaptchaToken" :value="reCaptchaToken" />
        </form>
      </div>
      <div class="cell sm:hidden md:block medium-6">
        <img :src="selectedBike.imageUrl" qaid="registration-form-image" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from '@/components/FormGroup';
import {PdlDatePicker} from '@pedal/pdl-date-picker';
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';
import {mapState} from 'vuex';
import TrekRecaptcha from '@/components/TrekRecaptcha';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlToastType} from '@/constants/pdl-toast-type';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  components: {
    TrekButton,
    TrekLink,
    FormGroup,
    PdlDatePicker,
    TrekRecaptcha,
    PdlSectionHeader,
    PdlHeading,
  },
  mixins: [TrekValidationMixin],
  props: {
    selectedBike: {
      type: Object,
      default: () => null,
    },
    currentBikeShop: {
      type: String,
      default: '',
    },
    selectedBikeJson: {
      type: String,
      default: '',
    },
    countries: {
      type: Array,
      default: () => [],
    },
    contextPath: {
      type: String,
      default: '',
    },
    formActionUrl: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    reCaptchaSiteKey: {
      type: String,
      default: '',
    },
    reCaptchaTokenToggle: {
      type: String,
      default: '',
    },
    privacyPolicyText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      serialNumberField: this.selectedBike.serialNumber,
      bikeDescription: this.selectedBike.description,
      shopNameField: '',
      firstNameField: '',
      lastNameField: '',
      emailAddressField: '',
      phoneNumberField: '',
      addressLine1Field: '',
      addressLine2Field: '',
      townCityField: '',
      stateField: '',
      countryField: '',
      countriesList: [],
      purchaseDate: '',
      postalCodeField: '',
      trekRetailerField: '',
      datePickerContainerClass: '',
      countriesContainerClass: '',
      originLocation: window.location.origin,
      optInVal: 'false',
      hasAcceptedPrivacyPolicy: false,
      hasRecaptchaFailure: false,
      isRecaptchaEnabled: false,
      reCaptchaToken: '',
    };
  },
  validations() {
    const validations = {
      serialNumberField: {
        required: this.trekValidators.requiredWithCustomMessage('productRegistration.serialNumber.error'),
      },
      bikeDescription: {
        required: this.trekValidators.requiredWithCustomMessage('productRegistration.enterModel'),
      },
      firstNameField: {
        required: this.trekValidators.requiredWithCustomMessage('productRegistration.firstName.error'),
      },
      lastNameField: {
        required: this.trekValidators.requiredWithCustomMessage('productRegistration.lastName.error'),
      },
      emailAddressField: {
        required: this.trekValidators.requiredWithCustomMessage('productRegistration.email.error'),
        email: this.trekValidators.email('forgottenPwd.email'),
      },
      addressLine1Field: {
        required: this.trekValidators.requiredWithCustomMessage('productRegistration.B2B.enterAddress'),
      },
      townCityField: {
        required: this.trekValidators.requiredWithCustomMessage('address.townCity.invalid'),
      },
      stateField: {
        required: this.trekValidators.requiredWithCustomMessage('address.state.invalid'),
      },
      postalCodeField: {
        required: this.trekValidators.requiredWithCustomMessage('productRegistration.B2B.enterZip'),
      },
    };
    const applyB2cValidations = () => {
      validations.trekRetailerField = {
        required: this.trekValidators.requiredWithCustomMessage('productRegistration.trekRetailer.error'),
      };
    };

    if (!this.b2b) applyB2cValidations();

    return validations;
  },
  computed: {
    ...mapState('backend', ['b2b', 'encodedContextPath']),
    ignoreRedirectUrl() {
      return `${this.originLocation}${this.contextPath}/${
        this.b2b ? 'bike-registration' : 'productRegistration'
      }/confirmation`;
    },
    isRegistrationEnabled() {
      if (this.b2b) return !this.hasRecaptchaFailure;
      return this.hasAcceptedPrivacyPolicy && !this.hasRecaptchaFailure;
    },
    isReCaptchaToggleEnabled() {
      return this.reCaptchaTokenToggle === 'true';
    },
  },
  mounted() {
    // populate countries data when base properties are available (they return undefined if set in data())
    this.countryField = null;
    this.countriesList = this.countries;
  },
  methods: {
    setDatePicker({event}) {
      if (event) this.purchaseDate = event;
      this.datePickerContainerClass = '';
    },
    checkDatePicker() {
      if (this.purchaseDate === '') {
        this.datePickerContainerClass = 'has-error';
        return false;
      } else {
        this.datePickerContainerClass = '';
        return true;
      }
    },
    checkCountryField() {
      this.countriesContainerClass = this.countryField ? '' : 'has-error';
      return Boolean(this.countryField);
    },
    executeRecaptcha() {
      this.$refs.recaptcha.execute();
    },
    handleTokenFailure(error) {
      this.$notify({
        type: PdlToastType.ERROR,
        message: this.$t('errorMessage.unableToProcess'),
      });
      this.hasRecaptchaFailure = true;
      console.error('recaptcha failed', error);
    },
    async validateAndSubmit(token) {
      if (token) this.reCaptchaToken = token;
      const validationResult = await this.v$.$validate();
      const isCountryFieldValid = this.checkCountryField();
      const isDateFieldValid = this.checkDatePicker();
      const isFormValid = validationResult && isCountryFieldValid && isDateFieldValid;
      if (!isFormValid) return;
      this.$refs.productRegistrationForm.submit();
    },
    handlePrivacyPolicyChecked(event) {
      this.hasAcceptedPrivacyPolicy = event.target.checked;
    },
  },
};
</script>
