<template>
  <div>
    <pdl-section-header size="xl" :is-dividing="true">
      <template slot="content">
        <pdl-heading :level="1">{{ pageTitle }}</pdl-heading>
      </template>
      <template slot="actions">
        <div class="buttons buttons--right-for-md">
          <trek-button secondary small icon="get_app" qaid="download-order-button" @click="downloadOrderDetails">
            {{ $t('text.downloadOrder') }}
          </trek-button>
          <trek-button
            secondary
            small
            icon="get_app"
            qaid="download-order-ascend-button"
            @click="downloadOrderDetailsAscend"
          >
            {{ $t('orderDownload.B2B.ascend') }}
          </trek-button>
        </div>
      </template>
    </pdl-section-header>

    <div v-if="!loading" class="grid-x grid-margin-x">
      <div class="cell medium-6 large-4 small-order-2 medium-order-1">
        <account-order-details-info :order-data="orderData" />
      </div>
      <div v-if="!isConsumerFriendlyMode" class="cell medium-6 large-4 large-offset-4 small-order-1 medium-order-2">
        <account-order-details-summary :order-data="orderData" />
      </div>
    </div>

    <pdl-tabs id="order-tabs" class="mt-5 text-base">
      <template v-for="tab in tabs">
        <pdl-tab-pane v-if="orderData[tab.key]" :key="tab.id">
          <h3 slot="label" class="heading heading--md">{{ tab.label }}</h3>

          <pdl-collapse icon-active="indeterminate_check_box" icon-inactive="add_box" qaid="order-details-collapse">
            <template v-for="shipment in Object.keys(orderData[tab.key])">
              <pdl-collapse-item
                v-if="
                  shipment != productDeliveryStatus.NOT_SHIPPED &&
                  shipment != productDeliveryStatus.BACKORDERED &&
                  shipment != productDeliveryStatus.ALLOCATED
                "
                :key="shipment"
                :name="shipment"
              >
                <div slot="title" class="heading heading--sm">{{ shipment }}</div>
                <account-order-details-shipment
                  :shipment="orderData[tab.key][shipment]"
                  :order-data="orderData"
                  :product-type="tab.key"
                  :table-data="orderData[tab.key][shipment]"
                  status="shipped"
                  :table-headers="tableHeaders"
                >
                  <div>
                    <account-order-details-shipment-info
                      :shipment="orderData[tab.key][shipment]"
                      :name="shipment"
                      :is-credit-memo="orderData.details.isCreditMemo"
                      :order-data="orderData"
                    />
                    <account-order-details-shipment-grid
                      :order-data="orderData"
                      :table-headers="tableHeaders"
                      :table-data="orderData[tab.key][shipment]"
                      :shipment="shipment"
                    />
                  </div>
                </account-order-details-shipment>
              </pdl-collapse-item>
            </template>

            <pdl-collapse-item
              v-if="orderData[tab.key][productDeliveryStatus.NOT_SHIPPED]"
              :name="productDeliveryStatus.NOT_SHIPPED"
            >
              <div slot="title" class="heading heading--sm">{{ $t('text.notShipped') }}</div>
              <account-order-details-shipment
                :order-data="orderData"
                :table-headers="tableHeaders"
                :table-data="orderData[tab.key][productDeliveryStatus.NOT_SHIPPED]"
              >
                <div>
                  <account-order-details-shipment-grid
                    :order-data="orderData"
                    :table-headers="tableHeaders"
                    :table-data="orderData[tab.key][productDeliveryStatus.NOT_SHIPPED]"
                    :shipment="productDeliveryStatus.NOT_SHIPPED"
                  />
                </div>
              </account-order-details-shipment>
            </pdl-collapse-item>

            <template v-for="section in sections">
              <pdl-collapse-item
                v-if="isAllocatedOrBackordered(tab.key, section.key)"
                :key="section.key"
                :name="section.label"
              >
                <div v-if="section.key == productDeliveryStatus.BACKORDERED" slot="title" class="heading heading--sm">
                  {{ `${section.label} (${orderData.subtotals[productDeliveryStatus.BACKORDERED].quantity})` }}
                </div>
                <div v-if="section.key == productDeliveryStatus.ALLOCATED" slot="title" class="heading heading--sm">
                  {{ `${section.label} (${orderData.subtotals[productDeliveryStatus.ALLOCATED].quantity})` }}
                </div>
                <account-order-details-backordered-grid
                  :order-data="orderData"
                  :headers="tableHeaders"
                  :items="sectionItems(tab.key, section.key)"
                  :shipment-info="orderData[tab.key][section.key]"
                  :section-key="section.key"
                  :page-size="50"
                />
              </pdl-collapse-item>
            </template>
          </pdl-collapse>
        </pdl-tab-pane>
      </template>
    </pdl-tabs>

    <pdl-section-header v-if="!isConsumerFriendlyMode" size="md" :is-dividing="true">
      <template slot="content">
        <pdl-heading :level="1">{{ $t('checkout.summary.ordersummary') }}</pdl-heading>
      </template>
    </pdl-section-header>

    <div v-if="!isConsumerFriendlyMode" class="grid-x grid-margin-x">
      <div class="cell medium-6 large-4 large-offset-8">
        <account-order-details-summary v-if="!loading" :order-data="orderData" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import TrekButton from '@/components/TrekButton';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import AccountOrderDetailsInfo from '@/components/my-account/AccountOrderDetailsInfo';
import AccountOrderDetailsSummary from '@/components/my-account/AccountOrderDetailsSummary';
import AccountOrderDetailsShipmentGrid from '@/components/my-account/AccountOrderDetailsShipmentGrid';
import AccountOrderDetailsBackorderedGrid from '@/components/my-account/AccountOrderDetailsBackorderedGrid';
import AccountOrderDetailsShipmentInfo from '@/components/my-account/AccountOrderDetailsShipmentInfo';
import LoadOrderDetails from '@/components/containers/my-account/mixins/load-order-details';
import findIndex from 'lodash/findIndex';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {ProductDeliveryStatus} from '@/constants/product';

export default {
  components: {
    PdlSectionHeader,
    PdlHeading,
    TrekButton,
    AccountOrderDetailsInfo,
    AccountOrderDetailsSummary,
    AccountOrderDetailsShipmentGrid,
    AccountOrderDetailsBackorderedGrid,
    AccountOrderDetailsShipmentInfo,
    PdlCollapse,
    PdlCollapseItem,
  },
  mixins: [LoadOrderDetails],
  provide() {
    return {
      container: this,
    };
  },
  props: {
    textBikes: {
      type: String,
      default: '',
    },
    textAccessories: {
      type: String,
      default: '',
    },
    textNonStock: {
      type: String,
      default: '',
    },
    textAllocatedItems: {
      type: String,
      default: '',
    },
    textBackorderedItems: {
      type: String,
      default: '',
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    pageTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      productDeliveryStatus: ProductDeliveryStatus,
      orderUrl: this.getUrl(),
    };
  },

  computed: {
    ...mapState('backend', ['encodedContextPath', 'isConsumerFriendlyMode']),
  },
  async mounted() {
    await this.loadData(this.orderUrl);
  },

  methods: {
    sectionItems(productType, sectionKey) {
      return this.orderData[productType][sectionKey];
    },
    getUrl() {
      const pathArray = window.location.pathname.split('/');
      const index = findIndex(pathArray, (x) => x == 'order');
      let orderId = '';

      if (index > -1 && pathArray.length > index + 1) {
        orderId = pathArray[index + 1];
      }

      return `/account/order/json/v2/${orderId}${window.location.search}`;
    },
    downloadOrderDetails() {
      window.location.href = `${this.encodedContextPath}/my-account/order/export/${this.orderData.code}?company=${this.orderData.details.orderCompany}&ordertype=${this.orderData.details.orderType}`;
    },
    downloadOrderDetailsAscend() {
      window.location.href = `${this.encodedContextPath}/my-account/order/export/${this.orderData.code}?company=${this.orderData.details.orderCompany}&ordertype=${this.orderData.details.orderType}&targetSystem=ASCEND`;
    },
    isAllocatedOrBackordered(tabKey, sectionKey) {
      return (
        this.orderData[tabKey][sectionKey] &&
        (sectionKey == ProductDeliveryStatus.BACKORDERED || sectionKey == ProductDeliveryStatus.ALLOCATED)
      );
    },
  },
};
</script>
