<template>
  <pdl-section-header size="xl" :is-dividing="true">
    <template slot="content">
      <div class="w-full lg:w-auto">
        <div class="flex items-center mb-25">
          <product-label-group :product="product" :is-b2b-pdp="isB2bPdp" class="mr-1" />
          <span
            v-if="product.marketingModelYear"
            qaid="product-marketing-model-year"
            class="text-md"
            v-html="product.marketingModelYear"
          />
          <span
            v-else-if="product.modelYearIntroAndCurrent && product.isArchived"
            qaid="product-model-year"
            class="text-md"
            v-html="product.modelYearIntroAndCurrent"
          />
        </div>
        <pdl-heading :level="1" qaid="product-name">{{ product.name }} </pdl-heading>
      </div>
    </template>
    <template slot="actions">
      <div v-if="!product.isArchived" class="buttons">
        <a
          :href="getUrl(`/hang-tags/${product.code}`)"
          qaid="create-hangtag-btn"
          class="button button--primary button--hollow button--skinny"
        >
          {{ $t('text.buyingZone.createHangTag') }}
        </a>
        <a
          :href="getUrl(`/sell-sheet/${product.code}`)"
          qaid="create-sellsheet-btn"
          class="button button--primary button--hollow button--skinny"
        >
          {{ $t('text.buyingZone.createSellSheet') }}
        </a>
      </div>
    </template>
  </pdl-section-header>
</template>
<script>
import {Url} from '@/utils/url';
import ProductLabelGroup from '@/components/ProductLabelGroup';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  name: 'PdpProductHeader',
  components: {ProductLabelGroup, PdlSectionHeader, PdlHeading},
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isB2bPdp: true,
    };
  },
  methods: {
    getUrl: (url) => Url.withContextPath(url),
  },
};
</script>
