<template>
  <fieldset class="vertical-spacer-1 form__fieldset">
    <legend class="form__legend header-xsmall">
      {{ $t('checkout.multi.deliveryMethod.chooseRetailer') }}
    </legend>
    <locale-shipping-text v-if="showOvernightShippingText" />

    <slot name="choose-retailer-description" />

    <p v-if="hasEbike && !isCPO" class="mb-2">{{ $t('checkout.multi.deliveryMethod.ebikeRetailerRestriction') }}</p>

    <slot name="delivery-specifics" />

    <dealer-zipcode-search />

    <pdl-loading :is-loading="isDealersLoading">
      <dealer-list />
    </pdl-loading>
  </fieldset>
</template>

<script>
import {mapState} from 'vuex';
import {PdlLoading} from '@pedal/pdl-loading';
import DealerList from '@/components/checkout/b2c/dealers/DealerList';
import DealerZipcodeSearch from '@/components/checkout/b2c/dealers/DealerZipcodeSearch';
import LocaleShippingText from './LocaleShippingText.vue';

export default {
  components: {PdlLoading, DealerList, DealerZipcodeSearch, LocaleShippingText},
  props: {
    hasEbike: {
      type: Boolean,
      default: false,
    },
    isCPO: {
      type: Boolean,
      default: false,
    },
    showOvernightShippingText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('checkout', ['isDealersLoading']),
  },
};
</script>
