<script>
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import {PdlIcon} from '@pedal/pdl-icon';
import TrekLink from '@/components/TrekLink';
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';
import EditorialFlair from '@/components/containers/pdp/overview/editorial-flair/EditorialFlair';
// TODO: Remove the buying zone container from here? Currently imported to this context in the test impex
import pdpBuyingZoneContainer from '@/components/containers/pdp/PdpBuyingZoneContainer';
import productCardItem from '@/components/containers/plp/ProductCardItem';
import techInfoContainer from '@/components/containers/tech-info/TechInfoContainer';
import {PdlLoading} from '@pedal/pdl-loading';
import ProductReviewsComponent from '@/components/power-reviews/ProductReviewsComponent';
import ProductReviewsHeader from '@/components/power-reviews/ProductReviewsHeader';
import BulkProductGridContainer from '@/components/containers/bulk-product/BulkProductGridContainer';
import {updateStockWatchStatus} from '@/components/containers/bulk-product/utils/warehouses';
import ProductGroups from '@/components/containers/bulk-product/utils/product-groups';
import SizeTable from '@/components/SizeTable';
import ImageComponent from '@/components/ImageComponent';
import GeometryContainer from '@/components/containers/pdp/GeometryContainer';
import {ProductApi} from '@/api/product';
import findIndex from 'lodash/findIndex';
import SpecsContainer from '@/components/containers/pdp/SpecsContainer';
import BomSpecsContainer from '@/components/containers/pdp/BomSpecsContainer';
import OverviewComponents from '@/components/containers/pdp/overview';
import ProductReferencesContainer from '@/components/containers/product-references/ProductReferencesContainer';

export default {
  name: 'TabsContentContainer',
  componentName: 'TabsContentContainer',
  components: {
    PdlCollapse,
    PdlCollapseItem,
    pdpBuyingZoneContainer,
    productCardItem,
    techInfoContainer,
    TrekLink,
    PdlLoading,
    PdlTabs,
    PdlTabPane,
    PdlIcon,
    ProductReviewsComponent,
    SizeTable,
    ProductReviewsHeader,
    BulkProductGridContainer,
    GeometryContainer,
    SpecsContainer,
    BomSpecsContainer,
    ImageComponent,
    EditorialFlair,
    ...OverviewComponents,
    ProductReferencesContainer,
  },
  props: {
    initialTab: {
      type: String,
      default: '',
    },
    baseProductCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      collapseAnimationDelay: 600,
      collapseAnimationOffsetDelay: 675,
      productGroups: [],
      initialTabSet: false,
      mobile: {
        container: 'pdl-collapse',
        item: 'pdl-collapse-item',
        labelKey: 'title',
        options: {
          accordion: true,
          defaultActive: false,
          iconActive: 'remove',
          iconInactive: 'add',
        },
      },
      desktop: {
        container: 'pdl-tabs',
        item: 'pdl-tab-pane',
        labelKey: 'label',
        options: {
          isSticky: true,
          isLazy: false,
          alignment: 'center',
          tabPosition: 'top',
        },
      },
    };
  },
  computed: {
    ...mapState('global', ['headerHeight']),
    ...mapState('viewSize', ['viewBreakpoint']),
    ...mapState('backend', ['unitId']),
    ...mapGetters('pdp', ['getActiveTab']),
    ...mapGetters('viewSize', ['viewBreakpointLessThan']),

    activeTab: {
      get() {
        return this.getActiveTab;
      },
      set(value) {
        this.setActiveTab(value);
        if (this.initialTabSet) {
          this.scrollToContent();
        }
      },
    },
    ui() {
      return this.viewBreakpointLessThan('large') ? this.mobile : this.desktop;
    },
    headerStyle() {
      return {
        '--header-height': `${this.headerHeight}px`,
      };
    },
  },
  created() {
    this.activeTab = this.initialTab;
    this.initialTabSet = true;
  },
  mounted() {
    if (this.unitId !== 'b2c') {
      this.fetchProducts();
    }
  },
  methods: {
    ...mapMutations('pdp', ['setActiveTab']),
    ...mapMutations('storage', ['setBikeTechSKU']),
    ...mapActions('skuGrid', ['loadAllocatedCounts']),
    scrollToContent() {
      if (this.viewBreakpointLessThan('large')) {
        setTimeout(() => {
          let primaryNavElement = document.getElementById('abbreviatedNavigationBar');
          const stickyNavOffset = primaryNavElement.getBoundingClientRect().height;
          let elements = document.getElementsByName(this.activeTab);
          elements?.[0]?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
          setTimeout(() => {
            window.scrollBy({top: -stickyNavOffset, behavior: 'smooth'});
          }, this.collapseAnimationOffsetDelay);
        }, this.collapseAnimationDelay);
      } else {
        let primaryNavElement = document.getElementById('primaryNavigation');
        const stickyNavOffset = primaryNavElement.getBoundingClientRect().height;
        let buyingZoneElement = document.getElementById('buying-zone');
        const y = buyingZoneElement.getBoundingClientRect().height + stickyNavOffset;
        window.scroll({top: y, behavior: 'smooth'});
      }
    },
    /**
     * Get product's ID from current URL.
     * @return {string|*}
     */
    getProductIdFromUrl() {
      const pathArray = window.location.pathname.split('/');
      const index = findIndex(pathArray, (x) => x === 'p');

      return index > -1 && pathArray.length > index + 1 ? pathArray[index + 1] : '';
    },

    /**
     * Update stock data.
     * @param payload
     */
    updateStocks(payload) {
      if (payload && payload.item) {
        updateStockWatchStatus(this.productGroups, payload.item);
      }
    },

    /**
     * Fetch product data.
     */
    fetchProducts() {
      const productId = this.getProductIdFromUrl();
      this.setBikeTechSKU(productId);

      if (this.baseProductCode) {
        ProductApi.loadProduct(this.baseProductCode).then((data) => {
          const productGroups = new ProductGroups(data);
          this.productGroups = productGroups.getProductGroups();
        });

        this.loadAllocatedCounts([this.baseProductCode]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .pdl-collapse-item__header {
  @apply font-heading;
  @apply text-lg;
}

::v-deep .pdl-tabs .pdl-tabs__item {
  @apply font-heading;
  @apply text-xl;
}

::v-deep .pdl-tabs__header.is-top.is-sticky {
  z-index: 100;
}

::v-deep .pdl-tabs .pdl-tabs__header.is-sticky {
  top: var(--header-height);
}
</style>
