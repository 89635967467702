<template>
  <!-- Product Groups wrapper/iterator -->
  <div>
    <div
      v-for="(productGroup, productGroupIndex) in productGroups"
      :key="productGroupIndex"
      :qaid="`bundle-child-product-${productGroup.code}`"
      :product-group-index="productGroupIndex"
    >
      <pdl-collapse
        icon-active="indeterminate_check_box"
        icon-inactive="add_box"
        icon-position="left"
        align-icon="start"
        :default-active="false"
        :value="viewBreakpointMoreThan('medium') ? [productGroupIndex] : null"
        qaid="bulk-product-collapse"
      >
        <bulk-product-grid
          :hide-unavailable="hideUnavailable"
          :image-size="{width: 192, height: 144}"
          :base-product="baseProductCode"
          :product-group="productGroup"
          :product-type="productGroup.productType"
          :product-group-index="productGroupIndex"
          :is-pdp="true"
          :has-sku-footer-button="false"
          :has-link="false"
          column-config="BUNDLE"
        />
      </pdl-collapse>
    </div>
  </div>
</template>

<script>
import BulkProductGrid from '@/components/containers/bulk-product/BulkProductGrid';
import ProductGroups from '@/components/containers/bulk-product/utils/product-groups';
import {PdlCollapse} from '@pedal/pdl-collapse';
import {mapGetters} from 'vuex';

export default {
  components: {BulkProductGrid, PdlCollapse},
  props: {
    childProduct: {
      type: Object,
      required: true,
      default: () => null,
    },
  },

  data() {
    return {
      hideUnavailable: false,
      productGroups: [],
    };
  },

  computed: {
    ...mapGetters('viewSize', ['viewBreakpointMoreThan']),

    baseProductCode() {
      return !this.productGroups || !('code' in this.productGroups) ? '' : this.productGroups.code;
    },
  },

  mounted() {
    const productGroups = new ProductGroups(this.childProduct);
    this.productGroups = productGroups.getProductGroups();
  },
};
</script>
