const BreadCrumbSchema = Object.freeze({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
});

export default class BreadCrumbMarkup {
  static get SCRIPT_ID() {
    return 'breadcrumb-markup';
  }

  static update(context, value) {
    context.textContent = JSON.stringify(value);
  }

  static inject(value) {
    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('id', BreadCrumbMarkup.SCRIPT_ID);
    scriptElement.setAttribute('type', 'application/ld+json');

    BreadCrumbMarkup.update(scriptElement, value);

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(scriptElement);
  }

  static hydrate({categoryName, categoryLink, productName, productLink}) {
    return Object.assign(
      {
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: categoryName,
            item: categoryLink,
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: productName,
            item: productLink,
          },
        ],
      },
      BreadCrumbSchema
    );
  }

  static watch(product, category) {
    const {protocol, hostname, href} = window.location;
    const locale = window.ACC.config.encodedContextPath;
    const base = `${protocol}//${hostname}${locale}`;
    const categoryName = category?.name ?? '';
    const categoryLink = category?.link ? `${base}${category.link}` : '';
    const productName = product?.name ?? '';
    const productLink = href;

    const fields = [categoryName, categoryLink, productName, productLink];
    const hasEmptyFields = fields.filter((required) => required.length < 1).length;
    if (hasEmptyFields) {
      return console.warn('breadcrumb markup does not have required props (ie product/ category)');
    }

    const payload = BreadCrumbMarkup.hydrate({categoryName, categoryLink, productName, productLink});
    const scriptElement = document.getElementById(BreadCrumbMarkup.SCRIPT_ID);

    if (scriptElement) {
      BreadCrumbMarkup.update(scriptElement, payload);
    } else {
      BreadCrumbMarkup.inject(payload);
    }
  }
}
