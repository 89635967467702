<template>
  <div><slot></slot></div>
</template>

<script>
export default {
  props: {
    gridData: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
