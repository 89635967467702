<template>
  <div>
    <div v-for="(item, index) in items" :key="index">
      {{ item }}
    </div>
  </div>
</template>

<script>
import {PdlLabel, PdlLabelGroup} from '@pedal/pdl-label';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import ImageComponent from '@/components/ImageComponent';

export default {
  /* Imported Pedal components give articlesListerItem.tag access to pdl-label, but es-lint does 
  not recognize the components are used and errors. The comment below prevents the es-lint error for this file */
  /* eslint-disable vue/no-unused-components */
  components: {
    PdlLabel,
    PdlLabelGroup,
    PdlCollapse,
    PdlCollapseItem,
    ImageComponent,
    PdlSectionHeader,
    PdlHeading,
  },

  props: {
    results: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    items() {
      return JSON.parse(this.results);
    },
  },
};
</script>
