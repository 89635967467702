<template>
  <div class="payment-icon-list">
    <template v-if="isSupportedMethods">
      <div class="space-x-1 flex justify-center items-center mb-1">
        <credit-card-icon />
        <span class="font-medium">
          {{ $t('text.footer.payment.methods') }}
        </span>
      </div>
      <ul class="flex justify-center items-center mx-auto mb-6 flex-wrap gap-3">
        <li
          v-for="(icon, i) in paymentIcons"
          :key="`accepted-type-${i}`"
          :class="`payment-methods payment-methods-${icon.name}`"
          :qaid="`payment-methods-${icon.name}-logo`"
        >
          <component
            :is="icon.type"
            v-if="icon.type"
            :qaid="`payment-methods-${icon.name}-image`"
            class="payment-icons"
          />
        </li>
      </ul>
    </template>
    <div class="flex flex-col items-center gap-1">
      <div class="flex items-center space-x-1">
        <pdl-icon name="lock" size="18" />
        <span class="font-medium">
          {{ $t('checkout.multi.secure.checkout') }}
        </span>
      </div>
      <div>
        <norton-secured-icon />
        <span class="sr-only">Norton Secured - Powered by VeriSign</span>
      </div>
    </div>
  </div>
</template>

<script>
import ApplePayIcon from '@/icons/ApplePayIcon.vue';
import {mapGetters} from 'vuex';
import {PdlIcon} from '@pedal/pdl-icon';
import {IconCardTypes, KCPPaymentMethodCode} from '@/constants/checkout.js';
import VisaIcon from '@/icons/VisaIcon.vue';
import AmexIcon from '@/icons/AmexIcon.vue';
import MasterCardIcon from '@/icons/MasterCardIcon.vue';
import CreditCardIcon from '@/icons/CreditCardIcon.vue';
import IdealIcon from '@/icons/IdealIcon.vue';
import JcbIcon from '@/icons/JcbIcon.vue';
import NortonSecuredIcon from '@/icons/NortonSecuredIcon.vue';
import VisaElectronIcon from '@/icons/VisaElectronIcon.vue';
import MaestroIcon from '@/icons/MaestroIcon.vue';

export default {
  components: {
    PdlIcon,
    ApplePayIcon,
    VisaIcon,
    AmexIcon,
    MasterCardIcon,
    CreditCardIcon,
    IdealIcon,
    JcbIcon,
    NortonSecuredIcon,
    VisaElectronIcon,
    MaestroIcon,
  },
  props: {
    acceptedCardTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      paymentIcons: [],
      hasApplePayEnabled: false,
    };
  },
  computed: {
    ...mapGetters('backend', ['getSupportedPaymentMethodCodes']),
    isSupportedMethods() {
      return !!this.paymentIcons.length;
    },
  },
  watch: {
    getSupportedPaymentMethodCodes(value) {
      if (value?.length) this.filterPaymentMethods();
    },
  },
  methods: {
    createCardAltText(cardName) {
      return `${cardName} payment method`;
    },
    filterPaymentMethods() {
      const iconConfig = {
        [IconCardTypes.jcb]: {type: 'JcbIcon', name: 'jcb'},
        [IconCardTypes.visa]: {type: 'VisaIcon', name: 'visa'},
        [IconCardTypes.masterCard]: {type: 'MasterCardIcon', name: 'mastercard'},
        [IconCardTypes.amex]: {type: 'AmexIcon', name: 'amex'},
        [IconCardTypes.CYBERSOURCE_APPLE]: {type: 'ApplePayIcon', name: 'applepay'},
        [IconCardTypes.GR4VY_APPLE]: {type: 'ApplePayIcon', name: 'applepay'},
        [IconCardTypes.CYBERSOURCE_IDEAL]: {type: 'IdealIcon', name: 'ideal'},
        [IconCardTypes.electron]: {type: 'VisaElectronIcon', name: 'visaelectron'},
        [IconCardTypes.maestro]: {type: 'MaestroIcon', name: 'maestro'},
      };
      this.getSupportedPaymentMethodCodes.forEach((code) => {
        if (code === KCPPaymentMethodCode) return;
        if (iconConfig[code]) return this.paymentIcons.push(iconConfig[code]);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .payment-methods {
  svg {
    width: 63px;
  }
}

::v-deep .payment-methods-visa {
  svg {
    width: 70px;
  }
}

::v-deep .payment-methods-visaelectron {
  svg {
    width: 70px;
  }
}

::v-deep .payment-methods-maestro {
  svg {
    width: 140px;
  }
}

::v-deep .payment-methods-jcb {
  svg {
    height: 61px;
  }
}

::v-deep .payment-methods-amex {
  svg {
    height: 44px;
  }
}

::v-deep .norton-secured-icon {
  svg {
    width: 88px;
  }
}
</style>
