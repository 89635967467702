<template>
  <table :qaid="tableData.qaid" :class="[baseClass, ...variantClasses]">
    <caption v-if="tableData.qaid" class="sr-only">
      {{
        tableData.qaid
      }}
    </caption>
    <thead v-if="showHead && columns.length">
      <tr>
        <th
          v-for="(head, headIndex) in columns"
          :key="headIndex"
          :qaid="`${tableData.qaid}-column-header-${head}`"
          scope="col"
          class="font-bold"
        >
          {{ head }}
        </th>
      </tr>
    </thead>
    <tbody v-if="rows">
      <tr v-for="(row, rowId) in rows" :key="rowId">
        <!-- NOTE: Uses v-html. Only pass data from trusted sources. -->
        <component
          :is="isRowHeader(index) ? 'th' : 'td'"
          v-for="(cell, index) in row"
          :key="index"
          :scope="isRowHeader(index) ? 'row' : false"
          :qaid="index === 0 ? `${tableData.qaid}-row-header-${cell}` : null"
          v-html="cell"
        ></component>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    variants: {
      type: Array,
      default: () => [],
    },
    showHead: {
      type: Boolean,
      default: true,
    },
    hasRowHeaders: {
      type: Boolean,
      default: true,
    },
    tableData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseClass: 'trek-table',
    };
  },
  computed: {
    columns() {
      return this.tableData.heads?.cols || [];
    },
    rows() {
      return Object.keys(this.tableData.items || {}).length ? this.tableData.items : {};
    },
    variantClasses() {
      let classes = this.variants.map(variantToClass);

      if (this.hasRowHeaders) {
        // Based on the hasRowHeaders prop to only require one input.
        classes.push(`${variantToClass('row-headers')}`);
      }

      return classes;

      function variantToClass(variant) {
        return variant ? `trek-table--${variant}` : '';
      }
    },
  },
  methods: {
    isRowHeader(index) {
      return this.hasRowHeaders && index === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.trek-table {
  @apply text-sm;

  margin-bottom: 0;
  // border-collapse: separate to support sticky first column
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;

  &--small {
    th,
    td {
      padding: 0.25rem 1rem;
    }
  }

  &--large {
    th,
    td {
      padding: 1rem;
    }
  }
}

th,
td {
  padding: 0.5rem 1rem;
  border-top: 1px solid var(--gray-10);
  border-left: 1px solid transparent;
  background-color: var(--white);
}

thead th,
thead td {
  border-top: 1px solid transparent;
  border-bottom: 1px solid var(--gray-100);
}

tbody th {
  font-weight: normal;
}

th.text-right,
td.text-right {
  text-align: right;
}

th:first-child,
td:first-child {
  border-left: none;
}

// Variant styles
.trek-table--centered th,
.trek-table--centered td {
  text-align: center;
}

.trek-table--narrow th,
.trek-table--narrow td {
  padding: 0.5rem;
}

.trek-table--borderless {
  tbody th,
  td {
    border-color: transparent;
  }
}

.trek-table--bordered {
  thead td,
  thead th {
    border-bottom: 1px solid var(--gray-100);
    border-left: 1px solid var(--gray-10);
  }

  tbody td,
  tbody th {
    border-bottom: 1px solid var(--gray-10);
    border-left: 1px solid var(--gray-10);
  }
}

.trek-table--row-headers {
  tbody th,
  thead th:first-child {
    position: sticky;
    left: 0;
  }
}

.trek-table--hstripe {
  tbody tr:nth-child(even) td,
  tbody tr:nth-child(even) th {
    background-color: var(--gray-05);
  }
}

// the top row of headers will stick and scroll with the table if the table is scrollable vertically
.trek-table--sticky-headers {
  thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;
  }

  thead th:first-child {
    z-index: 4;
  }
}

.trek-table--vstripe {
  td:nth-child(even) {
    background: var(--gray-05);
  }
}

// a wide table will maintain its columns and allow
// sideways scrolling to see the rest of the data
.trek-table--wide {
  @include breakpoint(medium down) {
    tbody th,
    thead th {
      &:first-child {
        display: none;
      }
    }

    td[data-spec]::before {
      content: attr(data-spec);
      display: block;
      margin-bottom: rem-calc(5);
      font-weight: 600;
    }
  }
  // we can revisit this if this styling is needed when we move the Trek Table to pedal
  // @media only screen and (max-width: 0 - 0.0625rem) {
  //   .side-scroll-wrapper {
  //     overflow-x: auto;
  //   }

  //   thead {
  //     display: table-header-group;
  //   }

  //   & tr {
  //     display: table-row;
  //   }

  //   & td {
  //     display: table-cell;
  //   }
  // }
}
</style>
