<template>
  <li class="order-summary__list-item">
    <section class="order-tile-checkout">
      <h4 qaid="checkout-product-name" class="font-bold">{{ cleanedProductData.displayName }}</h4>
      <div class="small-4 columns">
        <img v-if="imageThumbnail" class="order-tile-checkout__image" :src="imageThumbnail" :alt="imageAltText" />
      </div>
      <div class="order-tile-checkout__details small-8 columns space-y-1">
        <div
          v-for="(category, i) in variantCategories"
          :key="`checkout-product-${i}`"
          class="order-tile-checkout__list"
        >
          <span
            class="order-tile-checkout__term text-small-strong mr-1"
            :qaid="`checkout-product-${category.qaid}-title`"
          >
            {{ `${category.title}:` }}
          </span>
          <span class="order-tile-checkout__definition text-small" :qaid="`checkout-product-${category.qaid}-value`">
            {{ category.value }}
          </span>
        </div>
        <div class="order-tile-checkout__quantity order-tile-checkout__list">
          <span class="order-tile-checkout__term text-small-strong mr-1">{{ `${$t('basket.page.qty')}:` }}</span>
          <span class="order-tile-checkout__definition text-small">{{ cartEntry.quantity }}</span>
        </div>
        <div class="order-tile-checkout__quantity order-tile-checkout__list">
          <span class="order-tile-checkout__term font-bold mr-1" qaid="checkout-product-price-title">
            {{ $t('text.checkout.price') }}
          </span>
          <div class="order-tile-checkout__definition text-small-xstrong" :qaid="priceQaid">
            <span v-if="cartEntry.isLineLevelPromotionPresent" class="promoPrice">
              {{ ` ${cartEntry.formattedAdjustedUnitPrice}` }}
            </span>
            <span :class="priceClasses">
              {{ ` ${cartEntry.basePrice.formattedValue}` }}
            </span>
            <span v-if="cartEntry.product.price.formattedCalculableAdvertised" class="beforeSalePrice">
              {{ cartEntry.product.price.formattedCalculableAdvertised }}
            </span>
          </div>
        </div>
      </div>
    </section>
  </li>
</template>

<script>
import {ProductImages} from '@/components/containers/pdp/utils/product-images';

export default {
  props: {
    cartEntry: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    cleanedProductData() {
      return Object.entries(this.cartEntry.product)
        .filter(([, value]) => value !== null)
        .reduce((prev, [key, value]) => ({...prev, [key]: value}), {});
    },
    variantCategories() {
      return this.cleanedProductData?.variantMatrix?.map((column) => ({
        title: column.parentVariantCategory?.name,
        value: column.variantValueCategory?.name,
        qaid: column.parentVariantCategory?.code,
      }));
    },
    primaryImage() {
      return this.getPrimaryImage(this.cleanedProductData?.images);
    },
    imageThumbnail() {
      if (!this.primaryImage) return;
      return ProductImages.getThumbnailUrl(this.primaryImage.assetId, {c: 'pad'});
    },
    imageAltText() {
      if (!this.primaryImage) return;
      return this.primaryImage.altText;
    },
    price() {
      if (this.cartEntry.isLineLevelPromotionPresent) return;
      return this.cartEntry.basePrice?.value > 0 ? this.cartEntry.basePrice?.formattedValue : this.$t('text.free');
    },
    priceQaid() {
      return this.cartEntry.isLineLevelPromotionPresent ? 'checkout-promotion-price' : 'checkout-product-price-value';
    },
    priceClasses() {
      return {
        salePrice: this.cartEntry.product.price.formattedCalculableAdvertised,
        beforeSalePrice: this.cartEntry.isLineLevelPromotionPresent,
      };
    },
  },
  methods: {
    getPrimaryImage(imageList) {
      return imageList?.find((image) => image.imageType === 'PRIMARY');
    },
  },
};
</script>
