<template>
  <pdl-media-object class="bundle-dialog__media-object mb-6">
    <div slot="media">
      <img
        class="m-auto"
        qaid="bundle-dialog-image"
        :src="productImageSrc"
        :alt="bundleMainProductData.bundleMainProductName"
      />
    </div>

    <div slot="content">
      <h2
        qaid="bundle-dialog-description"
        class="heading heading--md mb-2"
        v-html="bundleMainProductData.bundleMainProductName"
      />

      <div v-for="(item, index) in bundleMainProductData.bundleMainProductMetaData" :key="index" class="mb-2">
        <div qaid="bundle-dialog-category" class="text-sm font-medium leading-normal" v-html="item.key" />
        <div qaid="bundle-dialog-variant" class="text-md" v-html="item.value" />
      </div>

      <div class="form-group">
        <label qaid="bundle-dialog-quantity" for="qty" class="form-label">{{ $t('basket.page.quantity') }}</label>
        <numerical-input
          id="bundleQty"
          ref="bundleQuantityInput"
          qaid="bundle-dialog-input"
          class="w-20"
          :initial-value="bundleMainProductData.bundleMainProductQuantity"
          :min="0"
          :max="9999"
          :should-clear="true"
          :is-buying-zone="true"
          @update-variant-qty="
            (payload) =>
              $emit('update-variant-qty', {
                qty: payload,
                sku: bundleMainProductData.bundleVariantCode,
                input: $refs['bundleQuantityInput'],
              })
          "
        />
      </div>
    </div>
  </pdl-media-object>
</template>
<script>
import {PdlMediaObject} from '@pedal/pdl-media-object';
import {mapMutations} from 'vuex';
import NumericalInput from '@/components/NumericalInput';
import {ProductImages} from '@/components/containers/pdp/utils/product-images';

export default {
  name: 'BundlingPrimaryProduct',
  components: {PdlMediaObject, NumericalInput},
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    bundleMainProductData: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    productImageSrc() {
      if (!this.bundleMainProductData?.bundleMainProductAssetId) {
        return this.bundleMainProductData?.bundleMainProductUrl;
      }
      return ProductImages.getAssetUrl(this.bundleMainProductData?.bundleMainProductAssetId, {w: 800, h: 600});
    },
  },
  watch: {
    showDialog(isVisible) {
      if (isVisible) {
        this.setBundleProductQuantity();
        this.getBundleVariant();
      }
    },
  },
  mounted() {
    if (this.showDialog) {
      this.setBundleProductQuantity();
      this.getBundleVariant();
    }
  },
  methods: {
    ...mapMutations('skuGrid', ['updateVariantQuantity', 'isBundleInput']),
    setBundleProductQuantity() {
      this.$refs.bundleQuantityInput.$el.value = this.bundleMainProductData.bundleMainProductQuantity;
    },
    getBundleVariant() {
      this.isBundleInput(true);
      this.updateVariantQuantity({
        qty: this.$refs.bundleQuantityInput.$el.value,
        sku: this.bundleMainProductData.bundleVariantCode,
        input: this.$refs['bundleQuantityInput'],
        callback: () => '',
      });
    },
  },
};
</script>
<style scoped lang="scss">
.pdl-media-object {
  &.bundle-dialog__media-object ::v-deep {
    @include breakpoint(small down) {
      --pdl-media-object-padding: 0;

      display: block;
    }

    .pdl-media-object__media {
      @apply w-1/2;

      @include breakpoint(small down) {
        @apply w-full;

        margin-bottom: 3rem;
      }
    }
  }
}
</style>
