<template>
  <div>
    <pdl-section-header size="lg" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1" qaid="page-title">{{ bp('confirmPurchase') }}</pdl-heading>
      </template>
    </pdl-section-header>
    <p class="text-base italic">{{ resultStringAndNumber }}</p>
    <div v-if="bikeNotFound" class="cell mt-1">
      <pdl-callout kind="warning">
        <template #content>
          <span v-html="bikeNotFound" />
        </template>
      </pdl-callout>
    </div>
    <div v-if="bikes.length > 1" class="mt-1">
      <pdl-callout kind="warning" qaid="choose-bike-message">
        <template #content>
          {{ $t('productRegistration.chooseModel') }}
        </template>
      </pdl-callout>
    </div>
    <div class="grid-x grid-margin-x small-up-1 medium-up-2 large-up-3 my-5" qaid="bike-results-section">
      <div v-for="(item, index) in bikes" :key="index" class="cell b2b-card b2b-card--padded" qaid="bike-results-card">
        <div class="b2b-card__img b2b-card__img--padded">
          <img :src="item.imageUrl || noBikeImage" alt="" />
        </div>

        <div class="b2b-card__footer b2b-card__footer--short b2b-card__footer--border">
          <label :key="index" :qaid="`option-${index}`" :for="`option-${index}`" class="radio m-0">
            <input
              :id="`option-${index}`"
              v-model="pickedBike"
              type="radio"
              name="user-role"
              :value="item.sku"
              :qaid="`option-${index}`"
              required="required"
            />
            <span class="control-indicator"></span>
            <span>
              <span v-if="item.description" qaid="bike-model-description" class="mr-25">{{ item.description }}</span>
              <span v-if="item.year" qaid="bike-model-year">{{ item.year }}</span>
            </span>
          </label>
        </div>
      </div>
    </div>

    <div class="buttons">
      <trek-link button primary :disabled="!pickedBike" :href="registerUrl" qaid="bike-result-continue-button">
        <span v-html="bp('continueButton')" />
      </trek-link>
      <trek-link button secondary :href="notFoundUrl" qaid="bike-result-not-found-button">
        <span v-html="bp('bikeNotShownButton')" />
      </trek-link>
    </div>

    <a
      class="underline hover:no-underline"
      :href="contactUsUrl"
      qaid="bike-result-request-support-link"
      v-html="bp('requestSupport')"
    />
  </div>
</template>
<script>
import TrekLink from '@/components/TrekLink';
import {mapState} from 'vuex';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {TrekLink, PdlSectionHeader, PdlHeading},
  props: {
    baseProperties: {
      type: Array,
      default: () => [],
    },
    bikes: {
      type: Array,
      default: () => [],
    },
    contextPath: {
      type: String,
      default: '',
    },
    bikeNotFound: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pickedBike: '',
      noBikeImage:
        'https://media.trekbikes.com/image/upload/f_auto,q_auto,w_800,h_600/Product-Registration_No-Bike-Image-Found',
      resultStringAndNumber: '',
    };
  },

  computed: {
    registerUrl() {
      return (window.location.href + this.pickedBike).replace('serial', 'verify') + '/';
    },
    notFoundUrl() {
      return `${this.contextPath}/${this.b2b ? 'bike-registration' : 'productRegistration'}/bike-not-found/`;
    },
    contactUsUrl() {
      return `${this.contextPath}/${this.b2b ? 'contact-us' : 'contactUs'}`;
    },
    ...mapState('backend', ['b2b']),
  },
  mounted() {
    this.resultStringAndNumber = this.getBaseProperty('resultsFound').replace('{0}', this.bikes.length);
    this.initDefaultBikeSelection();
  },

  methods: {
    initDefaultBikeSelection() {
      this.pickedBike = this.bikes.length === 1 ? this.bikes[0]?.sku : '';
    },
  },
};
</script>
