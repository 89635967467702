import {mapActions, mapState} from 'vuex';
import debounce from 'lodash/debounce';

/**
 * Maximum number of grids to be rendered at a time.
 * @type {number}
 */
const renderingMax = 1;

/**
 * A delay between rendering to allow browser to refresh and not to freeze.
 * @type {number}
 */
const renderingDelay = 200;

/**
 * Maximum number of products in the loading queue.
 * @type {number}
 */
const loadingChunkSize = 5;

const DelayedProductsRendering = {
  computed: {
    ...mapState('plp', ['collapseState']),
  },
  watch: {
    collapseState: {
      deep: true,
      immediate: true,
      handler: debounce(function () {
        this.loadActiveProductsAndVariants();
      }, 10),
    },
  },
  methods: {
    ...mapActions('skuGrid', ['loadAllocatedCounts']),
    ...mapActions('plp', ['updateProductStatus', 'loadProductData']),
    loadActiveProductsAndVariants() {
      const ids = this.getProductIdsToLoad();
      if (!ids.length) return;
      this.loadAllocatedCounts(ids);
      this.loadProductsByChunks(ids);
    },
    /**
     * Check whether the product has product groups by given product code.
     * @param code
     * @return {boolean}
     */
    productHasGroups(code) {
      const product = this.products.find((product) => product.code === code);
      return product && 'productGroups' in product && Object.keys(product.productGroups).length > 0;
    },

    /**
     * Get a list of products which we need to load.
     * @return {string[]}
     */
    getProductIdsToLoad() {
      const ids = Object.keys(this.collapseState).filter(
        (code) => this.collapseState[code] && !this.productHasGroups(code)
      );

      return ids;
    },

    /**
     * Start a loading queue and load products by chunks.
     * @param ids
     */
    async loadProductsByChunks(ids) {
      let chunks = this.splitByChunks(ids, loadingChunkSize);

      await this.loadProductData({
        chunks,
        options: {
          isSkipArchiveProducts: this.isArchive,
        },
      });

      chunks.forEach((chunk) => this.addToRenderingQueue(chunk));
    },

    /**
     * Split the list of IDs by chunks.
     * @param ids
     * @param chunkSize
     * @return {[]|*[]}
     */
    splitByChunks(ids, chunkSize) {
      if (chunkSize <= 0) {
        return [];
      }

      const result = [];
      const firstChunkSize = 4;

      if (ids.length > firstChunkSize) {
        const half = (firstChunkSize + (firstChunkSize & 1)) / 2;
        ids.splice(half, 0, ...ids.splice(ids.length - half, half));
      }

      for (let n = 0; n < ids.length; n += !n ? firstChunkSize : chunkSize) {
        result.push(ids.slice(n, n + (!n ? firstChunkSize : chunkSize)));
      }
      return result;
    },

    /**
     * Stop rendering queue.
     */
    stopRenderingQueue() {
      if (this.renderingTimeout) {
        clearInterval(this.renderingTimeout);
      }
    },

    /**
     * Render one or more grids (specified by renderingMax constant).
     */
    renderProducts() {
      const itemsToRender = this.renderingQueue.splice(0, renderingMax);
      if (itemsToRender && itemsToRender.length > 0) {
        itemsToRender.forEach((id) => {
          this.updateProductStatus({
            code: id,
            isReady: true,
          });
        });
      }

      if (this.renderingQueue.length > 0) {
        this.renderingTimeout = setTimeout(this.renderProducts, renderingDelay);
      }
    },

    /**
     * Add a list of IDs to rendering queue.
     * @param ids
     */
    addToRenderingQueue(ids) {
      if (!ids.length) {
        return;
      }

      this.renderingQueue.push(...ids);
      this.renderProducts();
    },
  },
  data() {
    return {
      renderingTimeout: null,
      renderingQueue: [],
    };
  },
  beforeDestroy() {
    this.stopRenderingQueue();
  },
};

export {DelayedProductsRendering};
