<template>
  <main id="plpContainer" :class="mainComputedClasses" class="search-results grid-container fluid">
    <div class="grid-container mb-6">
      <trek-marquee :marquee="marqueeData" :is-simplified="true">
        <template #cms-marquee-link>
          <util:cmsLink component="${marqueeData.bodyCTA}" />
        </template>
      </trek-marquee>
      <div
        v-if="hasTitlePlp"
        class="cell mt-4 medium-8 medium-offset-2"
        data-ga-dimension="dimension8"
        :data-ga-dimension-value="categoryData.titlePlp"
      >
        <header class="text-center">
          <h1 class="title-plp header--lg mb-2">{{ categoryData.titlePlp }}</h1>
          <p class="category-body-copy text-lg">{{ categoryData.bodyPlp }}</p>
        </header>
      </div>
    </div>

    <!-- Slot for main page data -->
    <slot name="search-results"></slot>

    <!-- Default cms slots -->
    <slot></slot>
  </main>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import TrekMarquee from '@/components/TrekMarquee';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'PlpContainer',

  components: {
    TrekMarquee,
    // eslint-disable-next-line vue/no-unused-components
    PdlIcon,
  },

  props: {
    categoryData: {
      type: Object,
      default: null,
    },
    marqueeData: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode']),
    mainComputedClasses() {
      return this.categoryData?.titlePlp || this.marqueeData?.body ? 'mt-4' : 'mt-6';
    },
    hasTitlePlp() {
      return Boolean(this.categoryData?.titlePlp);
    },
  },

  mounted() {
    this.updateLastPlp();
  },

  methods: {
    ...mapActions('storage', ['updateLastPlp']),
  },
};
</script>
