import occInstance from '@/api/instances/occ';
import store from '@/store';

const FunctionalityTogglesApi = {
  /**
   * Get all functionality toggles.
   * @returns {Promise<Object>}
   */
  async getAllToggles() {
    const url = `functionality/toggles`;
    const params = {
      locale: store?.state?.backend?.language,
    };
    const response = await occInstance.get(url, {params});
    return response?.data;
  },
  /**
   * Get a specific functionality toggle by the toggle ID.
   * @param {String} toggleId
   * @returns {Promise<Object>}
   */
  async getToggleById(toggleId) {
    const url = `functionality/toggles/${toggleId}`;
    const params = {
      locale: store?.state?.backend?.language,
    };
    const response = await occInstance.get(url, {params});
    return response?.data;
  },
};

export {FunctionalityTogglesApi};
