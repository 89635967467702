var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"siteMenu__button",class:{'siteMenu__button--active': _vm.hasUnread}},[_c('a',{directives:[{name:"tippy",rawName:"v-tippy",value:({
      html: '#notifications-dropdown',
      theme: 'square',
      interactive: true,
      reactive: true,
      trigger: 'click',
      placement: 'bottom-end',
      flip: false,
      sticky: true,
      popperOptions: {
        positionFixed: true,
        modifiers: {
          keepTogether: true,
          preventOverflow: {
            enabled: false,
          },
          hide: {
            enabled: false,
          },
        },
      },
    }),expression:"{\n      html: '#notifications-dropdown',\n      theme: 'square',\n      interactive: true,\n      reactive: true,\n      trigger: 'click',\n      placement: 'bottom-end',\n      flip: false,\n      sticky: true,\n      popperOptions: {\n        positionFixed: true,\n        modifiers: {\n          keepTogether: true,\n          preventOverflow: {\n            enabled: false,\n          },\n          hide: {\n            enabled: false,\n          },\n        },\n      },\n    }"}],attrs:{"href":"#","aria-label":"notifications"}},[_c('pdl-icon',{attrs:{"name":"notifications","label":_vm.$t('text.notifications')}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }