<template>
  <main class="grid-container">
    <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1" qaid="contact-us-form-header">{{ $t('contactUs.title') }}</pdl-heading>
      </template>
    </pdl-section-header>

    <div class="max-w-sm">
      <form
        id="contactUsForm"
        ref="contactUsForm"
        modelAttribute="contactUsForm"
        action="contact-us-confirmation"
        method="post"
        qaid="contact-us-form-form"
        @submit.prevent="validateAndSubmit"
      >
        <input
          id="dealerId"
          path="dealerId"
          type="hidden"
          name="dealerId"
          :value="dealerId"
          maxlength="100"
          qaid="contact-us-form-dealerId"
        />
        <input
          id="userName"
          :value="username"
          path="userName"
          type="hidden"
          name="userName"
          maxlength="100"
          qaid="contact-us-form-userName"
        />
        <input
          id="sessionLanguage"
          :value="sessionLanguage"
          path="sessionLanguage"
          type="hidden"
          name="sessionLanguage"
          maxlength="100"
          qaid="contact-us-form-sessionLanguage"
        />

        <input
          id="dealerBranch"
          :value="dealerBranch"
          path="dealerBranch"
          type="hidden"
          name="dealerBranch"
          maxlength="100"
          qaid="contact-us-form-dealerBranch"
        />

        <form-group
          id="contactName"
          v-model="contactNameField"
          :label="$t('contactUs.B2B.contactName')"
          :maxlength="100"
          type="text"
          name="contactName"
          qaid="contact-us-form-contactName"
          :error="getErrorMessage('contactNameField')"
          required
          show-error-messages
          force-display-error
        />

        <div class="form-group">
          <label for="topic" class="form-label" qaid="contact-us-form-topic">
            {{ $t('contactUs.topic') }}
            <span class="text-red ml-25">
              *
              <span class="show-for-sr">(required)</span>
            </span>
          </label>
          <div class="select">
            <select
              id="topic"
              v-model="selectedTopic"
              class="form-control"
              name="topic"
              qaid="contact-us-form-selectedTopic"
              required
            >
              <option v-for="(option, index) in topics" :key="index" :value="option.id">
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>

        <form-group
          id="email"
          v-model="emailField"
          :label="$t('text.address.email')"
          :maxlength="100"
          type="email"
          name="email"
          qaid="contact-us-form-email"
          :error="getErrorMessage('emailField')"
          required
          show-error-messages
          force-display-error
        />

        <input type="hidden" name="ignore_redirectmode" value="Auto" />
        <form-group
          id="messageText"
          v-model="commentsField"
          :label="$t('contactUs.questions')"
          :maxlength="2000"
          type="textarea"
          name="messageText"
          qaid="contact-us-form-questions"
          :error="getErrorMessage('commentsField')"
          required
          show-error-messages
          force-display-error
        />

        <div class="buttons pb-5">
          <trek-button primary type="submit" qaid="contact-us-form-submit">
            <span v-html="$t('contactUs.submit')" />
          </trek-button>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import TrekButton from '@/components/TrekButton';
import FormGroup from '@/components/FormGroup';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  components: {
    FormGroup,
    TrekButton,
    PdlSectionHeader,
    PdlHeading,
  },
  mixins: [TrekValidationMixin],
  validations() {
    return {
      contactNameField: {
        required: this.trekValidators.requiredWithCustomMessage('requestReturnLabel.name.error'),
      },
      emailField: {
        required: this.trekValidators.requiredWithCustomMessage('contactUs.email.error'),
        email: this.trekValidators.email('contactUs.email'),
      },
      commentsField: {
        required: this.trekValidators.requiredWithCustomMessage('contactUs.question.error'),
      },
    };
  },
  props: {
    dealerId: {
      type: String,
      default: '',
    },
    sessionLanguage: {
      type: String,
      default: '',
    },
    dealerBranch: {
      type: String,
      default: '',
    },
    username: {
      type: String,
      default: '',
    },
    contactUsName: {
      type: String,
      default: '',
    },
    contactUsEmail: {
      type: String,
      default: '',
    },
    topics: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      serialNumberField: '',
      userNameField: '',
      dealerIdField: '',
      sessionLanguageField: '',
      dealerBranchField: '',
      contactNameField: this.contactUsName,
      selectedTopic: 'customerCareRep',
      emailField: this.contactUsEmail,
      commentsField: '',
    };
  },

  methods: {
    validateAndSubmit() {
      this.v$.$validate().then((result) => {
        if (result) this.$refs.contactUsForm.submit();
      });
    },
  },
};
</script>
