<template>
  <div v-if="hasValue" qaid="serial-search-results">
    <p class="text-md mb-2">
      {{ $t('serialLookup.B2B.matchingBike') }}
    </p>
    <table class="b2b-table b2b-table--compact w-auto mb-2">
      <thead>
        <tr>
          <th id="model">{{ $t('text.model') }}</th>
          <th id="year">{{ $t('contactUs.year') }}</th>
          <th id="colour">{{ $t('product.variants.colour') }}</th>
          <th id="size">{{ $t('product.variants.size') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-html="value.model" />
          <td v-html="value.year" />
          <td v-html="value.color" />
          <td v-html="value.size" />
        </tr>
      </tbody>
    </table>
    <div class="buttons">
      <trek-button qaid="serial-search-view-bike-button" type="submit" variants="primary" @click="navigateToProduct">
        {{ $t('text.viewBike') }}
      </trek-button>
    </div>
  </div>
</template>
<script>
import {Url} from '@/utils/url';
import TrekButton from '@/components/TrekButton';

export default {
  name: 'TechInfoSerialSearchResults',
  components: {TrekButton},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasValue() {
      return this.value && Object.keys(this.value).length > 0;
    },
  },
  methods: {
    navigateToProduct() {
      window.location.href = Url.withContextPath(this.value.url);
    },
  },
};
</script>
