import CmsApi from '@/api/cms';
import {onMounted, ref} from 'vue';

export const useCmsComponent = (componentId) => {
  const componentData = ref({});
  const isLoading = ref(false);

  onMounted(async () => {
    try {
      isLoading.value = true;
      const data = await CmsApi.getComponentById(componentId);
      componentData.value = data;
    } catch (error) {
      console.error(`Failed to fetch cms component ${componentId}`, error);
    } finally {
      isLoading.value = false;
    }
  });

  return {componentData, isLoading};
};
