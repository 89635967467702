<template>
  <div>
    <div v-if="hasProducts" class="grid-x grid-margin-x small-up-1 medium-up-2 large-up-3">
      <product-card-item
        v-for="(product, i) in products"
        :key="`${settings.locationId}${product.code}`"
        :item-id="`${i}`"
        :is-compare-button-enabled="isCompareButtonEnabled"
        :product="product"
      />
    </div>
  </div>
</template>
<script>
import ProductsList from './mixins/products-list';
import ProductCardItem from './ProductCardItem';

export default {
  components: {
    ProductCardItem,
  },
  mixins: [ProductsList],
};
</script>
