<template>
  <div>
    <span v-if="minQtyErrorPresent">{{ $t('distributor.B2B.skuGrid.errorMessage') }}</span>

    <table class="b2b-grid b2b-grid--collapse is-compact" qaid="saved-list-sku-grid">
      <!-- SKU Grid Headers -->
      <thead class="b2b-grid__header">
        <tr>
          <th class="b2b-grid__cell b2b-grid__head w-4">
            <span
              :qaid="`sku-grid-checkbox-${baseProduct.baseProduct}`"
              role="button"
              tabindex="0"
              @click.prevent.stop="checkAll"
              @keyup.enter.prevent.stop="checkAll"
            >
              <grid-checkbox :label="baseProduct.baseProduct" :checked="isAllChecked" />
            </span>
          </th>
          <th class="b2b-grid__cell b2b-grid__head w-5" qaid="sku-grid.icon"></th>

          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="sku-grid.sku">
            {{ $t('text.account.savedLists.sku') }}
          </th>

          <th v-if="isProjectOne" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid.item">{{ $t('order.item') }}</th>
          <th v-else class="b2b-grid__cell b2b-grid__head uppercase" qaid="sku-grid.upc-ean">
            {{ $t('text.account.savedLists.upc') }}
          </th>

          <th
            v-if="isCustomWaterBottle || isProjectOne"
            class="b2b-grid__cell b2b-grid__head lg:text-right"
            qaid="sku-grid-custom-id"
          >
            <span>{{ $t('checkout.custom.ID') }}</span>
          </th>
          <template v-else>
            <th class="b2b-grid__cell b2b-grid__head lg:text-right" qaid="sku-grid.allocated">
              <span
                v-tippy="{
                  html: '#abbreviation-text',
                  trigger: 'mouseenter',
                }"
              >
                {{ $t('backordersAllocated.B2B.alloc') }}
              </span>
            </th>

            <th class="b2b-grid__cell b2b-grid__head lg:text-right" qaid="sku-grid.backordered">
              <span
                v-tippy="{
                  html: '#bo-abbreviation-text',
                  trigger: 'mouseenter',
                }"
              >
                {{ $t('backordersAllocated.B2B.bo') }}
              </span>
            </th>
          </template>

          <!-- Variable variant headers -->
          <template v-for="(variant, variantIndex) in variantHeaders">
            <th :key="variantIndex" class="b2b-grid__cell b2b-grid__head">
              <span v-if="variant.name === 'Size'" qaid="sku-grid.size">{{ $t('text.variant.size') }}</span>

              <span v-else-if="variant.name === 'Color'">{{ $t('text.variant.color') }}</span>

              <span v-else>
                <!-- Unknown variant - Printing the raw name -->
                {{ variant.name }}
              </span>
            </th>
          </template>

          <th
            v-if="!isConsumerFriendlyMode && !isProjectOne"
            class="b2b-grid__cell b2b-grid__head"
            qaid="sku-grid.order-price"
          >
            {{ $t('basket.page.price') }}
          </th>

          <th class="b2b-grid__cell b2b-grid__head" qaid="sku-grid.package-quantity">
            {{ $t('text.account.savedLists.qty') }}
          </th>

          <th v-if="!isConsumerFriendlyMode" class="b2b-grid__cell b2b-grid__head text-right" qaid="sku-grid.subtotal">
            {{ $t('text.order.subtotal') }}
          </th>

          <th class="b2b-grid__cell b2b-grid__head w-5" qaid="sku-grid.trash"></th>
        </tr>
      </thead>
      <span id="abbreviation-text" class="hidden">{{ $t('text.allocated') }}</span>
      <span id="bo-abbreviation-text" class="hidden">{{ $t('text.backordered') }}</span>
      <!-- SKU Grid Body/Rows -->
      <tbody>
        <!-- For each Product/Row -->
        <cart-product-grid-row
          v-for="(product, productIndex) in sortedProducts"
          :key="`${productIndex}-${product.cartEntryPk}`"
          :base-product="baseProduct"
          :base-product-index="baseProductIndex"
          :product="product"
          :min-qty-error="minQtyError(product)"
          :min-qty-error-msg="minQtyErrorMessage(product)"
          :row-index="productIndex"
          :is-custom-water-bottle="isCustomWaterBottle"
          :is-project-one="isProjectOne"
          @open-confirm-delete-dialog="openConfirmDeleteDialog"
          @send-cart-update="sendCartUpdate"
        />
      </tbody>
    </table>

    <pdl-dialog
      :visible.sync="dialogVisible"
      :title="$t('myCart.B2B.remove.confirm')"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      :z-index="16000011"
      qaid="delete-cart-item-dialog"
      @close="resetDeleteData"
    >
      <template slot="footer">
        <div class="buttons buttons--right">
          <trek-button alert qaid="confirm-delete-button" @click="confirmDelete">
            <span>{{ $t('myCart.B2B.remove') }}</span>
          </trek-button>
          <trek-button secondary qaid="not-remove-button" @click="dialogVisible = false">
            <span>{{ $t('myCart.B2B.doNotRemove') }}</span>
          </trek-button>
        </div>
      </template>
    </pdl-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapMutations, mapGetters, mapState} from 'vuex';
import TrekButton from '@/components/TrekButton';
import {PdlDialog} from '@pedal/pdl-dialog';
import GridCheckbox from '../grid/GridCheckbox';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import CartProductGridRow from '@/components/cart/CartProductGridRow';
import {SortedProducts} from '@/components/containers/bulk-product/mixins/sorted-products';
import {resolveToastType} from '@/constants/meta-feedback-type';

export default {
  components: {TrekButton, PdlDialog, GridCheckbox, CartProductGridRow},

  mixins: [SortedProducts],
  props: {
    baseProduct: {
      type: Object,
      default: () => null,
    },
    baseProductIndex: {
      type: Number,
      default: 0,
    },
    partType: {
      type: String,
      default: '',
    },
    isCustomWaterBottle: {
      type: Boolean,
      default: false,
    },
    isProjectOne: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialogVisible: false,
      itemToDeleteElement: null,
      itemDeleted: false,
      minQtyFailList: {},
      minQtyErrorPresent: false,
      isAllChecked: false,
    };
  },

  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode']),
    ...mapState('cart', ['containerFullErrorToast']),
    ...mapGetters('cart', ['selectedProducts']),

    sortedProducts() {
      return this.getSortedProducts(this.baseProduct.entries);
    },

    variantHeaders() {
      const variantItem = this.baseProduct;
      const categoriesObject = get(variantItem, 'entries[0].variantValueCategories[0]');

      if (categoriesObject) {
        const categories = Object.keys(categoriesObject);
        let variants = [];

        categories.forEach(function (category) {
          if (variantItem.groupBy != category) {
            variants.push({name: category});
          }
        });

        return variants;
      }

      return null;
    },
  },

  watch: {
    selectedProducts() {
      this.isAllChecked = this.isEveryEntrySelected();
    },
    containerFullErrorToast(newValue) {
      if (Object.keys(newValue).length) {
        this.$notify({
          type: resolveToastType(newValue.detail.feedback?.type),
          message: newValue.message?.basePropertyValue,
          showClose: true,
          duration: newValue.type?.toastDuration,
        });
        this.setContainerFullErrorToast({});
      }
    },
  },

  mounted() {
    this.isAllChecked = this.isEveryEntrySelected();
  },

  methods: {
    checkAll() {
      this.isAllChecked = !this.isAllChecked;
      if (this.isAllChecked) {
        this.baseProduct.entries.forEach((entry) => {
          this.addSelectedProduct({
            sku: entry.sku,
            qty: this.getProductQuantity(entry),
            productType: entry.productType,
            lineNote: entry.lineNote,
            productRecipeID: entry.productRecipeID,
            cartEntryPk: entry.cartEntryPk,
          });
        });
      } else {
        this.baseProduct.entries.forEach((entry) => {
          this.removeSelectedProduct(entry.cartEntryPk);
        });
      }
    },

    getProductQuantity(product) {
      return reduce(product.warehouses, (result, warehouse) => result + parseInt(warehouse.orderedQty), 0);
    },

    isEveryEntrySelected() {
      return this.baseProduct.entries.every(
        (entry) => !!this.selectedProducts.find((product) => entry.cartEntryPk === product.cartEntryPk)
      );
    },

    openConfirmDeleteDialog(element) {
      this.dialogVisible = true;
      this.itemToDeleteElement = element;
    },

    // This is called when the confirmation dialog closes
    resetDeleteData() {
      this.dialogVisible = false;

      if (!this.itemDeleted) {
        this.itemToDeleteElement.value = this.itemToDeleteElement.dataset.initialValue;
      }

      this.itemDeleted = false;
      this.itemToDeleteElement = null;
    },

    confirmDelete() {
      this.dialogVisible = false;
      this.itemDeleted = true;
      const sku = this.itemToDeleteElement.dataset.sku;
      const cartEntryPk = this.itemToDeleteElement.dataset.pk;
      const productRecipeID = this.itemToDeleteElement.dataset.productRecipeId;

      this.minQtyErrorPresent = false;
      this.minQtyFailList = {};

      this.updateCart([{sku: sku, qty: 0, productRecipeID: productRecipeID, cartEntryPk: cartEntryPk}], true)
        .then((response) => {
          let messages = (get(response, 'data.data.failedSKUs') || []).map((item) => item.message.basePropertyValue);
          let cartEntryPks = (get(response, 'data.data.failedSKUs') || []).map((item) => item.cartEntryPk);

          this.updateFailedPks(messages, cartEntryPks);
        })
        .finally(() => {
          this.fetchCart().then(() => {
            this.removeSelectedProduct(cartEntryPk);
            this.isAllChecked = this.isEveryEntrySelected();
          });
          this.fetchMiniCart();
        });
    },

    updateFailedPks(messages, cartEntryPks) {
      this.minQtyFailList = {};

      if (cartEntryPks.length) this.minQtyErrorPresent = true;

      for (let i = 0; i < cartEntryPks.length; i++) {
        Vue.set(this.minQtyFailList, cartEntryPks[i], messages[i]);
      }
    },

    async sendCartUpdate(cartData) {
      this.minQtyErrorPresent = false;
      this.minQtyFailList = {};

      let updateCartResponse = await this.updateCart([...cartData]);
      const messages = (updateCartResponse.data.data?.failedSKUs || []).map((item) => item.message.basePropertyValue);
      const cartEntryPks = (updateCartResponse.data.data?.failedSKUs || []).map((item) => item.cartEntryPk);
      this.updateFailedPks(messages, cartEntryPks);

      await this.fetchMiniCart();
    },

    minQtyError(product) {
      return !!(this.minQtyFailList && this.minQtyFailList[product.cartEntryPk]);
    },

    minQtyErrorMessage(product) {
      return this.minQtyFailList[product.cartEntryPk];
    },

    ...mapMutations('savedLists', ['setEntries']),
    ...mapMutations('cart', ['setContainerFullErrorToast']),

    ...mapActions('cart', [
      'removeProducts',
      'undoRemove',
      'updateCart',
      'fetchCart',
      'removeSelectedProduct',
      'addSelectedProduct',
    ]),

    ...mapActions('miniCart', ['fetchMiniCart']),
  },
};
</script>
