<template>
  <div id="zoovu-assistant"></div>
</template>

<script>
import {Url} from '@/utils/url.js';
import {mapState} from 'vuex';
import headTag from '@/utils/head-tag';

export default {
  props: {
    sizeLinkGuide: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapState(['locale']),
    ...mapState('pdp', ['product']),
    currentLocale() {
      return this.locale?.toLowerCase().replace('_', '-') ?? 'en-us';
    },
  },
  watch: {
    product(value) {
      this.init(value);
    },
  },
  mounted() {
    this.init(this.product);
  },
  methods: {
    init(value) {
      const {id} = this.addUrlParams(value);
      if (!this.isLoaded && id?.length) {
        this.appendScript(id);
      }
    },
    async appendScript(id) {
      await headTag.appendAsyncScript(
        id,
        `https://api-barracuda.zoovu.com/api/v1/advisors/${id}/js-loader?locale=${this.currentLocale}`
      );
      this.isLoaded = true;
    },
    resetSizeFinderParams() {
      let updatedUrl = Url.update(window.location.href, undefined, {removeKeys: ['step', 'section']});
      const queryParams = Url.parse(updatedUrl);

      // we need unique logic to remove the aid[xxx] params
      const sizeFinderRegex = new RegExp(/aid(\[|%5B)\d+(\]|%5D)/g);
      const filteredQueryParams = Object.entries(queryParams).filter((key) => {
        return sizeFinderRegex.test(key);
      });

      updatedUrl = Url.update(updatedUrl, undefined, {removeKeys: filteredQueryParams.map((entry) => entry[0])});
      window.history.replaceState(null, document.title, updatedUrl);
    },
    addUrlParams(product) {
      this.resetSizeFinderParams();

      const {id, aidId, categoryId} = product?.zoovuSizeFinder ?? {};
      if (aidId && categoryId) {
        const aidValue = `aid[${aidId}]`;
        const urlCode = `${encodeURIComponent(aidValue)}=${categoryId}&step=1`;
        window.history.replaceState(null, document.title, Url.appendUrl(window.location.href, urlCode));
        return {
          id,
          aidId,
          categoryId,
        };
      }

      return this.addUrlParamsLegacy();
    },
    // To be removed in TBN-8156
    addUrlParamsLegacy() {
      let bikeCategory = '';
      if (window.location.href.indexOf('road') !== -1) {
        bikeCategory = '122747';
      } else if (window.location.href.indexOf('mountain') !== -1) {
        bikeCategory = '122746';
      } else if (
        window.location.href.indexOf('city') |
        window.location.href.indexOf('hybrid') |
        (window.location.href.indexOf('commuter') !== -1)
      ) {
        bikeCategory = '122745';
      }
      if (window.location.pathname.indexOf('electra') === -1) {
        const urlCode = `${encodeURIComponent('aid[39899]')}=${bikeCategory}&step=1`;
        window.history.replaceState(null, document.title, Url.appendUrl(window.location.href, urlCode));
        return {
          id: 'xSTSZBsF',
          aidId: '39899',
          category: bikeCategory,
        };
      }
    },
  },
};
</script>
