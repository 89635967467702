import mapboxgl from 'mapbox-gl';
import headTag from '@/utils/head-tag';

const MapFactory = (options) => {
  headTag.appendLink('mapboxStyles', 'https://api.mapbox.com/mapbox-gl-js/v2.10.0/mapbox-gl.css', 'stylesheet');

  if (!options.accessToken) {
    return console.error('Error: Mapbox did not receive access token.');
  }

  const DEFAULT_MAP_ZOOM = 16;
  const DEFAULT_MARKER_COLOR = '#f11502';

  const defaultOptions = {
    container: 'mapContainer',
    center: [0, 0],
    style: 'mapbox://styles/mapbox/streets-v11', // default style
    zoom: DEFAULT_MAP_ZOOM,
  };

  const defaultMarkerOptions = {
    color: DEFAULT_MARKER_COLOR,
    draggable: false,
  };

  const mapInstance = new mapboxgl.Map({
    accessToken: options.accessToken,
    ...defaultOptions,
    ...options, //override default options
  });

  // New functions go here, and they should always return 'map' when updating/setting any option of the current 'mapInstance'. This enables chaining multiple methods.
  const mapFunctions = {
    updateMapCenter: function (coordinates) {
      mapInstance.jumpTo({center: coordinates});
      return map;
    },
    includeZoomControl: function (position = 'bottom-right') {
      mapInstance.addControl(new mapboxgl.NavigationControl(), position);
      return map;
    },
    addFullscreenControl: function () {
      const fullScreenControl = new mapboxgl.FullscreenControl();
      mapInstance.addControl(fullScreenControl);
      return map;
    },
    addMapEventHandlers: function ({events = {}}) {
      handleMapEvents(events);
    },
    addInteractionOnLayer(interactionType, layerId, interactionHandler) {
      mapInstance.on(interactionType, layerId, interactionHandler);
      return map;
    },
    addMapAttribution({position = 'bottom-right'}) {
      mapInstance.addControl(new mapboxgl.AttributionControl(), position);
      return map;
    },
    // START: Mapbox Marker related functions
    addMarker: function ({position = defaultOptions.center, markerOptions = {}, events = {}, controlPointIndex = 0}) {
      const marker = new mapboxgl.Marker({
        ...defaultMarkerOptions,
        ...markerOptions,
      })
        .setLngLat(position)
        .addTo(mapInstance);
      handleMarkerEvents(events, marker);
      controlPointIndex ? map.markerList.splice(controlPointIndex, 0, marker) : map.markerList.push(marker);
      return marker;
    },

    removeMarker: function (index) {
      if (index < 0) return;
      map.markerList[index]?.remove();
      map.markerList.splice(index, 1);
      return map;
    },

    removeMarkerByReference: function (marker) {
      if (!marker) return;
      const markerIndex = map.markerList.findIndex((currentMarker) => {
        if (marker === currentMarker) return true;
      });
      map.removeMarker(markerIndex);
    },

    addPopupToMarker: function ({popupHtml = null, marker = null, eventListenerObj = {}}) {
      const popup = createPopup(popupHtml);
      marker.setPopup(popup);
      const popupEl = marker.getPopup()._content;

      Object.entries(eventListenerObj).forEach(([listenerKey, listenerFn]) => {
        popupEl.addEventListener(listenerKey, listenerFn);
      });
      return map;
    },
    // END: Mapbox Marker related functions

    removeAllMapComponents: function () {
      map.clearMarkers();
      map.mapInstance.remove();
    },

    clearMarkers: function () {
      map.markerList.forEach((marker) => {
        marker.remove();
      });
      map.markerList = [];
    },

    closeAllPopups: function () {
      // Ensures only 1 popup open at a time
      map.markerList.forEach((marker) => {
        if (marker.getPopup()?.isOpen()) marker.togglePopup();
      });
    },
  };

  // START: Utils/helper functions
  function handleMapEvents(events) {
    if (events.onClick) mapInstance.on('click', events.onClick);
  }

  function handleMarkerEvents(events, marker) {
    if (events.onDragEnd) marker.on('dragend', events.onDragEnd);
    if (events.onDrag) marker.on('drag', events.onDrag);
  }

  function createPopup(popupHtml) {
    // Set popup defaults
    const markerPopup = new mapboxgl.Popup({offset: 15, closeButton: false}).setMaxWidth('400px');
    const popup = markerPopup.setHTML(popupHtml);
    return popup;
  }
  // END: Utils/helper functions

  const map = {mapInstance, markerList: [], ...mapFunctions};

  return map;
};

export default MapFactory;
