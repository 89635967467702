var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"table-row",class:[{'table-row--parent': _vm.row.hasBackorderedItems}, {'is-expanded': _vm.isExpanded}]},[_vm._l((_vm.grid.filteredColumns),function(column){return [(_vm.showColumn(column))?_c('td',{key:`${_vm.row.id}-${column.key}`,staticClass:"b2b-grid__cell lg:table-cell",class:[
        column.key,
        column.cssClasses,
        {'mb-4 md:mb-0': _vm.row.hasBackorderedItems && column.key === 'backOrders'},
      ]},[_c('div',{staticClass:"b2b-grid__label"},[_vm._v(_vm._s(column.label))]),_vm._v(" "),_c('div',{staticClass:"b2b-grid__content"},[(column.key === 'notShippable')?_c('not-shippable-cell',{attrs:{"row-id":_vm.row.id,"type-id":_vm.productTypeId,"is-green-shipping":_vm.row.isGreenShipping,"is-allowed-to-ship":_vm.row.isAllowedToShip,"quantity-allocated-or-shipped":_vm.row.quantityAllocatedOrShipped}}):(column.key === 'cancelDate')?_c('div',{staticClass:"flex"},[(_vm.row[column.key] && _vm.row[column.key].length > 0)?_c('pdl-icon',{staticClass:"text-warning mr-25",attrs:{"name":"warning","size":"18"}}):_vm._e(),_vm._v("\n          "+_vm._s(_vm.row.cancelDate)+"\n        ")],1):(column.key === 'warehouses')?_c('stock-cell',{attrs:{"warehouses":_vm.row.warehouses}}):(column.key === 'backOrders')?_c('div',[_c('button',{on:{"click":function($event){$event.preventDefault();_vm.isExpanded = !_vm.isExpanded}}},[_c('pdl-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.row.hasBackorderedItems || _vm.row.hasAllocatedItems),expression:"row.hasBackorderedItems || row.hasAllocatedItems"}],attrs:{"name":_vm.isExpanded ? 'indeterminate_check_box' : 'add_box',"size":"18"}})],1)]):(column.key === 'orderNumber')?_c('a',{staticClass:"underline hover:no-underline",attrs:{"href":_vm.orderLinkUrl}},[_vm._v("\n          "+_vm._s(_vm.row[column.key])+"\n        ")]):(column.key === 'etaWatch')?_c('grid-eta-watch',{attrs:{"eta-item":_vm.row}}):(column.key === 'lineNote')?_c('div',[(!_vm.row.isConsumer)?_c('grid-note',{attrs:{"data":{
              value: 'note_add',
              editable: true,
              content: _vm.lineComment ? _vm.lineComment : '',
              hasNotification: !!_vm.lineComment,
            },"item-id":_vm.row.id,"max-length":120},on:{"note-added":_vm.addNote}}):_vm._e()],1):(column.key === 'itemDescription')?_c('div',{staticClass:"flex"},[(_vm.row.productType === 'Project One Bikes')?_c('div',{staticClass:"contents"},[_c('div',{directives:[{name:"tippy-html",rawName:"v-tippy-html"}],attrs:{"id":`p1-tooltip-icon-${_vm.productTypeId}-${_vm.row.id}`}},[_vm._v("\n              "+_vm._s(_vm.$t('backordersAllocated.B2B.p1'))+"\n            ")]),_vm._v(" "),_c('pdl-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippyOptions),expression:"tippyOptions"}],staticClass:"flex-none self-center cursor-pointer mr-1",attrs:{"qaid":`p1-bike-color-wheel-${_vm.productTypeId}-${_vm.row.id}`,"name":"project_one_colorwheel","size":"18"}})],1):(_vm.row.productSubType === 'P1BTO')?_c('project-one-bto-icon',{attrs:{"id":`${_vm.productTypeId}-${_vm.row.id}`}}):_vm._e(),_vm._v("\n          "+_vm._s(_vm.row.product.itemName)+"\n        ")],1):(column.key === 'sku')?_c('sku-cell',{attrs:{"sku":_vm.row.product.code,"product-type":_vm.row.productType}}):(column.key === 'price')?_c('span',[_vm._v(_vm._s(_vm.row.basePrice.formattedValue))]):(column.key === 'sold' && _vm.row.isEligibleForProductPriority)?[_c('toggle-switch',{attrs:{"qaid":"history-grid-sold-toggle","value":_vm.alreadySoldToConsumer,"disabled":_vm.shouldDisable,"sync":true},on:{"change":_vm.onSoldToggle}}),_vm._v(" "),_c('grid-sold-options',{ref:"soldPopover",refInFor:true,attrs:{"item":_vm.itemData,"row-id":_vm.row.id},on:{"tooltip-closed":_vm.updateBackOrderQty,"add-note":_vm.onNoteAdd}})]:_c('span',[_vm._v(_vm._s(_vm.row[column.key]))])],2)]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }