<!-- B2C Checkout Summary Container -->
<script>
/* Store */
import {mapMutations, mapState} from 'vuex';

/* Components */
import AlabamaTaxIconDialog from '@/components/cart/AlabamaTaxIconDialog.vue';
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlLabel} from '@pedal/pdl-label';
import {PdlHeading} from '@pedal/pdl-section-header';
import TrekLink from '@/components/TrekLink';
import TrekButton from '@/components/TrekButton';
import ImageComponent from '@/components/ImageComponent';
import OrderReviewTermsAndConditions from '@/components/checkout/OrderReviewTermsAndConditions';
import OrderSummaryAside from '@/components/checkout/order-summary-aside';
import CartItemList from '@/components/cart/b2c/CartItemList.vue';
import CheckoutSummaryList from '@/components/cart/b2c/CheckoutSummaryList.vue';
import PaymentMethod from '@/components/checkout/PaymentMethod.vue';
import DeliveryDetails from '@/components/checkout/DeliveryDetails.vue';
import RetailerDetails from '@/components/checkout/RetailerDetails.vue';
import DeliveryMethod from '@/components/checkout/DeliveryMethod.vue';
import {PdlLink} from '@pedal/pdl-link';
import PaymentIconList from '@/components/PaymentIconList.vue';

/* Mixins */
import trackCheckout from '@/mixins/tracking/track-checkout';

export default {
  /* eslint-disable vue/no-unused-components */
  components: {
    AlabamaTaxIconDialog,
    PdlDialog,
    PdlHeading,
    PdlLink,
    TrekLink,
    TrekButton,
    ImageComponent,
    OrderReviewTermsAndConditions,
    OrderSummaryAside,
    PaymentMethod,
    DeliveryDetails,
    RetailerDetails,
    DeliveryMethod,
    PdlLabel,
    CartItemList,
    PaymentIconList,
    CheckoutSummaryList,
  },

  mixins: [trackCheckout],

  props: {
    cartData: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    ...mapState('dialogs', ['payerAuthDialogVisible']),
  },

  created() {
    // Sets the entries to the checkout store for Multi steps
    this.setupCartData();
  },

  methods: {
    ...mapMutations('checkout', ['setB2cCartData']),
    closePayerAuthDialog() {
      this.$store.commit('dialogs/setPayerAuthDialog', false);
    },

    /**
     * Handles setting the entries into the checkout store.
     */
    setupCartData() {
      if (this.cartData === null) return;

      this.setB2cCartData(this.cartData);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-summary-container {
  margin-bottom: 7.5rem;

  ::v-deep .order-summary {
    padding-top: 1.25rem;

    @include breakpoint(medium) {
      padding-top: 2.75rem;
    }
  }

  .order-consent {
    @include breakpoint(medium) {
      padding: rem-calc(40px);
    }

    @include breakpoint(large) {
      padding: $double-spacing;
      padding-top: 0;
    }
  }
}
</style>
