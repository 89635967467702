import LocatorQuickView from '@/components/locator/components/LocatorQuickView';

const QuickView = {
  components: {LocatorQuickView},
  computed: {
    isQuickViewVisible() {
      return this.quickViewId && this.quickViewId.length > 0 && this.quickViewStoreData;
    },
  },
  methods: {
    /**
     * Close quick view panel.
     */
    closeQuickView() {
      this.quickViewId = '';
    },

    /**
     * Open quick view panel.
     */
    openQuickView(id) {
      if (!id) {
        return;
      }

      this.quickViewId = id;
    },
  },
  data() {
    return {
      quickViewId: '',
    };
  },
};

export {QuickView};
