import {AllocatedBackorderSortCode, AllocatedBackorderSortOption} from '@/constants/allocated-backorder';

export default {
  data() {
    return {
      sortOptions: {
        [AllocatedBackorderSortCode.Sku]: {
          name: 'product.variants.sku',
          code: AllocatedBackorderSortCode.Sku,
          order: AllocatedBackorderSortOption[AllocatedBackorderSortCode.Sku].order,
        },
        [AllocatedBackorderSortCode.OrderNumber]: {
          name: 'text.account.orderHistory.page.sort.byOrderNumber',
          code: AllocatedBackorderSortCode.OrderNumber,
          order: AllocatedBackorderSortOption[AllocatedBackorderSortCode.OrderNumber].order,
        },
        [AllocatedBackorderSortCode.CustomerPO]: {
          name: 'text.poNumber',
          code: AllocatedBackorderSortCode.CustomerPO,
          order: AllocatedBackorderSortOption[AllocatedBackorderSortCode.CustomerPO].order,
        },
        [AllocatedBackorderSortCode.OrderDate]: {
          name: 'text.orderDate',
          code: AllocatedBackorderSortCode.OrderDate,
          order: AllocatedBackorderSortOption[AllocatedBackorderSortCode.OrderDate].order,
        },
        [AllocatedBackorderSortCode.RequestedDate]: {
          name: 'text.requestDate',
          code: AllocatedBackorderSortCode.RequestedDate,
          order: AllocatedBackorderSortOption[AllocatedBackorderSortCode.RequestedDate].order,
        },
        [AllocatedBackorderSortCode.UnitPrice]: {
          name: 'order.price',
          code: AllocatedBackorderSortCode.UnitPrice,
          order: AllocatedBackorderSortOption[AllocatedBackorderSortCode.UnitPrice].order,
        },
        [AllocatedBackorderSortCode.EstimatedShipDate]: {
          name: 'text.etaDate',
          code: AllocatedBackorderSortCode.EstimatedShipDate,
          order: AllocatedBackorderSortOption[AllocatedBackorderSortCode.EstimatedShipDate].order,
        },
        [AllocatedBackorderSortCode.QuantityAllocatedOrShipped]: {
          name: 'text.allocated',
          code: AllocatedBackorderSortCode.QuantityAllocatedOrShipped,
          order: AllocatedBackorderSortOption[AllocatedBackorderSortCode.QuantityAllocatedOrShipped].order,
        },
        [AllocatedBackorderSortCode.QuantityBackordered]: {
          name: 'text.backordered',
          code: AllocatedBackorderSortCode.QuantityBackordered,
          order: AllocatedBackorderSortOption[AllocatedBackorderSortCode.QuantityBackordered].order,
        },
        [AllocatedBackorderSortCode.ShipToNumber]: {
          name: 'text.storeNumber',
          code: AllocatedBackorderSortCode.ShipToNumber,
          order: AllocatedBackorderSortOption[AllocatedBackorderSortCode.ShipToNumber].order,
        },
      },
    };
  },
};
