<template>
  <pdl-heading :level="level" :size="size" :class="customClass">
    <ship-to-home-icon v-if="shippingType === FulfillmentOptionTypes.SHIP_TO_HOME" size="18" :qaid="qaid" />
    <pick-up-in-store-icon v-if="shippingType === FulfillmentOptionTypes.PICK_UP_IN_STORE" size="18" :qaid="qaid" />
    <premium-home-delivery-icon v-if="shippingType === FulfillmentOptionTypes.DEALER_DELIVERY" size="18" :qaid="qaid" />
    <slot />
  </pdl-heading>
</template>

<script>
import {PdlHeading} from '@pedal/pdl-section-header';
import ShipToHomeIcon from '@/components/containers/pdp/b2c/fulfillment-options/home-shipping/ShipToHomeIcon.vue';
import PickUpInStoreIcon from '@/components/containers/pdp/b2c/fulfillment-options/store-pick-up/PickUpInStoreIcon.vue';
import PremiumHomeDeliveryIcon from '@/components/containers/pdp/b2c/fulfillment-options/premium-home-delivery/PremiumHomeDeliveryIcon.vue';
import {FulfillmentOptionTypes} from '@/constants/fulfillment-options.js';

export default {
  components: {
    PdlHeading,
    ShipToHomeIcon,
    PickUpInStoreIcon,
    PremiumHomeDeliveryIcon,
  },
  props: {
    shippingType: {
      type: String,
      default: '',
    },
    qaid: {
      type: String,
      default: '',
    },
    level: {
      type: Number,
      default: 2,
    },
    size: {
      type: String,
      default: 'sm',
    },
    customClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      FulfillmentOptionTypes,
    };
  },
};
</script>
