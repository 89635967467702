<template>
  <div id="skuStockStatus" class="pdp-rpa-results mt-1">
    <div v-if="hasRetailers || hasRetailerData" qaid="pdp-rpa-results">
      <div v-if="hasRetailerData" qaid="rpa-disclaimer">
        <pdl-section-header size="sm">
          <template slot="content">
            <div class="sku-stock-status--messaging">
              <p class="sku-stock-status--messaging--disclaimer" qaid="rpa-disclaimer-text">
                <span>{{ $t('product.stockStatus.distance') }}</span>
                <a
                  class="your-location ml-25"
                  qaid="rpa-search-zipcode"
                  role="button"
                  tabindex="0"
                  @click="rpaModalClick"
                  @keyup.enter="rpaModalClick"
                >
                  {{
                    retailerData.locationPostalCode
                      ? retailerData.locationPostalCode
                      : $t('retailerProductAvailability.yourLocation')
                  }} </a
                >.
              </p>
            </div>
          </template>
        </pdl-section-header>
      </div>
      <div v-if="hasRetailers">
        <pdp-rpa-sku-stock-status
          v-for="retailer in retailerData.retailers.slice(0, 3)"
          :key="`retailer-${retailer.id}`"
          class="sku-stock-status--ibd w-full"
          qaid="pdp-rpa-retailer-results"
          :is-bike="isBike"
          :is-modal="false"
          :retailer="retailer"
        />
      </div>
      <trek-button secondary block icon="pin_drop" qaid="pdp-rpa-more-retailers-button" @click="rpaModalClick">
        <span>{{ $t('retailerProductAvailability.viewMoreRetailers') }}</span>
      </trek-button>
      <pdl-dialog
        :visible.sync="dialogVisible"
        :z-index="16000011"
        :title="$t('product.stockStatus.header')"
        qaid="pdp-modal-rpa-results"
        :close-dialog-tool-tip="$t('popup.close')"
        @close="close"
      >
        <pdp-rpa-modal-search
          v-model="postalCode"
          :class="{'mb-0': !b2b}"
          :retailer-data="retailerData"
          qaid="pdp-modal-rpa-search-results"
          @search="performSearch"
        />
        <div v-if="!b2b" class="mb-2">
          <toggle-switch
            id="rpa-instock-toggle-switch"
            name="rpa-instock-toggle-switch"
            :value="isRpaToggledQaid"
            :qaid="isRpaToggledQaid ? 'pdp-modal-rpa-toggle-switch-on' : 'pdp-modal-rpa-toggle-switch-off'"
            :text-label="$t('product.stockStatus.filter.inStock')"
            @change="rpaModalToggleSwitch"
          />
        </div>
        <div class="w-full mb-1">
          <div v-if="isLocationPostalCodeCorrect">
            <div class="b2b-scroll-list">
              <pdp-rpa-sku-stock-status
                v-for="retailer in retailerData.retailers"
                :key="`retailer-${retailer.id}`"
                class="sku-modal-status--ibd w-full"
                :is-bike="isBike"
                :retailer="retailer"
                :is-modal="isModal"
              />
            </div>
          </div>
          <div v-else>
            <div class="w-full text-center" qaid="pdp-modal-rpa-search-empty-results">
              <span>{{ $t('searchEmpty') }}</span>
              <br />
              <span>{{ $t('text.search.noResults') }}</span>
            </div>
          </div>
          <div v-if="isLocationPostalCodeCorrect" class="w-full text-center">
            <p class="text-xs italic text-gray-60" qaid="rpa-modal-disclaimer-text">
              {{ $t('product.stockStatus.updateDisclaimerB2B') }}
            </p>
          </div>
        </div>
      </pdl-dialog>
    </div>
    <div v-else>
      <div class="cart-find-retailer">
        <template v-if="!hasProduct || (!hasRetailerData && !product.retailerOnly && options.eCommAvailable)">
          <pdl-link
            id="find-trek-retailer-store-cta"
            button
            secondary
            block
            icon="pin_drop"
            :href="withContextPath('/store-finder/')"
            qaid="pdp-rpa-find-retailer-store"
          >
            <span>{{ $t('product.find.retailer.button') }}</span>
          </pdl-link>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import isArray from 'lodash/isArray';
import {Url} from '@/utils/url';
import {mapGetters, mapState} from 'vuex';
import PdpRpaSkuStockStatus from '@/components/containers/pdp/PdpRpaSkuStockStatus';
import {PdlDialog} from '@pedal/pdl-dialog';
import PdpRpaModalSearch from '@/components/containers/pdp/PdpRpaModalSearch';
import {Cookie} from '@/utils/cookie';
import TrekButton from '@/components/TrekButton';
import {PdlLink} from '@pedal/pdl-link';
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import {PdlSectionHeader} from '@pedal/pdl-section-header';
import {retailerActions} from '@/store/modules/retailer-search';
import Variant from '@/utils/variant-helpers';

export default {
  name: 'PdpRpaResults',
  components: {
    PdpRpaSkuStockStatus,
    PdlDialog,
    PdpRpaModalSearch,
    PdlLink,
    TrekButton,
    ToggleSwitch,
    PdlSectionHeader,
  },
  props: {
    isBike: {
      type: Boolean,
      required: true,
    },
    retailerData: {
      type: Object,
      default: () => null,
      required: false,
    },
    isLocationPostalCodeCorrect: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      default: () => null,
      required: false,
    },
    options: {
      type: Object,
      default: () => null,
      required: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      isRpaToggledQaid: this.rpaViewInStock,
      isModal: false,
      postalCode: this.retailerData?.locationPostalCode || Cookie.get('trek_rpa_user_search_phrase') || '',
    };
  },
  computed: {
    ...mapState('backend', ['b2b']),
    ...mapGetters('pdp', ['rpaViewInStock']),
    ...mapState('pdp', ['rpaViewInStock']),
    hasRetailers() {
      const retailers = this.retailerData?.retailers;
      return isArray(retailers) && retailers.length > 0;
    },
    hasRetailerData() {
      return this.retailerData && Object.keys(this.retailerData).length > 0;
    },
    hasProduct() {
      return this.product && Object.keys(this.product).length > 0;
    },
  },
  mounted() {
    this.isRpaToggledQaid = this.rpaViewInStock;
  },
  methods: {
    performSearch(locationPostalCode) {
      const variant = Variant(this.retailerData.sku, this.product, this.b2b).get();
      const payload = {
        variant,
        inStockOnly: this.isRpaToggledQaid,
        locationPostalCode: locationPostalCode || this.postalCode,
      };
      this.$store.dispatch(retailerActions.UPDATE_RETAILER_AVAILABILITY, payload);
    },
    withContextPath: (url) => Url.withContextPath(url),
    rpaModalClick() {
      if (this.isRpaToggledQaid) {
        this.performSearch();
      }
      this.isModal = true;
      this.dialogVisible = true;
      if (this.hasRetailers && this.isLocationPostalCodeCorrect) {
        this.postalCode = this.retailerData?.locationPostalCode;
      }
    },
    close() {
      this.dialogVisible = false;
      this.isModal = false;
    },

    rpaModalToggleSwitch() {
      this.performSearch();
      if (this.isRpaToggledQaid === true) {
        this.isRpaToggledQaid = false;
      } else {
        this.isRpaToggledQaid = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.b2b-scroll-list {
  max-height: 50vh;
}
</style>
