<template>
  <div v-if="!isLoading" class="delivery-mode-selector mb-2">
    <template v-if="hasShippingUpgrades">
      <pdl-collapse
        qaid="delivery-mode-collapse"
        header-classes="delivery-mode-selector-header"
        content-classes="flex flex-col bg-gray-05 p-2"
        default-active
      >
        <pdl-collapse-item qaid="delivery-mode-selector-shipping-speeds">
          <template #title>
            <div class="text-md font-medium">
              <shipping-icon qaid="delivery-mode-selector-shipping-icon" />
              <span qaid="delivery-mode-selector-title-text">{{ $t('checkout.multi.deliveryMethod.upgrade') }}</span>
            </div>
          </template>
          <div class="flex">
            <form-group
              :type="'radio'"
              name="delivery-modes"
              :multi-items="radioItems"
              :label="$t('text.deliveryMethod')"
              :show-label="false"
              :help-text="$t('checkout.multi.deliveryMethod.multiShipment')"
              :disabled="false"
              :required="false"
              :error="''"
              :warning="false"
              :value="cartFulfillmentSpeed"
              qaid="delivery-mode-selector"
              @change="updateDeliveryMode"
            />
          </div>
        </pdl-collapse-item>
      </pdl-collapse>
      <pdl-callout v-if="hasBike" qaid="delivery-mode-selector-callout">
        <template #content>{{ $t('checkout.multi.deliveryMethod.bikeShip') }}</template>
      </pdl-callout>
    </template>
  </div>
  <trek-skeleton v-else height="20rem" />
</template>

<script>
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {PdlCallout} from '@pedal/pdl-callout';
import {mapMutations, mapState} from 'vuex';
import {ShipToHomeMessages, DefaultShipToHomeModes} from '@/constants/delivery-modes.js';
import ShippingIcon from '@/icons/ShippingIcon.vue';
import FormGroup from '@/components/FormGroup.vue';
import TrekSkeleton from '@/components/TrekSkeleton.vue';

export default {
  name: 'CartDeliveryModeSelector',
  components: {PdlCollapse, PdlCollapseItem, PdlCallout, ShippingIcon, FormGroup, TrekSkeleton},
  props: {
    entries: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('cart', ['cartFulfillmentOptions', 'cartFulfillmentSpeed', 'cartData']),
    allDeliveryModes() {
      if (!this.cartFulfillmentOptions?.length) return [];
      return this.cartFulfillmentOptions
        .map((options) => {
          return options.fulfillmentOptions.flatMap((option) => {
            return option.deliveryModes;
          });
        })
        ?.flat();
    },
    hasBike() {
      return this.cartData?.hasBike;
    },
    isLoading() {
      return !this.cartFulfillmentOptions?.length;
    },
    hasShippingUpgrades() {
      if (this.radioItems?.length > 1) {
        this.setHasUpgradedShipping(true);
      }
      return this.radioItems?.length > 1;
    },
    radioItems() {
      if (!this.cartFulfillmentOptions?.length) return;

      const items = Object.keys(ShipToHomeMessages)
        .map((key) => {
          return this.allDeliveryModes.find((mode) => {
            return mode.code === key;
          });
        })
        .filter((m) => m);

      if (!items.length > 1) {
        return;
      }

      let compiledItems = Object.values(items).map((option) => {
        let isFree = option?.deliveryCost?.value === 0;
        return {
          value: option?.code,
          displayName: `<div class="flex justify-between"><div class="text-md--bold">${this.$t(
            ShipToHomeMessages[option?.code]
          )}</div><div qaid="delivery-mode-selector-price" class="text-md--bold">${
            isFree ? this.$t('text.free').toUpperCase() : option?.deliveryCost?.formattedValue
          }</div></div><div qaid="delivery-mode-selector-description" class="form-help">${option?.description}</div>`,
        };
      });

      return compiledItems;
    },
  },
  watch: {
    cartFulfillmentOptions: {
      handler() {
        this.updateDeliverySpeeds();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('cart', ['setCartFulfillmentSpeed', 'setHasUpgradedShipping']),
    updateDeliverySpeeds() {
      let speedFromEntryData = this.entries?.find((e) => Object.keys(ShipToHomeMessages).includes(e.deliveryMode?.code))
        ?.deliveryMode?.code;
      // If we don't have an entry with a value selected,
      // and the selected value is not in the list of available delivery modes,
      // we need to find the new default value (GROUND or GROUND_GROSS)
      if (!speedFromEntryData && !this.allDeliveryModes?.some((m) => m.code === this.cartFulfillmentSpeed)) {
        speedFromEntryData = this.allDeliveryModes.find((e) => DefaultShipToHomeModes.includes(e.code))?.code;
      }
      if (!speedFromEntryData) return;
      this.setCartFulfillmentSpeed(speedFromEntryData);
    },
    updateDeliveryMode(event) {
      this.setCartFulfillmentSpeed(event.target.value);
      this.$emit('delivery-mode-selected', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery-mode-selector {
  ::v-deep .delivery-mode-selector-header {
    background-color: var(--gray-05);
    padding: 1rem 0.5rem 0.5rem 1rem;
  }

  ::v-deep .radio {
    span:nth-of-type(2) {
      width: 100%;
    }
  }

  ::v-deep #delivery-modes-help {
    margin: 1.5rem 0 0 0;
  }

  ::v-deep .pdl-callout--default {
    margin: 1rem 0;
  }
}
</style>
