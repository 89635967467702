import {MaskInput} from './mask-input.js';

const masks = new WeakMap();

const checkValue = (input) => {
  setTimeout(() => {
    if (masks.get(input)?.needUpdateValue(input) === true) {
      input.dispatchEvent(new CustomEvent('input'));
    }
  });
};

export const maskDirective = (el, binding) => {
  const input = el instanceof HTMLInputElement ? el : el.querySelector('input');
  const opts = {...binding.arg} ?? {};

  if (input == null || input?.type === 'file') return;

  checkValue(input);

  const existed = masks.get(input);
  if (existed != null) {
    if (!existed.needUpdateOptions(input, opts)) {
      return;
    }
    existed.destroy();
  }

  masks.set(input, new MaskInput(input, opts));
};
