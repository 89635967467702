<template>
  <div>
    <pdl-heading class="mb-3" :level="1" size="sm">{{ $t('distributor.B2B.container.update') }}</pdl-heading>
    <div
      v-for="(container, index) in dropShipContainer.containers"
      :key="index"
      class="flex items-start justify-between mb-3"
    >
      <ContainerIndicator :container="container" :drop-ship-container="dropShipContainer" />
      <div class="flex items-center ml-6">
        <span :id="`quality-label-${index}`" class="text-md mr-2">{{ $t('text.quantity') }}</span>
        <input
          v-model.number="containersAmount[container.type]"
          type="number"
          class="form-control w-12"
          :aria-labelledby="`quality-label-${index}`"
          :class="{'border border-red': errorMessage}"
        />
      </div>
    </div>
    <pdl-callout v-if="errorMessage" class="max-w-xs" kind="error" qaid="containerConfigurationWarning">
      <template #content>
        <span v-html="errorMessage" />
      </template>
    </pdl-callout>
    <div class="buttons">
      <trek-button
        primary
        block
        :disabled="errorMessage"
        qaid="containerConfigurationConfirmButton"
        @click="submitContainers"
      >
        <span>{{ $t('distributor.B2B.container.confirm') }}</span>
      </trek-button>
    </div>
    <div class="buttons">
      <trek-button secondary block qaid="containerConfigurationCloseButton" @click="$emit('close')">
        <span>{{ $t('text.cancel') }}</span>
      </trek-button>
    </div>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {mapActions} from 'vuex';
import TrekButton from '@/components/TrekButton';
import {PdlCallout} from '@pedal/pdl-callout';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';
import {PdlHeading} from '@pedal/pdl-section-header';

export default {
  name: 'ContainerConfiguration',

  components: {
    ContainerIndicator: () => import('@/components/containers/drop-ship/ContainerIndicator'),
    PdlCallout,
    PdlHeading,
    TrekButton,
  },

  props: {
    dropShipContainer: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      responseErrorMessage: null,
      containersAmount: {},
    };
  },

  computed: {
    errorMessage() {
      if (this.responseErrorMessage) {
        return this.responseErrorMessage;
      } else if (Object.values(this.containersAmount).reduce((a, b) => a + b) < 1) {
        return this.$t('distributor.B2B.container.select');
      } else if (Object.values(this.containersAmount).reduce((a, b) => a + b) > 15) {
        return this.$t('distributor.B2B.container.maxContainer');
      }

      return null;
    },
  },

  watch: {
    dropShipContainer: {
      immediate: true,
      handler() {
        this.resetState();
      },
    },
  },

  methods: {
    resetState() {
      this.responseErrorMessage = null;
      this.dropShipContainer.containers.forEach((container) =>
        this.$set(this.containersAmount, container.type, container.totals.amount)
      );
    },

    submitContainers() {
      const payload = {
        containerQtyFortyHi: this.containersAmount['FORTY_HI_FEET'],
        containerQtyForty: this.containersAmount['FORTY_FEET'],
        containerQtyTwenty: this.containersAmount['TWENTY_FEET'],
      };

      this.responseErrorMessage = null;

      storefrontInstance
        .put('/containers', payload)
        .then((response) => {
          if (checkMetaFeedbackType(response.data, MetaFeedbackType.SUCCESS)) {
            this.$emit('close');
            this.fetchMiniCart();
            this.fetchCart();
          } else if (checkMetaFeedbackType(response.data, MetaFeedbackType.ERROR)) {
            this.responseErrorMessage = response.data?.meta?.feedback?.message?.basePropertyValue;
          } else {
            this.responseErrorMessage = this.$t('distributor.B2B.container.save.tryAgain');
          }
        })
        .catch((error) => {
          this.responseErrorMessage = this.$t('distributor.B2B.container.save.tryAgain');
          console.log(error);
        });
    },

    ...mapActions('cart', ['fetchCart']),
    ...mapActions('miniCart', ['fetchMiniCart']),
  },
};
</script>
