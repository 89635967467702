<template>
  <form qaid="apple-label-form" class="apple-label-form" @submit.prevent="saveAppleLabels">
    <pdl-loading :is-loading="isAppleLabelsLoading">
      <table
        aria-label="apple-label-table"
        qaid="apple-label-table"
        class="apple-label-table b2b-grid b2b-grid--collapse is-compact"
      >
        <!-- Headers -->
        <thead class="b2b-grid__header">
          <tr>
            <th
              v-for="column in getColumns()"
              :key="column.key"
              scope="col"
              class="b2b-grid__cell b2b-grid__head"
              :qaid="`apple-label-heading-${column.key}`"
              :v-if="column.key == 'advertisedPrice' ? `${column.isVisible}` : true"
              :class="[column.cssClasses, column.isVisible]"
            >
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <!-- Apple Labels Grid Body/Rows -->
        <tbody>
          <!-- For Each Product Row -->
          <apple-label-grid-item
            v-for="(item, itemIndex) in uniqSkuLocalItems"
            :key="item.sku + '_' + itemIndex"
            :errors="errors"
            :item="item"
            :columns="columns"
            :disable-toggles="!cartToggle"
            :item-index="itemIndex"
            @update-item="updateItem($event)"
            @update-is-dirty="updateIsDirty($event, item)"
          />
        </tbody>
      </table>

      <div class="buttons buttons--right-for-md">
        <trek-button
          primary
          small
          type="submit"
          :disabled="!isFormDirty || saveInProgress || formHasErrors"
          qaid="apple-label-button-save"
        >
          <span>{{ $t('text.saveChanges') }}</span>
        </trek-button>
        <trek-button secondary small qaid="apple-label-button-clear" @click="dialogVisible = true">
          <span>{{ $t('search.nav.clear') }}</span>
        </trek-button>
      </div>
    </pdl-loading>

    <pdl-dialog
      :visible.sync="dialogVisible"
      :title="$t('appleLabel.confirmClearChanges')"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      :z-index="16000011"
      :close-dialog-tooltip="$t('popup.close')"
      qaid="apple-label-change-dialog"
    >
      <p>{{ $t('text.allChangesWillBeLost') }}</p>

      <template slot="footer">
        <div class="buttons buttons--right">
          <trek-button alert qaid="yes-clear-changes-button" @click="clearChanges"
            ><span>{{ $t('text.yesClearChanges') }}</span></trek-button
          >
          <trek-button secondary qaid="go-back-button" @click="dialogVisible = false"
            ><span>{{ $t('text.noGoBack') }}</span></trek-button
          >
        </div>
      </template>
    </pdl-dialog>
  </form>
</template>

<script>
import uniqBy from 'lodash/uniqBy';
import {mapState, mapActions} from 'vuex';
import TrekButton from '@/components/TrekButton';
import AppleLabelGridItem from '@/components/checkout/v2/AppleLabelGridItem';
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlLoading} from '@pedal/pdl-loading';
import {ShipmentsApi} from '@/api/shipments';
import {PdlToastType} from '@/constants/pdl-toast-type';
import {useVuelidate} from '@vuelidate/core';

export default {
  components: {AppleLabelGridItem, PdlDialog, TrekButton, PdlLoading},

  props: {
    warehouseId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {v$: useVuelidate({$stopPropagation: true})};
  },

  data() {
    return {
      localItems: [],
      saveInProgress: false,
      formDirty: false,
      dialogVisible: false,
      keyCounter: 0,
      isAppleLabelsLoading: true,
    };
  },

  computed: {
    activeItems() {
      return this.uniqSkuLocalItems.filter((item) => {
        return item.dirty || item.retailLabelStatus;
      });
    },

    formHasErrors() {
      return !!this.v$.$errors.length;
    },

    isFormDirty() {
      const hasDirtyValidatedFields = this.v$.$anyDirty;
      const hasToggledOnRows = this.localItems ? this.localItems.some((item) => item.dirty) : false;
      return hasDirtyValidatedFields || hasToggledOnRows;
    },

    uniqSkuLocalItems() {
      return uniqBy(this.localItems, 'id');
    },

    columns() {
      return [
        {label: this.$t('appleLabel.skus'), key: 'sku', cssClasses: ''},
        {label: this.$t('appleLabel.label'), key: 'retailLabelStatus', cssClasses: ''},
        {
          label: this.$t('appleLabel.description'),
          key: 'retailLabelDescription',
          cssClasses: '',
        },
        {
          label: this.$t('appleLabel.partNumber'),
          key: 'retailLabelProductId',
          cssClasses: 'lg:w-64',
        },
        {label: this.$t('appleLabel.price'), key: 'retailLabelPrice', cssClasses: 'lg:w-64'},
        {label: this.$t('text.msrp'), key: 'advertisedPrice', cssClasses: 'lg:w-14'},
      ];
    },

    ...mapState('backend', ['cartToggle', 'isConsumerFriendlyMode']),
    ...mapState('shipments', ['entries', 'appleLabels']),
  },

  watch: {
    appleLabels: {
      immediate: true,
      handler() {
        this.localItems = this.getLocalItems();
      },
    },
    isConsumerFriendlyMode() {
      this.getColumns();
    },
  },

  async created() {
    await this.fetchAppleLabels({warehouseId: this.warehouseId, type: this.type});
    this.isAppleLabelsLoading = false;
    window.addEventListener('beforeunload', this.warnUser);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.warnUser);
  },

  methods: {
    getColumns() {
      this.columns.forEach((column) => {
        if (column.key == 'advertisedPrice') {
          column['isVisible'] = '';
          if (this.isConsumerFriendlyMode) {
            column.isVisible = 'hidden';
          } else {
            column.isVisible = 'table-cell';
          }
        }
      });
      return this.columns;
    },
    getLocalItems() {
      if (Object.keys(this.appleLabels).length > 0) {
        return structuredClone(this.appleLabels).map((item) => {
          this.$set(
            item,
            'initialRetailLabelStatus',
            this.item?.retailLabelStatus !== null ? item.retailLabelStatus : false
          );
          this.$set(item, 'initialRetailLabelPrice', item.retailLabelPrice);
          this.$set(item, 'dirty', false);

          return item;
        });
      }
    },

    resetLocalItems() {
      // return localItems to their initial state
      const initialItems = this.getLocalItems();

      this.localItems.forEach((item, index) =>
        Object.keys(item).forEach((key) => {
          item[key] = initialItems[index][key];
        })
      );
    },

    async saveAppleLabels() {
      // If the b2bCartToggle has turned off asyncronously,
      // send a toast, and don't attempt to save any data.
      if (!this.cartToggle) {
        this.clearChanges();

        return this.$notify({
          type: PdlToastType.ERROR,
          message: this.$t('text.systemDown.cannotSubmit'),
        });
      }

      this.saveInProgress = true;

      const isValid = await this.v$.$validate();
      if (!isValid) {
        this.saveInProgress = false;
        return;
      }

      const {data} = await ShipmentsApi.updateAppleLabels(this.activeItems);

      data.data.forEach((item) => {
        let targetRow = this.appleLabels.findIndex((localItem) => {
          return localItem.sku == item.sku;
        });
        this.$set(this.localItems[targetRow], 'initialRetailLabelPrice', item.price);
        this.$set(this.localItems[targetRow], 'initialRetailLabelStatus', item.status);
      });

      this.localItems.map((item) => {
        this.$set(item, 'retailLabelPrice', item.initialRetailLabelPrice);
        return item;
      });

      this.saveInProgress = false;
      this.v$.$reset();
      this.resetDirtyItems();
    },

    clearErrors(scope) {
      this.$children.forEach((child) => {
        if (child.$options._componentTag === 'apple-label-grid-item') {
          child.errors.clear(scope);
        }
      });
    },

    clearChanges() {
      this.keyCounter++;
      this.resetLocalItems();
      this.dialogVisible = false;
      this.v$.$reset();
      return this.errors.clear();
    },

    warnUser(e) {
      if (this.isFormDirty) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    },

    resetDirtyItems() {
      this.localItems.forEach((item) => (item.dirty = false));
    },

    updateItem(item) {
      const itemIndex = this.localItems.findIndex((localItem) => localItem.sku === item.sku);
      this.localItems[itemIndex] = item;
    },

    updateIsDirty(value, item) {
      const itemIndex = this.localItems.findIndex((localItem) => localItem.sku === item.sku);
      this.localItems[itemIndex].dirty = value;
    },

    ...mapActions('shipments', ['fetchAppleLabels']),
  },
};
</script>
