<template>
  <a target="_blank" :href="feed.link" qaid="blog-feed-link">
    <pdl-card>
      <img v-if="feed.imgURL" slot="image" :src="feed.imgURL" class="feed-item__image" qaid="blog-feed-image" alt="" />
      <template slot="bodyContent">
        <div class="flex items-center mb-2">
          <div v-if="feed.url" class="mr-1">
            <img :src="feed.url" qaid="blog-feed-badge" alt="" />
          </div>
          <div class="flex flex-col flex-auto leading-normal">
            <span v-if="feed.mainTitle" class="text-sm font-bold" qaid="blog-feed-creator" v-html="feed.mainTitle" />
            <span v-if="feed.pubDate" class="text-sm text-gray-60" qaid="blog-feed-date" v-html="feed.pubDate" />
          </div>
          <pdl-icon class="self-start text-gray-40" size="18" name="rss_feed" />
        </div>
        <h4 v-if="feed.title" qaid="blog-feed-title" class="heading heading--md mb-1" v-html="feed.title" />
        <p
          v-if="feed.description"
          qaid="blog-feed-description"
          class="text-sm leading-normal"
          v-html="feed.description"
        />
      </template>
    </pdl-card>
  </a>
</template>

<script>
import {PdlCard} from '@pedal/pdl-card';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'RssBlogItem',
  components: {PdlCard, PdlIcon},
  props: {
    feed: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.owl-stage {
  .feed-item {
    &__image {
      aspect-ratio: 4 / 3;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
