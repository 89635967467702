<template>
  <tr class="table-row" :class="[{'table-row--parent': row.hasBackorderedItems}, {'is-expanded': isExpanded}]">
    <!-- Each Cell -->
    <template v-for="column in grid.filteredColumns">
      <td
        v-if="showColumn(column)"
        :key="`${row.id}-${column.key}`"
        class="b2b-grid__cell lg:table-cell"
        :class="[
          column.key,
          column.cssClasses,
          {'mb-4 md:mb-0': row.hasBackorderedItems && column.key === 'backOrders'},
        ]"
      >
        <div class="b2b-grid__label">{{ column.label }}</div>

        <div class="b2b-grid__content">
          <!-- Not Shippable cell -->
          <not-shippable-cell
            v-if="column.key === 'notShippable'"
            :row-id="row.id"
            :type-id="productTypeId"
            :is-green-shipping="row.isGreenShipping"
            :is-allowed-to-ship="row.isAllowedToShip"
            :quantity-allocated-or-shipped="row.quantityAllocatedOrShipped"
          />
          <!-- Cancel Date cell -->
          <div v-else-if="column.key === 'cancelDate'" class="flex">
            <pdl-icon
              v-if="row[column.key] && row[column.key].length > 0"
              class="text-warning mr-25"
              name="warning"
              size="18"
            />
            {{ row.cancelDate }}
          </div>
          <!-- Stock cell -->
          <stock-cell v-else-if="column.key === 'warehouses'" :warehouses="row.warehouses" />
          <!-- Backorders cell -->
          <div v-else-if="column.key === 'backOrders'">
            <button @click.prevent="isExpanded = !isExpanded">
              <pdl-icon
                v-show="row.hasBackorderedItems || row.hasAllocatedItems"
                :name="isExpanded ? 'indeterminate_check_box' : 'add_box'"
                size="18"
              />
            </button>
          </div>
          <!-- Order Number cell -->
          <a v-else-if="column.key === 'orderNumber'" :href="orderLinkUrl" class="underline hover:no-underline">
            {{ row[column.key] }}
          </a>
          <!-- ETA Watch cell -->
          <grid-eta-watch v-else-if="column.key === 'etaWatch'" :eta-item="row" />
          <!-- line note cell -->
          <div v-else-if="column.key === 'lineNote'">
            <grid-note
              v-if="!row.isConsumer"
              :data="{
                value: 'note_add',
                editable: true,
                content: lineComment ? lineComment : '',
                hasNotification: !!lineComment,
              }"
              :item-id="row.id"
              :max-length="120"
              @note-added="addNote"
            />
          </div>
          <!-- Description cell -->
          <div v-else-if="column.key === 'itemDescription'" class="flex">
            <div v-if="row.productType === 'Project One Bikes'" class="contents">
              <div :id="`p1-tooltip-icon-${productTypeId}-${row.id}`" v-tippy-html>
                {{ $t('backordersAllocated.B2B.p1') }}
              </div>
              <pdl-icon
                v-tippy="tippyOptions"
                class="flex-none self-center cursor-pointer mr-1"
                :qaid="`p1-bike-color-wheel-${productTypeId}-${row.id}`"
                name="project_one_colorwheel"
                size="18"
              />
            </div>
            <project-one-bto-icon v-else-if="row.productSubType === 'P1BTO'" :id="`${productTypeId}-${row.id}`" />
            {{ row.product.itemName }}
          </div>
          <!-- SKU cell -->
          <sku-cell v-else-if="column.key === 'sku'" :sku="row.product.code" :product-type="row.productType" />
          <span v-else-if="column.key === 'price'">{{ row.basePrice.formattedValue }}</span>

          <template v-else-if="column.key === 'sold' && row.isEligibleForProductPriority">
            <toggle-switch
              qaid="history-grid-sold-toggle"
              :value="alreadySoldToConsumer"
              :disabled="shouldDisable"
              :sync="true"
              @change="onSoldToggle"
            />

            <grid-sold-options
              ref="soldPopover"
              :item="itemData"
              :row-id="row.id"
              @tooltip-closed="updateBackOrderQty"
              @add-note="onNoteAdd"
            />
          </template>

          <!-- All other cells -->
          <span v-else>{{ row[column.key] }}</span>
        </div>
      </td>
    </template>
  </tr>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import NotShippableCell from '@/components/order-item/NotShippableCell';
import GridEtaWatch from '@/components/grid/GridEtaWatch';
import StockCell from '@/components/order-item/StockCell';
import SkuCell from '@/components/order-item/SkuCell';
import GridNote from '@/components/grid/GridNote';
import ProjectOneBtoIcon from '@/components/grid/icons/ProjectOneBtoIcon.vue';
import OrderItemApi from '@/api/order-item-api.js';
import {PdlIcon} from '@pedal/pdl-icon';
import ToggleSwitch from '@/components/ToggleSwitch';
import GridSoldOptions from '@/components/grid/GridSoldOptions';

export default {
  components: {
    GridNote,
    ProjectOneBtoIcon,
    NotShippableCell,
    GridEtaWatch,
    SkuCell,
    StockCell,
    PdlIcon,
    ToggleSwitch,
    GridSoldOptions,
  },
  inject: ['grid'],
  provide() {
    return {
      item: this,
    };
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
      shouldDisable: this.row.alreadySoldToConsumer || this.row.quantityBackordered < 1,
      productTypeId: '',
    };
  },
  computed: {
    ...mapState('backend', ['encodedContextPath', 'isConsumerFriendlyMode', 'currentB2BUnit', 'currentUserEmail']),
    ...mapState('allocatedBackorder', ['savedState']),

    /**
     * Show a warning icon if any of the backordered items on an order has a cancel date
     * seven or fewer days away.
     */
    showCancelWarning() {
      if (!this.row.hasBackorderedItems) {
        return false;
      }

      const allItems = [...this.row.allocatedItems, ...this.row.notAllocatedItems];

      return allItems.some((el) => el.showCancelFlag);
    },

    orderLinkUrl() {
      const {orderNumber, orderType, orderCompany} = this.row;

      return `${this.encodedContextPath}/my-account/order/${orderNumber}?company=${orderCompany}&ordertype=${orderType}`;
    },

    tippyOptions() {
      return {
        html: `#p1-tooltip-icon-${this.productTypeId}-${this.row.id}`,
        interactive: true,
        reactive: true,
        trigger: 'mouseenter focus',
        flip: true,
        sticky: true,
        theme: 'light left-align',
      };
    },

    itemData() {
      if (!this.row.product) return null;
      return {
        sku: this.row.product.code,
        itemName: this.row.product.itemName,
        backorderedQty: this.row.quantityBackordered,
        lineNote: this.lineComment ? this.lineComment : '',
      };
    },

    alreadySoldToConsumer: {
      get() {
        return this.row.alreadySoldToConsumer;
      },
      set(value) {
        this.setAttributesByKey({
          value,
          id: this.row.id,
          key: 'alreadySoldToConsumer',
        });
      },
    },

    lineComment: {
      get() {
        return this.row.lineComment;
      },
      set(value) {
        this.setAttributesByKey({
          value,
          id: this.row.id,
          key: 'lineComment',
        });
      },
    },
  },
  watch: {
    isExpanded(value) {
      this.$emit('expand', value);
    },

    row() {
      this.shouldDisable = this.alreadySoldToConsumer || this.row.quantityBackordered < 1;
    },
  },
  created() {
    this.productTypeId = this.row.productType?.replace(/\s/g, '');
  },
  methods: {
    ...mapMutations('allocatedBackorder', ['setAttributesByKey', 'setSavedState']),

    showColumn(column) {
      const isPastOrdersWithBackOrders = this.grid.orderStatus === 'pastOrders' && column.key === 'backOrders';
      const isConsumerFriendlyMode =
        this.isConsumerFriendlyMode && (column.key === 'price' || column.key === 'orderTotal');

      return !isPastOrdersWithBackOrders && !isConsumerFriendlyMode;
    },

    onSoldToggle(event) {
      if (event?.value) {
        this.alreadySoldToConsumer = true;
        this.$refs?.soldPopover[0].openTooltip();
      } else {
        this.alreadySoldToConsumer = false;
        this.setSavedState(false);
      }
    },

    onNoteAdd() {
      this.setSavedState(true);
    },

    updateBackOrderQty({noteContent, qty}) {
      if (!this.savedState) {
        this.alreadySoldToConsumer = false;
        return;
      }
      this.alreadySoldToConsumer = true;

      this.backorderPrioritize({noteContent, qty});
    },

    async backorderPrioritize({noteContent, qty}) {
      const payload = {
        retailer: {
          id: this.currentB2BUnit,
        },
        user: {
          userName: this.currentUserEmail,
        },
        originalLine: {
          orderNumber: this.row.orderNumber,
          orderCompany: this.row.orderCompany,
          orderType: this.row.orderType,
          orderLineNumber: this.row.orderLineNumber,
          quantity: this.row.quantityOrdered,
        },
        newLines: [
          {
            quantity: qty,
            comment: noteContent,
            alreadySoldToConsumer: this.alreadySoldToConsumer,
          },
        ],
      };

      try {
        await OrderItemApi.backorderPrioritize(payload);
        this.setSavedState(false);
        this.lineComment = noteContent;
        this.shouldDisable = true;
      } catch (error) {
        console.log(error);
      }
    },

    async addNote(note) {
      const payload = {
        orderLineNumber: this.row.orderLineNumber,
        orderNumber: this.row.orderNumber,
        orderType: this.row.orderType,
        orderCompany: this.row.orderCompany,
        lineComment: note.content,
      };
      try {
        await OrderItemApi.updateComment(payload);
        this.lineComment = note.content;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
