<template>
  <div v-cloak class="grid-container text-md mb-6">
    <pdl-loading top :is-loading="loading">
      <!-- Header -->
      <section>
        <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
          <template slot="content">
            <pdl-heading :level="1" qaid="businessUnitDetails.header">{{ bp('storeDetailsTitle') }}</pdl-heading>
          </template>
        </pdl-section-header>
      </section>

      <!-- Store Details -->
      <section>
        <div class="grid-x grid-margin-x grid-margin-y">
          <div class="cell large-4">
            <div class="font-medium mb-1" qaid="businessUnitDetails.accountNumberLabel">{{ bp('accountNumber') }}</div>
            <div class="mb-3 text-gray-80" qaid="businessUnitDetails.accountNumber">{{ accountNumber }}</div>
            <div class="font-medium mb-1" qaid="businessUnitDetails.storeNameLabel">{{ bp('storeName') }}</div>
            <div class="mb-3 text-gray-80" qaid="businessUnitDetails.storeName">{{ storeName }}</div>
          </div>
        </div>
      </section>

      <!-- Address -->
      <section>
        <pdl-section-header size="md" :is-dividing="true" class="mt-5 mb-3">
          <template slot="content">
            <pdl-heading :level="1" qaid="businessUnitDetails.addressHeading">{{ bp('address') }}</pdl-heading>
          </template>
        </pdl-section-header>
        <div class="grid-x grid-margin-x grid-margin-y">
          <div class="cell large-4 p-3 bg-gray-05 text-gray-80" qaid="businessUnitDetails.address">
            <div>{{ address.line1 }}</div>
            <div>{{ address.line2 }}</div>
            <div>{{ addressCityStateLine }}</div>
            <div>{{ addressPostalCountryLine }}</div>
          </div>
        </div>
      </section>

      <!-- Store Locator Profiles -->
      <section>
        <pdl-section-header size="md" :is-dividing="true" class="mt-6 mb-3 items-center">
          <template slot="content">
            <pdl-heading :level="1" qaid="businessUnitDetails.storeLocationProfilesHeader">{{
              bp('storeLocationProfiles')
            }}</pdl-heading>
          </template>
          <template slot="actions">
            <trek-link
              button
              secondary
              small
              qaid="businessUnitDetails.addNewButton"
              :href="`${encodedContextPath}/my-company/retailers/create`"
            >
              <span v-html="bp('addNew')"></span>
            </trek-link>
          </template>
        </pdl-section-header>
        <!-- Locations found text and sorting -->
        <div class="flex justify-between items-center mb-3">
          <div class="italic" qaid="businessUnitDetails.locationsFound">{{ locationsFound }}</div>

          <!-- Sort By -->
          <div class="form-group--inline justify-end">
            <label for="selectSortby" class="form-label">{{ bp('sortBy') }}</label>
            <div class="select select--clear">
              <select id="selectSortby" v-model="sort" class="form-control" @change="fetchCompanyData(true)">
                <option
                  v-for="(column, colIndex) in sortOptions"
                  :key="colIndex"
                  :value="column.param"
                  qaid="`sort-list.option-${column.param}`"
                >
                  {{ column.name }}
                </option>
              </select>
              <div class="focus-indicator"></div>
            </div>
          </div>
        </div>

        <!-- Stores Grid -->
        <business-unit-details-table
          :base-properties="baseProperties"
          :stores="stores"
          :view-page-url="viewPageUrl"
          :loading="sortLoading"
          @delete-store="deleteStore"
        />
      </section>
    </pdl-loading>

    <!-- Delete store confirmation dialog -->
    <pdl-dialog
      :visible.sync="dialogVisible"
      :title="bp('deleteLocationConfirmation')"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      :z-index="16000011"
      @close="resetDeleteData"
    >
      <template slot="footer">
        <div class="buttons buttons--right">
          <trek-button alert @click="confirmDelete">
            <span v-html="bp('deleteConfirmation')"></span>
          </trek-button>
          <trek-button secondary @click="dialogVisible = false">
            <span v-html="bp('keepLocation')"></span>
          </trek-button>
        </div>
      </template>
    </pdl-dialog>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {PdlLoading, TrekButton, TrekLink, PdlDialog, PdlSectionHeader, PdlHeading},
  props: {
    baseProperties: {
      type: Array,
      default: undefined,
    },
    encodedContextPath: {
      type: String,
      default: undefined,
    },
    viewPageUrl: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      accountNumber: null,
      loading: true,
      sortLoading: true,
      storeName: null,
      address: {},
      fetchURL: '/my-company/retailerlist/',
      stores: [],
      sortKey: 'name',
      storeToDelete: null,
      dialogVisible: false,
      sort: 'Name',
    };
  },

  computed: {
    addressCityStateLine() {
      if (this.address.town && this.address.regionIso) return `${this.address.town}, ${this.address.regionIso}`;
      else if (this.address.town) return this.address.town;
      else if (this.address.regionIso) return this.address.regionIso;
      else return null;
    },

    addressPostalCountryLine() {
      if (this.address.postalCode && this.address.countryName)
        return `${this.address.postalCode}, ${this.address.countryName}`;
      else if (this.address.postalCode) return this.address.postalCode;
      else if (this.address.countryName) return this.address.countryName;
      else return null;
    },

    locationsFound() {
      return this.bp('locationsFound').replace('{0}', this.stores ? this.stores.length : 0);
    },

    sortOptions() {
      return [
        {name: this.bp('name'), param: 'Name'},
        {name: this.bp('address'), param: 'Address'},
        {name: this.bp('townCity'), param: 'City'},
        {name: this.bp('state'), param: 'State'},
        {name: this.bp('country'), param: 'Country'},
      ];
    },
  },

  mounted() {
    this.fetchCompanyData();
  },

  methods: {
    fetchCompanyData(sortLoading) {
      this.sortLoading = sortLoading;

      storefrontInstance
        .get(`${this.fetchURL}?sort=${this.sort}`)
        .then(({data}) => {
          if (data && data.data) {
            this.accountNumber = data.data.id;
            this.storeName = data.data.name;
            this.address = data.data.address;
            this.stores = data.data.stores;
          }
        })
        .finally(() => {
          this.loading = false;
          this.sortLoading = false;
          if (this.address === null) this.address = {};
        });
    },

    deleteStore(store) {
      if (store) {
        this.storeToDelete = store.id;
        this.dialogVisible = true;
      }
    },

    // This is called when the delete confirmation dialog closes
    resetDeleteData() {
      this.storeToDelete = null;
      this.dialogVisible = false;
    },

    confirmDelete() {
      this.dialogVisible = false;

      storefrontInstance.delete(`/my-company/retailer/${this.storeToDelete}`).finally(() => {
        this.fetchCompanyData();
      });
    },
  },
};
</script>
