<template>
  <form-group
    v-model="townCity"
    :maxlength="25"
    name="townCity"
    :label="$t('address.townCity')"
    :error="getErrorMessage('townCity')"
    required
    show-error-messages
    force-display-error
  />
</template>

<script>
import FormGroup from '@/components/FormGroup';
import {mapState, mapMutations} from 'vuex';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
export default {
  components: {FormGroup},
  mixins: [TrekValidationMixin],
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  validations() {
    return {
      townCity: {
        required: this.trekValidators.requiredWithCustomMessage('address.townCity.invalid'),
        maxLength: this.trekValidators.maxLength('address.townCity', 25),
      },
    };
  },
  computed: {
    ...mapState('checkout', ['billingAddress']),
    townCity: {
      get() {
        return this.billingAddress.townCity ? this.billingAddress.townCity : this.value;
      },
      set(townCity) {
        this.setTownCity(townCity);
      },
    },
    fieldName() {
      return 'townCity';
    },
  },
  methods: {
    ...mapMutations('checkout', ['setTownCity']),
  },
};
</script>
