<template>
  <!-- Claim Details -->
  <section>
    <pdl-section-header size="md" :is-dividing="true" class="mb-3">
      <template slot="content">
        <pdl-heading :level="3" qaid="warranty-claim-details-header">
          {{ $t('warrantyClaims.B2B.claimDetails') }}
        </pdl-heading>
      </template>
    </pdl-section-header>

    <div class="grid-x grid-margin-x">
      <div class="cell medium-4">
        <form-group
          type="text"
          name="poNumber"
          qaid="warranty-claim-contact-po-number"
          :maxlength="30"
          :label="$t('text.poNumber')"
          :value="claimData.poNumber"
          @blur="updateVuex"
        />
        <div>
          <form-group
            class="mb-0"
            type="textArea"
            name="notes"
            qaid="warranty-claim-contact-claim-notes"
            :label="$t('warrantyClaims.B2B.claimNotes')"
            :maxlength="maxNoteCharacters"
            :rows="4"
            :value="claimData.notes"
            :error="getErrorMessage('claimData.notes')"
            required
            show-error-messages
            force-display-error
            @blur="updateVuex"
          />
          <div class="form-help mb-4">
            {{ $t('text.charactersRemaining') }} {{ noteCharactersRemaining }} <br />
            {{ $t('warrantyClaims.B2B.claimNotes.body') }}
          </div>
        </div>

        <label class="checkbox">
          <input
            type="checkbox"
            name="isWarrantyRepair"
            qaid="warranty-claim-non-warranty-claim"
            :checked="!claimData.isWarrantyRepair"
            @change="updateIsWarrantyRepair"
          />
          <span class="control-indicator"></span>
          {{ $t('warrantyClaims.B2B.nonWarrantyClaim') }}
        </label>

        <label v-if="displayWarrantyMakeTheCall" class="checkbox">
          <input
            type="checkbox"
            name="isWarrantyCallMade"
            qaid="warranty-claim-make-the-call"
            :checked="isWarrantyCallMade"
            :disabled="!isWarrantyCallEnabled"
            @change="updateMakeTheCall"
          />
          <span class="control-indicator" />
          <span>{{ $t('warrantyClaims.B2B.makeTheCall') }}</span>
        </label>
      </div>
    </div>
  </section>
</template>

<script>
import FormGroup from '@/components/FormGroup';
import {mapMutations, mapActions} from 'vuex';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
import useVuelidate from '@vuelidate/core';

export default {
  components: {PdlSectionHeader, PdlHeading, FormGroup},
  mixins: [TrekValidationMixin],

  props: {
    claimData: {
      type: Object,
      required: true,
    },
    displayWarrantyMakeTheCall: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },

  data() {
    return {
      maxNoteCharacters: 2000,
      notes: '',
    };
  },
  validations() {
    return {
      claimData: {
        notes: {
          required: this.trekValidators.required('warrantyClaims.B2B.claimNotes'),
          maxLength: this.trekValidators.maxLength('warrantyClaims.B2B.claimNotes', this.maxNoteCharacters),
        },
      },
    };
  },
  computed: {
    isWarrantyCallEnabled() {
      return this.claimData?.status !== 'enumClaimStatusActionRequired';
    },
    isWarrantyCallMade() {
      return this.claimData?.isWarrantyCallMade ?? false;
    },
    noteCharactersRemaining() {
      return this.claimData.notes ? this.maxNoteCharacters - this.claimData.notes.length : this.maxNoteCharacters;
    },
  },
  watch: {
    claimData(updatedClaimData) {
      this.notes = updatedClaimData.notes;
    },
  },
  methods: {
    updateVuex(e) {
      const targetName = e.target.getAttribute('name');
      this.updateProperty({
        propName: targetName,
        value: e.target.value,
      });

      if (targetName === 'notes') {
        this.v$.claimData.notes.$touch();
      }
    },

    updateMakeTheCall(e) {
      const name = e.target.getAttribute('name');
      const checked = e.target.checked;

      this.updateProperty({
        propName: name,
        value: checked,
      });
    },

    updateIsWarrantyRepair(e) {
      this.updateProperty({
        propName: 'isWarrantyRepair',
        value: !e.target.checked,
      });
    },

    ...mapActions('warrantyClaims', ['updateProperty']),
    ...mapMutations('warrantyClaims', []),
  },
};
</script>
