<template>
  <tr class="table-row" :class="{'table-row--parent': row[childDataKey]}">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  provide() {
    return {
      item: this,
    };
  },
  props: {
    row: {
      type: Object,
      default: () => null,
    },
    rowId: {
      type: [String, Number],
      default: '',
    },
    rowData: {
      type: Array,
      default: () => [],
    },
    childDataKey: {
      type: String,
      default: 'childData',
    },
  },
  data() {
    return {};
  },
};
</script>
