<template>
  <div id="MyReviewHeader" class="power-reviews grid-container mb-6" :data-powerreviews="options.powerReviewsId">
    <pdl-loading :is-loading="!isReviewsComponentRendered" />

    <div
      v-if="options.showImageSnippet"
      :id="`pr-imagesnippet-${options.powerReviewsId}`"
      class="pr-imagesnippet"
    ></div>
    <div
      v-if="options.showReviewDisplay"
      :id="`pr-reviewdisplay-${options.powerReviewsId}`"
      class="pr-reviewdisplay"
    ></div>
    <div v-if="options.showQuestionDisplay" id="questions-wrapper">
      <div :id="`pr-questiondisplay-${options.powerReviewsId}`" class="pr-questiondisplay"></div>
    </div>
  </div>
</template>
<script>
import {PdlLoading} from '@pedal/pdl-loading';
import {mapState} from 'vuex';

export default {
  name: 'ProductReviewsComponent',
  components: {
    PdlLoading,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isReviewsComponentRendered: false,
    };
  },
  computed: {
    ...mapState('powerReviews', ['productDetails', 'isWindowPwrLoaded', 'prBaseProperties']),
  },
  watch: {
    isWindowPwrLoaded(val) {
      if (val && this.productDetails) {
        this.initPowerReviews();
      }
    },
  },
  mounted() {
    if (this.isWindowPwrLoaded) {
      this.initPowerReviews();
    }
  },
  methods: {
    initPowerReviews() {
      if (window.pwr && !this.isReviewsComponentRendered) {
        let powerReview = {
          locale: this.options.locale,
          merchant_group_id: this.options.merchantGroupId,
          page_id: this.options.powerReviewsId,
          merchant_id: this.options.merchantId,
          api_key: this.options.powerReviewsApiKey,
          review_wrapper_url: this.options.writeReviewUrl,
          on_render: () => {
            let reviewsQaHeaderElement = document.querySelector('[title="Questions & Answers"]');
            if (reviewsQaHeaderElement) {
              reviewsQaHeaderElement.innerText = this.prBaseProperties.pRQaHeader;
            }
            let reviewsCountElement = document.getElementsByClassName('pr-snippet-review-count');
            let scrollToReviews = () => {
              let reviewHeaderElement = document.getElementById('MyReviewHeader');
              reviewHeaderElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
            };
            if (reviewsCountElement[0]) {
              reviewsCountElement[0].addEventListener('click', scrollToReviews);
            }
          },
          components: {
            ReviewImageSnippet: `pr-imagesnippet-${this.options.powerReviewsId}`,
            ReviewDisplay: `pr-reviewdisplay-${this.options.powerReviewsId}`,
            QuestionDisplay: `pr-questiondisplay-${this.options.powerReviewsId}`,
          },
          product: {
            name: this.productDetails.pr_product_name,
            url: this.productDetails.pr_product_url,
            image_url: this.productDetails.pr_product_image,
            description: this.productDetails.pr_product_description,
            category_name: this.productDetails.pr_product_category_name,
            manufacturer_id: this.productDetails.pr_product_manufacturer_id,
            upc: this.productDetails.pr_product_upc,
            brand_name: this.productDetails.pr_product_brand_name,
            price: this.productDetails.pr_product_price,
            in_stock: this.productDetails.pr_product_in_stock,
          },
        };

        if (powerReview) {
          window.pwr('render', powerReview);
          this.isReviewsComponentRendered = true;
        }
      }
    },
  },
};
</script>
