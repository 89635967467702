<template>
  <main :id="settings.locationId">
    <div class="grid-x grid-margin-x">
      <facet-group-container
        v-if="filters"
        :location-id="settings.locationId"
        :filters="filters"
        :selected-filters="selectedFilters"
      />
      <div v-if="hasProducts" class="off-canvas-content cell small-12 large-10" data-off-canvas-content>
        <pdl-loading v-if="loading" :is-loading="loading" class="my-6" />
        <trek-button secondary block icon="list" class="hide-for-large mt-2" data-toggle="b2b-off-canvas-facet">
          <span>{{ $t('search.nav.refinements') }}</span>
        </trek-button>
        <plp-view-options
          v-show="options && options.totalCount && !loading"
          :options="options"
          class="my-2 lg:my-4"
          :is-expand-all-visible="isExpandAllButtonVisible"
          :can-change-page-size="isGrid"
          :view-options="viewOptions"
          :page-size-options="settings.pageSizeOptions"
          :sort-options="options.sortOptions"
          @view-mode-changed="onViewModeChanged"
        />
        <plp-list-view-container
          v-if="isListView && !loading"
          :products="products"
          :is-compare-button-enabled="settings.isCompareButtonEnabled"
          :settings="settings"
          :options="options"
          @item-state-changed="updateCollapseState"
        />
        <plp-grid-view-container
          v-if="isGrid && !loading"
          :products="products"
          :is-compare-button-enabled="settings.isCompareButtonEnabled"
          :settings="settings"
          :options="options"
        />
        <div
          v-if="hasProducts && !loading"
          class="md:flex md:justify-between border-gray-10 pt-4 pb-4"
          :class="{'border-t': isGrid}"
        >
          <div>
            <pagination-controls
              v-if="totalPages > 1 && !loading"
              :page-number="viewOptions.currentPage"
              :page-count="totalPages"
              @prev-page="prevPage"
              @next-page="nextPage"
              @page-change="gotoPage"
            />
          </div>
          <div>
            <plp-view-options
              v-show="options && options.totalCount && !loading"
              :options="options"
              :is-total-count-visible="false"
              :is-expand-all-visible="isExpandAllButtonVisible"
              :can-change-page-size="isGrid"
              :view-options="viewOptions"
              :page-size-options="settings.pageSizeOptions"
              :sort-options="options.sortOptions"
              @view-mode-changed="onViewModeChanged"
            />
          </div>
        </div>
      </div>
      <plp-no-results v-if="!hasProducts && !loading" :class="filters ? 'cell small-12 large-10' : 'w-full'" />
    </div>

    <bundling-dialog
      v-if="settings.locationId !== 'archive'"
      :show-dialog="isDialogVisible"
      :add-to-cart-button-caption="$t('text.addToCart')"
      @cancel="setBundleDialogVisibility(false)"
      @add-to-cart="setBundleDialogVisibility(false)"
    />
  </main>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';
import {PdlLoading} from '@pedal/pdl-loading';
import PlpViewOptions from './PlpViewOptions';
import PlpListViewContainer from './PlpListViewContainer';
import PlpGridViewContainer from './PlpGridViewContainer';
import PlpNoResults from './PlpNoResults';
import PaginationControls from '@/components/PaginationControls';
import {Url} from '@/utils/url';
import FacetGroupContainer from './FacetGroupContainer';
import {scrollToTop} from '@/utils/scroll-to';
import TrekButton from '@/components/TrekButton';
import BundlingDialog from '@/components/containers/pdp/BundlingDialog';

export default {
  components: {
    FacetGroupContainer,
    PaginationControls,
    PlpNoResults,
    PdlLoading,
    PlpViewOptions,
    PlpListViewContainer,
    PlpGridViewContainer,
    TrekButton,
    BundlingDialog,
  },

  props: {
    settings: {
      type: Object,
      default: undefined,
    },
    isSwatchDisplayAllowed: {
      type: Boolean,
      default: true,
    },
    hasAccessToPlpListView: {
      type: Boolean,
      default: true,
    },
    isExcludingTaxesShown: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('plp', [
      'products',
      'filters',
      'selectedFilters',
      'options',
      'loading',
      'viewOptions',
      'isBundleDialogVisible',
      'collapseState',
    ]),

    isExpandAllButtonVisible() {
      return this.settings.locationId !== 'archive';
    },

    isGrid() {
      return this.viewOptions.viewMode === 'grid';
    },

    isListView() {
      return this.viewOptions.viewMode === 'listview';
    },

    totalPages() {
      return parseInt(this.options.totalNumberOfPages);
    },

    hasProducts() {
      return this.products && this.products.length > 0;
    },

    isDialogVisible: {
      get() {
        return this.isBundleDialogVisible;
      },

      set(flag) {
        this.setBundleDialogVisibility(flag);
      },
    },
  },

  watch: {
    products(products, previousProducts) {
      //only expand when products are first loaded (product array transitions from empty to filled)
      if (!products?.length || previousProducts?.length) return;
      this.expandAllLists();
    },
  },

  mounted() {
    this.init();
  },

  beforeDestroy() {
    window.removeEventListener('popstate', this.initFromQueryParams);
    window.removeEventListener('plp-set-api-url', this.setApiUrl);
  },

  methods: {
    ...mapActions('plp', [
      'fetchResults',
      'updateViewOption',
      'setCategoryId',
      'setSearchQueryText',
      'resetViewOptions',
      'setFiltersQuery',
      'setApiBaseUrl',
      'updateCollapseState',
    ]),
    ...mapMutations('plp', [
      'setIsColorSwatchAllowedState',
      'setHasAccessToPlpListView',
      'setBundleDialogVisibility',
      'setIsExcludingTaxesShown',
    ]),

    setApiUrl(ev) {
      if (!ev.detail && !ev.detail.url) {
        return;
      }
      this.setApiBaseUrl(ev.detail.url);
      this.fetchResults();
    },

    init() {
      window.removeEventListener('popstate', this.initFromQueryParams);
      window.removeEventListener('plp-set-api-url', this.setApiUrl);

      window.addEventListener('popstate', this.initFromQueryParams);
      window.addEventListener('plp-set-api-url', this.setApiUrl);

      this.initFromQueryParams();
      this.fetchResults().then(() => {
        const ev = new CustomEvent('plp-data-loaded', {
          detail: {
            options: this.options,
            settings: this.settings,
          },
        });
        window.dispatchEvent(ev);
      });
      this.setIsColorSwatchAllowedState(this.isSwatchDisplayAllowed);
      this.setHasAccessToPlpListView(!this.hasAccessToPlpListView);
      this.setIsExcludingTaxesShown(this.isExcludingTaxesShown);
    },

    expandAllLists() {
      let urlExpandParams = Url.parse(window.location.search);
      if (urlExpandParams.viewMode !== 'listview' || !JSON.parse(urlExpandParams.expandAll) || this.settings.locationId)
        return;
      this.products.forEach((product) => {
        const options = {
          code: product.code,
          state: true,
        };
        this.updateCollapseState(options);
      });
    },

    getCategoryId() {
      const matches = window.location.href.match(/\/c\/((\w|-)+)(\/|\?|^)?/);
      if (matches && matches.length > 2 && matches[1].length > 0) {
        return matches[1];
      }
      return '';
    },

    initPlp() {
      // Store current URL to local storage, it's used by compare rack to return back when user clicks "Add Product" link.
      const lastPlpUrl = JSON.stringify(window.location.pathname);
      window.localStorage.setItem('latest_plp_url', lastPlpUrl);
    },

    initFromQueryParams() {
      const categoryId = this.getCategoryId();
      if (categoryId) {
        this.setCategoryId(categoryId);
        this.initPlp();
      }

      this.resetViewOptions();
      const params = Url.parse(window.location.href);
      let viewOptions = {};
      for (let param in params) {
        switch (param) {
          case 'pageSize':
            viewOptions.pageSize = params[param];
            break;
          case 'sort':
            viewOptions.sortCode = params[param];
            break;
          case 'viewMode':
            viewOptions.viewMode = params[param];
            break;
          case 'currentPage':
            viewOptions.currentPage = Number(params[param]);
            break;
          case 'facets':
            // Make sure each GET parameter is encoded to prevent issues with special characters such as ? or &.
            var query = params[param]
              .split(':')
              .map((item) => encodeURIComponent(item))
              .join(':');
            this.setFiltersQuery(query);
            break;
          case 'text':
            this.setSearchQueryText(params[param]);
            break;
        }
      }

      if (!this.hasAccessToPlpListView) {
        viewOptions.viewMode = 'grid';
      }

      if (Object.keys(viewOptions).length > 0) {
        this.updateViewOption(viewOptions);
      }
    },

    prevPage() {
      this.gotoPage(this.viewOptions.currentPage - 1);
    },

    nextPage() {
      this.gotoPage(this.viewOptions.currentPage + 1);
    },

    gotoPage(pageNumber) {
      if (this.loading || pageNumber < 0 || pageNumber >= this.options.totalNumberOfPages) {
        return;
      }

      this.cleanExpandedGrids();
      this.updateViewOption({currentPage: pageNumber});
      scrollToTop();
    },

    cleanExpandedGrids() {
      // UX Optimization. This method cleans DOM tree from expanded grids and allows
      // browser work faster when user switches between pages.
      document.querySelectorAll('[data-plp-grid]').forEach((grid) => grid.remove());
    },

    onViewModeChanged(newMode) {
      this.updateViewOption({viewMode: newMode});
    },
  },
};
</script>
