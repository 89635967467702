<template>
  <div v-if="items.length" class="attribute-dropdown">
    <input v-if="selectedOption" id="product-dimension" type="hidden" :value="selectedOption" />
    <div class="buying-zone__form-label-group">
      <div class="variantName">
        <span class="text-md--semibold" v-html="label" />
      </div>
    </div>
    <div class="select">
      <form-group v-model="selectedOption" type="select" :name="attribute[0].code" :multi-items="items" :qaid="qaid">
      </form-group>
    </div>
    <div
      v-if="stockMessage && stockMessage.explanation"
      class="text-gray-60 mb-4"
      v-html="stockMessage.explanation"
    ></div>
  </div>
</template>
<script>
import {Attribute} from '@/components/containers/pdp/attributes/mixins/attribute';
import FormGroup from '@/components/FormGroup';
import {mapState} from 'vuex';

export default {
  name: 'AttributeDropdown',
  components: {FormGroup},
  mixins: [Attribute],
  props: {
    option: {
      type: Object,
      default: () => {},
    },
    stockMessage: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      selectedOption: '',
      qaid: this.isB2Bsite ? 'attribute-dropdown-b2b' : 'attribute-dropdown-b2c',
    };
  },
  computed: {
    ...mapState('backend', ['isB2Bsite']),
    currentItem() {
      return this.getItem(this.selectedOption);
    },
    items() {
      let items = [
        {
          value: '',
          disabled: true,
          id: '',
          displayName: this.isB2Bsite
            ? this.$t('product.B2B.variants.select.variant')
            : this.$t('product.variants.select.variant'),
        },
      ];

      this.attribute.forEach((item) => {
        items.push({
          ...item,
        });
      });

      return items;
    },
  },
  watch: {
    items(items) {
      if (items.length - 1 === 1) {
        // Selecting the first value option. Array of items will always have length of 2 or more,
        // where the first item in the list is an empty item (for "Select One" option).
        this.selectedOption = items[1].value;
      }
    },
    selectedOption() {
      this.fireSelectedProduct();
    },
  },
  created() {
    this.initSelectedAttribute();
    this.fireSelectedProduct();
  },
  methods: {
    getItem(value) {
      return this.attribute.find((item) => item.value === value);
    },

    initSelectedAttribute() {
      const initValue = this.initAttributeSelection?.[this.code];
      if (!initValue || !this.items.find((item) => item.value === initValue)) {
        if (this.items.length - 1 >= 1) {
          this.selectedOption = this.items[1].value;
        }
      } else {
        this.selectedOption = initValue;
      }
    },

    fireSelectedProduct() {
      const option = this.getItem(this.selectedOption);
      if (!option) return;
      this.updateSelectedAttributes({
        [option.code]: this.selectedOption,
      });
    },
  },
};
</script>
