<template>
  <div ref="wrapperRef" class="flair-wrapper" :style="wrapperStyles">
    <div
      ref="flairBackground"
      class="flair-background flex flex-col px-2 py-3 md:p-4"
      :class="backgroundClasses"
      :style="backgroundStyles"
    >
      <div ref="iconBarRef" class="flex justify-between items-center relative z-10">
        <img class="flair-icon" :src="iconAsset" :alt="iconAltText" @load="snapshotHeights" />
        <button
          v-show="isVideoVisible && isLargeOrGreaterBreakpoint"
          class="cursor-pointer"
          :class="closeVideoIconColor"
          @click="collapseExpanded"
        >
          <pdl-icon size="32" :label="$t('popup.close')" name="close_fullscreen" />
        </button>
      </div>
      <div class="flair-transition-wrapper flex flex-col lg:flex-row gap-y-4 md:gap-y-8 lg:-mt-6" :class="gapClasses">
        <transition
          @before-leave="handleBeforeLeave"
          @after-leave="handleAfterLeave"
          @before-enter="setWrapperHeight"
          @after-enter="resetHeights"
          @enter="expandBody"
          @leave="collapseBody"
        >
          <editorial-flair-body
            v-show="!isLargeOrGreaterBreakpoint || !isVideoVisible"
            :is-body-visible="isBodyVisible"
            :title="title"
            :body="body"
            :title-color="titleColor"
            :body-color="bodyColor"
            :cta-color="ctaColor"
            :ctas="ctas"
            :play="play"
            :classes="bodyClasses"
          />
        </transition>
        <editorial-flair-video
          v-if="hasVideo"
          :asset-id="videoAsset"
          :title="video.title"
          :url="videoUrl"
          :is-video-visible="isVideoVisible"
          :play="play"
        />
        <editorial-flair-image v-else :asset-id="assetId" />
      </div>
    </div>
  </div>
</template>

<script>
import EditorialFlairBody from '@/components/containers/pdp/overview/editorial-flair/EditorialFlairBody.vue';
import EditorialFlairImage from '@/components/containers/pdp/overview/editorial-flair/EditorialFlairImage.vue';
import EditorialFlairVideo from '@/components/containers/pdp/overview/editorial-flair/EditorialFlairVideo.vue';
import {PdlIcon} from '@pedal/pdl-icon';
import {mapGetters} from 'vuex';
import {ViewSize} from '@/constants/view-breakpoints';
import {Cloudinary} from '@/utils/image/cloudinary-helpers';
import {YoutubePlayer} from '@/utils/media/youtube-player';
import {getContrastRatio} from '@/utils/contrast-ratio';

const STICKY_NAV_OFFSET = 150;

export default {
  components: {EditorialFlairBody, EditorialFlairImage, EditorialFlairVideo, PdlIcon},
  props: {
    title: {
      type: String,
      default: () => '',
    },
    body: {
      type: String,
      default: () => '',
    },
    assetId: {
      type: String,
      default: () => '',
    },
    iconAssetId: {
      type: String,
      default: () => '',
    },
    backgroundColor: {
      type: String,
      default: () => '',
    },
    titleColor: {
      type: String,
      default: () => '',
    },
    bodyColor: {
      type: String,
      default: () => '',
    },
    ctaColor: {
      type: String,
      default: () => '',
    },
    ctas: {
      type: Array,
      default: () => [],
    },
    video: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVideoVisible: false,
      isBodyVisible: true,
      preTransitionHeight: null,
      wrapperOverrideHeight: null,
      bodyClasses: ['active'],
    };
  },
  computed: {
    ...mapGetters('viewSize', ['viewBreakpointMoreThan']),
    hasVideo() {
      return this.video?.url && this.video?.assetId;
    },
    isLargeOrGreaterBreakpoint() {
      return this.viewBreakpointMoreThan(ViewSize.MEDIUM);
    },
    backgroundClasses() {
      if (!this.isLargeOrGreaterBreakpoint || !this.isVideoVisible) return ['video-collapsed'];
      return ['gap-y-8'];
    },
    backgroundStyles() {
      return {
        transition: 'row-gap 0.3s linear',
        background: this.backgroundColor,
        ...(this.isLargeOrGreaterBreakpoint && this.preTransitionHeight && {height: `${this.preTransitionHeight}px`}),
      };
    },
    wrapperStyles() {
      const {iconBarRef, wrapperRef} = this.$refs;
      const shouldUseWrapperOverride = this.wrapperOverrideHeight && !this.isVideoVisible;
      if (shouldUseWrapperOverride) return {height: `${this.wrapperOverrideHeight}px`};
      if (!this.isVideoVisible || !iconBarRef || !wrapperRef || !this.isLargeOrGreaterBreakpoint) return;
      return this.getWrapperFullHeight(wrapperRef, iconBarRef);
    },
    gapClasses() {
      return {
        'lg:gap-x-8': this.isBodyVisible,
      };
    },
    iconAsset() {
      return Cloudinary.createAssetUrl(this.iconAssetId);
    },
    videoAsset() {
      return Cloudinary.createAssetUrl(this.video?.assetId);
    },
    videoUrl() {
      return YoutubePlayer(this.video?.url).getEmbed({autoplay: true});
    },
    iconAltText() {
      return '';
    },
    closeVideoIconColor() {
      const gray90 = '#313131';
      const contrastRatio = getContrastRatio(this.backgroundColor, gray90);
      if (!contrastRatio) return;
      return contrastRatio > 7 ? ['text-gray-90'] : ['text-white'];
    },
  },
  methods: {
    getWrapperFullHeight(wrapperEl, iconBarEl) {
      const wrapperMargin = parseInt(window.getComputedStyle(wrapperEl, '').marginBottom.split('px')?.[0]) ?? 0;
      const viewportHeight = (66 * window.innerHeight) / 100;
      const calculatedHeight = viewportHeight + iconBarEl?.clientHeight + wrapperMargin;
      return {
        height: `${calculatedHeight}px`,
      };
    },
    setWrapperHeight() {
      this.wrapperOverrideHeight = this.preTransitionHeight;
    },
    snapshotHeights() {
      const backgroundHeight = this.$refs.flairBackground?.clientHeight;
      this.preTransitionHeight = backgroundHeight;
      this.wrapperOverrideHeight = backgroundHeight;
    },
    resetHeights() {
      this.preTransitionHeight = null;
      this.isBodyVisible = true;
    },
    handleBeforeLeave() {
      this.isBodyVisible = false;
      this.snapshotHeights();
    },
    handleAfterLeave() {
      const {wrapperRef} = this.$refs;
      const y = wrapperRef?.getBoundingClientRect().top + window?.scrollY - STICKY_NAV_OFFSET;
      window?.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    },
    collapseExpanded() {
      this.isVideoVisible = false;
    },
    play() {
      if (!this.hasVideo) return;
      this.isVideoVisible = true;
    },
    collapseBody() {
      this.wrapperOverrideHeight = null;
      this.bodyClasses = ['inactive'];
    },
    expandBody() {
      //Timeout required to prevent styling from being applied before the transition is 'watching' the component.
      setTimeout(() => {
        this.bodyClasses = ['active'];
      }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.flair-wrapper {
  transition: height 0.3s linear;
  margin-bottom: 4rem;
}

.video-collapsed {
  row-gap: 4rem;
}

.flair-icon {
  max-height: 64px;
}

.v-enter-active,
.v-leave-active {
  transition: flex-grow 0.3s linear;
  opacity: 0;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
