<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {PdlLoading} from '@pedal/pdl-loading';
import TrekButton from '@/components/TrekButton';
import {PdlTabPane, PdlTabs} from '@pedal/pdl-tabs';
import FormGroup from '@/components/FormGroup';
import {PdlCollapse} from '@pedal/pdl-collapse';
import CheckoutGrid from '@/components/checkout/v2/CheckoutGrid';
import CheckoutNavigation from '@/components/checkout/CheckoutNavigation';
import CheckoutOrderDetails from '@/components/checkout/v2/CheckoutOrderDetails';
import CheckoutShippingInfo from '@/components/checkout/v2/CheckoutShippingInfo';
import CartTotals from '@/components/cart/totals/CartTotals';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlLink} from '@pedal/pdl-link';
import TrekLink from '@/components/TrekLink';
import {PdlDialog} from '@pedal/pdl-dialog';
import DropShipOrdersContainer from '@/components/containers/drop-ship/DropShipOrdersContainer';
import {ProductType} from '@/constants/product';
import {scrollToElement} from '@/utils/scroll-to';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {useVuelidate} from '@vuelidate/core';

export default {
  name: 'CheckoutContainerShipments',
  components: {
    PdlLoading,
    PdlLink,
    TrekButton,
    PdlTabs,
    PdlTabPane,
    FormGroup,
    PdlCollapse,
    CheckoutGrid,
    CheckoutNavigation,
    PdlCallout,
    TrekLink,
    PdlDialog,
    DropShipOrdersContainer,
    CheckoutOrderDetails,
    CheckoutShippingInfo,
    PdlSectionHeader,
    PdlHeading,
    CartTotals,
  },

  props: {
    isDropShipCustomer: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      dialogVisible: false,
      loading: true,
      selectedTabName: '',
      isSaveWarningShown: false,
      orderData: '',
      orderNumber: '',
      isOrderDetailsLoading: true,
    };
  },

  computed: {
    tabSelected: {
      get() {
        return this.selectedTabName ? this.selectedTabName : this.entries[0]?.groupingType;
      },
      set(name) {
        this.selectedTabName = name;
      },
    },

    tabLabelMap() {
      return {
        [ProductType.Bike]: this.$t('text.bikes'),
        [ProductType.Aftermarket]: this.$t('text.accessories'),
        [ProductType.CustomWaterBottles]: this.$t('checkout.custom'),
        [ProductType.ProjectOne]: this.$t('checkout.projectOne'),
      };
    },

    // Show shipment warning if there is more than 1 warehouse
    isShipmentWarningShown() {
      return this.entriesListTree.some(([type, entries]) => type === this.tabSelected && entries.length > 1);
    },

    ...mapGetters('advancedOrdering', ['aoToggleCompletedWatcher']),
    ...mapGetters('user', ['b2bUnitShippingDays']),
    ...mapGetters('shipments', ['entriesListTree', 'warehousesMap']),
    ...mapState('backend', ['encodedContextPath', 'isConsumerFriendlyMode']),
    ...mapState('miniCart', ['containers']),
    ...mapState('shipments', ['entries', 'warehouses', 'shipmentsInfo', 'totals', 'freightTotals']),
  },

  watch: {
    aoToggleCompletedWatcher() {
      this.loadCart();
    },
  },

  async created() {
    this.loadShipmentsInfo();
    this.loadCart();

    if (this.isDropShipCustomer) {
      this.fetchMiniCart();
    }

    await this.fetchTotals();

    this.loading = false;
  },

  methods: {
    changeGridTab(tab) {
      this.tabSelected = tab;
      scrollToElement(this.$refs.checkoutTabs.$el, 150);
    },

    goToNextCheckoutStep() {
      this.v$.$validate().then((isValid) => {
        if (!isValid) {
          const firstErrorFieldNameType = document
            .querySelector('.has-error .form-control')
            ?.getAttribute('name')
            ?.split('-')?.[0];
          this.changeGridTab(firstErrorFieldNameType);
        } else {
          this.handleShipmentsBeforeUnload();
        }
      });
    },

    // Handles the data update to the BE when the user navigates away from Shipments page
    async handleShipmentsBeforeUnload() {
      const result = await this.updateShipmentsDetails({
        shipments: this.shipmentsInfo.shipments,
        entries: this.entries,
        warehouses: this.warehouses,
      });
      if (result) {
        window.location = `${this.encodedContextPath}/checkout/multi/shipment/next/`;
      } else {
        this.isSaveWarningShown = true;
      }
    },

    async loadCart() {
      await this.fetchCart();
    },

    async loadShipmentsInfo() {
      await this.fetchShipmentsInfo();
      for (const data in this.shipmentsInfo.shipments) {
        this.$set(this.shipmentsInfo.shipments[data], 'type', data);
      }
      this.isOrderDetailsLoading = false;
    },

    ...mapMutations('shipments', ['updateShipmentProperty']),
    ...mapActions('miniCart', ['fetchMiniCart']),
    ...mapActions('shipments', [
      'fetchCart',
      'fetchShipmentsInfo',
      'fetchTotals',
      'fetchTotalsWithFreight',
      'updateShipmentsDetails',
    ]),
  },
};
</script>
