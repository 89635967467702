'use strict';

export default function () {
  // GTM
  // PDP product impressions
  // Check whether there are any product references and push thru to data
  // layer
  function checkForProductReferences() {
    var productReferences = $('.product-set__slide');
    if (productReferences) {
      var $sku = $('.buying-zone__code');
      var sku = '';
      if ($sku && $sku.html()) {
        sku = $.trim($sku.html().replace('SKU', ''));
      }
      var productList = '';

      $(productReferences).each(function () {
        var productCodes = $(this).find('[id^="product-tile-sku-"]');
        $(productCodes).each(function () {
          var productId = $(this).attr('id').replace('product-tile-sku-', '');

          if (productList !== '') {
            productList = productList + '|' + productId;
          } else {
            productList = productId;
          }
        });
      });

      if (productList !== '' && sku !== '') {
        ACC.track.trackProductReferences(sku, productList);
      }
    }
  }

  // for GTM
  checkForProductReferences();
}
