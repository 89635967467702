<template>
  <div class="content-tile__wrap">
    <p class="icon-item">
      <pdl-icon v-if="content.assetType" name="description" qaid="pdf-icon" />
      <ref-link
        :id="`content-product-tile-${status}`"
        class="product-tile__title text-lg--bold flex-1"
        :title="content.title"
        :link="content.url"
        :asset-type="content.assetType"
        :asset-location="content.assetLocation"
        :target="content.target"
      />
    </p>
  </div>
</template>

<script>
import RefLink from '@/components/ref-link/RefLink.vue';
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {RefLink, PdlIcon},
  props: {
    content: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    status: {
      type: String,
      default: () => '',
      required: true,
    },
  },
};
</script>
