import storefrontInstance from '@/api/instances/storefront';
import get from 'lodash/get';

const TechInfoApi = {
  /**
   * Find a product by serial number.
   * @param serialNumber
   * @return {Promise<any>}
   */
  async findProductBySerialNumber(serialNumber) {
    if (!serialNumber) {
      throw new Error('Serial number cannot be empty!');
    }

    const response = await storefrontInstance.get(`/product/serial/${serialNumber}/`);
    const productData = get(response, 'data.data', {});
    return productData && Object.keys(productData).length > 0 ? productData : null;
  },
};

export {TechInfoApi};
