<template>
  <pdl-icon :size="size">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentcolor" viewBox="0 0 24 24">
      <path
        d="M21.143 17.703h-.286V9.966C21.566 9.417 22 8.64 22 7.783a.861.861 0 0 0-.069-.332L20.71 4.526a.887.887 0 0 0-.183-.275c-.023-.022-.057-.034-.08-.057a.813.813 0 0 0-.195-.125.806.806 0 0 0-.32-.069H4.08a.767.767 0 0 0-.526.206c-.023.023-.045.023-.068.045a.844.844 0 0 0-.195.286L2.07 7.451a.807.807 0 0 0-.069.32v.012c0 .857.434 1.623 1.143 2.171v7.737h-.286A.863.863 0 0 0 2 18.55c0 .468.389.857.857.857h18.286a.863.863 0 0 0 .857-.857.863.863 0 0 0-.857-.858v.012ZM3.817 8.126h4.194C7.76 8.65 6.971 9.13 5.92 9.13c-1.051 0-1.84-.48-2.091-1.005h-.012Zm5.623-.857.194-1.578h4.72l.195 1.578h-5.12.011Zm4.663.857c-.252.525-1.04 1.005-2.092 1.005-1.051 0-1.84-.48-2.091-1.005h4.194-.011Zm4 1.005c-1.052 0-1.84-.48-2.092-1.005h4.195c-.252.525-1.04 1.005-2.092 1.005h-.011ZM20.01 7.28h-4.594l-.194-1.577h4.137l.651 1.577ZM4.651 5.703h4.126L8.583 7.28H4l.651-1.577Zm.206 5.017a5.19 5.19 0 0 0 1.052.126c1.257 0 2.331-.446 3.051-1.143.709.697 1.794 1.143 3.051 1.143 1.258 0 2.332-.446 3.052-1.143.708.697 1.794 1.143 3.051 1.143.366 0 .72-.046 1.052-.126v6.983h-7.292v-4.629a.663.663 0 0 0-.663-.663H7.67a.663.663 0 0 0-.663.663v4.629H4.857V10.72Zm6.149 6.983H7.863v-4.434h3.143v4.434Z"
      />
    </svg>
  </pdl-icon>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {PdlIcon},
  props: {
    size: {
      type: String,
      default: '',
    },
  },
};
</script>
