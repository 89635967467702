<template>
  <div qaid="productCompareHeroWrapper">
    <a class="block relative pt-4" :href="getProductUrl(product.url)" qaid="productCompareHeroLinkWrapper">
      <div class="prod-compare__img-wrap" qaid="productCompareHeroImageWrapper">
        <span class="absolute top-0 left-0 underline hover:no-underline" qaid="productCompareHeroTitle">{{
          product.name
        }}</span>
        <div class="prod-compare-image-wrapper">
          <img
            class="prod-compare__img"
            :alt="productImage.altText"
            :src="productImage.url"
            qaid="productCompareHeroImage"
          />
        </div>
      </div>
    </a>
    <trek-button
      v-if="showRemove"
      text
      icon="highlight_off"
      class="mt-1"
      qaid="productCompareHeroRemove"
      @click="removeAndReload({productCode: sku})"
    >
      <span v-html="bp('remove')"></span>
    </trek-button>
    <trek-link v-else button primary :href="getProductUrl(product.url)" class="mt-2" qaid="productCompareHeroView"
      ><span v-html="bp('view')"></span
    ></trek-link>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {Url} from '@/utils/url';
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';

export default {
  components: {TrekButton, TrekLink},
  props: {
    product: {
      type: Object,
      default: () => ({
        url: '',
        name: '',
        useSpecItem: false,
        images: [],
      }),
    },
    sku: {
      type: String,
      required: true,
    },
    showRemove: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    productImage() {
      return this.product.images[0];
    },
  },

  methods: {
    getProductUrl: (url) => Url.withContextPath(url),

    removeAndReload(product) {
      this.removeFromRack(product).then(() => {
        this.$emit('remove-product', product.productCode);
      });
    },

    ...mapActions('compare', ['removeFromRack']),
  },
};
</script>
