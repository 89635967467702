<template>
  <div>
    <span v-if="b2b">{{ b2bPrice.displayPrice }}</span>
    <template v-else-if="isOnSale">
      <span id="actualPrice" class="actual-price salePrice">{{ salePrice.displayPrice }}</span>
      <span id="wasPriceWrapper">
        <span id="wasPrice" class="beforeSalePrice">{{ wasPrice.displayPrice }}</span>
      </span>
    </template>
    <span v-else>{{ wasPrice.displayPrice }}</span>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {PriceRange} from '@/components/containers/pdp/utils/price-range';
import {Price} from '@/components/containers/pdp/utils/price';

export default {
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState('backend', ['b2b', 'isConsumerFriendlyMode']),

    b2bPrice() {
      const productPrice = this.isConsumerFriendlyMode ? this.product?.consumerPrice : this.product?.retailerPrice;

      return PriceRange.create(productPrice?.price);
    },
    isOnSale() {
      return this.product?.consumerPrice?.wasPrice?.low?.value !== this.product?.consumerPrice?.price?.low?.value;
    },
    salePrice() {
      return new Price(this.product?.consumerPrice?.price?.low);
    },
    wasPrice() {
      return new Price(this.product?.consumerPrice?.wasPrice?.low);
    },
  },
};
</script>
