<template>
  <section class="select-delivery-mode">
    <list-component v-if="!!deliveryModes.length" :is-overflow="true">
      <p :class="['form-feedback is-invalid mb-2', {'is-visible': errorRadio}]">
        {{ $t('form.field.required') }}<span class="text-red">*</span>
      </p>
      <list-item-component v-for="mode in deliveryModes" :key="mode.code">
        <template #pre-content>
          <label class="radio">
            <input
              v-model="selectedDeliveryMode"
              type="radio"
              :name="radioNameAttr"
              :checked="mode.isSelected"
              :value="mode.code"
              @change="updateDeliveryModeSelection(mode.code)"
            />
            <span class="control-indicator"></span>
          </label>
        </template>
        <template #content>
          <p class="font-bold">{{ mode.name }}&nbsp;&mdash;&nbsp;{{ mode.deliveryCost.formattedValue }}</p>
          <p>{{ mode.description }}</p>
        </template>
      </list-item-component>
    </list-component>
    <template v-else>
      <pdl-loading is-loading />
    </template>
  </section>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import ListItemComponent from '@/components/ListItemComponent.vue';
import ListComponent from '@/components/ListComponent.vue';
import {PdlLoading} from '@pedal/pdl-loading';
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';

export default {
  name: 'SelectDeliveryMode',
  components: {
    ListItemComponent,
    ListComponent,
    PdlLoading,
  },

  setup() {
    return {v$: useVuelidate()};
  },

  validations() {
    return {
      selectedDeliveryMode: {required, $lazy: true},
    };
  },

  data() {
    return {
      radioNameAttr: 'deliveryModeRadio',
    };
  },

  computed: {
    ...mapState('checkout', ['deliveryModes', 'selectedDeliveryMode']),

    errorRadio() {
      return this.v$.selectedDeliveryMode.$invalid;
    },
  },

  methods: {
    ...mapMutations('checkout', ['setSelectedDeliveryMode']),
    updateDeliveryModeSelection(selectedMode) {
      this.setSelectedDeliveryMode(selectedMode);
      this.v$.selectedDeliveryMode.$touch();
    },
  },
};
</script>
