<template>
  <div class="toggle-button-group flex flex-nowrap items-stretch">
    <trek-button
      v-for="button in buttons"
      :key="button.id"
      tertiary
      icon-only
      :icon="getIcon(button)"
      :class="{active: selected.indexOf(button.id) > -1}"
      :qaid="`toggleButton-${button.id}`"
      :aria-label="button.id"
      @click.prevent="selectItem(button)"
    ></trek-button>
  </div>
</template>

<script>
import TrekButton from '@/components/TrekButton';
import get from 'lodash/get';

export default {
  name: 'ToggleButtonGroup',

  components: {TrekButton},
  props: {
    value: {
      type: [String, Array],
      default: undefined,
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      required: true,
    },
    iconPath: {
      type: String,
      default: 'icon',
    },
  },

  data() {
    return {
      selected: [],
    };
  },
  watch: {
    value(value) {
      this.updateValue(value);
    },
  },

  mounted() {
    this.updateValue(this.value);
  },

  methods: {
    updateValue(value) {
      if (typeof value === 'string') {
        this.selected = [value];
      } else {
        this.selected = value;
      }
    },

    selectItem(item) {
      const index = this.selected.indexOf(item.id);
      if (index < 0) {
        if (!this.multiSelect) {
          this.selected = [];
        }

        this.selected.push(item.id);
      } else if (this.multiSelect || this.buttons.length === 1) {
        this.selected.splice(index, 1);
      }

      this.$emit('input', this.multiSelect ? this.selected : this.selected[0]);
    },

    getIcon(button) {
      return get(button, this.iconPath);
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-button-group {
  .button--icon-only {
    margin: 0;
    padding: 0.25rem;
  }

  .active {
    color: var(--green-100);
  }
}
</style>
