<template>
  <div>
    <pdl-section-header size="md" :is-dividing="true" class="mt-2 mb-4">
      <template slot="content">
        <pdl-heading qaid="order-details-header">{{ titleName }}</pdl-heading>
      </template>
    </pdl-section-header>
    <form-group
      :id="`${shipmentsEntry.type}-customer-po-number`"
      v-model="customerPO"
      class="md:w-1/2 lg:w-1/3"
      type="text"
      :qaid="`${shipmentsEntry.type}-customer-po-number`"
      :name="`${shipmentsEntry.type}-customer-po-number`"
      :maxlength="25"
      :label="$t('text.customerPo')"
      show-error-messages
      force-display-error
      required
      :error="getErrorMessage('customerPO')"
    />
    <form-group
      v-if="isDropShipCustomer"
      v-model="deliveryPort"
      class="md:w-1/2 lg:w-1/3"
      type="text"
      :maxlength="11"
      allowed-characters-regex="[A-Za-z0-9 ]"
      :qaid="`${shipmentsEntry.type}-port-delivery`"
      :name="`${shipmentsEntry.type}-port-delivery`"
      :label="$t('distributor.B2B.container.port')"
      help-text="default"
      show-error-messages
      force-display-error
      required
      :error="getErrorMessage('deliveryPort')"
    >
      <span slot="form-help" class="form-help">
        {{ $t('distributor.B2B.container.port.maxChars', [charsLeft]) }}
      </span>
    </form-group>
    <checkout-hold-options
      v-if="isHoldOptionsVisible"
      :shipments-entry="shipmentsEntry"
      @update-hold-options="updateHoldOptions"
    />
  </div>
</template>
<script>
import FormGroup from '@/components/FormGroup';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
import {ProductType} from '@/constants/product';
import CheckoutHoldOptions from '@/components/checkout/v2/CheckoutHoldOptions';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {FormGroup, CheckoutHoldOptions, PdlSectionHeader, PdlHeading},
  mixins: [TrekValidationMixin],
  props: {
    shipmentsEntry: {
      type: Object,
      required: true,
    },
    isDropShipCustomer: {
      type: Boolean,
      default: false,
    },
  },
  validations() {
    const validations = {
      customerPO: {
        required: this.trekValidators.requiredWithCustomMessage('myCart.B2B.poRequired'),
        maxLength: this.trekValidators.maxLength('text.customerPo', 25),
      },
    };

    const applyDropShipValidations = () => {
      validations.deliveryPort = {
        required: this.trekValidators.required('distributor.B2B.container.port'),
        maxLength: this.trekValidators.maxLength('text.customerPo', 25),
      };
    };

    if (this.isDropShipCustomer === true) applyDropShipValidations();

    return validations;
  },
  computed: {
    charsLeft() {
      const maxChars = 11;
      const charsLeft = maxChars - (this.shipmentsEntry.delivery?.port?.length || 0);

      return charsLeft > 0 ? charsLeft : 0;
    },
    titleNamesMap() {
      return {
        BIKE: this.$t('text.bikeOrderDetails'),
        AFTERMARKET: this.$t('text.accessoryOrderDetails'),
        BONTRAGER_CUSTOM_BOTTLE: this.$t('checkout.custom.orderDetails'),
        P1_BIKE: this.$t('checkout.projectOne.orderDetails'),
      };
    },
    titleName() {
      return this.titleNamesMap[this.shipmentsEntry.type];
    },
    isHoldOptionsVisible() {
      return !this.isDropShipCustomer && [ProductType.Bike, ProductType.Aftermarket].includes(this.shipmentsEntry.type);
    },
    customerPO: {
      get() {
        return this.shipmentsEntry.customerPO;
      },
      set(value) {
        this.$emit('update-shipments-entry', {property: 'customerPO', value});
      },
    },
    deliveryPort: {
      get() {
        return this.shipmentsEntry.delivery.port;
      },
      set(value) {
        this.updateDeliveryPort(value);
      },
    },
  },
  methods: {
    updateHoldOptions({property, value}) {
      let updatedHoldOptions = {...this.shipmentsEntry.holdOptions};
      updatedHoldOptions[property] = value;
      this.$emit('update-shipments-entry', {property: 'holdOptions', value: updatedHoldOptions});
    },
    updateDeliveryPort(value) {
      let updatedDelivery = {...this.shipmentsEntry.delivery};
      updatedDelivery.port = value;
      this.$emit('update-shipments-entry', {property: 'delivery', value: updatedDelivery});
    },
  },
};
</script>
