<template>
  <div class="mb-3 min-h-xs">
    <pdl-loading :is-loading="loading">
      <div v-show="!loading">
        <table class="b2b-grid b2b-grid--collapse is-compact">
          <caption v-if="caption" class="sr-only">
            {{
              caption
            }}
          </caption>
          <thead class="b2b-grid__header">
            <tr>
              <th
                v-for="header in gridColumns"
                :key="header.key"
                class="b2b-grid__cell b2b-grid__head whitespace-nowrap"
                :class="[header.cssClasses]"
                :qaid="`warranty-grid-${header.key}`"
                v-html="header.label"
              ></th>
            </tr>
          </thead>

          <tbody>
            <warranty-grid-item
              v-for="(row, index) in listData"
              :key="index"
              :columns="columns"
              :item="row"
              @delete-claim="$emit('delete-claim', $event)"
            ></warranty-grid-item>
          </tbody>
        </table>

        <div v-if="!loading && !hasClaims" class="flex justify-center text-secondary text-md my-5">
          {{ $t('warrantyClaims.B2B.noClaimsFound') }}
        </div>

        <pagination-controls
          v-if="localPageCount > 1 && !loading"
          ref="pagination"
          :page-number="gridData.pagination.currentPage"
          :page-count="gridData.pagination.numberOfPages"
          @page-change="pageChange"
          @prev-page="prevPage"
          @next-page="nextPage"
        />
      </div>
    </pdl-loading>
  </div>
</template>

<script>
import pagination from '../../../mixins/pagination';
import remove from 'lodash/remove';
import {PdlLoading} from '@pedal/pdl-loading';
import WarrantyGridItem from '@/components/containers/retailer/WarrantyGridItem';
import PaginationControls from '@/components/PaginationControls';

export default {
  components: {
    PdlLoading,
    PaginationControls,
    WarrantyGridItem,
  },
  mixins: [pagination],

  props: {
    caption: {
      type: String,
      default: '',
    },
    gridData: {
      type: Object,
      default: () => {
        return {entries: []};
      },
    },
    gridType: {
      type: String,
      default: undefined,
    },
    size: {
      type: Number,
      default: 50,
    },
    loading: Boolean,
  },

  computed: {
    hasClaims() {
      return this.gridData?.entries?.length > 0;
    },

    gridColumns() {
      if (this.gridType !== 'enumClaimStatusCompleted') {
        remove(this.columns, (column) => column.key === 'details');
      }

      if (this.gridType === 'enumClaimStatusCompleted') {
        remove(this.columns, (column) => column.key === 'actions');
      }

      return this.columns;
    },

    listData() {
      return this.gridData ? this.gridData.entries : [];
    },

    localPageCount() {
      return this.gridData?.pagination ? this.gridData.pagination.numberOfPages : 1;
    },

    columns() {
      return [
        {
          label: this.$t('warrantyClaims.B2B.claimNumber'),
          key: 'id',
        },
        {
          label: this.$t('text.dateSubmitted'),
          key: 'dateCreated',
        },
        {
          label: this.$t('text.poNumber'),
          key: 'poNumber',
        },
        {
          label: this.$t('product.variants.sku'),
          key: 'sku',
        },
        {
          label: this.$t('productRegistration.serialNumber'),
          key: 'serialNumber',
        },
        {
          label: this.$t('text.description'),
          key: 'description',
        },
        {
          label: this.$t('text.status'),
          key: 'statusName',
        },
        {
          label: this.$t('text.actions'),
          key: 'actions',
        },
        {
          label: this.$t('product.overview'),
          key: 'details',
        },
      ];
    },
  },

  methods: {
    pageChange(page) {
      if (page !== this.pageNumber) {
        this.pageNumber = page;
      }
      this.$emit('page-change', {gridType: this.gridType, pageNumber: this.pageNumber});
    },
  },
};
</script>
