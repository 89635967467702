<template>
  <pdl-dialog
    qaid="checkout-dialog"
    width="75vw"
    :visible="isModalVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <form>
      <pdl-collapse :value="activeTabs" :default-active="false" :accordion="true" header-classes="text-lg font-bold">
        <pdl-collapse-item :name="checkoutSteps[0].tabName">
          <template #title>
            <heading-tooltip :heading-config="headerConfig[checkoutSteps[0].component]" />
          </template>
          <component :is="checkoutSteps[0].component" />
        </pdl-collapse-item>
        <pdl-collapse-item v-if="hasSecondSelection" :title="checkoutSteps[1].title" :name="checkoutSteps[1].tabName">
          <template #title>
            <heading-tooltip :heading-config="headerConfig[checkoutSteps[1].component]" />
          </template>
          <component :is="checkoutSteps[1].component" />
        </pdl-collapse-item>
      </pdl-collapse>

      <div class="buttons buttons--right">
        <apple-pay-payment-button
          :is-form-valid="isFormValid"
          :is-express-checkout="true"
          qaid="apple-pay-button-modal"
          button-style="white-outline"
          @handle-express-validation="handleExpressValidation"
        />
        <pdl-button tertiary @click="closeCheckoutModal">Cancel</pdl-button>
      </div>
    </form>
  </pdl-dialog>
</template>

<script>
import {ApiDefaults} from '@/constants/api.js';
import {mapState, mapActions, mapMutations} from 'vuex';
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlButton} from '@pedal/pdl-button';
import ApplePayPaymentButton from '@/components/checkout/providers/apple-pay/ApplePayPaymentButton.vue';
import FindRetailers from '@/components/checkout/FindRetailers';
import SelectDeliveryMode from '@/components/checkout/SelectDeliveryMode';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {ExpressCheckoutAddressTemplate} from '@/constants/express-checkout.js';
import {expressSetupCartAddress} from '@/utils/checkout/express-checkout-utils.js';
import {DeliveryModes} from '@/constants/checkout.js';
import HeadingTooltip from '@/components/HeadingTooltip.vue';
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';

const DeliveryModesRequiringRetailer = [
  DeliveryModes.CLICK_AND_COLLECT,
  DeliveryModes.CLICK_AND_COLLECT_NO_BIKE,
  DeliveryModes.CLICK_AND_COLLECT_NO_BIKE_GROSS,
  DeliveryModes.CLICK_AND_COLLECT_BIKE_OVERRIDE,
  DeliveryModes.BOPIS,
];

export default {
  name: 'CheckoutDialog',
  components: {
    ApplePayPaymentButton,
    PdlButton,
    PdlDialog,
    FindRetailers,
    SelectDeliveryMode,
    PdlCollapse,
    PdlCollapseItem,
    HeadingTooltip,
  },

  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {v$: useVuelidate()};
  },

  validations() {
    const hasClickAndCollect = DeliveryModesRequiringRetailer.includes(this.selectedDeliveryMode);

    const localRules = {
      selectedDeliveryMode: {required},
    };

    if (hasClickAndCollect) {
      localRules.selectedStore = {
        required,
      };
    }
    return localRules;
  },

  data() {
    return {
      setupAddress: ExpressCheckoutAddressTemplate,
      activeTabs: ['DeliveryModes'],
      isFormValid: false,
    };
  },

  computed: {
    ...mapState('backend', ['geolocation', 'isUserLoggedIn']),
    ...mapState('checkout', ['selectedDeliveryMode', 'selectedStore', 'deliveryModes', 'hasBikes']),
    ...mapState('cart', ['cartData', 'dealerAddress']),

    headerConfig() {
      const level = '2';
      const size = 'sm';
      return {
        FindRetailers: {
          title: this.$t('checkout.multi.deliveryMethod.chooseRetailer'),
          tooltip: this.hasBikes ? this.$t('checkout.express.bike.selectRetailer') : null,
          tooltipId: 'checkout-info',
          iconLabel: this.$t('text.shippingInformation'),
          subtext: this.cartData.hasEbike ? this.$t('checkout.multi.deliveryMethod.ebikeRetailerRestriction') : null,
          level,
          size,
        },
        SelectDeliveryMode: {
          title: this.$t('checkout.multi.deliveryMethod.choose'),
          tooltip: this.$t('checkout.express.shippingNote'),
          tooltipId: 'checkout-info',
          iconLabel: this.$t('text.shippingInformation'),
          level,
          size,
        },
      };
    },
    hasSecondSelection() {
      return (
        (this.hasBikes && !!this.deliveryModes.length) ||
        (!this.hasBikes && DeliveryModesRequiringRetailer.includes(this.selectedDeliveryMode))
      );
    },
    checkoutSteps() {
      const RETAILER_COMPONENT_CONFIG = {
        tabName: 'Retailers',
        component: 'FindRetailers',
      };
      const DELIVERY_MODE_COMPONENT_CONFIG = {
        tabName: 'DeliveryModes',
        component: 'SelectDeliveryMode',
      };
      if (this.hasBikes) {
        return [RETAILER_COMPONENT_CONFIG, DELIVERY_MODE_COMPONENT_CONFIG];
      } else {
        return [DELIVERY_MODE_COMPONENT_CONFIG, RETAILER_COMPONENT_CONFIG];
      }
    },
  },

  watch: {
    isModalVisible: {
      async handler(value) {
        if (!value) {
          this.resetCheckoutDialog();
          this.setCheckoutDialogVisibility(this.isModalVisible);
          return;
        }
        this.setupExpressModalData();
      },
    },
    selectedStore: {
      async handler(value) {
        if (!value) {
          this.setIsSuppressingHomeDelivery(false);
          return;
        }
        await this.updateCartDealerAndMode({
          dealerCode: value,
          deliveryModeId: this.selectedDeliveryMode ? this.selectedDeliveryMode : DeliveryModes.CLICK_AND_COLLECT,
        });

        this.setIsSuppressingHomeDelivery(true);
        const address = this.dealerAddress?.address;
        this.setupAddress = expressSetupCartAddress(address);
        await this.updateCartAddress(this.setupAddress);
        await this.getCartDataWithValidation(ApiDefaults.FULL);

        if (this.checkoutSteps[0].component !== 'SelectDeliveryMode') {
          this.getDeliveryModes();
          this.activeTabs = ['DeliveryModes'];
        }
      },
    },
    selectedDeliveryMode: {
      async handler(value) {
        if (!value) return;
        if (DeliveryModesRequiringRetailer.includes(value)) {
          this.activeTabs = ['Retailers'];
          this.isFormValid = false;
        } else if (this.checkoutSteps[0].component === 'SelectDeliveryMode') {
          this.setSelectedStore(null);
        }

        await this.updateCartDealerAndMode({
          dealerCode: this.selectedStore,
          deliveryModeId: value,
        });
        await this.getCartDataWithValidation(ApiDefaults.FULL);
      },
    },
  },

  methods: {
    ...mapActions('checkout', ['getDeliveryModes']),
    ...mapActions('cart', [
      'updateCartEmail',
      'updateCartDealerAndMode',
      'getCartDataWithValidation',
      'updateCartAddress',
      'deleteCartAddress',
    ]),
    ...mapMutations('checkout', [
      'setSelectedStore',
      'setHasBikes',
      'setIsSuppressingHomeDelivery',
      'setDeliveryModes',
      'setSelectedDeliveryMode',
      'setDealers',
      'setDealerPostcode',
      'setSelectedStore',
      'setCheckoutDialogVisibility',
    ]),

    async setupExpressModalData() {
      if (!this.isUserLoggedIn) {
        await this.updateCartEmail();
      }
      if (this.geolocation?.countryCode) {
        const geoAddress = {
          town: this.geolocation?.city,
          postalCode: this.geolocation?.postalCode,
          country: {
            isocode: this.geolocation?.countryCode,
          },
          region: {
            isocodeShort: this.geolocation?.regionCode,
            countryIso: this.geolocation?.countryCode,
            isocode: `${this.geolocation?.countryCode}-${this.geolocation?.regionCode}`,
          },
        };

        this.setupAddress = expressSetupCartAddress(geoAddress);
      }

      await this.updateCartAddress(this.setupAddress);
      await this.getDeliveryModes();
      this.setHasBikes(!this.deliveryModes.length);

      if (this.hasBikes) {
        this.activeTabs = ['Retailers'];
      }

      this.setCheckoutDialogVisibility(this.isModalVisible);
    },
    async handleExpressValidation() {
      this.isFormValid = await this.v$.$validate();
    },
    closeCheckoutModal() {
      this.$emit('close-modal');
    },
    async resetCheckoutDialog() {
      this.activeTabs = ['DeliveryModes'];
      this.setDealers([]);
      this.setDealerPostcode(null);
      this.setSelectedStore(null);
      this.setSelectedDeliveryMode(null);
      this.setDeliveryModes([]);
      this.v$.$reset();
      this.isFormValid = false;
      await this.deleteCartAddress();
      this.getCartData();
    },
  },
};
</script>
