<template>
  <div class="b2b-grid__price">
    <template v-if="saleAdvertisedPrice">
      <div class="b2b-grid__discount">
        <span class="block line-through text-secondary"
          ><s>{{ advertisedPrice }}</s></span
        >
        <span class="block text-alert">{{ saleAdvertisedPrice }}</span>
      </div>
    </template>
    <template v-else>
      <div class="b2b-grid__price--no-discount">
        <span class="block">{{ advertisedPrice }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'GridAdvertisedPrice',
  props: {
    price: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    advertisedPrice() {
      return this.price?.advertised?.low?.formatted;
    },
    saleAdvertisedPrice() {
      return this.price?.saleAdvertised?.low?.formatted;
    },
  },
  methods: {
    hasSalePrice() {
      return this.price?.saleAdvertised?.low?.value;
    },
  },
};
</script>
