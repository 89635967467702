<template>
  <div v-cloak class="v-cloak--invisible">
    <!-- Header -->
    <pdl-section-header
      size="md"
      :is-dividing="true"
      :is-non-stacking="true"
      qaid="sku-grid.bulk-order"
      class="mt-5 xl:mt-0"
    >
      <template v-if="!isPdp" slot="content">
        <pdl-heading :level="2">{{ $t('appleLabel.bulk') }}</pdl-heading>
      </template>
      <template slot="actions">
        <div class="flex items-center text-sm font-medium" qaid="sku-grid.unavailable">
          {{ $t('product.stockStatus.filter.inStock') }}
          <grid-switch class="ml-1" :data="{id: 'show-unavailable'}" @switch-change="hideUnavailable = $event">
          </grid-switch>
        </div>
      </template>
    </pdl-section-header>

    <!-- Product Groups wrapper/iterator -->
    <div class="b2b-grid-group">
      <div
        v-for="(productGroup, productGroupIndex) in productGroups"
        :key="productGroupIndex"
        class="b2b-grid-group__section"
        :product-group-index="productGroupIndex"
      >
        <pdl-collapse
          icon-active="indeterminate_check_box"
          icon-inactive="add_box"
          icon-position="left"
          align-icon="start"
          :default-active="false"
          :value="viewBreakpointMoreThan('medium') ? [productGroupIndex] : null"
          qaid="bulk-product-collapse"
        >
          <bulk-product-grid
            :hide-unavailable="hideUnavailable"
            :image-size="{width: 192, height: 144}"
            :base-product="baseProductCode"
            :product-group="productGroup"
            :product-type="productGroup.productType"
            :product-group-index="productGroupIndex"
            :is-pdp="isPdp"
            :product-groups="productGroups"
            @add-to-cart="handleAddToCart"
            @update-stock-watch-state="(payload) => $emit('update-stock-watch-state', payload)"
            @update-cart-or-list-column="updateColumns"
          />
        </pdl-collapse>
      </div>
    </div>

    <pdp-archived-product-link v-if="isHybridActiveProduct && b2b" />

    <bundling-dialog
      :show-dialog="isBundleDialogVisible"
      :add-to-cart-button-caption="$t('text.addToCart')"
      @cancel="setBundleDialogVisibility({type: 'GRID', isVisible: false})"
      @add-to-cart="setBundleDialogVisibility({type: 'GRID', isVisible: false})"
    />
  </div>
</template>

<script>
import {mapMutations, mapState, mapGetters} from 'vuex';
import GridSwitch from '@/components/grid/GridSwitch';
import {PdlCollapse} from '@pedal/pdl-collapse';
import BulkProductGrid from '@/components/containers/bulk-product/BulkProductGrid';
import BundlingDialog from '@/components/containers/pdp/BundlingDialog';
import PdpArchivedProductLink from '@/components/containers/pdp/PdpArchivedProductLink';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {
    GridSwitch,
    PdlCollapse,
    BulkProductGrid,
    BundlingDialog,
    PdlSectionHeader,
    PdlHeading,
    PdpArchivedProductLink,
  },

  props: {
    productGroups: {
      type: Array,
      default: () => [],
    },
    isPdp: Boolean,
    productStatus: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      hideUnavailable: false,
    };
  },

  computed: {
    baseProductCode() {
      return !this.productGroups || !('code' in this.productGroups) ? '' : this.productGroups.code;
    },

    isBundleDialogVisible: {
      get() {
        return this.isGridBundleDialogVisible;
      },

      set(isVisible) {
        this.setBundleDialogVisibility({type: 'GRID', isVisible});
      },
    },

    isHybridActiveProduct() {
      return (
        this.productStatus?.baseProductArchivedStatus === 'HYBRID' &&
        (this.productStatus?.archivedStatus == 'HYBRID' || this.productStatus?.archivedStatus == 'ACTIVE') &&
        window.location.href.includes('/p/')
      );
    },

    ...mapState('skuGrid', ['baseProductsSkus']),
    ...mapState('pdp', ['isGridBundleDialogVisible']),
    ...mapState('backend', ['b2b']),
    ...mapState('productBundle', ['bundleTemplateIds']),
    ...mapState('viewSize', ['viewBreakpoint']),
    ...mapGetters('viewSize', ['viewBreakpointMoreThan']),
  },

  methods: {
    updateColumns(responseData) {
      const responseEntries = responseData.response;
      const colName = responseData.colName;
      this.productGroups.forEach((productGroup) => {
        const products = productGroup.products;
        this.$store.dispatch('skuGrid/updateCartOrListColumn', {products, responseEntries, colName}).then((res) => {
          productGroup.products = res;
        });
      });
    },

    handleAddToCart() {
      let bundleIds = [];

      //   Get the respective bundletemplateId, if present, open up the bundle modal.
      this.baseProductsSkus.forEach((code) => {
        let id = this.bundleTemplateIds.find((id) => id.includes(code));
        id && bundleIds.push(id);
        this.setActiveIds(bundleIds);
      });

      if (bundleIds.length > 0) this.setBundleDialogVisibility({type: 'GRID', isVisible: true});
    },

    ...mapMutations('productBundle', ['setActiveIds']),
    ...mapMutations('pdp', ['setBundleDialogVisibility']),
  },
};
</script>
