const PdpPanelSwiperStyles = [
  `.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom:1.563rem;
  }`,
  ` .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  background-color:var(--gray-20);
  opacity:1;
  padding:0;
  font-size:0;
  margin-right:0.5rem;
  border-radius:100%;
}
`,
  ` .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color:var(--gray-100);
}
`,
  `@media screen and (min-width:1024px) {
  .swiper-pagination {
  display:none;
}
}`,
  `@media screen and (max-width:639px) {
  .swiper-button-prev,.swiper-button-next {
  display:none;
}
}`,
];

const PdpImageZoomSwiperStyles = [
  ` .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    background-color:var(--gray-20);
    border-radius: 0.063rem;
    margin-right: 1rem;
    width: 1.5rem;
    height: 0.1875rem;
    opacity:1;
    padding:0;
    font-size:0;
}
`,
  ` .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color:var(--gray-100);
}
`,
];

const PdpImageCommonSwiperStyles = [
  `.swiper-button-prev,
.swiper-button-next {
  align-items: center;
  justify-content: center;
  background-image: none;
  color: var(--gray-20);
  width: 3rem;
  height: 3rem;
}`,
  `.swiper-button-prev:hover,.swiper-button-next:hover {
  color:var(--gray-100);
}
`,
  `.swiper-button-disabled {
  display:none;
}
`,
  `.swiper-button-next svg,.swiper-button-prev svg {
  width:0.906rem;
  height:1.5rem;
}
`,
  `.swiper-button-next svg path,.swiper-button-prev svg path {
  stroke-width:2px;
  stroke:currentColor;
}
`,
  ` .swiper-button-next {
  right:0;
}
`,
  ` .swiper-button-prev {
  left:0;
}
`,
];

export {PdpPanelSwiperStyles, PdpImageZoomSwiperStyles, PdpImageCommonSwiperStyles};
