<template>
  <div>
    <pdl-steps v-model="currentStep" @change="navigate">
      <pdl-steps-item v-for="step in steps" :key="step.progressBarId" :qaid="`${step.progressBarId}-nav-link`">
        {{ $t(`trek.checkout.multi.${step.progressBarId}`) }}
      </pdl-steps-item>
    </pdl-steps>
  </div>
</template>

<script>
import {PdlSteps, PdlStepsItem} from '@pedal/pdl-steps';
import {mapState} from 'vuex';

export default {
  components: {PdlSteps, PdlStepsItem},

  props: {
    steps: {
      type: Array,
      default: () => [],
      required: true,
    },
    progressBarId: {
      type: String,
      default: '',
      required: true,
    },
  },

  data() {
    return {
      currentStep: 0,
    };
  },

  computed: {
    ...mapState('backend', ['encodedContextPath']),
  },

  created() {
    this.currentStep = this.steps.indexOf(this.steps.find((item) => item.progressBarId === this.progressBarId));
  },

  methods: {
    navigate(stepIndex) {
      if (this.steps?.length > stepIndex) {
        window.location.href = `${this.encodedContextPath}${this.steps[stepIndex].url}`;
      }
    },
  },
};
</script>
