<template>
  <div v-cloak class="grid-container text-md pb-3">
    <pdl-loading page :is-loading="loading" />
    <div v-if="viewOnly && !loading">
      <warranty-claim-view :claim-data="claimData" :display-warranty-make-the-call="displayWarrantyMakeTheCall" />
    </div>
    <div v-else>
      <form id="warranty-claim-form" @submit.prevent="onSubmit">
        <!-- Claim Edit Form view-->
        <warranty-claim-header :claim-data="claimData" :claim-number="claimNumber" />
        <warranty-claim-details :claim-data="claimData" :display-warranty-make-the-call="displayWarrantyMakeTheCall" />

        <!-- Claim Lines -->
        <section class="my-6">
          <pdl-section-header size="lg" :is-dividing="true">
            <template slot="content">
              <pdl-heading :level="2" qaid="warranty-claim-lines">
                {{ $t('warrantyClaims.B2B.claimLines') }}
              </pdl-heading>
            </template>
          </pdl-section-header>

          <pdl-collapse
            icon-active="indeterminate_check_box"
            icon-inactive="add_box"
            :default-active="false"
            :value="expandedClaims"
            qaid="warranty-claim-collapse"
            :is-sticky="true"
            :sticky-header-offset="headerHeight"
            @change="claimLineExpansionChange"
          >
            <pdl-collapse-item
              v-for="(claimLine, claimIndex) in claimData.claimLines"
              :key="claimIndex"
              :name="`line${claimIndex}`"
            >
              <div slot="title" class="heading heading--md" qaid="warranty-claim-line-title">
                {{ $t('warrantyClaims.B2B.claimLineNumber', [claimIndex + 1]) }}
              </div>

              <warranty-claim-line
                :claim-data="claimData"
                :claim-index="claimIndex"
                :claim-line="claimLine"
                :display-warranty-shipment-options="displayWarrantyShipmentOptions"
                :display-warranty-make-the-call="displayWarrantyMakeTheCall"
                :is-proof-of-purchase-image-required="isProofOfPurchaseImageRequired"
                @open-delete-dialog="openclaimLineDeleteDialog"
              />
            </pdl-collapse-item>
          </pdl-collapse>

          <template v-if="!isActionRequiredStatus">
            <trek-button text underline class="mt-4" qaid="warranty-claim-line-add-claim" @click="addNewClaimLine">
              <span>{{ $t('warrantyClaims.B2B.addClaimLine') }}</span>
            </trek-button>

            <hr class="rule mt-4" />
          </template>

          <div class="mt-4">
            <div class="buttons">
              <trek-button :disabled="claimSubmitted" primary qaid="warranty-claim-submit" type="submit">
                <span>{{ $t('warrantyClaims.B2B.submitClaim') }}</span>
              </trek-button>
              <trek-button
                v-if="!isActionRequiredStatus"
                secondary
                qaid="warranty-claim-close"
                @click="saveClaim({toast: true})"
              >
                <span>{{ $t('warrantyClaims.B2B.saveClose') }}</span>
              </trek-button>
              <trek-button
                v-show="claimData.id && claimData.status === 'enumClaimStatusSaved'"
                tertiary
                qaid="warranty-claim-delete"
                @click="isDeleteClaimDialogVisible = true"
              >
                <span>{{ $t('warrantyClaims.B2B.deleteClaim') }}</span>
              </trek-button>
            </div>
          </div>

          <pdl-dialog
            :visible.sync="isDeleteClaimLineDialogVisible"
            :title="$t('warrantyClaims.B2B.deleteClaimLine.confirm')"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            :show-close="false"
            :z-index="16000011"
            qaid="delete-claim-line-dialog"
            @close="isDeleteClaimLineDialogVisible = false"
          >
            <template slot="footer">
              <div class="buttons buttons--right">
                <trek-button alert qaid="warranty-claim-confirm-delete" @click="deleteClaimLine"
                  ><span>{{ $t('warrantyClaims.B2B.deleteClaimLine.yes') }}</span>
                </trek-button>
                <trek-button
                  secondary
                  qaid="warranty-claim-cancel-delete"
                  @click="isDeleteClaimLineDialogVisible = false"
                  ><span>{{ $t('text.cancel') }}</span>
                </trek-button>
              </div>
            </template>
          </pdl-dialog>

          <pdl-dialog
            :visible.sync="isDeleteClaimDialogVisible"
            :title="$t('warrantyClaims.B2B.deleteConfirmCheck')"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            :show-close="false"
            :z-index="16000011"
            qaid="delete-claim-dialog"
            @close="isDeleteClaimDialogVisible = false"
          >
            <div>{{ $t('warrantyClaims.B2B.claimNotSubmitted') }}</div>

            <template slot="footer">
              <div class="buttons buttons--right">
                <trek-button alert qaid="warranty-claim-confirm-delete" @click="deleteClaim">
                  <span>{{ $t('warrantyClaims.B2B.deleteConfirm') }}</span>
                </trek-button>
                <trek-button secondary qaid="warranty-claim-cancel-delete" @click="isDeleteClaimDialogVisible = false">
                  <span>{{ $t('text.cancel') }}</span>
                </trek-button>
              </div>
            </template>
          </pdl-dialog>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import {PdlLoading} from '@pedal/pdl-loading';
import storefrontInstance from '@/api/instances/storefront';
import {mapActions, mapState, mapGetters} from 'vuex';
import {PdlDialog} from '@pedal/pdl-dialog';
import get from 'lodash/get';
import remove from 'lodash/remove';
import TrekButton from '@/components/TrekButton';
import WarrantyClaimHeader from '@/components/containers/warranty-claim/WarrantyClaimHeader';
import WarrantyClaimDetails from '@/components/containers/warranty-claim/WarrantyClaimDetails';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';
import {PdlToastType} from '@/constants/pdl-toast-type';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {scrollToElement, delayedScrollToElement} from '@/utils/scroll-to';
import useVuelidate from '@vuelidate/core';

export default {
  components: {
    PdlCollapse,
    PdlCollapseItem,
    PdlDialog,
    TrekButton,
    PdlLoading,
    PdlSectionHeader,
    PdlHeading,
    WarrantyClaimHeader,
    WarrantyClaimDetails,
  },
  provide() {
    return {
      anyErrors: this.anyErrors,
    };
  },

  props: {
    claimId: {
      type: String,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      defaut: false,
      required: false,
    },
    displayWarrantyShipmentOptions: {
      type: Boolean,
      default: true,
    },
    displayWarrantyMakeTheCall: {
      type: Boolean,
      default: false,
    },
    isProofOfPurchaseImageRequired: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      isDeleteClaimLineDialogVisible: false,
      isDeleteClaimDialogVisible: false,
      claimLineToDelete: null,
      expandedClaims: ['line0'],
      claimNumber: '',
      claimSubmitted: false,
    };
  },

  computed: {
    ...mapState('global', ['headerHeight']),
    ...mapState('warrantyClaims', ['claimData', 'loading']),
    ...mapState('backend', ['encodedContextPath']),
    ...mapGetters('warrantyClaims', ['isActionRequiredStatus']),
  },

  mounted() {
    this.fetchClaimData(this.claimId);
  },

  methods: {
    addNewClaimLine() {
      this.expandedClaims.push(`line${this.claimData.claimLines.length}`);

      let highestId = 1;

      if (this.claimData.claimLines && this.claimData.claimLines.length) {
        highestId = parseInt(this.claimData.claimLines[this.claimData.claimLines.length - 1].id);
      }

      this.claimData.claimLines.push({
        id: `${highestId + 1}`,
        productType: '',
        qty: 1,
        selectedDamagedOccurred: '',
        selectedRequestedAction: '',
        warrantyConsumerWaiting: '',
        warrantyDamageImage: {name: null, url: null, encodedImage: null},
        warrantyPurchaseProofImages: [],
        warrantySerialNumberImage: {name: null, url: null, encodedImage: null},
        warrantyClaimIssueUfoCode: '',
        warrantyLineNumber: '',
        warrantyOptionalAttachments: [],
        serialNumberImage: {name: null, url: null, encodedImage: null},
        damageMethod: {code: null},
        purchasedDate: '',
        purchaseDateDisplay: '',
        serialNumber: '',
        status: '',
        size: '',
        sku: '',
        modelYear: '',
        modelName: '',
      });

      this.saveClaim();
    },

    claimLineExpansionChange(payload) {
      this.expandedClaims = payload;
    },

    anyErrors() {
      return !!this.v$.$errors.length;
    },

    deleteClaimLine() {
      this.isDeleteClaimLineDialogVisible = false;

      if (this.claimData.id) {
        // If we have a claim id then this claim has been autosaved and we can BE delete the line
        storefrontInstance
          .delete(`/claims/delete/${this.claimData.id}/${this.claimData.id}_${this.claimLineToDelete}`)
          .finally(() => this.fetchClaimData(this.claimData.id));
      } else {
        // Else we can just locally delete it as this claim doesn't exist in the BE yet
        remove(this.claimData.claimLines, (claimLine) => claimLine.id === this.claimLineToDelete);

        // Re-assign ids based on order
        this.claimData.claimLines.forEach((claimLine, index) => {
          claimLine.id = `${index + 1}`;
        });

        this.$notify({
          type: PdlToastType.SUCCESS,
          message: this.$t('warrantyClaims.B2B.deleteClaimLine.thankYou'),
        });
      }
    },

    openclaimLineDeleteDialog(claimLineToDelete) {
      this.isDeleteClaimLineDialogVisible = true;
      this.claimLineToDelete = claimLineToDelete;
    },

    deleteClaim() {
      this.isDeleteClaimDialogVisible = false;

      storefrontInstance.delete(`/claims/delete/${this.claimData.id}`).then((response) => {
        const message = get(response, 'data.meta.feedback.message.basePropertyKey', null);

        if (message === 'warrantyClaims.B2B.claimDeleted') {
          setTimeout(() => {
            window.location = `${this.encodedContextPath}/warranty-claim/all-warranty-claims/`;
          }, 3000);
        }
      });
    },

    async validateForm() {
      // returns a promise of all children Vuelidate validators
      const isValid = await this.v$.$validate();

      if (isValid) {
        const submitResponse = await this.submitClaim();
        return submitResponse;
      } else {
        this.scrollToFirstError();
        this.claimSubmitted = false;
      }
    },

    onSubmit() {
      this.claimSubmitted = true;

      this.validateForm().then((submitResponse) => {
        if (submitResponse?.data && checkMetaFeedbackType(submitResponse.data, MetaFeedbackType.SUCCESS)) {
          const feedbackMsg = this.$t('warrantyClaims.B2B.submission.success', [submitResponse.data?.data?.id]);
          this.setFeedbackMsg(feedbackMsg);
          window.location = `${this.encodedContextPath}/warranty-claim/all-warranty-claims/`;
        } else {
          this.claimSubmitted = false;
        }
      });
    },

    expandAllLinesWithErrors(allErrors) {
      const linesWithErrors = [];
      let currentLine;

      allErrors.forEach((error) => {
        // check if error is in a collapsed component
        if (error.offsetHeight !== 0) return;
        // find the collapse-item's name
        const lineName = error.closest('.pdl-collapse-item').getAttribute('name');
        // make sure we aren't adding it more than once
        if (currentLine === lineName) return;
        // add collapse-item to the opened item array
        linesWithErrors.push(lineName);
        currentLine = lineName;
      });

      this.expandedClaims = [...new Set(linesWithErrors.concat(this.expandedClaims))];
    },

    scrollToFirstError() {
      const allErrors = document.querySelectorAll('.has-error');
      const firstErrorElement = allErrors[0];
      const isErrorHidden = firstErrorElement.offsetHeight === 0;
      const offset = this.headerHeight + 80;

      this.expandAllLinesWithErrors(allErrors);

      if (isErrorHidden) {
        delayedScrollToElement(firstErrorElement, offset);
      } else {
        scrollToElement(firstErrorElement, offset);
      }
    },

    ...mapActions('warrantyClaims', ['fetchClaimData', 'saveClaim', 'submitClaim']),
    ...mapActions('storage', ['setFeedbackMsg']),
  },
};
</script>

<style lang="scss">
.pdl-collapse-item {
  .is-sticky {
    z-index: 2;
  }
}
</style>
