<template>
  <div>
    <form-group
      v-model="cardType"
      name="cardType"
      :label="$t('payment.cardType')"
      type="select"
      :multi-items="cardTypeItems"
      required
      force-display-error
      show-error-messages
      :error="fieldError('cardType')"
    />
    <form-group
      v-model="cardNumber"
      v-mask
      :data-mask="cardFormat"
      name="cardNumber"
      :label="$t('payment.cardNumber')"
      autocomplete="cc-number"
      required
      force-display-error
      show-error-messages
      :error="fieldError('cardNumber')"
    />
    <form-group
      v-model="cardName"
      name="cardName"
      :label="$t('payment.nameOnCard')"
      autocomplete="cc-name"
      required
      force-display-error
      show-error-messages
      :error="fieldError('cardName')"
    />
    <form-group
      v-model="cvn"
      v-mask
      :data-mask="cvnFormat"
      name="cvn"
      :label="$t('checkout.payment.cvv.security.code')"
      autocomplete="cc-csc"
      required
      force-display-error
      show-error-messages
      :error="fieldError('cvn')"
    />
    <em class="form__help text-small-emphasized cvv_code--msg mb-1">
      <span>{{ $t('checkout.payment.card.back.number') }}</span>
    </em>
    <fieldset>
      <legend class="form-label" for="">{{ $t('checkout.expiration.date') }}</legend>
      <div class="flex space-x-2">
        <form-group
          v-model="expiryMonth"
          name="expiryMonth"
          :label="$t('payment.month')"
          type="select"
          :multi-items="monthItems"
          required
          force-display-error
          show-error-messages
          :error="fieldError('expiryMonth')"
        />
        <form-group
          v-model="expiryYear"
          name="expiryYear"
          :label="$t('payment.year')"
          type="select"
          :multi-items="yearItems"
          required
          force-display-error
          show-error-messages
          :error="fieldError('expiryYear')"
        />
      </div>
    </fieldset>
    <form-group
      v-if="userEmail && !isRestrictedToRetailer"
      type="checkbox"
      :multi-items="shouldSaveBillingInfoCheckbox"
      :label-classes="'text-sm'"
      name="shouldSaveBillingInfo"
      show-label
      :required="false"
      @change="handleCheckboxChange"
    />
  </div>
</template>

<script>
import getCardPattern from '@/utils/masks/credit-card-mask-patterns.js';
import {mask} from '@/directives/masks';
import FormGroup from '@/components/FormGroup.vue';
import {mapState, mapMutations, mapGetters} from 'vuex';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  components: {FormGroup},
  directives: {mask},
  mixins: [TrekValidationMixin],
  inject: ['validationErrors'],
  props: {
    monthList: {
      type: Array,
      default: () => [],
    },
    yearList: {
      type: Array,
      default: () => [],
    },
    cardTypeList: {
      type: Array,
      default: () => [],
    },
  },
  validations() {
    return {
      cardType: {
        required: this.trekValidators.requiredWithCustomMessage('payment.cardType.invalid'),
      },
      cardNumber: {
        required: this.trekValidators.requiredWithCustomMessage('payment.cardNumber.invalid'),
      },
      cardName: {
        required: this.trekValidators.requiredWithCustomMessage('payment.nameOnCard.invalid'),
      },
      cvn: {
        required: this.trekValidators.requiredWithCustomMessage('payment.cvv.invalid'),
      },
      expiryMonth: {
        required: this.trekValidators.requiredWithCustomMessage('payment.expiryMonth.invalid'),
      },
      expiryYear: {
        required: this.trekValidators.requiredWithCustomMessage('payment.expiryYear.invalid'),
      },
    };
  },
  computed: {
    ...mapState('checkout', ['paymentData']),
    ...mapState('user', ['userEmail']),
    ...mapGetters('checkout', ['isRestrictedToRetailer']),
    cardNumber: {
      get() {
        return this.paymentData.cardNumber;
      },
      set(cardNumber) {
        this.setCardNumber(cardNumber);
      },
    },
    cardName: {
      get() {
        return this.paymentData.cardName;
      },
      set(cardName) {
        this.setCardName(cardName);
      },
    },
    cvn: {
      get() {
        return this.paymentData.cvn;
      },
      set(cvn) {
        this.setCvn(cvn);
      },
    },
    cvnFormat() {
      return this.cardType === 'amex' ? '####' : '###';
    },
    cardType: {
      get() {
        return this.paymentData.cardType;
      },
      set(cardType) {
        this.setCardType(cardType);
      },
    },
    expiryMonth: {
      get() {
        return this.paymentData.expiryMonth;
      },
      set(expiryMonth) {
        this.setExpiryMonth(expiryMonth);
      },
    },
    expiryYear: {
      get() {
        return this.paymentData.expiryYear;
      },
      set(expiryYear) {
        this.setExpiryYear(expiryYear);
      },
    },
    shouldSaveBillingInfo: {
      get() {
        return this.paymentData.shouldSaveBillingInfo;
      },
      set(shouldSaveBillingInfo) {
        this.setShouldSaveBillingInfo(shouldSaveBillingInfo);
      },
    },
    shouldSaveBillingInfoCheckbox() {
      return [
        {
          name: 'shouldSaveBillingInfo',
          label: 'should-save-billing-info-checkbox',
          value: 'shouldSaveBillingInfo',
          displayName: this.$t('checkout.multi.paymentMethod.addPaymentDetails.savePaymentDetailsInAccount'),
          checked: this.shouldSaveBillingInfo,
        },
      ];
    },
    cardFormat() {
      return getCardPattern(this.cardType);
    },
    monthItems() {
      return this.monthList?.map(({id, label}) => ({
        value: label,
        name: id,
        displayName: label,
      }));
    },
    yearItems() {
      return this.yearList?.map(({id, label}) => ({
        value: id,
        name: id,
        displayName: label,
      }));
    },
    cardTypeItems() {
      return this.cardTypeList?.map(({id, label}) => ({
        value: id,
        name: id,
        displayName: label,
      }));
    },
  },
  mounted() {
    this.setCardType(this.cardTypeList?.[0]?.id);
    this.setExpiryMonth(this.monthList?.[0]?.label);
    this.setExpiryYear(this.yearList?.[0]?.id);
  },
  methods: {
    ...mapMutations('checkout', [
      'setCardNumber',
      'setCardName',
      'setCvn',
      'setCardType',
      'setExpiryMonth',
      'setExpiryYear',
      'setShouldSaveBillingInfo',
    ]),
    fieldError(fieldName) {
      const theError =
        this.validationErrors.find((item) => item.field === fieldName)?.msg ?? this.getErrorMessage(fieldName);

      return theError;
    },
    handleCheckboxChange(e) {
      this.shouldSaveBillingInfo = e.target.checked;
    },
  },
};
</script>
