<template>
  <table class="b2b-table b2b-table--vstripe b2b-product-compare-table" qaid="productCompareLoveWrapper">
    <tbody>
      <tr
        v-for="(section, sectionIndex) in sectionData.compareItems"
        :key="sectionIndex"
        :qaid="`productCompareLoveRow-${sectionIndex}`"
      >
        <th scope="row" :qaid="`productCompareLoveLabel-${sectionIndex}`">{{ section.label }}</th>
        <td
          v-for="(product, productIndex) in section.products"
          :key="productIndex"
          :data-spec="section.label"
          :qaid="`productCompareLoveCell-${productIndex}`"
        >
          <template v-if="product && section.code === 'localizedVideoOverview'">
            <a
              :title="product.caption"
              :href="product.url"
              data-video
              class="sprocket__play-overlay"
              :qaid="`productCompareLoveVideoLink-${productIndex}`"
            >
              <img :src="product.stillAssetId" alt="" />
              <pdl-icon name="play_circle_outline" class="text-white" size="48" :label="$t('text.watch.video')" />
            </a>
          </template>
          <template v-else>
            <div :qaid="`productCompareLoveText-${productIndex}`">{{ product }}</div>
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {
    PdlIcon,
  },
  props: {
    sectionData: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
