<template>
  <div id="route-details-elevation-chart" class="grid-container">
    <div class="flex justify-end mb-1">
      <rcp-builder-route-info-tab
        class="rcp-details-page"
        :total-distance="totalRouteDistance"
        :total-elevation-gain="totalElevationGain"
      />
    </div>
    <trek-chart ref="rcpElevationChart" :chart-options="chartOptions" />
  </div>
</template>

<script>
import TrekChart from '@/components/trek-chart/components/TrekChart';
import {DEFAULT_CHART_OPTIONS, convertChartArrayToChartOptions} from '@/rcpBuilder/utils/chart-options';
import {
  convertChartDataToPoints,
  calculateElevationChartRange,
  calculateMinAltitude,
} from '@/rcpBuilder/utils/elevation-helpers';
import {mapState, mapMutations} from 'vuex';
import {convertMetersToElevationUnit} from '@/utils/unit-converter';
import RcpBuilderRouteInfoTab from '@/rcpBuilder/components/utility/RcpBuilderRouteInfoTab';

export default {
  components: {TrekChart, RcpBuilderRouteInfoTab},
  props: {
    detailPageData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('backend', ['preferredUnit']),
    chartOptions() {
      if (!this.detailPageData?.elevationData) return DEFAULT_CHART_OPTIONS;

      const updatedValues = this.getRequiredElevationChartData();
      this.setChartOptions(updatedValues);
      return updatedValues;
    },
    totalRouteDistance() {
      if (!this.detailPageData?.distance && !this.detailPageData?.rideMetrics?.totalDistance) return 0;
      return this.detailPageData?.rideMetrics?.totalDistance
        ? this.detailPageData?.rideMetrics?.totalDistance
        : this.detailPageData?.distance;
    },
    totalElevationGain() {
      if (!this.detailPageData?.elevationData && !this.detailPageData?.rideMetrics?.totalElevationGain) return 0;
      return this.detailPageData?.rideMetrics?.totalElevationGain
        ? this.detailPageData?.rideMetrics?.totalElevationGain
        : this.detailPageData?.elevationData?.totalElevationGain;
    },
    elevationChartRefTemplate() {
      return this.$refs.rcpElevationChart?.$refs.trekChart.chart
        ? this.$refs.rcpElevationChart?.$refs.trekChart.chart
        : undefined;
    },
  },
  methods: {
    ...mapMutations('rcpBuilder', ['setChartOptions']),
    ...mapMutations('rcpRoutes', ['setMapPoint']),
    getRequiredElevationChartData() {
      const chartPoints = convertChartDataToPoints(this.detailPageData.elevationData.segments, this.preferredUnit);
      const yAxisMax = convertMetersToElevationUnit(this.detailPageData.elevationData.maxAltitude, this.preferredUnit);
      const yAxisMin = convertMetersToElevationUnit(
        calculateMinAltitude(this.detailPageData.elevationData.segments),
        this.preferredUnit
      );
      const yAxisTickLabel = calculateElevationChartRange(yAxisMax, yAxisMin, this.preferredUnit);
      return convertChartArrayToChartOptions(
        chartPoints,
        this.preferredUnit,
        {yAxisTickLabel, yAxisMin, yAxisMax},
        {
          handleChartPointHover: this.handleChartPointHoverOnStaticMap,
          hideHoverIconFromChart: this.hideHoverIconFromChartStaticMap,
        },
        {left: 0, right: 0}
      );
    },
    handleChartPointHoverOnStaticMap(evt) {
      this.setMapPoint(evt.target.geometry);
    },
    hideHoverIconFromChartStaticMap() {
      this.$emit('hideHoverIcon');
    },
  },
};
</script>
