<template>
  <dl class="description-list">
    <template v-for="(item, index) in list">
      <dt
        :key="`${index}-title`"
        :qaid="item.qaid ? `${item.qaid}-title` : null"
        :class="['description-list__title', item.titleClass]"
        v-html="item.title"
      />
      <dd :key="`${index}-description`" :class="['description-list__description', item.descriptionClass]">
        <span :qaid="item.qaid ? `${item.qaid}-description` : null" v-html="item.description" />
        <span v-if="item.icon" class="pl-2">
          <pdl-icon
            v-tippy="{
              html: `#description-tooltip-${index}`,
              placement: 'bottom',
            }"
            :name="item.icon.name"
            :qaid="item.qaid ? `${item.qaid}-icon` : null"
          />
        </span>
        <span
          v-if="getTooltipContents(item)"
          :id="`description-tooltip-${index}`"
          class="hidden"
          v-html="getTooltipContents(item)"
        />
      </dd>
    </template>
  </dl>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'DescriptionList',
  components: {PdlIcon},
  props: {
    /**
     * An array of objects for each item in the list. Each item must contain a title & description, and can optionally contain a QAID. Titles must be unique. You can optionally pass classes to an item with titleClass or descriptionClass.
     * If you want to display an icon next to the item description you can include an item.icon object which
     * contains the icon.name for the icon to be displayed and icon.tooltip for the screen readers and hover text.
     */
    list: {
      type: Array,
      validator: (list) => list.every((item) => Boolean(item.title) && Boolean(item.description)),
      required: true,
    },
  },
  methods: {
    getTooltipContents(item) {
      return item.icon?.tooltip ? item.icon.tooltip : null;
    },
  },
};
</script>

<style scoped lang="scss">
.description-list {
  @apply grid;
  @apply grid-cols-2;
  @apply gap-x-8;
  @apply text-base;
  @apply text-gray-100;

  &__title {
    @apply mb-2;
    @apply font-medium;
  }

  &__description {
    @apply mb-2;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
}
</style>
