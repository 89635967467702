export default function () {
  var privacyPolicyAcceptCheckbox = document.getElementById('privacyPolicyAccept');

  if (document.body.contains(document.getElementById('privacyPolicyAccept'))) {
    $('.button-submit').addClass('disabled');

    privacyPolicyAcceptCheckbox.addEventListener('change', (e) => {
      if (e.target.checked) {
        $('.button-submit').removeClass('disabled');
      } else {
        $('.button-submit').addClass('disabled');
      }
    });
  }

  window.email_fill = function () {
    var email = $('#form_email').val();
    if (email.match(/^\s*$/)) {
      $('.button-submit').addClass('disabled');
      $('.button-submit').attr('onClick', '');
    } else if (!privacyPolicyAcceptCheckbox || privacyPolicyAcceptCheckbox.checked) {
      $('.button-submit').removeClass('disabled');
      $('.button-submit').attr('onClick', 'newsletter_submit()');
    }
  };

  window.newsletter_submit = function () {
    var emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    var email = $('#form_email').val();
    if (!emailReg.test(email) || email == '' || email == undefined) {
      $('#form_email').parent().addClass('form__item--error alert-icon-after');
      scrollTo(0, 0);
    } else {
      $('#form_email').parent().removeClass('form__item--error alert-icon-after');
      $('#newsletter_submit').submit();
    }
  };
}
