<template>
  <div>
    <div v-if="isProjectOne" :id="`p1-eta-tooltip-${productTypeId}-${etaItem.id}`" v-tippy-html>
      {{ $t('orderHistory.B2B.etaWatch.cannotDisable') }}
    </div>
    <button
      v-tippy="tippyOptions"
      class="b2b-grid__stock-watch__indicator"
      :class="stockWatchClasses"
      @click="updateEtaWatch(etaItem, updateEtaWatchPayload)"
    >
      <pdl-icon size="18" :name="stockWatchIcon" />
      <span :class="`b2b-grid__stock-watch__label `">{{ $t('text.watch') }}</span>
    </button>
  </div>
</template>

<script>
import OrderItemApi from '@/api/order-item-api.js';
import {ProductType} from '@/constants/product';
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {PdlIcon},
  props: {
    etaItem: {
      type: Object,
      default: () => null,
    },
    updateEtaWatchPayload: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isProcessing: false,
      productTypeId: '',
      localEtaWatchPk: this.etaItem?.etaWatchPk,
    };
  },
  computed: {
    isProjectOne() {
      return this.etaItem.product.productType === ProductType.DIProjectOne;
    },
    tippyOptions() {
      return {
        html: `#p1-eta-tooltip-${this.productTypeId}-${this.etaItem.id}`,
        interactive: true,
        reactive: true,
        trigger: 'mouseenter focus',
        flip: true,
        sticky: true,
      };
    },
    stockWatchIcon() {
      return this.hasEtaWatchPk ? 'visibility' : 'visibility_off';
    },
    stockWatchClasses() {
      return {
        'text-success': this.hasEtaWatchPk,
        disabled: this.isProcessing || this.isProjectOne,
      };
    },
    hasEtaWatchPk() {
      return this.localEtaWatchPk !== undefined;
    },
  },
  created() {
    this.productTypeId = this.etaItem.product.productType?.replace(/\s/g, '');
  },
  methods: {
    async updateEtaWatch(etaItem, payload) {
      if (this.isProjectOne) {
        return;
      }
      if (!payload) {
        payload = {
          sku: etaItem.product.code,
          warehouse: etaItem.warehouse,
          orderLineNumber: etaItem.orderLineNumber,
          orderNumber: etaItem.orderNumber,
          orderType: etaItem.orderType,
          orderCompany: etaItem.orderCompany,
        };
      }
      this.isProcessing = true;
      try {
        const data = this.hasEtaWatchPk
          ? await OrderItemApi.deleteEtaWatch(this.localEtaWatchPk)
          : await OrderItemApi.updateEtaWatch(payload);
        this.localEtaWatchPk = data?.etaWatchPk;
      } catch (error) {
        console.log(error);
      }
      this.isProcessing = false;
    },
  },
};
</script>
