<template>
  <div
    v-cloak
    :class="`nav-utility-bar-b2b-container nav-utility-bar-b2b-container--${mode}`"
    qaid="nav-utility-bar-b2b-container"
  >
    <div class="nav-utility-bar-b2b-container__toggles">
      <slot></slot>
    </div>
    <div class="nav-utility-bar-b2b-container__controls">
      <slot name="controls" />
    </div>
    <pdl-button
      class="nav-utility-bar-b2b-container__close-mobile-menu-button"
      tertiary
      inverted
      icon="close"
      @click="toggleMobileMenu"
    >
      {{ $t('popup.close') }}
    </pdl-button>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';
import {PdlButton} from '@pedal/pdl-button';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'NavUtilityBarB2bContainer',
  /* eslint-disable-next-line vue/no-unused-components */
  components: {PdlButton, PdlIcon},
  props: {
    advancedOrderingMode: {
      type: Boolean,
      default: false,
    },
    isConsumerFriendlyMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mode() {
      if (this.advancedOrderingMode) {
        return 'advanced-ordering';
      }
      return this.isConsumerFriendlyMode ? 'consumer-friendly' : 'default';
    },
  },
  methods: {
    ...mapMutations('navigation', ['setMobileMenuOpen']),
    toggleMobileMenu() {
      this.setMobileMenuOpen(false);
    },
  },
};
</script>

<style scoped lang="scss">
.nav-utility-bar-b2b-container {
  @apply mt-2;
  @apply pt-2;
  @apply px-2;

  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;

  @include breakpoint(large) {
    @apply mt-0;
    @apply py-0;

    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    flex-direction: row;
    justify-content: space-between;
  }

  // Variants -----------------------------------
  &--consumer-friendly {
    @apply bg-blue;
  }

  &--advanced-ordering {
    @apply bg-red;
  }

  &--default {
    @include breakpoint(large) {
      @apply bg-gray-90;
    }
  }

  // Toggles ------------------------------------
  &__toggles {
    display: flex;
    flex-direction: column;

    @include breakpoint(large) {
      align-items: center;
      flex-direction: row;
    }
  }

  &__toggle {
    @apply mr-4;
    @apply mt-2;

    display: flex;
    align-items: center;

    &:first-child,
    &:empty {
      @apply mt-0;
    }

    @include breakpoint(large) {
      @apply mt-0;
    }
  }

  // Controls -----------------------------------
  &__controls {
    @apply text-white;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    @include breakpoint(large) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__close-mobile-menu-button {
    @apply mt-2;

    @include breakpoint(large) {
      display: none;
    }
  }
}
</style>

<style lang="scss">
// Legacy overrides from _pattern-scroll-list.scss
.nav-utility-bar-b2b-container__scroll-list {
  background-clip: padding-box;
  overflow-y: scroll;
  max-height: 10rem !important;

  @include breakpoint(large) {
    max-height: 16rem !important;
  }
}
</style>

<style lang="scss">
// Legacy styles overriding of text label in toggle components
.nav-utility-bar-b2b-container {
  & .vue-js-switch .v-switch-text {
    @apply text-lg;
    @apply font-normal;

    @include breakpoint(large) {
      @apply text-md;
      @apply font-medium;
    }
  }
}
</style>
