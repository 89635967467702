var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.product.isArchived)?_c('ul',{staticClass:"quantityOptions"},[_c('li',{staticClass:"form__item"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"qty"}},[_vm._v(_vm._s(_vm.$t('basket.page.quantity')))]),_vm._v(" "),_c('numerical-input',{staticClass:"w-20",attrs:{"id":"qty","initial-value":_vm.isPurchasable ? (_vm.minQuantity ? _vm.minQuantity : 1) : null,"min":0,"max":9999,"should-clear":true,"is-buying-zone":true,"disabled":!_vm.isPurchasable || !_vm.hasUserAccessToBuyingZone},on:{"updated":_vm.quantityChanged}}),_vm._v(" "),(_vm.maxQuantityText)?_c('div',{attrs:{"qaid":"max-quantity-help-text"}},[_c('span',{staticClass:"form-help",domProps:{"textContent":_vm._s(_vm.maxQuantityText)}})]):_vm._e()],1)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"buttons"},[(_vm.canAddToCart && _vm.hasUserAccessToBuyingZone)?_c('trek-button',{attrs:{"id":"addToCartButton","primary":"","block":"","icon":"shopping_cart","type":"submit","disabled":!_vm.cartToggle || !_vm.isPurchasable || (_vm.isDistributor && !_vm.hasAvailability) || _vm.isAddingToCart},on:{"click":_vm.addToCart}},[_vm._v("\n      "+_vm._s(_vm.addToCartButtonCaption)+"\n    ")]):_vm._e(),_vm._v(" "),_c('trek-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasUserAccessToBuyingZone),expression:"hasUserAccessToBuyingZone"},{name:"tippy",rawName:"v-tippy",value:({
        html: '#saved-lists-buying-zone',
        interactive: true,
        reactive: true,
        trigger: 'click',
        flip: true,
        sticky: true,
      }),expression:"{\n        html: '#saved-lists-buying-zone',\n        interactive: true,\n        reactive: true,\n        trigger: 'click',\n        flip: true,\n        sticky: true,\n      }"}],ref:"tippy_2",attrs:{"id":"sl-buying-zone-btn","secondary":"","block":"","icon":"list","disabled":_vm.isDistributor && !_vm.hasAvailability,"qaid":"saved-lists-button.move-to-list.buying-zone"}},[_c('span',[_vm._v(_vm._s(_vm.$t('myCart.B2B.moveToList')))])]),_vm._v(" "),_c('saved-lists-panel',{attrs:{"id":"saved-lists-buying-zone","token":_vm.options.token,"text-max-chars":_vm.$t('text.buyingZone.B2B.max40Char'),"text-my-lists":_vm.$t('text.account.savedLists.myLists'),"text-other-lists":_vm.$t('text.account.savedLists.otherLists'),"text-undo":_vm.$t('text.undo'),"qaid-panel":"saved-lists-panel.buying-zone","qaid-title":"saved-lists-panel.title.buying-zone","qaid-list":"saved-lists-panel.list.buying-zone","qaid-list-item":"saved-lists-panel.list.buying-zone.item","tippy-id":"6","is-buying-zone":true,"show-min-qty-toasts":true,"btn-id":"sl-buying-zone-btn"}}),_vm._v(" "),(_vm.options.productCompareEnabledOnSite)?_c('product-compare-button',{attrs:{"tertiary":"","block":"","qaid":"compare-product-button","product":_vm.product}}):_vm._e()],1),_vm._v(" "),_c('pdp-add-to-cart-status',{attrs:{"message":_vm.successMessage,"is-error":false,"options":_vm.options}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }