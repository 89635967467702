<template>
  <div qaid="pre-season-overview-header">
    <pdl-section-header size="xl" :is-dividing="true">
      <template slot="content">
        <pdl-heading qaid="pre-season-overview-heading" :level="1">{{ $t('preOrder.B2B.heading.order') }}</pdl-heading>
      </template>
      <template slot="actions">
        <div class="buttons buttons--right-for-md">
          <pdl-link qaid="pre-season-overview-review-submit" button primary small :href="reviewOrderUrl">
            <span v-html="bp('reviewSubmit')" />
          </pdl-link>
          <trek-button
            ref="tippybutton"
            v-tippy="{
              html: '#delete-shipment-popover',
              interactive: true,
              reactive: true,
              trigger: 'click',
              placement: 'bottom',
              flip: false,
              sticky: true,
              theme: 'flush',
              zIndex: 0,
            }"
            qaid="pre-season-overview-manage-shipment-details"
            secondary
            small
            icon="expand_more"
            :icon-right="true"
          >
            <span v-html="bp('manageShipment')" />
          </trek-button>

          <div v-cloak id="delete-shipment-popover" class="w-48 z-0">
            <ul class="block-list nav-list text-left">
              <li class="block-list__item py-0">
                <pdl-link standalone qaid="pre-season-overview-edit-shipment-details" :href="editShipmentUrl">
                  <span v-html="bp('editShipment')" />
                </pdl-link>
              </li>
              <li class="block-list__item py-0">
                <pdl-link standalone qaid="pre-season-overview-delete-shipment-details" @click="deleteShipment">
                  <span v-html="bp('deleteShipment')" />
                </pdl-link>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </pdl-section-header>

    <pdl-dialog
      :visible.sync="dialogVisible"
      :title="bp('deleteShipmentDialogText')"
      :modal-append-to-body="false"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :z-index="1000"
      qaid="preseason-overview-delete-shipment-dialog"
    >
      <template slot="footer">
        <div class="buttons buttons--right">
          <trek-button
            alert
            qaid="pre-season-overview-confirm-delete-shipment"
            @click.stop="confirmDeleteShipment"
            v-html="bp('deleteConfirmText')"
          />
          <trek-button
            secondary
            qaid="pre-season-overview-cancel-delete-shipment"
            @click.stop="closeDialog"
            v-html="bp('deleteCancelText')"
          />
        </div>
      </template>
    </pdl-dialog>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {PdlLink} from '@pedal/pdl-link';
import {mapState, mapGetters, mapMutations} from 'vuex';
import TrekButton from '@/components/TrekButton';
import {PdlDialog} from '@pedal/pdl-dialog';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';
import {PdlToastType} from '@/constants/pdl-toast-type';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  name: 'PreSeasonOverviewHeader',
  components: {PdlSectionHeader, PdlHeading, PdlLink, TrekButton, PdlDialog},
  props: {
    orderFormType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    reviewOrderUrl() {
      return `${this.encodedContextPath}/pre-season-orders/review/${this.orderFormType}`;
    },

    editShipmentUrl() {
      return `${this.encodedContextPath}/pre-season-orders/shipments/${this.orderFormType}`;
    },

    ...mapGetters('preSeason', ['successOnOverview']),
    ...mapState('backend', ['encodedContextPath']),
  },
  mounted() {
    this.initializeOverview();
  },
  methods: {
    initializeOverview() {
      if (this.successOnOverview) {
        this.$notify({
          type: PdlToastType.SUCCESS,
          message: this.bp('saveShipmentSuccess'),
        });
        this.showSuccessOnOverview(false);
      }
    },
    deleteShipment() {
      this.dialogVisible = true;
      if (this.$refs.tippybutton && this.$refs.tippybutton.$el._tippy) {
        this.$refs.tippybutton.$el._tippy.hide();
      }
    },
    confirmDeleteShipment() {
      this.dialogVisible = false;
      storefrontInstance
        .get(`/pre-season-orders-api/deletePreSeasonOrder/${this.orderFormType}/`)
        .then((response) => {
          if (checkMetaFeedbackType(response.data, MetaFeedbackType.SUCCESS)) {
            this.showSuccessOnPreSeasonOrders(true);
            window.location = `${this.encodedContextPath}/pre-season-orders/`;
          }
        })
        .catch((error) => {
          this.$notify({
            type: PdlToastType.ERROR,
            message: this.bp('deleteFailToast'),
          });
          console.error(error);
        });
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    ...mapMutations('preSeason', ['showSuccessOnPreSeasonOrders', 'showSuccessOnOverview']),
  },
};
</script>

<style scoped>
.nav-list a {
  width: 100%;
  padding: 1rem;
}
</style>
