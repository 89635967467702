<template>
  <div class="leading-none">
    <span
      v-cloak
      v-tippy="{
        html: '#pricing-tooltip',
        theme: 'light-gray',
        placement: 'bottom',
      }"
      class="b2b-buying-zone__price-info"
      qaid="buying-zone.price-info"
    >
      <pdl-icon name="info" />
    </span>

    <!-- Pricing Tooltip -->
    <div id="pricing-tooltip" v-tippy-html class="hidden">
      <div v-if="price" class="b2b-buying-zone__tooltip min-w-xs font-bold">
        <div class="flex justify-between pt-1">
          <div class="font-normal">{{ $t('text.buyingZone.originalPrice') }}</div>
          <div class="pl-4">{{ price.wasPrice.formatted }}</div>
        </div>
        <div v-if="price.advancedOrdering.displayPrice" class="flex justify-between pt-1">
          <div class="font-normal">{{ $t('distributor.B2B.advancedOrderPrice') }}</div>
          <div class="pl-4">{{ price.advancedOrdering.displayPrice }}</div>
        </div>
        <div v-if="price.hasSalePrice" class="flex justify-between pt-1">
          <div class="font-normal">{{ $t('text.buyingZone.salePrice') }}</div>
          <div class="pl-4">{{ price.price.displayPrice }}</div>
        </div>
        <div v-if="price.bulkPrices && price.bulkPrices.length > 0">
          <div v-for="(item, index) in price.bulkPrices" :key="index" class="flex justify-between pt-1">
            <div class="font-normal">
              <span v-if="index === 0">{{ $t('text.buyingZone.bulkPrice') }}</span>
            </div>
            <div class="pl-4">{{ item.label }} = {{ item.price }}</div>
          </div>
        </div>
        <div v-if="price.bulkSalePrices && price.bulkSalePrices.length > 0">
          <div v-for="(item, index) in price.bulkSalePrices" :key="index" class="flex justify-between pt-1">
            <div class="font-normal">
              <span v-if="index === 0">{{ $t('text.buyingZone.bulkSalePrice') }}</span>
            </div>
            <div class="pl-4">{{ item.label }} = {{ item.price }}</div>
          </div>
        </div>
        <div v-if="price.savedAmount.value >= 0" class="flex justify-between pt-1">
          <div class="font-normal">{{ $t('text.buyingZone.youSave') }}</div>
          <div class="pl-4 text-alert">{{ price.savedAmount.formatted }}</div>
        </div>
        <hr class="border-gray-20 my-2" />
        <div v-if="price.advertised.displayPrice" class="flex justify-between pt-1">
          <div class="font-normal">{{ $t('text.buyingZone.advertised') }}</div>
          <div class="pl-4">{{ price.advertised.displayPrice }}</div>
        </div>
        <div v-if="price.saleAdvertised.displayPrice" class="flex justify-between pt-1">
          <div class="font-normal">{{ $t('text.buyingZone.sale') }}</div>
          <div class="pl-4">{{ price.saleAdvertised.displayPrice }}</div>
        </div>
        <div v-if="price.apple.displayPrice" class="flex justify-between pt-1">
          <div class="font-normal">{{ $t('text.buyingZone.applePrice') }}</div>
          <div class="pl-4">{{ price.apple.displayPrice }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {
    PdlIcon,
  },
  props: {
    price: {
      type: Object,
      required: true,
    },
  },
};
</script>
