<template>
  <div>
    <mapbox-map class="mapboxgl-map" :map-classes="mapClasses" qaid="edit-store-profile-map-canvas" />
  </div>
</template>

<script>
import MapFactory from '../utils/mapbox';
import {computeGeocodedLocation} from '../utils/mapbox-api';
import MapboxMap from '../components/MapboxMap';
import isEmpty from 'lodash/isEmpty';
import {mapState} from 'vuex';

const DEFAULT_MAP_ZOOM = 16;

export default {
  components: {
    MapboxMap,
  },
  props: {
    mapClasses: {
      type: String,
      default: '',
    },
    initialCoordinates: {
      type: Object,
      default: () => {},
    },
    zoom: {
      type: Number,
      default: DEFAULT_MAP_ZOOM,
    },
    address: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      map: undefined,
      marker: undefined,
      isPageLoaded: false,
      geocodedLocation: [0, 0],
      markerOptions: {draggable: true},
      zoomLevel: DEFAULT_MAP_ZOOM,
    };
  },
  computed: {
    ...mapState('backend', ['mapboxApiKey']),
  },
  watch: {
    address() {
      this.updateMapCenter(this.address);
    },
    zoomLevel() {
      this.map.mapInstance.setZoom(this.zoomLevel);
    },
  },

  async mounted() {
    this.map = await MapFactory({
      accessToken: this.mapboxApiKey,
      center: [this.initialCoordinates.lng, this.initialCoordinates.lat],
      zoom: this.zoom,
    });
    this.map.includeZoomControl();
    this.marker = this.map.addMarker({
      events: {onDragEnd: this.onMarkerDragEnd},
      position: [this.initialCoordinates.lng, this.initialCoordinates.lat],
      markerOptions: this.markerOptions,
    });
    this.$nextTick(() => {
      this.isPageLoaded = true;
      if (
        !isEmpty(this.initialCoordinates) &&
        this.initialCoordinates.lat === 0 &&
        this.initialCoordinates.lng === 0 &&
        !isEmpty(this.address)
      ) {
        this.updateMapCenter(this.address);
      }
    });
  },
  destroyed() {
    // Clean-up of mapbox & marker
    this.map.removeAllMapComponents();
  },

  methods: {
    async updateMapCenter(address) {
      // Only compute geocode location after page has loaded with initial coordinates
      if (!this.isPageLoaded) return;
      let coordinates = await computeGeocodedLocation(address?.address, this.mapboxApiKey);
      this.geocodedLocation = {
        lng: coordinates[0],
        lat: coordinates[1],
      };
      this.mapChangedUpdateCoordinates(this.geocodedLocation);
      this.map.updateMapCenter(coordinates);
      this.marker.setLngLat([this.geocodedLocation.lng, this.geocodedLocation.lat]);
    },
    onMarkerDragEnd(marker) {
      const lngLat = marker.target.getLngLat();
      this.map.updateMapCenter([lngLat.lng, lngLat.lat]);
      this.geocodedLocation = {
        lng: lngLat.lng,
        lat: lngLat.lat,
      };
      this.$emit('pin-drop', this.geocodedLocation);
      this.mapChangedUpdateCoordinates(this.geocodedLocation);
    },
    mapChangedUpdateCoordinates(coordinates) {
      this.$emit('map-changed', coordinates);
    },
  },
};
</script>
