<template>
  <div class="grid-x grid-margin-x">
    <div class="cell medium-5 large-2">
      <div class="form-group is-required">
        <form-group
          :id="getFieldName('productSKU')"
          v-model="rowData.productSKU"
          required
          type="text"
          :label="$t('consumerReturns.B2B.productSKU')"
          :error="getErrorMessage('rowData.productSKU')"
          :name="getFieldName('productSKU')"
          :maxlength="100"
          :qaid="'row-' + rowIndex + '-productSKU'"
          show-error-messages
          force-display-error
        />
      </div>
    </div>
    <div class="cell medium-7 large-4">
      <div class="form-group is-required">
        <form-group
          :id="getFieldName('productDescription')"
          v-model="rowData.productDescription"
          required
          type="text"
          :label="$t('consumerReturns.B2B.productDescription')"
          :error="getErrorMessage('rowData.productDescription')"
          :name="getFieldName('productDescription')"
          :maxlength="500"
          :qaid="'row-' + rowIndex + '-productDescription'"
          show-error-messages
          force-display-error
        />
      </div>
    </div>
    <div class="cell medium-2 large-1">
      <div class="form-group is-required">
        <form-group
          :id="getFieldName('qty')"
          v-model="rowData.qty"
          required
          type="number"
          :label="$t('text.account.savedLists.qty')"
          :error="getErrorMessage('rowData.qty')"
          :name="getFieldName('qty')"
          :qaid="'row-' + rowIndex + '-qty'"
          show-error-messages
          force-display-error
        />
      </div>
    </div>
    <div class="cell medium-auto">
      <div class="form-group">
        <label for="select" class="form-label">{{ $t('consumerReturns.B2B.returnReason') }}</label>
        <div class="select">
          <select
            :id="getFieldName('returnReason')"
            :name="getFieldName('returnReason')"
            required="required"
            class="form-control"
            :qaid="'row-' + rowIndex + '-reason'"
          >
            <option v-for="item in reasons" :key="item" :value="item">{{ item }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="cell large-1 mt-3">
      <div v-if="rowIndex !== 0" class="form-group md:form-group--inline mb-3">
        <trek-button
          tertiary
          icon-only
          form-inline
          :aria-label="$t('text.remove')"
          icon="delete"
          @click="$emit('deleteRow', rowIndex)"
        >
        </trek-button>
      </div>
    </div>
  </div>
</template>
<script>
import TrekButton from '@/components/TrekButton';
import FormGroup from '@/components/FormGroup';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  components: {
    TrekButton,
    FormGroup,
  },
  mixins: [TrekValidationMixin],
  props: {
    rowIndex: {
      type: Number,
      default: 0,
    },
    initialRowData: {
      type: Object,
      default: () => ({
        productSKU: '',
        productDescription: '',
        qty: '',
        returnReason: '',
      }),
    },
  },
  data() {
    return {
      rowData: this.initialRowData,
    };
  },
  validations() {
    return {
      rowData: {
        productSKU: {
          required: this.trekValidators.requiredWithCustomMessage('consumerReturns.B2B.productSKU.required'),
        },
        productDescription: {
          required: this.trekValidators.requiredWithCustomMessage('consumerReturns.B2B.productDescription.required'),
        },
        qty: {
          required: this.trekValidators.requiredWithCustomMessage('consumerReturns.B2B.productQty.required'),
        },
      },
    };
  },
  computed: {
    reasons() {
      return [
        this.$t('consumerReturns.B2B.reason.tooSmall'),
        this.$t('consumerReturns.B2B.reason.tooLarge'),
        this.$t('consumerReturns.B2B.reason.notAsDescribed'),
        this.$t('consumerReturns.B2B.reason.changedMind'),
        this.$t('consumerReturns.B2B.reason.defective'),
        this.$t('consumerReturns.B2B.reason.bontragerGuarantee'),
        this.$t('consumerReturns.B2B.reason.damaged'),
        this.$t('consumerReturns.B2B.reason.late'),
        this.$t('consumerReturns.B2B.reason.wrongItem'),
        this.$t('consumerReturns.B2B.reason.priceMatch'),
        this.$t('text.other'),
      ];
    },
  },
  watch: {
    rowData(value) {
      this.$emit('update', value);
    },
  },
  mounted() {
    this.rowData = this.initialRowData;
  },
  methods: {
    getFieldName(name) {
      return `items[${this.rowIndex}].${name}`;
    },
  },
};
</script>
