<template>
  <div v-cloak class="block-list">
    <div class="block-list__item w-full" :qaid="`cart-totals-${productType}-subtotal`">
      <span>{{ subtotalLabel }}</span>
      <span>{{ subtotal.prices.subtotal.formattedValue }}</span>
    </div>
    <div class="block-list__item w-full" :qaid="`cart-totals-${productType}-promotions`">
      <span>{{ $t('text.promotions') }}</span>
      <span class="text-alert">{{ subtotal.prices.discount.formattedValue }}</span>
    </div>

    <template v-if="!isDistributor">
      <div
        v-if="isDeliveryCostVisible"
        class="block-list__item w-full"
        :qaid="`cart-totals-${productType}-deliveryCost`"
      >
        <div>
          <p>{{ $t('text.estimatedShipping') }}</p>
          <trek-button v-if="isCalculateFreightVisible" text small underline @click="calculateFreight">
            {{ $t('text.recalculateShipping') }}
          </trek-button>
        </div>
        <span v-if="showDeliveryCost">{{ subtotal.prices.deliveryCost.formattedValue }}</span>
      </div>
      <div class="block-list__item w-full" :qaid="`cart-totals-${productType}-freightDeduction`">
        <span>
          {{ $t('text.shippingDeductions') }}
        </span>
        <span v-if="showDeliveryCost || showDeductionByDefault">{{
          subtotal.prices.freightDeduction.formattedValue
        }}</span>
      </div>
    </template>

    <div v-if="isTaxVisible" class="block-list__item w-full" :qaid="`cart-totals-${productType}-totalTax`">
      <span>{{ taxLabel }}</span>
      <span>{{ subtotal.prices.totalTax.formattedValue }}</span>
    </div>
    <div v-if="isPaymentTermsVisible" class="block-list__item w-full" qaid="cart-totals-paymentTerms">
      <span>{{ $t('text.paymentTerms') }}</span>
      <span>{{ subtotal.paymentTerms }}</span>
    </div>
    <div class="block-list__item w-full" :qaid="`cart-totals-${productType}-totalPrice`">
      <span class="font-medium">{{ totalLabel }}</span>
      <span>{{ subtotal.prices.totalPrice.formattedValue }}</span>
    </div>
  </div>
</template>

<script>
import TrekButton from '@/components/TrekButton';
import {CheckoutStep} from '@/constants/checkout';
import {mapState} from 'vuex';
import {ProductType, ProductTypesWithVisibleDeliveryCost} from '@/constants/product';

export default {
  name: 'CartProductSubtotals',
  components: {
    TrekButton,
  },
  props: {
    productType: {
      type: String,
      required: true,
    },
    subtotal: {
      type: Object,
      required: true,
      validator(value) {
        return ['prices'].every((prop) => Object.hasOwn(value, prop));
      },
    },
    checkoutStep: {
      type: String,
      default: '',
    },
    showDeliveryCost: {
      type: Boolean,
      default: false,
    },
    isTaxableEmployee: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    subtotalLabel() {
      switch (this.productType) {
        case ProductType.Bike:
          return this.$t('text.bikesSubtotal');
        case ProductType.Aftermarket:
          return this.$t('text.accessoriesSubtotal');
        case ProductType.CustomWaterBottles:
          return this.$t('checkout.custom.subtotal');
        case ProductType.ProjectOne:
          return this.$t('checkout.projectOne.subtotal');
        default:
          return `cart.totals.subtotal.${this.productType}`;
      }
    },
    taxLabel() {
      return this.$store.state.locale === 'zh_CN' ? this.$t('myCart.B2B.tax.China') : this.$t('text.order.tax');
    },
    totalLabel() {
      switch (this.productType) {
        case ProductType.Bike:
          return this.$t('myCart.B2B.bikesTotal');
        case ProductType.Aftermarket:
          return this.$t('myCart.B2B.partsAccessories.total');
        case ProductType.CustomWaterBottles:
          return this.$t('checkout.custom.total');
        case ProductType.ProjectOne:
          return this.$t('checkout.projectOne.total');
        default:
          return `cart.totals.total.${this.productType}`;
      }
    },
    isDeliveryCostVisible() {
      return ProductTypesWithVisibleDeliveryCost.includes(this.productType);
    },
    isPaymentTermsVisible() {
      return [CheckoutStep.Shipments].includes(this.checkoutStep);
    },
    isCalculateFreightVisible() {
      return [CheckoutStep.Shipments, CheckoutStep.Allocated].includes(this.checkoutStep);
    },
    isTaxVisible() {
      return this.subtotal.prices.totalTax?.value && (this.$store.state.locale === 'zh_CN' || this.isTaxableEmployee);
    },
    showDeductionByDefault() {
      return [ProductType.CustomWaterBottles, ProductType.ProjectOne].includes(this.productType);
    },
    ...mapState('backend', ['isDistributor']),
  },
  methods: {
    calculateFreight() {
      this.$emit('calculate-freight-totals');
    },
  },
};
</script>
