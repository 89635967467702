<template>
  <form action="#" @submit.prevent="submit">
    <div class="form-group max-w-md" :class="{'has-error': !!getErrorMessage('serialNumber')}" qaid="serial-lookup">
      <label for="serialNumber" class="form-label">
        {{ $t('contactUs.serial.number') }}
      </label>
      <div class="form-group--inline">
        <input
          id="serialNumber"
          ref="serialNumber"
          v-model="serialNumber"
          type="text"
          name="serialNumber"
          class="form-control"
          :maxlength="30"
        />
        <button class="button button--primary button--inline md:ml-1" :disabled="!hasSerialNumber">
          {{ $t('serialLookup.B2B.findBySerial') }}
        </button>
      </div>
      <span
        v-if="!!getErrorMessage('serialNumber')"
        class="form-feedback is-invalid"
        v-html="getErrorMessage('serialNumber')"
      />
    </div>
  </form>
</template>
<script>
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  name: 'TechInfoSerialSearchForm',
  mixins: [TrekValidationMixin],
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      serialNumber: '',
    };
  },
  validations() {
    return {
      serialNumber: {
        required: this.trekValidators.required('contactUs.serial.number'),
      },
    };
  },
  computed: {
    hasSerialNumber() {
      return this.serialNumber && this.serialNumber.length > 0;
    },
  },
  watch: {
    serialNumber(value) {
      return this.$emit('input', value);
    },
  },
  created() {
    this.serialNumber = this.value;
  },
  methods: {
    submit() {
      if (this.hasSerialNumber) {
        this.$emit('search');
      }
    },
  },
};
</script>
