<template>
  <section class="md:w-1/2 columns left">
    <h2 class="header-medium mb-2">{{ $t('checkout.multi.deliveryMethod.deliveryMethod') }}</h2>
    <slot name="global-messages" />
    <pdl-loading :is-loading="deliveryMethodsLoading">
      <delivery-method-retailer-selector v-if="hasBikes" :has-ebike="hasEbike" :show-overnight-shipping-text="true">
        <template #choose-retailer-description>
          <slot name="choose-retailer-description-section" />
        </template>
        <template #delivery-specifics>
          <slot name="delivery-specifics-section" />
        </template>
      </delivery-method-retailer-selector>

      <delivery-mode-selector v-if="isVisibleDeliveryModes" :is-c-p-o="isCPO" :show-overnight-shipping-text="!hasBikes">
        <template #delivery-method-specifics>
          <slot name="delivery-method-specifics-section" />
        </template>
      </delivery-mode-selector>

      <delivery-method-retailer-selector v-if="!hasBikes && shipToStoreMode" :has-ebike="hasEbike" :is-c-p-o="isCPO">
        <template #choose-retailer-description>
          <slot name="choose-retailer-description-section" />
        </template>
        <template #delivery-specifics>
          <slot name="delivery-specifics-section" />
        </template>
      </delivery-method-retailer-selector>
    </pdl-loading>

    <div class="buttons">
      <pdl-button
        primary
        :disabled="isContinueDisabled"
        qaid="checkout-del-method-continue"
        @click="goToNextCheckoutStep()"
      >
        {{ $t('checkout.multi.deliveryMethod.continuePayment') }}
      </pdl-button>
      <pdl-link button secondary :href="continuePaymentUrl" qaid="checkout-del-method-back">
        {{ $t('checkout.multi.deliveryMethod.back') }}
      </pdl-link>
    </div>
  </section>
</template>

<script>
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
import {PdlLoading} from '@pedal/pdl-loading';
import DeliveryMethodRetailerSelector from '@/components/checkout/b2c/dealers/DeliveryMethodRetailerSelector';
import DeliveryModeSelector from '@/components/checkout/b2c/dealers/DeliveryModeSelector';
import {scrollToElement} from '@/utils/scroll-to';
import {mapState, mapMutations, mapActions} from 'vuex';
import {ShipToStoreMode} from '@/constants/dealer-list';
import {useVuelidate} from '@vuelidate/core';

/* Mixins */
import trackCheckout from '@/mixins/tracking/track-checkout';

export default {
  components: {PdlButton, PdlLink, PdlLoading, DeliveryMethodRetailerSelector, DeliveryModeSelector},
  mixins: [trackCheckout],
  provide() {
    return {
      setImpossibleZipcode: this.setImpossibleZipcode,
    };
  },
  props: {
    dealerPostcode: {
      type: String,
      default: '',
    },
    hasEbike: {
      type: Boolean,
      default: false,
    },
    isCPO: {
      type: Boolean,
      default: false,
    },
    continuePaymentUrl: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      isContinueDisabled: false,
      showErrorCallout: false,
      impossibleZipcodeError: false,
    };
  },

  computed: {
    ...mapState('checkout', [
      'deliveryModes',
      'deliveryMethodsLoading',
      'hasBikes',
      'selectedDeliveryMode',
      'shipToStoreMode',
      'selectedStore',
      'isVisibleDeliveryModes',
    ]),
  },

  mounted() {
    this.setDealerPostcode(this.dealerPostcode);
    this.initData();
  },
  methods: {
    ...mapMutations('checkout', [
      'setDeliveryMethodsLoading',
      'setDealerPostcode',
      'setHasBikes',
      'setShipToStoreMode',
      'setIsVisibleDeliveryModes',
    ]),
    ...mapActions('checkout', ['getDealers', 'getDeliveryModes', 'submitDeliveryMethod']),
    setImpossibleZipcode(bool) {
      this.impossibleZipcodeError = bool;
    },
    async initData() {
      await this.getDeliveryModes();
      //if modes not empty - we have a cart with accessories, otherwise - with bikes only
      if (this.deliveryModes?.length > 0) {
        this.setDeliveryMethodsLoading(false);
        this.setHasBikes(false);
        if (this.selectedDeliveryMode?.includes(ShipToStoreMode)) {
          this.setShipToStoreMode(true);
          this.getDealers();
        }
      } else {
        this.setHasBikes(true);
        this.setDeliveryMethodsLoading(false);
        this.setIsVisibleDeliveryModes(false);
        await this.getDealers();

        if (this.selectedStore) {
          this.setIsVisibleDeliveryModes(true);
          this.getDeliveryModes(this.selectedStore);
        }
      }
    },

    async goToNextCheckoutStep() {
      this.isContinueDisabled = true;
      let isValid = true;

      // this is for when there's a zipcode that doesn't return any results.
      if (this.impossibleZipcodeError) {
        isValid = false;
      } else {
        isValid = await this.v$.$validate();
      }
      if (isValid) {
        this.showErrorCallout = false;
        const redirectUrl = await this.submitDeliveryMethod();
        this.isContinueDisabled = false;
        this.trackDeliveryCheckoutSteps();
        if (redirectUrl !== undefined) {
          window.location = redirectUrl;
        } else {
          this.showErrorCallout = true;
        }
      } else {
        this.showErrorCallout = true;
        const firstErrorFieldEl = document.querySelector('.has-delivery-error');
        if (firstErrorFieldEl) {
          scrollToElement('.has-delivery-error', 100);
        }
        this.isContinueDisabled = false;
      }
    },
  },
};
</script>
