<template>
  <main class="site-content grid-container">
    <pdl-callout v-if="showErrorCallout" qaid="product-return-error-callout" kind="error">
      <template #content>
        {{ $t('form.global.error') }}
      </template>
    </pdl-callout>
    <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1">{{ $t('consumerReturns.B2B.title') }}</pdl-heading>
      </template>
    </pdl-section-header>
    <p class="mb-1" qaid="consumerReturns-b2b-deatails-1">{{ $t('consumerReturns.B2B.details.1') }}</p>
    <p class="mb-1" qaid="consumerReturns-b2b-deatails-2">{{ $t('consumerReturns.B2B.details.2') }}</p>
    <p class="mb-1" qaid="consumerReturns-b2b-deatails-3">{{ $t('consumerReturns.B2B.details.3') }}</p>
    <p class="mb-1" qaid="consumerReturns-b2b-deatails-4">{{ $t('consumerReturns.B2B.details.4') }}</p>

    <pdl-section-header size="md" class="mt-4">
      <template slot="content">
        <pdl-heading :level="3">{{ $t('consumerReturns.B2B.consumerInfo') }}</pdl-heading>
      </template>
    </pdl-section-header>
    <form
      id="powf_544461EAB03CE51180DD3863BB3CE4B0"
      ref="productReturnForm"
      modelAttribute="customerReturnsForm"
      action="confirmation"
      method="post"
      qaid="return-form"
      @submit.prevent="validateAndSubmit"
    >
      <div class="grid-x grid-margin-x">
        <div class="cell medium-6 large-4">
          <input
            id="dealerId"
            :value="dealerId"
            path="dealerId"
            type="hidden"
            name="dealerId"
            maxlength="100"
            qaid="return-form-dealerId"
          />

          <form-group
            id="firstName"
            v-model="firstNameField"
            required
            type="text"
            :label="$t('address.firstName')"
            :error="getErrorMessage('firstNameField')"
            name="firstName"
            :maxlength="100"
            show-error-messages
            force-display-error
            qaid="return-form-firstName"
          />
        </div>
        <div class="cell medium-6 large-4">
          <form-group
            id="lastName"
            v-model="lastNameField"
            required
            type="text"
            :label="$t('address.surname')"
            :error="getErrorMessage('lastNameField')"
            name="lastName"
            :maxlength="100"
            show-error-messages
            force-display-error
            qaid="return-form-lastName"
          />
        </div>
      </div>

      <div class="grid-x grid-margin-x">
        <div class="cell medium-6 large-4">
          <form-group
            id="email"
            v-model="emailField"
            required
            type="email"
            :label="$t('text.address.email')"
            :error="getErrorMessage('emailField')"
            name="email"
            :maxlength="100"
            show-error-messages
            force-display-error
            qaid="return-form-email"
          />
        </div>
        <div class="cell medium-6 large-4">
          <form-group
            id="phoneNumber"
            v-model="phoneNumberField"
            required
            type="text"
            :label="$t('text.address.phone')"
            :error="getErrorMessage('phoneNumberField')"
            name="phoneNumber"
            show-error-messages
            force-display-error
            :maxlength="100"
            qaid="return-form-phoneNumber"
          />
        </div>
      </div>

      <pdl-section-header size="md" class="mt-4">
        <template slot="content">
          <pdl-heading :level="3">{{ $t('text.orderInformation') }}</pdl-heading>
        </template>
      </pdl-section-header>
      <div class="grid-x grid-margin-x">
        <div class="cell medium-6 large-4">
          <div class="form-group is-required">
            <form-group
              id="customerPO"
              v-model="customerPoField"
              required
              type="text"
              :label="$t('consumerReturns.B2B.customerPO')"
              :error="getErrorMessage('customerPoField')"
              name="customerPO"
              show-error-messages
              force-display-error
              :maxlength="100"
              qaid="return-form-customerPO"
            />

            <span id="simple-input-help" class="form-help">{{ $t('consumerReturns.B2B.customerPO.description') }}</span>
          </div>
        </div>
      </div>

      <pdl-section-header size="md" class="mt-4">
        <template slot="content">
          <pdl-heading :level="3">{{ $t('consumerReturns.B2B.items') }}</pdl-heading>
        </template>
      </pdl-section-header>
      <product-return-row
        v-for="(item, index) in items"
        :key="item.id"
        :initial-row-data="item"
        :row-index="index"
        qaid="return-form-rows"
        @deleteRow="removeItem"
        @update="item = $event"
      />

      <trek-button v-if="canAddItem" text underline qaid="return-form-addItem" @click="addItem">
        {{ $t('consumerReturns.B2B.addItem') }}
      </trek-button>

      <div class="grid-x grid-margin-x mt-3">
        <div class="cell large-6">
          <div class="form-group">
            <form-group
              id="notes"
              v-model="notesField"
              type="textArea"
              :label="$t('consumerReturns.B2B.notes')"
              name="notes"
              :maxlength="500"
              qaid="return-form-notes"
            />

            <span id="textarea-help" class="form-help">{{ $t('consumerReturns.B2B.additionalInfo') }}</span>
          </div>
        </div>
      </div>

      <div class="buttons pb-5">
        <trek-button primary type="submit" qaid="return-form-submit">
          {{ $t('contactUs.submit') }}
        </trek-button>
        <trek-link button secondary :href="`${encodedContextPath}/`" qaid="return-form-cancel">
          {{ $t('text.cancel') }}
        </trek-link>
      </div>
    </form>
  </main>
</template>

<script>
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';
import FormGroup from '@/components/FormGroup';
import ProductReturnRow from '@/components/containers/returns/ProductReturnRow';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
import {scrollToElement} from '@/utils/scroll-to';

export default {
  components: {
    FormGroup,
    ProductReturnRow,
    TrekButton,
    TrekLink,
    PdlSectionHeader,
    PdlHeading,
  },
  mixins: [TrekValidationMixin],
  props: {
    dealerId: {
      type: String,
      default: '',
    },
    encodedContextPath: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      items: [],
      firstNameField: '',
      lastNameField: '',
      emailField: '',
      phoneNumberField: '',
      customerPoField: '',
      notesField: '',
      nativeElementsValidationResult: false,
      childrenElementsValidationResult: false,
      childrenValidationTriggered: false,
      validatedRowsCount: 0,
      showErrorCallout: false,
    };
  },

  validations() {
    return {
      firstNameField: {
        required: this.trekValidators.required('address.firstName'),
      },
      lastNameField: {
        required: this.trekValidators.required('address.surname'),
      },
      emailField: {
        required: this.trekValidators.required('address.email'),
        email: this.trekValidators.email('login.email'),
      },
      phoneNumberField: {
        required: this.trekValidators.required('text.address.phone'),
      },
      customerPoField: {
        required: this.trekValidators.required('consumerReturns.B2B.customerPO'),
      },
    };
  },

  computed: {
    submitReady() {
      return this.nativeElementsValidationResult && this.childrenElementsValidationResult;
    },
    canAddItem() {
      return this.items.length < 5;
    },
  },

  mounted() {
    this.addItem();
  },

  methods: {
    async validateAndSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        this.$refs.productReturnForm.submit();
      } else {
        this.showErrorCallout = true;
        scrollToElement('.has-error');
      }
    },
    addItem() {
      if (this.canAddItem) {
        this.items.push({
          productSKU: '',
          productDescription: '',
          qty: '',
          id: `product-row-${window.crypto.getRandomValues(new Uint16Array(1))[0]}`,
        });
      }
    },
    removeItem(index) {
      if (this.items.length > 1) {
        this.$delete(this.items, index);
      }
    },
  },
};
</script>
