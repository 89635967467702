<template>
  <div class="grid-container">
    <div class="my-2">
      <p class="text-md mb-2">
        {{ $t('serialLookup.B2B.title') }}
      </p>
      <pdl-callout v-if="errorMessage" class="max-w-md" kind="error">
        <template #content>
          <span v-html="errorMessage" />
        </template>
      </pdl-callout>
      <tech-info-serial-search-form v-model="serialNumber" :class="{'opacity-50': isWorking}" @search="search" />
      <tech-info-serial-search-results
        v-if="hasResults"
        :value="searchResults"
        :base-properties="baseProperties"
        class="mt-2"
      />
    </div>
  </div>
</template>
<script>
import TechInfoSerialSearchForm from '@/components/containers/tech-info/tech-info-serial-search/TechInfoSerialSearchForm';
import TechInfoSerialSearchResults from '@/components/containers/tech-info/tech-info-serial-search/TechInfoSerialSearchResults';
import {TechInfoApi} from '@/api/tech-info';
import {PdlCallout} from '@pedal/pdl-callout';

export default {
  name: 'TechInfoSerialSearchContainer',
  components: {PdlCallout, TechInfoSerialSearchResults, TechInfoSerialSearchForm},
  data() {
    return {
      isWorking: false,
      serialNumber: '',
      errorMessage: '',
      searchResults: [],
    };
  },
  computed: {
    hasResults() {
      return this.searchResults && Object.keys(this.searchResults).length > 0;
    },
  },
  methods: {
    /**
     * Peform search request.
     * @return {Promise<void>}
     */
    async search() {
      try {
        this.errorMessage = '';
        this.isWorking = true;

        const results = await TechInfoApi.findProductBySerialNumber(this.serialNumber);
        if (!results || !Object.keys(results).length) {
          this.errorMessage = this.$t('serialLookup.B2B.notFound');
        } else {
          this.searchResults = results;
        }
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.isWorking = false;
      }
    },
  },
};
</script>
