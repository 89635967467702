<template>
  <pdl-dialog
    :visible.sync="isShowingFindRetailerDialog"
    :z-index="16000011"
    :close-dialog-tool-tip="$t('popup.close')"
    qaid="find-retailer-dialog"
    :title="title"
    @close="closeFindRetailerDialog"
  >
    <form class="find-retailer-dialog-search" @submit.prevent>
      <label for="findRetailerDialogSearch" class="text-md">{{ $t('storelocator.query') }}</label>
      <div class="find-retailer-dialog-search-inner relative flex">
        <pdl-icon name="location_on" />
        <mapbox-address-suggestions-input
          input-id="findRetailerDialogSearch"
          input-classes="find-retailer-dialog-search-input"
          :input-placeholder="$t('text.searchForALocation')"
          :input-max-length="inputMaxLength"
          @input-updated="updateInputValue($event)"
          @input-suggestions-updated="setSuggestionsList"
        ></mapbox-address-suggestions-input>
        <div>
          <pdl-button
            button
            primary
            qaid="find-retailer-dialog-find-retailers"
            class="find-retailer-dialog-search-button"
            @click="findRetailers"
          >
            <span>{{ $t('text.search') }}</span>
          </pdl-button>
        </div>
      </div>
    </form>
    <div id="find-retailer-dialog-inner" class="find-retailer-dialog-inner p-0 mt-2">
      <pdl-loading v-if="isLoadingRetailers" :is-loading="isLoadingRetailers"></pdl-loading>

      <p
        v-else-if="!isLoadingRetailers && !retailerList.length > 0"
        class="flex justify-center items-center text-center"
      >
        {{ $t('text.search.noResults') }}
      </p>

      <template v-else-if="!isLoadingRetailers && retailerList.length > 0">
        <div
          v-for="retailer in retailerList"
          :key="retailer.name + Math.random()"
          class="sku-modal-status--ibd--info"
          :class="{'bg-gray-05 preferred': retailer.isPreferredPinnedResult}"
          :data-store-id="retailer.name"
        >
          <p v-if="retailer.isPreferredPinnedResult" class="font-bold text-green">
            {{ $t('storeFinder.product.my.pref') }}
          </p>

          <p>
            <pdl-link
              v-if="retailer.url"
              qaid="find-retailer-store-link"
              :href="retailer.url"
              standalone
              target="_blank"
              :title="retailer"
            >
              {{ retailer.displayName }}
            </pdl-link>
            <span v-else>{{ retailer.displayName }}</span>
          </p>

          <p v-if="retailer.address.formattedAddress" class="shop-address">
            {{ retailer.address.formattedAddress }}
          </p>

          <p v-if="retailer.formattedDistance" class="shop-distance">
            {{ `${$t('storeFinder.label.distance')}: ${retailer.formattedDistance} ${retailerData.distanceUnit}` }}
          </p>
          <p v-if="retailer.address.phone">
            <pdl-link :href="`tel:${retailer.address.phone}`" qaid="find-retailer-store-phone" standalone>
              {{ retailer.address.phone }}
            </pdl-link>
          </p>
          <div v-if="!retailer.isPreferredPinnedResult" class="pt-1">
            <div v-if="retailer.name === preferredRetailerName">
              <pdl-icon name="done" size="14" />
              <span class="pl-1 text-xs">{{ $t('storeFinder.product.my.pref') }}</span>
            </div>
            <pdl-button
              v-else
              secondary
              xsmall
              qaid="find-retailer-dialog-set-preferred"
              @click="setPreferredRetailer(retailer)"
            >
              {{ $t('storeFinder.product.set.as.pref') }}
            </pdl-button>
          </div>
        </div>
      </template>
    </div>
  </pdl-dialog>
</template>

<script>
import RetailerApi from '@/api/retailer-api.js';
import UserOccApi from '@/api/user-occ.js';
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlIcon} from '@pedal/pdl-icon';
import MapboxAddressSuggestionsInput from '@/components/mapbox/components/MapboxAddressSuggestionsInput.vue';
import {mapState, mapMutations, mapActions} from 'vuex';
import {UnitTypes} from '@/constants/unit-types.js';

const PAGE_SIZE = 20;
const RADIUS_IN_METERS = 80467;
const INPUT_MAX_LENGTH = 256;
const MIN_CHARACTER_SEARCH_COUNT = 3;

export default {
  components: {
    PdlLoading,
    PdlDialog,
    PdlButton,
    PdlIcon,
    MapboxAddressSuggestionsInput,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    hasFindRetailerDialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowingFindRetailerDialog: false,
      isLoadingRetailers: false,
      retailerData: {},
      retailerList: [],
      preferredRetailerName: '',
      inputValue: '',
      inputData: {},
      isSearching: false,
      preferredRetailer: null,
      inputMaxLength: INPUT_MAX_LENGTH,
      mapBoxSuggestionList: [],
    };
  },
  watch: {
    userPreferredRetailer() {
      this.preferredRetailer = this.userPreferredRetailer;
      this.preferredRetailerName = this.preferredRetailer?.name ?? '';
      this.inputValue && this.findRetailers();
    },
    hasFindRetailerDialogVisible() {
      this.isShowingFindRetailerDialog = this.hasFindRetailerDialogVisible;
    },
    ...mapState('backend', ['mapboxApiKey']),
  },
  async mounted() {
    await this.initPreferredRetailer();
    if (this.inputValue) {
      this.findRetailers();
    }
  },
  computed: {
    ...mapState('user', ['userPreferredRetailer']),
    ...mapState('backend', ['geolocation']),
  },
  methods: {
    ...mapMutations('user', ['setSelectedRetailer']),
    ...mapActions('user', ['setSelectedLocationFromCoordinates', 'fetchUserPreferredRetailer']),

    closeFindRetailerDialog() {
      this.$emit('close-find-retailer-dialog');
    },
    setSuggestionsList(list) {
      this.mapBoxSuggestionList = list;
    },
    updateInputValue({inputValue = '', data = {}, isSearching = false}) {
      this.inputValue = inputValue;
      this.inputData = data;
      this.isSearching = isSearching;
      if (isSearching) {
        this.findRetailers();
      }
    },
    async findRetailers() {
      if (!this.inputValue) {
        return this.handleEmptyInput();
      }
      try {
        if (this.inputValue.length < MIN_CHARACTER_SEARCH_COUNT) {
          return;
        }
        this.isLoadingRetailers = true;
        const radius = RADIUS_IN_METERS;
        let params = {
          radius,
          pageSize: PAGE_SIZE,
        };
        if (this.inputValue === this.geolocation.address) {
          params.longitude = this.geolocation.longitude;
          params.latitude = this.geolocation.latitude;
        } else if (this.inputData?.center) {
          params.longitude = this.inputData.center[0];
          params.latitude = this.inputData.center[1];
        } else {
          params.longitude = this.mapBoxSuggestionList[0]?.data.center[0];
          params.latitude = this.mapBoxSuggestionList[0]?.data.center[1];
        }
        const {data: retailerData} = await RetailerApi.getRetailers(params);
        this.retailerData.distanceUnit =
          retailerData?.distanceUnit === UnitTypes.IMPERIAL.milesString
            ? UnitTypes.IMPERIAL.milesAbbreviation
            : UnitTypes.METRIC.kilometersAbbreviation;
        this.retailerList = retailerData?.stores;
        if (this.preferredRetailer && this.retailerList.length > 0) {
          this.preferredRetailer.isPreferredPinnedResult = true;
          this.retailerList.unshift(this.preferredRetailer);
          this.preferredRetailerName = this.preferredRetailer?.name;
        }
      } catch (error) {
        this.retailerList = [];
      } finally {
        this.isLoadingRetailers = false;
      }
    },
    async initPreferredRetailer() {
      try {
        await this.fetchUserPreferredRetailer();
      } catch (error) {
        console.log(error);
      }
    },
    async setPreferredRetailer(retailer) {
      const payloadObj = {
        preferredRetailerCode: retailer.name,
      };
      try {
        this.isLoadingRetailers = true;
        await UserOccApi.patchUserProfile(payloadObj);
        this.setSelectedRetailer(retailer);
        this.setSelectedLocationFromCoordinates({
          latitude: retailer?.geoPoint?.latitude,
          longitude: retailer?.geoPoint?.longitude,
        });
        this.$notify({
          type: 'success',
          message: this.$t('text.account.profile.confirmationUpdated'),
        });
        await this.initPreferredRetailer();
        await this.findRetailers();
      } catch (error) {
        console.error(error);
        this.$notify({
          type: 'error',
          message: this.$t('errorMessage.unableToProcess'),
        });
      } finally {
        this.isLoadingRetailers = false;
      }
    },
    handleEmptyInput() {
      this.retailerList = [];
      this.$notify({
        type: 'error',
        message: this.$t('storefinder.searchterm.invalid'),
      });
    },
  },
};
</script>
