<template>
  <label :for="label" class="checkbox mb-0 flex self-center">
    <span class="hidden">checkbox for - {{ label }}</span>
    <input :id="label" type="checkbox" :value="value" :checked="checked" :disabled="disabled" @change="onChange" />
    <span class="control-indicator"></span>
  </label>
</template>

<script>
export default {
  model: {
    event: 'change',
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
    disabled: Boolean,
    checked: Boolean,
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  watch: {
    checked(val) {
      this.$emit('change', val);
    },
  },
  methods: {
    onChange(event) {
      this.$emit('change', event.target.checked);
    },
  },
};
</script>
