<template>
  <tr>
    <td class="b2b-grid__cell lg:table-cell" qaid="entry-checkbox">
      <label class="checkbox mb-0">
        <input
          :id="item.id"
          v-model="isSelected"
          type="checkbox"
          :disabled="isOnHold || item.isAllowedToShip === false"
        />
        <span class="control-indicator"></span>
      </label>
    </td>
    <td class="b2b-grid__cell lg:table-cell whitespace-nowrap" qaid="entry-notes">
      <div class="flex">
        <pdl-icon v-if="item.isSplitWarehouse" name="call_split" size="18" />
        <grid-note
          v-show="item.lineComment"
          :row-id="item.id"
          :data="{
            value: 'insert_drive_file',
            content: item.lineComment,
            editable: false,
            cssClass: 'ml-1',
          }"
        />
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="entry-itemName">
      <div class="b2b-grid__label">{{ getHeader('item') }}</div>
      <div class="b2b-grid__content">
        {{ item.product.itemName }}
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="entry-orderNumber">
      <div class="b2b-grid__label">{{ getHeader('orderNumber') }}</div>
      <div class="b2b-grid__content">
        <a :href="orderLinkUrl" class="underline hover:no-underline">
          {{ item.orderNumber }}
        </a>
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="entry-poNumber">
      <div class="b2b-grid__label">{{ getHeader('poNumber') }}</div>
      <div class="b2b-grid__content">
        {{ item.customerPo }}
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="entry-sku">
      <div class="b2b-grid__label">{{ getHeader('sku') }}</div>
      <div class="b2b-grid__content">
        <div class="flex items-center">
          <a
            :href="`${encodedContextPath}/my-account/orders?partNumber=${item.product.code}`"
            class="leading-none mr-1"
            qaid="entry-orderHistory"
          >
            <pdl-icon name="history" :label="$t('orders')" />
          </a>
          <a :href="pdpUrl" class="underline hover:no-underline">
            {{ item.product.code }}
          </a>
        </div>
      </div>
    </td>
    <td v-if="!isConsumerFriendlyMode" class="b2b-grid__cell lg:table-cell" qaid="entry-unitPrice">
      <div class="b2b-grid__label">{{ getHeader('price') }}</div>
      <div class="b2b-grid__content">
        {{ item.basePrice.formattedValue }}
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="entry-quantityOrder">
      <div class="b2b-grid__label">{{ getHeader('qty') }}</div>
      <div class="b2b-grid__content">
        {{ item.subtotalsByWarehouse[item.warehouse].quantity }}
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="entry-quantityAllocatedOrShipped">
      <div class="b2b-grid__label">{{ getHeader('allocated') }}</div>
      <div class="b2b-grid__content">
        {{ item.subtotalsByWarehouse[item.warehouse].availableQuantity }}
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="entry-cancelDate">
      <div class="b2b-grid__label">{{ getHeader('cancellationDate') }}</div>
      <div v-if="item.cancelDate" class="b2b-grid__content items-center">
        <pdl-icon v-if="item.showCancelFlag" class="text-warning mr-25" size="18" name="warning" />
        {{ item.cancelDate }}
      </div>
    </td>
    <td v-if="!isConsumerFriendlyMode" class="b2b-grid__cell lg:table-cell" qaid="entry-subTotal">
      <div class="b2b-grid__label">{{ getHeader('subtotal') }}</div>
      <div class="b2b-grid__content">
        {{ item.subtotalsByWarehouse[item.warehouse].prices.totalPrice.formattedValue }}
      </div>
    </td>
  </tr>
</template>

<script>
import find from 'lodash/find';
import {mapState, mapMutations} from 'vuex';
import GridNote from '@/components/grid/GridNote';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {GridNote, PdlIcon},

  props: {
    item: {
      type: Object,
      default: () => null,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    isOnHold: Boolean,
  },

  computed: {
    ...mapState('backend', ['encodedContextPath', 'isConsumerFriendlyMode']),

    orderLinkUrl() {
      // make sure we have ordertype and ordercompany available
      const {orderNumber, orderType, orderCompany} = this.item;

      return `${this.encodedContextPath}/my-account/order/${orderNumber}?company=${orderCompany}&ordertype=${orderType}`;
    },

    pdpUrl() {
      return `${this.encodedContextPath}/p/${this.item.product.code}/`;
    },

    isSelected: {
      get() {
        return this.item.selected;
      },
      set(value) {
        this.changeSelection(value);
      },
    },
  },

  mounted() {
    if (this.isOnHold || this.item.isAllowedToShip === false) {
      this.toggleSelection({id: this.item.id, selected: false});
    }
    if (this.item.selected === undefined && this.item.isAllowedToShip === true) {
      this.toggleSelection({id: this.item.id, selected: true});
    }
  },

  methods: {
    getHeader(headerName) {
      let header = find(this.headers, (header) => header.name === headerName);
      return header ? header.label : '';
    },
    changeSelection(value) {
      this.toggleSelection({id: this.item.id, selected: value});
      this.$emit('toggle-selection');
    },
    ...mapMutations('allocatedItems', ['toggleSelection']),
  },
};
</script>
