<template>
  <div>
    <dl class="description-list grid-x grid-margin-x max-w-sm text-base mt-4 md:mt-0">
      <dt class="cell small-6">{{ $t('text.account.orderHistory.orderNumber') }}</dt>
      <dd class="cell small-6">{{ orderData.code }}</dd>

      <dt class="cell small-6">{{ $t('text.poNumber') }}</dt>
      <dd class="cell small-6">{{ orderData.details.customerPo }}</dd>

      <dt class="cell small-6">{{ $t('text.orderDate') }}</dt>
      <dd class="cell small-6">{{ orderData.details.orderDate }}</dd>

      <dt class="cell small-6">{{ $t('text.requestDate') }}</dt>
      <dd class="cell small-6">{{ orderData.details.requestedDate }}</dd>

      <dt class="cell small-6">{{ $t('text.placedBy') }}</dt>
      <dd class="cell small-6">{{ orderData.details.placedBy }}</dd>

      <dt class="cell small-6">{{ $t('text.paymentTerms') }}</dt>
      <dd class="cell small-6">{{ orderData.details.paymentDetails.paymentTerms }}</dd>

      <template v-if="orderData.details.paymentDetails.maskedCreditCardNumber">
        <dt class="cell small-6">{{ $t('payment.cardNumber') }}</dt>
        <dd class="cell small-6">{{ orderData.details.paymentDetails.maskedCreditCardNumber }}</dd>
      </template>

      <dt class="cell small-6">{{ $t('text.account.orderHistory.orderStatus') }}</dt>
      <dd class="cell small-6">{{ orderData.details.orderStatus }}</dd>

      <dt class="cell small-6">{{ $t('text.itemsShipped') }}</dt>
      <dd class="cell small-6">{{ orderData.details.shippedQuantity }}</dd>

      <dt class="cell small-6">{{ $t('text.account.paymentDetails.billingAddress') }}</dt>
      <dd class="cell small-6">
        <div v-show="orderData.billingAddress.billTo">{{ orderData.billingAddress.billTo }}</div>
        <div v-show="orderData.billingAddress.billingNumber">{{ orderData.billingAddress.billingNumber }}</div>
        <div v-show="orderData.billingAddress.line1">{{ orderData.billingAddress.line1 }}</div>
      </dd>
    </dl>
    <dl
      v-if="orderData.details.isConsumerDelivery && orderData.consumerDetails"
      class="description-list grid-x grid-margin-x max-w-sm text-base"
    >
      <dt class="cell small-6">{{ $t('orderDetails.B2B.consumerName') }}</dt>
      <dd class="cell small-6">{{ orderData.consumerDetails.name }}</dd>

      <dt class="cell small-6">{{ $t('text.address.phone') }}</dt>
      <dd class="cell small-6">{{ orderData.consumerDetails.phoneNumber }}</dd>

      <dt class="cell small-6">{{ $t('text.address.email') }}</dt>
      <dd class="cell small-6">{{ orderData.consumerDetails.email }}</dd>

      <dt class="cell small-6">{{ $t('text.deliveryAddress') }}</dt>
      <dd class="cell small-6">
        <div v-show="orderData.consumerDetails.line1">{{ orderData.consumerDetails.line1 }}</div>
        <div v-show="orderData.consumerDetails.line2">{{ orderData.consumerDetails.line2 }}</div>
        <div v-show="orderData.consumerDetails.town">{{ orderData.consumerDetails.town }}</div>
        <div v-show="orderData.consumerDetails.regionIso">{{ orderData.consumerDetails.regionIso }}</div>
        <div v-show="orderData.consumerDetails.postalCode">{{ orderData.consumerDetails.postalCode }}</div>
      </dd>
    </dl>
  </div>
</template>

<script>
export default {
  props: {
    orderData: {
      type: Object,
      required: true,
      default: () => null,
    },
  },
};
</script>
