<script>
import debounce from 'lodash/debounce';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {PdlTabPane, PdlTabs} from '@pedal/pdl-tabs';
import {PdlCollapse} from '@pedal/pdl-collapse';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlDialog} from '@pedal/pdl-dialog';
import {AllocatedItemsApi} from '@/api/allocated-items';
import CartTotals from '@/components/cart/totals/CartTotals';
import CheckoutHoldOptionsCallout from '@/components/containers/checkout/CheckoutHoldOptionsCallout';
import CheckoutNavigation from '@/components/checkout/CheckoutNavigation';
import WarehouseContainer from '@/components/containers/checkout/WarehouseContainer';
import TrekButton from '@/components/TrekButton';
import {ProductType} from '@/constants/product';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {
    PdlLoading,
    PdlTabs,
    PdlTabPane,
    PdlCollapse,
    PdlDialog,
    CartTotals,
    CheckoutHoldOptionsCallout,
    CheckoutNavigation,
    WarehouseContainer,
    TrekButton,
    PdlSectionHeader,
    PdlHeading,
  },
  provide() {
    return {
      container: this,
    };
  },
  data() {
    return {
      visitedTabs: new Set(),
      loading: false,
      isSaveWarningVisible: false,
      isConfirmationDialogVisible: false,
      selectedTabValue: null,
    };
  },
  computed: {
    selectedTab: {
      get() {
        return this.selectedTabValue;
      },
      set(name) {
        this.selectedTabValue = name;
        this.visitedTabs.add(name);
      },
    },
    ...mapGetters('storage', ['hasUserTouchedAllocatedItems']),
    ...mapGetters('user', ['b2bUnitShippingDays']),
    ...mapState('backend', ['encodedContextPath', 'isConsumerFriendlyMode']),
    ...mapState('allocatedItems', [
      'entries',
      'totals',
      'freightTotals',
      'warehouses',
      'warehousesSubtotals',
      'holdOptions',
    ]),
    ...mapState('cart', ['hasAllocatedItemsProcessing']),
    ...mapGetters('allocatedItems', [
      'entriesTree',
      'entriesListTree',
      'entriesProductTypes',
      'warehouseById',
      'hasProductTypesOnHold',
      'productTypesOnHold',
      'eligibleEntriesForTotals',
    ]),
  },
  async created() {
    // Totals should include all selected allocated items
    // Optimize requests to server if user check items one by one
    this.debouncedLoadTotals = debounce(this.fetchTotals, 400);

    await this.init();
  },
  methods: {
    async init() {
      this.loading = true;

      await this.loadCart();
      this.setDefaultTab();
      await this.fetchHoldOptions();

      //switch entry selected by false on page load if productType is onHold
      if (this.hasProductTypesOnHold) {
        this.toggleEntriesSelection({productTypes: this.productTypesOnHold, isSelected: false});
      }

      await this.fetchTotals();

      this.loading = false;
    },

    async loadCart() {
      await this.fetchCart();
      this.hasCheckedAllocatedItems = this.entries.some((entry) => entry.selected);
      if (!this.hasCheckedAllocatedItems && !this.hasUserTouchedAllocatedItems) {
        this.toggleEntriesSelection({productTypes: this.entriesProductTypes, isSelected: true});
      }
    },

    shouldShowConfirmationDialog(productType) {
      return !this.visitedTabs.has(productType) && this.entriesTree.has(productType);
    },

    tryGoToNextCheckoutStep() {
      if (this.shouldShowConfirmationDialog(ProductType.Aftermarket)) {
        this.isConfirmationDialogVisible = true;
        return;
      }

      this.goToNextCheckoutStep();
    },
    async goToNextCheckoutStep() {
      if (this.totals.prices.totalPrice.value === 0) {
        this.isConfirmationDialogVisible = false;

        // prevent a lot of toasts in a row if we’ll push “Proceed to Review” more than one time
        this.$notify.closeAll();
        this.$notify({
          type: 'warning',
          message: this.$t('checkout.B2B.selectAllocated'),
          showClose: true,
          duration: 0,
        });
        return;
      }

      await this.updateCartAllocatedItems();

      if (this.isSaveWarningVisible) {
        return;
      }

      this.navigateTo(`${this.encodedContextPath}/checkout/multi/allocated-items/next/`);
    },
    async updateCartAllocatedItems() {
      this.loading = true;

      this.isConfirmationDialogVisible = false;
      this.isSaveWarningVisible = false;

      this.storeUserSelection();

      try {
        const data = await AllocatedItemsApi.updateAllocatedItems(this.entries);

        if (data?.meta?.feedback?.message?.basePropertyValue) {
          this.isSaveWarningVisible = true;
        }
      } catch (e) {
        this.isSaveWarningVisible = true;
        this.loading = false;
      }
    },
    storeUserSelection() {
      const isAllUnselected = this.entries.every((item) => !item.selected);

      // If user changed initial selection and proceed to Review Order
      // we should not preselect all items when user come back to Manage allocated items
      if (isAllUnselected && !this.hasUserTouchedAllocatedItems) {
        this.addUserTouchedAllocatedItems();
      }
    },
    hasEntriesSelectedByType(productType) {
      return this.eligibleEntriesForTotals
        .filter((entry) => entry.selected)
        .some((entry) => entry.productType === productType);
    },
    setDefaultTab() {
      if (this.entries?.length < 1) {
        return;
      }

      const [firstProductType] = this.entriesProductTypes;
      this.selectedTab = firstProductType;
    },
    navigateTo(url) {
      window.location.href = url;
    },
    getProductTypeLabel(productType) {
      return {
        [ProductType.Bike]: this.$t('text.bikes'),
        [ProductType.Aftermarket]: this.$t('text.accessories'),
      }[productType];
    },
    ...mapMutations('storage', ['addUserTouchedAllocatedItems']),
    ...mapActions('allocatedItems', [
      'fetchCart',
      'fetchTotals',
      'fetchTotalsWithFreight',
      'fetchHoldOptions',
      'toggleEntriesSelection',
    ]),
  },
};
</script>
