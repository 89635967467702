<template>
  <div class="contents">
    <mechanic-notes />
    <section v-if="isLayoutEnabled" :id="componentId" class="pdp-overview grid-container mb-6" qaid="bike-overview">
      <h2 class="sr-only">{{ $t('product.details.nav.label.overview') }}</h2>
      <article id="overview" data-magellan-target="overview" class="pdp-overview--upper kf-block grid-x grid-margin-x">
        <div
          v-for="section of overviewSections"
          :key="section.key"
          :qaid="section.key"
          class="kf-block--intro cell small-12 large-4 mb-4"
        >
          <h3 class="header--md mb-1">{{ $t(`product.detail.${section.headerBP}`) }}</h3>
          <p>{{ section.body }}</p>
        </div>
      </article>
      <div class="cell small-12 separator mb-4 border-t border-gray-10" />
      <article class="pdp-overview--lower kf-block grid-x grid-margin-x">
        <div class="kf-block--media cell small-12 large-8 large-order-2 sm:mb-2 lg:mb-0">
          <bike-overview-video
            v-if="productData.localizedVideoOverview"
            :url="productData.localizedVideoOverview.url"
            :asset-id="productData.localizedVideoOverview.stillAssetId"
            :heading="productData.localizedVideoOverview.overviewVideoHeading"
            :caption="productData.localizedVideoOverview.caption"
          />
          <div v-else-if="productData.assetIdOverview" class="kf-image no-play">
            <image-component
              :alt-text="$t(`altText.${productData.assetIdOverview.replaceAll('_', '-')}`)"
              :asset-id="productData.assetIdOverview"
              img-format="pjpg"
              aspect-ratio="16x9"
              transformation="pad"
              sizes="auto"
              css-class="img-thumbnail thumb"
              :is-responsive="true"
            />
          </div>
        </div>
        <div class="kf-block--list cell small-12 large-4 large-order-1">
          <h3 class="header--md mb-1">
            <pdl-icon class="mr-1" size="18" name="favorite_border" />
            {{ $t('product.detail.overviewHeader4') }}
          </h3>
          <ol class="kf-ordered">
            <li v-for="feature of keyFeatureList" :key="feature.key" :qaid="feature.key" class="mb-1 pl-4">
              {{ feature.body }}
            </li>
          </ol>
        </div>
      </article>
    </section>
    <section
      v-else
      :id="componentId"
      class="key-features text-list vertical-spacer-4 row expand"
      qaid="bike-overview-alt"
    >
      <div v-if="trekFamilyHeadline" class="small-12 columns" qaid="bike-overview-headline">
        <p class="header--xl">{{ trekFamilyHeadline }}</p>
      </div>
      <div v-if="trekFamilyIntro" class="medium-6 large-6 columns" qaid="bike-overview-profile">
        {{ trekFamilyIntro }}
        <div v-if="trekFamilyProfile" class="text-list__text text-weak">
          <br />
          <br />{{ trekFamilyProfile }}
        </div>
      </div>
      <div v-if="keyFeatureList.length" class="medium-6 large-4 columns end">
        <div class="text-list__list">
          <p class="header--lg">
            {{ $t('product.key.features') }}
          </p>
          <ul>
            <li
              v-for="feature of keyFeatureList"
              :key="feature.key"
              :qaid="feature.key"
              class="text-list__item text-weak"
            >
              {{ feature.body }}
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ImageComponent from '@/components/ImageComponent';
import BikeOverviewVideo from '@/components/containers/pdp/overview/bike/BikeOverviewVideo';
import MechanicNotes from '@/components/containers/pdp/overview/MechanicNotes';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {ImageComponent, BikeOverviewVideo, MechanicNotes, PdlIcon},
  props: {
    productData: {
      type: Object,
      default: () => ({}),
    },
    componentId: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    isLayoutEnabled() {
      return !!(this.isEveryOverviewPopulated && this.keyFeatureList.length > 3 && this.isMultimediaAvailable);
    },
    isEveryOverviewPopulated() {
      return !!(this.productData?.copyOverview1 && this.productData?.copyOverview2 && this.productData?.copyOverview3);
    },
    isMultimediaAvailable() {
      return !!(this.productData?.localizedVideoOverview || this.productData?.assetIdOverview);
    },
    keyFeatureList() {
      const FEATURE_PROPERTY = 'keyFeature';
      return Object.entries(this.productData)
        .filter(([property, value]) => {
          return property.includes(FEATURE_PROPERTY) && value;
        })
        .map(([property, value]) => {
          const sectionIndex = property.split(FEATURE_PROPERTY)?.[1];
          return {
            body: value,
            key: `product-feature-${sectionIndex}`,
          };
        });
    },
    trekFamilySection() {
      return this.productData?.categoryData?.trekFamily;
    },
    trekFamilyHeadline() {
      return this.trekFamilySection?.headline;
    },
    trekFamilyIntro() {
      return this.trekFamilySection?.intro;
    },
    trekFamilyProfile() {
      return this.trekFamilySection?.profile;
    },
    overviewSections() {
      const COPY_OVERVIEW = 'copyOverview';
      return Object.entries(this.productData)
        .filter(([property, value]) => {
          return property.includes(COPY_OVERVIEW) && value;
        })
        .map(([property, value]) => {
          const sectionIndex = property.split(COPY_OVERVIEW)?.[1];
          return {
            body: value,
            key: `product-overview-${sectionIndex}`,
            headerBP: `overviewHeader${sectionIndex}`,
          };
        });
    },
  },
};
</script>
