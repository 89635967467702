<template>
  <div
    qaid="editorial-flair-media"
    class="video-container bg-black -mt-4 md:-mt-7 lg:mt-0 transform"
    :style="containerStyles"
  >
    <img v-if="!isVideoVisible" class="w-full absolute h-full object-cover" :src="assetId" alt="" />
    <div
      v-if="!isVideoVisible"
      class="absolute cursor-pointer w-full h-full top-0 left-0"
      role="button"
      tabindex="0"
      @click="play"
      @keyup.enter="play"
    >
      <div class="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <pdl-icon size="48" name="play_circle_outline" />
      </div>
    </div>
    <iframe v-if="isVideoVisible || !isLargeOrGreaterBreakpoint" loading="lazy" :title="title" :src="url" />
  </div>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';
import {mapGetters, mapState} from 'vuex';
import {ViewSize} from '@/constants/view-breakpoints';

const VideoHeightMap = Object.freeze({
  [ViewSize.SMALL]: '300px',
  [ViewSize.MEDIUM]: '400px',
});

export default {
  components: {PdlIcon},
  props: {
    assetId: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    url: {
      type: String,
      default: () => '',
    },
    isVideoVisible: {
      type: Boolean,
      default: () => false,
    },
    play: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    ...mapState('viewSize', ['viewBreakpoint']),
    ...mapGetters('viewSize', ['viewBreakpointMoreThan']),
    isLargeOrGreaterBreakpoint() {
      return this.viewBreakpointMoreThan(ViewSize.MEDIUM);
    },
    containerStyles() {
      const {isLargeOrGreaterBreakpoint, viewBreakpoint, isVideoVisible} = this;
      if (!isLargeOrGreaterBreakpoint) {
        return {
          height: VideoHeightMap[viewBreakpoint],
        };
      }
      return {
        height: isVideoVisible ? `${(66 * window.innerHeight) / 100}px` : '400px',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  --tw-translate-y: 3.5rem;

  @include breakpoint(large up) {
    transition: height 0.3s linear;
    flex: 1 1 0%;
  }
}

iframe {
  width: 100%;
  height: 100%;
}
</style>
