<script>
import storefrontInstance from '@/api/instances/storefront';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import {PdlLoading} from '@pedal/pdl-loading';
import TrekButton from '@/components/TrekButton';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import CartSummary from '@/components/cart/CartSummary';
import FormGroup from '@/components/FormGroup';
import ShippingInfo from '@/components/checkout/ShippingInfo';
import {PdlCollapse} from '@pedal/pdl-collapse';
import CheckoutGrid from '@/components/checkout/CheckoutGrid';
import CheckoutNavigation from '@/components/checkout/CheckoutNavigation';
import OrderDetails from '@/components/checkout/OrderDetails';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlLink} from '@pedal/pdl-link';
import TrekLink from '@/components/TrekLink';
import {PdlDialog} from '@pedal/pdl-dialog';
import DropShipOrdersContainer from '@/components/containers/drop-ship/DropShipOrdersContainer';
import {ProductDeliveryStatus} from '@/constants/product';
import {PdlToastType} from '@/constants/pdl-toast-type';
import {DateFormats} from '@/constants/date-formats';
import dayjs from 'dayjs';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {
    PdlLoading,
    PdlLink,
    TrekButton,
    PdlTabs,
    PdlTabPane,
    CartSummary,
    FormGroup,
    ShippingInfo,
    PdlCollapse,
    CheckoutGrid,
    CheckoutNavigation,
    OrderDetails,
    PdlCallout,
    TrekLink,
    PdlDialog,
    DropShipOrdersContainer,
    PdlSectionHeader,
    PdlHeading,
  },

  props: {
    checkoutStep: {
      type: String,
      default: '',
      required: false,
    },
    orderCode: {
      type: String,
      default: '',
      required: false,
    },
    unavailableItems: {
      type: Array,
      default: () => [],
      required: false,
    },
    baseProperties: {
      type: Array,
      default: () => [],
      required: false,
    },
    bikesOrderCode: {
      type: String,
      default: '',
      required: false,
    },
    partsOrderCode: {
      type: String,
      default: '',
      required: false,
    },
    customWaterBottlesOrderCode: {
      type: String,
      default: '',
    },
    projectOneBikesOrderCode: {
      type: String,
      default: '',
    },
    projectOneWarrantyFramesetOrderCode: {
      type: String,
      default: '',
    },
    isDropShipCustomer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      dialogVisible: false,
      types: ['bikes', 'parts', 'customWaterBottles', 'projectOneBikes'],
      selectedTabName: '',
      asyncOrderInterval: '',
      asyncOrderProcessing: false,
      orderTypeMapping: {},
      timeoutError: false,
      emptyReturnError: false,
      orderData: '',
      orderNumber: '',
      bikesOrderUpdate: this.bikesOrderCode ? this.bikesOrderCode : '',
      partsOrderUpdate: this.partsOrderCode ? this.partsOrderCode : '',
      customWaterBottlesOrderUpdate: this.customWaterBottlesOrderCode ? this.customWaterBottlesOrderCode : '',
      projectOneBikesOrderUpdate: this.projectOneBikesOrderCode ? this.projectOneBikesOrderCode : '',
      projectOneWarrantyFramesetOrderUpdate: this.projectOneWarrantyFramesetOrderCode
        ? this.projectOneWarrantyFramesetOrderCode
        : '',
      isLazy: false,
    };
  },

  computed: {
    ...mapGetters('advancedOrdering', ['advancedOrderingMode', 'aoToggleCompletedWatcher']),
    ...mapState('backend', ['isConsumerFriendlyMode']),
    ...mapGetters('user', ['b2bUnitShippingDays']),
    ...mapState('miniCart', ['containers']),
    ...mapState('cart', ['hasCartValidationError']),
    ...mapState('checkout', ['b2bCartData']),
    ...mapGetters('checkout', [
      'bikes',
      'parts',
      'customWaterBottles',
      'projectOneBikes',
      'cartSummary',
      'isPageLoading',
    ]),

    isReadyToSetMessages() {
      return (
        this.orderTypeMapping.bikes?.isInCartData === this.orderTypeMapping.bikes?.hasOrderUpdate &&
        this.orderTypeMapping.parts?.isInCartData === this.orderTypeMapping.parts?.hasOrderUpdate &&
        this.orderTypeMapping.customWaterBottles?.isInCartData ===
          this.orderTypeMapping.customWaterBottles?.hasOrderUpdate &&
        this.orderTypeMapping.projectOneBikes?.isInCartData === this.orderTypeMapping.projectOneBikes?.hasOrderUpdate
      );
    },

    tabSelected: {
      get() {
        let tabSelected;
        if (this.selectedTabName) {
          tabSelected = this.selectedTabName;
        } else if (!isEmpty(this.bikes)) {
          tabSelected = 'bikes';
        } else if (!isEmpty(this.parts)) {
          tabSelected = 'parts';
        } else if (!isEmpty(this.customWaterBottles)) {
          tabSelected = 'customWaterBottles';
        } else if (!isEmpty(this.projectOneBikes)) {
          tabSelected = 'projectOne';
        } else {
          tabSelected = 'bikes';
        }
        return tabSelected;
      },
      set(name) {
        this.selectedTabName = name;
        if (this.checkoutStep === 'confirmation' && !this.isPageLoading) {
          this.setMessagesTabChange();
        }
      },
    },

    bikesOrderDetails() {
      if (this.bikes && this.bikes.orderDetails) {
        return get(this.b2bCartData, 'bikes.orderDetails', {});
      }

      return {};
    },

    partsOrderDetails() {
      if (this.parts && this.parts.orderDetails) {
        return get(this.b2bCartData, 'parts.orderDetails', {});
      }

      return {};
    },

    customWaterBottlesOrderDetails() {
      return this.customWaterBottles && this.customWaterBottles.orderDetails
        ? get(this.b2bCartData, 'customWaterBottles.orderDetails', {})
        : {};
    },

    projectOneBikesOrderDetails() {
      return this.projectOneBikes && this.projectOneBikes.orderDetails
        ? get(this.b2bCartData, 'projectOneBikes.orderDetails', {})
        : {};
    },

    endpoint() {
      switch (this.checkoutStep) {
        case 'orderReview':
          return '/checkout-b2b/api/orderInfo';
        case 'confirmation':
          return `/checkout-b2b/api/order-review/orderSummaryInfo?orderCode=${this.orderCode}`;
        default:
          console.error('No checkout category provided');
          return undefined;
      }
    },

    summaryTotalItems() {
      const bikesTotal = parseInt(get(this.b2bCartData, 'cartSummary.bikes.totalItems', 0)) || 0;
      const partsTotal = parseInt(get(this.b2bCartData, 'cartSummary.parts.totalItems', 0)) || 0;
      const customWaterBottlesTotal =
        parseInt(get(this.b2bCartData, 'cartSummary.customWaterBottles.totalItems', 0)) || 0;
      const projectOneBikesTotal = parseInt(get(this.b2bCartData, 'cartSummary.projectOneBikes.totalItems', 0)) || 0;
      return bikesTotal + partsTotal + customWaterBottlesTotal + projectOneBikesTotal;
    },

    allocatedItemsExistenceMap() {
      let result = {};
      this.types.forEach((type) => {
        if (this[type]) {
          result[type] = true;
          this.b2bCartData[type].warehouses?.forEach((item) => {
            if (item.entries?.find((el) => el.isAllocated === false || el.isAllocated === null)) {
              result[type] = false;
            }
          });
        }
      });
      return result;
    },
  },

  watch: {
    aoToggleCompletedWatcher() {
      this.loadB2bCart();
    },
  },

  async mounted() {
    this.displayUnavailableItemsWarnings();

    this.$store.commit('cart/setHasValidationError', false);

    await this.loadB2bCart(this.endpoint);

    this.setLoading(false);

    if (this.isDropShipCustomer) {
      await this.fetchMiniCart();
    }
    if (this.checkoutStep === 'confirmation' && !this.orderData) {
      this.asyncOrderCheck();
    }
    if (this.checkoutStep === 'orderReview') {
      this.checkForPlacedOrderParam();
    }
  },

  methods: {
    ...mapMutations('checkout', ['setLoading']),
    ...mapActions('miniCart', ['fetchMiniCart']),
    ...mapActions('checkout', ['loadB2bCart']),

    asyncOrderCheck() {
      const asyncPingInterval = 3000;
      const asyncPingTimeout = 60000;
      this.generateOrderTypeMapping();
      this.asyncOrderProcessing = true;
      this.getOrderNumber();
      this.asyncOrderInterval = setInterval(this.getOrderNumber, asyncPingInterval);
      setTimeout(() => {
        clearInterval(this.asyncOrderInterval);
        this.asyncOrderProcessing = false;
        this.setMessages();
        if (!this.orderData) this.timeoutError = true;
      }, asyncPingTimeout);
    },

    generateOrderTypeMapping() {
      this.orderTypeMapping = {
        bikes: {
          isInCartData: !!this.bikes,
          hasOrderUpdate: this.allocatedItemsExistenceMap.bikes ? true : !!this.bikesOrderUpdate,
        },
        parts: {
          isInCartData: !!this.parts,
          hasOrderUpdate: this.allocatedItemsExistenceMap.parts ? true : !!this.partsOrderUpdate,
        },
        customWaterBottles: {
          isInCartData: !!this.customWaterBottles,
          hasOrderUpdate: this.allocatedItemsExistenceMap.customWaterBottles
            ? true
            : !!this.customWaterBottlesOrderUpdate,
        },
        projectOneBikes: {
          isInCartData: !!this.projectOneBikes,
          hasOrderUpdate: this.allocatedItemsExistenceMap.projectOneBikes ? true : !!this.projectOneBikesOrderUpdate,
        },
        projectOneWarrantyFrameset: {
          isInCartData: !!this.projectOneBikes,
          hasOrderUpdate: this.allocatedItemsExistenceMap.projectOneBikes
            ? true
            : !!this.projectOneWarrantyFramesetOrderUpdate,
        },
      };
    },

    getOrderNumber() {
      if (this.isReadyToSetMessages) {
        this.setMessages();
        return;
      }
      storefrontInstance
        .get(`/checkout-b2b/api/order-review/jdeOrderNumber?orderCode=${this.orderCode}`)
        .then(({data}) => {
          if (
            data.data.bikesOrderCode ||
            data.data.partsOrderCode ||
            data.data.customWaterBottlesOrderCode ||
            data.data.projectOneBikesOrderCode ||
            data.data.projectOneWarrantyFramesetSku
          ) {
            this.orderData = data.data;
            if (!this.bikesOrderUpdate && this.orderData.bikesOrderCode) {
              this.bikesOrderUpdate = this.orderData.bikesOrderCode;
              this.orderTypeMapping.bikes.hasOrderUpdate = true;
            }
            if (!this.partsOrderUpdate && this.orderData.partsOrderCode) {
              this.partsOrderUpdate = this.orderData.partsOrderCode;
              this.orderTypeMapping.parts.hasOrderUpdate = true;
            }
            if (!this.customWaterBottlesOrderUpdate && this.orderData.customWaterBottlesOrderCode) {
              this.customWaterBottlesOrderUpdate = this.orderData.customWaterBottlesOrderCode;
              this.orderTypeMapping.customWaterBottles.hasOrderUpdate = true;
            }
            if (!this.projectOneBikesOrderUpdate && this.orderData.projectOneBikesOrderCode) {
              this.projectOneBikesOrderUpdate = this.orderData.projectOneBikesOrderCode;
              this.orderTypeMapping.projectOneBikes.hasOrderUpdate = true;
            }
            if (!this.projectOneWarrantyFramesetOrderUpdate && this.orderData.projectOneWarrantyFramesetSku) {
              this.projectOneWarrantyFramesetOrderUpdate = this.orderData.projectOneWarrantyFramesetSku;
              this.orderTypeMapping.projectOneWarrantyFrameset.hasOrderUpdate = true;
            }
            this.setMessages();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => this.setLoading(false));
    },

    setMessages() {
      this.tabSelected ? this.setMessagesTabChange() : this.setMessagesFromApi();
    },

    setMessagesTabChange() {
      if (this.tabSelected === 'parts') {
        if (!this.partsOrderUpdate && !this.asyncOrderProcessing) {
          this.orderFailed();
        } else {
          this.emptyReturnError = false;
          this.orderSuccessful(this.partsOrderUpdate);
        }
      }
      if (this.tabSelected === 'bikes') {
        if (!this.bikesOrderUpdate && !this.asyncOrderProcessing) {
          this.orderFailed();
        } else {
          this.emptyReturnError = false;
          this.orderSuccessful(this.bikesOrderUpdate);
        }
      }
      if (this.tabSelected === 'customWaterBottles') {
        if (!this.customWaterBottlesOrderUpdate && !this.asyncOrderProcessing) {
          this.orderFailed();
        } else {
          this.emptyReturnError = false;
          this.orderSuccessful(this.customWaterBottlesOrderUpdate);
        }
      }
      if (this.tabSelected === 'projectOne') {
        if (
          !this.projectOneBikesOrderUpdate &&
          !this.projectOneWarrantyFramesetOrderUpdate &&
          !this.asyncOrderProcessing
        ) {
          this.orderFailed();
        } else if (this.projectOneBikesOrderUpdate) {
          this.emptyReturnError = false;
          this.orderSuccessful(this.projectOneBikesOrderUpdate);
        } else if (this.projectOneWarrantyFramesetOrderUpdate) {
          this.emptyReturnError = false;
          this.orderSuccessful(this.projectOneWarrantyFramesetOrderUpdate);
        }
      }
    },

    //Necessary for fast returns, sometimes data comes back before tabSelected is loaded.
    setMessagesFromApi() {
      if (this.orderTypeMapping.bikes?.isInCartData) {
        if (this.orderTypeMapping.bikes?.hasOrderUpdate) {
          this.orderSuccessful(this.bikesOrderUpdate);
        } else if (!this.orderTypeMapping.bikes?.hasOrderUpdate && !this.asyncOrderProcessing) {
          this.orderFailed();
        }
      } else if (this.orderTypeMapping.parts?.isInCartData) {
        if (this.orderTypeMapping.parts?.hasOrderUpdate) {
          this.orderSuccessful(this.partsOrderUpdate);
        } else if (!this.orderTypeMapping.parts?.hasOrderUpdate && !this.asyncOrderProcessing) {
          this.orderFailed();
        }
      } else if (this.orderTypeMapping.customWaterBottles?.isInCartData) {
        if (this.orderTypeMapping.customWaterBottles?.hasOrderUpdate) {
          this.orderSuccessful(this.customWaterBottlesOrderUpdate);
        } else if (!this.orderTypeMapping.customWaterBottles?.hasOrderUpdate && !this.asyncOrderProcessing) {
          this.orderFailed();
        }
      } else if (this.orderTypeMapping.projectOneBikes?.isInCartData) {
        if (this.orderTypeMapping.projectOneBikes?.hasOrderUpdate) {
          this.orderSuccessful(this.projectOneBikesOrderUpdate);
        } else if (this.orderTypeMapping.projectOneWarrantyFrameset?.hasOrderUpdate) {
          this.orderSuccessful(this.projectOneWarrantyFramesetOrderUpdate);
        } else if (!this.orderTypeMapping.projectOneBikes?.hasOrderUpdate && !this.asyncOrderProcessing) {
          this.orderFailed();
        }
      } else {
        this.orderFailed();
        console.error('Unable to set order number messages');
      }
    },

    orderSuccessful(orderNumber) {
      this.orderNumber = orderNumber;
    },

    orderFailed() {
      this.orderNumber = '';
      this.emptyReturnError = true;
    },

    displayUnavailableItemsWarnings() {
      if (!isEmpty(this.unavailableItems)) {
        this.unavailableItems.forEach((item) => {
          setTimeout(() => {
            return this.$notify({
              type: PdlToastType.WARNING,
              message: this.$t('myCart.B2B.review.skuNotAvailable', [item]),
            });
          }, 1000);
        });
      }
    },

    // Show shipment warning if there is more than 1 warehouse
    showShipmentWarning(type) {
      if (this.checkoutStep === 'orderReview' || this.checkoutStep === 'confirmation') return false;

      if (type === 'bikes') {
        return get(this.b2bCartData, 'bikes.warehouses', []).length > 1;
      } else if (type === 'parts') {
        return get(this.b2bCartData, 'parts.warehouses', []).length > 1;
      } else {
        return false;
      }
    },

    compileData() {
      let compiledData = {};

      let bikeCompiledData = this.getCompiledPartType(this.bikes);
      let partsCompiledData = this.getCompiledPartType(this.parts);
      let customWaterBottlesCompiledData = this.getCompiledPartType(this.customWaterBottles);
      let projectOneBikesCompiledData = this.getCompiledPartType(this.projectOneBikes);

      if (bikeCompiledData) compiledData.bikes = bikeCompiledData;
      if (partsCompiledData) compiledData.parts = partsCompiledData;
      if (customWaterBottlesCompiledData) compiledData.customWaterBottles = customWaterBottlesCompiledData;
      if (projectOneBikesCompiledData) compiledData.projectOneBikes = projectOneBikesCompiledData;

      return compiledData;
    },

    getCompiledPartType(partData) {
      if (isEmpty(partData) || isEmpty(partData.orderDetails)) return null;

      const deliveryDate = !isEmpty(partData.orderDetails?.shipmentInfo?.deliveryDate)
        ? partData.orderDetails?.shipmentInfo?.deliveryDate
        : this.getTodaysDate();

      return this.getISODeliveryDate(deliveryDate)
        ? {
            customerPO: partData.orderDetails.customerPO,
            portofDelivery: partData.orderDetails.portofDelivery,
            holdNote: partData.orderDetails.holdNote,
            holdValue: partData.orderDetails.holdValue,
            holdCode: partData.orderDetails.holdCode,
            selectedShipping: partData.orderDetails.selectedShipping,
            deliveryDate: this.getISODeliveryDate(deliveryDate),
            warehouses: this.getCompiledWarehouseData(partData.warehouses),
          }
        : {};
    },

    getTodaysDate() {
      return dayjs().format(DateFormats.DEFAULT);
    },

    getCompiledWarehouseData(warehouses) {
      if (isEmpty(warehouses)) return [];

      return map(warehouses, (warehouse) => {
        return {
          id: warehouse.id,
          isBackordered: this.getBackorderStatus(warehouse),
          items: map(warehouse.entries, (item) => {
            return {
              sku: item.product.code,
              qty: warehouse.id === ProductDeliveryStatus.BACKORDERED ? item.backorderedQty : item.nonBackorderedQty,
              isAllocated: item.isAllocated,
              productRecipeID: item.product.productRecipeID,
              p1ConfigurationId: item?.product.p1ConfigurationId,
              entryPK: item.entryPK,
            };
          }),
        };
      });
    },

    getBackorderStatus({id, isPrimary, isBackordered}) {
      if (isPrimary) return false;
      return id === ProductDeliveryStatus.BACKORDERED || isBackordered;
    },

    getISODeliveryDate(date) {
      if (!date) return null;

      let deliveryDate = new Date(date);

      return isNaN(deliveryDate) ? '' : deliveryDate.toISOString();
    },

    updateItemBTOData(payload) {
      if (payload) {
        const warehouse = this.bikes.warehouses.find((warehouse) => warehouse.id === payload.warehouseId);
        const item = warehouse.entries.find((item) => item.sku === payload.sku);

        item.lineNote = payload.lineNote;
        item.alreadySoldToConsumer = payload.alreadySoldToConsumer;
      }
    },

    showCheckoutSuccessMessage() {
      return (
        Object.keys(this.allocatedItemsExistenceMap).length > 0 && this.allocatedItemsExistenceMap[this.tabSelected]
      );
    },
    showProjectOneWarrantyFramesetSuccessMessage() {
      return (
        Object.keys(this.allocatedItemsExistenceMap).length > 0 &&
        !this.allocatedItemsExistenceMap[this.tabSelected] &&
        this.orderNumber &&
        this.projectOneWarrantyFramesetOrderUpdate
      );
    },
    checkForPlacedOrderParam() {
      const searchParams = new URLSearchParams(window.location.search);
      const hasPlacedOrderParam = searchParams.get('order-placed');
      if (hasPlacedOrderParam) this.dialogVisible = true;
    },
  },
};
</script>
