<template>
  <div class="cell auto">
    <div class="bg-gray-05 p-2 font-normal">
      <div class="mb-2 text-md">
        <div class="flex justify-between">
          <span qaid="order-totals-subtotal-label">
            {{ $t('text.order.subtotal') }}
          </span>
          <span class="flex justify-between" qaid="order-totals-subtotal-value">
            {{ subtotalPrice }}
          </span>
        </div>
        <div v-if="hasAppliedPromotions" class="flex justify-between mb-2">
          <span qaid="order-totals-discount-label">{{ discountLabel }}</span>
          <span qaid="order-totals-discount-value">
            {{ `- ${discountPrice}` }}
          </span>
        </div>
      </div>
      <div class="mb-2 pb-2 border-b border-gray-60 text-md">
        <div class="flex justify-between">
          <span qaid="order-totals-delivery-label">
            {{ deliveryModeName }}
          </span>
          <span qaid="order-totals-delivery-value">
            {{ shippingGrossPrice }}
          </span>
        </div>
        <template v-if="isCalculatedTaxEnabled">
          <div class="flex justify-between">
            <span qaid="order-totals-total-tax-label">
              {{ $t('text.order.vat') }}
            </span>
            <span qaid="order-totals-total-tax-value">
              {{ totalTax }}
            </span>
          </div>
          <alabama-tax-icon-dialog v-if="isAlabama" />
        </template>
      </div>
      <div class="flex justify-between text-lg">
        <span qaid="order-totals-total-label" class="font-bold">
          {{ $t('text.order.total') }}
        </span>
        <span qaid="order-totals-total-value" class="font-bold">
          {{ orderTotal }}
        </span>
      </div>
      <div v-if="isVatEnabled" class="text-md">
        <div class="flex justify-between">
          <span qaid="order-totals-net-total-label">
            {{ $t('basket.page.totals.netTotal') }}
          </span>
          <span qaid="order-totals-net-total-value">
            {{ orderSubtotal }}
          </span>
        </div>
        <div class="flex justify-between">
          <span qaid="order-totals-vat-label">
            {{ $t('basket.page.totals.estimatedVAT') }}
          </span>
          <span qaid="order-totals-vat-value">
            {{ totalTax }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlabamaTaxIconDialog from '@/components/cart/AlabamaTaxIconDialog.vue';
import {useCartSummary, useOrderSummary} from '@/composables/checkout-summary.js';

export default {
  components: {AlabamaTaxIconDialog},
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const {
      hasAppliedPromotions,
      isVatEnabled,
      isCalculatedTaxEnabled,
      deliveryModeName,
      shippingGrossPrice,
      isAlabama,
      subtotalPrice,
      discountPrice,
      discountLabel,
    } = useCartSummary(props.order);

    const {totalTax, orderTotal, orderSubtotal} = useOrderSummary(props.order);

    return {
      isVatEnabled,
      isCalculatedTaxEnabled,
      subtotalPrice,
      isAlabama,
      orderTotal,
      hasAppliedPromotions,
      discountLabel,
      discountPrice,
      orderSubtotal,
      totalTax,
      shippingGrossPrice,
      deliveryModeName,
    };
  },
};
</script>
