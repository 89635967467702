<template>
  <!-- This is a container to house the summary component & pass data from Vuex specifically for the JSP model overview page: preseasoncollectioncomponent.jsp -->
  <div class="grid-container">
    <pdl-loading :is-loading="dataIsLoading">
      <pre-season-summary
        :order-form-type="orderFormType"
        :summary-data="orderSummaryOverview"
        :base-properties="bpData"
      />
    </pdl-loading>
  </div>
</template>

<script>
import PreSeasonSummary from '@/preSeason/components/PreSeasonSummary';
import {PdlLoading} from '@pedal/pdl-loading';
import {mapActions, mapState} from 'vuex';

export default {
  name: 'PreSeasonSummaryLayout',
  components: {
    PdlLoading,
    PreSeasonSummary,
  },
  props: {
    orderFormType: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState('preSeason', ['dataIsLoading', 'orderSummaryOverview']),
  },
  mounted() {
    this.fetchSummaryOverview(this.orderFormType);
  },
  methods: {
    ...mapActions('preSeason', ['fetchSummaryOverview']),
  },
};
</script>
