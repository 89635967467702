import storefrontInstance from '@/api/instances/storefront';

const fetchClaims = (type, params) => {
  const url = `/claims/lines/${type}/`;
  return storefrontInstance.get(url, {params}).then(({data}) => {
    return {
      entries:
        data?.data?.results?.flatMap((claim) =>
          claim.claimLines.map((claimLine) => ({
            ...claim,
            ...claimLine,
          }))
        ) || [],
      pagination: data.data.pagination,
    };
  });
};

const deleteClaims = (claims) => {
  return storefrontInstance.delete(`/claims/delete/${claims}`);
};

export {fetchClaims, deleteClaims};
