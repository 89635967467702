<template>
  <div role="list" :style="computedStyles" :class="['list-component', computedClasses]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ListComponent',

  props: {
    /**
     * turns the overflow on or off to make list scrollable
     */
    isOverflow: {
      type: Boolean,
      default: false,
    },
    /**
     * sets the max-height to control the over flow
     */
    height: {
      type: String,
      default: '',
    },
  },

  computed: {
    computedStyles() {
      return {
        'max-height': this.height,
      };
    },
    computedClasses() {
      return {
        'list-component--overflow': this.isOverflow,
      };
    },
  },
};
</script>

<style lang="scss">
.list-component {
  &.list-component--overflow {
    max-height: 35vh;
    overflow-y: auto;
  }
}
</style>
