<template>
  <div class="hidden" />
</template>
<script>
import {mapMutations, mapState} from 'vuex';
export default {
  name: 'ProductReviewsHeader',
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('powerReviews', ['isLoadingScript', 'isWindowPwrLoaded']),
  },
  created() {
    let productImage = this.options.productImage;
    if (productImage && !productImage.startsWith('http')) {
      productImage = 'https:' + productImage;
    }
    let pageId = this.options.powerReviewsId ? this.options.powerReviewsId : this.options.productCode;
    let powerReviewsProductDetails = {
      pr_product_image: productImage,
      pr_page_id: pageId,
      pr_product_url: this.options.productUrl,
      pr_product_name: this.options.productName,
      pr_product_description: this.options.productDescription,
      pr_product_category_name: this.options.productCategoryName,
      pr_merchant_user_id: this.options.reviewerId,
      pr_merchant_user_email: this.options.reviewerEmail,
      pr_api_key: this.options.powerReviewsApiKey,
      pr_locale: this.options.locale,
      pr_merchant_group_id: this.options.merchantGroupId,
      pr_merchant_id: this.options.merchantId,
      pr_write_review: this.options.writeReviewUrl,
      pr_product_manufacturer_id: this.options.productCode,
      pr_product_upc: this.options.productUpc,
      pr_product_brand_name: this.options.productBrandName,
      pr_product_price: this.options.productPrice,
      pr_product_in_stock: this.options.productInStock,
    };
    this.setProductDetails(powerReviewsProductDetails);
    this.setBaseProps(this.baseProperties);
    if (!this.isWindowPwrLoaded && !this.isLoadingScript) {
      this.setIsLoadingScript(true);
      const script = document.createElement('script');
      script.setAttribute('src', '//ui.powerreviews.com/stable/4.1/ui.js');
      script.setAttribute('async', '');
      script.onload = () => {
        window.pwr =
          window.pwr ||
          function () {
            // eslint-disable-next-line no-undef
            (pwr.q = pwr.q || []).push(arguments);
          };
        this.setIsWindowPwrLoaded(true);
        this.setIsLoadingScript(false);
      };
      document.head.appendChild(script);
    }
  },
  methods: {
    ...mapMutations('powerReviews', [
      'setProductDetails',
      'setIsWindowPwrLoaded',
      'setIsLoadingScript',
      'setBaseProps',
    ]),
  },
};
</script>
