var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contents"},[_c('div',{directives:[{name:"tippy-html",rawName:"v-tippy-html"}],attrs:{"id":`p1-tooltip-icon-${_vm.id}`}},[_vm._v(_vm._s(_vm.$t('projectOne.B2B.order.btoIcon.hover')))]),_vm._v(" "),_c('pdl-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
      html: `#p1-tooltip-icon-${_vm.id}`,
      interactive: true,
      reactive: true,
      trigger: 'mouseenter focus',
      flip: true,
      sticky: true,
      theme: 'light left-align',
    }),expression:"{\n      html: `#p1-tooltip-icon-${id}`,\n      interactive: true,\n      reactive: true,\n      trigger: 'mouseenter focus',\n      flip: true,\n      sticky: true,\n      theme: 'light left-align',\n    }"}],staticClass:"filter grayscale flex-none self-center cursor-pointer mr-1",attrs:{"name":"project_one_colorwheel","size":"18","qaid":`p1-tooltip-icon-${_vm.id}`}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }