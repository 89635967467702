<template>
  <div class="mb-6">
    <pdl-heading :level="2" size="sm">{{ $t('checkout.confirmation.save.information') }}</pdl-heading>
    <form id="trekGuestRegisterForm" class="guest-register-form my-4">
      <form-group
        ref="password"
        v-model="password"
        qaid="guest-register-password"
        type="password"
        name="password"
        :label="$t('register.pwd')"
        :error="getErrorMessage('password')"
        :autocomplete="autocompleteAttributeValue"
        :help-text="passwordHelpText"
        show-error-messages
        force-display-error
        @focus="getErrorMessage('password') ? '' : (passwordHelpText = $t('register.infoPassword'))"
        @blur="passwordHelpText = ''"
      />
      <form-group
        v-model="passwordConfirmation"
        qaid="guest-register-confirm-password"
        type="password"
        name="registerPasswordConfirmation"
        :label="$t('register.checkPwd')"
        :error="getErrorMessage('passwordConfirmation')"
        :autocomplete="autocompleteAttributeValue"
        show-error-messages
        force-display-error
      />
      <div class="text-sm mb-2">{{ $t('register.emailOptIn') }}</div>
      <form-group
        qaid="guest-register-email-optin"
        type="checkbox"
        name="emailOptInOptions"
        :multi-items="emailOptInOptions"
        @change="handleCheckboxChange"
      />
    </form>
    <register-consent-checkboxes @requiredTermsChecked="handleChecked" />
    <div class="buttons">
      <pdl-button
        id="guest-register-button"
        :disabled="!isAllConsentChecked && !isWorking"
        qaid="guest-register-button"
        primary
        type="submit"
        @click="registerGuest"
      >
        <span>{{ $t('checkout.confirmation.create.account') }}</span>
      </pdl-button>
      <pdl-link id="guest-home-link" qaid="guest-home-link" button secondary :href="`${encodedContextPath}`">
        <span>{{ $t('checkout.confirmation.return.homepage') }}</span>
      </pdl-link>
    </div>
  </div>
</template>

<script>
import RegisterConsentCheckboxes from '@/components/containers/my-account/registration/RegisterConsentCheckboxes.vue';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
import {PdlHeading} from '@pedal/pdl-section-header';
import {mapState} from 'vuex';
import FormGroup from '@/components/FormGroup.vue';
import {UserApi} from '@/api/user.js';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin.js';

const GUEST_REGISTRATION_EVENT = 'guestRegistered';

export default {
  components: {RegisterConsentCheckboxes, PdlButton, PdlLink, FormGroup, PdlHeading},
  mixins: [TrekValidationMixin],
  props: {
    orderId: {
      type: String,
      default: '',
      required: true,
    },
    emailAddress: {
      type: String,
      default: '',
    },
    hasElectraOptinToggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAllConsentChecked: false,
      optInTrek: true,
      optInElectra: true,
      password: '',
      passwordConfirmation: '',
      isWorking: false,
    };
  },
  validations() {
    return {
      password: {
        required: this.trekValidators.required('register.pwd'),
        password: this.trekValidators.password(),
      },
      passwordConfirmation: {
        required: this.trekValidators.required('register.checkPwd'),
        sameAsPassword: this.trekValidators.sameAs('register.checkPwd', this.password, 'register.pwd'),
      },
    };
  },
  computed: {
    ...mapState('backend', ['encodedContextPath', 'CSRFToken']),
    emailOptInOptions() {
      return this.hasElectraOptinToggle
        ? [
            {displayName: this.$t('text.trek'), name: 'optInTrek', checked: true, value: true},
            {displayName: this.$t('text.electra'), name: 'optInElectra', checked: true, value: true},
          ]
        : [{displayName: this.$t('text.trek'), name: 'optInTrek', checked: true, value: true}];
    },
    isSubmitDisabled() {
      return !this.isAllConsentChecked;
    },
  },
  methods: {
    handleChecked(isAllChecked) {
      this.isAllConsentChecked = isAllChecked;
    },
    handleCheckboxChange(e) {
      this.$set(this, e.target.name, e.target.checked);
    },
    registerGuest() {
      this.v$.$validate().then((isValid) => {
        if (!isValid) return;
        this.isWorking = true;
        UserApi.registerGuestCheckoutUser(this.orderId, this.getFormData())
          .then((response) => {
            this.$emit(GUEST_REGISTRATION_EVENT, response.status);
          })
          .catch((err) => {
            this.$emit(GUEST_REGISTRATION_EVENT, err.response.status);
          })
          .finally(() => {
            this.isWorking = false;
          });
      });
    },
    getFormData() {
      let registerData = {
        orderCode: this.orderId,
        uid: this.emailAddress,
        pwd: this.password,
        checkPwd: this.passwordConfirmation,
        termsCheck: this.isAllConsentChecked,
        CSRFToken: this.CSRFToken,
        optin: this.optInTrek,
        optinElectra: this.hasElectraOptinToggle && this.optInElectra,
      };
      return registerData;
    },
  },
};
</script>

<style lang="scss" scoped>
.guest-register-form {
  width: 100%;

  @include breakpoint(medium) {
    width: 50%;
  }

  border-bottom: 1px solid var(--gray-20);
}
</style>
