var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"b2b-scroll-list__item flex",attrs:{"role":"button","tabindex":"0"},on:{"click":_vm.onClick,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onClick.apply(null, arguments)}}},[_c('div',{staticClass:"notification__button"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.notification.readStatus),expression:"!notification.readStatus"},{name:"tippy",rawName:"v-tippy",value:({
        theme: 'light',
        interactive: true,
        trigger: 'mouseenter focus',
        reactive: true,
        popperOptions: {
          positionFixed: true,
          modifiers: {
            keepTogether: true,
            preventOverflow: {
              enabled: false,
            },
            hide: {
              enabled: false,
            },
          },
        },
      }),expression:"{\n        theme: 'light',\n        interactive: true,\n        trigger: 'mouseenter focus',\n        reactive: true,\n        popperOptions: {\n          positionFixed: true,\n          modifiers: {\n            keepTogether: true,\n            preventOverflow: {\n              enabled: false,\n            },\n            hide: {\n              enabled: false,\n            },\n          },\n        },\n      }"}],staticClass:"notification__hitbox",attrs:{"type":"button","title":"Mark as read"}},[_c('span',{staticClass:"notification__indicator"})])]),_vm._v(" "),_c('div',{staticClass:"notification__body"},[_c('h6',{staticClass:"notification__subject",domProps:{"innerHTML":_vm._s(_vm.notification.category.localizedName)}}),_vm._v(" "),_c('p',{staticClass:"notification__text",attrs:{"tabindex":"0","role":"link"},domProps:{"innerHTML":_vm._s(_vm.notification.message)},on:{"click":_vm.messageClickAction,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.messageClickAction.apply(null, arguments)}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }