<template>
  <div class="form-group">
    <label for="pdp-modal-rpa-search-input" qaid="pdp-modal-rpa-search-input-label" class="form-label">
      {{ $t('storelocator.query') }}
    </label>
    <div class="form-group--inline relative">
      <pdl-icon class="absolute rpa-modal__search-icon" name="location_on" />
      <input
        id="pdp-modal-rpa-search-input"
        v-model="zipCode"
        type="text"
        name="pdp-modal-rpa-search-input"
        class="form-control pl-5"
        qaid="pdp-modal-rpa-search-input"
        @keyup.enter="search"
      />
      <trek-button primary form-inline qaid="pdp-modal-rpa-search-button" @click="search">
        <span>{{ $t('storeFinder.search') }}</span>
      </trek-button>
    </div>
  </div>
</template>

<script>
import TrekButton from '@/components/TrekButton';
import {Cookie} from '@/utils/cookie';
import {retailerActions} from '@/store/modules/retailer-search';
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  name: 'PdpRpaModalSearch',
  components: {TrekButton, PdlIcon},
  props: {
    value: {
      type: String,
      default: '',
      required: false,
    },
    retailerData: {
      type: Object,
      default: () => null,
      required: false,
    },
  },
  data() {
    return {
      zipCode: this.retailerData.locationPostalCode || Cookie.get('trek_rpa_user_search_phrase'),
    };
  },
  watch: {
    zipCode(value) {
      this.$store.dispatch(retailerActions.UPDATE_LOCATION, {location: value});
    },
    value() {
      this.zipCode = this.value;
    },
  },
  mounted() {
    this.zipCode = this.value;
  },
  methods: {
    search() {
      if (this.zipCode == '') {
        this.zipCode = this.retailerData.locationPostalCode;
      }
      this.$emit('search', this.zipCode);
    },
  },
};
</script>
