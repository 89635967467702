<template>
  <div id="rcp-details-map" class="grid-container">
    <rcp-map :center="center" :map-classes="mapClasses" @rcpMap="onMapAvailable" />
    <div class="rcp-details-map__zoom-btn-wrapper">
      <rcp-builder-map-zoom-control class="rcp-details-map__zoom-btn" @zoomToFit="zoomFit" />
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import polyline from '@mapbox/polyline';
import {RouteBuilderMapDefaults, RouteBuilderMapMarkers, RouteBuilderDefaultMapLocation} from '@/constants/rcp-builder';
import {RcpBuilderMap} from '@/rcpBuilder/mixins/rcp-builder';
import RcpBuilderMapZoomControl from '@/rcpBuilder/components/RcpBuilderMapZoomControl';
import {
  centerAndFitBounds,
  addPolylineAndSource,
  addRouteMarkers,
  getHoverMarkerElement,
} from '@/utils/rcp-map-helpers';
import RcpMap from '@/components/rcp-map/RcpMap';
import mapboxgl from 'mapbox-gl';

export default {
  components: {
    RcpBuilderMapZoomControl,
    RcpMap,
  },
  mixins: [RcpBuilderMap],
  props: {
    mapClasses: {
      type: String,
      default: '',
    },
    mapData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    elevationChartReference: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      map: undefined,
      hoverIcon: undefined,
      hoverMarker: undefined,
      polylineGeometry: undefined,
      center: RouteBuilderDefaultMapLocation,
    };
  },
  computed: {
    ...mapState('rcpBuilder', ['zoomValue', 'maxZoom', 'minZoom', 'mapView', 'chartOptions']),
    ...mapState('rcpRoutes', ['mapPoint']),
    createMap() {
      return this.mapData.geometry && this.map;
    },
  },
  watch: {
    createMap() {
      if (!this.mapData.geometry) return;
      this.polylineGeometry = polyline.toGeoJSON(
        this.mapData.geometry,
        RouteBuilderMapDefaults.DEFAULT_COORDS_PRECISION
      );
      this.initializeHoverMarkerInDetails();
      centerAndFitBounds(this.map, [{geometry: this.mapData.geometry}], 50, 50, 50);
      addPolylineAndSource(this.map, this.polylineGeometry, this.showHoverIcon);
      addRouteMarkers(
        this.map,
        this.polylineGeometry.coordinates[0],
        RouteBuilderMapMarkers.MAIN_MARKER_SVG,
        'green',
        this.hideHoverIcon
      );
      addRouteMarkers(
        this.map,
        this.polylineGeometry.coordinates[this.polylineGeometry.coordinates.length - 1],
        RouteBuilderMapMarkers.MAIN_MARKER_SVG,
        'red',
        this.hideHoverIcon
      );
    },
    zoomValue() {
      this.map.mapInstance.zoomTo(this.zoomValue, {duration: RouteBuilderMapDefaults.ZOOM_DURATION});
    },
    mapPoint(oldVal, newVal) {
      this.updateHoverIconStylesOnStaticMap('block', 'pointer');
      this.hoverMarker?.setLngLat(newVal).addTo(this.map.mapInstance);
    },
  },
  async mounted() {},
  methods: {
    ...mapMutations('rcpBuilder', ['setCurrentZoomLevel']),
    zoomFit() {
      centerAndFitBounds(this.map, [{geometry: this.mapData.geometry}], 50, 50, 50);
    },
    onMapAvailable(map) {
      this.map = map;
      this.map.addMapAttribution({position: 'top-right'});
      this.map.mapInstance.resize();
    },
    initializeHoverMarkerInDetails() {
      this.hoverIcon = getHoverMarkerElement();
      this.hoverMarker = new mapboxgl.Marker({
        ...this.markerOptions,
        element: this.hoverIcon,
      });
      this.hoverIcon.addEventListener('mouseleave', this.hideHoverIcon);
      this.hoverMarker?.setLngLat([0, 0]).addTo(this.map.mapInstance);
    },
    updateHoverIconStylesOnStaticMap(hoverMarkerStyleProperty, cursorStyleProperty) {
      this.hoverIcon.style.display = hoverMarkerStyleProperty;
      this.map.mapInstance.getCanvas().style.cursor = cursorStyleProperty;
    },
  },
};
</script>

<style lang="scss">
#rcp-details-map {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .mapbox_rcp_canvas {
    aspect-ratio: 16/9;
    width: 100%;
    margin: 2rem 0;
  }

  .rcp-details-map__zoom-btn-wrapper {
    position: absolute;
    bottom: 3rem;
    right: 2rem;
  }

  .mapboxgl-canvas {
    cursor: crosshair;
  }

  .main-marker-element {
    top: -0.813rem;
  }

  .main-marker-element svg {
    height: 2rem;
    width: 2rem;
  }

  .red-marker svg path {
    fill: var(--red-100);
  }

  .green-marker svg path {
    fill: var(--green-140);
  }

  .mapboxgl-ctrl-bottom-right {
    display: none;
  }

  .route-hover-icon {
    > svg {
      width: 0.75rem;
      height: 0.75rem;
      color: var(--blue-100);
    }
  }

  .mapboxgl-marker {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
