<template>
  <div class="content">
    <h3 v-if="heading" class="header--md">
      {{ heading }}
    </h3>
    <a
      :href="url"
      class="kf-video"
      :data-video="url"
      data-ga-event="video-engagement"
      data-ga-category="Video Engagement"
      :data-ga-action="caption"
      data-ga-label="Play"
    >
      <image-component
        v-if="assetId"
        :alt-text="$t('product.family.watch.video')"
        :asset-id="assetId"
        img-format="pjpg"
        aspect-ratio="16x9"
        transformation="pad"
        css-class="vid-thumbnail thumb"
        :is-responsive="true"
      />
      <pdl-icon size="48" name="play_circle_outline" class="text-white" :label="$t('text.watch.video')" />
    </a>
  </div>
</template>

<script>
import ImageComponent from '@/components/ImageComponent';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {ImageComponent, PdlIcon},
  props: {
    assetId: {
      type: String,
      default: () => '',
    },
    url: {
      type: String,
      required: true,
    },
    caption: {
      type: String,
      default: () => '',
    },
    heading: {
      type: String,
      default: () => '',
    },
  },
};
</script>
