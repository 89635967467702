<template>
  <div class="b2b-grid__price" :class="[data.cssClass]">
    <template v-if="data.discount && data.discount !== data.dealerCost">
      <div class="b2b-grid__discount">
        <span class="block line-through text-secondary"
          ><s>{{ data.dealerCost }}</s></span
        >
        <span class="block text-alert">{{ data.discount }}</span>
      </div>
    </template>
    <template v-else>
      <div class="b2b-grid__price--no-discount">
        <span class="block">{{ data.dealerCost }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
