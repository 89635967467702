<template>
  <div class="heading-tooltip">
    <div :id="headingConfig.tooltipId" :qaid="`${headingConfig.tooltipId}-tooltip-container`" class="text-left">
      {{ headingConfig.tooltip }}
    </div>
    <pdl-section-header :size="headingConfig.size">
      <template #content>
        <pdl-heading :level="headingConfig.level">
          {{ headingConfig.title }}&nbsp;<pdl-icon
            v-if="headingConfig.tooltip"
            v-tippy="tooltipConfig"
            :qaid="`${headingConfig.tooltipId}-tooltip-btn`"
            :name="icon"
            :label="headingConfig.iconLabel"
            class="cursor-pointer"
          />
        </pdl-heading>
        <pdl-subheading v-if="headingConfig.subtext">{{ headingConfig.subtext }}</pdl-subheading>
      </template>
    </pdl-section-header>
  </div>
</template>

<script>
import {PdlSectionHeader, PdlHeading, PdlSubheading} from '@pedal/pdl-section-header';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'HeadingTooltip',

  components: {
    PdlSectionHeader,
    PdlHeading,
    PdlSubheading,
    PdlIcon,
  },

  props: {
    /**
     * Sets the size, level, tooltipId, iconLabel, title, subtext of the pdl-heading component used
     */
    headingConfig: {
      type: Object,
      default: () => ({
        tooltipId: '',
        title: '',
        subtext: '',
        iconLabel: '',
        level: '',
        size: '',
      }),
    },
    /**
     * Sets the name of the icon used to display the tooltip
     */
    icon: {
      type: String,
      default: 'info',
    },
  },

  computed: {
    tooltipConfig() {
      return {
        html: `#${this.headingConfig.tooltipId}`,
        reactive: true,
        maxWidth: '20rem',
        placement: 'right',
      };
    },
  },
};
</script>
