import storefrontInstance from '@/api/instances/storefront';
import get from 'lodash/get';

const OrderHistoryDetailsApi = {
  /**
   * Get order details data.
   * @param url
   * @return {Promise<any>}
   */
  async getOrderHistoryDetailsData(url) {
    const response = await storefrontInstance.get(url);
    const orderDetailsData = get(response, 'data.data', {});

    return orderDetailsData && Object.keys(orderDetailsData).length > 0 ? orderDetailsData : null;
  },
};

export {OrderHistoryDetailsApi};
