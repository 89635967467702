<template>
  <div class="pdp-product-details">
    <input id="product-name" type="hidden" :value="product.name" />
    <input id="product-code" type="hidden" :value="variant.code" />
    <input id="product-brand" type="hidden" :value="variant.brandNameFull" />
    <section
      v-if="!purchaseMode || product.isArchived"
      id="buying-zone"
      class="b2b-buying-zone grid-container pt-4"
      data-pdp
    >
      <pdp-product-header :product="product" />
      <div class="b2b-buying-zone__content grid-x grid-margin-x mt-3 md:mt-5">
        <div class="cell medium-12 large-8">
          <div
            class="b2b-buying-zone__carousel-container buying-zone__carousel mb-3 md:mb-5"
            :data-brand-name="product.brandNameFull"
          >
            <!-- Product Image Panel -->
            <pdp-product-image-panel
              v-if="hasImages"
              :product="product"
              :color="selectedColor"
              :max-thumbnails="options.maxPdpThumbnails"
            />
            <div v-else>
              <img
                src="https://media.trekbikes.com/image/upload/w_800,h_600,f_auto,q_auto/default-no-image"
                :alt="$t('skuGrid.B2B.noImage')"
              />
            </div>
          </div>
        </div>
        <div class="cell medium-12 large-4">
          <div class="b2b-buying-zone__info-options-container grid-x grid-margin-x">
            <div class="b2b-buying-zone__info cell medium-6 large-12">
              <div data-product-details>
                <!-- Product Price -->
                <pdp-product-price
                  :product="product"
                  :is-variant-selected="!!variantCode"
                  :retailer="retailerPrice"
                  :consumer="consumerPrice"
                />
                <product-reviews-summary class="mt-2 buying-zone" :product-power-reviews-id="product.powerReviewsID" />
                <pdp-product-summary :product="product" />
              </div>
              <div
                v-if="copyPositioningStatement.length > 0"
                class="buying-zone__positioning-statement mb-2 pt-2 border-t border-gray-10"
              >
                <p qaid="product-positioning-statement" v-html="copyPositioningStatement" />
              </div>
            </div>

            <footer class="b2b-buying-zone__options cell medium-6 large-12 lg:pt-2">
              <!-- Attributes Selection -->
              <pdp-product-attributes-container
                ref="attributesContainer"
                v-model="variantCode"
                :product="product"
                :init-attribute-selection="initAttributeSelection"
                @color-changed="
                  (color) => {
                    selectedColor = color;
                  }
                "
              />
              <!-- Display SKU of Selected Variant -->
              <div
                v-if="variantCode && variantCode.length > 0"
                class="pdp-sku-number flex justify-between my-2 items-center"
              >
                <div />
                <div qaid="buying-zone.sku" class="flex">
                  <span class="buying-zone__code text-gray-60" v-html="buyingZoneCode"></span>
                  <trek-link
                    v-show="options.hasUserAccessToBuyingZone"
                    button
                    text
                    icon-only
                    icon="history"
                    :href="orderHistoryUrl"
                    class="ml-1"
                    qaid="buying-zone.orderHistory"
                    aria-label="Order history"
                  >
                  </trek-link>
                </div>
              </div>

              <div v-if="product.isArchived">
                <div v-if="product.archivedProductCurrentCategoryLink" class="mt-2">
                  <trek-link button primary block :href="product.archivedProductCurrentCategoryLink">
                    <span>{{ $t('productArchive.seeCurrent') }}</span>
                  </trek-link>
                </div>
                <pdp-replacement v-if="variantCode" :replacement-product="replacementProduct" />
              </div>
              <div v-else>
                <pdl-callout v-if="variant.replacementProduct">
                  <template #content>
                    <!-- v-html required here to render HTML appropriately. Otherwise, BP outputs plain text for URL -->
                    <p qaid="active-product-replacement-heading" class="mb-1">{{ $t('productArchive.replacement') }}</p>

                    <pdl-link qaid="active-product-replacement-link" standalone :href="replacementProductLink">
                      {{ $t('productArchive.viewCurrent') }}
                    </pdl-link>
                  </template>
                </pdl-callout>
                <!-- Display Availability -->
                <pdp-product-warehouses
                  v-show="!isSpectrumEnabled && options.hasUserAccessToBuyingZone"
                  v-model="product.code"
                  :product-type="product.productType"
                  @availability-change="onAvailabilityChange"
                />
                <!-- Add to Cart -->
                <pdp-customize-product-button
                  :is-customizable="hasAnyPrices"
                  :project-one-url="projectOneUrl"
                  :spectrum-url="product.spectrumUrl"
                  :custom-configurator-type="product.customConfiguratorType"
                  :is-project-one-configurator-active="options.isProjectOneConfiguratorActive"
                  :custom-product-starting-point="product.customProductStartingPoint"
                  :site-locale="options.siteLocale"
                  :is-b2b="true"
                  :options="options"
                />
                <pdp-add-to-cart
                  v-show="!isSpectrumEnabled"
                  :options="options"
                  :product="product"
                  :variant-code="variantCode"
                  :variant-max-order-quantity="variant.maxOrderQuantity"
                  :is-adding-to-cart="isAddingToCart"
                  :is-purchasable="hasPrice && hasAvailability"
                  :min-quantity="minQuantity"
                  :has-availability="hasAvailability"
                  :add-to-cart-button-caption="addToCartButtonCaption"
                  :success-message="cartSuccessMessage"
                  :has-user-access-to-buying-zone="options.hasUserAccessToBuyingZone"
                  @add-to-cart="beforeAddToCart"
                />
              </div>
              <pdp-rpa-results
                v-if="canShowRpa && options.rpaFunctionalityActive"
                :is-bike="isBike"
                :retailer-data="retailerData"
                :is-location-postal-code-correct="isLocationPostalCodeCorrect"
                :options="options"
                :product="product"
              />
            </footer>
          </div>
        </div>
      </div>
    </section>

    <bundling-dialog
      :show-dialog="isBundleDialogVisible"
      :add-to-cart-button-caption="addToCartButtonCaption"
      @error="addToCartNow(variantCode, quantity)"
      @cancel="setBundleDialogVisibility({type: 'BUYING', isVisible: false})"
      @add-to-cart="setBundleDialogVisibility({type: 'BUYING', isVisible: false})"
    />
  </div>
</template>
<script>
import {mapActions, mapState, mapGetters, mapMutations} from 'vuex';
import {InitProductAttributes} from '@/components/containers/pdp/utils/init-product-attributes';
import get from 'lodash/get';
import {AddToCart} from '@/components/containers/pdp/utils/add-to-cart';
import PdpProductHeader from '@/components/containers/pdp/b2b/PdpProductHeader';
import ProductReviewsSummary from '@/components/power-reviews/ProductReviewsSummary';
import PdpProductSummary from '@/components/containers/pdp/PdpProductSummary';
import PdpProductPrice from '@/components/containers/pdp/PdpProductPrice';
import PdpProductAttributesContainer from '@/components/containers/pdp/PdpProductAttributesContainer';
import PdpProductWarehouses from '@/components/containers/pdp/b2b/PdpProductWarehouses';
import PdpAddToCart from '@/components/containers/pdp/b2b/PdpAddToCart';
import PdpProductImagePanel from '@/components/containers/pdp/PdpProductImagePanel';
import {ProductDetails} from '@/components/containers/pdp/utils/product-details';
import PdpRpaResults from '@/components/containers/pdp/PdpRpaResults';
import PdpReplacement from '@/components/containers/pdp/PdpReplacement';
import TrekLink from '@/components/TrekLink';
import {PdlCallout} from '@pedal/pdl-callout';
import PdpCustomizeProductButton from '@/components/containers/pdp/PdpCustomizeProductButton';
import BundlingDialog from '@/components/containers/pdp/BundlingDialog';
import Variant from '@/utils/variant-helpers';
import {PdlLink} from '@pedal/pdl-link';
import {Url} from '@/utils/url';

export default {
  name: 'PdpProductDetails',
  components: {
    PdpReplacement,
    PdpRpaResults,
    PdpProductImagePanel,
    PdpAddToCart,
    PdpProductWarehouses,
    PdpProductAttributesContainer,
    PdpProductPrice,
    PdpProductSummary,
    ProductReviewsSummary,
    PdpProductHeader,
    TrekLink,
    PdlCallout,
    PdpCustomizeProductButton,
    BundlingDialog,
    PdlLink,
  },
  mixins: [AddToCart, InitProductAttributes, ProductDetails],
  data() {
    return {
      hasAvailability: false,
      quantity: 0,
    };
  },
  computed: {
    orderHistoryUrl() {
      return `${this.encodedContextPath}/my-account/orders?partNumber=${this.variantCode}`;
    },
    minQuantity() {
      return get(this.pricingData, 'vendorMinQty', get(this.product, 'vendorMinQty', 0));
    },
    hasAnyPrices() {
      return this.product?.variants.some(
        (variant) =>
          variant.prices?.retailerPrice?.price?.formatted || variant.prices?.retailerPrice?.price?.low?.formatted
      );
    },
    replacementProduct() {
      if (!this.variantCode) {
        return null;
      }

      return get(
        this.product.variants.find((variant) => variant.code === this.variantCode),
        'replacementProduct'
      );
    },
    replacementProductLink() {
      const url = get(this.replacementProduct, 'url');
      return !url ? '' : Url.withContextPath(url);
    },

    canShowRpa() {
      if (!this.product) {
        return false;
      }

      const allowedByOptions = !get(this.options, 'kioskModeEnabledOnSite');
      const hasRetailers = get(this.retailerData, 'retailers', []).length > 0;
      const isVariantSelected = this.variantCode && this.variantCode.length > 0;

      return allowedByOptions && hasRetailers && isVariantSelected && !this.isConsumerFriendlyMode;
    },
    buyingZoneCode() {
      return `${this.$t('product.variants.sku')} ${this.variantCode}`;
    },
    isSpectrumEnabled() {
      return !!this.product.spectrumUrl;
    },

    isBundleDialogVisible: {
      get() {
        return this.isBuyingZoneBundleDialogVisible;
      },

      set(isVisible) {
        this.setBundleDialogVisibility({type: 'BUYING', isVisible});
      },
    },

    ...mapState('backend', ['encodedContextPath', 'isConsumerFriendlyMode']),
    ...mapState('pdp', ['pdpDetailsLoaded', 'isBuyingZoneBundleDialogVisible']),
    ...mapGetters('storage', ['purchaseMode']),
    ...mapState('viewSize', ['viewBreakpoint']),
  },
  watch: {
    variantCode(code) {
      const productCode = code ? code : this.product.code;
      this.setVariantPdpSku(productCode);

      const mechanicNotes = Variant(productCode, this.product).get()?.mechanicsNotes;
      this.setMechanicNotes(mechanicNotes);

      this.loadPricing(productCode);
      // set this to true if variant code changes
      this.setIsVariantsChanged(true);
    },
  },
  mounted() {
    // Set base product's ID for B2B section only.
    this.setVariantPdpSku(this.product.code);
    window.addEventListener('load', this.pdpFullyLoaded());
    this.setIsECommAvailable(this.options.eCommAvailable);
  },
  beforeDestroy() {
    window.removeEventListener('load', this.pdpFullyLoaded());
  },
  methods: {
    ...mapMutations('pdp', ['setBundleDialogVisibility', 'setIsECommAvailable', 'setMechanicNotes']),
    ...mapActions('pdp', ['setVariantPdpSku']),
    ...mapActions('pdp', ['updatePdpDetailsLoaded']),
    ...mapMutations('productBundle', ['setIsVariantsChanged']),

    onAvailabilityChange(status) {
      this.hasAvailability = status;
    },
    pdpFullyLoaded() {
      this.updatePdpDetailsLoaded(true);
    },
    beforeAddToCart(quantity) {
      this.quantity = quantity;
      this.addToCart(this.variantCode, this.quantity);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .bundle-dialog {
  max-width: 1200px;

  .buttons {
    @include breakpoint(small down) {
      flex-direction: column;
    }
  }
}
</style>
