<template>
  <pdl-file-upload
    :aspect-ratio="uploaderAspectRatio"
    :click-to-upload-msg="clickToUploadMessage"
    :upload-button="false"
    :in-file-name="assetId"
    :in-preset-image-file-url="previewImage"
    :cloudinary-cloud-name="cloudName"
    :cloudinary-upload-preset="uploadPreset"
    :cloudinary-generate-signature="fetchSignature"
    :cloudinary-api-key="apiKey"
    :cloudinary-cropping-aspect-ratio="croppingAspectRatio"
    :extensions="imageFormats"
    :help="helpText"
    @file-uploaded="$emit('file-uploaded', $event)"
    @clear-file="deleteAsset"
  />
</template>

<script>
import {PdlFileUpload} from '@pedal/pdl-file-upload';
import {mapState} from 'vuex';
import {CloudinaryApi} from '@/api/cloudinary.js';
import {Cloudinary} from '@/constants/cloudinary.js';

export default {
  components: {PdlFileUpload},
  props: {
    clickToUploadMessage: {
      type: String,
      default: '',
    },
    croppingAspectRatio: {
      type: String,
      default: '',
    },
    assetId: {
      type: String,
      default: '',
    },
    uploaderAspectRatio: {
      type: String,
      default: '',
    },
    uploadPreset: {
      type: String,
      required: true,
    },
    imageFormats: {
      type: Array,
      default: () => ['jpg', 'gif', 'png'],
    },
    helpText: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('backend', ['imageBaseUrl']),

    apiKey() {
      return Cloudinary.API_KEY;
    },
    cloudName() {
      return Cloudinary.CLOUD_NAME;
    },
    previewImage() {
      if (!this.assetId) return '';
      return `${this.imageBaseUrl}${this.assetId}`;
    },
  },
  methods: {
    async fetchSignature(callback, params) {
      const signature = await CloudinaryApi.getCloudinarySignature(params);
      if (typeof callback === 'function') {
        callback(signature);
      }
    },
    deleteAsset() {
      const asset = this.assetId.split('.')?.[0];
      try {
        CloudinaryApi.deleteAssetFromCloudinary(asset);
      } catch (error) {
        console.error(`Unable to delete asset ${this.assetId} from Cloudinary`, error);
      }
      this.$emit('asset-destroyed', this.assetId);
    },
  },
};
</script>
