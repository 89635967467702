<template>
  <tr :qaid="`apple-label-item-row-${itemIndex}`">
    <td v-for="column in columns" :key="column.key" class="b2b-grid__cell lg:table-cell">
      <div class="b2b-grid__label">
        {{ column.label }}
      </div>

      <div class="b2b-grid__content">
        <div v-if="column.key === 'sku'" class="h-input flex items-center">
          {{ localItem[column.key] }}
        </div>

        <!-- If it's label/active toggle -->
        <div v-else-if="column.key === 'retailLabelStatus'" class="h-input flex items-center">
          <toggle-switch
            :id="`${item.id}-active-toggle`"
            :name="`${item.id}-active-toggle`"
            class="text-white"
            :value="localItem.retailLabelStatus"
            :sync="true"
            :disabled="disableToggles"
            qaid="apple-label-item-toggle"
            @change="toggleItem"
          />
        </div>

        <div v-else-if="column.key === 'retailLabelPrice'">
          <form-group
            :id="`${localItem.sku}-${column.key}`"
            v-model="localItem[column.key]"
            class="mb-0"
            :type="'text'"
            step="0.01"
            :disabled="inputsDisabled"
            :error="getErrorMessage(`localItem.${column.key}`)"
            :label="column.label"
            :maxlength="maxLength(column.key)"
            :name="localItem.sku + column.key"
            :qaid="`apple-label-item-input-${column.key}`"
            :show-label="false"
            :allowed-characters-regex="'^[0-9,.]*$'"
            show-error-messages
            force-display-error
          />
        </div>

        <div v-else-if="column.key === 'advertisedPrice' && !isConsumerFriendlyMode">
          <grid-advertised-price
            :qaid="`apple-label-item-${column.key}`"
            :price="localItem[column.key]"
          ></grid-advertised-price>
        </div>

        <!-- If it's any of the rest -->
        <div v-else>
          <form-group
            v-if="column.key != 'advertisedPrice'"
            :id="`${localItem.sku}-${column.key}`"
            v-model="localItem[column.key]"
            class="mb-0"
            type="text"
            :disabled="inputsDisabled"
            :error="getErrorMessage(`localItem.${column.key}`)"
            :label="column.label"
            :maxlength="maxLength(column.key)"
            :name="localItem.sku + column.key"
            :qaid="`apple-label-item-input-${column.key}`"
            :show-label="false"
            show-error-messages
            force-display-error
          />
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import ToggleSwitch from '@/components/ToggleSwitch';
import FormGroup from '@/components/FormGroup';
import GridAdvertisedPrice from '@/components/grid/GridAdvertisedPrice';
import {mapState} from 'vuex';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  components: {ToggleSwitch, FormGroup, GridAdvertisedPrice},

  mixins: [TrekValidationMixin],

  props: {
    item: {
      type: Object,
      required: true,
    },

    columns: {
      type: Array,
      default: () => [],
    },

    disableToggles: {
      type: Boolean,
      default: false,
    },

    itemIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localItem: this.item,
    };
  },

  validations() {
    const validations = {
      localItem: {
        advertisedPrice: {
          required: this.trekValidators.required('text.msrp'),
        },
      },
    };

    const applyRetailLabelStatusValidations = () => {
      validations.localItem.retailLabelDescription = {
        required: this.trekValidators.required('appleLabel.description'),
        maxLength: this.trekValidators.maxLength('appleLabel.description', 60),
      };
      validations.localItem.retailLabelProductId = {
        required: this.trekValidators.required('appleLabel.partNumber'),
      };
      validations.localItem.retailLabelPrice = {
        required: this.trekValidators.required('appleLabel.price'),
      };
    };

    if (this.localItem.retailLabelStatus) {
      applyRetailLabelStatusValidations();
    }

    return validations;
  },

  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode']),
    inputsDisabled() {
      return !this.localItem.retailLabelStatus;
    },
    isDirty() {
      return (
        this.localItem.retailLabelStatus !== this.localItem.initialRetailLabelStatus || this.v$.localItem?.$anyDirty
      );
    },
  },

  watch: {
    item(value) {
      this.localItem = value;
    },
    localItem: {
      handler(item) {
        this.$emit('update-item', item);
      },
      deep: true,
    },
    isDirty(value) {
      this.$emit('update-is-dirty', value);
    },
  },

  mounted() {
    this.v$.$reset();
  },

  methods: {
    toggleItem(payload) {
      this.localItem.retailLabelStatus = payload.value;
      if (payload.value === false) {
        this.localItem.retailLabelPrice = this.localItem.initialRetailLabelPrice;
      }
    },

    maxLength(columnKey) {
      if (columnKey === 'retailLabelDescription') return 60;

      return null;
    },
  },
};
</script>
