<template>
  <pdl-callout role="alert" kind="error">
    <template #content>
      <section qaid="payment-init-error-message" v-html="$t(errorMessages.message)" />
      <ul v-if="hasErrorFields" class="list-disc mt-1 ml-3">
        <li
          v-for="(value, name) in errorMessages.errorFields"
          :key="`error-${name}`"
          :qaid="`payment-init-error-field-${name}`"
        >
          {{ value }}
        </li>
      </ul>
    </template>
  </pdl-callout>
</template>

<script>
import {PdlCallout} from '@pedal/pdl-callout';

export default {
  name: 'PaymentInitErrors',
  components: {
    PdlCallout,
  },

  props: {
    errorMessages: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    hasErrorFields() {
      return !!Object.keys(this.errorMessages.errorFields).length;
    },
  },
};
</script>
