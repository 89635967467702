'use strict';

export default function (element) {
  var $window = $(window); // this seems to be misused in various places below

  var navigation = element.find('[data-collection-highlights-navigation]');
  var slides = element.find('[data-collection-highlights-slide]');
  var imageHeight = slides.eq(0).find('article').height();

  var index = 0;

  function handleResize() {
    navigation.css('top', imageHeight - navigation.height());
    slides.removeClass('hide');
  }

  function update() {
    slides.addClass('hide');
    $(slides.get(index)).removeClass('hide');
  }

  $($window).resize(handleResize);

  navigation.find('li').on('click', function (e) {
    index = $(this).index();
    navigation.find('a').removeClass('active');
    $(this).find('a').addClass('active');
    update();
    e.preventDefault();
  });

  element.trigger('resize');

  update();
}
