<template>
  <apple-pay-payment-button v-if="isApplePaySelected" />
  <pdl-button v-else id="checkoutReviewAndOrder" block primary @click="handleCheckoutSubmitButtonAction">
    {{ $t('checkout.payment.review.button') }}
  </pdl-button>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import {PdlButton} from '@pedal/pdl-button';
import {PaymentProvider} from '@/constants/payment-provider.js';
import ApplePayPaymentButton from '@/components/checkout/providers/apple-pay/ApplePayPaymentButton.vue';
import trackCheckout from '@/mixins/tracking/track-checkout.js';

export default {
  components: {PdlButton, ApplePayPaymentButton},
  mixins: [trackCheckout],
  inject: ['handleCheckoutSubmitButtonAction'],
  props: {
    onClick: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    ...mapState('checkout', ['selectedPaymentProvider']),
    isApplePaySelected() {
      return (
        this.selectedPaymentProvider?.value === PaymentProvider.CYBERSOURCE_APPLE ||
        this.selectedPaymentProvider?.value === PaymentProvider.GR4VY_APPLE
      );
    },
  },
  mounted() {
    if (!this.isNewPayment) this.setSelectedPaymentProvider({value: PaymentProvider.STORED_PAYMENT});
  },
  methods: {
    ...mapMutations('checkout', ['setSelectedPaymentProvider']),
  },
};
</script>
