<template>
  <div>
    <div v-if="gridData.totalItemCount > 0">
      <table
        id="order-history-grid"
        :class="
          viewBreakpointMoreThan('medium') ? 'b2b-grid b2b-grid--collapse mb-0 is-compact' : 'b2b-grid--collapse mb-0'
        "
      >
        <caption v-if="caption" class="sr-only">
          {{
            caption
          }}
        </caption>
        <thead class="b2b-grid__header">
          <tr>
            <template v-for="(column, index) in headers">
              <th
                v-if="showColumn(column)"
                :key="index"
                class="b2b-grid__cell b2b-grid__head"
                :class="[column.cssClasses]"
                v-html="column.label"
              ></th>
            </template>
          </tr>
        </thead>

        <tbody>
          <!-- Each Row -->
          <template v-for="(row, rowIndex) in paginatedData">
            <order-item
              :key="row.id + rowIndex"
              :row="row"
              :class="{'bg-gray-02': (rowIndex + 1) % 2 === 0}"
              class="order-item"
              @expand="$emit('expand-order', {rowIndex: rowIndex, isExpanded: $event, orderType: orderStatus})"
            ></order-item>

            <transition :key="`child-${row.id}`" name="fade">
              <order-item-details :row="row" />
            </transition>
          </template>
        </tbody>
      </table>
      <div v-if="cartToggle" class="grid-x grid-margin-x mb-5 mt-2">
        <div class="cell medium-6 large-8">
          <pagination-controls
            v-if="gridData.pageCount > 1"
            ref="pagination"
            :page-number="gridData.pageIndex"
            :page-count="gridData.pageCount"
            @page-change="pageChange"
            @prev-page="prevPage"
            @next-page="nextPage"
          ></pagination-controls>
        </div>
        <div class="cell medium-6 large-4">
          <div class="buttons buttons--right-for-md">
            <trek-link id="manage-allocated-items-link" button primary :href="linkManageAllocatedItems">
              {{ $t('text.manageAllocatedItems') }}
            </trek-link>
          </div>
        </div>
      </div>
    </div>
    <pdl-callout v-else>
      <template #content>
        {{ $t('text.search.noResults') }}
      </template>
    </pdl-callout>
  </div>
</template>

<script>
import pagination from '../../../mixins/pagination';
import {mapState, mapGetters} from 'vuex';
import TrekLink from '@/components/TrekLink';

import OrderItem from '@/components/order-item/OrderItem';
import OrderItemDetails from '@/components/containers/my-account/OrderItemDetails';
import PaginationControls from '@/components/PaginationControls';
import {PdlCallout} from '@pedal/pdl-callout';

export default {
  components: {
    OrderItem,
    OrderItemDetails,
    PaginationControls,
    TrekLink,
    PdlCallout,
  },
  mixins: [pagination],
  inject: ['container'],

  provide() {
    return {
      grid: this,
      container: this.container,
    };
  },

  props: {
    caption: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      required: false,
      default: ACC.orderHistoryPageSize || 10,
    },

    gridData: {
      type: Object,
      default: () => null,
    },

    columns: {
      type: Array,
      default: () => [],
    },

    partSearchColumns: {
      type: Array,
      default: () => [],
    },

    childColumns: {
      type: Array,
      default: () => [],
    },

    childDataKey: {
      type: String,
      default: 'items',
    },
    orderStatus: {
      type: String,
      default: '',
    },
    linkManageAllocatedItems: {
      type: String,
      default: '',
    },
    isPartSearch: Boolean,
  },
  computed: {
    ...mapState('backend', ['cartToggle', 'isConsumerFriendlyMode']),
    ...mapState('viewSize', ['viewBreakpoint']),
    ...mapGetters('viewSize', ['viewBreakpointMoreThan']),

    listData() {
      return this.gridData.rows;
    },

    headers() {
      return this.isPartSearch ? this.partSearchColumns : this.columns;
    },

    filteredColumns() {
      return this.headers;
    },
  },

  methods: {
    pageChange(num) {
      this.container[this.orderStatus].pageIndex = num;
      this.container.fetchOrders(this.orderStatus);
    },

    showColumn(column) {
      // Don't show the Backorders column in Invoiced orders tab
      if (column.key === 'backOrders' && this.orderStatus === 'pastOrders') {
        return false;
      }
      return !(column.key === 'orderTotal' && this.isConsumerFriendlyMode);
    },
  },
};
</script>
