import storefront from '@/api/instances/storefront';

const baseUrl = `/checkout/multi/delivery-address`;

const convertToJSON = (formData) => {
  const jsonFormData = {};
  formData.forEach((value, key) => {
    jsonFormData[key] = value;
  });
  return jsonFormData;
};

const DeliveryAddressApi = {
  async addDeliveryAddress(formData) {
    const url = `${baseUrl}/add`;
    return storefront.post(url, formData);
  },
};

export {convertToJSON, DeliveryAddressApi};
