<template>
  <fieldset class="mb-5">
    <legend class="text-lg font-bold">{{ $t('checkout.personal.details') }}</legend>
    <ul>
      <li
        v-for="(componentConfig, index) in componentConfigList"
        :key="`${componentConfig.field}-${index}`"
        :qaid="componentConfig.qaid"
      >
        <component
          :is="componentConfig.component"
          :required="true"
          :error="getCustomError(componentConfig.field)"
          v-bind="getPersonalFieldsProp(componentConfig.field)"
          @input="handleInput"
        />
      </li>
    </ul>
  </fieldset>
</template>

<script>
import {FirstNameField, SurnameField, PhoneField} from '@/components/checkout/fields/personal/index';
import {mapState} from 'vuex';
import {getAddressFormConfig, isNameField, AddressFormComponents} from '@/config/checkout/address-form-config';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  components: {FirstNameField, SurnameField, PhoneField},
  mixins: [TrekValidationMixin],
  props: {
    phoneMaxLength: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      fullNameValidator: null,
      fullNameError: null,
      personalInformationValues: {
        firstNameValue: '',
        surnameValue: '',
      },
    };
  },
  validations() {
    return {
      personalInformationValues: {
        surnameValue: {
          fullNameValid: this.trekValidators.fullNameMaxByteLength(
            this.personalInformationValues.firstNameValue,
            this.locale
          ),
        },

        firstNameValue: {
          fullNameValid: this.trekValidators.fullNameMaxByteLength(
            this.personalInformationValues.surnameValue,
            this.locale
          ),
        },
      },
    };
  },
  computed: {
    ...mapState('backend', ['locale']),
    componentConfigList() {
      return getAddressFormConfig(this.locale).personalDetails;
    },
  },
  methods: {
    async handleInput({value, field}) {
      this.personalInformationValues[field] = value;
      await this.v$.personalInformationValues.$validate();

      if (!this.v$.personalInformationValues.$errors.length) {
        this.fullNameError = undefined;
        return;
      }

      this.fullNameError = this.v$.personalInformationValues.$errors[0].$message;
    },
    getCustomError(fieldName) {
      return isNameField(fieldName) ? this.fullNameError : undefined;
    },
    getPersonalFieldsProp(field) {
      const propConfig = {
        [AddressFormComponents.PHONE.field]: {
          phoneMaxLength: this.phoneMaxLength,
        },
      };
      return propConfig[field] ?? {};
    },
  },
};
</script>
