<template>
  <div class="p-3">
    <pdl-button primary qaid="dst-generate-key-button" @click="generateProductKey">{{
      $t('dst.download.productKey.generateNew')
    }}</pdl-button>
    <pdl-loading v-if="isLoading" class="pt-3" inline :is-loading="isLoading" :message="$t('text.loadingMessage')" />
    <div v-if="productKey" qaid="dst-generate-key-card" class="flex flex-col bg-gray-05 p-3 mt-3 w-fit gap-2">
      <div class="flex items-center gap-3">
        <p qaid="dst-product-key" class="text-xl font-bold">{{ productKey }}</p>
        <pdl-button qaid="dst-product-key-copy-button" small secondary @click="copyToClipboard">{{
          $t('dst.download.productKey.copy')
        }}</pdl-button>
      </div>
      <p qaid="dst-product-key-description">{{ $t('dst.download.productKey.description') }}</p>
    </div>
  </div>
</template>

<script>
import {PdlButton} from '@pedal/pdl-button';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlToastType} from '@/constants/pdl-toast-type';
import occInstance from '@/api/instances/occ';
import debounce from 'lodash/debounce';

const PRODUCT_KEY_API_DEBOUNCE_TIME = 250;

export default {
  components: {
    PdlButton,
    PdlLoading,
  },
  data() {
    return {
      productKey: '',
      isLoading: false,
    };
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard
        .writeText(this.productKey)
        .then(() => {
          this.$notify({
            type: PdlToastType.SUCCESS,
            showClose: true,
            message: this.$t('dst.download.productKey.copy.success'),
          });
        })
        .catch((error) => {
          console.error(error);
          this.$notify({
            type: PdlToastType.ERROR,
            showClose: true,
            message: this.$t('errorMessage.unableToProcess'),
          });
        });
    },
    generateProductKey: debounce(async function () {
      try {
        this.productKey = '';
        this.isLoading = true;
        const path = `/users/current/dst/generate`;
        const {data} = await occInstance.get(path);
        this.productKey = data;
        if (this.productKey === '') {
          throw new Error('Product key returned empty, could not find b2b unit');
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          type: PdlToastType.ERROR,
          showClose: true,
          message: this.$t('errorMessage.unableToProcess'),
        });
      } finally {
        this.isLoading = false;
      }
    }, PRODUCT_KEY_API_DEBOUNCE_TIME),
  },
};
</script>

<style scoped>
/* Added this class that is not available in Tailwind V2, when we update we can remove this css */
.w-fit {
  width: fit-content;
}
</style>
