const PostcodeFormats = Object.freeze({
  ca: 'A#A #A#',
  jp: '###-####',
});

const getPostcodeFormatOptions = (countryCode) => {
  return PostcodeFormats[countryCode];
};

export {PostcodeFormats, getPostcodeFormatOptions};
