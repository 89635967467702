<template>
  <div v-if="product.summary" class="buying-zone__positioning-statement mb-2 pt-2 border-t border-gray-10">
    <p qaid="product-summary">{{ product.summary }}</p>
  </div>
</template>
<script>
export default {
  name: 'PdpProductSummary',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>
