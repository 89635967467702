// Partially inspired by
// https://github.com/spatie/vue-table-component/blob/master/src/components/Pagination.vue

export default {
  props: {
    size: {
      type: Number,
      required: false,
      default: 5,
    },
  },

  data() {
    return {
      pageNumber: 0,
      pageSize: this.size,
    };
  },

  computed: {
    pageCount() {
      const count = Math.ceil(this.listData.length / this.pageSize);

      // If the current page number is higher than the page count then reset to 0
      if (this.pageNumber >= count) this.pageNumber = 0;

      return count;
    },

    paginatedData() {
      const start = this.pageNumber * this.pageSize;
      const end = start + this.pageSize;
      return this.listData.slice(start, end);
    },
  },

  methods: {
    nextPage() {
      this.pageNumber += 1;
      this.$emit('page-change');
    },

    prevPage() {
      if (this.pageNumber > 0) {
        this.pageNumber -= 1;
      }
      this.$emit('page-change');
    },

    pageChange(page) {
      if (page !== this.pageNumber) {
        this.pageNumber = page;
      }
      this.$emit('page-change');
    },
  },
};
