<template>
  <div v-cloak class="grid-container text-md pb-3">
    <pdl-loading page :is-loading="isLoading" />
    <form id="warranty-claim-form" @submit.prevent="submitFeedback">
      <product-feedback-header :show-success="showSuccessNotification" />
      <section class="my-6">
        <pdl-section-header size="md" :is-dividing="true">
          <template slot="content">
            <pdl-heading :level="3" qaid="product-feedback__info-header">
              {{ $t('text.productInformation') }}
            </pdl-heading>
          </template>
        </pdl-section-header>
        <product-feedback-product-details />
      </section>

      <section v-if="showFeedbackDetails" class="my-6">
        <pdl-section-header size="md" :is-dividing="true">
          <template slot="content">
            <pdl-heading :level="3" qaid="product-feedback__details-header">
              {{ $t('warrantyClaims.B2B.feedback.submitNew') }}
            </pdl-heading>
          </template>
        </pdl-section-header>
        <product-feedback-details />

        <hr class="rule my-4" />

        <div class="buttons">
          <trek-button primary type="submit" qaid="product-feedback__submit" :disabled="!showFeedbackDetails">
            {{ $t('warrantyClaims.B2B.feedback.submit') }}
          </trek-button>
        </div>
      </section>
    </form>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';
import ProductFeedbackHeader from './ProductFeedbackHeader';
import ProductFeedbackDetails from './ProductFeedbackDetails';
import ProductFeedbackProductDetails from './ProductFeedbackProductDetails';
import TrekButton from '@/components/TrekButton';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlLoading} from '@pedal/pdl-loading';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
import {scrollToTop} from '@/utils/scroll-to';

export default {
  components: {
    ProductFeedbackHeader,
    ProductFeedbackDetails,
    ProductFeedbackProductDetails,
    TrekButton,
    PdlSectionHeader,
    PdlHeading,
    PdlLoading,
  },
  mixins: [TrekValidationMixin],
  props: {
    claimLine: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      showSuccessNotification: false,
    };
  },

  computed: {
    ...mapState('productFeedback', ['feedbackData', 'showFeedbackDetails', 'isLoading']),
  },

  watch: {
    v$() {
      if (this.v$.$invalid) return;
      this.updateFlag({flagName: 'showErrorCallout', value: false});
    },
  },

  mounted() {
    this.fetchFeedbackData();
  },

  methods: {
    ...mapActions('productFeedback', ['fetchFeedbackData', 'saveProcessedFeedback']),
    ...mapMutations('productFeedback', ['updateFeedbackProperty', 'setFeedbackMsg', 'updateFlag']),
    ...mapActions('storage', ['setFeedbackMsg']),

    submitFeedback() {
      this.v$.$validate().then(async (isValid) => {
        if (isValid) {
          try {
            const submitResponse = await this.saveProcessedFeedback(false);

            if (checkMetaFeedbackType(submitResponse.data, MetaFeedbackType.SUCCESS)) {
              const feedbackMsg = this.$t('warrantyClaims.B2B.feedback.submit.confirm', [submitResponse.data.data.id]);
              this.setFeedbackMsg(feedbackMsg);

              window.location = `${ACC.config.encodedContextPath}/warranty-claim/all-warranty-claims/`;
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          scrollToTop();
        }
      });
    },
  },
};
</script>
