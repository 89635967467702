<template>
  <div>
    <div v-if="hasProducts" class="pb-2">
      <product-list-view-item
        v-for="(product, i) in products"
        :key="`${settings.locationId}${product.code}`"
        :item-id="i"
        :is-compare-button-enabled="isCompareButtonEnabled"
        :product="product"
        :products="products"
        @state-changed="(state) => itemStateChanged(product.code, state)"
      />
    </div>
  </div>
</template>
<script>
import ProductsList from './mixins/products-list';
import ProductListViewItem from './ProductListViewItem';
import {DelayedProductsRendering} from '@/components/containers/bulk-product/mixins/delayed-rendering';

export default {
  components: {
    ProductListViewItem,
  },
  mixins: [ProductsList, DelayedProductsRendering],
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    itemStateChanged(code, state) {
      this.$emit('item-state-changed', {code, state});
    },
  },
};
</script>
