<template>
  <div>
    <section v-if="compare" :id="uid" class="details-list grid-container mb-6" :data-magellan-target="uid">
      <ul class="grid-x grid-margin-x">
        <slot name="components" />
      </ul>
      <div class="mb-1">
        <p v-html="b2b ? bp('disclaimerB2B') : bp('disclaimerB2C')" />
      </div>
      <div class="mb-1">
        <p v-html="isBike ? bp('weightBike') : bp('weightAftermarket')" />
      </div>
    </section>
    <slot v-else name="components" />
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  props: {
    uid: {
      type: String,
      default: undefined,
    },
    compare: {
      type: Boolean,
      default: undefined,
    },
  },
  computed: {
    ...mapState('backend', ['b2b']),
    ...mapState('pdp', ['isBike']),
  },
};
</script>
