<template>
  <form-group
    v-model="paymentMethod"
    name="paymentMethod"
    type="select"
    :multi-items="paymentMethodList"
    :label="$t('text.paymentMethod')"
    required
    force-display-error
    show-error-messages
    :error="fieldError('paymentMethod')"
  />
</template>

<script>
import FormGroup from '@/components/FormGroup.vue';
import {mapState, mapMutations} from 'vuex';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

const paymentMethodConfig = [
  {
    value: 'creditCard',
    label: 'checkout.paymentMethod.creditCard',
  },
];

export default {
  components: {FormGroup},
  mixins: [TrekValidationMixin],
  inject: ['validationErrors'],
  validations() {
    return {
      paymentMethod: {
        required: this.trekValidators.required('text.bank'),
      },
    };
  },
  computed: {
    ...mapState('checkout', ['paymentData']),
    paymentMethod: {
      get() {
        return this.paymentData.kcpPaymentMethod;
      },
      set(paymentMethod) {
        this.setKcpPaymentMethod(paymentMethod);
      },
    },
    paymentMethodList() {
      return paymentMethodConfig.map((paymentMethod) => ({
        value: paymentMethod.value,
        id: paymentMethod.value,
        displayName: this.$t(paymentMethod.label),
      }));
    },
  },
  mounted() {
    this.setKcpPaymentMethod(paymentMethodConfig[0].value);
  },
  methods: {
    ...mapMutations('checkout', ['setKcpPaymentMethod']),
    fieldError(fieldName) {
      const theError =
        this.validationErrors.find((item) => item.field === fieldName)?.msg ?? this.getErrorMessage(fieldName);

      return theError;
    },
  },
};
</script>
