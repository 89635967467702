<template>
  <tr>
    <template v-for="column in filterHeaders()">
      <td
        v-if="column.key === 'icons'"
        :key="column.key"
        qaid="shipment.grid.row.notes"
        class="b2b-grid__cell lg:table-cell"
        :class="[
          {
            'whitespace-nowrap': column.key === 'icons',
          },
          column.cssClasses,
        ]"
      >
        <div class="flex flex-nowrap">
          <pdl-icon v-if="item.isSplitWarehouse" name="call_split" size="18" />
          <grid-note
            v-show="item.lineComment"
            :row-id="item.id"
            :data="{
              value: 'insert_drive_file',
              content: item.lineComment,
              editable: false,
              cssClass: 'ml-1',
            }"
          />
        </div>
      </td>
      <grid-cell
        v-else-if="column.key === 'sku'"
        :key="column.key"
        :cell-key="column.key"
        :label="column.label"
        :qaid="`order-detail-item-${column.key}`"
        :value="itemData.product.code"
        :classes="['w-1', column.cssClasses]"
      />
      <grid-cell
        v-else-if="column.key === 'itemName'"
        :key="column.key"
        :cell-key="column.key"
        :label="column.label"
        :qaid="`order-detail-item-${column.key}`"
        :value="itemData.product.itemName"
        :classes="[column.cssClasses]"
      />
      <grid-cell
        v-else-if="column.key === 'upc'"
        :key="column.key"
        :cell-key="column.key"
        :label="column.label"
        :qaid="`order-detail-item-${column.key}`"
        :value="itemData.product.upc"
        :classes="['w-1', column.cssClasses]"
      />

      <grid-cell
        v-else
        :key="column.key"
        :cell-key="column.key"
        :label="column.label"
        :qaid="`order-detail-item-${column.key}`"
        :value="column.key !== 'lineStatus' ? itemData[column.key] : null"
        :classes="[
          {
            'whitespace-nowrap': column.key === 'lineStatus' || column.key === 'icons',
            'w-1': column.key !== 'itemName',
          },
          column.cssClasses,
        ]"
      >
        <pdl-label
          v-if="column.key === 'lineStatus'"
          class="align-self-top mr-0"
          :kind="calculateLabelKind(item[column.key])"
        >
          {{ $t(`${statusNamesMap[item[column.key]]}`) }}
        </pdl-label>
      </grid-cell>
    </template>
  </tr>
</template>

<script>
import {mapState} from 'vuex';
import {PdlLabel} from '@pedal/pdl-label';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {
    PdlLabel,
    PdlIcon,
  },

  inject: ['container', 'headers'],
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      statusNamesMap: {
        AWAITING_RETURN: 'text.account.order.status.awaitingReturn',
        CANCELLED: 'text.account.order.status.display.cancelled',
        INVOICED: 'text.account.order.status.invoiced',
        IN_WAREHOUSE: 'text.account.order.status.inWarehouse',
        PROCESSING: 'text.account.order.status.display.processing',
        SHIPPED: 'text.account.order.consignment.status.shipped',
        PLACED: 'text.account.order.status.placed',
      },
    };
  },
  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode']),
    itemData() {
      const data = this.item;
      data['unitPrice'] = data?.basePrice?.formattedValue;
      data['lineSubtotal'] = data?.totalPrice?.formattedValue;
      return data;
    },
  },
  methods: {
    filterHeaders() {
      const retailerOnlyHeaders = ['unitPrice', 'lineSubtotal'];
      const filteredHeaders = this.headers?.filter(({key}) => {
        return !(key && this.isConsumerFriendlyMode && retailerOnlyHeaders.includes(key));
      });
      return filteredHeaders;
    },
    calculateLabelKind(status) {
      let labelKind = this.getLabel(status);
      return labelKind;
    },
    getLabel(status) {
      switch (status) {
        case 'CANCELLED':
          return 'critical';
        case 'INVOICED':
          return 'prominent';
        case 'IN_WAREHOUSE':
          return 'important';
        case 'PROCESSING':
          return 'important';
        case 'SHIPPED':
          return 'positive';
        case 'PLACED':
          return 'default';
      }
    },
  },
};
</script>
