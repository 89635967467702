<template>
  <pdl-collapse-item :name="name">
    <div slot="title" class="heading heading--sm" qaid="warehouse.heading">
      {{ warehouse.name }} ({{ warehouseSubtotals.quantity }})
    </div>

    <slot name="info">
      <div class="grid-x">
        <div v-cloak class="cell large-4" qaid="warehouse.infoText">
          <p>{{ $t('checkout.B2B.manageShipment') }}</p>
        </div>
      </div>
    </slot>

    <grid-container>
      <table class="b2b-grid b2b-grid--collapse is-compact" qaid="allocated-items-sku-grid" aria-label="warehause-grid">
        <thead class="b2b-grid__header" qaid="warehouse.grid.head">
          <th id="warehouse.grid.checkBox" class="b2b-grid__cell b2b-grid__head" qaid="warehouse.grid.checkBox">
            <label class="checkbox mb-0">
              <input
                id="selectAllItems"
                v-model="allSelected"
                type="checkbox"
                :disabled="isOnHold"
                @change="selectAllItems"
              />
              <span class="control-indicator"></span>
            </label>
          </th>
          <th
            v-for="header in visibleHeaders"
            :id="header.name"
            :key="header.name"
            class="b2b-grid__cell b2b-grid__head"
            :qaid="`warehouse-grid-column-${header.name}`"
          >
            {{ header.label }}
          </th>
        </thead>

        <tbody qaid="warehouse.grid.body">
          <template v-for="item in paginatedData">
            <warehouse-item
              :key="item.id"
              :item="item"
              :is-on-hold="isOnHold"
              :headers="headers"
              @toggle-selection="updateItemsSelection"
            />
          </template>
        </tbody>
      </table>
    </grid-container>
    <pagination-controls
      v-if="pageCount > 1"
      ref="pagination"
      :page-number="pageNumber"
      :page-count="pageCount"
      @page-change="pageChange"
      @prev-page="prevPage"
      @next-page="nextPage"
    >
    </pagination-controls>
  </pdl-collapse-item>
</template>

<script>
import pagination from '@/mixins/pagination';
import {mapMutations, mapState} from 'vuex';
import {PdlCollapseItem} from '@pedal/pdl-collapse';
import GridContainer from '@/components/grid/GridContainer';
import WarehouseItem from '@/components/containers/checkout/WarehouseItem';
import PaginationControls from '@/components/PaginationControls';

export default {
  components: {PdlCollapseItem, GridContainer, WarehouseItem, PaginationControls},

  mixins: [pagination],

  props: {
    warehouse: {
      type: Object,
      required: true,
    },
    warehouseSubtotals: {
      type: Object,
      default: () => null,
    },
    entries: {
      type: Array,
      default: () => [],
    },
    size: {
      type: Number,
      required: false,
      default: ACC.allocatedItemsPageSize || 6,
    },
    name: {
      type: String,
      default: '',
    },
    isOnHold: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      allSelected: false,
      listData: this.entries,
      updateItemCallCount: 0,
      headers: [
        {name: '', label: ''},
        {name: 'item', label: this.$t('order.item')},
        {name: 'orderNumber', label: this.$t('text.account.orderHistory.orderNumber')},
        {name: 'poNumber', label: this.$t('text.poNumber')},
        {name: 'sku', label: this.$t('product.variants.sku')},
        {name: 'price', label: this.$t('order.price')},
        {name: 'qty', label: this.$t('text.account.savedLists.qty')},
        {name: 'allocated', label: this.$t('text.allocated')},
        {name: 'cancellationDate', label: this.$t('text.cancellationDate')},
        {name: 'subtotal', label: this.$t('text.order.subtotal')},
      ],
    };
  },

  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode']),

    isAllSelected() {
      return this.entries?.every((item) => item.selected);
    },
    isAllSelectable() {
      return this.entries?.every((item) => item.isAllowedToShip);
    },

    visibleHeaders() {
      return this.headers.filter(
        (header) => !(this.isConsumerFriendlyMode && ['price', 'subtotal'].includes(header.name))
      );
    },
  },

  watch: {
    isAllSelected: {
      immediate: true,
      handler() {
        this.allSelected = this.isAllSelected;
      },
    },
  },

  methods: {
    updateItemsSelection() {
      this.$emit('update-selection');
    },
    selectAllItems() {
      const newState = this.allSelected;

      this.entries
        ?.filter((item) => item.isAllowedToShip && !this.isOnHold)
        ?.forEach((item) => {
          item.selected = newState;
        });

      this.updateItemsSelection();
    },
    ...mapMutations('cart', ['setHasAllocatedItemsProcessing']),
  },
};
</script>
