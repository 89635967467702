<template>
  <td class="b2b-grid__cell lg:table-cell" :class="[...classes]" :qaid="qaid">
    <div class="b2b-grid__label">
      <slot name="label">
        <span v-html="label"></span>
      </slot>
    </div>
    <div class="b2b-grid__content">
      <slot>
        <span v-html="value"></span>
      </slot>
    </div>
  </td>
</template>

<script>
export default {
  props: {
    cellKey: {
      type: String,
      default: '',
    },
    classes: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    qaid: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>
