<template>
  <ul>
    <template v-if="hasPreferredRetailer">
      <li class="text">{{ preferredRetailerData.description }}</li>
      <li class="text">{{ preferredRetailerData.address.line1 }}</li>
      <li v-if="preferredRetailerData.address.line2" class="text">{{ preferredRetailerData.address.line2 }}</li>
      <li class="text">{{ preferredRetailerData.address.town }}</li>
      <li class="text">
        <template v-if="preferredRetailerData.region">
          {{ preferredRetailerData.address.region.name }}
        </template>
        {{ preferredRetailerData.address.postalCode }}
      </li>
      <li class="text">{{ preferredRetailerData.address.country.name }}</li>
    </template>
    <template v-else>
      <li class="text">{{ $t('profile.no.preferredRetailer') }}</li>
    </template>
  </ul>
</template>
<script>
export default {
  props: {
    preferredRetailerData: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    hasPreferredRetailer() {
      return Boolean(this.preferredRetailerData);
    },
  },
};
</script>
