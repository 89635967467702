<template>
  <div class="text-base">
    <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1">{{ $t('text.hangTag') }}</pdl-heading>
      </template>
    </pdl-section-header>

    <p class="md:w-1/2" qaid="hangTagSelectFeatureText">{{ $t('hangTags.B2B.selectFeatures') }}</p>

    <pdl-section-header size="md" :is-dividing="true" class="mt-4">
      <template slot="content">
        <pdl-heading :level="3">{{ $t('hangTags.B2B.selectLogo') }}</pdl-heading>
      </template>
    </pdl-section-header>

    <fieldset class="form-fieldset">
      <label for="logo-selection-option-1" class="radio">
        <input
          id="logo-selection-option-1"
          v-model="formData.logo"
          type="radio"
          name="logo"
          value="logo-trek"
          required
          qaid="hangTagSelectTrekLogo"
        />
        <span class="control-indicator"></span>
        {{ $t('sellSheets.B2B.logo.trek') }}
      </label>

      <label for="logo-selection-option-2" class="radio">
        <input
          id="logo-selection-option-2"
          v-model="formData.logo"
          type="radio"
          name="logo"
          value="logo-store"
          required
          qaid="hangTagSelectStoreLogo"
        />
        <span class="control-indicator"></span>
        {{ $t('sellSheets.B2B.logo.store') }}
      </label>

      <label for="logo-selection-option-3" class="radio">
        <input
          id="logo-selection-option-3"
          v-model="formData.logo"
          type="radio"
          name="logo"
          value="logo-exclude"
          required
          qaid="hangTagExcludeLogo"
        />
        <span class="control-indicator"></span>
        {{ $t('hangTags.B2B.excludeLogo') }}
      </label>
    </fieldset>

    <pdl-section-header size="md" :is-dividing="true" class="mt-4">
      <template slot="content">
        <pdl-heading :level="3">{{ $t('text.bikeInformation') }}</pdl-heading>
      </template>
    </pdl-section-header>

    <fieldset class="form-fieldset">
      <label class="checkbox">
        <input
          id="bikeInfo-checkbox-1"
          v-model="formData.showModelYear"
          type="checkbox"
          name="showModelYear"
          value="true"
          qaid="hangTagBikeModelYear"
        />
        <span class="control-indicator"></span>
        {{ $t('hangTags.B2B.includeModelYear') }}
      </label>

      <label class="checkbox">
        <input
          id="bikeInfo-checkbox-2"
          v-model="formData.showPricing"
          type="checkbox"
          name="showPricing"
          value="true"
          qaid="hangTagBikeShowPricing"
        />
        <span class="control-indicator"></span>
        {{ $t('text.showPricing') }}
      </label>
    </fieldset>

    <fieldset v-show="formData.showPricing" class="form-fieldset">
      <label for="bikeInfo-radio-option-1" class="radio">
        <input
          id="bikeInfo-radio-option-1"
          v-model="formData.showOriginalPrice"
          type="radio"
          name="showOriginalPrice"
          value="true"
          qaid="hangTagBikeShowAdvertised"
        />
        <span class="control-indicator"></span>
        {{ $t('text.buyingZone.advertised') }}
      </label>

      <label for="bikeInfo-radio-option-2" class="radio">
        <input
          id="bikeInfo-radio-option-2"
          v-model="formData.showOriginalPrice"
          type="radio"
          name="showOriginalPrice"
          value="false"
          :disabled="!(isAppleLabelDealer && hasApplePrice)"
          qaid="hangTagBikeShowOurPrice"
        />
        <span class="control-indicator"></span>
        {{ $t('hangTags.B2B.ourPrice') }}
      </label>
    </fieldset>

    <pdl-section-header size="md" :is-dividing="true" class="mt-4">
      <template slot="content">
        <pdl-heading :level="3">{{ $t('text.otherOptions') }}</pdl-heading>
      </template>
    </pdl-section-header>

    <fieldset class="form-fieldset">
      <label class="checkbox">
        <input
          id="otherOptions-checkbox-1"
          v-model="formData.showMarketingStory"
          type="checkbox"
          name="showMarketingStory"
          value="true"
          qaid="hangTagShowMarketingStory"
        />
        <span class="control-indicator"></span>
        {{ $t('text.marketingStory') }}
      </label>

      <label class="checkbox">
        <input
          id="otherOptions-checkbox-2"
          v-model="formData.showProductFeatures"
          type="checkbox"
          name="showProductFeatures"
          value="true"
          qaid="hangTagShowProductFeatures"
        />
        <span class="control-indicator"></span>
        {{ $t('text.productFeatures') }}
      </label>
      <div class="lg:max-w-sm">
        <label for="otherOption.pageFormat" class="form-label">{{ $t('sellSheets.B2B.pageFormat') }}</label>
        <div class="select">
          <select
            id="otherOption.pageFormat"
            v-model="formData.pageFormat"
            class="form-control"
            name="pageFormat"
            qaid="handTagPageFormat"
            @change="onPageFormatSelect"
          >
            <option key="0" value="OneSided">
              {{ $t('sellSheets.B2B.1sided') }}
            </option>
            <option key="1" value="TwoSided">
              {{ $t('sellSheets.B2B.2sided') }}
            </option>
          </select>
        </div>
      </div>
    </fieldset>

    <div class="buttons pb-3">
      <trek-link
        ref="downloadButton"
        button
        primary
        icon="get_app"
        download="file.pdf"
        :href="requestUrl"
        qaid="hangTagDownload"
        @click="onSubmit"
      >
        <span>{{ $t('hangTags.B2B.downloadTag') }}</span>
      </trek-link>
      <trek-button secondary qaid="hangTagCancel" @click="close">
        <span>{{ $t('checkout.multi.cancel') }}</span>
      </trek-button>
    </div>

    <input
      id="productCode"
      type="hidden"
      name="productCode"
      value="${hangTagForm.productCode}"
      qaid="hangTagHiddenProductCode"
    />

    <input id="hangTagsPdfUrl" type="hidden" value="${hangTagPdfUrl}" qaid="hangTagHiddenPdfUrl" />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin.js';
import {scrollToElement} from '@/utils/scroll-to';

export default {
  components: {TrekButton, TrekLink, PdlSectionHeader, PdlHeading},
  mixins: [TrekValidationMixin],
  props: {
    productCode: {
      type: String,
      required: true,
    },
    hangTagPdfUrl: {
      type: String,
      required: true,
    },
    returnUrl: {
      type: String,
      required: true,
    },
    hasApplePrice: {
      type: Boolean,
      required: true,
    },
    isAppleLabelDealer: {
      type: Boolean,
      required: true,
    },
    isShipment: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      includeImage: false,
      formData: {
        logo: 'logo-store',
        showModelYear: true,
        showPricing: true,
        showOriginalPrice: true,
        showMarketingStory: false,
        showProductFeatures: true,
        paperSize: 'Letter',
        pageFormat: 'OneSided',
        pdfName: '',
        pdfView: true,
        productCode: this.productCode,
        tidyHTML: true,
        suffixTimestamp: false,
        isShipment: this.isShipment,
      },
    };
  },

  computed: {
    ...mapState('backend', ['encodedContextPath']),

    // Split the bound form data values into a query string to be sent with the request for the PDF
    query() {
      const esc = encodeURIComponent;
      return Object.keys(this.formData)
        .map((k) => esc(k) + '=' + esc(this.formData[k]))
        .join('&');
    },

    // The URL that will get used as the target for getting the PDF
    requestUrl() {
      return `${this.hangTagPdfUrl}${this.formData.productCode}/pdf?${this.query}`;
    },

    pdfName() {
      return `Hang_Tag_${this.productCode}`;
    },
  },

  validations() {
    const validations = {
      formData: {
        logo: {
          required: this.trekValidators.required('logo'),
        },
      },
    };
    return validations;
  },

  mounted() {
    this.formData.pdfName = this.pdfName;
  },

  methods: {
    // In modern browsers, download the PDF by programatically clicking the HTML link with the
    // download attribute. For browsers that don't support it, just open a new window before
    // redirecting
    downloadPDF(e) {
      if (typeof this.$refs.downloadButton.download != 'undefined') {
        return this.close();
      } else {
        e.preventDefault();
        window.open(this.requestUrl, '_blank');
      }
      return this.close();
    },

    onSubmit(e) {
      // Validate using vuelidate. If there are errors, scroll the browser to top-most error
      // on the page
      this.v$.$validate().then((valid) => {
        if (!valid) {
          const firstErrorFieldName = document.querySelector('.has-error');
          scrollToElement(firstErrorFieldName);
        } else {
          return this.downloadPDF(e);
        }
      });
    },

    close() {
      if (this.isShipment === 'true') {
        window.close();
      } else {
        this.redirectToPdp();
      }
    },

    redirectToPdp() {
      return location.replace(this.returnUrl);
    },
    onPageFormatSelect(e) {
      this.formData.pageFormat = e.target.value;
    },
  },
};
</script>
