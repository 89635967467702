<template>
  <header id="data-product-details-header" class="buying-zone__header small-12 medium-6 large-12 cell">
    <div class="flex items-center">
      <product-label-group :product="product" class="mr-1" />
      <span v-if="product.marketingModelYear" qaid="product-marketing-model-year" class="text-md">
        {{ product.marketingModelYear }}
      </span>
      <span
        v-else-if="product.modelYearIntroAndCurrent && product.isArchived"
        qaid="product-model-year"
        class="text-md"
      >
        {{ product.modelYearIntroAndCurrent }}
      </span>
    </div>
    <h1 qaid="product-name" class="buying-zone__title">{{ product.name }}</h1>

    <product-reviews-summary :product-power-reviews-id="product.powerReviewsID" />

    <!-- CMS Consumer Message Slot -->
    <slot name="consumer-messages"></slot>
  </header>
</template>
<script>
import ProductReviewsSummary from '@/components/power-reviews/ProductReviewsSummary';
import ProductLabelGroup from '@/components/ProductLabelGroup';

export default {
  name: 'PdpProductHeader',
  components: {ProductLabelGroup, ProductReviewsSummary},
  props: {
    product: {
      type: Object,
      required: true,
    },
    variantCode: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      required: true,
    },
  },
};
</script>
