const GeolocationHelpers = (() => {
  const getBrowserPosition = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) return reject('geolocation unavailable');
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      );
    });
  };

  const isGeolocationEmpty = (geolocation) => {
    if (!geolocation?.latitude || !geolocation.longitude) return true;
    return geolocation?.latitude === 0 && geolocation?.longitude === 0;
  };

  return {
    getBrowserPosition,
    isGeolocationEmpty,
  };
})();

export default GeolocationHelpers;
