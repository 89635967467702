<template>
  <div class="grid-x grid-margin-x justify-between gap-y-4">
    <div class="cell small-auto large-shrink">
      <dl class="description-list inline-grid gap-x-16 text-base">
        <dt qaid="warranty-details-packing-slip-number">{{ $t('text.packingSlipNumber') }}</dt>
        <dd v-if="consignment.packingListURL">
          <pdl-link standalone qaid="warranty-details-packing-slip-download" :href="packingSlipUrl">
            {{ consignment.code }}
          </pdl-link>
        </dd>
        <dd v-else>{{ consignment.code }}</dd>

        <dt qaid="warranty-details-shipment-date">{{ $t('test.shipDate') }}</dt>
        <dd>{{ consignment.shipDate }}</dd>

        <dt qaid="warranty-details-bill-number">{{ $t('text.billTo') }}</dt>
        <dd>{{ warrantyDetails.retailerId }}</dd>

        <dt qaid="warranty-details-carrier-name">{{ $t('virtualPackingSlip.B2B.carrier') }}</dt>
        <dd>{{ consignment.tracking.shipmentCarrier }}</dd>

        <dt qaid="warranty-details-tracking-number">{{ $t('text.trackingNumber') }}</dt>
        <dd>
          <pdl-link
            standalone
            :href="`${consignment.tracking.shipmentTrackingUrl}`"
            target="_blank"
            qaid="warranty-details-shipment-tracking-url"
          >
            {{ consignment.tracking.shipmentTrackingNumber }}
          </pdl-link>
        </dd>

        <dt qaid="warranty-details-customer-PO">{{ $t('text.customerPo') }}</dt>
        <dd>{{ warrantyDetails.details.customerPo }}</dd>
      </dl>
    </div>

    <div class="cell small-auto large-shrink">
      <dl class="description-list inline-grid gap-x-16 text-base">
        <dt qaid="warranty-details-billing-address">{{ $t('text.billToAddress') }}</dt>
        <dd>
          <p>{{ warrantyDetails.billingAddress.billTo }}</p>
          <p>{{ warrantyDetails.billingAddress.line1 }}</p>
          <p>
            <span v-if="warrantyDetails.billingAddress.billToCity">
              {{ warrantyDetails.billingAddress.billToCity }},
            </span>
            <span>{{ warrantyDetails.billingAddress.billToStateOrProvince }}</span>
            <span class="ml-25">{{ warrantyDetails.billingAddress.billToPostalCode }}</span>
          </p>
        </dd>

        <dt qaid="warranty-details-shipping-address">{{ $t('text.shipToAddress') }}</dt>
        <dd>
          <p>{{ consignment.shippingAddress.companyName }}</p>
          <p>{{ consignment.shippingAddress.line1 }}</p>
          <p>{{ consignment.shippingAddress.line2 }}</p>
          <p>
            <span v-if="consignment.shippingAddress.town">{{ consignment.shippingAddress.town }}, </span>
            <span>{{ consignment.shippingAddress.region.isocodeShort }}</span>
            <span class="ml-25">{{ consignment.shippingAddress.postalCode }}</span>
          </p>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {PdlLink} from '@pedal/pdl-link';

export default {
  components: {PdlLink},
  props: {
    warrantyDetails: {
      type: Object,
      required: true,
    },
    consignment: {
      type: Object,
      required: true,
    },
  },

  computed: {
    packingSlipUrl() {
      return `${this.encodedContextPath}${this.consignment.packingListURL}`;
    },
    ...mapState('backend', ['encodedContextPath']),
  },
};
</script>

<style scoped>
.description-list {
  grid-template-columns: auto;
}

@screen md {
  .description-list {
    grid-template-columns: auto 1fr;
  }
}
</style>
