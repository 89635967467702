<template>
  <div class="plp-spelling-suggestion cell">
    <p class="searchSpellingSuggestionPrompt mb-2">
      {{ $t('search.spellingSuggestion.prompt') }}
      <a href="#" class="link-small" @click.prevent="applySuggestion">{{ suggestion }}</a>
      ?
    </p>
  </div>
</template>
<script>
import {mapActions} from 'vuex';
export default {
  name: 'PlpSpellingSuggestion',
  props: {
    suggestion: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('plp', {
      textSearch: 'textSearch',
    }),
    applySuggestion() {
      this.textSearch(this.suggestion);
    },
  },
};
</script>
