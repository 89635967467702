<template>
  <form-group
    v-mask
    :value="phone"
    name="phone"
    :data-mask="phoneFormat"
    :help-text="$t('text.address.contact.desc')"
    required
    :maxlength="phoneMaxLength"
    :label="$t('text.address.phone')"
    show-error-messages
    force-display-error
    :error="getErrorMessage('phone')"
    @input="handleInput"
    @blur="v$.$touch"
  />
</template>

<script>
import FormGroup from '@/components/FormGroup.vue';
import {mask} from '@/directives/masks';
import {getPhoneMaskPattern} from '@/utils/masks/phone-mask-patterns.js';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin.js';
import {mapMutations, mapState} from 'vuex';

export default {
  components: {FormGroup},
  directives: {mask},
  mixins: [TrekValidationMixin],
  props: {
    phoneMaxLength: {
      type: Number,
      default: () => null,
    },
  },
  validations() {
    return {
      phone: {
        required: this.trekValidators.requiredWithCustomMessage('address.phone.invalid'),
        maxLength: this.trekValidators.maxLengthWithCustomMessage('address.phone.invalid', this.phoneMaxLength),
        minLength: this.trekValidators.minLengthWithCustomMessage('address.phone.invalid', this.minLengthofLocale),
      },
    };
  },
  computed: {
    ...mapState('backend', ['locale']),
    ...mapState('checkout', ['billingAddress']),
    phone: {
      get() {
        return this.billingAddress.phone;
      },
      set(phone) {
        this.setPhone(phone);
      },
    },
    phoneFormat() {
      return getPhoneMaskPattern(this.locale);
    },
    fieldName() {
      return 'phone';
    },
    minLengthofLocale() {
      if (this.locale === 'jp') return this.phoneMaxLength - 1;
      else if (['de', 'at'].includes(this.locale)) return 1;
      else return this.phoneMaxLength;
    },
  },
  methods: {
    ...mapMutations('checkout', ['setPhone']),
    handleInput(value) {
      this.phone = value;
      this.v$.phone.$reset();
    },
  },
};
</script>
