<template>
  <section>
    <pdl-loading page :is-loading="isConfirmLoading" />
    <fieldset class="form-fieldset my-2">
      <legend class="form-fieldset__legend text-lg font-bold" qaid="feedback-product-details__type-label">
        {{ $t('text.productType') }}
      </legend>
      <label for="Bikes" class="radio">
        <input
          id="Bikes"
          v-model="selectedProductType"
          type="radio"
          value="Bikes"
          qaid="feedback-product-details__type-bike"
          checked
          @change="productTypeChanged"
        />
        <span class="control-indicator"></span>
        {{ $t('text.bikes') }}
      </label>
      <label for="parts-and-accessories" class="radio">
        <input
          id="parts-and-accessories"
          v-model="selectedProductType"
          type="radio"
          value="PartsAndAccessories"
          qaid="feedback-product-details__type-parts"
          @change="productTypeChanged"
        />
        <span class="control-indicator"></span>
        {{ $t('text.partsAccessories') }}
      </label>
    </fieldset>

    <hr class="rule my-4" />

    <div class="grid-x grid-margin-x">
      <div v-if="isPartsAndAccessories" key="inputName('sku')" class="cell large-7">
        <div class="form-group" :class="{'has-error': !!getErrorMessage(inputName('sku'))}">
          <label for="sku" class="form-label" qaid="feedback-product-details__sku-label">
            {{ $t('product.variants.sku') }}
          </label>
          <div class="form-group--inline is-required" :class="hasWarning">
            <input
              id="sku"
              v-model="claimLine.sku"
              type="text"
              :maxlength="25"
              class="form-control grow"
              qaid="feedback-product-details__sku"
              :name="inputName('sku')"
            />
            <trek-button
              primary
              form-inline
              class="md:ml-1"
              qaid="feedback-product-detail__lookup"
              @click="processSerialOrSku"
            >
              {{ $t('warrantyClaims.B2B.feedback.skuLookup') }}
            </trek-button>
          </div>
          <div
            v-if="emptyInput"
            key="sku_empty_warning"
            class="form-feedback block text-warning"
            :class="isVisible(emptyInput)"
          >
            {{ $t('warrantyClaims.B2B.enterSKU') }}
          </div>
          <div
            v-if="showSkuNotFound"
            key="sku_warning"
            class="form-feedback block text-warning"
            :class="isVisible(showSkuNotFound)"
          >
            {{ $t('warrantyClaims.B2B.feedback.tryAnother') }}
          </div>
        </div>

        <trek-button v-if="!showProductDetails" secondary qaid="unknown-sku-button" @click="processNoInput">
          {{ $t('warrantyClaims.B2B.feedback.skuUnknown') }}
        </trek-button>
      </div>

      <div v-if="isBike" :key="inputName('serialNumber')" class="cell large-7">
        <div class="form-group" :class="{'has-error': v$.serialNumber.$error}">
          <label for="serial-number" class="form-label" qaid="warranty-claim-line-details-serial-label">
            {{ $t('productRegistration.serialNumber') }}
            <span class="text-red ml-25">*</span>
          </label>

          <div class="form-group--inline is-required" :class="hasWarning">
            <input
              id="serial-number"
              :value="serialNumber"
              type="text"
              required
              class="form-control grow"
              qaid="feedback-product-details__serial"
              :name="inputName('serialNumber')"
              :maxlength="30"
              @input="setSerialNumber"
              @blur="updateWarningMessages"
            />

            <trek-button primary form-inline class="md:ml-1" @click="processSerialOrSku">
              {{ $t('warrantyClaims.B2B.feedback.serialLookup') }}
            </trek-button>
          </div>
          <div v-show="v$.serialNumber.$error" class="form-feedback is-invalid">
            {{ getErrorMessage('serialNumber') }}
          </div>
          <div
            v-if="showSerialNotFound"
            key="serialNumber_warning"
            class="form-feedback block text-warning"
            :class="isVisible(showSerialNotFound)"
          >
            {{ $t('warrantyClaims.B2B.feedback.tryAnother') }}
          </div>
        </div>
      </div>

      <div v-if="showProductDetails" class="cell large-5">
        <form-group
          v-model="claimLine.modelName"
          type="text"
          qaid="feedback-product-details__model"
          :name="inputName('model')"
          :label="$t('text.model')"
        />
        <div class="grid-x grid-margin-x">
          <form-group
            v-if="isBike"
            v-model="claimLine.sku"
            class="cell large-5"
            type="text"
            :maxlength="25"
            qaid="feedback-product-details__sku"
            :name="inputName('sku')"
            :label="$t('product.variants.sku')"
          />
          <form-group
            v-if="isBike"
            v-model="claimLine.modelYear"
            class="cell large-7"
            type="select"
            qaid="feedback-product-details__year"
            :name="inputName('modelYear')"
            :multi-items="modelYearList"
            :label="$t('contactUs.year')"
          />
        </div>
        <div class="grid-x grid-margin-x">
          <form-group
            v-model="claimLine.color"
            class="cell large-7"
            type="text"
            qaid="feedback-product-details__color"
            :name="inputName('color')"
            :label="$t('product.variants.colour')"
          />

          <form-group
            v-model="claimLine.size"
            class="cell large-5"
            type="text"
            qaid="feedback-product-details__size"
            :name="inputName('size')"
            :label="$t('product.variants.size')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {mapMutations, mapState} from 'vuex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import TrekButton from '@/components/TrekButton';
import FormGroup from '@/components/FormGroup';
import {PdlLoading} from '@pedal/pdl-loading';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  components: {TrekButton, FormGroup, PdlLoading},
  mixins: [TrekValidationMixin],

  data() {
    return {
      isConfirmLoading: false,
      showSerialNotFound: false,
      showSkuNotFound: false,
      showProductDetails: false,
      validator: null,
      serialValidated: false,
      selectedProductType: 'Bikes',
      emptyInput: false,
      serialNumber: '',
    };
  },

  computed: {
    ...mapState('productFeedback', ['validators', 'feedbackData', 'isSerialNumberPristine']),
    modelYearList() {
      const modelYearList = [];
      if (this.claimLine && this.claimLine.modelYearList) {
        modelYearList.push({value: null, displayName: null});
        this.claimLine.modelYearList.map((year) => {
          modelYearList.push({value: year, displayName: year});
        });
      }
      return modelYearList;
    },
    hasWarning() {
      return this.emptyInput || this.showSerialNotFound || this.showSkuNotFound ? 'has-warning' : '';
    },
    isPartsAndAccessories() {
      return this.claimLine && this.claimLine.productType === 'PartsAndAccessories';
    },
    isBike() {
      return this.claimLine && this.claimLine.productType === 'Bikes';
    },
    claimLine() {
      return get(this.feedbackData, 'claimLines[0]', {});
    },
    isEmptySku() {
      return this.isPartsAndAccessories && this.claimLine.sku === '';
    },
    isemptySerial() {
      return this.isBike && this.claimLine.serialNumber === '';
    },
  },

  validations() {
    const validations = {
      serialNumber: {
        requiredIfPristine: this.trekValidators.requiredUnless(
          'productRegistration.serialNumber',
          this.isSerialNumberPristine
        ),
      },
    };
    return validations;
  },

  mounted() {
    if (
      this.claimLine &&
      ((this.isBike && this.claimLine.serialNumber) || (this.isPartsAndAccessories && this.claimLine.sku))
    ) {
      this.serialValidated = true;
    }
  },
  methods: {
    ...mapMutations('productFeedback', ['setFeedbackData', 'updateFeedbackProperty', 'updateFlag']),
    isVisible(flag) {
      return flag ? 'block' : '';
    },
    inputName(propertyName) {
      return `cl_${this.claimLine.productType}_${propertyName}`;
    },
    updateWarningMessages() {
      this.updateFlag({flagName: 'isSerialNumberPristine', value: false});
      this.v$.$validate().then(() => {
        if (this.v$.serialNumber.$error) this.updateFlag({flagName: 'showErrorCallout', value: true});
        this.clearWarningMessages();
      });
    },
    setSerialNumber($event) {
      this.serialNumber = $event.target.value;
      this.updateFeedbackProperty({
        propName: 'serialNumber',
        value: this.serialNumber,
      });
    },
    productTypeChanged() {
      this.updateFlag({flagName: 'isSerialNumberPristine', value: true});
      this.showSerialNotFound = false;
      this.showSkuNotFound = false;
      this.emptyInput = false;
      this.claimLine.sku = '';
      this.updateFlag({flagName: 'showFeedbackDetails', value: false});
      this.updateFeedbackProperty({
        propName: 'productType',
        value: this.selectedProductType,
      });
      this.v$.$reset();
      this.showProductDetails = false;
    },
    clearWarningMessages() {
      this.showSerialNotFound = false;
      this.showSkuNotFound = false;
    },
    clearFormInputs() {
      this.claimLine.modelName = '';
      if (this.isBike) {
        this.claimLine.modelYear = '';
      }
      this.claimLine.size = '';
      this.claimLine.color = '';
      this.serialValidated = false;
      this.showSkuNotFound = false;
      this.$forceUpdate();
    },
    processNoInput() {
      // Clear old fields
      this.clearFormInputs();
      this.showProductDetails = true;
      this.updateFlag({flagName: 'showFeedbackDetails', value: true});
    },
    processSerialOrSku() {
      // need to run validation for serial lookup
      if (this.isBike) {
        this.updateWarningMessages();
      }
      this.v$.$validate().then(() => {
        this.clearFormInputs();
        if (this.isEmptySku) {
          this.emptyInput = true;
          return;
        }
        this.processSerialOrSkuCall();
      });
    },
    processSerialOrSkuCall() {
      if ((this.isBike && this.claimLine.serialNumber) || (this.isPartsAndAccessories && this.claimLine.sku)) {
        let urlType = this.isBike ? 'serial' : 'sku';
        let param = this.isBike ? this.claimLine.serialNumber : this.claimLine.sku;
        this.isConfirmLoading = true;
        storefrontInstance
          .get(`/claims/${urlType}/${param}/`)
          .then((response) => {
            this.handleResponse(response.data.data, response.data.meta);
          })
          .finally(() => {
            this.isConfirmLoading = false;
          });
        if (this.isBike) {
          this.claimLine.sku = '';
        }
      }
    },
    handleResponse(response, meta) {
      if (!isEmpty(response)) {
        this.showProductDetails = true;
        this.updateFeedbackProperty({propName: 'modelName', value: response.modelName});
        if (this.isBike) {
          this.updateFeedbackProperty({propName: 'sku', value: response.sku});
          this.updateFeedbackProperty({propName: 'modelYear', value: response.modelYear});
          this.updateFeedbackProperty({propName: 'modelYearList', value: response.modelYearList});
        }
        this.updateFeedbackProperty({propName: 'color', value: response.color});
        this.updateFeedbackProperty({propName: 'size', value: response.size});
        this.updateFlag({flagName: 'showFeedbackDetails', value: true});
        this.serialValidated = true;
        this.emptyInput = false;
        this.showSkuNotFound = false;
        // Force update since we're modifying deeper attributes than what Vue is watching for
        this.$forceUpdate();
      }
      if (!isEmpty(meta.feedback.message)) {
        if (meta.feedback.message.basePropertyKey === 'warrantyClaims.B2B.unableLocateSerial') {
          this.showSerialNotFound = true;

          this.serialValidated = false;
          this.showSkuNotFound = false;
        } else if (meta.feedback.message.basePropertyKey === 'warrantyClaims.B2B.unableLocateSku') {
          this.showSerialNotFound = false;
          this.showSkuNotFound = true;
        }
      }
    },
  },
};
</script>
