const trackLocatorStore = {
  methods: {
    trackStoreClick(data) {
      const {name} = data;

      const storeClickData = {
        event: 'storeFinderCard',
        storeCardName: name,
      };

      ACC.track.trackEvent(storeClickData);
    },
  },
};

export default trackLocatorStore;
