<template>
  <div v-if="options" class="md:flex md:justify-between items-center">
    <div class="flex justify-center">
      <div v-if="isTotalCountVisible">
        <div class="search-results__result-count text-base font-bold" qaid="resultsCountText">
          {{ $t('search.page.totalResults', [options.totalCount]) }}
        </div>
        <plp-spelling-suggestion
          v-if="options.searchSpellingSuggestion"
          :suggestion="options.searchSpellingSuggestion"
        />
      </div>
    </div>
    <div class="flex items-center justify-center">
      <plp-bulk-expand-button
        v-if="currentViewMode === 'listview' && isExpandAllVisible"
        class="flex justify-center"
        :is-expanded="isAllProductsExpanded"
        @click="toggleProductsState"
      />
      <toggle-button-group
        v-model="currentViewMode"
        :buttons="viewModes"
        class="sm:hidden md:hidden lg:flex ml-3"
        icon-path="icon"
      >
      </toggle-button-group>
      <view-option-select-box
        v-show="canChangePageSize"
        id="pagesPerView"
        class="md:ml-3"
        :label="$t('text.productListing.Items.per.page')"
        :value="viewOptions.pageSize"
        :options="pageSizeOptions"
        @updated="updatePageSize"
      />
      <view-option-select-box
        v-if="sortOptions"
        id="sortOptions"
        class="ml-3"
        :label="$t('search.page.sortTitle')"
        :value="viewOptions.sortCode"
        :options="sortOptions"
        @updated="updateSortCode"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import ToggleButtonGroup from '@/components/ToggleButtonGroup';
import PlpSpellingSuggestion from './PlpViewOptions/PlpSpellingSuggestion';
import ViewOptionSelectBox from '@/components/containers/plp/PlpViewOptions/ViewOptionSelectBox';
import PlpBulkExpandButton from '@/components/containers/plp/PlpViewOptions/PlpBulkExpandButton';

export default {
  name: 'PlpViewOptions',

  components: {PlpBulkExpandButton, PlpSpellingSuggestion, ToggleButtonGroup, ViewOptionSelectBox},

  props: {
    pageSizeOptions: {
      type: Array,
      required: true,
    },
    isTotalCountVisible: {
      type: Boolean,
      default: true,
    },
    isExpandAllVisible: {
      type: Boolean,
      default: true,
    },
    canChangePageSize: {
      type: Boolean,
      default: true,
    },
    sortOptions: {
      type: Array,
      validator(prop) {
        return typeof prop === 'object' || prop === null;
      },
      default: () => [],
    },
    options: {
      required: true,
      validator(prop) {
        return typeof prop === 'object' || prop === null;
      },
    },
    viewOptions: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sortCode: '',
      pageSize: '',
      currentViewMode: '',
      viewModes: [
        {id: 'grid', icon: 'apps'},
        {id: 'listview', icon: 'reorder'},
      ],
    };
  },

  computed: {
    ...mapGetters('plp', ['isAllProductsExpanded']),
    ...mapState('plp', ['products', 'hasAccessToPlpListView']),
  },

  watch: {
    viewOptions: {
      deep: true,
      handler(value) {
        this.updateViewOptions(value);
      },
    },
    currentViewMode(value, prevValue) {
      this.$emit('view-mode-changed', value);
      //do not clear collapse during initialization
      if (!prevValue) return;
      this.clearCollapseState();
    },
  },

  mounted() {
    this.updateViewOptions(this.viewOptions);
    if (this.hasAccessToPlpListView) {
      this.viewModes = [];
    }
  },

  methods: {
    ...mapActions('plp', ['updateViewOption', 'updateCollapseState', 'clearCollapseState']),

    toggleProductsState() {
      const state = !this.isAllProductsExpanded;
      this.products.forEach((product) => {
        this.updateCollapseState({
          code: product.code,
          state,
        });
      });
    },
    updateSortCode(value) {
      if (value !== this.sortCode) {
        this.updateViewOption({sortCode: value, currentPage: 0});
      }
    },
    updatePageSize(value) {
      if (value !== this.pageSize) {
        this.updateViewOption({pageSize: value, currentPage: 0});
      }
    },
    updateViewOptions(value) {
      this.currentViewMode = value.viewMode;
      this.sortCode = value.sortCode;
      this.pageSize = value.pageSize;
    },
  },
};
</script>
