<template>
  <pdl-dialog
    :visible="showDialog"
    :z-index="16000011"
    qaid="bundle-dialog"
    custom-class="bundle-dialog"
    :append-to-body="true"
    :width="viewBreakpointMoreThan('small') ? '75%' : '100%'"
    :top="viewBreakpointMoreThan('small') ? '15vh' : '60px'"
    :close-dialog-tool-tip="$t('popup.close')"
    @close="onCancel"
  >
    <template v-if="!isBundleDataEmpty" #title>
      <pdl-section-header size="lg">
        <template slot="content">
          <pdl-heading :level="1" qaid="bundle-dialog-heading">{{ $t('product.modal.bundle.heading') }}</pdl-heading>
          <pdl-subheading qaid="bundle-dialog-subHeading">{{ $t('product.modal.bundle.subHeading') }}</pdl-subheading>
        </template>
      </pdl-section-header>
    </template>

    <pdl-loading :is-loading="dataIsLoading">
      <div v-for="(bundleMainVariant, index) of bundleMainProducts" :key="index">
        <bundling-primary-product
          v-if="!dataIsLoading"
          :show-dialog="showDialog"
          :bundle-main-product-data="bundleMainVariant"
          @update-variant-qty="updateVariantQty"
        />
      </div>

      <pdl-heading
        v-if="!isBundleDataEmpty && !dataIsLoading"
        :level="2"
        size="md"
        class="mt-4"
        qaid="bundle-dialog-additional-parts-heading"
      >
        {{ $t('product.modal.bundle.additionalParts') }}
      </pdl-heading>

      <div v-if="!isBundleDataEmpty && !dataIsLoading" class="b2b-grid-group">
        <bundling-child-product
          v-for="product in getChildProductList"
          :key="product.code"
          :child-product="product"
          class="b2b-grid-group__section"
        />
      </div>
    </pdl-loading>

    <template #footer>
      <div class="buttons buttons--right mt-5">
        <!-- Add to cart button -->
        <pdl-button
          qaid="bundle-dialog-cart-button"
          :disabled="dataIsLoading"
          primary
          icon="shopping_cart"
          type="submit"
          @click="onAddToCart"
        >
          <span v-html="addToCartButtonCaption" />
        </pdl-button>

        <!-- Cancel Button -->
        <pdl-button qaid="bundle-dialog-cancel-button" secondary @click="$emit('cancel')">
          {{ $t('text.button.cancel') }}
        </pdl-button>
      </div>
    </template>
  </pdl-dialog>
</template>

<script>
import get from 'lodash/get';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlDialog} from '@pedal/pdl-dialog';
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';
import BundlingPrimaryProduct from '@/components/containers/pdp/BundlingPrimaryProduct';
import BundlingChildProduct from '@/components/containers/pdp/BundlingChildProduct';
import {PdlSectionHeader, PdlHeading, PdlSubheading} from '@pedal/pdl-section-header';
import {PdlButton} from '@pedal/pdl-button';
import removeDuplicateProducts from '@/modules/ProductBundle';
import {PdlToastType} from '@/constants/pdl-toast-type';

export default {
  components: {
    PdlLoading,
    BundlingPrimaryProduct,
    BundlingChildProduct,
    PdlSectionHeader,
    PdlHeading,
    PdlSubheading,
    PdlButton,
    PdlDialog,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    addToCartButtonCaption: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dataIsLoading: false,
    };
  },

  computed: {
    // Get all the child options from all the active bundle templates.
    getChildProductList() {
      let bundles = [];

      this.activeIds.forEach((id) => {
        const bundleItem = get(this.bundleData, [id, 'bundles'], []);

        bundles = bundles.concat(bundleItem);
      });

      // Passing all the bundle product to get unique child products(Remove duplicates).
      return removeDuplicateProducts(bundles);
    },

    ...mapGetters('productBundle', ['isBundleDataEmpty']),
    ...mapState('productBundle', ['bundleData', 'bundleTemplateIds', 'activeIds', 'bundleMainProducts']),
    ...mapState('productBundle', ['isVariantsChanged']),
    ...mapState('skuGrid', ['isBundleModal', 'skuEntriesWithBundle', 'skuNotifyErrorToast']),
    ...mapState('viewSize', ['viewBreakpoint']),
    ...mapGetters('viewSize', ['viewBreakpointMoreThan']),
  },

  watch: {
    showDialog(isVisible) {
      // call API when modal is opened and bundleData is empty.
      if (isVisible && this.isVariantsChanged) {
        this.getBundleResponse();
      }
    },
    skuNotifyErrorToast(newValue) {
      if (newValue) {
        this.$notify({
          type: PdlToastType.ERROR,
          showClose: true,
          duration: 0,
          message: newValue,
        });
        this.setSkuNotifyErrorToast('');
      }
    },
  },

  methods: {
    getBundleResponse() {
      this.dataIsLoading = true;
      this.fetchBundleProducts()
        .then((response) => {
          if (!response) throw new Error();
          this.dataIsLoading = false;
        })
        .catch(() => {
          this.$emit('error');
        });
    },

    onAddToCart() {
      this.addBundleSkuToCart(this.$t('errorMessage.unableToProcess'));
      this.$emit('add-to-cart');
    },

    onCancel() {
      if (!this.isBundleModal) {
        this.$notify({
          type: PdlToastType.ERROR,
          message: this.$t('product.modal.bundle.cancelMessage'),
          showClose: true,
          duration: 0,
        });
        //   Set the entries to skuEntriesWithBundle again in case user close dialogs without adding anything
        this.setEntries(this.skuEntriesWithBundle);
        this.$emit('cancel');
      }
      this.clearRegisterInputs();
    },

    updateVariantQty(payload) {
      this.isBundleInput(true);
      this.updateVariantQuantity({
        qty: payload.qty,
        sku: payload.sku,
        input: payload.input,
        productType: 'bundleProducts',
        callback: () => '',
      });
    },
    ...mapMutations('savedLists', ['setEntries']),
    ...mapActions('skuGrid', ['clearRegisterInputs', 'addBundleSkuToCart']),
    ...mapMutations('skuGrid', ['updateVariantQuantity', 'isBundleInput', 'setSkuNotifyErrorToast']),
    ...mapActions('productBundle', ['fetchBundleProducts']),
  },
};
</script>
