<template>
  <pdl-icon :size="size">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentcolor" viewBox="0 0 24 24">
      <path
        d="M21.147 17.792h-.736v-8.81l.39.173c.11.045.228.073.337.073a.859.859 0 0 0 .345-1.646l-2.336-1V5c0-.473-.436-.918-.918-.918h-2.173c-.428 0-.819.373-.891.79L13.137 4 4.81 7.573A.865.865 0 0 0 4.355 8.7a.862.862 0 0 0 1.127.455l.4-.173v3.1H5.21c-.472 0-.918.437-.918.919v4.8H2.855a.854.854 0 1 0 0 1.71h18.283a.854.854 0 1 0 0-1.71l.01-.009Zm-8.855 0H6.009v-4h2v1.427h2.283v-1.427h2v4Zm.8-5.719h-.655V9.928h3.719v3.718H14.01v-.654c0-.473-.436-.919-.918-.919Zm5.61 5.72H14.01V14.51h2.519c.236 0 .481-.245.481-.482V9.573c0-.236-.245-.482-.482-.482h-4.454c-.237 0-.482.246-.482.482v2.519H7.61V8.264l5.555-2.382 5.555 2.382v9.546l-.019-.018Z"
      />
    </svg>
  </pdl-icon>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {PdlIcon},
  props: {
    size: {
      type: String,
      default: '',
    },
  },
};
</script>
