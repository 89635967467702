<template>
  <div qaid="cart-pricing">
    <div v-if="isPromotionApplied" qaid="cart-promotion-price" class="font-bold text-md">
      <p class="line-through text-gray-60" :qaid="originalPriceQaid">{{ formattedPrice }}</p>
      <p class="text-red" :qaid="unitPriceQaid">
        {{ withEach(entry.formattedAdjustedUnitPrice) }}
      </p>
      <p v-if="entry.quantity > 1" :qaid="totalPriceQaid">
        {{ formattedTotalPrice }}
      </p>
    </div>
    <div v-else-if="isSalePriceVisible" qaid="cart-sale-price" class="font-bold text-md">
      <p v-if="isSalePriceVisible" class="line-through text-gray-60" :qaid="originalPriceQaid">
        {{ entry.product.price.formattedCalculableAdvertised }}
      </p>
      <p class="text-red" :qaid="unitPriceQaid">
        {{ withEach(formattedPrice) }}
      </p>
      <p v-if="entry.quantity > 1" :qaid="totalPriceQaid">
        {{ formattedTotalPrice }}
      </p>
    </div>
    <div v-else qaid="cart-product-price" class="font-bold text-md">
      <p v-if="entry.quantity > 1" class="text-gray-60" :qaid="unitPriceQaid">
        {{ withEach(formattedPrice) }}
      </p>
      <p class="font-bold text-md" :qaid="totalPriceQaid">
        {{ formattedTotalPrice }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    entry: {
      type: Object,
      required: true,
    },
    isPromotionApplied: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    formattedPrice() {
      return this.formatPrice(this.entry.basePrice);
    },
    formattedTotalPrice() {
      return this.formatPrice(this.entry.totalPrice);
    },
    isSalePriceVisible() {
      return Boolean(this.entry.product.price.formattedCalculableAdvertised);
    },
    unitPriceQaid() {
      return 'product-unit-price';
    },
    totalPriceQaid() {
      return 'product-total-price';
    },
    originalPriceQaid() {
      return 'product-original-price';
    },
  },
  methods: {
    withEach(price) {
      if (this.entry.quantity <= 1) return price;
      return this.$t('cart.price.each', [price]);
    },
    formatPrice(priceObj) {
      if (priceObj.value > 0) return priceObj.formattedValue;
      return this.$t('text.free');
    },
  },
};
</script>
