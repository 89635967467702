<template>
  <div :class="{'siteMenu__button--active': hasUnread}" class="siteMenu__button">
    <a
      v-tippy="{
        html: '#notifications-dropdown',
        theme: 'square',
        interactive: true,
        reactive: true,
        trigger: 'click',
        placement: 'bottom-end',
        flip: false,
        sticky: true,
        popperOptions: {
          positionFixed: true,
          modifiers: {
            keepTogether: true,
            preventOverflow: {
              enabled: false,
            },
            hide: {
              enabled: false,
            },
          },
        },
      }"
      href="#"
      aria-label="notifications"
    >
      <pdl-icon name="notifications" :label="$t('text.notifications')" />
    </a>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {
    PdlIcon,
  },
  computed: {
    hasUnread() {
      return this.notifications !== null
        ? this.notifications.filter((notification) => !notification.readStatus).length > 0
        : false;
    },
    ...mapGetters('notifications', ['notifications']),
  },
};
</script>
