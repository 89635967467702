<template>
  <div id="lcly-button-0">
    <a id="lcly-link-0" href="https://brands.locally.com" target="_blank">Powered by Locally</a>
  </div>
</template>

<script>
import {Url} from '@/utils/url';
import {scrollToElement} from '@/utils/scroll-to';

const LOCALLY_DEFAULT_LIMIT = 6;

export default {
  props: {
    options: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    locallyConfig() {
      return {
        store: this.options.locally.store,
        uri: 'search',
        sort: 'price-high-to-low',
        limit: LOCALLY_DEFAULT_LIMIT,
      };
    },
    locallyQuery() {
      return Url.objectToQueryStringArray(this.locallyConfig).join('&');
    },
  },
  mounted() {
    this.appendLocallyScript();
    this.scrollToComponent();
    window.addEventListener(
      'hashchange',
      () => {
        const urlFragment = window.location.hash;
        if (urlFragment.includes('reload=true')) {
          location.reload();
        }
      },
      false
    );
  },

  methods: {
    appendLocallyScript() {
      const script = document.createElement('script');
      script.src = `${this.options.locally.baseUrl}/widgets/search.js?${this.locallyQuery}`;
      document.head.appendChild(script);
    },

    scrollToComponent() {
      const locallyWidgetElement = document.getElementById('locallyWidgetComponent');
      const urlFragment = window.location.hash;
      // list of fragment keys where page scrolls
      const urlFragmentKeys = ['#/search/', '#/product/'];

      //conditions to test for scrolling
      const returnConditions = [
        !urlFragment,
        urlFragmentKeys.every((key) => !urlFragment.startsWith(key)),
        !locallyWidgetElement,
      ];

      //will return whether we need to scroll to ELP section or not
      const willNotScroll = (returnCondition) => returnCondition === true;

      //return if doesn't match any of the scroll conditions
      if (returnConditions.some(willNotScroll)) {
        return;
      }

      window.addEventListener('load', () => {
        scrollToElement(locallyWidgetElement);
      });
    },
  },
};
</script>
