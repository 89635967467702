<template>
  <div class="mt-5 md:flex flex-row-reverse">
    <pdl-callout class="md:w-1/2 lg:w-1/3">
      <template #content>
        <p qaid="archived-heading" class="mb-1">{{ $t('product.variants.archive.exists') }}</p>

        <pdl-link qaid="archived-product-link" standalone :href="archivedProductLink">
          {{ $t('product.variants.archive.link') }}
        </pdl-link>
      </template>
    </pdl-callout>
  </div>
</template>

<script>
import {PdlCallout} from '@pedal/pdl-callout';
import {mapState} from 'vuex';
import {PdlLink} from '@pedal/pdl-link';

export default {
  name: 'PdpArchivedProductLink',
  components: {PdlCallout, PdlLink},

  computed: {
    ...mapState('pdp', ['pdpBaseProductCode']),

    archivedProductLink() {
      const initialUrl = window.location.pathname.split('/p/')[0];
      return `${initialUrl}/a/${this.pdpBaseProductCode}`;
    },
  },
};
</script>
