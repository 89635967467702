<script>
import {useIsTranslationLoaded} from '@/composables/is-translation-loaded.js';
import {PdlToastType} from '@/constants/pdl-toast-type';

export default {
  props: {
    accConfMsgs: {
      type: Array,
      default: () => [],
    },
    accInfoMsgs: {
      type: Array,
      default: () => [],
    },
    accErrorMsgs: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const displayGlobalMessageToasts = (messages, toastType) => {
      messages?.forEach((message) => {
        const {attributes, code} = message;
        const payload = {
          type: toastType,
          message: window.VueI18n.t(code, attributes),
          showClose: true,
          dangerouslyUseHTMLString: true,
        };
        if (toastType === PdlToastType.ERROR) {
          payload.duration = 0;
        }
        window.vm.$notify(payload);
      });
    };

    useIsTranslationLoaded(() => {
      displayGlobalMessageToasts(props.accConfMsgs, PdlToastType.SUCCESS);
      displayGlobalMessageToasts(props.accInfoMsgs, PdlToastType.INFO);
      displayGlobalMessageToasts(props.accErrorMsgs, PdlToastType.ERROR);
    });
  },
  render: () => null,
};
</script>
