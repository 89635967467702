<template>
  <div v-if="!!(data.value instanceof Array)">
    <div v-for="(text, key) in data.value" :key="key" :qaid="`grid-text-${key}`">
      {{ text }}
    </div>
  </div>
  <div v-else>
    <div :id="`tooltipTemplate-${data.value}`" v-tippy-html class="hidden">
      <div v-if="data.tooltip" class="max-w-md w-full text-left">
        <div v-if="data.tooltip.MFR && data.tooltip.MFR != 'NULL'" class="text-xs">
          <span class="font-bold">MFR: </span>{{ data.tooltip.MFR }}
        </div>
        <div v-if="data.tooltip.replacementProductUrl" class="sm:hidden md:hidden lg:flex">
          <trek-link xsmall qaid="grid-replacement-product-link" underline button text :href="replacementProductUrl">
            {{ data.tooltip.replacementProductText }}
          </trek-link>
        </div>
      </div>
    </div>

    <div class="flex" :class="{'flex-col justify-center': !showTooltip}">
      <!-- PLP and on other grids we need link on SKu to navigate to PDP-->
      <div v-if="data.link">
        <a :href="data.link" class="underline hover:no-underline" qaid="grid-text-link" v-html="data.value" />
      </div>
      <!-- If there is no link and other columns use this -->
      <div v-else qaid="grid-text" v-html="data.value" />

      <!-- Handle the tooltip for UPC and MFR with both  -->
      <div
        v-if="data.tooltip && showTooltip"
        v-tippy="{html: `#tooltipTemplate-${data.value}`, placement: 'top-start', arrow: true, interactive: true}"
      >
        <pdl-icon class="b2b-grid__text__tooltip-icon text-base align-text-bottom no-underline" name="info" size="18" />
      </div>
    </div>

    <div v-if="(!data.tooltip || !showTooltip) && data.type === 'date'" class="flex items-center lg:justify-end">
      <pdl-icon v-show="data.warn === true" class="text-warning mr-25" size="18" name="warning" />
      {{ formatDate(data.value) }}
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import TrekLink from '@/components/TrekLink';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'GridText',
  components: {TrekLink, PdlIcon},
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    showTooltip() {
      return !!(this.data?.tooltip && Object.keys(this.data?.tooltip).length > 0);
    },
    replacementProductUrl() {
      return this.encodedContextPath + this.data.tooltip.replacementProductUrl;
    },
    ...mapState('backend', ['encodedContextPath']),
  },
  methods: {
    formatDate: function (rawDate) {
      var date = new Date(rawDate);

      var year = date.getFullYear();

      var month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;

      var day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;

      return month + '/' + day + '/' + year;
    },
  },
};
</script>
