<template>
  <div class="pdp-no-product-data text-center pt-4 pb-4">
    <a href="#" @click.prevent="tryAgain">{{ $t('errorMessage.unableToProcess') }}</a>
  </div>
</template>
<script>
export default {
  name: 'PdpNoProductData',
  methods: {
    tryAgain() {
      this.$emit('try-again');
    },
  },
};
</script>
