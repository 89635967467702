<template>
  <div>
    <pdl-loading :top="loading" :page="downloading" :is-loading="loading || downloading">
      <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="1">{{ $t('text.sellSheet') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <p class="md:w-1/2">{{ $t('sellSheets.B2B.selectFeatures') }}</p>

      <pdl-section-header size="md" :is-dividing="true" class="mt-4">
        <template slot="content">
          <pdl-heading :level="3">{{ $t('text.header') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <label class="checkbox font-medium">
        <input
          id="header-checkbox-1"
          v-model="formData.showHeader"
          type="checkbox"
          name="showHeader"
          qaid="sellSheetIncludeHeader"
        />
        <span class="control-indicator"></span>
        {{ $t('sellSheets.B2B.includeHeader') }}
      </label>

      <!-- Header Options -->
      <fieldset v-if="formData.showHeader" class="form-fieldset lg:ml-5 mt-3">
        <label class="checkbox">
          <input
            id="logo-checkbox-2"
            v-model="formData.showLogo"
            name="showLogo"
            type="checkbox"
            qaid="sellSheetIncludeLogo"
            checked
            @change="setLogoOption"
          />
          <span class="control-indicator"></span>
          {{ $t('sellSheets.B2B.includeLogo') }}
        </label>

        <div v-if="formData.showLogo" class="lg:ml-5 mt-3">
          <label for="logo-selection-option-1" class="radio">
            <input
              id="logo-selection-option-1"
              v-model="formData.logo"
              name="logo"
              type="radio"
              value="logo-trek"
              qaid="sellSheetIncludeTrekLogo"
              required
            />
            <span class="control-indicator"></span>
            {{ $t('sellSheets.B2B.logo.trek') }}
          </label>

          <label for="logo-selection-option-2" class="radio">
            <input
              id="logo-selection-option-2"
              v-model="formData.logo"
              name="logo"
              type="radio"
              value="logo-brand"
              qaid="sellSheetIncludeElectraLogo"
              required
            />
            <span class="control-indicator"></span>
            {{ $t('sellSheets.B2B.logo.electra') }}
          </label>

          <label for="logo-selection-option-3" class="radio">
            <input
              id="logo-selection-option-3"
              v-model="formData.logo"
              type="radio"
              name="logo"
              value="logo-store"
              qaid="sellSheetIncludeStoreLogo"
              required
              checked
            />
            <span class="control-indicator"></span>
            {{ $t('sellSheets.B2B.logo.store') }}
          </label>
        </div>

        <!-- POS Data -->

        <div v-if="hasPosData" class="form-fieldset mt-4 lg:max-w-sm text-base">
          <label class="checkbox">
            <input
              id="storeName-checkbox-3"
              v-model="formData.showStoreName"
              type="checkbox"
              name="showStoreName"
              value="true"
              qaid="sellSheetIncludeStoreName"
              checked
            />
            <span class="control-indicator"></span>
            {{ $t('text.storeName') }}
          </label>

          <div class="ml-5" v-html="displayName"></div>

          <!-- POS Address -->
          <div v-if="address">
            <label class="checkbox mt-4">
              <input
                id="storeAddress-checkbox-4"
                v-model="formData.showStoreAddress"
                type="checkbox"
                name="showStoreAddress"
                value="true"
                qaid="sellSheetIncludeStoreAddress"
                checked
              />
              <span class="control-indicator"></span>
              {{ $t('storeDetails.store.address') }}
            </label>
            <div class="ml-5">
              {{ address.line1 }}
              <br />
              <span v-if="address.line2">
                <span>{{ address.line2 }}</span>
                <br />
              </span>
              {{ address.regionName }},&nbsp;{{ address.town }}
              <br />
              {{ address.postalCode }}&nbsp;{{ address.displayCountry }}
              <br />
            </div>

            <label class="checkbox mt-4">
              <input
                id="email-checkbox-5"
                v-model="formData.showEmail"
                type="checkbox"
                name="showEmail"
                value="true"
                qaid="sellSheetIncludeEmail"
                checked
              />
              <span class="control-indicator"></span>
              {{ $t('forgottenPwd.email') }}
            </label>
            <div class="ml-5">{{ address.email }}</div>

            <label class="checkbox mt-4">
              <input
                id="websiteUrl-checkbox-6"
                v-model="formData.showWebsiteUrl"
                type="checkbox"
                name="showWebsiteUrl"
                value="true"
                qaid="sellSheetIncludeWebsite"
                checked
              />
              <span class="control-indicator"></span>
              {{ $t('text.websiteURL') }}
            </label>
            <div class="ml-5">{{ address.url }}</div>
          </div>

          <!-- Store hours -->
          <div v-if="openingHours">
            <label class="checkbox mt-4">
              <input
                id="storeHours-checkbox-7"
                v-model="formData.showStoreHours"
                type="checkbox"
                name="showStoreHours"
                value="true"
                qaid="sellSheetIncludeStoreHours"
                checked
              />
              <span class="control-indicator"></span>
              {{ $t('storeFinder.store.hours') }}
            </label>

            <ol class="lg:ml-5 text-base">
              <li v-for="schedule in openingHours" :key="schedule.weekDayLong" class="flex justify-between">
                <span>{{ schedule.weekDayLong }}</span>
                <span>{{ schedule.storeOpeningTime }} - {{ schedule.storeClosingTime }}</span>
              </li>
            </ol>
          </div>
        </div>

        <div class="form-fieldset lg:max-w-sm mt-3">
          <label class="checkbox">
            <input
              id="additionalContactInfo-checkbox-8"
              v-model="formData.showAdditionalContactInfo"
              type="checkbox"
              name="showAdditionalContactInfo"
              value="true"
              qaid="sellSheetIncludeAdditionalContactInfo"
            />
            <span class="control-indicator"></span>
            {{ $t('sellSheets.B2B.additionalContactInfo') }}
          </label>
          <form-group
            id="showAdditionalContactInfo-textarea-1"
            v-model="formData.additionalContactInformation"
            class="lg:ml-5"
            :label="$t('sellSheets.B2B.additionalContactInfo')"
            name="additionalContactInformation"
            type="textarea"
            qaid="sellSheetIncludeAdditionalContactInfoInput"
            :disabled="!formData.showAdditionalContactInfo"
            show-error-messages
            force-display-error
            :error="getErrorMessage('formData.additionalContactInformation')"
            :rows="5"
            :show-label="false"
          />
        </div>
      </fieldset>

      <pdl-section-header size="md" :is-dividing="true" class="mt-4">
        <template slot="content">
          <pdl-heading :level="3">{{ $t('text.productInformation') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <div class="lg:max-w-sm">
        <div class="is-required" :class="{'has-error': !!getErrorMessage('formData.color')}">
          <label for="color" class="form-label">{{ $t('product.variants.colour') }}</label>

          <div class="select">
            <select
              id="color"
              ref="color"
              v-model="formData.color"
              name="color"
              class="form-control"
              qaid="sellSheetProductColor"
              @change="resetSize"
            >
              <option v-for="variant in productVariants" :key="variant.id" :value="variant.id">
                {{ variant.name }}
              </option>
            </select>
          </div>
          <div v-show="getErrorMessage('formData.color')" class="form-feedback is-invalid">
            {{ getErrorMessage('formData.color') }}
          </div>
        </div>
        <div v-if="sizeOptions.length" class="is-required" :class="{'has-error': !!getErrorMessage('formData.size')}">
          <label for="size" class="form-label mt-2">{{ $t('product.variants.size') }}</label>

          <div class="select">
            <select
              id="size"
              ref="size"
              v-model="formData.size"
              name="size"
              class="form-control"
              qaid="sellSheetProductSize"
              @change="onSizeSelect"
            >
              <option v-for="item in sizeOptions" :key="item.id" :data-id="item.id" :value="item.colorSwatchName">
                {{ item.size }}
              </option>
            </select>
          </div>
          <div v-show="getErrorMessage('formData.size')" class="form-feedback is-invalid">
            {{ getErrorMessage('formData.size') }}
          </div>
        </div>
      </div>

      <fieldset class="form-fieldset lg:max-w-sm mt-3">
        <label class="checkbox">
          <input
            id="includeImage-checkbox-1"
            v-model="formData.showProductImage"
            type="checkbox"
            name="showProductImage"
            qaid="sellSheetProductIncludeImage"
            checked
          />
          <span class="control-indicator"></span>
          {{ $t('sellSheets.B2B.includeImage') }}
        </label>

        <div class="mt-4">
          <label class="checkbox">
            <input
              id="additionalProductInfo-checkbox-2"
              v-model="formData.showAdditionalProductInfo"
              type="checkbox"
              name="showAdditionalProductInfo"
              value="true"
              qaid="sellSheetProductAdditionalInfo"
            />
            <span class="control-indicator"></span>
            {{ $t('sellSheets.B2B.additionalProductInfo') }}
          </label>
          <form-group
            id="showAdditionalProductInfo-textarea-1"
            v-model="formData.additionalProductInformation"
            class="lg:ml-5"
            name="additionalProductInformation"
            type="textarea"
            qaid="sellSheetProductAdditionalInfoInput"
            :disabled="!formData.showAdditionalProductInfo"
            show-error-messages
            force-display-error
            :label="$t('sellSheets.B2B.additionalProductInfo')"
            :error="getErrorMessage('formData.additionalProductInformation')"
            :rows="5"
            :show-label="false"
          />
        </div>
      </fieldset>

      <pdl-section-header size="md" :is-dividing="true" class="mt-4">
        <template slot="content">
          <pdl-heading :level="3">{{ $t('text.pricing') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <fieldset class="form-fieldset lg:max-w-sm">
        <label v-show="pricingInfo.calculableAdvertised" class="checkbox">
          <input
            id="advertisedPrice-checkbox-1"
            v-model="formData.showAdvertisedPrice"
            type="checkbox"
            name="showAdvertisedPrice"
            value="true"
            qaid="sellSheetPricingAdvertised"
          />
          <span class="control-indicator"></span>
          {{ $t('text.buyingZone.advertised') }}
        </label>
        <label v-show="pricingInfo.closeoutPrice" class="checkbox">
          <input
            id="closeOutPrice-checkbox-2"
            v-model="formData.showCloseOutPrice"
            type="checkbox"
            name="showCloseOutPrice"
            value="true"
            qaid="sellSheetPricingCloseout"
          />
          <span class="control-indicator"></span>
          {{ $t('text.buyingZone.closeout') }}
        </label>
        <label v-show="pricingInfo.calculableSaleAdvertised" class="checkbox">
          <input
            id="reducedPrice-checkbox-3"
            v-model="formData.showReducedPrice"
            type="checkbox"
            name="showReducedPrice"
            value="true"
            qaid="sellSheetPricingSale"
          />
          <span class="control-indicator"></span>
          {{ $t('text.buyingZone.salePrice') }}
        </label>
        <label v-show="pricingInfo.applePrice" class="checkbox">
          <input
            id="applePrice-checkbox-4"
            v-model="formData.showApplePrice"
            type="checkbox"
            name="showApplePrice"
            value="true"
            qaid="sellSheetPricingApple"
          />
          <span class="control-indicator"></span>
          {{ $t('text.buyingZone.applePrice') }}
        </label>

        <label class="checkbox">
          <input
            id="ourPrice-checkbox-5"
            v-model="formData.showOurPrice"
            type="checkbox"
            name="showOurPrice"
            qaid="sellSheetPricingOurPrice"
          />
          <span class="control-indicator"></span>
          {{ $t('text.ourPrice') }}
        </label>
        <form-group
          id="ourPrice-text-1"
          v-model="formData.ourPrice"
          class="ml-5"
          name="ourPrice"
          type="text"
          qaid="sellSheetPricingOurPriceInput"
          show-error-messages
          force-display-error
          :disabled="!formData.showOurPrice"
          :error="getErrorMessage('formData.ourPrice')"
          :label="$t('text.ourPrice')"
          :show-label="false"
        />
      </fieldset>

      <div class="buttons pb-3">
        <trek-button
          id="downloadSellSheetButton"
          ref="downloadButton"
          primary
          icon="get_app"
          qaid="sellSheetDownload"
          @click.stop="onSubmit"
        >
          <span v-html="$t('sellSheets.B2B.download')"></span>
        </trek-button>
        <trek-link button secondary :href="returnUrl" qaid="sellSheetCancel">
          <span v-html="$t('checkout.multi.cancel')"></span>
        </trek-link>
      </div>
    </pdl-loading>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import {mapState} from 'vuex';
import {downloadFile} from '@/utils/file-download';
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlToastType} from '@/constants/pdl-toast-type';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin.js';
import {scrollToElement} from '@/utils/scroll-to';

export default {
  components: {TrekButton, TrekLink, PdlLoading, PdlSectionHeader, PdlHeading},
  mixins: [TrekValidationMixin],
  props: {
    address: {
      type: Object,
      default: () => {
        return {
          line1: null,
          line2: null,
          regionName: null,
          town: null,
          postalCode: null,
          displayCountry: null,
          email: null,
          url: null,
        };
      },
    },
    baseProductId: {
      type: String,
      required: true,
    },
    displayName: {
      type: String,
      default: undefined,
    },
    hasPosData: {
      type: Boolean,
      required: true,
    },
    isAppleLabelDealer: {
      type: Boolean,
      required: true,
    },
    openingHours: {
      type: Array,
      default: () => [],
    },
    productCode: {
      type: String,
      required: true,
    },
    returnUrl: {
      type: String,
      required: true,
    },
    sellSheetPdfUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      includeImage: false,
      productVariants: null,
      loading: true,
      downloading: false,
      pricingInfo: {
        applePrice: null,
        bulkPrices: null,
        bulkSalePrices: null,
        calculableAdvertised: null,
        calculableSaleAdvertised: null,
        closeoutPrice: null,
        price: null,
        savedAmount: null,
        wasPrice: null,
      },
      formData: {
        additionalContactInformation: '',
        additionalProductInformation: '',
        color: null,
        logo: 'logo-store',
        ourPrice: '',
        pdfName: '',
        pdfView: true,
        productCode: this.productCode,
        showAdditionalContactInfo: false,
        showAdditionalProductInfo: false,
        showAdvertisedPrice: !this.isAppleLabelDealer,
        showApplePrice: this.isAppleLabelDealer,
        showCloseOutPrice: false,
        showEmail: true,
        showHeader: false,
        showLogo: true,
        showOurPrice: false,
        showProductImage: true,
        showReducedPrice: false,
        showStoreAddress: true,
        showStoreHours: true,
        showStoreName: true,
        showWebsiteUrl: true,
        size: null,
        suffixTimestamp: false,
        tidyHTML: true,
      },
    };
  },

  computed: {
    ...mapState('backend', ['encodedContextPath']),

    // Map the product variants (retrieved via API call) by color.
    variantsByColor() {
      if (!this.productVariants) return [];
      return keyBy(this.productVariants, 'id');
    },

    // Return only the size options available for the currently selected color
    sizeOptions() {
      if (!this.variantsByColor || !this.formData.color) return [];
      const sizes = this.variantsByColor[this.formData.color].sizes;
      return sortBy(sizes, ['id']);
    },

    pdfName() {
      return `Sell_Sheet_${this.formData.productCode}`;
    },

    // Split the bound form data values into a query string to be sent with the request for the PDF
    query() {
      const esc = encodeURIComponent;

      return Object.keys(this.formData)
        .map((k) => esc(k) + '=' + esc(this.formData[k]))
        .join('&');
    },

    // The URL that will get used as the target for getting the PDF
    requestUrl() {
      return `${this.sellSheetPdfUrl}${this.formData.productCode}/pdf?${this.query}`;
    },
  },
  validations() {
    const validations = {
      formData: {
        color: {
          required: this.trekValidators.required('product.variants.colour'),
        },
        size: {
          required: this.trekValidators.required('product.variants.size'),
        },
        ourPrice: {
          required: this.trekValidators.requiredUnless('text.ourPrice', !this.formData.showOurPrice),
        },
        additionalContactInformation: {
          required: this.trekValidators.requiredUnless(
            'sellSheets.B2B.additionalContactInfo',
            !this.formData.showAdditionalContactInfo
          ),
        },
        additionalProductInformation: {
          required: this.trekValidators.requiredUnless(
            'sellSheets.B2B.additionalProductInfo',
            !this.formData.showAdditionalProductInfo
          ),
        },
      },
    };

    return validations;
  },

  mounted() {
    this.formData.pdfName = this.pdfName;

    // Do the API call for pricing info and product variants so we can
    // set other required local scope data
    Promise.all([this.getPrices(), this.getOtherPrices(), this.getVariants()]).then(
      ([pricing, otherPrices, variants]) => {
        this.setApplePrice(pricing.data);
        this.setOtherPrices(otherPrices.data);
        this.productVariants = variants.data.data;
        this.setSize();
        this.setColor();
        this.loading = false;
      }
    );
  },

  methods: {
    getPrices() {
      return storefrontInstance.get(`${this.encodedContextPath}/p/pricinginfo/${this.formData.productCode}/`);
    },

    getOtherPrices() {
      return storefrontInstance.get(`${this.encodedContextPath}/p/${this.formData.productCode}/price`);
    },

    setApplePrice({applePrice}) {
      if (this.isAppleLabelDealer) {
        this.pricingInfo.applePrice = applePrice;
      }
    },

    setOtherPrices({formattedValue, formattedCloseoutWholesalePrice, calculableSaleAdvertised}) {
      this.pricingInfo.calculableAdvertised = formattedValue;
      this.pricingInfo.closeoutPrice = formattedCloseoutWholesalePrice;
      this.pricingInfo.calculableSaleAdvertised = calculableSaleAdvertised;
    },

    onSizeSelect(e) {
      this.formData.productCode = e.target.options[e.target.options.selectedIndex].getAttribute('data-id');
      this.setSize();
      this.updatePricesOnSizeSelect();
    },

    updatePricesOnSizeSelect() {
      this.getPrices().then((response) => {
        this.setApplePrice(response.data);

        this.getOtherPrices().then((response) => {
          this.setOtherPrices(response.data);
        });
      });
    },

    getVariants() {
      return storefrontInstance.get(`/technical-specification/selector/matrix/${this.baseProductId}`);
    },

    downloadPdf() {
      this.downloading = true;
      downloadFile(this.requestUrl, `${this.pdfName}.pdf`, 'application/pdf')
        .then(() => {
          this.$nextTick(() => {
            this.downloading = false;
            this.redirectToPdp();
          });
        })
        .catch((e) => {
          this.downloading = false;
          this.$notify({
            type: PdlToastType.WARNING,
            message: e.message,
          });
        });
    },

    onSubmit() {
      // Validate using vuelidate. If there are errors, scroll the browser to top-most error
      // on the page
      this.v$.$validate().then((valid) => {
        if (!valid) {
          const firstErrorFieldName = document.querySelector('.has-error');
          scrollToElement(firstErrorFieldName);
        } else {
          return this.downloadPdf();
        }
      });
    },

    setLogoOption(e) {
      if (e.target.value && this.logo == null) return (this.logo = 'logo-trek');
      return (this.logo = null);
    },

    setSize() {
      return this.productVariants.findIndex((variant) => {
        if (!variant.sizes || !variant.sizes.length) return false;

        let sizeIndex = variant.sizes.findIndex((size) => size.id == this.formData.productCode);
        if (sizeIndex === -1) {
          sizeIndex = 0;
        }
        this.formData.size = variant.sizes[sizeIndex].colorSwatchName;

        return sizeIndex;
      });
    },

    setColor() {
      const sizeIndex = this.setSize();
      const variantIndex = sizeIndex > 0 ? sizeIndex : 0;
      return (this.formData.color = this.productVariants[variantIndex].id);
    },

    redirectToPdp() {
      return location.replace(this.returnUrl);
    },

    /**
     * Reset size options to the first one when color is changed
     */
    resetSize() {
      if (this.sizeOptions.length) {
        this.formData.size = this.sizeOptions[0].colorSwatchName;
        this.formData.productCode = this.sizeOptions[0].id;
      } else {
        this.formData.size = null;
      }

      this.updatePricesOnSizeSelect();
    },
  },
};
</script>
