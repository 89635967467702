<template>
  <pdl-icon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 19.425V12.575L6 9.1V15.95L12 19.425ZM14 19.425L20 15.95V9.1L14 12.575V19.425ZM12 21.725L5 17.7C4.68333 17.5167 4.4375 17.275 4.2625 16.975C4.0875 16.675 4 16.3417 4 15.975V8.025C4 7.65833 4.0875 7.325 4.2625 7.025C4.4375 6.725 4.68333 6.48333 5 6.3L12 2.275C12.3167 2.09167 12.65 2 13 2C13.35 2 13.6833 2.09167 14 2.275L21 6.3C21.3167 6.48333 21.5625 6.725 21.7375 7.025C21.9125 7.325 22 7.65833 22 8.025V15.975C22 16.3417 21.9125 16.675 21.7375 16.975C21.5625 17.275 21.3167 17.5167 21 17.7L14 21.725C13.6833 21.9083 13.35 22 13 22C12.65 22 12.3167 21.9083 12 21.725ZM17 8.525L18.925 7.425L13 4L11.05 5.125L17 8.525ZM13 10.85L14.95 9.725L9.025 6.3L7.075 7.425L13 10.85Z"
        fill="#313131"
      />
      <path d="M7 12H1" stroke="#313131" stroke-width="2" stroke-linecap="round" />
      <path d="M6 7.5H3" stroke="#313131" stroke-width="2" stroke-linecap="round" />
      <path d="M9 16.5H2" stroke="#313131" stroke-width="2" stroke-linecap="round" />
    </svg>
  </pdl-icon>
</template>
<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {PdlIcon},
};
</script>
