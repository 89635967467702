<template>
  <div>
    <section :id="uid" class="details-list grid-container mb-6" :data-magellan-target="uid">
      <pdl-collapse icon-position="left" class="pdp-spec-collapse" qaid="pdp-spec-collapse">
        <p v-html="bp('productDetailsSpecs')" />
        <slot name="components" />
      </pdl-collapse>
      <div class="mb-1">
        <p v-html="b2b ? bp('disclaimerB2B') : bp('disclaimerB2C')" />
      </div>
      <div class="mb-1">
        <p v-html="isBike ? bp('weightBike') : bp('weightAftermarket')" />
      </div>
    </section>
  </div>
</template>

<script>
import {PdlCollapse} from '@pedal/pdl-collapse';
import {mapState} from 'vuex';
export default {
  components: {
    PdlCollapse,
  },
  props: {
    uid: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapState('backend', ['b2b']),
    ...mapState('pdp', ['isBike']),
  },
};
</script>
