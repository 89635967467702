<template>
  <mapbox-map :map-classes="mapClasses" />
</template>

<script>
import MapFactory from '@/components/mapbox/utils/mapbox';
import MapboxMap from '@/components/mapbox/components/MapboxMap';
import {mapState, mapMutations} from 'vuex';
import {RouteBuilderMapDefaults} from '@/constants/rcp-builder';

export default {
  components: {
    MapboxMap,
  },
  props: {
    mapClasses: {
      type: String,
      default: '',
    },
    center: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {map: undefined};
  },
  computed: {
    ...mapState('backend', ['mapboxApiKey']),
    ...mapState('rcpBuilder', ['zoomValue', 'maxZoom', 'minZoom', 'mapView']),
  },
  async mounted() {
    this.map = await MapFactory({
      accessToken: this.mapboxApiKey,
      center: [this.center.longitude, this.center.latitude],
      zoom: RouteBuilderMapDefaults.DEFAULT_MAP_ZOOM,
      attributionControl: false,
      cooperativeGestures: false,
      style: this.mapView,
      pitch: 0,
      bearing: 0,
    });

    this.map.mapInstance.dragRotate.disable();
    this.map.mapInstance.touchZoomRotate.disableRotation();
    this.map.mapInstance.setMaxZoom(this.maxZoom);
    this.map.mapInstance.setMinZoom(this.minZoom);

    this.map.mapInstance.on('load', async () => {
      this.map.mapInstance.resize();
      this.$emit('rcpMap', this.map);
    });
    this.map.mapInstance.on('idle', () => {
      this.setCurrentZoomLevel(this.map.mapInstance.getZoom());
    });
  },
  methods: {
    ...mapMutations('rcpBuilder', ['setCurrentZoomLevel']),
  },
};
</script>
