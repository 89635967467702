<template>
  <div v-if="hasAttributes" class="pdp-product-attributes-container">
    <component
      :is="getAttributeComponent(attribute)"
      v-for="attribute in product.variantAttributesDirectory"
      :key="attribute.code"
      :code="attribute.code"
      :product="product"
      :selected-variant="selectedVariant"
      :init-attribute-selection="initAttributeSelection"
      :stock-message="stockMessage"
      :label="attribute.displayName"
      :attribute="getAttributeOptions(attribute)"
      :selected-attributes="selectedAttributes"
      :update-selected-attributes="updateSelectedAttributes"
      @color-changed="
        (color) => {
          $emit('color-changed', color);
        }
      "
    >
    </component>
    <size-link v-if="isSizeLinkEnabled" :size-link-guide="product.sizeLinkGuide" />
  </div>
</template>
<script>
import SizeLink from '@/components/containers/pdp/attributes/SizeLink';
import {mapState, mapMutations} from 'vuex';

const Attributes = {
  dropdown: () => import('@/components/containers/pdp/attributes/AttributeDropdown'),
  swatch: () => import('@/components/containers/pdp/attributes/AttributeColor'),
  radio: () => import('@/components/containers/pdp/attributes/ProductAttributeButtonGrid'),
};

export default {
  name: 'PdpProductAttributesContainer',
  components: {SizeLink},
  props: {
    product: {
      type: Object,
      required: true,
    },
    initAttributeSelection: {
      type: Object,
      default: () => null,
    },
    value: {
      type: String,
      default: '',
    },
    stockMessage: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      selectedAttributes: {},
      selectedVariant: {},
    };
  },

  computed: {
    ...mapState('pdp', ['sizeChartData']),
    hasAttributes() {
      return this.product && this.product.variantAttributesDirectory.length > 0;
    },
    hasSizeAttribute() {
      return this.product.variantAttributesDirectory.some((attribute) =>
        attribute.code?.toLocaleLowerCase().includes('size')
      );
    },
    isSizeLinkEnabled() {
      return this.hasSizeAttribute && (this.product?.sizeLinkGuide || this.sizeChartData);
    },
  },
  mounted() {
    this.updateSelectedAttributes(this.initAttributeSelection);
  },
  methods: {
    ...mapMutations('savedLists', ['setBuyingZoneEntries']),
    getAttributeComponent(attribute) {
      return Attributes?.[attribute?.type] ?? Attributes.dropdown;
    },
    getAttributeOptions(attribute) {
      return attribute.options.map((option) => {
        return {
          ...attribute,
          ...option,
        };
      });
    },
    getSelectedVariant() {
      const variant = this.product.variants.find((v) => {
        return Object.entries(this.selectedAttributes).every(([key, value]) => {
          return v.variantAttributes[key] === value;
        });
      });

      return variant;
    },
    updateSelectedAttributes(selectedAttributes) {
      let validatedAttributes = {};

      Object.keys(selectedAttributes).forEach((code) => {
        const hasValidAttributeCode = this.product.variantAttributesDirectory.find(
          (attribute) => attribute.code === code
        );

        if (hasValidAttributeCode) {
          validatedAttributes[code] = selectedAttributes[code];
        }
      });

      this.selectedAttributes = {
        ...this.selectedAttributes,
        ...validatedAttributes,
      };

      const variant = this.getSelectedVariant();
      if (variant?.code) {
        this.$emit('input', variant.code);
        this.setBuyingZoneEntries([{sku: variant.code, qty: 1}]);
      } else {
        this.$emit('input', null);
      }

      this.selectedVariant = variant;
    },
  },
};
</script>
