<template>
  <pdl-loading :is-loading="isMapLoading">
    <div v-if="center">
      <mapbox-map :map-classes="mapClasses" qaid="locator-page-map-canvas" />
    </div>
  </pdl-loading>
</template>
<script>
import debounce from 'lodash/debounce';
import MapFactory from '@/components/mapbox/utils/mapbox';
import {mapState} from 'vuex';
import MapboxMap from '@/components/mapbox/components/MapboxMap.vue';
import {PdlLoading} from '@pedal/pdl-loading';
import {computeGeocodedLocation} from '@/components/mapbox/utils/mapbox-api';

const centerChangedDebounceTime = 250;
const MAP_MARKER = `<svg width="38" height="53" viewBox="0 0 38 53">
  <defs>
    <filter id="a" width="150%" height="250%" x="-25%" y="-75%" filterUnits="objectBoundingBox">
      <feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
    </filter>
  </defs>
  <g fill="none" fill-rule="evenodd" transform="translate(3 3)">
    <path fill="currentColor" fill-rule="nonzero" stroke="currentColor" stroke-opacity=".202" stroke-width="3" d="M16-1.5A17.445 17.445 0 0 0 3.626 3.626 17.445 17.445 0 0 0-1.5 16c0 6.12 5.318 14.521 16.468 25.089.346.327.69.65 1.03.966.343-.315.687-.638 1.034-.966C28.182 30.52 33.5 22.119 33.5 16c0-4.832-1.959-9.207-5.126-12.374A17.445 17.445 0 0 0 16-1.5Z"/>
    <ellipse cx="16" cy="46" fill="#1A1A1A" filter="url(#a)" opacity=".4" rx="6" ry="2"/>
    <path class='favorite' opacity="0" fill="#FFF" fill-rule="nonzero" d="m16 23.792-1.208-1.1C10.5 18.8 7.667 16.233 7.667 13.083c0-2.566 2.016-4.583 4.583-4.583A4.99 4.99 0 0 1 16 10.242 4.99 4.99 0 0 1 19.75 8.5c2.567 0 4.583 2.017 4.583 4.583 0 3.15-2.833 5.717-7.125 9.617L16 23.792Z"/>
    <circle cx="16" cy="15" r="6" fill="#FFF"/>
  </g>
</svg>
`;

export default {
  components: {
    MapboxMap,
    PdlLoading,
  },
  props: {
    cardHoverId: {
      type: Number,
      default: 0,
    },
    quickViewId: {
      type: String,
      default: null,
    },
    stores: {
      type: Array,
      default: () => [],
    },
    centerCoords: {
      type: Object,
      default: () => null,
    },
    zoomLevel: {
      type: Number,
      default: 11,
    },
    boundsToSet: {
      type: Object,
      default: () => null,
    },
    boundsToSetByCenterAndZoom: {
      type: Object,
      default: () => null,
    },
    boundsToSetByTextQuery: {
      type: String,
      default: null,
    },
    mapClasses: {
      type: String,
      default: null,
    },
    showDebug: Boolean,
    isLoading: Boolean,
  },
  data() {
    return {
      map: undefined,
      center: {...this.centerCoords},
      marker: undefined,
      zoom: this.zoomLevel,
      isMapLoading: true,
      currentMarkers: [],
    };
  },
  computed: {
    ...mapState('backend', ['mapboxApiKey']),
    favIndex() {
      for (let index = 0; index < this.stores.length; index++) {
        if (this.stores[index].isMyStore) return index;
      }
      return -1;
    },
  },
  watch: {
    stores() {
      this.setMarkers();
    },
    quickViewId(val) {
      if (val) {
        this.extendBoundsForStore(val);
      }

      this.updateActiveMarker(val);
    },
    boundsToSet(val) {
      if (!val) return;
      this.setBounds(val);
    },
    boundsToSetByCenterAndZoom(val) {
      if (!val) return;
      const centerCoords = {lng: val.centerCoords.lng, lat: val.centerCoords.lat};
      const updatedPayload = {
        centerCoords: centerCoords,
        zoom: val.zoom,
        extendBoundsForStore: val.extendBoundsForStore,
      };
      this.setBoundsByCenterAndZoom(updatedPayload);
    },
    boundsToSetByTextQuery(val) {
      if (!val) return;
      this.findLocation(val);
    },
    cardHoverId(val) {
      if (!this.currentMarkers.length) return;
      this.currentMarkers.forEach((marker) => {
        marker.classList.remove('location-marker--card-hovered');
      });
      if (val === -1) return;
      this.currentMarkers[val].classList.add('location-marker--card-hovered');
    },
  },
  async mounted() {
    this.map = await MapFactory({
      accessToken: this.mapboxApiKey,
      center: [
        this.center?.lng === 0 ? -89.0058727 : this.center?.lng,
        this.center?.lat === 0 ? 43.1790849 : this.center?.lat,
      ],
      zoom: this.zoom,
      cooperativeGestures: true,
    });
    this.map.includeZoomControl();
    this.map.addFullscreenControl();
    this.map.mapInstance.on('load', () => {
      this.map.mapInstance.resize();
      this.initializeMap();
      this.isMapLoading = false;
    });
  },

  unmounted() {
    this.map.remove();
    this.map = null;
  },

  methods: {
    initializeMap() {
      if (this.quickViewId) {
        this.extendBoundsForStore(this.quickViewId);
      } else if (this.boundsToSet) {
        this.setBounds(this.boundsToSet);
      } else if (this.boundsToSetByCenterAndZoom) {
        this.setBoundsByCenterAndZoom(this.boundsToSetByCenterAndZoom);
      } else if (this.boundsToSetByTextQuery) {
        this.findLocation(this.boundsToSetByTextQuery);
      }
    },

    setMarkers() {
      if (this.currentMarkers.length) {
        this.currentMarkers.forEach((marker) => {
          marker.removeEventListener('mouseenter', this.watchMarkerHover);
          marker.removeEventListener('mouseleave', this.watchMarkerHoverLeave);
        });
      }
      this.currentMarkers = [];
      this.map.clearMarkers();
      this.stores.forEach((store, index) => {
        const el = document.createElement('div');
        el.className = 'location-marker';
        el.dataset.quickViewId = store.id;
        this.favIndex == index && this.classList.add('location-marker--favorite');
        el.innerHTML = MAP_MARKER;

        const marker = this.map.addMarker({
          position: [store.location.longitude, store.location.latitude],
          markerOptions: {draggable: false, element: el},
        });
        const markerElement = marker.getElement();
        this.currentMarkers.push(markerElement);
        markerElement.addEventListener('click', () => {
          this.markerClicked(store.id, store.distance, markerElement);
        });
        markerElement.addEventListener('mouseenter', this.watchMarkerHover);
        markerElement.addEventListener('mouseleave', this.watchMarkerHoverLeave);
      });
    },

    watchMarkerHover(e) {
      const hoveredIndex = this.currentMarkers.indexOf(e.target);
      this.$emit('pin-hover', hoveredIndex);
    },

    watchMarkerHoverLeave() {
      this.$emit('pin-hover', -1);
    },

    updateActiveMarker(quickViewId) {
      if (!this.currentMarkers.length) return;
      this.currentMarkers.forEach((marker) => {
        marker.classList.remove('location-marker--active');
        if (!quickViewId) return;

        if (marker.dataset.quickViewId === quickViewId) {
          marker.classList.add('location-marker--active');
        }
      });
    },
    storePos(store) {
      return {lat: store.location.latitude, lng: store.location.longitude};
    },
    markerClicked(id, distance) {
      this.$emit('store-clicked', id, distance);
    },

    zoomChanged(val) {
      if (val) {
        if (!this.isSettingBounds) {
          this.handleUserChange();
        } else {
          this.emitNewCoords();
        }
      }
    },

    centerChanged: debounce(function () {
      // Used for map panning via drag or keyboard
      if (!this.isSettingBounds) {
        this.handleUserChange();
      } else {
        this.emitNewCoords();
      }
    }, centerChangedDebounceTime),

    setCenter(coords) {
      const currentCenter = this.map.mapInstance.getCenter();
      if (
        coords &&
        Number.isFinite(coords.lat) &&
        Number.isFinite(coords.lng) &&
        !(coords.lat === currentCenter.lat && coords.lng === currentCenter.lng)
      ) {
        this.center = {...coords};
        this.map.updateMapCenter(coords);
      }
    },

    setZoom(zoom) {
      const currentZoom = this.map.mapInstance.getZoom();
      if (currentZoom !== zoom) {
        this.zoom = zoom;

        this.map.mapInstance.setZoom(zoom);
      }
    },

    setBounds(bounds) {
      const mapObject = this.map.mapInstance;
      if (!mapObject) return;
      this.setIsSettingBounds(true);

      const currentBounds = mapObject.getBounds() ? mapObject.getBounds() : {};

      if (JSON.stringify(bounds) === JSON.stringify(currentBounds)) return;

      mapObject.fitBounds(
        [
          [bounds._sw.lng, bounds._sw.lat],
          [bounds._ne.lng, bounds._ne.lat],
        ],
        {padding: {top: 90, bottom: 75, left: 50, right: 50}}
      );

      this.setIsSettingBounds(false);
    },

    setBoundsByCenterAndZoom(payload) {
      const currentCenter = this.map.mapInstance.getCenter();
      const currentZoom = this.map.mapInstance.getZoom();
      // If we aren't changing centerCoords or zoom, then just do extendBoundsForStore
      if (
        currentCenter.lat === payload.centerCoords.lat &&
        currentCenter.lng === payload.centerCoords.lng &&
        currentZoom === payload.zoom
      ) {
        if (payload.extendBoundsForStore) {
          this.setIsSettingBounds(true);
          this.extendBoundsForStore(payload.extendBoundsForStore);
          this.setIsSettingBounds(false);
        }
        return;
      }

      this.setIsSettingBounds(true);

      if (payload.extendBoundsForStore) {
        // Make sure store is visible on the map by extending the map bounds if necessary
        this.map.mapInstance.once('moveend', () => {
          this.extendBoundsForStore(payload.extendBoundsForStore);
          this.setIsSettingBounds(false);
        });
      }

      this.setZoom(payload.zoom);
      this.setCenter(payload.centerCoords);
      this.emitNewCoords();

      if (!payload.extendBoundsForStore) {
        this.setIsSettingBounds(false);
      }
    },

    extendBoundsForStore(storeId) {
      setTimeout(() => {
        const store = this.stores.find((storeForId) => {
          return storeForId.id === storeId;
        });

        if (store && store.location) {
          const storeLocation = {
            lng: store.location.longitude,
            lat: store.location.latitude,
          };

          const currentBounds = this.map.mapInstance.getBounds();

          if (!currentBounds.contains(storeLocation)) {
            const expandedBounds = currentBounds.extend(storeLocation);

            this.setBounds(expandedBounds);
          }
        }
      }, centerChangedDebounceTime * 2);
    },

    async findLocation(address) {
      this.setIsSettingBounds(true);
      try {
        const coordinates = await computeGeocodedLocation(address, this.mapboxApiKey);
        this.setZoom(this.initialZoom);
        this.setCenter({
          lng: coordinates[0],
          lat: coordinates[1],
        });
        this.setIsSettingBounds(false);
      } catch (error) {
        console.error(error);
      }
    },

    emitNewCoords() {
      if (!this.map.mapInstance) return;
      this.zoom = this.map.mapInstance.getZoom();
      this.center = this.map.mapInstance.getCenter();
      this.$emit('new-coords', this.center, this.zoom);
    },

    handleUserChange() {
      this.zoom = this.map.mapInstance.getZoom();
      this.center = this.map.mapInstance.getCenter();
      const bounds = this.map.mapInstance.getBounds();
      const boundsArray = [
        {lat: bounds.south, lng: bounds.west},
        {lat: bounds.north, lng: bounds.east},
      ];
      this.$emit('user-changed-map', boundsArray);
      this.$emit('new-coords', this.center, this.zoom);
    },

    setIsSettingBounds(val) {
      if (val) {
        this.isSettingBounds = true;
      } else {
        this.$nextTick(() => {
          setTimeout(() => {
            this.isSettingBounds = false;
          }, centerChangedDebounceTime * 2);
        });
      }
    },
  },
};
</script>
<style lang="scss">
.location-marker {
  pointer-events: none;

  svg {
    cursor: pointer;
    color: #08c;
    transition: 0.2s color ease, 0.2s transform ease;
    transform: translateY(-50%);
    transform-origin: 50% 87%;

    &:hover {
      color: #1a1a1a;
      transform: translateY(-50%) scale(1.25);
      z-index: 999;
    }

    g {
      pointer-events: all;
    }
  }

  &--favorite {
    .favorite {
      opacity: 1;

      & + circle {
        opacity: 0;
      }
    }
  }

  &--active,
  &--card-hovered {
    svg {
      color: #1a1a1a;
      transform: translateY(-50%) scale(1.25);
    }
  }
}
</style>
