<template>
  <div class="grid-container text-base">
    <!-- Header -->
    <pdl-section-header :is-dividing="true" size="xl">
      <template slot="content">
        <pdl-heading :level="1" qaid="shipments-inquiry-heading">{{ $t('checkout.B2B.shipments') }}</pdl-heading>
      </template>
      <template slot="actions">
        <div class="buttons">
          <trek-link
            button
            secondary
            small
            :href="`${encodedContextPath}/my-account/orders/`"
            qaid="shipments-inquiry-go-to-order-history"
          >
            <span>{{ $t('shipmentInquiry.B2B.orderHistory') }}</span>
          </trek-link>
        </div>
      </template>
    </pdl-section-header>

    <p class="mt-3" qaid="shipments-inquiry-description">{{ $t('shipmentInquiry.B2B.search') }}</p>

    <pdl-section-header :is-dividing="true" size="md" class="mt-3">
      <template slot="content">
        <pdl-heading level="1" qaid="shipments-inquiry-filters-heading">
          {{ $t('shipmentInquiry.B2B.filter') }}
        </pdl-heading>
      </template>
    </pdl-section-header>

    <div class="grid-x grid-margin-x">
      <div class="cell large-4">
        <form-group name="shipDate" :label="$t('test.shipDate')">
          <template #input>
            <pdl-date-picker
              ref="dateRangePicker"
              mode="range"
              :default-date="urlParamDefaultDate"
              :allow-input="true"
              qaid="shipments-inquiry-ship-date-field"
              @change="dateFilterInput"
            />
            <pdl-button tertiary text underline qaid="shipments-inquiry-view-last-thirty-days" @click="getLast30Days">
              {{ $t('shipmentInquiry.B2B.view30Days') }}
            </pdl-button>
          </template>
        </form-group>
        <form-group
          v-model="options.shipToNumber"
          type="text"
          name="shipToNumber"
          :label="$t('text.storeNumber')"
          :placeholder="$t('shipmentInquiry.B2B.enterStoreNumber')"
          :maxlength="8"
          qaid="shipments-inquiry-store-number"
          @input="updateShipToValue"
        />
      </div>

      <div class="cell large-4">
        <form-group
          v-model="options.packingSlipNumber"
          type="text"
          name="customerPo"
          :label="$t('text.packingSlipNumber')"
          :placeholder="$t('shipmentInquiry.B2B.enterSlip')"
          :maxlength="8"
          qaid="shipments-inquiry-packing-slip-number"
          @input="updatePackingSlipNumber"
        />
      </div>
      <div class="cell large-4 flex md:justify-end h-4">
        <div class="buttons buttons--right-for-md">
          <trek-button
            id="filter-shipments-button"
            primary
            class="lg:w-full"
            qaid="shipments-inquiry-filter-shipments-button"
            @click="fetchAllOrders"
          >
            <span>{{ $t('shipmentInquiry.B2B.filter') }}</span>
          </trek-button>
          <trek-button
            id="clear-filters-button"
            secondary
            class="lg:w-full"
            qaid="shipments-inquiry-clear-filter-button"
            @click="clearFilters"
          >
            <span>{{ $t('text.clearFilters') }}</span>
          </trek-button>
        </div>
      </div>
    </div>

    <div>
      <pdl-loading page :is-loading="loading" />

      <!-- Orders Tabs -->
      <section class="my-5">
        <pdl-tabs ref="tabs" :value="currentTab" @input="tabChanged">
          <pdl-tab-pane :key="`${bikesCount}-bike`" name="bike">
            <h3 slot="label" class="heading heading--md m-0" qaid="shipments-inquiry-bikes-tab-label">
              {{ bikesCount }}
            </h3>
            <shipments-inquiry-grid
              :grid-data="bike"
              :columns="tableHeaders"
              :size="bike.pageSize"
              @page-change="pageChange"
            />
          </pdl-tab-pane>
          <pdl-tab-pane :key="`${aftermarketCount}-aftermarket`" name="aftermarket">
            <h3 slot="label" class="heading heading--md m-0" qaid="shipments-inquiry-accessories-tab-label">
              {{ aftermarketCount }}
            </h3>
            <shipments-inquiry-grid
              :grid-data="aftermarket"
              :columns="aftermarketHeaders"
              :size="aftermarket.pageSize"
              @page-change="pageChange"
            />
          </pdl-tab-pane>
        </pdl-tabs>
      </section>
    </div>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {mapState} from 'vuex';
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import FormGroup from '@/components/FormGroup';
import {PdlDatePicker} from '@pedal/pdl-date-picker';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import ShipmentsInquiryGrid from '@/components/containers/my-account/ShipmentsInquiryGrid';
import {Url} from '@/utils/url';
import dayjs from 'dayjs';
import {DateFormats} from '@/constants/date-formats';
import {PdlButton} from '@pedal/pdl-button';

const DEFAULT_OPTIONS = {
  endDate: null,
  startDate: null,
  shipToNumber: null,
  packingSlipNumber: null,
};

export default {
  components: {
    TrekButton,
    TrekLink,
    PdlLoading,
    PdlTabs,
    PdlTabPane,
    FormGroup,
    PdlDatePicker,
    PdlSectionHeader,
    PdlHeading,
    ShipmentsInquiryGrid,
    PdlButton,
  },

  provide() {
    return {
      container: this,
    };
  },

  data() {
    return {
      loading: true,
      bike: {
        type: 'bike',
        items: null,
        totalItemCount: 0,
        pageIndex: 0,
        pageSize: 50,
        pageCount: 0,
      },
      aftermarket: {
        type: 'aftermarket',
        items: null,
        totalItemCount: 0,
        pageIndex: 0,
        pageSize: 50,
        pageCount: 0,
      },
      options: {...DEFAULT_OPTIONS},
      currentTab: 'bike',
    };
  },

  computed: {
    tableHeaders() {
      return [
        {label: this.$t('text.hangTag'), key: 'hangTag'},
        {label: this.$t('text.packingSlipNumber'), key: 'packingSlipNumber'},
        {label: this.$t('test.shipDate'), key: 'actualShipDate'},
        {label: this.$t('shipmentInquiry.B2B.shipToAccount'), key: 'shipToNumber'},
        {label: this.$t('shipmentInquiry.B2B.distCenter'), key: 'warehouse'},
        {label: this.$t('text.totalAmount'), key: 'totalPrice'},
        {label: this.$t('shipmentInquiry.B2B.shipmentTracking'), key: 'shipmentTrackingNumber'},
      ];
    },

    bikesCount() {
      return this.bike.totalItemCount
        ? `${this.$t('backordersAllocated.B2B.bikes')} (${this.bike.totalItemCount})`
        : `${this.$t('backordersAllocated.B2B.bikes')} (0)`;
    },

    aftermarketCount() {
      return this.aftermarket.totalItemCount
        ? `${this.$t('text.accessories')} (${this.aftermarket.totalItemCount})`
        : `${this.$t('text.accessories')}  (0)`;
    },

    aftermarketHeaders() {
      // Remove the hangtag column from the aftermarket grid because they're not available for them.
      return this.tableHeaders.filter((header) => header.key !== 'hangTag');
    },

    urlParamDefaultDate() {
      let urlParamDate;
      if (this.options.startDate && this.options.endDate) {
        urlParamDate = [new Date(this.options.startDate), new Date(this.options.endDate)];
      } else {
        urlParamDate = null;
      }
      return urlParamDate;
    },

    ...mapState('backend', ['encodedContextPath']),
  },

  created() {
    this.setFiltersFromUrlParams();
    this.fetchAllOrders();
  },

  methods: {
    async fetchAllOrders() {
      Url.removeUrlParameterObjectFromUrl(this.options);
      Url.appendObjectToUrlParameters(this.options);

      await this.fetchOrders('bike');
      this.fetchOrders('aftermarket');
    },

    fetchOrders(type = 'bike') {
      storefrontInstance
        .get('/my-account/shipments/', {
          params: {
            productType: type,
            pageIndex: this[type].pageIndex,
            pageSize: this[type].pageSize,
            shipToNumber: this.options.shipToNumber,
            packingSlipNumber: this.options.packingSlipNumber,
            shippingStartDate: this.options.startDate,
            shippingEndDate: this.options.endDate,
          },
        })
        .then((response) => {
          if (response && response.data && response.data.data) {
            let data = response.data.data;

            this[type] = {
              type: type,
              items: data.shipments,
              totalItemCount: data.paging.totalItemCount,
              pageIndex: this[type].pageIndex,
              pageSize: this[type].pageSize,
            };

            if (
              this[type].totalItemCount > 0 &&
              this[type].pageSize > 0 &&
              this[type].totalItemCount / this[type].pageSize > 1
            ) {
              this[type].pageCount = Math.ceil(this[type].totalItemCount / this[type].pageSize);
            } else {
              this[type].pageCount = 0;
            }

            if (this[type].pageIndex > this[type].pageCount) {
              this[type].pageIndex = 0;
              this.fetchOrders(type);
            }

            if (this.options.packingSlipNumber && Array.isArray(data.shipments) && data.shipments?.length > 0) {
              if (data.shipments[0]?.productType === 'Bikes') {
                this.currentTab = 'bike';
              } else {
                this.currentTab = 'aftermarket';
              }
            }
          }

          this.loading = false;
        });
    },

    dateFilterInput({startDate, endDate}) {
      if (startDate) {
        this.options.startDate = dayjs(startDate).format(DateFormats.MM_DD_YYYY);
      } else {
        this.options.startDate = null;
      }
      if (endDate) {
        this.options.endDate = dayjs(endDate).format(DateFormats.MM_DD_YYYY);
      } else {
        this.options.endDate = null;
      }
    },

    tabChanged(paneName) {
      this.currentTab = paneName;
    },

    updateShipToValue() {
      const shipToMaxLength = 8;
      this.options.shipToNumber = this.options.shipToNumber.replace(/[^\d]/g, '');
      if (String(this.options.shipToNumber).length > shipToMaxLength) {
        this.options.shipToNumber = this.options.shipToNumber.substring(0, shipToMaxLength);
      }
    },

    updatePackingSlipNumber() {
      const packingSlipNumberMaxLength = 8;
      this.options.packingSlipNumber = this.options.packingSlipNumber.replace(/[^\d]/g, '');
      if (String(this.options.packingSlipNumber).length > packingSlipNumberMaxLength) {
        this.options.packingSlipNumber = this.options.packingSlipNumber.substring(0, packingSlipNumberMaxLength);
      }
    },

    getLast30Days() {
      let date = new Date();
      let endDate = date.toISOString();
      let startDate = new Date(date.setDate(date.getDate() - 30)).toISOString();

      startDate = startDate.substring(0, startDate.indexOf('T'));
      endDate = endDate.substring(0, endDate.indexOf('T'));

      this.options = {};

      this.$refs.dateRangePicker.fp.setDate([startDate, endDate]);
      this.options.startDate = dayjs(this.$refs.dateRangePicker.fp.selectedDates[0]).format(DateFormats.MM_DD_YYYY);
      this.options.endDate = dayjs(this.$refs.dateRangePicker.fp.selectedDates[1]).format(DateFormats.MM_DD_YYYY);
      this.fetchAllOrders();
    },

    clearFilters() {
      Url.removeUrlParameterObjectFromUrl(this.options);
      this.$refs.dateRangePicker.fp.clear();
      this.options = {};
      this.fetchAllOrders();
    },
    setFiltersFromUrlParams() {
      this.urlParams = Url.parse(window.location.search);

      for (const urlParameter in this.urlParams) {
        for (const filterOption in this.options) {
          if (urlParameter === filterOption && this.urlParams[urlParameter] !== null) {
            //url only uses strings, so we need to convert string booleans back to booleans
            if (this.urlParams[urlParameter] === 'true') {
              this.urlParams[urlParameter] = true;
            } else if (this.urlParams[urlParameter] === 'false') {
              this.urlParams[urlParameter] = false;
            }
            this.options[urlParameter] = this.urlParams[urlParameter];
          }
        }
      }
    },

    pageChange({pageIndex, type}) {
      this[type].pageIndex = pageIndex;
      this.fetchOrders(type);
    },
  },
};
</script>
