<template>
  <div class="grid-container pb-5">
    <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1" qaid="contact-us-result-header">{{ bp('contactUsTitle') }}</pdl-heading>
      </template>
    </pdl-section-header>

    <pdl-callout qaid="contact-us-result-callout" kind="success">
      <template #content>
        <span v-html="bp('contactUsResponse')" />
      </template>
    </pdl-callout>

    <div class="buttons py-5">
      <trek-link button primary qaid="contact-us-result-submit-another" :href="`${encodedContextPath}/contact-us`">
        <span v-html="bp('submitAnother')"></span>
      </trek-link>
    </div>
  </div>
</template>
<script>
import TrekLink from '@/components/TrekLink';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {TrekLink, PdlCallout, PdlSectionHeader, PdlHeading},
  props: {
    baseProperties: {
      type: Array,
      default: () => [],
    },
    encodedContextPath: {
      type: String,
      default: '',
    },
  },
};
</script>
