<template>
  <div class="grid-container">
    <form :class="{'opacity-50': isWorking}" @submit.prevent="submit">
      <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="1">
            {{ $t('text.account.profile.updateEmail') }}
          </pdl-heading>
        </template>
      </pdl-section-header>
      <p class="mb-3 text-base">
        {{ $t('text.account.profile.updateEmailAddress') }}
      </p>
      <pdl-callout v-if="message" :kind="messageType">
        <template #content>
          {{ message }}
        </template>
      </pdl-callout>

      <div class="max-w-sm mb-6">
        <form-group
          ref="email"
          v-model="email"
          type="email"
          name="email"
          :label="$t('profile.email')"
          :error="getErrorMessage('email')"
          :show-error-messages="true"
          force-display-error
          required
        />
        <form-group
          v-model="chkEmail"
          type="email"
          name="chkEmail"
          :label="$t('profile.checkEmail')"
          :error="getErrorMessage('chkEmail')"
          :show-error-messages="true"
          force-display-error
          required
        />

        <div class="buttons">
          <trek-button primary type="submit" :disabled="isWorking" @click="submit">
            <span>{{ $t('text.account.profile.saveUpdates') }}</span>
          </trek-button>
          <trek-button secondary @click="cancel">
            <span>{{ $t('text.account.profile.cancel') }}</span>
          </trek-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {PdlCallout} from '@pedal/pdl-callout';
import FormGroup from '@/components/FormGroup';
import TrekButton from '@/components/TrekButton';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {UserAccountBase} from '@/components/containers/my-account/utils/user-account-base';
import {mapState} from 'vuex';
import {getMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';

export default {
  name: 'UpdateEmailContainer',
  components: {PdlCallout, FormGroup, TrekButton, PdlSectionHeader, PdlHeading},
  extends: UserAccountBase,
  data() {
    return {
      email: '',
      chkEmail: '',
      isWorking: false,
    };
  },
  computed: {
    ...mapState('user', ['userEmail']),
  },
  validations() {
    return {
      email: {
        required: this.trekValidators.required('profile.email'),
        email: this.trekValidators.email('profile.email'),
      },
      chkEmail: {
        required: this.trekValidators.required('profile.email'),
        sameAsEmail: this.trekValidators.sameAs('profile.email', this.email, 'profile.checkEmail'),
      },
    };
  },

  methods: {
    /**
     * Handle email update.
     * @return {Promise<void>}
     */
    async processUpdate() {
      this.isWorking = true;
      try {
        const response = await this.api.updateEmailAddress({
          email: this.email,
          chkEmail: this.chkEmail,
        });

        this.displayMessage(response?.data);
        if (!getMetaFeedbackType(response?.data) !== MetaFeedbackType.ERROR) {
          this.resetForm();
        }
      } catch (e) {
        this.displayError(e.message);
      } finally {
        this.isWorking = false;
      }
    },

    /**
     * Reset form data.
     */
    resetForm() {
      this.email = '';
      this.chkEmail = '';
      this.v$.$reset();
    },
  },
};
</script>
