<template>
  <div>
    <input id="product-color" type="hidden" :value="currentColorLabel" />
    <div v-if="swatches.length" class="attribute-color">
      <div class="buying-zone__form-label-group">
        <div class="variantName">
          <span>{{ label }}</span>
          <template v-if="!isSaleSelected">
            <span>/</span>
            <span qaid="product-color-selected" class="text-gray-60 italic">{{ currentColorLabel }}</span>
            <span v-if="currentFinishValue">{{ `; ${currentFinishLabel} / ` }}</span>
            <span v-if="currentFinishValue" class="text-gray-60 italic">{{ currentFinishValue }}</span>
          </template>
        </div>
      </div>
      <pdl-swatch-group
        class="my-1"
        :options="swatches"
        :radio-name="swatchesName"
        :initial-selected-option="selectedColor"
        @color-changed="colorChanged"
      />
    </div>
    <!-- Sale Swatches -->
    <div v-if="saleSwatches.length" class="attribute-color">
      <div class="buying-zone__form-label-group">
        <div class="variantName text-red">
          <span>{{ $t('product.variants.color.sale') }}</span>
          <template v-if="isSaleSelected">
            <span>/</span>
            <span qaid="product-sale-color-selected" class="text-red italic">{{ currentColorLabel }}</span>
            <span v-if="currentFinishValue">{{ `; ${currentFinishLabel} / ` }}</span>
            <span v-if="currentFinishValue" class="text-red italic">{{ currentFinishValue }}</span>
          </template>
        </div>
      </div>
      <pdl-swatch-group
        class="my-1"
        :options="saleSwatches"
        :radio-name="saleSwatchesName"
        :initial-selected-option="selectedSaleColor"
        @color-changed="colorChanged"
      />
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import {Attribute} from '@/components/containers/pdp/attributes/mixins/attribute';
import {PdlSwatchGroup} from '@pedal/pdl-swatch';
import swatchMixin from '@/mixins/swatches.js';
import {determineOptionAvailability} from '@/utils/variant-helpers.js';

export default {
  name: 'AttributeColor',
  components: {PdlSwatchGroup},
  mixins: [Attribute, swatchMixin],
  data() {
    return {
      currentColor: null,
      saleSwatchesName: 'sale-swatches',
      swatchesName: 'swatches',
      selectedColor: undefined,
      selectedSaleColor: undefined,
      currentFinishValue: '',
    };
  },
  computed: {
    ...mapState('backend', ['b2b']),
    ...mapState('pdp', ['isECommAvailable']),
    activeItem() {
      return this.attribute.find((color) => {
        return this.isSaleSelected
          ? color.onSale && color.colorSwatchName === this.currentColor?.name
          : color.colorSwatchName === this.currentColor?.name;
      });
    },
    currentFinishLabel() {
      return this.$t('products.variants.finish');
    },
    isSaleSelected() {
      return this.currentColor?.onSale ?? false;
    },
    currentColorLabel() {
      return this.activeItem?.displayName ?? '';
    },
    saleSwatches() {
      const {attribute, b2b, selectedAttributes, product, isECommAvailable, processColorCode} = this;
      if (!attribute) {
        return [];
      }

      return attribute
        .filter((item) => item.onSale)
        .map((color) => {
          const isAvailable = determineOptionAvailability({
            option: color,
            product,
            b2b,
            selectedAttributes,
            isECommAvailable,
          });

          return {
            isAvailable,
            onSale: true,
            name: color.colorSwatchName,
            value: color.value,
            code: color.code,
            ...processColorCode(color.colorSwatchName),
          };
        });
    },
    swatches() {
      const {attribute, b2b, selectedAttributes, product, isECommAvailable, processColorCode} = this;
      if (!attribute) {
        return [];
      }

      const options = attribute.filter((item) => !item.onSale);
      return options.map((color) => {
        const isAvailable = determineOptionAvailability({
          option: color,
          product,
          b2b,
          selectedAttributes,
          isECommAvailable,
        });

        return {
          name: color.colorSwatchName,
          onSale: false,
          value: color.value,
          code: color.code,
          isAvailable,
          ...processColorCode(color.colorSwatchName),
        };
      });
    },
  },
  watch: {
    attribute() {
      this.populateInitialSelectedOption();
    },
    selectedAttributes() {
      const option = this.currentColor;
      if (option.onSale) {
        const selected = this.saleSwatches.find((swatch) => swatch.value === option.value);
        this.selectedSaleColor = {...selected, radioName: this.saleSwatchesName};
      } else {
        const selected = this.swatches.find((swatch) => swatch.value === option.value);
        this.selectedColor = {...selected, radioName: this.swatchesName};
      }
    },
  },
  mounted() {
    this.populateInitialSelectedOption();
  },
  methods: {
    populateInitialSelectedOption() {
      if (this.currentColor) return;

      const filter = (item) => {
        return item.value === this.initAttributeSelection[this.code];
      };

      const swatch = this.saleSwatches?.find(filter) || this.swatches?.find(filter) || this.swatches?.[0];
      if (swatch?.onSale) {
        this.selectedSaleColor = {...swatch, radioName: this.saleSwatchesName};
      } else {
        this.selectedColor = {...swatch, radioName: this.swatchesName};
      }

      this.currentColor = swatch?.onSale ? this.selectedSaleColor : this.selectedColor;
      this.$emit('color-changed', this.currentColor.name);
      this.fireSelectedProduct();
    },
    fireSelectedProduct() {
      const currentProduct = this.activeItem;
      if (currentProduct) {
        this.currentFinishValue = currentProduct?.specFinishTranslated;
        this.updateSelectedAttributes({
          [currentProduct.code]: currentProduct.value,
        });
      }
    },

    /**
     * Event handler, fires when user changes color via swatches.
     * @param selected
     */
    colorChanged(selected) {
      if (!selected?.name) return;
      this.$emit('color-changed', selected.name);
      this.currentColor = selected;

      this.selectedSaleColor = undefined;
      this.selectedColor = undefined;

      if (selected.onSale) {
        this.selectedSaleColor = selected;
      } else {
        this.selectedColor = selected;
      }
      this.fireSelectedProduct();
    },
  },
};
</script>
