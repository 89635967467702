<template>
  <div :class="[data.cssClass]" :title="getTooltipTitle">
    <i
      :ref="tippyId"
      v-tippy="tippyConfig"
      class="inline-flex relative cursor-pointer"
      :title="data.content"
      @show="restoreOriginalContent"
      @hidden="$emit('tooltip-closed', {id: itemId, content: noteContent, dirtyContent: dirtyContent})"
    >
      <pdl-icon :name="data.value" size="18" />
      <pdl-badge v-if="data.hasNotification" qaid="sku-grid-note-badge" class="absolute left-0 top-0" />
    </i>

    <!-- Tooltip -->
    <div v-if="data.editable" :id="tippyId" v-tippy-html qaid="noteTooltip">
      <div class="cart-line-tooltip text-left p-1 max-w-xs">
        <div>
          <div>
            <label class="font-medium" :for="rowId + '-input'">{{ $t('text.addANote') }}</label>

            <!-- Real Button -->
            <trek-button
              text
              small
              icon-only
              icon="close"
              class="p-25 absolute top-0 right-0 mt-1 mr-1"
              aria-label="Close"
              @click="closeTooltip(noteId)"
            >
            </trek-button>
          </div>
          <div class="form-group mb-0">
            <textarea
              :id="rowId + '-input'"
              v-model="noteContent"
              class="form-control w-64 max-w-full"
              :maxlength="maxLength"
              rows="4"
              :placeholder="placeholderText"
            >
            </textarea>
            <div class="buttons buttons--right mt-1">
              <trek-button primary small qaid="add-note-button" @click="addNote">{{ $t('text.addNote') }}</trek-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrekButton from '@/components/TrekButton';
import {PdlBadge} from '@pedal/pdl-badge';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {TrekButton, PdlBadge, PdlIcon},
  props: {
    data: {
      type: Object,
      default: () => null,
    },
    // need to refactor this so only one value is valid. #nomoretwovalueprops!
    /* eslint-disable */
    rowId: {
      type: [String, Number],
      default: undefined,
    },
    itemId: [String, Number],
    /* eslint-enable */
    maxLength: {
      type: Number,
      default: 0,
    },
    placeholderText: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      noteContent: this.data.content,
      dirtyContent: this.data.content,
    };
  },

  computed: {
    noteId() {
      return `note-${this.rowId}`;
    },
    tippyId() {
      return `tippy_${this.rowId}_${this.sanitizedSku}`;
    },
    tippyConfig() {
      if (this.data.editable) {
        return {
          html: `#${this.tippyId}`,
          theme: 'light',
          interactive: true,
          sticky: true,
          trigger: 'click',
          reactive: true,
        };
      } else {
        return {
          theme: 'light left-align',
          trigger: 'mouseenter focus',
        };
      }
    },
    tooltipContent() {
      return this.editable ? this.noteContent : this.data.content;
    },

    getTooltipTitle() {
      return this.editable ? this.tooltipContent : '';
    },

    // + in SKU for P1 bikes was breaking document.querySelector
    sanitizedSku() {
      if (typeof this.itemId === 'string') {
        return this.itemId.replace(/\+/g, '_');
      }

      return this.itemId;
    },
  },

  mounted() {
    this.dirtyContent = this.data.content;
  },

  methods: {
    restoreOriginalContent() {
      this.noteContent = this.tooltipContent;
    },

    openTooltip() {
      let el = this.$refs[`tippy_${this.rowId}_${this.sanitizedSku}`];
      if (el && el._tippy) el._tippy.show();
    },

    closeTooltip() {
      let el = this.$refs[`tippy_${this.rowId}_${this.sanitizedSku}`];

      if (el && el._tippy) {
        el._tippy.hide();
      }
    },

    addNote() {
      this.$emit('note-added', {id: this.itemId, content: this.noteContent});
      this.dirtyContent = this.noteContent;
      this.closeTooltip(this.noteId);
    },
  },
};
</script>
