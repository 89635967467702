<template>
  <div class="mt-2">
    <pdl-callout v-if="!hasReplacement" kind="warning">
      <template #content>
        <p>{{ $t('productArchive.noReplacement') }}</p>
      </template>
    </pdl-callout>
    <div v-else>
      <pdl-callout>
        <template #content>
          <p qaid="archive-product-replacement-heading" class="mb-1">{{ $t('productArchive.replacement') }}</p>

          <pdl-link qaid="archive-product-replacement-link" standalone :href="url">
            {{ $t('productArchive.viewCurrent') }}
          </pdl-link>
        </template>
      </pdl-callout>
    </div>
  </div>
</template>
<script>
import get from 'lodash/get';
import {Url} from '@/utils/url';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlLink} from '@pedal/pdl-link';

export default {
  name: 'PdpReplacement',
  components: {PdlLink, PdlCallout},
  props: {
    replacementProduct: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    sku() {
      return get(this.replacementProduct, 'sku');
    },
    url() {
      const url = get(this.replacementProduct, 'url');
      return !url ? '' : Url.withContextPath(url);
    },
    hasReplacement() {
      return this.sku && this.url;
    },
  },
};
</script>
