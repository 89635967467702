/**
 * Track checkout mixin is used to track checkout data for our Analytics team.
 * Used across our multi step checkout process to handle the different
 * steps along the way.
 */

import {mapGetters, mapState} from 'vuex';
import {trackEvent} from '@/utils/tracking';

const trackCheckout = {
  props: {
    isNewAddress: {
      type: Boolean,
      default: false,
    },
    cardType: {
      type: String,
      default: '',
    },
    checkoutSteps: {
      type: Array,
      default: () => [],
    },
    isNewPayment: {
      type: Boolean,
      default: false,
    },
    orderConfirmData: {
      type: Object,
      default: () => ({}),
    },
    stepId: {
      type: String,
      default: '',
    },
    deliveryAddress: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      cardTypeSelected: this.cardType,
    };
  },

  computed: {
    ...mapState('checkout', ['selectedDeliveryMode']),
    ...mapGetters('checkout', ['trackCartEntries']),
    ...mapState('backend', ['currencyIso', 'isUserLoggedIn']),

    getCurrentStep() {
      // Current step in the multi step process
      return this.checkoutSteps.find((item) => item.progressBarId === this.stepId)?.stepNumber;
    },

    hasSavedAddress() {
      // Checks if registered user has a saved default address
      return !!(this.isUserLoggedIn && !!this.deliveryAddress.length > 0);
    },

    isSavedAddressLayout() {
      return !!(this.hasSavedAddress && !this.isNewAddress);
    },

    addressType() {
      // Registered user with saved address, Else Guest user newly added address, Registered User new address
      return this.hasSavedAddress && !this.isNewAddress ? 'Saved Address' : 'New Address';
    },
  },

  created() {
    // Initial track page event
    window.addEventListener('DOMContentLoaded', () => this.trackCheckoutPageEvent());

    // Clear cc select on new form
    if (this.isNewPayment === true) {
      this.cardTypeSelected = '';
    }
  },

  methods: {
    trackCheckoutPageEvent() {
      // Check for order confirmation page and data
      if (Object.keys(this.orderConfirmData).length) {
        const orderConfirmationPageEventData = {
          event: 'gaPurchase',
          ecommerce: {
            purchase: {
              currencyCode: this.currencyIso,
              actionField: this.orderConfirmData,
              products: this.trackCartEntries,
            },
          },
        };

        trackEvent(orderConfirmationPageEventData);
        return;
      }

      const checkoutPageEventData = {
        event: 'checkout',
        ecommerce: {
          checkout: {
            currencyCode: this.currencyIso,
            actionField: {step: this.getCurrentStep},
            products: this.trackCartEntries,
          },
        },
      };

      trackEvent(checkoutPageEventData);
    },

    trackDeliveryCheckoutSteps() {
      const deliveryStepData = {
        event: 'checkoutOption',
        ecommerce: {
          checkout_option: {
            actionField: {step: this.getCurrentStep, option: this.selectedDeliveryMode},
          },
        },
      };

      trackEvent(deliveryStepData);
    },

    trackBillingCheckoutSteps() {
      const billingStepData = {
        event: 'checkoutOption',
        ecommerce: {
          checkout_option: {
            actionField: {step: this.getCurrentStep, option: this.cardTypeSelected},
          },
        },
      };

      trackEvent(billingStepData);
    },

    trackAddressCheckoutSteps() {
      const addressStepData = {
        event: 'checkoutOption',
        ecommerce: {
          checkout_option: {
            actionField: {step: this.getCurrentStep, option: this.addressType},
          },
        },
      };

      trackEvent(addressStepData);
    },

    /**
     * Handles payment wallet selections to update cardTypeSelected
     * @param {String} cardValue
     */
    handlePaymentTileSelection(cardValue) {
      this.cardTypeSelected = cardValue;
    },
  },
};

export default trackCheckout;
