<template>
  <div v-if="linkConfig" :class="[defaultClasses, customClass]">
    <pdl-link
      :id="linkSetup.id"
      standalone
      :qaid="`${qaid}-edit-link`"
      :href="linkSetup.href"
      :content="linkSetup.text"
    >
      {{ linkSetup.text }}
    </pdl-link>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {PdlLink} from '@pedal/pdl-link';

export default {
  name: 'CheckoutEditLink',
  components: {PdlLink},

  props: {
    customClass: {
      type: String,
      default: '',
    },
    linkConfig: {
      type: Object,
      default: () => null,
    },
    qaid: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState('backend', ['encodedContextPath']),

    defaultClasses() {
      return {
        'mt-2': !this.customClass,
      };
    },
    linkSetup() {
      return {
        id: this.linkConfig?.id,
        href: `${this.encodedContextPath}${this.linkConfig?.href}`,
        text: this.$t(this.linkConfig?.text),
      };
    },
  },
};
</script>
