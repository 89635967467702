<template>
  <div>
    <pdl-loading top :is-loading="loading">
      <pdl-callout v-if="errorMessage" kind="error">
        <template #content>
          <span v-html="errorMessage" />
        </template>
      </pdl-callout>

      <pdl-callout v-if="discontinuedMessage" kind="warning" is-dismissible>
        <template #content>
          <span v-html="discontinuedMessage" />
        </template>
      </pdl-callout>

      <section class="savedList__meta">
        <!-- Delete list prompt -->
        <pdl-dialog
          :visible.sync="dialogVisible"
          :title="$t('text.account.savedLists.confirmDelete')"
          :show-close="false"
          :close-on-press-escape="false"
          :close-on-click-modal="false"
          :z-index="16000011"
          qaid="delete-saved-list-dialog"
        >
          <template slot="footer">
            <div class="buttons buttons--right">
              <trek-button alert qaid="saved-lists.delete.list.yes" @click="confirmDelete">{{
                $t('text.account.savedLists.yesDelete')
              }}</trek-button>
              <trek-button secondary qaid="saved-lists.delete.list.no" @click="dialogVisible = false">{{
                $t('text.account.savedLists.save')
              }}</trek-button>
            </div>
          </template>
        </pdl-dialog>

        <!-- Delete selected entries prompt -->
        <pdl-dialog
          :visible.sync="dialogVisibleRemove"
          :title="$t('text.account.savedLists.item.confirmDelete')"
          :show-close="false"
          :close-on-press-escape="false"
          :close-on-click-modal="false"
          :z-index="16000011"
          qaid="delete-saved-list-item-dialog"
        >
          <template slot="footer">
            <div class="buttons buttons--right">
              <trek-button alert qaid="saved-lists.item.delete.list.yes" @click="confirmRemove">
                {{ $t('text.account.savedLists.yesDelete') }}
              </trek-button>
              <trek-button secondary qaid="saved-lists.item.delete.list.no" @click="dialogVisibleRemove = false">
                {{ $t('text.account.savedLists.save') }}
              </trek-button>
            </div>
          </template>
        </pdl-dialog>

        <p v-show="selectedList.bikesEntries.length || selectedList.partsEntries.length" class="mb-3 text-base">
          {{ $t('text.account.savedLists.disclaimer.notAllocated') }}
        </p>

        <div
          v-if="!(userLists.length || accountLists.length)"
          id="savedListDisclaimer--noSavedLists"
          class="bg-gray-05 p-3"
        >
          <span>{{ $t('text.account.savedLists.disclaimer.noSavedLists') }}</span>
        </div>

        <div v-if="userLists.length || accountLists.length">
          <!--Select a Saved List-->
          <saved-list-dropdown @list-changed="clearMinQtyErrorsAndChangeList"></saved-list-dropdown>
          <!--Current Saved List Info-->
          <saved-list-meta
            v-if="selectedList.id"
            :list="selectedList"
            :cancel-list-name="$t('text.cancel')"
            :created-by-translation="$t('text.account.savedLists.createdBy')"
          ></saved-list-meta>
        </div>
      </section>

      <!-- Tabs (SKU and Apple Labels) -->
      <pdl-tabs ref="tabs" :value="selectedList.bikesEntries.length ? 'bikes' : 'accessories'">
        <!-- Bikes Tab -->
        <pdl-tab-pane
          v-if="selectedList.bikesEntries.length"
          :key="`${selectedList.bikesEntries.length}-bikes`"
          name="bikes"
        >
          <h3 slot="label" class="heading heading--md m-0">
            {{ $t('text.bikes') }} ({{ !selectedList.bikesEntries ? 0 : selectedList.bikesEntries.length }})
          </h3>

          <saved-list-grid
            :key="changeCounter"
            :disable-interactions="disableInteractions"
            :disable-actions="disableActions"
            :list="selectedList.bikesEntries"
            :min-qty-move-failed-list="minQtyFailList"
            :moving-to-cart="disableMoveToCartBtn"
            entries-name="bikesEntries"
            @update-sort-type="updateSortList"
            @grid-move-to-cart="moveToCart"
            @grid-remove="onRemove"
            @grid-delete="onDelete"
          >
            <template #summary>
              <saved-list-summary :list="selectedList" />
            </template>
          </saved-list-grid>
        </pdl-tab-pane>

        <!-- Parts/Accessories Tab -->
        <pdl-tab-pane
          v-if="selectedList.partsEntries.length"
          :key="`${selectedList.partsEntries.length}-accessories`"
          name="accessories"
        >
          <h3 slot="label" class="heading heading--md m-0">
            {{ $t('text.accessories') }} ({{ !selectedList.partsEntries ? 0 : selectedList.partsEntries.length }})
          </h3>

          <saved-list-grid
            :key="changeCounter"
            :disable-interactions="disableInteractions"
            :disable-actions="disableActions"
            :list="selectedList.partsEntries"
            :min-qty-move-failed-list="minQtyFailList"
            :moving-to-cart="disableMoveToCartBtn"
            entries-name="partsEntries"
            @update-sort-type="updateSortList"
            @grid-move-to-cart="moveToCart"
            @grid-remove="onRemove"
            @grid-delete="onDelete"
          >
            <template #summary>
              <saved-list-summary :list="selectedList" />
            </template>
          </saved-list-grid>
        </pdl-tab-pane>
      </pdl-tabs>

      <!-- Needed for scenario where there's a list with no entries, in case user wants to delete it -->
      <div
        v-if="
          (userLists.length || accountLists.length) &&
          !selectedList.partsEntries.length &&
          !selectedList.bikesEntries.length
        "
        class="w-full lg:w-auto lg:grow"
      >
        <div class="buttons buttons--right-for-md">
          <trek-button
            v-if="!selectedList.readOnly && !disableInteractions"
            tertiary
            qaid="saved-lists.button-delete-secondary"
            :disabled="disableInteractions"
            @click="onDelete"
            >{{ $t('text.account.savedLists.delete') }}</trek-button
          >
        </div>
      </div>
    </pdl-loading>

    <pdl-dialog
      :visible.sync="dialogVisibleOtherVendor"
      :z-index="16000011"
      :close-dialog-tool-tip="$t('popup.close')"
      qaid="move-to-cart-error-dialog"
      :title="$t('distributor.B2B.unableMoveToCart')"
      @close="dialogVisibleOtherVendor = false"
    >
      <div>
        <p class="text-left mb-3" qaid="saved-lists.otherVendorDescription">
          {{ $t('distributor.B2B.notSoldByVendor') }}
        </p>

        <div class="b2b-scroll-list-container w-full">
          <div class="b2b-scroll-list">
            <table class="b2b-grid">
              <thead class="b2b-grid__header">
                <tr>
                  <th class="b2b-grid__cell b2b-grid__head" qaid="saved-lists.otherVendorSkuHeading">
                    {{ $t('text.account.savedLists.sku') }}
                  </th>
                  <th class="b2b-grid__cell b2b-grid__head" qaid="saved-lists.otherVendorNameHeading">
                    {{ $t('text.account.savedLists.description') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in otherVendorItems" :key="index">
                  <td class="b2b-grid__cell table-cell" :qaid="`saved-lists.otherVendorSkuRow-${index}`">
                    {{ item.sku }}
                  </td>
                  <td class="b2b-grid__cell table-cell" :qaid="`saved-lists.otherVendorNameRow-${index}`">
                    {{ item.itemName }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <template slot="footer">
        <div class="buttons buttons--right">
          <trek-button primary qaid="saved-lists.otherVendorBackButton" @click="dialogVisibleOtherVendor = false"
            ><span v-html="$t('navigation.back')"></span
          ></trek-button>
        </div>
      </template>
    </pdl-dialog>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {axiosToast, getToast} from '../../utils/axios';
import {mapState, mapActions, mapMutations} from 'vuex';
import Vue from 'vue';
import first from 'lodash/first';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlDialog} from '@pedal/pdl-dialog';
import TrekButton from '@/components/TrekButton';
import SavedListMeta from '@/components/saved-lists/SavedListMeta';
import SavedListGrid from '@/components/saved-lists/SavedListGrid';
import SavedListSummary from '@/components/saved-lists/SavedListSummary';
import SavedListDropdown from '@/components/saved-lists/SavedListDropdown';
import {checkMetaFeedbackType, MetaFeedbackType, resolveToastType} from '@/constants/meta-feedback-type';

export default {
  components: {
    PdlTabs,
    PdlTabPane,
    PdlCallout,
    PdlLoading,
    PdlDialog,
    TrekButton,
    SavedListMeta,
    SavedListGrid,
    SavedListSummary,
    SavedListDropdown,
  },

  data() {
    return {
      dialogVisible: false,
      dialogVisibleRemove: false,
      dialogVisibleOtherVendor: false,
      loading: true,
      initDone: false,
      lists: [],
      otherVendorItems: [],
      minQtyFailList: {},
      changeCounter: 0,
      disableMoveToCartBtn: false,
    };
  },

  computed: {
    disableInteractions() {
      return this.selectedList.readOnly;
    },

    disableActions() {
      return (
        this.selectedList.readOnly ||
        (!this.selectedList.bikesEntries.find((line) => line.checked) &&
          !this.selectedList.partsEntries.find((line) => line.checked))
      );
    },

    ...mapState('savedLists', ['userLists', 'accountLists', 'selectedList', 'errorMessage', 'discontinuedMessage']),
    ...mapState('backend', ['isB2BPurchasable']),
  },

  watch: {
    accountLists() {
      this.onListsChanged();
    },
    userLists() {
      this.onListsChanged();
    },
  },

  created() {
    this.fetchAllLists();
  },

  methods: {
    onListsChanged() {
      if (!this.initDone) {
        // Init lists when data has been loaded
        const selectedList = [...this.userLists, ...this.accountLists][0];

        if (selectedList) {
          let id = selectedList.id;
          let sortKey = 'savedlistdateadded';
          this.fetchListSort({id, sortKey});
        }
        this.loading = false;
        this.initDone = true;
      }
    },

    updateSortList(sortKey) {
      let id = this.selectedList.id;
      this.fetchListSort({id, sortKey});
    },

    changeList(id) {
      if (id) {
        this.loading = true;
        this.fetchList({id: id}).finally(() => (this.loading = false));
      } else {
        this.clearSelectedList();
      }
    },

    clearMinQtyErrorsAndChangeList(id) {
      // this counter var is used as key to trick Vue to trigger a form refresh to remove form
      // errors from previous list since forceUpdate is not working as expected
      this.changeCounter++;
      this.minQtyFailList = {};
      this.changeList(id);
    },

    getCheckedEntries() {
      const {bikesEntries, partsEntries} = this.selectedList;
      const entries = [...bikesEntries, ...partsEntries].reduce((acc, entry) => {
        if (entry.checked) {
          acc.push({
            sku: entry.sku,
            qty: parseInt(entry.orderedQty, 10),
            checked: true,
            lineNote: entry.lineNote?.length > 0 ? entry.lineNote : null,
          });
        }

        return acc;
      }, []);

      return entries;
    },

    onDelete() {
      this.dialogVisible = true;
    },

    onRemove() {
      this.dialogVisibleRemove = true;
    },

    closeDialog() {
      this.dialogVisible = false;
    },

    closeDialogRemove() {
      this.dialogVisibleRemove = false;
    },

    confirmDelete() {
      this.loading = true;
      this.deleteList(this.selectedList.id)
        .then(() => {
          const newSelected = first([...this.userLists, ...this.accountLists]);
          const newId = newSelected ? newSelected.id : null;

          this.changeList(newId);
        })
        .finally(() => {
          this.dialogVisible = false;
          this.loading = false;
        });
    },

    undoMoveToCart(id, entries) {
      this.loading = true;

      storefrontInstance
        .delete(`/cart/add/${id}`, {data: {entries}, toast: false})
        .then(() => {
          return this.updateSelectedEntries({id, entries}).then(() => {
            this.changeList(id);
            this.fetchMiniCart();
          });
        })
        .finally(() => (this.loading = false));
    },

    async moveToCart() {
      this.disableMoveToCartBtn = true;

      this.minQtyFailList = {};

      const {id} = this.selectedList;
      const entries = this.getCheckedEntries();
      try {
        const {data} = await storefrontInstance.put(`/cart/add/${id}`, {entries}, {toast: false});

        let failedSkus = (data?.data?.failedSKUs ?? []).map((item) => item.sku);
        let failedMessages = (data?.data?.failedSKUs ?? []).map((item) => item.message.basePropertyValue);

        for (let i = 0; i < failedSkus.length; i++) {
          Vue.set(this.minQtyFailList, failedSkus[i], failedMessages[i]);
        }

        const {type, message, toastDuration} = getToast(data.meta.feedback);
        if (checkMetaFeedbackType(data, MetaFeedbackType.ERROR)) {
          const messageKey = data?.meta?.feedback?.message?.basePropertyKey;

          if (messageKey === 'distributor.B2B.advancedOrder.error.cannotAdd') {
            const items = data?.data?.cartEntries;

            if (items?.length) {
              const nonAOItems = items.map((item) => ({sku: item.sku, itemName: item.itemName}));

              this.setDisplayAddErrorModal(true);
              this.setNonAOItems(nonAOItems);
            }
          } else if (messageKey === 'distributor.B2B.notSoldByVendor') {
            const items = data?.data?.cartEntries;

            if (items?.length) {
              this.otherVendorItems = items.map((item) => ({sku: item.sku, itemName: item.itemName}));
              this.dialogVisibleOtherVendor = true;
            }
          } else {
            if (!failedSkus?.length) {
              this.$notify({
                type: resolveToastType(type),
                message,
                duration: toastDuration,
                showClose: true,
                link: data.meta.feedback.link,
              });
            }
          }
        } else {
          this.$notify({
            type: resolveToastType(type),
            message,
            actionClickText: this.$t('text.undo'),
            actionClick: () => this.undoMoveToCart(id, entries),
          });
        }
      } catch ({response}) {
        axiosToast(response);
      } finally {
        this.changeList(this.selectedList.id);

        this.fetchMiniCart();

        this.$refs.tabs.setCurrentName(this.selectedList.bikesEntries.length ? 'bikes' : 'accessories');

        this.loading = false;
        this.disableMoveToCartBtn = false;
      }
    },

    confirmRemove() {
      this.loading = true;

      const entries = this.getCheckedEntries().map((entry) => entry.sku);
      this.dialogVisibleRemove = false;

      this.removeSelectedEntries(entries)
        .then(() => {
          this.changeList(this.selectedList.id);
          this.$refs.tabs.setCurrentName(this.selectedList.bikesEntries.length ? 'bikes' : 'accessories');
        })
        .finally(() => (this.loading = false));
    },

    ...mapMutations('advancedOrdering', ['setNonAOItems', 'setDisplayAddErrorModal']),
    ...mapActions('savedLists', [
      'fetchAllLists',
      'fetchList',
      'fetchListSort',
      'deleteList',
      'removeSelectedEntries',
      'updateSelectedEntries',
      'clearSelectedList',
    ]),
    ...mapActions('miniCart', ['fetchMiniCart']),
  },
};
</script>
