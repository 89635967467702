<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import {useVuelidate} from '@vuelidate/core';
import AlabamaTaxIconDialog from '@/components/cart/AlabamaTaxIconDialog.vue';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
import ImageComponent from '@/components/ImageComponent.vue';
import KcpPaymentForm from '@/components/checkout/providers/kcp/KcpPaymentForm.vue';
import CreditCardPaymentOption from '@/components/checkout/providers/credit-card/CreditCardPaymentOption.vue';
import IdealPaymentOption from '@/components/checkout/providers/ideal/IdealPaymentOption.vue';
import ApplePayPaymentOption from '@/components/checkout/providers/apple-pay/ApplePayPaymentOption.vue';
import DeliveryInformationForm from '@/components/checkout/forms/delivery/DeliveryInformationForm.vue';
import BillingAddressForm from '@/components/checkout/forms/billing/BillingAddressForm.vue';
import PaymentOptionContainer from '@/components/checkout/b2c/PaymentOptionContainer.vue';
import CheckoutSubmitButton from '@/components/checkout/fields/CheckoutSubmitButton.vue';
import OrderSummaryAside from '@/components/checkout/order-summary-aside';
import ChooseDeliveryMethodContainer from '@/components/checkout/b2c/dealers/ChooseDeliveryMethodContainer.vue';
import PaymentIconList from '@/components/PaymentIconList.vue';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlHeading} from '@pedal/pdl-section-header';
import {scrollToElement, scrollToTop} from '@/utils/scroll-to.js';
import trackCheckout from '@/mixins/tracking/track-checkout.js';
import {useApplePaySdk} from '@/composables/apple-pay-sdk.js';
import DeliveryDetails from '../DeliveryDetails.vue';
import {PaymentProvider} from '@/constants/payment-provider.js';
import PaymentInitErrors from '@/components/PaymentInitErrors.vue';
import {PdlToastType} from '@/constants/pdl-toast-type';

const BILLING_DETAILS_EVENT = 'billingDetailsError';
const parseBillingErrorKey = (key) => {
  const billingErrorPrefix = 'billingAddress.';
  if (!key.includes(billingErrorPrefix)) return key;
  return key.split(billingErrorPrefix)?.[1] ?? key;
};

export default {
  name: 'CheckoutMultiStepContainer',
  components: {
    AlabamaTaxIconDialog,
    ApplePayPaymentOption,
    BillingAddressForm,
    CreditCardPaymentOption,
    DeliveryDetails,
    DeliveryInformationForm,
    IdealPaymentOption,
    ImageComponent,
    KcpPaymentForm,
    OrderSummaryAside,
    PaymentOptionContainer,
    CheckoutSubmitButton,
    ChooseDeliveryMethodContainer,
    PdlButton,
    PdlLink,
    PdlCallout,
    PdlHeading,
    PaymentIconList,
    PaymentInitErrors,
  },

  mixins: [trackCheckout],
  provide() {
    return {
      validationErrors: this.validationErrors,
      setValidationErrors: this.setValidationErrors,
      clearValidationErrors: this.clearValidationErrors,
      handleCheckoutSubmitButtonAction: this.handleCheckoutSubmitButtonAction,
    };
  },

  props: {
    cartData: {
      type: Array,
      default: () => null,
    },
    cartDataFull: {
      type: Object,
      default: () => null,
    },
    emailAddress: {
      type: String,
      default: '',
    },
  },

  setup() {
    useApplePaySdk();
    const v$ = useVuelidate();
    return {v$};
  },

  data() {
    return {
      validationErrors: [],
    };
  },

  computed: {
    ...mapState('checkout', [
      'selectedDeliveryMode',
      'errorMessages',
      'selectedPaymentProvider',
      'gr4vyFormValidations',
      'continueShoppingUrl',
    ]),
  },

  watch: {
    errorMessages(value) {
      if (!value) return;
      scrollToTop();
    },
  },

  created() {
    // Sets the entries to the checkout store for Multi steps
    this.setupCartData();
  },

  mounted() {
    document.addEventListener(BILLING_DETAILS_EVENT, this.handleBillingErrors);
    this.initializeSelectedAddressCode();
  },
  destroyed() {
    document.removeEventListener(BILLING_DETAILS_EVENT, this.handleBillingErrors);
  },

  methods: {
    ...mapMutations('checkout', [
      'setB2cCartData',
      'setB2cCartDataFull',
      'setIsGr4vyFieldValid',
      'setSelectedAddressCode',
    ]),
    ...mapActions('user', ['setSelectedLocationFromPostcode']),
    ...mapActions('checkout', ['initializePayment', 'getIsGr4vyPaymentFormValid']),
    /**
     * Handles setting the entries into the checkout store.
     */
    setupCartData() {
      if (!this.cartData) return;

      this.setB2cCartData(this.cartData);
      this.setB2cCartDataFull(this.cartDataFull);
    },

    async handleCheckoutSubmitButtonAction() {
      this.trackBillingCheckoutSteps();

      const isValid = await this.v$.$validate();
      if (!isValid) {
        scrollToElement('.has-error', 20);
        return;
      }
      if (this.selectedPaymentProvider?.value === PaymentProvider.GR4VY) {
        const isGr4vyValid = await this.handleGr4vyValidation();
        if (!isGr4vyValid) return;
      }
      this.initializePayment();
    },
    async handleGr4vyValidation() {
      const isGr4vyPaymentFormValid = await this.getIsGr4vyPaymentFormValid();
      if (!isGr4vyPaymentFormValid) {
        this.$notify({
          type: PdlToastType.ERROR,
          message: this.$t('text.allFieldsAreRequired'),
          showClose: true,
        });
      }
      if (isGr4vyPaymentFormValid) return true;
      Object.keys(this.gr4vyFormValidations).forEach((field) => {
        if (this.gr4vyFormValidations[field].valid === null) {
          this.setIsGr4vyFieldValid({id: field, valid: false, dirty: true});
        }
      });
      scrollToTop();
      return false;
    },
    handleBillingErrors(event) {
      if (!event.detail) return;
      this.setValidationErrors(event.detail);
    },
    clearValidationErrors() {
      this.validationErrors.splice(0);
    },
    setValidationErrors(errorFields) {
      if (!errorFields) return;
      Object.entries(errorFields).forEach(([key, value]) => {
        const parsedKey = parseBillingErrorKey(key);

        const error = {
          field: parsedKey,
          msg: value,
        };

        this.validationErrors.push(error);
      });
    },
    initializeSelectedAddressCode() {
      this.updateSelectedAddressCode();
      document.addEventListener('selectedAddressCode', this.updateSelectedAddressCode);
    },
    setFirstAddressCodeAsSelected() {
      const firstOption = document.querySelector('.form__retailer-option');
      firstOption?.classList?.add('selected');
      let radio = firstOption?.querySelector('input[data-options-check]');
      if (radio) {
        radio.checked = true;
      }
    },
    updateSelectedAddressCode() {
      let selectedOption = document.querySelector('.form__retailer-option.selected');
      if (!selectedOption) {
        this.setFirstAddressCodeAsSelected();
        selectedOption = document.querySelector('.form__retailer-option.selected');
      }
      const addressCode = selectedOption?.querySelector('input#deliveryAddressCode')?.value;
      const postCode = selectedOption?.querySelector('input.postalCode')?.value;
      if (addressCode) {
        this.setSelectedAddressCode(addressCode);
      }
      if (postCode) {
        this.setSelectedLocationFromPostcode(postCode);
      }
    },
  },
  render: () => null,
};
</script>
