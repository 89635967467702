<template>
  <div class="mt-5">
    <table class="b2b-grid b2b-grid--collapse is-compact">
      <caption class="sr-only">
        {{
          $t('order.orderItems')
        }}
      </caption>
      <!-- SKU, QTY Grid Headers -->
      <thead class="b2b-grid__header">
        <tr>
          <th id="checkbox" class="b2b-grid__cell b2b-grid__head w-4">
            <span
              role="button"
              tabindex="0"
              qaid="sku-grid-checkbox"
              @click.prevent.stop="checkAll"
              @keyup.enter.prevent.stop="checkAll"
            >
              <grid-checkbox :label="'baseProduct.baseProduct'" :checked="isAllChecked" />
            </span>
          </th>
          <th id="sku" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid-sku">
            {{ $t('text.account.savedLists.sku') }}
          </th>
          <th id="description" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid-description">
            {{ $t('productCompare.description') }}
          </th>
          <!-- Variable warehouse headers -->
          <th
            v-for="(warehouse, warehouseKey) in warehouseList"
            id="warehouse"
            :key="`warehouse-${warehouseKey}`"
            class="b2b-grid__cell b2b-grid__head text-right"
            qaid="sku-grid-warehouse"
            v-html="warehouse"
          />
          <th
            v-if="hasWarehouses(warehouseList)"
            id="stock-watch"
            class="b2b-grid__cell b2b-grid__head text-center"
            qaid="sku-grid-stock-watch"
          >
            {{ $t('text.account.savedLists.stockWatch') }}
          </th>
          <th id="price" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid-order-price">{{ $t('order.price') }}</th>
          <th id="qty" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid-qty">
            {{ $t('text.account.savedLists.qty') }}
          </th>
          <th id="subtotal" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid-subtotal">
            {{ $t('text.order.subtotal') }}
          </th>
        </tr>
      </thead>

      <!-- SKU/QTY Grid Body/Rows -->
      <tbody>
        <!-- For each Product/Row -->
        <check-skus-grid-item
          v-for="product in paginatedData"
          :key="product.code"
          :locale="locale"
          :product="product"
          :checkbox-list="checkboxList"
          :currency-iso-code="currencyISOCode"
          :warehouse-codes="warehouseList"
          @update-check-all="updateCheckAll"
          @remove-from-checkbox-list="removeFromCheckboxList"
          @add-to-checkbox-list="addToCheckboxList"
          @update-variant-quantity="updateVariantQty"
          @update-stock-watch-state="(payload) => $emit('update-stock-watch-state', payload)"
        />
      </tbody>
    </table>

    <!-- SKU Grid footer buttons (move to list/add to cart) -->
    <div class="buttons buttons--right-for-md">
      <trek-button
        primary
        small
        icon="shopping_cart"
        :disabled="isButtonDisabled"
        qaid="check-skus-add-to-cart"
        @click="addSkusToCart"
      >
        {{ $t('text.addToCart') }}
      </trek-button>
      <trek-button
        id="saved-lists-button-accessories"
        v-tippy="{
          html: '#saved-lists-panel',
          interactive: true,
          reactive: true,
          trigger: 'click',
          flip: true,
          sticky: true,
        }"
        small
        secondary
        icon="list"
        qaid="check-skus-save-to-list"
        :disabled="isButtonDisabled"
        @click="updateSavedList"
      >
        {{ $t('text.buyingZone.addToSavedList') }}
      </trek-button>
      <saved-lists-panel
        id="saved-lists-panel"
        :text-undo="$t('text.undo')"
        :text-my-lists="$t('text.account.savedLists.myLists')"
        :text-max-chars="$t('text.buyingZone.B2B.max40Char')"
        :text-other-lists="$t('text.account.savedLists.otherLists')"
        :text-moved-to-list="$t('text.buyingZone.B2B.moveToList')"
        qaid-panel="saved-lists-panel"
        qaid-title="saved-lists-panel-title"
        qaid-list="saved-lists-panel-list"
        qaid-list-item="saved-lists-panel-list-item"
        btn-id="saved-lists-button-accessories"
        @undo="undoRemove"
        @submit="removeProducts"
      />
    </div>

    <div class="flex flex-wrap mb-1 pb-5">
      <div class="w-full lg:w-auto lg:shrink self-center text-center pb-5">
        <pagination-controls
          v-if="pageCount > 1"
          ref="pagination"
          :page-number="pageNumber"
          :page-count="pageCount"
          @page-change="pageChange"
          @prev-page="prevPage"
          @next-page="nextPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';
import pagination from '@/mixins/pagination';
import PaginationControls from '@/components/PaginationControls';
import TrekButton from '@/components/TrekButton';
import CheckSkusGridItem from './CheckSkusGridItem';
import GridCheckbox from '@/components/grid/GridCheckbox';
import SavedListsPanel from '@/components/saved-lists/SavedListsPanel';
import warehouseMixin from '@/mixins/warehouse';
import {PdlToastType} from '@/constants/pdl-toast-type';

export default {
  name: 'CheckSkuskGrid',

  components: {TrekButton, GridCheckbox, CheckSkusGridItem, SavedListsPanel, PaginationControls},

  mixins: [pagination, warehouseMixin],

  props: {
    products: {
      type: Array,
      default: () => [],
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: 50,
    },
  },

  data() {
    return {
      checkboxList: [],
      isAllChecked: false,
      locale: '',
      currencyISOCode: '',
    };
  },

  computed: {
    isButtonDisabled() {
      return this.checkboxList.length === 0;
    },

    listData() {
      return this.products;
    },

    // Returns a list of Warehouse Header/Label names based on the products returned
    // Assumes that all products have the same warehouses in the same order
    warehouseList() {
      return this.getUniqueWarehouseCodesFromAllProductVariants(this.products);
    },

    ...mapState('miniCart', ['bikes', 'parts']),
    ...mapState('skuGrid', ['skuNotifyErrorToast']),
  },

  watch: {
    checkboxList() {
      if (this.checkboxList.length === 0) {
        this.isAllChecked = false;
      }
    },

    'bikes.quantity': function () {
      this.removeAddedToCartCheckboxes();
    },

    'parts.quantity': function () {
      this.removeAddedToCartCheckboxes();
    },

    listData() {
      this.pageNumber = 0;
    },
    skuNotifyErrorToast(newValue) {
      if (newValue) {
        this.$notify({
          type: PdlToastType.ERROR,
          showClose: true,
          duration: 0,
          message: newValue,
        });
        this.setSkuNotifyErrorToast('');
      }
    },
  },

  created() {
    this.locale = window.location.pathname.split('/')[3].replace('_', '-');
    this.currencyISOCode = document.querySelector('#page').getAttribute('data-currency-iso-code');
  },

  methods: {
    removeAddedToCartCheckboxes() {
      const removeCheckboxes = this.checkboxList.filter((checkbox) => checkbox.isAllowedAddToCart === true);
      this.checkboxList = []; // uncheck all remained checkboxes(it will be all checkboxes that can't be added to cart)
      this.$emit('after-add-to-cart', removeCheckboxes);
    },

    addSkusToCart() {
      this.addBaseSkuToCart();
    },

    updateVariantQty(payload) {
      this.updateCheckboxList(payload);
      this.updateVariantQuantity({
        sku: payload.sku || payload.code,
        qty: payload.qty,
        baseSku: payload.sku || payload.code,
        callback: () => '',
      });

      this.$emit('update-product-quantity', payload);
    },

    addToCheckboxList({sku, isAllowedAddToCart}) {
      if (!this.checkboxList.some((item) => item.sku === sku)) {
        this.checkboxList.push({sku, isAllowedAddToCart});
      }
    },

    undoRemove() {
      this.$emit('undo-saved-lists');
    },

    removeProducts() {
      this.$emit('on-added-products', this.checkboxList);
      this.checkboxList = [];
      this.isAllChecked = false;
    },

    updateSavedList() {
      this.setEntries(this.checkboxList);
    },

    updateCheckboxList(selectedProduct) {
      this.checkboxList = this.checkboxList.map((product) => {
        if (product.sku === selectedProduct.sku) {
          return {
            ...product,
            qty: selectedProduct.qty,
          };
        }
        return product;
      });
      this.$emit('update-variant-quantity', selectedProduct);
    },

    removeFromCheckboxList(selectedProduct) {
      if (this.checkboxList.some((item) => item.sku === selectedProduct.sku)) {
        this.checkboxList = this.checkboxList.filter((item) => item.sku !== selectedProduct.sku);
      }
    },

    checkAll() {
      this.isAllChecked = !this.isAllChecked;
      if (this.isAllChecked) {
        this.checkboxList = [];
        this.products.forEach((item) => {
          this.checkboxList.push({
            sku: item.code,
            isAllowedAddToCart: item.isAllowedAddToCart,
          });
        });
      } else {
        this.checkboxList = [];
      }
    },

    updateCheckAll() {
      this.isAllChecked = this.checkboxList.length === this.products.length;
    },

    ...mapActions('skuGrid', ['addBaseSkuToCart']),
    ...mapMutations('skuGrid', ['updateVariantQuantity', 'setSkuNotifyErrorToast']),
    ...mapMutations('savedLists', ['setEntries']),
  },
};
</script>
