import LocatorFilters from '@/components/locator/components/LocatorFilters';
import isEmpty from 'lodash/isEmpty';

const logoFilters = ['trek', 'electra'];
const filtersMap = {
  distance: false,
  toprated: 'storeFinderTopRated',
  bestSelection: 'storeFinderBestSelection',
  popular: 'storeFinderPopular',
};

const Filters = {
  props: {
    filterableServiceCodes: {
      type: Array,
      default() {
        return [];
      },
    },
    filterParams: Array,
  },
  components: {LocatorFilters},
  methods: {
    /**
     * Close filters panel.
     */
    closeFilters() {
      this.isFiltersPanelVisible = false;
    },

    /**
     * Open filters panel.
     */
    openFilters() {
      this.isFiltersPanelVisible = true;
      this.$nextTick(() => {
        // Moves focus to the filters panel, which removes focus from the Open Filters button
        this.$refs.locatorFilters && this.$refs.locatorFilters.$el.focus();
      });
    },

    /**
     * Apply filters selection.
     * @param filters
     */
    applyFilters(filters) {
      this.filters = filters;
      this.loadData(0, false, true);
    },

    /**
     * Remove a filter.
     * @param filter
     */
    removeFilter(filter) {
      if (!this.filters || !Object.keys(this.filters).length) {
        return;
      }

      if (filter.type) {
        if (!(filter.type in this.filters)) {
          return;
        }

        let filters = this.filters[filter.type].split(',');
        const index = filters.indexOf(filter.id);
        if (index > -1) {
          filters.splice(index, 1);
        }

        this.$set(this.filters, filter.type, filters.join(','));
      } else {
        for (let item in this.filters) {
          if (item === filter.id) {
            this.$set(this.filters, item, false);
          }
        }
      }

      this.loadData(0, false, true);
    },

    getVisibleFilters(items, dataSource, type) {
      if (!items || !items.length || isEmpty(this[dataSource])) {
        return [];
      }

      let result = [];
      items.forEach((item) => {
        const filter = this[dataSource].find((filter) => filter.code === item);
        if (filter && filter.title) {
          result.push({
            type,
            name: filter.title,
            id: filter.code,
          });
        }
      });

      return result;
    },
  },
  computed: {
    allowedServiceFilters() {
      if (this.serviceFilters && this.serviceFilters.length && this.filterableServiceCodes.length) {
        return this.serviceFilters.filter((serviceFilter) => {
          return this.filterableServiceCodes.indexOf(serviceFilter.code) >= 0;
        });
      } else {
        return this.serviceFilters;
      }
    },

    currentFiltersList() {
      if (!this.filters || !Object.keys(this.filters).length) {
        return [];
      }

      let filters = [];
      let commaSeparatedFilters = {
        services: 'allowedServiceFilters',
        sales: 'saleFilters',
      };

      for (const filter in this.filters) {
        if (filter in filtersMap && !filtersMap[filter]) {
          continue;
        }

        if (Object.keys(commaSeparatedFilters).indexOf(filter) > -1) {
          filters = filters.concat(
            this.getVisibleFilters(this.filters[filter].split(','), commaSeparatedFilters[filter], filter)
          );
        } else if (this.filters[filter]) {
          if (logoFilters.indexOf(filter) > -1) {
            filters.push({
              hasLogo: true,
              name: filter,
              id: filter,
            });
          } else {
            filters.push({
              name: filter in filtersMap ? this.bp(filtersMap[filter]) : filter,
              id: filter,
            });
          }
        }
      }
      return filters;
    },
  },
  data() {
    return {
      isFiltersPanelVisible: false,
      filters: {},
    };
  },
};

export {Filters};
