'use strict';

export default function () {
  $('#communicationLanguageSelect').change(function (e) {
    e.preventDefault();
    updateCommunicationLanguage(this.value);
  });

  function updateCommunicationLanguage(communicationLanguageISO) {
    var postData = {languageISO: communicationLanguageISO};
    $.ajax({
      url: $('#communicationLanguageRow').data('url'),
      data: postData,
      type: 'GET',
      dataType: 'json',
      error: function () {
        $('#communicationLanguageSelect').css('border-color', 'red');
      },
      success: function (data) {
        if (data.RESULT === 'OK') {
          $('#communicationLanguageSelect').css('border-color', 'green');
        } else {
          $('#communicationLanguageSelect').css('border-color', 'red');
        }
      },
    });
  }

  function updateDistanceUnits(distanceUnit) {
    $.ajax({
      url: `${ACC.config.encodedContextPath}/customer/profile/`,
      type: 'GET',
      error: function () {
        console.error('error');
      },
      success: function (responseData) {
        const setElementText = (selector, text) => $(selector).text(text);

        if (responseData.data.heightInteger) {
          setElementText('.profile__definition--feet', responseData.data.heightInteger);
        } else {
          setElementText('.profile__definition--feet', '\xa0');
        }

        if (responseData.data.heightRemainder) {
          setElementText('.profile__definition--inches', responseData.data.heightRemainder);
        } else {
          setElementText('.profile__definition--inches', '\xa0');
        }

        if (responseData.data.weight) {
          setElementText('.profile__definition--weight', responseData.data.weight);
        } else {
          setElementText('.profile__definition--weight', '\xa0');
        }

        if (distanceUnit === 'MILES') {
          setElementText('.profile__distance-unit--feet', window.feet);
          setElementText('.profile__distance-unit--inches', window.inches);
          setElementText('.profile__distance-unit--weight', window.lbs);
        } else {
          setElementText('.profile__distance-unit--feet', window.meters);
          setElementText('.profile__distance-unit--inches', window.centimeters);
          setElementText('.profile__distance-unit--weight', window.kg);
        }
      },
    });
  }

  $('#garageDistanceUnitSelect').change(function (e) {
    e.preventDefault();
    updateGarageDistanceUnit(this.value);
  });

  function updateGarageDistanceUnit(garageDistanceUnit) {
    var postData = {garageDistanceUnit: garageDistanceUnit};
    $.ajax({
      url: $('#garageDistanceUnitRow').data('url'),
      data: postData,
      type: 'POST',
      dataType: 'json',
      error: function () {
        $('#garageDistanceUnitSelect').css('border-color', 'red');
      },
      success: function (data) {
        if (data.RESULT === 'OK') {
          $('#garageDistanceUnitSelect').css('border-color', 'green');
          updateDistanceUnits(data.DISTANCEUNIT);
        } else {
          $('#garageDistanceUnitSelect').css('border-color', 'red');
        }
      },
    });
  }

  $('#category').change(function () {
    populateModels();
  });
  function populateModels() {
    var models = document.getElementById('models');
    models.disabled = true;
    var cat = document.getElementById('category').value;
    $('#models').empty();
    $('#models').append($('<option></option>').val('').html(document.getElementById('localisedSelect').value));
    if (cat != undefined && cat != null) {
      $.ajax({
        url: '/' + $('#currentRegion').val() + '/' + $('#currentLocale').val() + '/my-account/models/' + cat,
        headers: {Accept: 'text/html,application/json'},
        success: function (result) {
          $.each(result, function (i, p) {
            $('#models').append($('<option></option>').val(p.value).html(p.key));
          });
          var models = document.getElementById('models');
          models.disabled = false;
        },
      });
    }
  }

  $(document).ready(function () {
    if ($('#models option:selected').val() !== undefined && $('#models option:selected').val() !== '') {
      var models = document.getElementById('models');
      models.disabled = false;
    }
  });
}
