<template>
  <div>
    <pdl-dialog
      fullscreen
      :z-index="16000020"
      :visible.sync="dialogVisible"
      qaid="spectrum-customizer-overlay-container"
      @close="closeSpectrumCustomizer"
    >
      <div id="spectrum-customizer" />
    </pdl-dialog>
    <pdp-add-to-cart-status :message="cartSuccessMessage" :is-error="false" :options="options" />
  </div>
</template>

<script>
import PdpAddToCartStatus from '@/components/containers/pdp/PdpAddToCartStatus';
import {AddToCart} from '@/components/containers/pdp/utils/add-to-cart';
import {PdlDialog} from '@pedal/pdl-dialog';
import storefrontInstance from '@/api/instances/storefront';

export default {
  components: {
    PdlDialog,
    PdpAddToCartStatus,
  },
  mixins: [AddToCart],
  props: {
    spectrumUrl: {
      type: String,
      default: '',
    },
    customProductStartingPoint: {
      type: String,
      default: '',
    },
    hasSpectrumOpen: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: true,
    },
    successMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    hasSpectrumOpen(value) {
      this.dialogVisible = value;
      if (value) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
  },
  created() {
    this.appendSpectrumScript();
  },
  mounted() {
    this.$nextTick(() => {
      this.addSpectrumFunctionality(this.$parent.$refs.spectrumCustomizerOverlay);
    });
  },
  methods: {
    appendSpectrumScript() {
      const existingScript = document.querySelector(`script[src='${this.spectrumUrl}]`);
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = `${this.spectrumUrl}`;
        script.id = 'spectrumUrl';
        document.head.appendChild(script);
      }
    },
    closeSpectrumCustomizer() {
      this.$emit('close-spectrum-customizer', false);
    },
    async saveDesign(payload) {
      await storefrontInstance.post('/custom-product/saveSpectrum', payload).catch((error) => {
        console.error(error);
      });
    },
    addSpectrumFunctionality(componentReference) {
      window.spectrumLoadProduct = this.customProductStartingPoint;
      window.spectrumAddToCart = function spectrumAddToCart(payload) {
        return new Promise(function (resolve) {
          componentReference.closeSpectrumCustomizer();
          componentReference.addToCartCustom(payload);
          resolve();
        });
      };
      window.spectrumSaveDesign = function spectrumSaveDesign(payload) {
        return new Promise(function (resolve) {
          componentReference.saveDesign(payload);
          resolve();
        });
      };

      window.spectrumItemPricesAndAvailabilityCallback = function spectrumItemPricesAndAvailabilityCallback(skus) {
        return new Promise(function (resolve) {
          const data = skus.map((s) => {
            return {
              sku: s,
              name: 'Example',
              type: 'baseProduct',
              price: 99.99,
              listPrice: null, // nullable list price to distinguish from sale price
              isoCurrency: 'USD', // must be a valid ISO 4217 currency code
              inStock: true,
              options: null, // nullable options object with string values
            };
          });
          resolve(data);
        });
      };
    },
  },
};
</script>
