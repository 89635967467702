<template>
  <section v-if="!isRetailerLoading" :key="updateKey" class="pb-3 pt-1">
    <postal-code-drawer :is-drawer-open="isPostalCodeDrawerOpen" @close="isPostalCodeDrawerOpen = false" />
    <retailer-drawer :is-drawer-open="isRetailerDrawerOpen" @close="isRetailerDrawerOpen = false" />
    <div class="grid md:grid-cols-2 gap-8 md:gap-6">
      <div>
        <pdl-label qaid="selected-retailer-store-pickup-label" kind="default" class="mb-1">
          {{ $t('checkout.multi.pickupInStore.location') }}
        </pdl-label>

        <template v-if="!Object.keys(selectedRetailer).length">
          <div qaid="selected-retailer-no-retailer-message" class="font-bold mb-1">
            {{ $t('checkout.multi.retailer.error') }}
          </div>
        </template>
        <template v-else>
          <div qaid="selected-retailer-name" class="font-bold">
            {{ selectedRetailer.displayName }}
            <span
              v-if="selectedRetailer.formattedDistance"
              qaid="selected-retailer-formatted-distance"
              class="font-normal text-gray-60"
            >
              {{ `(${selectedRetailer.formattedDistance} ${unitAbbreviation})` }}
            </span>
          </div>
        </template>

        <address-summary
          v-if="selectedRetailer.address"
          custom-classes="mb-1"
          :address="selectedRetailer.address"
          qaid="selected-retailer-store-pickup-address"
        />

        <pdl-link qaid="selected-retailer-store-pickup-edit" @click.prevent="handleRetailerClick">
          {{ $t('basket.page.shipping.change.store') }}
        </pdl-link>
      </div>
      <div>
        <pdl-label qaid="selected-retailer-postcode-label" kind="default" class="mb-1">
          {{ $t('checkout.multi.shipToHome.zip') }}
        </pdl-label>
        <div class="mb-1" qaid="selected-retailer-ship-to-postal-code">
          {{ selectedPostcode || '-' }}
        </div>
        <pdl-link qaid="selected-retailer-postcode-edit" @click.prevent="handlePostcodeClick">
          {{ $t('text.edit') }}
        </pdl-link>
      </div>
    </div>
  </section>
  <section v-else class="h-20">
    <pdl-loading is-loading />
  </section>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import {PdlLabel} from '@pedal/pdl-label';
import {PdlLink} from '@pedal/pdl-link';
import {PdlLoading} from '@pedal/pdl-loading';
import AddressSummary from '@/components/AddressSummary';
import RetailerDrawer from '@/components/drawer/retailer/RetailerDrawer.vue';
import PostalCodeDrawer from '@/components/drawer/address/PostalCodeDrawer.vue';
import {populateAddress} from '@/utils/cart/delivery-address-util.js';

export default {
  name: 'SelectedRetailer',

  components: {
    PdlLabel,
    AddressSummary,
    PdlLink,
    RetailerDrawer,
    PostalCodeDrawer,
    PdlLoading,
  },

  data() {
    return {
      isRetailerDrawerOpen: false,
      isPostalCodeDrawerOpen: false,
    };
  },

  computed: {
    ...mapState('user', ['selectedRetailer', 'selectedLocation', 'isRetailerLoading']),
    ...mapGetters('backend', ['unitAbbreviation', 'countryCode']),
    selectedPostcode() {
      return this.selectedLocation?.postcode;
    },
    updateKey() {
      return `${this.selectedPostcode}-${this.selectedRetailer?.name}`;
    },
  },
  watch: {
    selectedRetailer(newVal, oldVal) {
      if (newVal?.name === oldVal?.name) return;
      this.updateCartDealer(this.selectedRetailer.name);
    },
    async selectedLocation(newVal, oldVal) {
      if (newVal?.postcode === oldVal?.postcode) return;
      this.updateCartAddress(await populateAddress(this.countryCode, this.selectedLocation));
    },
  },
  methods: {
    ...mapActions('cart', ['updateCartDealer', 'updateCartAddress']),
    handlePostcodeClick() {
      this.isPostalCodeDrawerOpen = !this.isPostalCodeDrawerOpen;
    },
    handleRetailerClick() {
      this.isRetailerDrawerOpen = !this.isRetailerDrawerOpen;
    },
  },
};
</script>
