<script>
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  name: 'OrderDetails',

  components: {
    PdlLoading,
    PdlSectionHeader,
    PdlHeading,
  },

  props: {
    checkoutStep: {
      type: String,
      default: '',
    },
    partTypeKey: {
      type: String,
      default: '',
    },
    bikesOrderDetails: {
      type: Object,
      default: () => null,
    },
    partsOrderDetails: {
      type: Object,
      default: () => null,
    },
    customWaterBottlesOrderDetails: {
      type: Object,
      default: () => null,
    },
    projectOneBikesOrderDetails: {
      type: Object,
      default: () => null,
    },
    bikesOrderCode: {
      type: String,
      default: '',
    },
    bikesOrderUpdate: {
      type: String,
      default: '',
    },
    partsOrderCode: {
      type: String,
      default: '',
    },
    partsOrderUpdate: {
      type: String,
      default: '',
    },
    customWaterBottlesOrderCode: {
      type: String,
      default: '',
    },
    customWaterBottlesOrderUpdate: {
      type: String,
      default: '',
    },
    projectOneBikesOrderCode: {
      type: String,
      default: '',
    },
    projectOneBikesOrderUpdate: {
      type: String,
      default: '',
    },
    projectOneWarrantyFramesetOrderCode: {
      type: String,
      default: '',
    },
    projectOneWarrantyFramesetOrderUpdate: {
      type: String,
      default: '',
    },
    timeoutError: {
      type: Boolean,
      default: false,
    },
    emptyReturnError: {
      type: Boolean,
      default: false,
    },
    isDropShip: {
      type: Boolean,
      default: false,
    },
    allocatedItemsExistenceMap: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      loading: true,
    };
  },
  computed: {
    orderDetails() {
      let selectedTabOrderDetails;
      switch (this.partTypeKey) {
        case 'bikes':
          selectedTabOrderDetails = this.bikesOrderDetails;
          break;
        case 'parts':
          selectedTabOrderDetails = this.partsOrderDetails;
          break;
        case 'customWaterBottles':
          selectedTabOrderDetails = this.customWaterBottlesOrderDetails;
          break;
        case 'projectOneBikes':
          selectedTabOrderDetails = this.projectOneBikesOrderDetails;
          break;
        default:
          break;
      }
      return selectedTabOrderDetails;
    },
    headerKey() {
      let headerKey;
      switch (this.partTypeKey) {
        case 'bikes':
          headerKey = 'order-details-bikes-heading';
          break;
        case 'parts':
          headerKey = 'order-details-parts-heading';
          break;
        case 'customWaterBottles':
          headerKey = 'order-details-custom-water-bottles-heading';
          break;
        case 'projectOneBikes':
          headerKey = 'order-details-project-one-heading';
          break;
        default:
          break;
      }
      return headerKey;
    },
    headerText() {
      let headerText;
      switch (this.partTypeKey) {
        case 'bikes':
          headerText = this.$t('text.bikeOrderDetails');
          break;
        case 'parts':
          headerText = this.$t('text.accessoryOrderDetails');
          break;
        case 'customWaterBottles':
          headerText = this.$t('checkout.custom.orderDetails');
          break;
        case 'projectOneBikes':
          headerText = this.$t('checkout.projectOne.orderDetails');
          break;
        default:
          break;
      }
      return headerText;
    },
  },
};
</script>
