<template>
  <div
    id="b2b-off-canvas-facet"
    class="b2b-plp-sidebar off-canvas position-top reveal-for-large"
    data-off-canvas
    data-transition="overlap"
  >
    <facets-header
      :title="$t('search.nav.refinements')"
      :is-open="isFiltersVisible"
      :selected-filters="selectedFilters"
      @toggle-state="toggleFiltersState"
    />

    <selected-facets
      v-if="selectedFilters && selectedFilters.length > 0"
      v-show="isFiltersVisible"
      :filters="selectedFilters"
      @state-changed="updateFilter"
    />

    <div v-if="filters && filters.length > 0" v-show="isFiltersVisible">
      <ul class="facet-group__list">
        <facet-item
          v-for="(filter, i) in filters"
          :key="`filter-${i}`"
          :name="`filter-${i}`"
          :item-id="`filter-${i}-${locationId}`"
          :value="filter"
          @state-changed="updateFilter"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import FacetsHeader from './PlpFacets/FacetsHeader';
import FacetItem from './PlpFacets/FacetItem';
import SelectedFacets from './PlpFacets/SelectedFacets';

export default {
  components: {
    FacetsHeader,
    FacetItem,
    SelectedFacets,
  },
  props: {
    filters: {
      type: [Array, Object],
      default: () => [],
    },
    selectedFilters: {
      type: [Array, Object],
      default: () => [],
    },
    locationId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isFiltersVisible: true,
    };
  },
  methods: {
    ...mapActions('plp', {
      updateFilter: 'updateFilter',
    }),
    toggleFiltersState() {
      this.isFiltersVisible = !this.isFiltersVisible;
    },
  },
};
</script>
