<script>
import {mapMutations, mapState, mapActions, mapGetters} from 'vuex';
import B2bMiniCart from '@/components/mini-cart/b2b/B2bMiniCart';
import ToggleSwitch from '@/components/ToggleSwitch';
import AccountSelector from '@/components/account-selector/AccountSelector';
import VendorSelector from '@/components/vendor-selector/VendorSelector';
import NotificationsNav from '@/components/containers/notifications/NotificationsNav';
import NotificationsDropdown from '@/components/containers/notifications/NotificationsDropdown';
import SearchInput from '@/components/header-search/SearchInput';
import {PdlIcon} from '@pedal/pdl-icon';

import NavLargeContainer from '@/navigation/containers/NavLargeContainer';
import NavUtilityBarB2bContainer from '@/navigation/containers/NavUtilityBarB2bContainer';
import NavUtilityBarControl from '@/navigation/components/NavUtilityBarControl';
import NavUtilityBarVendorSelector from '@/navigation/components/NavUtilityBarVendorSelector';
import NavCategoriesLayout from '@/navigation/layouts/NavCategoriesLayout';
import NavCategoriesLink from '@/navigation/components/NavCategoriesLink';
import NavCompactContainer from '@/navigation/containers/NavCompactContainer';
import NavOverlayCompactLayout from '@/navigation/layouts/NavOverlayCompactLayout';

export default {
  components: {
    B2bMiniCart,
    ToggleSwitch,
    AccountSelector,
    VendorSelector,
    NotificationsNav,
    NotificationsDropdown,
    NavCompactContainer,
    NavLargeContainer,
    NavUtilityBarB2bContainer,
    NavUtilityBarControl,
    NavUtilityBarVendorSelector,
    NavCategoriesLayout,
    NavCategoriesLink,
    NavOverlayCompactLayout,
    SearchInput,
    PdlIcon,
  },

  data() {
    return {
      showNav: false,
      mainNavItems: [
        {title: 'EQUIPMENT', url: '/b2b/us/en_US/c/B300'},
        {title: 'APPAREL', url: '#'},
        {title: 'RETAILERS SERVICES', url: '#'},
        {title: 'ORDERS', url: '#'},
        {title: 'NEWS', url: '#'},
      ],
      companyMenuItems: [
        {label: 'Profile', url: '#'},
        {label: 'Password', url: '#'},
        {label: 'Email address', url: '#'},
        {label: 'Payment details', url: '#'},
        {label: 'Saved lists', url: '#'},
        {label: 'Notification settings', url: '#'},
      ],
      accountMenuItems: [
        {label: 'Business Unit', url: '#'},
        {label: 'Users', url: '#'},
      ],
    };
  },

  computed: {
    qaidValueHeaderColor() {
      return !this.isConsumerFriendlyMode ? 'site.preheader.color.gray' : 'site.preheader.color.blue';
    },

    ...mapState('user', ['loggedIn']),
    ...mapState('backend', ['isConsumerFriendlyMode', 'isDistributor', 'isAccountConsumerToggleAccessEnabled']),
    ...mapState('advancedOrdering', ['advancedOrderingMode']),
    ...mapGetters('notifications', ['notifications']),
  },

  watch: {
    // These two modes cannot be "on" at the same time
    isConsumerFriendlyMode(value) {
      if (value) this.setAdvancedOrderingMode(false);
    },
    advancedOrderingMode(value) {
      if (value) this.setConsumerFriendlyMode(false);
    },
  },

  created() {
    this.fetchNotifications(false);
  },

  methods: {
    ...mapActions('backend', ['toggleConsumerFriendlyMode', 'toggleAdvancedOrderingMode']),
    ...mapActions('advancedOrdering', ['toggleAdvancedOrderingMode']),
    ...mapMutations('backend', ['setConsumerFriendlyMode']),
    ...mapMutations('advancedOrdering', ['setAdvancedOrderingMode']),
    ...mapActions('notifications', ['fetchNotifications']),
  },
};
</script>
