<template>
  <div v-if="message && message.length > 0" class="pdp-add-to-cart-status">
    <div id="cart-add-success" class="cart-add-success flex gap-6 justify-end">
      <div class="cart-add-success__message flex-auto">
        <pdl-icon size="18" name="check_circle" />
        <span class="cart-add-success__text" qaid="cart-add-success-text" v-html="message" />
      </div>
      <p class="cart-add-success__button flex-none whitespace-nowrap">
        <a :href="cartUrl">
          {{ $t('basket.view.basket') }}
        </a>
      </p>
    </div>
  </div>
</template>
<script>
import {PdlIcon} from '@pedal/pdl-icon';
import {useCartUtils} from '@/composables/cart-utils';

export default {
  name: 'PdpAddToCartStatus',
  components: {
    PdlIcon,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  setup() {
    const {cartUrl} = useCartUtils();
    return {cartUrl};
  },
};
</script>
<style lang="scss">
.cart-add-success {
  border-bottom: 1px solid var(--gray-05);
  display: none;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  width: 100%;

  &__message {
    color: var(--green-100);
    text-align: center;

    &::before {
      margin-right: rem-calc(5px);
    }
  }

  &__text {
    color: var(--green-140);
  }

  &__button {
    display: table-cell;
    text-align: right;
  }
}
</style>
