<template>
  <div class="grid-container">
    <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1">{{ $t('forgottenPwd.title') }}</pdl-heading>
      </template>
    </pdl-section-header>
    <p class="mb-2">{{ $t('forgottenPwd.description') }}</p>
    <div class="max-w-sm mb-3">
      <pdl-callout v-if="message" :kind="messageType">
        <template #content>
          <span v-html="message" />
        </template>
      </pdl-callout>
      <form id="forgottenPwdForm" action="#" autocomplete="off" :class="{'opacity-50': isWorking}" @submit="submit">
        <form-group
          id="forgottenPwd.email"
          v-model="email"
          name="email"
          type="email"
          :show-error-messages="true"
          :error="getErrorMessage('email')"
          :label="$t('forgottenPwd.email')"
          force-display-error
        />
        <div class="buttons">
          <trek-button primary type="submit" :disabled="isWorking" @click="submit">
            <span>{{ $t('forgottenPwd.submit') }}</span>
          </trek-button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import TrekButton from '@/components/TrekButton';
import {PdlCallout} from '@pedal/pdl-callout';
import {UserAccountBase} from '@/components/containers/my-account/utils/user-account-base';
import {getMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {TrekButton, PdlCallout, PdlSectionHeader, PdlHeading},
  extends: UserAccountBase,
  data() {
    return {
      email: '',
    };
  },
  validations() {
    return {
      email: {
        required: this.trekValidators.required('forgottenPwd.email'),
        email: this.trekValidators.email('forgottenPwd.email'),
      },
    };
  },
  methods: {
    /**
     * Process request.
     */
    async processUpdate() {
      this.isWorking = true;
      try {
        const response = await this.api.passwordRecovery({
          email: this.email,
          CSRFToken: this.options.CSRFToken,
        });

        this.displayMessage(response?.data);

        if (getMetaFeedbackType(response?.data) !== MetaFeedbackType.ERROR) {
          this.resetForm();
        }
      } catch (e) {
        this.displayError(e.message);
      } finally {
        this.isWorking = false;
      }
    },

    /**
     * Reset form data to defaults.
     */
    resetForm() {
      this.email = '';
      this.v$.$reset();
    },
  },
};
</script>
