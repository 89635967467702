<template>
  <div>
    <table class="b2b-table b2b-table--hstripe">
      <thead>
        <!-- Each Header -->
        <tr>
          <th
            v-for="(column, colIndex) in columns"
            :key="`${column.label}-${colIndex}`"
            :class="[column.cssClasses]"
            v-html="column.label"
          ></th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(group, groupIndex) in groupings">
          <tr :key="`${group.groupKey}-${groupIndex}`">
            <td colspan="3" class="font-semibold" v-html="group.groupLabel" />
          </tr>
          <tr v-for="(row, rowIndex) in sortPreferences(group.groupItems)" :key="`${row.label}-${rowIndex}`">
            <td>
              <div>
                <span v-html="row.label" />
              </div>
            </td>
            <td>
              <div v-if="row.online" class="text-right">
                <label class="checkbox justify-end">
                  <input
                    id="`${row.online.code}`"
                    v-model="row.online.checked"
                    type="checkbox"
                    :disabled="!row.online.isEnabled"
                  />
                  <span class="control-indicator mr-0"></span>
                </label>
              </div>
            </td>
            <td>
              <div v-if="row.email" class="text-right">
                <label class="checkbox justify-end">
                  <input
                    :id="row.email.code"
                    v-model="row.email.checked"
                    type="checkbox"
                    :disabled="!row.email.isEnabled"
                    @change="setEmailPreference(row.email)"
                  />
                  <span class="control-indicator mr-0" />
                </label>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import sortBy from 'lodash/sortBy';

export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    groupings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },

  methods: {
    sortPreferences(prefs) {
      return sortBy(prefs, ['label']);
    },
    setEmailPreference(currentPref) {
      storefrontInstance.put('/notification-settings/update-email-notification', currentPref);
    },
  },
};
</script>
