<template>
  <highcharts ref="trekChart" :class="className" :options="chartOptions"></highcharts>
</template>

<script>
import {Chart} from 'highcharts-vue';

export default {
  components: {
    highcharts: Chart,
  },
  props: {
    chartOptions: {
      type: Object,
      required: true,
    },
    className: {
      type: String,
      default: () => '',
    },
  },
};
</script>
