<template>
  <div v-if="fulfillmentOption && deliveryMode" class="flex justify-between relative leading-loose text-sm">
    <div class="mr-3" :qaid="`cart-entry-${entryNumber}-${fulfillmentOption.code}-fulfillment-option-arrival-dates`">
      <!-- Delivery Dates -->
      <template v-if="deliveryDates && fulfillmentOption.messaging.message">
        <p v-if="deliveryDates.startDate" class="mb-1">
          {{ $t(fulfillmentOption.messaging.message, deliveryDates) }}
        </p>
        <p v-else-if="deliveryDates.endDate" class="mb-1">
          {{ $t('fulfillment.multi.arrivesBy', [deliveryDates.endDate]) }}
        </p>
      </template>
      <!-- BOPIS Messaging -->
      <p
        v-if="isBopis"
        class="leading-normal text-sm relative mb-1"
        :qaid="`cart-entry-${entryNumber}-${fulfillmentOption.code}-bopis-description`"
      >
        {{ $t(fulfillmentOption.messaging.message) }}
      </p>
      <!-- Delivery Messaging -->
      <p
        v-if="dealerDeliveryMessage"
        class="leading-normal text-xs relative"
        :qaid="`cart-entry-${entryNumber}-${fulfillmentOption.code}-fulfillment-option-description`"
      >
        {{ $t(dealerDeliveryMessage) }}
      </p>
      <div v-if="fulfillmentOption.richMessaging">
        <fulfillment-option-dialog
          :id="fulfillmentOption.richMessaging.learnMoreComponentId"
          :is-visible="isLearnMoreVisible"
          :component-id="fulfillmentOption.richMessaging.learnMoreComponentId"
          @close="setIsLearnMoreVisible(false)"
        />
        <pdl-button
          :qaid="`cart-entry-${entryNumber}-${fulfillmentOption.name}-fulfillment-option-learn-more-button`"
          label="Text"
          small
          underline
          text
          class="pointer-events-auto"
          @click.stop="setIsLearnMoreVisible(true)"
        >
          {{ $t(fulfillmentOption.richMessaging.learnMoreMessage) }}
        </pdl-button>
      </div>
    </div>

    <!-- Pricing -->
    <div :qaid="`cart-entry-${entryNumber}-${fulfillmentOption.code}-fulfillment-option-price`">
      <p>{{ deliveryCost }}</p>
    </div>
  </div>
</template>

<script>
import {DeliveryModeTypes, ShipToHomeMessages, UpgradedShipToHomeModes} from '@/constants/delivery-modes.js';
import {PdlButton} from '@pedal/pdl-button';
import FulfillmentOptionDialog from '@/components/containers/pdp/b2c/fulfillment-options/FulfillmentOptionDialog.vue';
import {mapState} from 'vuex';
import {FulfillmentOptionTypes} from '@/constants/fulfillment-options';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'CartFulfillmentOptionMessaging',
  components: {
    PdlButton,
    FulfillmentOptionDialog,
  },
  props: {
    fulfillmentOption: {
      type: Object,
      required: true,
    },
    deliveryMode: {
      type: Object,
      required: true,
    },
    entryNumber: {
      type: Number,
      default: Number,
    },
  },
  data() {
    return {
      isLearnMoreVisible: false,
    };
  },
  computed: {
    ...mapState('cart', ['hasUpgradedShipping']),

    isBopis() {
      return (
        (this.deliveryMode?.code === DeliveryModeTypes.BOPIS ||
          this.deliveryMode?.code === DeliveryModeTypes.BOPIS_NO_BIKE) &&
        isEmpty(this.deliveryMode?.deliveryWindow)
      );
    },
    deliveryDates() {
      if (this.deliveryMode?.deliveryWindow?.start === this.deliveryMode?.deliveryWindow?.end) {
        return {endDate: this.deliveryMode?.deliveryWindow?.end};
      }
      return {
        startDate: this.deliveryMode?.deliveryWindow?.start,
        endDate: this.deliveryMode?.deliveryWindow?.end,
      };
    },
    dealerDeliveryMessage() {
      if (UpgradedShipToHomeModes.includes(this.deliveryMode?.code) && this.hasUpgradedShipping) {
        return ShipToHomeMessages[this.deliveryMode?.code];
      } else if (!this.hasUpgradedShipping && this.fulfillmentOption?.code === FulfillmentOptionTypes.SHIP_TO_HOME) {
        return '';
      }
      return this.fulfillmentOption?.messaging?.dealerDeliveryMessage;
    },
    deliveryCost() {
      if (UpgradedShipToHomeModes.includes(this.deliveryMode?.code)) {
        return '';
      }
      if (this.isAftermarketDealerDelivery(this.deliveryMode)) {
        return this.$t('fulfillment.premium.cart.aftermarketPrice');
      }
      return this.deliveryMode?.deliveryCost?.value === 0
        ? this.$t('order.free')
        : this.deliveryMode?.deliveryCost?.formattedValue;
    },
  },
  methods: {
    setIsLearnMoreVisible(value) {
      this.isLearnMoreVisible = value;
    },
    isAftermarketDealerDelivery(deliveryMode) {
      return (
        (deliveryMode?.code === DeliveryModeTypes.DEALER_DELIVERY ||
          deliveryMode?.code === DeliveryModeTypes.DEALER_DELIVERY_GROSS) &&
        deliveryMode?.deliveryCost?.value === 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.button--text {
  font-size: 0.75rem;
}
</style>
