import {RouteBuilderMapDefaults, RouteBuilderMapMarkers} from '@/constants/rcp-builder';
import {PdlToastType} from '@/constants/pdl-toast-type';
import isEqual from 'lodash/isEqual';
import {mapState} from 'vuex';
import mapboxgl from 'mapbox-gl';
import {findNearestPointOnLine, getHoverMarkerElement} from '@/utils/rcp-map-helpers';
import {getClosestPointInSeries} from '@/rcpBuilder/utils/elevation-helpers';

const RcpBuilderMap = {
  computed: {
    ...mapState('rcpBuilder', ['featureTypes']),
  },

  methods: {
    getMarkerElement() {
      let el;
      el = document.createElement('div');
      if (!this.map?.markerList.length) {
        el.classList.add('main-marker-element');
        el.innerHTML = RouteBuilderMapMarkers.MAIN_MARKER_SVG;
      } else {
        el.classList.add('waypoint-marker-element');
        el.innerHTML = RouteBuilderMapMarkers.WAYPOINT_SVG;
      }
      el.addEventListener('mouseover', this.hideHoverIcon);
      el.classList.add('route-marker-element');
      return el;
    },
    setPrecisionOnCoords(coords) {
      return coords.map((value) =>
        parseFloat(parseFloat(value).toFixed(RouteBuilderMapDefaults.DEFAULT_COORDS_PRECISION))
      );
    },
    getMarkerIndex(coord) {
      const index = this.map?.markerList?.findIndex((el) => {
        return isEqual(el.getLngLat(), coord);
      });
      return index;
    },
    getNextMarkerIndex(markerIndex, coord) {
      for (let index = markerIndex + 1; index < this.map?.markerList.length; index++) {
        const element = this.map?.markerList[index];
        const areSame = isEqual(element.getLngLat(), coord);
        if (areSame === true) return index;
      }
      return markerIndex;
    },
    createPayloadOptions(origin, destination, snapToRoads) {
      const payloadOptions = {
        origin,
        destination,
        snapToRoads,
      };
      return payloadOptions;
    },
    createPayloadObject(index, options) {
      const payloadObj = {
        data: {
          ...options,
          travelMode: this.routingOption,
        },
        index,
      };
      return payloadObj;
    },
    createWaypointObject({id, snapTo, directionsData, geoJsonSourceId, segmentElevationData}) {
      const createdDate = new Date().toISOString();
      let object = {
        id,
        createdDate,
        location: this.map.markerList[id]?.getLngLat(),
        cues: directionsData?.cues || [],
        snapTo: directionsData?.snapToRoads || snapTo,
        travelMode: directionsData?.travelMode || this.routingOption,
        distance: directionsData?.distance || 0,
        duration: directionsData?.duration || 0,
        geometry: directionsData?.geometry || '',
        geoJsonSourceId,
        segmentElevationData,
      };
      return object;
    },
    showErrorToast(feedback) {
      this.$notify({
        type: feedback?.type ?? PdlToastType.ERROR,
        title: feedback?.title ? this.$t(`${feedback?.title}`) : '',
        message: feedback?.message ? this.$t(`${feedback?.message}`) : '',
        showClose: true,
        duration: feedback?.duration ?? RouteBuilderMapDefaults.TOAST_DURATION,
      });
    },
    showWarningOnEvent(hasEventOnBuilder) {
      if (hasEventOnBuilder) {
        this.showErrorToast({
          type: PdlToastType.WARNING,
          message: 'checkout.B2B.orderConfirmation.wait',
          duration: 2000,
        });
        return true;
      }
      return false;
    },
    addMarkerOnPosition({position, controlPointIndex = 0, clickHandler = undefined}) {
      position = this.setPrecisionOnCoords(position);
      const el = this.getMarkerElement();
      this.markerOptions = {
        ...this.markerOptions,
        element: el,
      };
      const marker = this.map.addMarker({
        events: {onDragEnd: this.onMarkerDragEnd, onDrag: this.debouncedPreview},
        position,
        markerOptions: this.markerOptions,
        controlPointIndex,
      });
      if (this.isAutoCenterEnabled) {
        this.map.mapInstance.flyTo({
          center: position,
        });
      }
      if (clickHandler) {
        el.addEventListener('click', (evt) => {
          clickHandler(marker);
          evt.stopPropagation();
        });
      }
    },
    snapMarkersToRoad(origin, destination, startCoordinates, lastCoordinates, index) {
      !this.compareCoordsArrays(origin, startCoordinates) && this.moveMarkersToCoords(startCoordinates, index - 1);
      !this.compareCoordsArrays(destination, lastCoordinates) && this.moveMarkersToCoords(lastCoordinates, index);
    },
    updateHoverIconStyles(hoverMarkerStyleProperty) {
      this.hoverIcon.style.display = hoverMarkerStyleProperty;
      if (hoverMarkerStyleProperty === 'block') {
        this.hoverIcon.style.opacity = '1';
        this.hoverIcon.style.pointerEvents = 'auto';
      }
    },
    compareCoordsArrays(arrToCompare, arrToCompareWith) {
      return isEqual(arrToCompare, arrToCompareWith);
    },
    hasMultiPolyline(payload = []) {
      return payload.length > 1;
    },
    getCoordsArray(coordsObj = {}) {
      let position = [];
      position.push(coordsObj.lng, coordsObj.lat);
      return position;
    },
    initializeHoverMarker() {
      this.hoverIcon = getHoverMarkerElement();
      this.hoverMarker = new mapboxgl.Marker({
        ...this.markerOptions,
        element: this.hoverIcon,
      });
      this.hoverMarker.on('dragstart', this.showDragHoverIcon);
      this.hoverMarker.on('drag', this.debouncedPreview);
      this.hoverMarker.on('dragend', this.hoverMarkerDragEnd);
      this.hoverIcon.addEventListener('mouseleave', this.hideHoverIcon);
      this.hoverIcon.addEventListener('click', (event) => {
        this.dragMarkerClickHandler(this.hoverMarker);
        event.stopPropagation();
      });
      this.hoverMarker?.setLngLat([0, 0]).addTo(this.map.mapInstance);
    },
    showHoverIcon(evt) {
      const isRouteMarkerElement =
        evt?.originalEvent?.target?.parentNode?.parentNode?.parentNode?.classList.contains('route-marker-element');
      if (isRouteMarkerElement && this.hoverIcon) {
        this.hoverIcon.style.display = 'none';
        return;
      }

      this.updateHoverIconStyles('block');
      let evtCoords = evt.lngLat.toArray();
      let nearestPointOnPolyline = findNearestPointOnLine(evt.features[0].geometry, evtCoords, {});
      this.hoverMarkerRoute = evt.features[0].source;
      this.hoverMarker.setLngLat(nearestPointOnPolyline.geometry.coordinates).addTo(this.map.mapInstance);
      if (this.chartOptions?.series[0].data.length) {
        this.updateMarkerOnChart(nearestPointOnPolyline.geometry.coordinates);
      }
    },
    hideHoverIcon() {
      if (!this.isHoverMarkerDragging && this.hoverIcon) {
        this.updateHoverIconStyles('none');
      }

      if (this.chartOptions?.series[0].data.length) {
        this.elevationChartReference?.series?.forEach((series) => {
          series?.data?.forEach((data) => {
            data.setState(null);
          });
        });
        this.elevationChartReference?.tooltip?.hide(0);
        this.elevationChartReference?.xAxis[0]?.hideCrosshair();
      }
    },
    updateMarkerOnChart(dataPoint) {
      const closestPoint = getClosestPointInSeries(dataPoint, this.chartOptions?.series);

      const pointOnMap =
        this.elevationChartReference?.series[0]?.data[
          this.chartOptions?.series[0]?.data?.findIndex((item) => item.geometry[0] === closestPoint.point.geometry[0])
        ];

      if (closestPoint?.point && closestPoint?.distance < 0.2) {
        pointOnMap?.setState('hover');
      }
      this.elevationChartReference?.tooltip?.refresh(pointOnMap);
      this.elevationChartReference?.xAxis[0]?.drawCrosshair({}, pointOnMap);
    },

    handleChartPointHover(evt) {
      this.updateHoverIconStyles('block');
      this.hoverMarker.setLngLat(evt.target.geometry).addTo(this.map.mapInstance);
    },
    hideHoverIconFromChart() {
      this.updateHoverIconStyles('none');
    },
  },
};

export {RcpBuilderMap};
