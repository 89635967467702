<template>
  <div class="grid-container pb-5">
    <div class="cell">
      <pdl-section-header size="lg" class="mt-3">
        <template slot="content">
          <pdl-heading :level="1" qaid="recall-inquiry-title">{{ $t('recall.Checker.heading') }}</pdl-heading>
        </template>
      </pdl-section-header>
    </div>
    <div class="grid-x grid-margin-x">
      <div class="cell large-6">
        <p class="mb-3" qaid="recall-inquiry-description">{{ $t('recall.Checker.body') }}</p>
        <div class="max-w-sm">
          <div class="form-group">
            <label for="serial-input" class="form-label">{{ $t('productRegistration.B2B.serialNumber') }}</label>
            <input
              id="serial-input"
              v-model="serialNumber"
              type="text"
              name="serial-input"
              class="form-control"
              qaid="find-recall-serial-number"
              :maxlength="30"
              @keyup.enter="submitSerialNumber"
              @keypress="checkInput"
            />
          </div>
        </div>
        <div class="buttons">
          <trek-button
            primary
            qaid="submit-recall-inquiry-button"
            :disabled="!serialNumber"
            @click="submitSerialNumber"
          >
            <span>{{ $t('text.findBike') }}</span>
          </trek-button>
        </div>
        <div class="mb-2">
          <a class="underline hover:no-underline" :href="contactUsUrl" qaid="recall-inquiry-contact-us">
            {{ $t('productRegistration.B2B.requestSupport') }}
          </a>
        </div>
        <pdl-callout v-if="hasSearchedForRecall" :kind="recallCalloutType">
          <template #content>
            {{ recallSearchResponse }}
          </template>
        </pdl-callout>
      </div>
      <div class="cell large-6">
        <div class="px-2 py-3 bg-gray-05">
          <img
            qaid="recall-inquiry-serial-number-location"
            :src="serialNumberLocationImage"
            alt="serial-number-locator-image"
          />
          <p class="mt-2">{{ $t('garage.integrations.garmin.addBike.serialNumber.location') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {PdlCallout} from '@pedal/pdl-callout';
import TrekButton from '@/components/TrekButton';
import {mapState, mapActions} from 'vuex';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {
    PdlCallout,
    TrekButton,
    PdlSectionHeader,
    PdlHeading,
  },

  props: {
    contextPath: {
      type: String,
      default: '',
    },
    allowedCharactersRegex: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      serialNumber: 'WTU',
      serialNumberLocationImage:
        'https://media.trekbikes.com/image/upload/w_785,h_400,f_auto,q_auto/BikeRegistration_sn_location',
    };
  },

  computed: {
    contactUsUrl() {
      return `${this.contextPath}/${this.b2b ? 'contact-us' : 'contactUs'}/`;
    },
    recallSearchResponse() {
      if (this.hasRecall) {
        return this.$t('recall.Checker.response1');
      } else {
        return this.$t('recall.Checker.response2');
      }
    },
    recallCalloutType() {
      if (this.hasRecall) {
        return 'warning';
      }
      return 'default';
    },
    ...mapState('backend', ['b2b']),
    ...mapState('recallInquiry', ['hasRecall', 'hasSearchedForRecall']),
  },

  methods: {
    ...mapActions('recallInquiry', ['getRecallBySerialNumber']),
    submitSerialNumber() {
      this.getRecallBySerialNumber(this.serialNumber);
    },
    checkInput(e) {
      if (this.allowedCharactersRegex) {
        const regex = RegExp(this.allowedCharactersRegex);
        if (regex.test(String.fromCharCode(e.charCode))) {
          return true;
        } else {
          e.preventDefault();
        }
      }
    },
  },
};
</script>
