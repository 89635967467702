<template>
  <fieldset class="vertical-spacer-1 form__fieldset">
    <legend v-if="isCPO" class="form__legend header-xsmall">
      {{ $t('checkout.multi.deliveryMethod.cpo') }}
    </legend>
    <legend v-else class="form__legend header-xsmall">
      {{ $t('checkout.multi.deliveryMethod.choose') }}
    </legend>
    <locale-shipping-text v-if="showOvernightShippingText" />
    <pdl-loading :is-loading="deliveryModesLoading">
      <delivery-mode-list>
        <template #delivery-method-specifics>
          <slot name="delivery-method-specifics" />
        </template>
      </delivery-mode-list>
    </pdl-loading>
  </fieldset>
</template>

<script>
import {mapState} from 'vuex';
import {PdlLoading} from '@pedal/pdl-loading';
import DeliveryModeList from '@/components/checkout/b2c/dealers/DeliveryModeList';
import LocaleShippingText from './LocaleShippingText.vue';

export default {
  components: {PdlLoading, DeliveryModeList, LocaleShippingText},
  props: {
    isCPO: {
      type: Boolean,
      default: false,
    },
    showOvernightShippingText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('checkout', ['deliveryModesLoading']),
  },
};
</script>
