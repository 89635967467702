<template>
  <input
    :id="id"
    v-model="value"
    type="number"
    :name="id"
    :min="min"
    :max="max"
    :placeholder="placeholder"
    class="form-control"
    @input="onInput"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 9999,
    },
    sku: {
      type: String,
      default: '',
    },
    shouldClear: {
      type: Boolean,
      default: false,
    },
    isBuyingZone: Boolean,
    // #nomoretwovalueprops - needs refactor to one value
    /* eslint-disable */
    initialValue: [Number, String],
    /* eslint-enable*/
    id: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      value: 0,
    };
  },

  computed: {
    ...mapState('savedLists', ['clearQty', 'buyingZoneClearQty']),
    ...mapState('pdp', ['pdpSku']),
  },

  watch: {
    clearQty(clearQty) {
      if (clearQty && this.shouldClear && !this.isBuyingZone) {
        this.value = null;
      }
    },

    initialValue(newVal) {
      this.value = newVal;
      this.updateSavedList();
    },

    value(value) {
      this.$emit('updated', value);
    },

    buyingZoneClearQty(buyingZoneClearQty) {
      if (buyingZoneClearQty && this.shouldClear && this.isBuyingZone) {
        this.value = null;
      }
    },
  },

  created() {
    this.updateSavedList();
  },

  mounted() {
    this.value = this.initialValue;
  },

  methods: {
    ...mapMutations('savedLists', ['setIsBuyingZone']),
    onInput(event) {
      const {value} = event.target;
      let newValue = value;

      if (value < this.min) {
        newValue = this.min;
      } else if (value > this.max) {
        newValue = this.max;
      }

      event.target.value = newValue;
      this.value = newValue;

      this.updateSavedList();
      this.$emit('update-variant-qty', this.value);
    },

    updateSavedList() {
      const qty = this.value;
      const sku = this.sku || this.pdpSku;
      this.setIsBuyingZone(this.isBuyingZone);
      if (sku && qty) {
        this.$store.dispatch('savedLists/updateQty', {
          sku,
          qty,
        });
      }
    },
  },
};
</script>
