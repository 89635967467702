const hasMatchingDeliveryModeForEntry = function (entry, cartFulfillmentOptions) {
  return cartFulfillmentOptions?.some(
    (cfo) =>
      cfo.orderEntryId === entry.entryNumber && hasMatchingDeliveryMode(entry.deliveryMode, cfo.fulfillmentOptions)
  );
};
const hasMatchingDeliveryMode = function (deliveryMode, fulfillmentOptions) {
  return fulfillmentOptions?.some((option) => {
    return option.deliveryModes?.some((mode) => {
      return mode.code === deliveryMode?.code;
    });
  });
};

export {hasMatchingDeliveryMode, hasMatchingDeliveryModeForEntry};
