<template>
  <div><slot></slot></div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import {mapState} from 'vuex';

export default {
  inject: ['container'],
  props: {
    shipment: {
      type: Array,
      default: () => [],
    },
    orderData: {
      type: Object,
      default: () => null,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    status: {
      type: String,
      default: '',
    },
    productType: {
      type: String,
      default: '',
    },
  },

  data() {
    return {};
  },
  computed: {
    ...mapState('backend', ['encodedContextPath', 'isConsumerFriendlyMode']),

    hasShipped() {
      return !isEmpty(this.shipment.shipped);
    },

    hasInWarehouse() {
      return !isEmpty(this.orderData?.[this.productType]?.notShipped ?? {});
    },

    packingSlipNumber() {
      const {packingSlipNumber} = this.shipment;
      return packingSlipNumber ? packingSlipNumber : null;
    },

    packingSlipUrl() {
      if (!this.shipment) {
        return '';
      }

      const packingSlipNumber = this.packingSlipNumber;
      const shipToNumber = this.shipToNumber;

      return `${this.encodedContextPath}/my-account/packingslip-data/${packingSlipNumber}/${shipToNumber}`;
    },
  },

  methods: {
    isEmpty: isEmpty,
  },
};
</script>
