import {PdlSectionHeader, PdlHeading, PdlSubheading} from '@pedal/pdl-section-header';
import FormGroup from '@/components/FormGroup';
import {FormMessage} from '@/components/containers/my-account/utils/form-message';
import {UserApi} from '@/api/user';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

const UserAccountBase = {
  components: {FormGroup, PdlSectionHeader, PdlHeading, PdlSubheading},
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  mixins: [FormMessage, TrekValidationMixin],
  computed: {
    api() {
      return UserApi;
    },
  },
  methods: {
    /**
     * Handle cancel button click.
     */
    cancel() {
      window.location.href = this.options.cancelUrl;
    },

    /**
     * Handle form submission.
     * @return {Promise<void>}
     */
    submit() {
      this.hideMessage();
      this.v$.$validate().then((isValid) => {
        if (isValid) {
          this.processUpdate();
        }
      });
    },

    /**
     * Process update method (to be implemented in children component).
     * @return {Promise<void>}
     */
    async processUpdate() {
      console.error('processUpdate method not implemented.');
    },
  },
  data() {
    return {
      isWorking: false,
    };
  },
};

export {UserAccountBase};
