<template>
  <div>
    <div class="small-3 sku-modal-status--ibd--stock-label">
      <div class="status-box" qaid="rpa-retailer-stock-status">
        <pdl-icon v-if="isInStock" name="check_circle" class="text-green" />
        <span :class="retailerStockClass">{{ retailerStockMessage }}</span>
      </div>
    </div>
    <div v-if="retailer" class="small-9 sku-modal-status--ibd--info" qaid="rpa-retailer-info">
      <span class="shop-name" qaid="rpa-retailer-name">
        <trek-link
          v-if="hasRetailerElpUrl"
          :href="retailer.elpURL"
          underline="underline"
          target="_blank"
          :title="$t('retailer')"
          v-html="retailer.name"
        />
        <span v-else v-html="retailer.name" />
      </span>
      <span v-if="retailer.formattedAddress && isModal" class="shop-address" v-html="retailer.formattedAddress" />
      <span v-if="retailer.distance" class="shop-distance" qaid="rpa-retailer-shop-distance">
        {{ deliveryDistanceText }}
      </span>
      <span v-if="hasDeliveryMessage" class="rpa-msg-delivery">{{ deliveryMessageText }}</span>
      <span v-if="retailer.phoneNumber" class="rpa-msg-inquire">
        <trek-link :href="`tel:${retailer.phoneNumber}`" underline="underline">{{ retailerPhoneNumber }}</trek-link>
      </span>
    </div>
  </div>
</template>

<script>
import {isInStockByCode, isInWarehouseByCode} from '@/components/containers/pdp/utils/retailer-helpers';
import {stockStatusConfig} from '@/constants/stock-status';
import {mapState} from 'vuex';
import trekLink from '@/components/TrekLink';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'PdpRpaSkuStockStatus',
  components: {trekLink, PdlIcon},
  props: {
    isBike: {
      type: Boolean,
      required: true,
    },
    retailer: {
      type: Object,
      default: () => null,
      required: false,
    },
    isModal: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('backend', ['b2b']),
    hasRetailerElpUrl() {
      return this.retailer?.elpURL && this.retailer?.elpURL.length > 0 && !this.b2b;
    },
    hasDeliveryMessage() {
      return this.retailer?.ecommEnabled && this.isInWarehouse;
    },
    deliveryDistanceText() {
      return `${this.$t('storeFinder.label.distance')}: ${this.retailer?.distance} ${this.retailer?.distanceUnit}`;
    },
    deliveryMessageText() {
      return this.isBike
        ? this.$t('product.stockStatus.inStoreDelivery')
        : this.$t('product.stockStatus.inStoreDeliveryAftermarket');
    },
    retailerPhoneNumber() {
      return `${this.$t('product.stockStatus.inquire')} ${this.retailer.phoneNumber}`;
    },
    isInStock() {
      return isInStockByCode(this.retailer?.retailerStockStatus);
    },
    retailerStockClass() {
      return stockStatusConfig[this.retailer?.retailerStockStatus]?.retailerStockClass;
    },
    isInWarehouse() {
      return isInWarehouseByCode(this.retailer?.retailerStockStatus);
    },
    retailerStockMessage() {
      return this.$t(
        `product.stockStatus.${stockStatusConfig[this.retailer?.retailerStockStatus]?.stockStatusMessage.retailerLabel}`
      );
    },
  },
};
</script>
