export const FulfillmentOptionTypes = Object.freeze({
  SHIP_TO_HOME: 'SHIP-TO-HOME',
  PICK_UP_IN_STORE: 'PICK-UP-IN-STORE',
  DEALER_DELIVERY: 'DEALER-DELIVERY',
});

export const FulfillmentOptionHeadings = Object.freeze({
  [FulfillmentOptionTypes.SHIP_TO_HOME]: 'fulfillment.shipToHome.heading',
  [FulfillmentOptionTypes.PICK_UP_IN_STORE]: 'fulfillment.storePickup.heading',
  [FulfillmentOptionTypes.DEALER_DELIVERY]: 'fulfillment.premium.heading',
});
