<template>
  <section v-if="isVisible" :id="componentId" class="grid-container mb-6" qaid="aftermarket-overview">
    <article id="overview" data-magellan-target="overview" class="grid-x grid-margin-x prod-overview">
      <div class="cell small-12 medium-6 mb-2">
        <h3 v-if="productData.copyMarketingHeadline" class="header--lg mb-1" qaid="aftermarket-overview-headline">
          {{ productData.copyMarketingHeadline }}
        </h3>
        <div
          v-if="productData.copyMarketingBody"
          class="prod-overview__intro__marketing-text"
          qaid="aftermarket-overview-body"
        >
          <p class="mb-1">{{ productData.copyMarketingBody }}</p>
        </div>
        <div
          v-if="productData.copyMarketingBodyAlt"
          class="prod-overview__intro__marketing-text"
          qaid="aftermarket-overview-body"
        >
          <p>{{ productData.copyMarketingBodyAlt }}</p>
        </div>
      </div>
      <div v-if="productData.copyBulletPoints" class="cell small-12 medium-6" qaid="aftermarket-overview-feature-list">
        <h3 class="text-lg font-semibold mb-1">{{ $t('product.key.features') }}</h3>
        <ol class="prod-overview__key-features__orderedlist">
          <li
            v-for="(content, index) of productData.copyBulletPoints"
            :key="`overview-feature-${index}`"
            :qaid="`aftermarket-overview-feature-${index}`"
            class="prod-overview__key-features__orderedlist-items"
          >
            {{ content }}
          </li>
        </ol>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  props: {
    productData: {
      type: Object,
      default: () => ({}),
    },
    componentId: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    isVisible() {
      return (
        this.productData.copyMarketingHeadline ||
        this.productData.copyMarketingBody ||
        this.productData.copyBulletPoints
      );
    },
  },
};
</script>
