import axios from 'axios';

const baseUrl = `/checkout/multi/payment/billingDetails`;
const billingAddressInstance = axios.create({
  headers: {'Content-Type': 'application/json'},
});

const BillingAddressApi = {
  async getSameAsDeliveryAddress() {
    return billingAddressInstance.get(`${baseUrl}/v2/sameAsDeliveryAddress`);
  },
};

export {BillingAddressApi};
