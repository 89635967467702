<template>
  <section id="blogFeedComponent" qaid="blog-feed-section" class="product-set grid-container mt-6 mb-6">
    <h3 qaid="blog-feed-header" class="product-set__header header--lg" v-html="bp('feedBlogHeading')" />
    <div data-owl-slider="three-up" class="owl-carousel owl-loaded owl-drag">
      <div class="owl-stage-outer">
        <div class="owl-stage">
          <div v-for="(feed, index) of feedBlogData" :key="index" class="owl-item" qaid="feed-blog">
            <rss-blog-item :feed="feed" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RssBlogItem from '@/components/blog-feed-container/RssBlogItem';
export default {
  name: 'RssBlogFeed',
  components: {RssBlogItem},
  props: {
    baseProperties: {
      type: Array,
      default: () => [],
    },

    feedBlogData: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
