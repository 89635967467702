<script>
import headTag from '@/utils/head-tag';
import ContactUsForm from '@/modules/contactUs.js';
import {ReCaptchaHelpers} from '@/utils/recaptcha-helpers.js';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'ContactUsScript',
  props: {
    reCaptchaTokenToggle: {
      type: String,
      default: '',
    },
    reCaptchaSiteKey: {
      type: String,
      default: '',
    },
    reCaptchaFailRedirect: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    customerId: {
      type: String,
      default: '',
    },
  },
  computed: {
    isTranslationsLoaded() {
      return !isEmpty(window.VueI18n.messages);
    },
    messageMap() {
      return {
        firstName: this.$t('contactUs.firstName.error'),
        lastName: this.$t('contactUs.lastName.error'),
        postalCode: {
          required: this.$t('contactUs.zip.error'),
          zipCode: this.$t('contactUs.zip.valid.check'),
        },
        email: {
          required: this.$t('contactUs.email.error'),
          email: this.$t('contactUs.email.valid.check'),
        },
        messageText: this.$t('contactUs.question.error'),
        topic: this.$t('contactUs.topic.error'),
        phone: {
          required: this.$t('contactUs.phone.error'),
          phoneNumber: this.$t('contactUS.phone.valid.check'),
        },
        securityCode: this.$t('contactUs.securityCode.required'),
        invalidCode: this.$t('contactUs.invalidCode'),
      };
    },
    reCaptchaTokenData() {
      return {
        reCaptchaTokenToggle: this.reCaptchaTokenToggle,
        isReCaptchaEnabled: false,
        reCaptchaSiteKey: this.reCaptchaSiteKey,
        reCaptchaToken: '',
        reCaptchaFailRedirect: this.reCaptchaFailRedirect,
      };
    },
  },
  watch: {
    isTranslationsLoaded(updatedValue, prevValue) {
      if (!prevValue && updatedValue) this.loadScript(this.reCaptchaTokenToggle);
    },
  },
  methods: {
    async loadScript(reCaptchaTokenToggle) {
      const reCaptcha = ReCaptchaHelpers();
      if (Boolean(reCaptchaTokenToggle) === false || this.customerId !== '') {
        new ContactUsForm({
          locale: this.locale,
          contactUsMessages: this.messageMap,
        });
        this.enableSubmitForm();
        return;
      }
      this.registerScriptCallback(reCaptcha);
      this.appendScript();
    },
    appendScript() {
      const SCRIPT_ID = 'contact-us-captcha';
      const reCaptchaScript = `https://www.google.com/recaptcha/api.js?onload=onLoadCallback&render=${this.reCaptchaSiteKey}`;
      headTag.appendScript(SCRIPT_ID, reCaptchaScript);
    },
    enableSubmitForm() {
      document.getElementById('SubmitContactUsBtn').classList.remove('disabled');
      document.getElementById('SubmitContactUsBtn').removeAttribute('disabled');
    },
    registerScriptCallback(reCaptcha) {
      window.onLoadCallback = async () => {
        try {
          this.reCaptchaTokenData.createToken = async () => {
            return reCaptcha.getReCaptchaToken(this.reCaptchaSiteKey, {action: 'contactUs'});
          };
          new ContactUsForm({
            tokenData: this.reCaptchaTokenData,
            locale: this.locale,
            contactUsMessages: this.messageMap,
          });
        } catch (error) {
          console.error(error);
        }
      };
    },
  },
  render: () => null,
};
</script>
