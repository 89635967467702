<template>
  <label
    :id="`${mode.code}-delivery-mode`"
    :class="['form__retailer-option', isSelectedDeliveryMode ? 'selected' : '']"
  >
    <span class="form__retailer-checkbox">
      <input
        v-model="selectedDeliveryModeValue"
        class="form__radio"
        type="radio"
        name="deliveryMethod"
        :qaid="`delivery-method-${mode.code}__input`"
        :value="mode.code"
        @change="handleDeliveryModeChange"
      />
    </span>
    <span class="form__retailer-details">
      <strong class="form__retailer-title m-0 text-md" :qaid="`delivery-method-${mode.code}__name`">
        {{ mode.name }} - {{ mode.deliveryCost.formattedValue }}
      </strong>
      <span class="text-sm" :qaid="`delivery-method-${mode.code}__description`">
        {{ mode.description }}
      </span>
      <slot name="delivery-method-specifics" />
    </span>
  </label>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import {ShipToStoreMode} from '@/constants/dealer-list';
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';

export default {
  props: {
    mode: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      selectedDeliveryModeValue: {required},
    };
  },
  computed: {
    selectedDeliveryModeValue: {
      get() {
        return this.selectedDeliveryMode;
      },
      set(value) {
        this.setSelectedDeliveryMode(value);
      },
    },
    isSelectedDeliveryMode() {
      return this.mode?.code === this.selectedDeliveryModeValue;
    },
    ...mapState('checkout', ['hasBikes', 'selectedDeliveryMode']),
  },

  mounted() {
    document
      .querySelector(`#${this.mode.code}-delivery-mode .${this.mode.code}-delivery-specifics`)
      ?.classList.remove('hide');
  },

  methods: {
    ...mapMutations('checkout', ['setSelectedDeliveryMode', 'setShipToStoreMode']),
    ...mapActions('checkout', ['getDealers']),
    handleDeliveryModeChange(event) {
      this.selectedDeliveryModeValue = event.target.value;
      this.setShipToStoreMode(this.mode.code?.includes(ShipToStoreMode));

      if (!this.hasBikes && this.mode.code?.includes(ShipToStoreMode)) {
        this.getDealers();
      }
    },
  },
};
</script>
