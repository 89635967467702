<template>
  <div>
    <pdl-callout v-if="showSavedDisclaimer" icon="history" qaid="warranty-claim-save-disclaimer">
      <template #content>
        <span v-html="savedDisclaimer" />
      </template>
    </pdl-callout>

    <pdl-callout v-if="showErrorCallout" kind="error" qaid="warranty-claim-global-errors">
      <template #content>
        <span>{{ $t('form.global.error') }}</span>
      </template>
    </pdl-callout>

    <pdl-callout
      v-for="(callout, calloutIndex) in claimCallouts"
      :key="calloutIndex"
      kind="error"
      qaid="warranty-claim-callout"
    >
      <template #content>
        <span v-html="callout" />
      </template>
    </pdl-callout>

    <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1" qaid="warranty-claim-header">{{ claimHeader }}</pdl-heading>
      </template>
    </pdl-section-header>

    <p qaid="warranty-claim-fields-required">{{ $t('warrantyClaims.B2B.allFieldsRequired') }}</p>

    <section class="mt-5">
      <!-- Header -->
      <pdl-section-header size="md" :is-dividing="true" class="mb-3">
        <template slot="content">
          <pdl-heading :level="1" qaid="warranty-claim-contact-info">{{
            $t('warrantyClaims.B2B.claimContactInfo')
          }}</pdl-heading>
        </template>
      </pdl-section-header>

      <!-- Contact Info -->
      <div class="grid-x grid-margin-x">
        <div class="cell medium-4">
          <form-group
            type="text"
            name="contactName"
            qaid="warranty-claim-contact-person"
            :label="$t('text.contactPerson')"
            :error="getErrorMessage('contactName')"
            required
            show-error-messages
            force-display-error
            :value="claimData.contactName"
            @blur="updateVuex"
          />
        </div>
        <div class="cell medium-4">
          <form-group
            type="text"
            name="contactPhoneNumber"
            qaid="warranty-claim-contact-phone"
            :maxlength="26"
            :label="$t('text.address.phone')"
            :error="getErrorMessage('contactPhoneNumber')"
            required
            show-error-messages
            force-display-error
            :value="claimData.contactPhoneNumber"
            @blur="updateVuex"
          />
        </div>
        <div class="cell medium-4">
          <form-group
            type="text"
            name="contactEmail"
            qaid="warranty-claim-contact-email"
            :label="$t('text.address.email')"
            :value="claimData.contactEmail"
            @blur="updateVuex"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {PdlCallout} from '@pedal/pdl-callout';
import FormGroup from '@/components/FormGroup';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {mapGetters, mapState, mapActions} from 'vuex';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
import {useVuelidate} from '@vuelidate/core';

export default {
  components: {PdlSectionHeader, PdlHeading, PdlCallout, FormGroup},
  mixins: [TrekValidationMixin],
  inject: ['anyErrors'],
  props: {
    showSuccess: {
      type: Boolean,
      default: false,
    },
    claimNumber: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      contactName: '',
      contactPhoneNumber: '',
    };
  },

  validations() {
    return {
      contactName: {
        required: this.trekValidators.required('text.contactPerson'),
      },
      contactPhoneNumber: {
        required: this.trekValidators.required('text.address.phone'),
      },
    };
  },
  computed: {
    ...mapGetters('warrantyClaims', ['isActionRequiredStatus']),
    ...mapState('warrantyClaims', ['claimData']),

    isNewClaim() {
      return this.claimData && this.claimData.status === 'enumClaimStatusNew';
    },

    savedDisclaimer() {
      if (this.claimData && this.claimData.lastSavedTime) {
        // Transform server GMT Time to local browser time
        const newDate = new Date(this.claimData.lastSavedTime);

        if (newDate instanceof Date && !isNaN(newDate)) {
          const timeZone = newDate.toString().match(/\((.*)\)/)[1];
          const minutes = newDate.getMinutes().toString().padStart('2', 0);

          return this.$t('warrantyClaims.B2B.lastSaved', [
            this.claimData.lastSavedDate,
            `${newDate.getHours()}:${minutes} ${timeZone}`,
          ]);
        }
      }

      return '';
    },

    showSavedDisclaimer() {
      return this.claimData.id && !this.isActionRequiredStatus;
    },

    claimHeader() {
      if (!this.claimData.id) {
        return this.$t('warrantyClaims.B2B.newClaim');
      }

      return this.$t('warrantyClaims.B2B.claim.number', [this.claimData.id]);
    },

    claimCallouts() {
      const callouts = [];
      this.claimData?.claimLines?.forEach((claimLine, index) =>
        claimLine.waitingCodeList?.forEach((code) => {
          const claimIndex = index + 1;
          const calloutMessages = {
            D: !claimLine.warrantyDamageImage.name && this.$t('warrantyClaims.B2B.warning.damageImage', [claimIndex]),
            I:
              !claimLine.warrantyOptionalAttachments[0]?.name &&
              this.$t('warrantyClaims.B2B.warning.image', [claimIndex]),
            S: this.$t('warrantyClaims.B2B.warning.validSerial', [claimIndex]),
            F: this.$t('warrantyClaims.B2B.warning.sizeRequired', [claimIndex]),
          };

          calloutMessages[code] && callouts.push(calloutMessages[code]);
        })
      );

      return callouts;
    },

    showErrorCallout() {
      return this.anyErrors();
    },
  },
  watch: {
    claimData(updatedClaimData) {
      this.contactName = updatedClaimData.contactName;
      this.contactPhoneNumber = updatedClaimData.contactPhoneNumber;
    },
  },
  methods: {
    ...mapActions('warrantyClaims', ['updateProperty']),

    updateVuex(e) {
      const targetName = e.target.getAttribute('name');
      this.updateProperty({
        propName: targetName,
        value: e.target.value,
      });

      if (this[targetName] === undefined) return;

      this[targetName] = e.target.value;

      this.v$[targetName]?.$touch();
    },

    redirect() {
      window.location = `${ACC.config.encodedContextPath}/warranty-claim/all-warranty-claims/`;
    },
  },
};
</script>
