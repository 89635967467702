<template>
  <pdl-icon size="32">
    <svg
      id="bike-stand-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      style="enable-background: new 0 0 64 64"
      xml:space="preserve"
    >
      <circle class="st0" cx="13.7" cy="28.7" r="11.7" />
      <circle class="st0" cx="50.3" cy="28.7" r="11.7" />
      <circle class="st0" cx="13.7" cy="28.7" r="3.3" />
      <circle class="st0" cx="50.3" cy="28.7" r="3.3" />
      <circle class="st1" cx="32" cy="30.2" r="3.3" />
      <line class="st0" x1="29.9" y1="27.3" x2="20.8" y2="6.3" />
      <polyline class="st0" points="33.8,27.3 43.1,11.6 24,13.6 15.4,25.6 " />
      <polyline class="st0" points="48.9,25.5 41.1,5.8 47.6,5.8 " />
      <line class="st0" x1="18" y1="6.3" x2="24.8" y2="6.3" />
      <line class="st0" x1="17.4" y1="28.9" x2="28.4" y2="30.3" />
      <line class="st0" x1="32" y1="8.9" x2="32" y2="2" />
      <path class="st0" d="M32,8.9" />
      <line class="st0" x1="32" y1="59.7" x2="32" y2="16.6" />
      <line class="st0" x1="14.4" y1="62" x2="32" y2="50" />
      <line class="st0" x1="49.6" y1="62" x2="32" y2="50" />
      <path class="st0" d="M47.5,5.8c1.6,0,2.9,1.3,2.9,2.9s-1.3,2.9-2.9,2.9" />
      <line class="st0" x1="34.7" y1="9.1" x2="29.2" y2="9.7" />
      <line class="st0" x1="29.2" y1="16.4" x2="34.7" y2="15.8" />
    </svg>
  </pdl-icon>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {
    PdlIcon,
  },
};
</script>

<style scoped>
.st0,
.st1 {
  fill: none;
  stroke: var(--gray-100);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st1 {
  fill: var(--gray-100);
}
</style>
