<template>
  <div id="lcly-button-0" data-switchlive="true" data-switchlive-impression="true" data-switchlive-impression-id-PL="1">
    <a
      id="lcly-link-0"
      data-switchlive="true"
      data-switchlive-mode="auto"
      data-switchlive-id-PL="6"
      href="https://brands.locally.com"
      target="_blank"
      rel="noopener"
    >
      <span v-html="$t('storeFinder.locally.by')" />
    </a>
  </div>
</template>

<script>
import {Url} from '@/utils/url';
import headTag from '@/utils/head-tag';

export default {
  props: {
    options: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    locallyConfig() {
      return {
        company_name: this.options.companyName,
        company_id: this.options.companyId,
        inline: '1',
        lang: this.$store.state.locale.toLowerCase().replace(/_/g, '-'),
        no_link: '1',
      };
    },
    locallyQuery() {
      return Url.objectToQueryStringArray(this.locallyConfig).join('&');
    },
  },
  mounted() {
    this.appendLocallyScript();
  },
  methods: {
    appendLocallyScript() {
      headTag.appendScript('lcly-script-0', `${this.options.baseUrl}/stores/map.js?${this.locallyQuery}`);
      headTag.appendAsyncScript(
        'lcly-script-switchlive-0',
        `${this.options.baseUrl}/js/switchlive.js?company_id=${this.options.companyId}`
      );
      headTag.appendAsyncScript(
        'lcly-script-switchlive-events-0',
        `${this.options.baseUrl}/switchlive/switchlive-events.js?company_id=${this.options.companyId}`
      );
    },
  },
};
</script>
