<template>
  <div>
    <pdl-callout v-if="showErrorCallout" kind="error" qaid="product-feedback__global-errors">
      <template #content>
        <span>{{ $t('form.global.error') }}</span>
      </template>
    </pdl-callout>
    <pdl-section-header size="xl" :is-dividing="true">
      <template slot="content">
        <pdl-heading :level="1" qaid="product-feedback__header">
          {{ $t('warrantyClaims.B2B.feedback.submitProduct') }}
        </pdl-heading>
      </template>
    </pdl-section-header>

    <section class="mt-5">
      <!-- Header -->
      <pdl-section-header size="md" :is-dividing="true">
        <template slot="content">
          <pdl-heading :level="3" qaid="product-feedback__contact-info-header">
            {{ $t('warrantyClaims.B2B.feedback.contact') }}
          </pdl-heading>
        </template>
      </pdl-section-header>

      <!-- Contact Info -->
      <div class="grid-x grid-margin-x">
        <div class="cell medium-4">
          <form-group
            type="text"
            name="contactName"
            qaid="product-feedback__contact-person"
            :error="getErrorMessage('contactName')"
            :label="$t('text.contactPerson')"
            :required="true"
            show-error-messages
            force-display-error
            :value="contactName"
            @input="updateFieldBasedUserData('contactName', $event)"
            @blur="validateFieldOnBlur('contactName')"
          />
        </div>
        <div class="cell medium-4">
          <form-group
            type="text"
            name="contactPhoneNumber"
            qaid="product-feedback__contact-phone"
            :maxlength="26"
            :error="getErrorMessage('contactPhoneNumber')"
            show-error-messages
            force-display-error
            :label="$t('text.address.phone')"
            :value="contactPhoneNumber"
            @input="updateFieldBasedUserData('contactPhoneNumber', $event)"
            @blur="validateFieldOnBlur('contactPhoneNumber')"
          />
        </div>
        <div class="cell medium-4">
          <form-group
            type="text"
            name="contactEmail"
            qaid="product-feedback__contact-email"
            :error="getErrorMessage('contactEmail')"
            show-error-messages
            force-display-error
            :label="$t('address.email')"
            :required="true"
            :value="contactEmail"
            @input="updateFieldBasedUserData('contactEmail', $event)"
            @blur="validateFieldOnBlur('contactEmail')"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import {PdlCallout} from '@pedal/pdl-callout';
import FormGroup from '@/components/FormGroup';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  components: {PdlCallout, FormGroup, PdlSectionHeader, PdlHeading},
  mixins: [TrekValidationMixin],
  props: {
    showSuccess: Boolean,
  },
  computed: {
    ...mapState('productFeedback', ['feedbackData', 'showErrorCallout']),
    contactName() {
      return this.feedbackData.contactName || '';
    },
    contactPhoneNumber() {
      return this.feedbackData.contactPhoneNumber || '';
    },
    contactEmail() {
      return this.feedbackData.contactEmail || '';
    },
  },

  validations() {
    const validations = {
      contactName: {
        required: this.trekValidators.required('text.contactPerson'),
      },
      contactPhoneNumber: {
        maxLength: this.trekValidators.maxLength('text.address.phone', 26),
      },
      contactEmail: {
        required: this.trekValidators.required('address.email'),
      },
    };
    return validations;
  },
  methods: {
    ...mapMutations('productFeedback', ['updateFeedbackProperty', 'updateUserData', 'updateFlag']),
    updateFieldBasedUserData(propName, event) {
      if (this.v$[propName].$error) this.updateFlag({flagName: 'showErrorCallout', value: true});
      this.updateUserData({propName, value: event});
    },
    validateFieldOnBlur(propName) {
      this.v$[propName].$validate().then(() => {
        if (this.v$[propName].$error) this.updateFlag({flagName: 'showErrorCallout', value: true});
      });
    },
  },
};
</script>
