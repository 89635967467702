<template>
  <div>
    <template v-if="isAllProductTypesOnHold">
      <pdl-callout kind="warning" qaid="callout-order-hold">
        <template #content>
          <div class="flex flex-1 justify-between text-base">
            <p>{{ $t('checkout.B2B.hold.order.cannotAddAllocated') }}</p>
            <p>
              <a :href="`${encodedContextPath}/checkout/multi/order-review`" class="js-shipments-listener">
                {{ $t('checkout.B2B.proceedToReview') }}
              </a>
            </p>
          </div>
        </template>
      </pdl-callout>
    </template>
    <template v-else-if="productTypesOnHold.length > 0">
      <pdl-callout
        v-for="productType in productTypesOnHold"
        :key="productType"
        kind="warning"
        qaid="callout-order-hold"
      >
        <template #content>
          <div class="flex flex-1 justify-between text-base">
            <p>{{ messagesMap[productType] }}</p>
            <trek-button text @click="changeGridTab(productType)">
              {{ linkLabelMap[productType] }}
            </trek-button>
          </div>
        </template>
      </pdl-callout>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {PdlCallout} from '@pedal/pdl-callout';
import TrekButton from '@/components/TrekButton';
import {scrollToElement} from '@/utils/scroll-to';

export default {
  components: {PdlCallout, TrekButton},

  props: {
    productTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      messagesMap: {
        BIKE: this.$t('checkout.B2B.hold.bike.cannotAddAllocated'),
        AFTERMARKET: this.$t('checkout.B2B.hold.accessory.cannotAddAllocated'),
      },
      linkLabelMap: {
        BIKE: this.$t('myCart.B2B.review.bikes'),
        AFTERMARKET: this.$t('text.reviewAccessories'),
      },
    };
  },

  computed: {
    productTypesOnHold() {
      return this.holdOptions
        ? Object.keys(this.holdOptions).filter((productType) => this.holdOptions[productType])
        : [];
    },
    isAllProductTypesOnHold() {
      return this.productTypes.length === this.productTypesOnHold.length;
    },
    ...mapState('backend', ['encodedContextPath']),
    ...mapState('allocatedItems', ['holdOptions']),
  },

  methods: {
    changeGridTab(productType) {
      this.$emit('grid-tab-change', productType);
      scrollToElement('#checkoutTabs', 150);
    },
  },
};
</script>
