<template>
  <label :class="['form__retailer-option', isSelectedStore ? 'selected' : '']">
    <span class="form__retailer-checkbox">
      <input
        v-model="selectedStoreValue"
        class="form__radio"
        type="radio"
        name="selectedStore"
        :qaid="`retailer-option-${index}__input`"
        :value="store.name"
        @change="handleStoreChange"
      />
    </span>
    <span class="form__retailer-details">
      <strong class="block m-0 text-md" :qaid="`retailer-option-${index}__name`">
        <span qaid="retailer-summary-title">{{ store.displayName }}</span>
        <em v-if="store.formattedDistance" class="text-small-emphasized">
          ({{ store.formattedDistance }} {{ distanceFormats[store.currentLocaleDistanceUnit] }})
        </em>
      </strong>
      <span qaid="retailer-summary-address" class="text-sm">
        {{ store.address.line1 }}
        <span v-if="store.address.line2">, {{ store.address.line2 }}</span>
        <span v-if="store.address.town">, {{ store.address.town }}</span>
        <span v-if="store.address.region && store.address.region.name">, {{ store.address.region.name }}</span>
        <span v-if="store.address.regionText">, {{ store.address.regionText }}</span>
        <span v-if="store.address.postalCode">, {{ store.address.postalCode }}</span>
      </span>
      <strong
        v-for="(message, messageIndex) in store.userAdditionalMessages"
        :key="messageIndex"
        class="block text-sm m-0 text-red"
        :qaid="`retailer-option-${messageIndex}__dealer-delivers`"
      >
        {{ $t(message) }}
      </strong>
    </span>
  </label>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import {DistanceFormats} from '@/constants/dealer-list';
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';

export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    store: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      selectedStoreValue: {required},
    };
  },
  data() {
    return {
      distanceFormats: DistanceFormats,
    };
  },
  computed: {
    selectedStoreValue: {
      get() {
        return this.selectedStore;
      },
      set(value) {
        this.setSelectedStore(value);
      },
    },
    isSelectedStore() {
      return this.store?.name === this.selectedStoreValue;
    },
    ...mapState('checkout', ['hasBikes', 'selectedStore']),
  },

  methods: {
    ...mapMutations('checkout', ['setSelectedStore', 'setIsVisibleDeliveryModes']),
    ...mapActions('checkout', ['getDeliveryModes']),

    handleStoreChange(event) {
      this.selectedStoreValue = event.target.value;
      if (this.hasBikes) {
        this.setIsVisibleDeliveryModes(true);
        this.getDeliveryModes();
      }
    },
  },
};
</script>
