<template>
  <div>
    <div class="form-group--inline justify-start">
      <label for="selectSortby" class="form-label">
        {{ $t('search.page.sortTitle') }}
      </label>
      <div class="select select--clear w-auto inline-block">
        <select
          id="selectSortby"
          v-model="sortKey"
          class="form-control w-auto"
          @change="$emit('update-sort-type', sortKey)"
        >
          <option
            v-for="(column, colIndex) in sortOptions"
            :key="colIndex"
            :value="column.param"
            :qaid="`sort-list.option-${column.param}`"
          >
            {{ column.name }}
          </option>
        </select>
        <div class="focus-indicator"></div>
      </div>
    </div>

    <span v-if="minQtyErrorPresent">{{ $t('distributor.B2B.skuGrid.errorMessage') }}</span>

    <table class="b2b-grid b2b-grid--collapse is-compact" qaid="saved-list-sku-grid">
      <thead class="b2b-grid__header">
        <tr>
          <th class="b2b-grid__cell b2b-grid__head" qaid="sku-grid.checkbox">
            <label class="checkbox">
              <input
                id="saved-lists-select-all"
                type="checkbox"
                :checked="allSelected"
                :disabled="disableInteractions"
                @change="selectAll"
              />
              <span class="control-indicator"></span>
            </label>
          </th>
          <th class="b2b-grid__cell b2b-grid__head w-5" qaid="sku-grid.note"></th>
          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="sku-grid.sku">
            {{ $t('text.account.savedLists.sku') }} | {{ $t('text.account.savedLists.upc') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head" qaid="sku-grid.description">
            {{ $t('text.account.savedLists.description') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head uppercase lg:hidden" qaid="sku-grid.upc">
            {{ $t('text.account.savedLists.upc') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head whitespace-nowrap" qaid="sku-grid.date-added">
            {{ $t('text.account.savedLists.dateAdded') }}
          </th>
          <!-- Variable warehouse headers (1 to 5) -->
          <template v-if="hasWarehouses(warehouseList)">
            <th
              v-for="(warehouse, warehouseKey) in warehouseList"
              :key="warehouseKey"
              class="b2b-grid__cell b2b-grid__head uppercase"
              data-type="warehouse"
              qaid="sku-grid.warehouse"
            >
              {{ warehouse }}
            </th>
            <th
              class="b2b-grid__cell b2b-grid__head whitespace-nowrap"
              data-type="stock-watch"
              qaid="sku-grid.stock-watch"
            >
              {{ $t('text.account.savedLists.stockWatch') }}
            </th>
          </template>
          <th v-if="!isConsumerFriendlyMode" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid.order-price">
            {{ $t('order.price') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="sku-grid.qty">
            {{ $t('text.account.savedLists.qty') }}
          </th>
          <th v-if="!isConsumerFriendlyMode" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid.subtotal">
            {{ $t('text.order.subtotal') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- Each Row -->
        <template v-for="(row, rowIndex) in paginatedData">
          <saved-list-grid-item
            :key="`${row.sku}-${rowIndex}`"
            :row="row"
            :row-index="rowIndex"
            :disable-interactions="disableInteractions"
            :entries-name="entriesName"
            :min-qty-move-error="minQtyMoveFailedList && minQtyMoveFailedList[row.sku] ? true : false"
            :min-qty-move-error-msg="minQtyMoveFailedList[row.sku] ? minQtyMoveFailedList[row.sku] : null"
            :warehouse-codes="warehouseList"
            @refresh="refresh"
            @min-qty-error-status-emitter="minQtyErrorPresentUpdater"
            @send-list-update="sendListUpdate"
          />
        </template>
      </tbody>
    </table>

    <div v-show="selectedList.id">
      <div class="flex flex-wrap mb-1 pb-5">
        <div class="w-full lg:w-auto lg:shrink self-center text-center pb-5">
          <pagination-controls
            v-if="pageCount > 1"
            ref="pagination"
            :page-number="pageNumber"
            :page-count="pageCount"
            @page-change="pageChange"
            @prev-page="prevPage"
            @next-page="nextPage"
          ></pagination-controls>
        </div>
        <slot name="summary"></slot>
        <div class="w-full lg:w-auto lg:grow">
          <div class="buttons buttons--right-for-md">
            <pdl-loading :is-loading="movingToCart">
              <trek-button
                v-if="isB2BPurchasable"
                primary
                icon="shopping_cart"
                qaid="saved-lists.button-move-to-cart"
                :disabled="!cartToggle || disableActions"
                @click="$emit('grid-move-to-cart')"
              >
                <span>{{ $t('text.account.savedLists.moveToCart') }}</span>
              </trek-button>
            </pdl-loading>
            <trek-button
              v-if="!disableInteractions"
              secondary
              qaid="saved-lists.button-remove"
              :disabled="disableActions"
              @click="$emit('grid-remove')"
            >
              <span>{{ $t('text.account.savedLists.remove') }}</span>
            </trek-button>
            <trek-button
              v-if="!selectedList.readOnly && !disableInteractions"
              tertiary
              qaid="saved-lists.button-delete"
              :disabled="disableInteractions"
              @click="$emit('grid-delete')"
            >
              <span>{{ $t('text.account.savedLists.delete') }}</span>
            </trek-button>
          </div>
        </div>
      </div>
    </div>

    <pdl-dialog
      :z-index="16000011"
      :visible.sync="dialogVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      :title="$t('text.account.savedLists.item.confirmDelete')"
      qaid="saved-list-qty-zero-dialog"
      @close="closeDialog"
    >
      <template slot="footer">
        <div class="buttons buttons--right">
          <trek-button alert qaid="saved-lists.delete.row.yes" @click="confirmDelete">
            <span>
              {{ $t('text.account.savedLists.yesDelete') }}
            </span>
          </trek-button>
          <trek-button secondary qaid="saved-lists.delete.row.no" @click="closeDialog">
            <span>{{ $t('text.account.savedLists.save') }}</span>
          </trek-button>
        </div>
      </template>
    </pdl-dialog>
  </div>
</template>

<script>
import {PdlDialog} from '@pedal/pdl-dialog';
import pagination from '@/mixins/pagination';
import PaginationControls from '@/components/PaginationControls';
import SavedListGridItem from '@/components/saved-lists/SavedListGridItem';
import {mapActions, mapMutations, mapState} from 'vuex';
import TrekButton from '@/components/TrekButton';
import {PdlLoading} from '@pedal/pdl-loading';
import warehouseMixin from '@/mixins/warehouse';

export default {
  components: {PdlDialog, TrekButton, PaginationControls, SavedListGridItem, PdlLoading},

  mixins: [pagination, warehouseMixin],

  props: {
    disableInteractions: {
      type: Boolean,
      default: false,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
    entriesName: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    size: {
      type: Number,
      required: false,
      default: 15,
    },
    minQtyMoveFailedList: {
      type: Object,
      default: () => null,
    },
    movingToCart: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialogVisible: false,
      sku: null,
      sortKey: 'savedlistdateadded',
      sortOptions: [
        {name: this.$t('text.account.savedLists.dateAdded'), param: 'savedlistdateadded'},
        {name: this.$t('text.account.savedLists.description'), param: 'description'},
        {name: this.$t('text.account.savedLists.qty'), param: 'quantity'},
        {name: this.$t('text.account.savedLists.sku'), param: 'code'},
      ],
      minQtyUpdateErrorMsg: [],
      minQtyErrorPresent: false,
    };
  },

  computed: {
    ...mapState('savedLists', ['selectedList']),
    ...mapState('backend', ['isB2BPurchasable', 'cartToggle', 'isConsumerFriendlyMode']),

    listData() {
      return this.list;
    },

    allSelected() {
      return this.selectedList[this.entriesName].every((entry) => {
        return entry.checked;
      });
    },

    warehouseList() {
      return this.getUniqueWarehouseCodesFromAllProductVariants(this.list);
    },
  },

  watch: {
    listData() {
      this.pageNumber = 0;
    },
  },

  created() {
    this.$on('row:delete', this.openDialog);
  },

  methods: {
    ...mapActions('savedLists', ['removeSelectedEntries', 'fetchList', 'updateQty']),
    ...mapMutations('savedLists', ['setRowChecked']),

    refresh() {
      this.fetchList({id: this.selectedList.id});
    },

    minQtyErrorPresentUpdater(status) {
      this.minQtyErrorPresent = status;
    },

    minQtyUpdateErrorUpdater(msg) {
      this.minQtyUpdateErrorMsg = msg;
    },

    selectAll(event) {
      if (this.selectedList[this.entriesName]) {
        this.selectedList[this.entriesName].forEach((entry, rowIndex) => {
          this.setRowChecked({rowIndex, entriesType: this.entriesName, value: event.target.checked});
        });
      }
    },

    openDialog(sku) {
      this.sku = sku;
      this.dialogVisible = true;
    },

    closeDialog() {
      this.$emit('update-sort-type', this.sortKey); // Refresh list after user cancels initial Qty change
      this.sku = null;
      this.dialogVisible = false;
    },

    async sendListUpdate(listData) {
      const response = await this.updateQty({
        sku: listData.sku,
        qty: listData.qty,
        checked: listData.checked,
        lineNote: listData.lineNote.length > 0 ? listData.lineNote : null,
      });

      if (response.data.failedSKUs) {
        this.minQtyItemUpdateError = true;
        this.minQtyUpdateErrorMessage = (response.data.failedSKUs || []).map(
          (item) => item.message.basePropertyValue
        )[0];
      } else {
        this.minQtyItemUpdateError = false;
        this.minQtyUpdateErrorMessage = null;
      }

      await this.refresh();
    },

    confirmDelete() {
      this.dialogVisible = false;
      this.removeSelectedEntries([this.sku]).then(() => {
        this.sku = null;
      });
    },
  },
};
</script>
