<template>
  <div class="grid-container">
    <form :class="{'opacity-50': isWorking}" @submit.prevent="submit">
      <pdl-section-header class="mt-3" :is-dividing="true" size="xl">
        <template slot="content">
          <pdl-heading :level="1">
            {{ $t('text.editProfile') }}
          </pdl-heading>
        </template>
      </pdl-section-header>
      <pdl-callout v-if="message" :kind="messageType">
        <template #content>
          <span v-html="message" />
        </template>
      </pdl-callout>
      <div class="max-w-sm mb-6">
        <form-group
          v-model="firstNameValue"
          type="text"
          name="firstName"
          :label="$t('profile.firstName')"
          :error="getErrorMessage('firstNameValue')"
          :show-error-messages="true"
          :maxlength="19"
          required
          force-display-error
        />
        <form-group
          v-model="lastNameValue"
          type="text"
          name="lastName"
          :label="$t('profile.lastName')"
          :error="getErrorMessage('lastNameValue')"
          :show-error-messages="true"
          :maxlength="19"
          required
          force-display-error
        />
        <section v-if="!b2b">
          <div class="form-group w-1/4 md:w-1/2">
            <div class="form-label">
              {{ $t('garage.user.height') }}
            </div>
            <div class="flex flex-col md:flex-row gap-4">
              <form-group
                v-model="heightIntegerValue"
                class="flex-auto mb-0"
                type="text"
                :maxlength="1"
                :show-label="false"
                :show-error-messages="true"
                name="heightInteger"
                :label="helpText.heightInteger"
                :help-text="helpText.heightInteger"
                :error="getErrorMessage('heightIntegerValue')"
                force-display-error
              />
              <form-group
                v-model="heightRemainderValue"
                class="flex-auto mb-0"
                type="text"
                :maxlength="2"
                :show-label="false"
                :show-error-messages="true"
                name="heightRemainder"
                :label="helpText.heightRemainder"
                :help-text="helpText.heightRemainder"
                :error="getErrorMessage('heightRemainderValue')"
                force-display-error
              />
            </div>
          </div>
          <form-group
            v-model="weightValue"
            class="w-1/4 md:w-1/2"
            type="text"
            name="weight"
            :maxlength="3"
            :label="$t('garage.user.weight')"
            :show-error-messages="true"
            :help-text="helpText.weight"
            :error="getErrorMessage('weightValue')"
            force-display-error
          />
          <em class="text-sm text-gray-60 italic mb-3 block">
            {{ $t('profile.heightWeight.message') }}
          </em>
        </section>
        <div class="buttons">
          <trek-button primary type="submit" :disabled="isWorking" @click="submit">
            <span>{{ $t('text.account.profile.saveUpdates') }}</span>
          </trek-button>

          <trek-button secondary @click="cancel">
            <span>{{ $t('text.account.profile.cancel') }}</span>
          </trek-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import {PdlCallout} from '@pedal/pdl-callout';
import FormGroup from '@/components/FormGroup';
import TrekButton from '@/components/TrekButton';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {UserAccountBase} from '@/components/containers/my-account/utils/user-account-base';

export default {
  name: 'EditProfileContainer',
  components: {PdlCallout, FormGroup, TrekButton, PdlSectionHeader, PdlHeading},
  extends: UserAccountBase,
  props: {
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    heightInteger: {
      type: String,
      default: null,
    },
    heightRemainder: {
      type: String,
      default: null,
    },
    weight: {
      type: String,
      default: null,
    },
    distanceUnit: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      firstNameValue: '',
      lastNameValue: '',
      heightIntegerValue: '',
      heightRemainderValue: '',
      weightValue: '',
    };
  },
  validations() {
    return {
      firstNameValue: {required: this.trekValidators.required('profile.firstName')},
      lastNameValue: {required: this.trekValidators.required('profile.lastName')},
      heightIntegerValue: {bodyParams: this.trekValidators.bodyParams()},
      heightRemainderValue: {heightRemainder: this.trekValidators.heightRemainder()},
      weightValue: {bodyParams: this.trekValidators.bodyParams()},
    };
  },
  computed: {
    ...mapState('backend', ['b2b']),
    helpText() {
      return {
        heightInteger: this.distanceUnit === 'MILES' ? this.$t('garage.user.feet') : this.$t('garage.user.meters'),
        heightRemainder:
          this.distanceUnit === 'MILES' ? this.$t('garage.user.inches') : this.$t('garage.user.centimeters'),
        weight: this.distanceUnit === 'MILES' ? this.$t('garage.user.lbs') : this.$t('garage.user.kg'),
      };
    },
  },
  watch: {
    heightIntegerValue(val) {
      this.heightIntegerValue = val.replace(/[^\d]/g, '');
    },
    heightRemainderValue(val) {
      this.heightRemainderValue = val.replace(/[^\d]/g, '');
    },
    weightValue(val) {
      this.weightValue = val.replace(/[^\d]/g, '');
    },
  },
  mounted() {
    this.firstNameValue = this.firstName;
    this.lastNameValue = this.lastName;
    this.heightIntegerValue = this.heightInteger;
    this.heightRemainderValue = this.heightRemainder;
    this.weightValue = this.weight;
  },
  methods: {
    /**
     * Handle email update.
     * @return {Promise<void>}
     */
    async processUpdate() {
      this.isWorking = true;
      try {
        const response = await this.api.updateProfile({
          firstName: this.firstNameValue,
          lastName: this.lastNameValue,
          heightInteger: this.heightIntegerValue.trim(),
          heightRemainder: this.heightRemainderValue.trim(),
          weight: this.weightValue.trim(),
          CSRFToken: this.options.CSRFToken,
        });

        this.displayMessage(response?.data);
      } catch (e) {
        this.displayError(e.message);
      } finally {
        this.isWorking = false;
      }
    },
  },
};
</script>
