<template>
  <section
    :id="uid"
    data-magellan-target="GeometryComponent"
    class="sizing-and-fit-aftermarket-component sizing-table grid-container mb-6"
  >
    <h2 v-if="b2b" class="header--lg mb-1 cell small-12" v-html="bp('geometry')" />
    <advanced-geometry-chart v-if="geometryChartScriptUrl" :geometry-chart-script-url="geometryChartScriptUrl" />
    <div v-else>
      <div v-if="content" class="cell small-12 mb-4">
        <p class="font-light">{{ content }}</p>
        <slot name="precision" />
      </div>
      <div class="grid-x grid-margin-x">
        <div id="spec-chart__container" class="cell medium-5">
          <slot name="geometry" />
        </div>
        <div class="cell medium-7">
          <p
            id="sizing-table__disclaimer"
            class="sizing-table__disclaimer mb-1 text-left"
            v-html="bp('sizeDisclaimer')"
          />
          <table id="sizing-table" class="sizing-table__table">
            <caption>
              Sizing table
            </caption>
            <thead class="sizing-table__head">
              <tr class="sizing-table__head-row">
                <th
                  v-for="(entry, index) in headers"
                  :key="index"
                  class="sizing-table__header px-2 py-25 text-xs font-semibold"
                >
                  <span v-if="indicators[entry]" class="sizing-table__alias">{{ indicators[entry] }} &mdash; </span>
                  {{ bp(entry) }}
                </th>
              </tr>
            </thead>
            <tbody class="sizing-table__body">
              <slot name="tbody" />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapState} from 'vuex';
import AdvancedGeometryChart from '@/components/pdp-widgets/AdvancedGeometryChart.vue';
export default {
  components: {AdvancedGeometryChart},
  props: {
    uid: {
      type: String,
      default: undefined,
    },
    content: {
      type: String,
      default: undefined,
    },
    headers: {
      type: Array,
      default: undefined,
    },
    indicators: {
      type: Object,
      default: undefined,
    },
    matrix: {
      type: Object,
      default: undefined,
    },
    geometryChartScriptUrl: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapState('backend', ['b2b']),
  },
};
</script>
