<template>
  <div class="skeleton" :class="[computedClasses]">
    <template v-if="isList">
      <p
        v-for="(item, i) in list"
        :key="`item-${i}`"
        :style="heightWidth"
        :class="`skeleton__${type} skeleton__${type}--items`"
      ></p>
    </template>
    <template v-else>
      <p v-if="type === types.circle" :style="heightWidth" class="skeleton__circle"></p>
      <p v-if="type === types.square" :style="heightWidth" class="skeleton__square"></p>
      <p v-if="type === types.rectangle" :style="heightWidth" class="skeleton__rectangle"></p>
    </template>
  </div>
</template>
<script>
export default {
  name: 'TrekSkeleton',
  props: {
    /**
     * Sets the height of the skeleton container. Customize for different
     * scales like px, %, rem.
     */
    height: {
      type: String,
      default: undefined,
    },
    /**
     * Sets the width of the skeleton container. Customize for different scales
     * like px, %, rem.
     */
    width: {
      type: String,
      default: undefined,
    },
    /**
     * Set the template to use a list.
     */
    isList: {
      type: Boolean,
      default: false,
    },
    /**
     * Set the amount of list items.
     */
    listCount: {
      type: Number,
      default: 3,
    },
    hasWrapperMargin: {
      type: Boolean,
      default: true,
    },
    /**
     * Sets the different types of skeletons.
     */
    type: {
      type: String,
      default: 'rectangle',
      validator: (type) => ['rectangle', 'circle', 'square'].includes(type),
    },
  },
  data() {
    return {
      types: {
        rectangle: 'rectangle',
        circle: 'circle',
        square: 'square',
      },
      list: Array(this.listCount),
    };
  },
  computed: {
    computedClasses() {
      return {
        'skeleton--items': this.isList,
        'skeleton--no-margin': !this.hasWrapperMargin,
      };
    },
    heightWidth() {
      return {
        height: `${this.height}`,
        width: `${this.width}`,
      };
    },
  },
};
</script>
<style lang="scss">
.skeleton {
  width: 100%;
  margin-bottom: 0.5rem;

  &--items {
    display: flex;
    flex-wrap: wrap;
  }

  &--no-margin {
    margin-bottom: 0;
  }

  .skeleton__rectangle,
  .skeleton__circle,
  .skeleton__square {
    background: var(--gray-10);
  }

  .skeleton__rectangle {
    height: 1rem;
    width: 100%;

    &.skeleton__rectangle--items {
      margin-bottom: 0.5rem;
    }
  }

  .skeleton__square,
  .skeleton__circle {
    height: 40px;
    width: 40px;

    &.skeleton__square--items,
    &.skeleton__circle--items {
      margin-right: 0.25rem;
      margin-bottom: 0.5rem;
    }

    &.skeleton__square--items:last-child,
    &.skeleton__circle--items:last-child {
      margin-right: 0;
    }
  }

  .skeleton__circle {
    border-radius: 50%;
  }

  animation: pulse 2s linear infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }
}
</style>
