<template>
  <div>
    <span class="flex items-center">
      {{ $t('checkout.tax.alabama.title1') }}
      <button class="ml-25 flex items-center" @click="showInfoDialog">
        <pdl-icon qaid="alabama-tax-icon" size="14" name="info_outline" />
      </button>
    </span>

    <pdl-dialog
      :visible.sync="dialogVisible"
      :title="$t('checkout.tax.alabama.title2')"
      :z-index="16000011"
      :close-dialog-tool-tip="$t('popup.close')"
      qaid="alabama-tax-dialog"
    >
      <p class="mb-2">{{ $t('checkout.tax.alabama.body1') }}</p>
      <p>{{ $t('checkout.tax.alabama.body2') }}</p>
    </pdl-dialog>
  </div>
</template>
<script>
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {PdlDialog, PdlIcon},
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    showInfoDialog() {
      this.dialogVisible = true;
    },
  },
};
</script>
