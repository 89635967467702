<template>
  <div :id="mapId" class="mapboxgl-map" :class="mapClasses"></div>
</template>

<script>
export default {
  props: {
    mapId: {
      type: String,
      default: 'mapContainer',
    },
    mapClasses: {
      type: String,
      default: '',
    },
  },
};
</script>
