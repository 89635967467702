<template>
  <div class="grid-container text-base">
    <pdl-section-header size="xl" :is-dividing="true">
      <template slot="content">
        <pdl-heading qaid="ps-order-form-order-form-heading" :level="1">
          {{ $t('text.virtualPackingSlip') }}
        </pdl-heading>
      </template>
      <template slot="actions">
        <div v-if="isVisibleDownloadButton" class="buttons buttons--right-for-md">
          <trek-button primary small qaid="packing-slip-download" @click="downloadPackingSlip">
            <span>{{ $t('text.download') }}</span>
          </trek-button>
        </div>
      </template>
    </pdl-section-header>
    <virtual-packing-slip-shipment-details :shipment-details="packingSlipData" />
    <virtual-packing-slip-order-details
      :packing-orders="packingSlipData.orderLines"
      :bill-to-number="packingSlipData.billToNumber"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import VirtualPackingSlipShipmentDetails from '@/components/containers/my-account/VirtualPackingSlipShipmentDetails';
import VirtualPackingSlipOrderDetails from '@/components/containers/my-account/VirtualPackingSlipOrderDetails';
export default {
  components: {PdlSectionHeader, PdlHeading, VirtualPackingSlipShipmentDetails, VirtualPackingSlipOrderDetails},
  props: {
    packingSlipData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('backend', ['encodedContextPath']),

    isVisibleDownloadButton() {
      const shipmentDate = new Date(this.packingSlipData.actualShipDate);
      const currentDate = new Date();
      const differenceInTime = currentDate.getTime() - shipmentDate.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      return differenceInDays < 90;
    },
  },
  methods: {
    downloadPackingSlip() {
      window.location.href = `${this.encodedContextPath}/my-account/packingslip-data/${this.packingSlipData.packingSlipNumber}/${this.packingSlipData.shipToNumber}`;
    },
  },
};
</script>
