'use strict';

function init() {
  const updateSelect = (select) => {
    var selectTag = $(select);
    var selectedVal = $(select).find('option:selected').text();
    var selectedTagId = selectTag[0].id;

    if (selectedTagId === 'addressLookupOptions') {
      addressLookUp(selectedVal, selectTag);
    } else {
      $(selectTag?.[0].dataset['selectDisplay']).html(selectedVal);
    }
  };

  function addressLookUp(selectedVal, selectTag) {
    var addressAbrv = selectedVal.slice(0, 25);
    $(selectTag.data('select-display')).html(addressAbrv + '...');
  }

  $('[data-select-display]').each(function () {
    var selectedVal = $(this).find('option:selected').text();
    $($(this).data('select-display')).html(selectedVal);
  });

  $(document).on('select-update', function () {
    $('[data-select-display]').each(function () {
      this.selectedIndex = 0;
      updateSelect(this);
    });
  });

  $(document).on('change', '[data-select-display]', function () {
    updateSelect(this);
  });
}

export default init;
