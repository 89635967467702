import {scrollToTop} from '@/utils/scroll-to';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';
import {PdlToastType} from '@/constants/pdl-toast-type';

const FormMessage = {
  watch: {
    message(value) {
      if (value) {
        this.$nextTick(scrollToTop());
      }
    },
  },

  methods: {
    /**
     * Hide message.
     */
    hideMessage() {
      this.message = '';
      this.messageType = 'default';
    },

    displayMessage(responseData) {
      const message = responseData?.meta?.feedback?.message?.basePropertyValue;

      if (checkMetaFeedbackType(responseData, MetaFeedbackType.SUCCESS)) {
        this.displaySuccess(message);
      }

      if (checkMetaFeedbackType(responseData, MetaFeedbackType.ERROR)) {
        this.displayError(message);
      }
    },

    /**
     * Display error message.
     * @param message
     */
    displayError(message) {
      this.messageType = PdlToastType.ERROR;
      this.message = message;
    },

    /**
     * Display success message.
     * @param message
     */
    displaySuccess(message) {
      this.messageType = PdlToastType.SUCCESS;
      this.message = message;
    },
  },
  data() {
    /**
     * using default message type so there are styles if no message type is provided
     */
    return {
      messageType: 'default',
      message: '',
    };
  },
};

export {FormMessage};
