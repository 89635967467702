<template>
  <div qaid="warranty-details-grid" class="my-6">
    <div class="flex flex-col md:flex-row justify-between">
      <p>
        <span qaid="warranty-details-order-number" class="font-medium"
          >{{ $t('text.account.packingslip.ordernumber') }}:&nbsp;</span
        >
        <pdl-link
          standalone
          content="warrantyDetails.code"
          qaid="warranty-order-link"
          :href="`${encodedContextPath}/my-account/order/${warrantyDetails.code}?company=${warrantyDetails.details.orderCompany}&ordertype=${warrantyDetails.details.orderType}`"
        >
          {{ warrantyDetails.code }}
        </pdl-link>
      </p>
      <div v-if="hasInvoices">
        <p v-for="invoice in consignment.invoices" :key="invoice.invoiceNumber">
          <span qaid="warranty-details-grid-invoice" class="font-medium">{{ $t('text.invoiceNumber') }}:&nbsp;</span>
          <pdl-link standalone qaid="warranty-invoice-download" @click="downloadInvoice(invoice.invoiceUrl)">
            {{ invoice.invoiceNumber }}
          </pdl-link>
        </p>
      </div>
    </div>
    <table class="b2b-grid b2b-grid--collapse is-compact table-fixed" qaid="warranty-details-order-grid">
      <caption class="sr-only">
        {{
          $t('text.account.packingslip.ordernumber')
        }}
        {{
          warrantyDetails.code
        }}
      </caption>
      <thead class="b2b-grid__header">
        <tr>
          <th
            v-for="(item, itemKey) in filterHeaders()"
            :key="itemKey"
            :qaid="`warranty-details-grid-header-${itemKey}`"
            :class="[
              'b2b-grid__cell',
              'b2b-grid__head',
              {'lg:w-1/4': itemKey === 'description'},
              {'lg:w-1/6': itemKey === 'status'},
            ]"
          >
            <span>{{ item }}</span>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="entry in entriesByConsignmentCode" :key="entry.id">
          <td qaid="warranty-details-grid-sku" class="b2b-grid__cell lg:table-cell">
            <div class="b2b-grid__label">{{ $t('text.itemNumber') }}</div>
            <div class="b2b-grid__content">
              <pdl-link
                v-if="hasProductLink(entry)"
                standalone
                qaid="pdp-sku-link"
                :href="`${encodedContextPath}/p/${entry.product.code}`"
              >
                <grid-text :data="getGridTextData(entry.product.code)" />
              </pdl-link>
              <grid-text v-else :data="getGridTextData(entry.product.code)" />
            </div>
          </td>

          <td qaid="warranty-details-grid-quantity" class="b2b-grid__cell lg:table-cell">
            <div class="b2b-grid__label">{{ $t('text.quantity') }}</div>
            <div class="b2b-grid__content">
              <grid-text :data="getGridTextData(entry.quantityOrdered)" />
            </div>
          </td>

          <td qaid="warranty-details-grid-description" class="b2b-grid__cell lg:table-cell">
            <div class="b2b-grid__label">{{ $t('distributor.B2B.advancedOrder.name') }}</div>
            <div v-if="entry.product.itemName" class="b2b-grid__content">
              <grid-text :data="getGridTextData(entry.product.itemName)" />
            </div>
          </td>

          <td qaid="warranty-details-grid-status" class="b2b-grid__cell lg:table-cell">
            <div class="b2b-grid__label">{{ $t('text.status') }}</div>
            <div class="b2b-grid__content">
              <div>
                <pdl-label :kind="calculateLabelKind(entry.lineStatus)">
                  {{ statusLabelBaseProps[entry.lineStatus] }}
                </pdl-label>
              </div>
            </div>
          </td>

          <td qaid="warranty-details-grid-upc" class="b2b-grid__cell lg:table-cell">
            <div class="b2b-grid__label">{{ $t('text.upcNumber') }}</div>
            <div v-if="entry.product.upc" class="b2b-grid__content">
              <grid-text :data="getGridTextData(entry.product.upc)" />
            </div>
          </td>

          <td qaid="warranty-details-grid-warehouse" class="b2b-grid__cell lg:table-cell">
            <div class="b2b-grid__label">{{ $t('text.warehouse') }}</div>
            <div class="b2b-grid__content">
              <grid-text :data="getGridTextData(entry.warehouse)" />
            </div>
          </td>

          <td
            v-if="!isConsumerFriendlyMode"
            qaid="warranty-details-grid-unit-price"
            class="b2b-grid__cell lg:table-cell"
          >
            <div class="b2b-grid__label">{{ $t('text.unitPrice') }}</div>
            <div class="b2b-grid__content">
              <grid-text :data="getGridTextData(entry.basePrice.formattedValue)" />
            </div>
          </td>

          <td
            v-if="!isConsumerFriendlyMode"
            qaid="warranty-details-grid-extended-price"
            class="b2b-grid__cell lg:table-cell"
          >
            <div class="b2b-grid__label">{{ $t('text.extendedPrice') }}</div>
            <div class="b2b-grid__content">
              <grid-text :data="getGridTextData(entry.totalPrice.formattedValue)" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import GridText from '@/components/grid/GridText';
import {PdlLink} from '@pedal/pdl-link';
import {PdlLabel} from '@pedal/pdl-label';
import {LineType} from '@/constants/warranty-line-types';

export default {
  components: {GridText, PdlLink, PdlLabel},

  props: {
    warrantyDetails: {
      type: Object,
      required: true,
    },
    consignment: {
      type: Object,
      default: () => null,
      required: false,
    },
  },
  data() {
    return {
      colHeadersCustomerFriendly: {
        itemNumber: this.$t('text.itemNumber'),
        quantity: this.$t('text.quantity'),
        description: this.$t('distributor.B2B.advancedOrder.name'),
        status: this.$t('text.status'),
        UPCNumber: this.$t('text.upcNumber'),
        warehouse: this.$t('text.warehouse'),
      },
      colHeaders: {
        unitPrice: this.$t('text.unitPrice'),
        extendedPrice: this.$t('text.extendedPrice'),
      },
      statusLabelBaseProps: {
        INVOICED: this.$t('text.account.order.status.invoiced'),
        IN_WAREHOUSE: this.$t('text.account.order.status.inWarehouse'),
        PROCESSING: this.$t('text.account.order.status.display.processing'),
        PLACED: this.$t('text.account.order.status.placed'),
        CANCELLED: this.$t('text.account.order.status.display.cancelled'),
        SHIPPED: this.$t('text.account.order.consignment.status.shipped'),
        AWAITING_RETURN: this.$t('text.account.order.status.awaitingReturn'),
      },
    };
  },
  computed: {
    hasInvoices() {
      return this.consignment?.invoices?.length;
    },

    entriesByConsignmentCode() {
      return this.warrantyDetails.entries?.filter((entry) => entry?.consignmentCode === this.consignment?.code);
    },

    ...mapState('backend', ['encodedContextPath']),
    ...mapState('backend', ['isConsumerFriendlyMode']),
  },
  methods: {
    downloadInvoice(invoiceUrl) {
      window.location.href = `${invoiceUrl}`;
    },
    filterHeaders() {
      if (this.isConsumerFriendlyMode) {
        return this.colHeadersCustomerFriendly;
      } else {
        return {
          ...this.colHeadersCustomerFriendly,
          ...this.colHeaders,
        };
      }
    },
    calculateLabelKind(status) {
      let labelKind;

      switch (status) {
        case 'INVOICED':
          labelKind = 'prominent';
          break;
        case 'IN_WAREHOUSE':
          labelKind = 'important';
          break;
        case 'PROCESSING':
          labelKind = 'important';
          break;
        case 'PLACED':
          labelKind = 'default';
          break;
        case 'CANCELLED':
          labelKind = 'critical';
          break;
        case 'SHIPPED':
          labelKind = 'positive';
          break;
        case 'AWAITING_RETURN':
          labelKind = 'default';
          break;
      }
      return labelKind;
    },
    hasProductLink(entry) {
      return entry.lineType === LineType.BIKES || entry.lineType === LineType.ACCESSORIES;
    },
    getGridTextData(value) {
      return {value};
    },
  },
};
</script>
