<template>
  <div class="flex flex-col gap-1">
    <pdl-button
      qaid="rcp-builder-map-zoom-to-fit"
      button
      primary
      icon="center_focus_strong"
      icon-only
      xsmall
      :label="$t('rcp.routeBuilder.map.zoomFit')"
      @click="$emit('zoomToFit')"
    />

    <div class="flex flex-col">
      <pdl-button
        qaid="rcp-builder-map-zoom-in-button"
        button
        primary
        icon="add"
        icon-only
        xsmall
        :label="$t('rcp.routeBuilder.map.zoomIn')"
        @click="setIncreasedZoomLevel()"
      />
      <pdl-button
        qaid="rcp-builder-map-zoom-out-button"
        button
        primary
        icon="remove"
        icon-only
        xsmall
        :label="$t('rcp.routeBuilder.map.zoomOut')"
        @click="setDecreasedZoomLevel()"
      />
    </div>
  </div>
</template>

<script>
import {PdlButton} from '@pedal/pdl-button';
import {mapMutations} from 'vuex';

export default {
  components: {
    PdlButton,
  },
  methods: {
    ...mapMutations('rcpBuilder', ['setIncreasedZoomLevel', 'setDecreasedZoomLevel']),
  },
};
</script>
