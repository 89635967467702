import {TOKENS} from './mask-tokens';

export class Mask {
  memo = new Map();

  constructor(defaults = {}) {
    const opts = {...defaults};

    if (opts.tokens) {
      opts.tokens = opts.tokensReplace ? {...opts.tokens} : {...TOKENS, ...opts.tokens};

      for (const token of Object.values(opts.tokens)) {
        if (typeof token.pattern === 'string') {
          token.pattern = new RegExp(token.pattern);
        }
      }
    } else {
      opts.tokens = TOKENS;
    }

    if (Array.isArray(opts.mask)) {
      if (opts.mask.length > 1) {
        opts.mask = [...opts.mask].sort((a, b) => a.length - b.length);
      } else {
        opts.mask = opts.mask[0] ?? '';
      }
    }
    if (opts.mask === '') {
      opts.mask = null;
    }

    this.opts = opts;
  }

  masked(value) {
    return this.process(value, this.findMask(value));
  }

  unmasked(value) {
    return this.process(value, this.findMask(value), false);
  }

  isEager() {
    return this.opts.eager === true;
  }

  isReversed() {
    return this.opts.reversed === true;
  }

  completed(value) {
    const mask = this.findMask(value);
    if (!this.opts.mask || !mask) return false;

    const length = this.process(value, mask).length;

    if (typeof this.opts.mask === 'string') {
      return length >= this.opts.mask.length;
    } else if (typeof this.opts.mask === 'function') {
      return length >= mask.length;
    } else {
      return this.opts.mask.filter((m) => length >= m.length).length === this.opts.mask.length;
    }
  }

  findMask(value) {
    const mask = this.opts.mask;
    if (!mask) {
      return null;
    } else if (typeof mask === 'string') {
      return mask;
    } else if (typeof mask === 'function') {
      return mask(value);
    }

    const l = this.process(value, mask.slice(-1).pop() ?? '', false);

    return mask.find((el) => this.process(value, el, false).length >= l.length) ?? '';
  }

  escapeMask(maskRaw) {
    const chars = [];
    const escapedCharIndexes = [];
    maskRaw.split('').forEach((ch, i) => {
      if (ch === '!' && maskRaw[i - 1] !== '!') {
        escapedCharIndexes.push(i - escapedCharIndexes.length);
      } else {
        chars.push(ch);
      }
    });

    return {mask: chars.join(''), escapedCharIndexes};
  }

  process(value, maskRaw, masked = true) {
    if (!maskRaw) return value;

    const key = `value=${value},mask=${maskRaw},masked=${masked ? 1 : 0}`;
    if (this.memo.has(key)) return this.memo.get(key);

    const {mask, escapedCharIndexes} = this.escapeMask(maskRaw);
    const result = [];
    const tokens = this.opts?.tokens ?? {};
    const offset = this.isReversed() ? -1 : 1;
    const lastMaskChar = this.isReversed() ? 0 : mask.length - 1;

    const isStillMaskingValue = this.isReversed()
      ? () => maskIndex > -1 && valueIndex > -1
      : () => maskIndex < mask.length && valueIndex < value.length;

    const isNotLastMaskChar = (character) =>
      (!this.isReversed() && character <= lastMaskChar) || (this.isReversed() && character >= lastMaskChar);

    const updateResult = (valueChar) => {
      this.isReversed() ? result.unshift(valueChar) : result.push(valueChar);
    };

    let lastRawMaskChar = '';
    let repeatedPos = -1;
    let maskIndex = this.isReversed() ? mask.length - 1 : 0;
    let valueIndex = this.isReversed() ? value.length - 1 : 0;
    let multipleMatched = false;

    while (isStillMaskingValue()) {
      const maskChar = mask.charAt(maskIndex);
      const token = tokens[maskChar];
      const valueChar = token?.transform ? token.transform(value.charAt(valueIndex)) : value.charAt(valueIndex);
      const isAnEscapedCharacter = escapedCharIndexes.includes(maskIndex);

      if (!isAnEscapedCharacter && token) {
        if (valueChar.match(token.pattern)) {
          updateResult(valueChar);

          if (token.repeated) {
            if (repeatedPos === -1) {
              repeatedPos = maskIndex;
            } else if (maskIndex === lastMaskChar && maskIndex !== repeatedPos) {
              maskIndex = repeatedPos - offset;
            }

            if (lastMaskChar === repeatedPos) {
              maskIndex -= offset;
            }
          } else if (token.multiple) {
            multipleMatched = true;
            maskIndex -= offset;
          }

          maskIndex += offset;
        } else if (token.multiple) {
          if (multipleMatched) {
            maskIndex += offset;
            valueIndex -= offset;

            multipleMatched = false;
          }
        } else if (valueChar === lastRawMaskChar) {
          // matched the last untranslated (raw) mask character that we encountered
          // likely an insert offset the mask character from the last entry;
          // fall through and only increment v
          lastRawMaskChar = undefined;
        } else if (token.optional) {
          maskIndex += offset;
          valueIndex -= offset;
        }

        valueIndex += offset;
      } else {
        if (!this.isEager()) {
          if (masked) {
            updateResult(maskChar);
          }

          if (valueChar === maskChar) {
            valueIndex += offset;
          } else {
            lastRawMaskChar = maskChar;
          }

          maskIndex += offset;
        }
      }

      if (this.isEager()) {
        while (isNotLastMaskChar(maskIndex) && !tokens[mask.charAt(maskIndex)]) {
          if (masked) {
            updateResult(mask.charAt(maskIndex));
          } else if (mask.charAt(maskIndex) === value.charAt(valueIndex)) {
            valueIndex += offset;
          }
          maskIndex += offset;
        }
      }
    }

    this.memo.set(key, result.join(''));

    return this.memo.get(key);
  }
}
