<template>
  <section class="guest-network-container container px-1 mx-auto my-6">
    <pdl-heading size="xl" :level="2" class="text-center mb-3">Terms and Conditions</pdl-heading>
    <div class="overflow-y-auto h-40 mb-4 max-w-lg mx-auto pt-2 p-1 rounded-sm border border-gray-10">
      <pdl-heading size="sm" :level="3" class="mb-1">Privacy policy & terms of use</pdl-heading>
      <p>
        Please read carefully before using this site. This site is owned and operated by Trek Bicycle Corporation
        together with its subsidiaries and affiliates including but not limited to eTrek LLC, and Trekbikes.com LLC,
        which operate, if applicable, the online store ("Store") portion of the site, (collectively "We", "Us" or
        "Our”). By using this site, you signify your agreement to the Privacy Policy and Terms of Use. If you do not
        agree with these terms, please do not use this site. We reserve the right, at Our discretion, to change, modify,
        add, or remove portions of these terms at any time. Please check periodically for changes. Your continued use of
        this site following the posting of changes to these terms will signify your acceptance of the revised terms.
        They were last updated on December 30, 2019.
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">Privacy Policy</pdl-heading>
      <p>
        Your privacy on the Internet is important to Us. As a part of the operation of this site, We gather certain
        types of information about users. We want you to know the types of information We gather and what we do with it.
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">Information We Collect</pdl-heading>
      <p>
        We collect two general types of information from users: personal data (such as names and e-mail addresses); and
        aggregate data (such as information about traffic patterns on this site; for example, how many users log into
        this site on a daily basis).
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">Personal Data</pdl-heading>
      <p>
        Personal data is collected when users provide information to Us, for example in registering for electronic
        newsletters or other membership services, in answering surveys, purchasing items from the Store, registering to
        bid on items available at an online auction, and/or registering for contests and other promotional opportunities
        provided by Us. When you register or request information on this site, We generally need you to provide your
        name, e-mail address, mailing address, day phone number and credit card information (only when ordering products
        at the Store), as well as create a username and password. In addition, if you send Us personal correspondence,
        such as e-mails or letters, We may collect such information into a file specific to you.
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">Information and Usage Information</pdl-heading>
      <p>
        Aggregate information, such as which page users access and information volunteered by users, such as survey
        information and/or site registration information, is collected through various methods. In the course of using
        Our site, We also automatically track certain information about you. This information includes the URL that you
        just came from, which URL you next go to, what browser you are Using, and your IP address. An IP address is a
        number that is automatically assigned to your computer whenever you're surfing the web. Web servers, the
        computers that "serve up" web pages, automatically identify your computer by its IP address, and when you
        request a page from Us, Our servers log your IP address. We do not link IP addresses to anything personally
        identifiable, so although your session will be logged, you will remain anonymous to Us.
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">Use of Cookies</pdl-heading>
      <p>
        Please refer to our cookie policy for details. Use of Information Collected by Us We use both the personal and
        aggregated and usage information We collect for multiple purposes. For example, We collect this information for
        fulfilling orders and prizes, administering information requests and providing information services,
        tracking/verifying compliance with Our policies as well as federal, state and local laws, and for editorial and
        feedback solicitation purposes. We may use your e-mail address and, in rare situations, your mailing address or
        phone number, to contact you regarding customer service issues or billing matters. Personal and aggregated
        information is also used to: improve the content of the Store; perform system administration activities;
        customize the content and/or layout of the Store for each individual user; and notify users about updates to the
        Store or promotional offers or opportunities that We believe will be of interest to you. We will always try to
        provide you with an opportunity to decline this service when such information is requested from you online. If
        you later decide you would rather not receive this information, or would like to opt-out of any other
        information request or distribution, you may also update your account information on the applicable portion of
        this site or simply reply to any e-mail sent to you and type UNSUBSCRIBE as the subject of your reply or in the
        message of the e-mail. We may also use your information and activity on the site to resolve disputes,
        troubleshoot problems and enforce Our Terms of Use.
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">Disclosure of Collected Information to Third Parties</pdl-heading>
      <p>
        We do not sell, trade, rent or otherwise provide your personal data to third parties without prior permission
        from you, provided however, that third parties does not include Our subsidiaries, affiliates, licensees,
        partners, website hosting partners or other parties who assist Us in operating this site. We may, however, share
        your data in an aggregate format, as well as aggregate usage information, with third parties for both
        advertising and promotional purposes. Further, We cooperate with all law enforcement inquires, and with all
        third parties, to enforce their intellectual property or other legal rights.
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">Links to Other Sites</pdl-heading>
      <p>
        It is important to note that this site might contain links to other sites, and that those sites may not follow
        the same privacy policies as Us. For instance, if you clicked on an advertisement on Store, this might take you
        away from this site and to an entirely different site. This includes links to advertisers, bicycle dealers,
        content providers, and business partners, who may use Our name and/or logos as a result of a co-branding or
        other agreement. These sites may send their own cookies to you, and may collect data and make uses of it that We
        would not. We do not control these sites or use, or bear any responsibility or liability for them.
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">Security</pdl-heading>
      <p>
        The security of all personally identifiable information associated with users of this site is of great concern
        to Us. We implement a variety of security measures to maintain the safety of your personal information. Once We
        receive your transmission, We use reasonable efforts to ensure its security on Our systems. When credit card
        information is transmitted, We use SSL (secure socket layer) encryption technology. Any account information you
        enter on this site is password-protected so that only you can access it. You should not divulge your password or
        user identification number to anyone. Unfortunately, no data transmission over the Internet can be guaranteed to
        be 100% secure. As a result, while We strive to protect your personal information, We can't ensure or warrant
        the security of any information you transmit to Us, and you do so at your own risk.
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">Terms of Use</pdl-heading>
      <p>
        Ownership Restrictions on Use of Materials The entire content included in this site, including but not limited
        to text, graphics or code, is owned by or licensed to Us. We reserve the right to make changes to the content of
        this site at any time without notice, including with respect to our products, equipment, specifications, models,
        colors, and materials. You may download, view, and print text and graphics from this site, provided that such
        content is only used for informational, non-commercial purposes, and is not modified or altered in any way.
        Except as expressly provided herein, you may not use, download, upload, copy, print, display, perform,
        reproduce, publish, license, post, transmit or distribute any information or content from this site in whole or
        in part without Our prior written permission. Trek, Bontrager, Gary Fisher are registered trademarks of Trek
        Bicycle Corporation. Other trademarks, service marks, slogans, logos and trade names identified on this site are
        either Our trademarks or trademarks of a third party licensor or other provider. None of these marks, names or
        logos may be used without the prior written consent of their owner.
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">Forums</pdl-heading>
      <p>
        "Forum" means a chat area, bulletin board, or e-mail function offered as part of this site. Harassment in any
        manner or form on this site, including via e-mail and chat or by obscene or abusive language, is strictly
        forbidden. You shall not upload to, distribute through, or otherwise publish on or through this site any content
        that is libelous, defamatory, infringing, obscene, pornographic, threatening, invasive of privacy or publicity
        rights, abusive, illegal, or otherwise objectionable that would constitute or encourage a criminal offense,
        violate the rights of any party, or otherwise give rise to liability or violate any law. The Forums shall be
        used only in a noncommercial manner. You shall not, without Our express approval, distribute or otherwise
        publish any material containing any solicitation of funds, promotion, advertising, or solicitation for goods or
        services. By uploading materials to any Forum or submitting any materials to Us, you automatically grant us a
        perpetual, royalty-free, irrevocable, nonexclusive right and license, subject only to express restrictions set
        forth in Our Privacy Policy, to use, reproduce, modify, adapt, publish, translate, create derivative works from,
        and distribute such materials or incorporate such materials into any form, medium, or technology now known or
        later developed throughout the world.
        <br />
        We do not and cannot review all communications and materials posted to or created by users accessing Our site
        and are not in any manner responsible for the content of these communications and materials. We reserve the
        right, however, to block or remove communications or materials that We determine to be (a) abusive, defamatory,
        or obscene, (b) fraudulent, deceptive, or misleading, (c) in violation of a copyright, trademark or other
        intellectual property right of another, or (d) offensive or otherwise unacceptable to Us in Our sole discretion.
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">
        EU/US Data Privacy Framework, UK Extension, and Swiss – U.S. Data Privacy Framework Statement
      </pdl-heading>
      <p>
        Trek Bicycle Corporation, together with its subsidiary, Trek Retail Corporation (“Trek”) complies with the
        EU-U.S. Data Privacy Framework, its UK Extension, and the Swiss – U.S. Data Privacy Framework as set forth by
        the U.S. Department of Commerce regarding the collection, use and retention of personal information transferred
        from the European Union, UK, and Switzerland to the United States, respectively. Trek has certified to the
        Department of Commerce that it adheres to the Data Privacy Framework. If there is any conflict between the terms
        in this privacy policy and the Data Privacy Framework Principles, the Data Privacy Framework Principles shall
        govern. To learn more about the Data Privacy Framework programme, and to view our certification, please visit
        www.dataprivacyframework.gov. Trek is subject to the investigatory and enforcement powers of the Federal Trade
        Commission (FTC). Notwithstanding the above, for users who are residents of a member of the European Union, UK,
        or Switzerland, users may submit any unresolved complaints, demands, claims or actions whatsoever related to the
        EU/US Data Privacy Framework, its UK Extension, or the Swiss – U.S. Data Privacy Framework for resolution by
        arbitration by the American Arbitration Association (https://go.adr.org/dpf_irm.html; International Centre for
        Dispute Resolution; A Division of the American Arbitration Association; Case Filing Services; 1101 Laurel Oak
        Road, Suite 100; Voorhees, NJ 08043; Phone: +1 856 435 6401; Freephone number in the US +1 877 495 4185; Fax
        number +1 877 304 8457; Fax number outside the US: +1 212 484 4178; Email box: casefiling@adr.org). It may be
        possible for you, under certain conditions, to invoke binding arbitration through the Data Privacy Framework
        panel as a last resort. In the event that an issue still cannot be resolved, you may invoke binding arbitration
        through the Data Privacy Framework panel. To learn more about the Data Privacy Framework program, and to view
        Trek’s certification, please visit www.dataprivacyframework.gov. Users shall be provided with the opportunity to
        access Personal Information held by Trek through their account portal and their local Country Office to correct,
        amend or delete any information that is inaccurate. Notwithstanding the foregoing, we are not required to
        provide such opportunity where the burden or expense of providing access is disproportionate to the risks to the
        requesting individual’s privacy, or where the rights of another individual would be violated. Individuals have
        the opportunity to choose (opt in by consenting to submit) whether their Personal Information will be disclosed
        to a third party or used for a purpose other than that for which the information was originally collected.
        Personal Information collected through the warranty registration mechanism is not used for marketing purposes
        unless the User opts in. Trek’s accountability for personal data that it receives under the Data Privacy
        Framework and subsequently transfers to a third party is described in the Data Privacy Framework Principles. In
        particular, Trek remains responsible and liable under the Data Privacy Framework Principles if third-party
        agents that it engages to process the personal data on its behalf do so in a manner inconsistent with the
        Principles, unless Trek shows that it is not responsible for the event giving rise to the damage. If you have
        any questions about our privacy practices or our implementation of the Data Privacy Framework Principles, please
        contact the Human Resources Director, Trek Bicycle Corporation, 801 W. Madison Street, Waterloo, WI 53594. .
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">For California Residents</pdl-heading>
      <p>
        California Residents have a right, with some exceptions, to request that We delete their personal information
        and/or give them access (in-so-far as technically feasible in a portable format) to the personal information
        collected by Us within the last twelve (12) months. Such deletion and/or access requests related to personal
        information should be sent to CCPA@trekbikes.com. You may also reach Us by tollfree telephone at 1 (800)
        585-8735. If you send us an email requesting deletion or access to personal information, the requesting email
        MUST come from that specific email address associated with your personal information in order to verify that the
        request is valid and also to protect your personal information. In order to protect your information and because
        people may have the same name, we will only provide or delete personal information associated with such specific
        email address. We will not delete the personal information of a person who still owes a debt to Trek, as it is
        considered personal information related to an ongoing transaction. As noted above, the easiest way to stop
        receiving emails is to click 'unsubscribe' on the bottom of any of our emails or to change your account
        preferences.
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">Web Accessibility Policy</pdl-heading>
      <p>
        We strive to maintain and increase the accessibility of this Website to ensure that persons with disabilities
        have full and equal enjoyment of Trek’s Website and online experience. If you need assistance with accessibility
        as it related to Trek’s website, please call Us by tollfree telephone at 1 (800) 585-8735 or email Us at
        accessibility@trekbikes.com.
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">Disclaimer</pdl-heading>
      <p>
        THE CONTENT IN THIS SITE IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO
        THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
        INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND
        NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE OPERATION OF THIS SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT
        DEFECTS WILL BE CORRECTED, OR THAT THIS SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
        HARMFUL COMPONENTS. WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF
        THE MATERIALS IN THIS SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. APPLICABLE LAW
        MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
      </p>

      <pdl-heading size="sm" :level="3" class="mb-1">Information and Pricing</pdl-heading>
      <p>
        The prices in this website are MSRP, are intended only as a guide, and are subject to change without notice. The
        prices that are displayed in the shopping cart are available only through Trekbikes.com. From time to time, sale
        or closeout pricing may also appear on the website; sale and closeout pricing are only available through
        Trekbikes.com. All photos, illustrations, colors, average weights, and specifications contained in this website
        are based on the production information at the time of publication. Trek reserves the right to make changes at
        any time, without notice, in colors, weights, materials, equipment, specifications, and models. Any variations
        in colors shown may be due to the reproductive variations of the displaying process. Photos may include
        equipment not available in all markets. Some models may be shown with optional equipment. All product is subject
        to availability. E-bike and light power, ratings, and ranges are conditioned maximums and will decrease over the
        lifetime of the product. Use of a live chat system On this website, for the purpose of operating a live chat
        system that serves to answer live inquiries, your communicated chat name, your e-mail address and your
        communicated chat content are collected as data and stored for the course of the chat. The chat and your
        provided chat name and e-mail address are stored exclusively in a case file in the US. Cookies are used to
        operate the chat function. Cookies are small text files that are stored locally in the cache of the site
        visitor's Internet browser. The cookies make it possible to recognize the Internet browser of the site visitor
        in order to distinguish between individual users of the chat function of our website. If the information
        collected in this way has a personal reference, the processing is carried out in accordance with Art. 6 (1) lit.
        f DSGVO on the basis of our legitimate interest in effective customer service and the statistical analysis of
        user behavior for optimization purposes. n order to avoid the storage of cookies, you can set your internet
        browser so that no cookies can be stored on your computer in the future or so that cookies that have already
        been stored are deleted. However, switching off all cookies may mean that the chat function on our website can
        no longer be executed.
      </p>
      <p>eTrek®</p>
    </div>
    <div class="text-center">
      <pdl-link button :href="merakiUrl">Connect to Trek Wifi</pdl-link>
    </div>
  </section>
</template>

<script>
import {PdlLink} from '@pedal/pdl-link';
import {PdlHeading} from '@pedal/pdl-section-header';

export default {
  name: 'GuestNetworkContainer',

  components: {
    PdlLink,
    PdlHeading,
  },

  data() {
    return {
      merakiUrl: '',
    };
  },

  mounted() {
    const search = window.location.search;
    const grantUrl = new URLSearchParams(search);
    this.merakiUrl = `${grantUrl.get('base_grant_url')}?user_continue_url=${grantUrl.get('user_continue_url')}`;
  },
};
</script>

<style lang="scss" scoped>
.guest-network-container {
  p {
    margin-bottom: 2rem;
  }
}
</style>
