<template>
  <tr>
    <td class="b2b-grid__cell lg:table-cell">
      <div class="b2b-grid__label" />
      <div class="b2b-grid__content">
        <div class="flex items-center">
          <grid-checkbox
            :qaid="`sku-grid-checkbox-${product.code}`"
            :label="product.code"
            :checked="isChecked"
            @change="handleCheck"
          />
        </div>
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid-sku">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.sku') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: product.code}" />
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid-description">
      <div class="b2b-grid__label">{{ $t('productCompare.description') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: product.itemName}" />
      </div>
    </td>
    <!-- Product warehouses -->
    <template v-if="btoLeadTime">
      <td
        v-if="hasWarehouses(product.warehouses)"
        :colspan="product.warehouses.length"
        class="b2b-grid__cell lg:table-cell"
        qaid="sku-grid-warehouse"
      >
        <div class="b2b-grid__label" v-html="warehouseId" />
        <div class="b2b-grid__content text-center" qaid="sku-grid-btoLeadTime" v-html="btoLeadTime" />
      </td>
    </template>

    <template v-else-if="productEtaAvailable">
      <td :colspan="product.warehouses.length" class="b2b-grid__cell lg:table-cell" qaid="sku-grid-warehouse">
        <div class="b2b-grid__label" v-html="getWarehouseTitle(product)" />
        <div
          class="b2b-grid__content lg:text-center text-red font-medium"
          qaid="sku-grid-newProductEta"
          v-html="productEtaAvailable"
        />
      </td>
    </template>

    <template v-else-if="hasWarehouses(product.warehouses)">
      <td
        v-for="(warehouse, warehouseKey) in getWarehouses(product)"
        :key="`warehouse-${warehouseKey}`"
        class="b2b-grid__cell lg:table-cell"
      >
        <div class="b2b-grid__label" v-html="warehouse.id" />
        <div class="b2b-grid__content">
          <grid-warehouse
            :warehouse="warehouse"
            :is-bto-purchasable-b2b="product.isPurchasableB2B"
            :no-eta-text="$t('text.buyingZone.noEta')"
            :bto-text="$t('product.stockStatus.bto')"
            :sold-out-text="$t('text.buyingZone.soldOut')"
            :not-available-text="$t('text.na')"
          />
        </div>
      </td>
    </template>
    <!-- end warehouses -->
    <td v-if="hasWarehouses(product.warehouses)" class="b2b-grid__cell lg:table-cell" qaid="sku-grid-stock-watch">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.stockWatch') }}</div>
      <div class="b2b-grid__content">
        <grid-stock-watch
          :product-index="product.code"
          :warehouses="getWarehouseStockWatchOptions(product.stockWatch, [product])"
          @update-stock-watch-state="(payload) => $emit('update-stock-watch-state', payload)"
        />
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid-order-price">
      <div class="b2b-grid__label">{{ $t('order.price') }}</div>
      <div class="b2b-grid__content">
        <grid-price
          :data="{
            dealerCost,
            discount,
          }"
        />
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid-qty">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.qty') }}</div>
      <div class="b2b-grid__content">
        <numerical-input
          ref="quantityInput"
          :min="0"
          :max="9999"
          :sku="product.code"
          :should-clear="false"
          :data-sku="product.code"
          :initial-value="product.skuQty"
          @blur="updateQty"
          @update-variant-quantity="updateQty"
        />
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid-subtotal">
      <div class="b2b-grid__label">{{ $t('text.order.subtotal') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: subtotal}" />
      </div>
    </td>
  </tr>
</template>

<script>
import get from 'lodash/get';
import {mapState, mapMutations} from 'vuex';
import GridText from '@/components/grid/GridText';
import GridPrice from '@/components/grid/GridPrice';
import NumericalInput from '@/components/NumericalInput';
import GridCheckbox from '@/components/grid/GridCheckbox';
import GridWarehouse from '@/components/grid/GridWarehouse';
import GridStockWatch from '@/components/grid/GridStockWatch';
import warehouseMixin from '@/mixins/warehouse';

export default {
  name: 'CheckSkusGridItem',

  components: {GridCheckbox, GridStockWatch, GridPrice, NumericalInput, GridText, GridWarehouse},

  mixins: [warehouseMixin],

  props: {
    product: {
      type: Object,
      default: () => null,
      required: true,
    },
    checkboxList: {
      type: Array,
      default: () => [],
      required: true,
    },
    locale: {
      type: String,
      default: 'en-US',
      required: true,
    },
    currencyIsoCode: {
      type: String,
      default: 'USD',
      required: true,
    },
    warehouseCodes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isAllowedAddToCart: this.product.isAllowedAddToCart,
      quantity: this.product.skuQty,
    };
  },

  computed: {
    warehouseId() {
      return Array.isArray(this.product?.warehouses) && this.product.warehouses.length
        ? this.product.warehouses[0].id
        : '';
    },

    isChecked() {
      return this.checkboxList.some((product) => product.sku === this.product.code);
    },

    dealerCost() {
      return get(this.product, 'retailerPrice.wasPrice.low.formatted');
    },

    discount() {
      return get(this.product, 'retailerPrice.price.low.value');
    },

    subtotal() {
      const formatOptions = {
        style: 'currency',
        currency: this.currencyIsoCode,
      };
      const quantity = parseFloat(this.product.skuQty);
      const discountPrice = this.discount ? this.discount : false;

      return quantity === 1
        ? this.singleQtySubtotal(discountPrice, formatOptions)
        : this.multipleQtySubtotal(quantity, discountPrice, formatOptions);
    },

    productEtaAvailable() {
      const newProductETAMonthTranslation = this.$t(`general.month.${this.product.newProductETAMonth}`);
      if (this.product.newProductETAYear && newProductETAMonthTranslation) {
        const newProductETADate = newProductETAMonthTranslation + ' ' + this.product.newProductETAYear;
        return this.$t('skuGrid.B2B.newProductETA', [newProductETADate]);
      }
      return false;
    },

    btoLeadTime() {
      if (this.product.btoLeadTime && this.product.isPurchasableB2B) {
        return this.$t('skuGrid.B2B.leadTime', [this.product.btoLeadTime]);
      }

      return false;
    },
  },

  methods: {
    singleQtySubtotal(discountPrice, formatOptions) {
      if (discountPrice) {
        return Intl.NumberFormat(this.local, formatOptions).format(discountPrice);
      } else {
        const priceValue = get(this.product, 'retailerPrice.wasPrice.low.formatted'); // will return undefined if property not exists
        return priceValue ? priceValue : '';
      }
    },

    multipleQtySubtotal(quantity, discountPrice, formatOptions) {
      if (discountPrice) {
        const rawPrice = quantity * discountPrice;
        const price = Number(rawPrice) ? rawPrice : '';
        const formattedPrice = new Intl.NumberFormat(this.locale, formatOptions).format(price);
        return Number.isNaN(formattedPrice) || !Number(rawPrice) ? '' : formattedPrice;
      } else {
        const rawPrice = quantity * get(this.product, 'retailerPrice.wasPrice.low.value');
        const formattedPrice = new Intl.NumberFormat(this.locale, formatOptions).format(rawPrice);
        return Number.isNaN(formattedPrice) || !Number(rawPrice) ? '' : formattedPrice;
      }
    },

    updateQty(event) {
      const targetValue = get(event, 'target.value', false);
      const value = targetValue ? targetValue : event;
      this.quantity = event.target.value;
      this.$emit('update-variant-quantity', {qty: value, sku: this.product.code, input: this.$refs['quantityInput']});
    },

    handleCheck(event) {
      const selectedProduct = {
        qty: this.product.skuQty,
        sku: this.product.code,
      };

      if (event) {
        this.$emit('add-to-checkbox-list', {
          sku: this.product.code,
          isAllowedAddToCart: this.isAllowedAddToCart,
        });
        this.$emit('update-variant-quantity', {
          qty: this.product.skuQty,
          sku: this.product.code,
          input: this.$refs['quantityInput'],
        });
      } else {
        this.clearBaseProduct(this.product.code);
        this.$emit('remove-from-checkbox-list', selectedProduct);
      }
      this.$emit('update-check-all');
    },

    getWarehouseTitle(product) {
      /**This will be used to determine title of warehouse columm in lower viewports**/
      if (product.warehouses && product.warehouses.length > 0) {
        let warehousesNumber = product.warehouses.length - 1;
        let warehouseTitle = ''; //initializing empty string, to work with below concatenation
        while (warehousesNumber >= 0) {
          warehouseTitle += `${product.warehouses[warehousesNumber].id}`;
          warehousesNumber--;
        }
        return warehouseTitle;
      }
    },

    getWarehouses(product) {
      return this.fillWarehousesByCodes(this.warehouseCodes, product.warehouses);
    },

    ...mapState('skuGrid', ['baseProducts']),
    ...mapMutations('skuGrid', ['clearBaseProduct']),
  },
};
</script>
