import {trackEvent} from '@/utils/tracking';

const trackPdpAddToCart = {
  methods: {
    trackPdpAddToCart() {
      const addToCartData = {
        event: 'AddToCart',
        variant_product_id: this.trackEventData.productId, // Custom variables (FB event)
        product_name: this.trackEventData.productName, // Custom variables (FB event)
        ecommerce: {
          currencyCode: this.trackEventData.currencyIso,
          add: {
            products: [
              {
                id: this.trackEventData.productId,
                name: this.trackEventData.productName,
                price: this.trackEventData.productPrice,
                brand: this.trackEventData.productBrand,
                category: this.trackEventData.productCategory,
                variant: this.trackEventData.productColor,
                size: this.trackEventData.productSize,
                quantity: this.quantity,
              },
            ],
          },
        },
      };

      trackEvent(addToCartData);
    },
  },
};

export default trackPdpAddToCart;
