<template>
  <ul class="self-start bg-gray-10 p-4">
    <li>
      <trek-button
        v-show="!etaTab"
        button
        text
        icon="get_app"
        :disabled="backorderDownloading"
        qaid="allocatedBackorder-download-backorders"
        @click="downloadBackorders"
      >
        <span>{{ $t('backordersAllocated.B2B.download') }}</span>
      </trek-button>
    </li>
    <li>
      <trek-button
        button
        text
        icon="get_app"
        :disabled="etaDownloading"
        qaid="allocatedBackorder-download-eta-changes"
        @click="downloadEtaReport"
      >
        <span>{{ $t('backordersAllocated.B2B.etaChanges') }}</span>
      </trek-button>
    </li>
  </ul>
</template>

<script>
import TrekButton from '@/components/TrekButton';
import DownloadBackorders from '@/components/containers/my-account/mixins/download-backorders';
import DownloadEtaReport from '@/components/containers/my-account/mixins/download-eta-report';
import {mapGetters} from 'vuex';

export default {
  name: 'AllocatedBackorderHistoryReports',
  components: {
    TrekButton,
  },
  mixins: [DownloadBackorders, DownloadEtaReport],
  props: {
    currentTab: {
      type: String,
      default: '',
    },
    etaTab: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({
        hideUnallocated: false,
        hideWarranties: false,
        hideLinesWithoutComments: false,
        showSoldOut: false,
        showProjectOne: false,
        descriptionSearchTerm: '',
        skuSearchTerm: '',
        orderNumberSearchTerm: '',
        customerPoSearchTerm: '',
        configSortByForUrl: '',
        hidePreseason: '',
      }),
    },
  },
  data() {
    return {
      downloadTimeout: null,
      backorderDownloading: false,
      etaDownloading: false,
    };
  },
  computed: {
    ...mapGetters('allocatedBackorder', ['searchEntriesPayload']),
  },
};
</script>
