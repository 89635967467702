import axios from 'axios';
import occ from '@/api/instances/occ.config';

const baseUrl = occ.url.BASE_URL;

const AddressAutofillInstance = axios.create({baseURL: baseUrl});

const AddressAutofillApi = {
  async getAddressSuggestions({query}) {
    return AddressAutofillInstance.get(`/address/suggest?address=${query}`);
  },
};

export {AddressAutofillApi};
