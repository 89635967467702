import {RouteBuilderMapDefaults, RouteBuilderHoverMarkers} from '@/constants/rcp-builder';
import mapboxgl from 'mapbox-gl';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import polyline from '@mapbox/polyline';

function centerAndFitBounds(map, routes, right, bottom, left) {
  let completePolylineGeometry;
  const bounds = new mapboxgl.LngLatBounds();
  for (let mapCoord of routes) {
    if (!mapCoord.geometry) {
      continue;
    }
    completePolylineGeometry = polyline.toGeoJSON(mapCoord.geometry, RouteBuilderMapDefaults.DEFAULT_COORDS_PRECISION);
    // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
    for (const coord of completePolylineGeometry.coordinates) {
      bounds.extend(coord);
    }
  }
  map.mapInstance.fitBounds(bounds, {
    padding: {
      top: RouteBuilderMapDefaults.ZOOM_BOUNDS_PADDING,
      right: right + RouteBuilderMapDefaults.ZOOM_BOUNDS_PADDING,
      bottom: bottom + RouteBuilderMapDefaults.ZOOM_BOUNDS_PADDING,
      left: left + RouteBuilderMapDefaults.ZOOM_BOUNDS_PADDING,
    },
    duration: RouteBuilderMapDefaults.ZOOM_DURATION,
  });
}

function addPolylineAndSource(map, routePoints, showHoverIcon) {
  map.mapInstance.addSource('route', {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: routePoints.coordinates,
      },
    },
  });
  map.mapInstance.addLayer({
    id: 'route',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': RouteBuilderMapDefaults.DEFAULT_POLYLINE_COLOR,
      'line-width': RouteBuilderMapDefaults.DEFAULT_POLYLINE_WIDTH,
    },
  });
  map.addInteractionOnLayer('mouseover', 'route', showHoverIcon);
}

function addRouteMarkers(map, markerPoint, svgString, markerColor, hideHoverIcon) {
  const el = document.createElement('div');
  el.classList.add('main-marker-element', `${markerColor}-marker`);
  el.innerHTML = svgString;
  el.addEventListener('mouseleave', hideHoverIcon);
  map.addMarker({
    position: markerPoint,
    markerOptions: {
      draggable: false,
      element: el,
    },
  });
}

function getHoverMarkerElement() {
  const hoverIcon = document.createElement('div');
  hoverIcon.className = 'route-hover-icon';
  hoverIcon.innerHTML = RouteBuilderHoverMarkers.HOVER_MARKER_SVG;
  hoverIcon.style.display = 'none';
  return hoverIcon;
}

function findNearestPointOnLine(line, point, {options}) {
  return nearestPointOnLine(line, point, {options});
}

function calculateRadiusCoordinates(center, radius, points = 64) {
  const centerCoordinates = {
    latitude: center[1],
    longitude: center[0],
  };
  const radiusByKm = radius / 1000;
  const radiusCoordinates = [];
  const distanceX = radiusByKm / (111.32 * Math.cos((centerCoordinates.latitude * Math.PI) / 180));
  const distanceY = radiusByKm / 110.574;

  let theta;
  let xCoordinate;
  let yCoordinate;
  for (var i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    xCoordinate = distanceX * Math.cos(theta);
    yCoordinate = distanceY * Math.sin(theta);

    radiusCoordinates.push([centerCoordinates.longitude + xCoordinate, centerCoordinates.latitude + yCoordinate]);
  }
  radiusCoordinates.push(radiusCoordinates[0]);

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [radiusCoordinates],
          },
        },
      ],
    },
  };
}

function centerAndFitRadius(map, radiusData) {
  const radiusCoordinates = radiusData.features[0].geometry.coordinates[0];
  let bounds = {};

  radiusCoordinates.forEach((coordinates) => {
    const longitude = coordinates[0];
    const latitude = coordinates[1];
    bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude;
    bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude;
    bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude;
    bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude;
  });

  const southWestBound = [bounds.xMin, bounds.yMin];
  const northEastBound = [bounds.xMax, bounds.yMax];

  map.mapInstance.fitBounds([southWestBound, northEastBound], {
    padding: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20,
    },
    duration: RouteBuilderMapDefaults.ZOOM_DURATION,
  });
}

export {
  centerAndFitBounds,
  addPolylineAndSource,
  addRouteMarkers,
  findNearestPointOnLine,
  getHoverMarkerElement,
  calculateRadiusCoordinates,
  centerAndFitRadius,
};
