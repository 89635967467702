function YoutubePlayer(url) {
  const YOUTUBE_LINK_PATTERN =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;

  const youtubeRegex = new RegExp(YOUTUBE_LINK_PATTERN);
  const YOUTUBE_EMBED_PATTERN = 'https://www.youtube.com/embed/$1';

  function getEmbed({autoplay} = {}) {
    const regexResult = youtubeRegex.exec(url)?.at(-2);
    if (!regexResult?.length) return;
    const embedUrl = new URL(YOUTUBE_EMBED_PATTERN.replace('$1', regexResult));
    if (autoplay) embedUrl.searchParams.append('autoplay', 1);
    return embedUrl.toString();
  }

  return {getEmbed};
}

export {YoutubePlayer};
