<template>
  <div id="RENTABIKENOW_WIDGET_01">
    <p
      id="RENTABIKENOW_LOADER"
      :style="`background: url('//${rentBikeId}.rentabikenow.com/images/loader.gif') left center no-repeat; padding: 5px 5px 5px 20px`"
    >
      Searching Availability...
    </p>

    <noscript>
      <a :href="`https://${rentBikeId}.rentabikenow.com/reserve/`" style="font-size: 16px"
        >Continue to Secure Booking System &raquo;</a
      >
    </noscript>
  </div>
</template>

<script>
export default {
  props: {
    rentBikeId: {
      type: String,
      default: '',
    },
  },

  mounted() {
    this.appendScript();
  },

  methods: {
    appendScript() {
      const script = document.createElement('script');
      script.src = `https://${this.rentBikeId}.rentabikenow.com/lib/interface--0.js`;
      script.onload = this.initWidget;
      document.head.appendChild(script);
    },

    initWidget() {
      new window.DROPLET.Widget({
        host: `${this.rentBikeId}.rentabikenow.com`,
        target: 'RENTABIKENOW_WIDGET_01',
        options: 'category_select,hidesearch',
        style: 'font-family: Roboto',
        provider: 'droplet',
      }).render();
    },
  },
};
</script>
