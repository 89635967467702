<template>
  <div class="content flex flex-col">
    <slot />
    <p v-if="customError" class="mb-3 text-red text-sm italic" :class="{'order-3': locale == 'jp'}">
      {{ customError }}
    </p>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

const FIRST_NAME_ID = 'address.firstName';
const SURNAME_ID = 'address.surname';
const FORM_ID = 'trekAddressForm';

export default {
  mixins: [TrekValidationMixin],
  data() {
    return {
      validator: null,
      surnameElement: null,
      firstNameElement: null,
      formElement: null,
      valid: false,
      customError: null,
      personalInformationValues: {
        firstNameValue: '',
        surnameValue: '',
      },
    };
  },
  computed: {
    ...mapState('backend', ['locale']),
  },
  validations() {
    return {
      personalInformationValues: {
        surnameValue: {
          fullNameValid: this.trekValidators.fullNameMaxByteLength(
            this.personalInformationValues.firstNameValue,
            this.locale
          ),
        },
        firstNameValue: {
          fullNameValid: this.trekValidators.fullNameMaxByteLength(
            this.personalInformationValues.surnameValue,
            this.locale
          ),
        },
      },
    };
  },

  mounted() {
    this.addListeners();
  },
  beforeDestroy() {
    this.removeListeners();
  },
  methods: {
    async validateInputValues(field, value) {
      this.personalInformationValues[field] = value;

      await this.v$.personalInformationValues.$validate();

      const handleError = () => {
        this.customError = this.$t('address.error.formentry.invalid.length');
        [this.firstNameElement, this.surnameElement].forEach((element) =>
          element.parentElement.classList.add('form__item--error')
        );
      };

      const clearError = () => {
        this.customError = null;
        [this.firstNameElement, this.surnameElement].forEach((element) =>
          element.parentElement.classList.remove('form__item--error')
        );
      };

      this.v$.personalInformationValues.$errors.length ? handleError() : clearError();
    },

    handleNameChange(event) {
      this.validateInputValues(
        event.target.getAttribute('id') === FIRST_NAME_ID ? 'firstNameValue' : 'surnameValue',
        event.target.value
      );
    },

    submitInterceptor(event) {
      if (!this.customError) return true;
      event.preventDefault();
      return false;
    },
    addListeners() {
      this.firstNameElement = document.getElementById(FIRST_NAME_ID);
      this.surnameElement = document.getElementById(SURNAME_ID);
      this.formElement = document.getElementById(FORM_ID);

      this.validateInputValues('firstNameValue', this.firstNameElement.value);
      this.validateInputValues('surnameValue', this.surnameElement.value);

      this.firstNameElement.addEventListener('input', this.handleNameChange);
      this.surnameElement.addEventListener('input', this.handleNameChange);
      this.formElement.addEventListener('submit', this.submitInterceptor);
    },
    removeListeners() {
      this.firstNameElement.removeEventListener('input', this.handleNameChange);
      this.surnameElement.removeEventListener('input', this.handleNameChange);
      this.formElement.removeEventListener('submit', this.submitInterceptor);
    },
  },
};
</script>
