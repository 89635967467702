export default {
  props: {
    products: {
      required: true,
      validator(prop) {
        return Array.isArray(prop) || prop === null;
      },
    },
    options: {
      required: true,
      validator(prop) {
        return typeof prop === 'object' || prop === null;
      },
    },
    settings: {
      required: true,
      validator(prop) {
        return typeof prop === 'object' || prop === null;
      },
    },
    isCompareButtonEnabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isArchive() {
      return this.settings.locationId === 'archive';
    },

    hasProducts() {
      return this.products && this.products.length > 0;
    },
  },
};
