<template>
  <form-group
    v-model="regionIso"
    :multi-items="transformedRegions"
    name="regionIso"
    :label="$t('address.state')"
    type="select"
    :error="fieldError"
    required
    show-error-messages
    force-display-error
    @input="clearErrors"
  />
</template>

<script>
import FormGroup from '@/components/FormGroup';
import {mapState, mapMutations} from 'vuex';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
export default {
  components: {FormGroup},
  mixins: [TrekValidationMixin],
  inject: ['validationErrors', 'clearValidationErrors'],
  props: {
    regions: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  validations() {
    return {
      regionIso: {
        required: this.trekValidators.requiredWithCustomMessage('address.regionIso.invalid'),
      },
    };
  },
  computed: {
    ...mapState('checkout', ['billingAddress']),
    regionIso: {
      get() {
        return this.billingAddress.regionIso ? this.billingAddress.regionIso : this.value;
      },
      set(regionIso) {
        this.setRegionIso(regionIso);
      },
    },
    transformedRegions() {
      const transformedRegions = this.regions.map((region) => {
        return {
          displayName: region.name,
          value: region.isocode,
        };
      });
      transformedRegions.unshift({displayName: this.$t('form.select.empty'), disabled: true, value: ''});
      return transformedRegions;
    },
    fieldName() {
      return 'regionIso';
    },
    fieldError() {
      const theError =
        this.validationErrors.find((item) => item.field === this.fieldName)?.msg ??
        this.getErrorMessage(this.fieldName);

      return theError;
    },
  },
  methods: {
    ...mapMutations('checkout', ['setRegionIso']),
    clearErrors() {
      if (!this.validationErrors.length) return;
      this.clearValidationErrors();
    },
  },
};
</script>
