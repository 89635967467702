'use strict';

export default function (element) {
  var $window = $(window);
  var slideSizeLarge = 0.25;

  var mask = element.find('[data-product-carousel-mask]');
  var container = element.find('[data-product-carousel-container]');
  var slides = element.find('[data-product-carousel-slide]');
  var pagination = element.find('[data-product-carousel-pagination]');

  var slideWidth;

  var index;
  var position;

  function updateSize() {
    var height = 0;
    var extent = 0;

    //var buttonHeight = scope.hasButton ? 60 : 0;
    var buttonHeight = 60;

    slideWidth = mask.width();

    slideWidth = slideWidth * slideSizeLarge;

    //if ($window.matchMedia('(min-width: 960px)').matches) {
    //    slideWidth = slideWidth * slideSizeLarge;
    //} else if ($window.matchMedia('(min-width: 600px').matches) {
    //    slideWidth = slideWidth * slideSizeMedium;
    //} else {
    //    slideWidth = slideWidth * slideSizeSmall;
    //    buttonHeight = 0; // Even if we have a button, we don't need to account for its height at small
    //}

    index = 0;
    position = 0;

    slides.css('width', slideWidth + 'px');

    slides.each(function (index, slide) {
      slide = $(slide);
      slide.css('left', extent + 'px');
      extent += slideWidth;
      height = slide.height();
    });

    container.css({
      width: extent + 'px',
      height: height + 'px',
    });

    mask.css('height', height + buttonHeight + 'px');

    updatePosition(false);
  }

  function updateIndex(incr) {
    index += incr;
    position = index * slideWidth * -1;

    updatePosition(true);
  }

  function updatePosition(animate) {
    var target = container[0];

    target.style['transition-duration'] = (animate ? 0.5 : 0) + 's';
    target.style['-webkit-transform'] = 'translate3d(' + position + 'px, 0px, 0px)';
    target.style['-moz-transform'] = 'translate3d(' + position + 'px, 0px, 0px)';
    target.style['-ms-transform'] = 'translate3d(' + position + 'px, 0px, 0px)';
    target.style['-o-transform'] = 'translate3d(' + position + 'px, 0px, 0px)';
    target.style.transform = 'translate3d(' + position + 'px, 0px, 0px)';
  }

  pagination.find('a').on('click', function (e) {
    var next = $(this).data('paginationNext') !== undefined;
    updateIndex(next ? 1 : -1);
    e.preventDefault();
  });

  $($window).on('resize', function () {
    updateSize();
  });

  // Trigger initial resize
  $(element).trigger('resize');
}
