import occInstance from '@/api/instances/occ.js';

const CmsApi = {
  async getComponentById(id) {
    const response = await occInstance.get(`/cms/components/${id}`);
    return response.data;
  },
};

export default CmsApi;
