<template>
  <div class="locator-search mb-1">
    <div class="form-group mb-0">
      <div class="form-group--inline relative">
        <div class="w-full relative">
          <form>
            <mapbox-address-suggestions-input-vue
              v-model="textSearchQuery"
              container-classes="relative w-full"
              input-classes="h-full w-full form-control w-full pr-5 search-query"
              input-name="searchQuery"
              input-qaid="store-locator__search__input"
              :input-max-length="100"
              :disabled="isLoading"
              :input-placeholder="$t('storeFinder.v2.enterCity')"
              :is-auto-location-supported="isAutoLocationSupported"
              :query-geolocation="queryGeolocation"
              :is-geolocation-query-scheduled="isGeolocationQueryScheduled"
              :near-location-value="nearLocationValue"
              :is-auto-detecting-location="isAutoDetectingLocation"
              @input-updated="updateSearch($event)"
            />
            <pdl-button
              text
              icon-only
              form-inline
              icon="search"
              type="submit"
              class="absolute top-0 right-2"
              qaid="store-locator__search__search-button"
              @click.prevent="search"
            >
            </pdl-button>
          </form>
        </div>
        <pdl-button
          tertiary
          icon-only
          form-inline
          icon="tune"
          class="ml-1"
          qaid="store-locator__search__filters-button"
          :aria-label="$t('storeFinder.v2.filter')"
          @click.prevent="openFilters"
        >
        </pdl-button>
      </div>
    </div>
  </div>
</template>
<script>
import get from 'lodash/get';
import {ClickOutside} from '@/utils/click-outside';
import {PdlButton} from '@pedal/pdl-button';
import {mapState} from 'vuex';
import MapboxAddressSuggestionsInputVue from '@/components/mapbox/components/MapboxAddressSuggestionsInput.vue';
import GeolocationHelpers from '@/utils/geolocation-helpers';
import {getLocationObject} from '@/components/mapbox/utils/mapbox-api';

export default {
  name: 'LocatorSearch',
  components: {PdlButton, MapboxAddressSuggestionsInputVue},
  mixins: [ClickOutside(['.google-suggestions', '.search-query'])],
  props: {
    value: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    geoipLocation: {
      type: String,
      default: '',
    },
    initialCoordinates: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isGeolocationQueryScheduled: false,
      textSearchQuery: this.value,
      queryCenter: {},
      isGoogleSuggestionsVisible: false,
      autoCompleteOverQuery: '',
      isAutoLocationSupported: false,
      isAutoDetectingLocation: false,
      currentLocationValue: '',
      nearLocationValue: '',
    };
  },
  computed: {
    ...mapState('backend', ['mapboxApiKey']),
  },
  watch: {
    isAutoDetectingLocation(value) {
      if (!value) {
        this.isGeolocationQueryScheduled = false;
      }
    },
    queryCenter(val) {
      this.$emit('query-center', val);
    },
    isLoading(value) {
      if (!value) {
        this.isGoogleSuggestionsVisible = false;
      }
    },
  },

  mounted() {
    this.getCurrentLocation();
  },
  methods: {
    applyAutoCompleteOverQuery(e) {
      if (this.autoCompleteOverQuery.length > 0) {
        this.textSearchQuery = this.autoCompleteOverQuery;
        this.search();
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    },

    async getCurrentLocation() {
      this.isAutoDetectingLocation = true;
      try {
        const currentLocation = await GeolocationHelpers.getBrowserPosition();
        this.isAutoLocationSupported = true;
        const centerData = {lng: currentLocation.coords.longitude, lat: currentLocation.coords.latitude};
        this.getLocationInformation(`${centerData.lng},${centerData.lat}`);
        this.isAutoDetectingLocation = false;
      } catch (error) {
        this.isAutoLocationSupported = false;
        // default to Trek HQ if initial coordinate are 0,0
        this.getLocationInformation(
          `${this.initialCoordinates.lng === 0 ? -89.0058727 : this.initialCoordinates?.lng},${
            this.initialCoordinates.lat === 0 ? 43.1790849 : this.initialCoordinates?.lat
          }`
        );
        this.isAutoDetectingLocation = false;
      }
    },

    async getLocationInformation(location) {
      if (!location) return;
      const locationObject = await getLocationObject(location, this.mapboxApiKey);
      this.textSearchQuery = locationObject.place_name;
      this.nearLocationValue = this.textSearchQuery;
      this.search();
      this.isGeolocationQueryScheduled = false;
    },

    setAutoCompleteOverQuery(query) {
      this.autoCompleteOverQuery = get(query, 'description', '');
    },

    openFilters() {
      this.$emit('open-filters');
    },
    queryGeolocation() {
      if (this.geoipLocation) {
        this.textSearchQuery = this.geoipLocation;
        this.nearLocationValue = this.textSearchQuery;
        this.search();
        this.isGeolocationQueryScheduled = false;
      } else {
        this.getCurrentLocation();
      }
    },
    search() {
      this.$emit('input', this.textSearchQuery);
      this.$emit('search');
    },

    selectSuggestion(suggestion) {
      this.textSearchQuery = suggestion.description;
      this.search();
    },

    showAutoLocation() {
      this.isGoogleSuggestionsVisible = true;
    },

    updateSearch({inputValue = '', data = {}, isSearching = false}) {
      this.textSearchQuery = inputValue;
      this.inputData = data;
      this.isSearching = isSearching;

      if (!isSearching) return;
      if (data.center) {
        const centerData = {lng: data.center[0], lat: data.center[1]};
        this.queryCenter = centerData;
      }
      this.search();
    },

    scheduleGeolocationQuery() {
      this.isGeolocationQueryScheduled = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@-webkit-keyframes processing {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes processing {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.locator-search {
  .auto-location {
    .processing {
      -webkit-animation: processing 2s infinite linear;
      animation: processing 2s infinite linear;
    }
  }

  .google-suggestions {
    position: absolute;
    top: 100%;
    width: 100%;
    padding-right: 3.8em;
    z-index: 3;

    li {
      $border-style: 1px solid var(--gray-20);

      border-left: $border-style;
      border-right: $border-style;
      border-bottom: $border-style;
      background: var(--white);
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      cursor: pointer;

      &:first-child {
        border-top: $border-style;
      }

      &:hover {
        color: var(--white);
        background: var(--blue-100);
        border-color: var(--blue-100);
      }
    }
  }
}
</style>
