<template>
  <div>
    <div v-if="bikeNotFound" class="cell">
      <pdl-callout kind="warning">
        <template #content>
          <span v-html="bikeNotFound" />
        </template>
      </pdl-callout>
    </div>
    <div class="cell">
      <pdl-section-header size="lg" class="mt-3">
        <template slot="content">
          <pdl-heading :level="1" qaid="find-bike-registration-title">{{ bp('registerCustomersBike') }}</pdl-heading>
        </template>
      </pdl-section-header>
    </div>
    <div class="grid-x grid-margin-x">
      <form class="cell large-6" @submit.prevent="submitSerialNumber">
        <p class="mb-3" qaid="find-bike-registration-description">{{ bp('registrationDescription') }}</p>
        <fieldset class="max-w-sm">
          <div class="form-group" :class="errorMsg ? 'has-error' : ''">
            <label for="serial-input" class="form-label">{{ bp('serialNumber') }}</label>
            <input
              id="serial-input"
              v-model="serialNumber"
              type="text"
              name="serial-input"
              class="form-control"
              qaid="find-bike-serial-number"
              :maxlength="30"
            />
            <span v-if="errorMsg" class="form-feedback is-invalid" qaid="find-bike-error-message" v-html="errorMsg" />
          </div>
        </fieldset>
        <div class="buttons">
          <trek-button type="submit" primary qaid="find-bike-button" :disabled="!serialNumber">
            <span v-html="bp('findBike')" />
          </trek-button>
        </div>
        <div class="mb-2">
          <a
            class="underline hover:no-underline"
            :href="contactUsUrl"
            qaid="find-bike-contact-us"
            v-html="bp('requestSupport')"
          />
        </div>
      </form>
      <div class="cell large-6">
        <div class="px-2 py-3 bg-gray-05">
          <img qaid="find-bike-serial-number-location" :src="serialNumberLocationImage" alt="" />
          <p class="mt-2">{{ bp('serialNumberLocation') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrekButton from '@/components/TrekButton';
import {PdlCallout} from '@pedal/pdl-callout';
import {mapState} from 'vuex';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {TrekButton, PdlCallout, PdlSectionHeader, PdlHeading},
  props: {
    baseProperties: {
      type: Array,
      default: () => [],
    },
    errorMsg: {
      type: String,
      default: '',
    },
    bikeNotFound: {
      type: String,
      default: '',
    },
    contextPath: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      serialNumber: '',
      serialNumberLocationImage:
        'https://media.trekbikes.com/image/upload/w_785,h_400,f_auto,q_auto/BikeRegistration_sn_location',
    };
  },
  computed: {
    contactUsUrl() {
      return `${this.contextPath}/${this.b2b ? 'contact-us' : 'contactUs'}/`;
    },
    ...mapState('backend', ['b2b']),
  },

  methods: {
    submitSerialNumber() {
      if (this.serialNumber && this.serialNumber.length > 0) {
        window.location.href = `${this.contextPath}/${
          this.b2b ? 'bike-registration' : 'productRegistration'
        }/serial/${this.serialNumber.toUpperCase().replace(/\s+/g, '')}/`;
      }
    },
  },
};
</script>
