<template>
  <div v-if="listData.length > 0">
    <table class="b2b-grid b2b-grid--collapse is-compact" qaid="saved-list-sku-grid">
      <thead class="b2b-grid__header">
        <!-- Each Header -->
        <tr>
          <th class="b2b-grid__cell b2b-grid__head" qaid="notification-grid-header.checkbox">
            <label class="checkbox">
              <input
                id="notification-settings-select-all"
                v-model="selectAllCheckboxes"
                type="checkbox"
                @change="toggleSelectAll"
              />
              <span class="control-indicator"></span>
            </label>
          </th>
          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="notification-grid-header.sku">
            {{ $t('text.account.savedLists.sku') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="notification-grid-header.item">
            {{ $t('productCompare.description') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="notification-grid-header.upc">
            {{ $t('text.account.savedLists.upc') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="notification-grid-header.upc">
            {{ $t('text.warehouse') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="notification-grid-header.upc">
            {{ $t('text.originalETA') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="notification-grid-header.upc">
            {{ $t('text.currentETA') }}
          </th>
          <th
            class="b2b-grid__cell b2b-grid__head uppercase whitespace-nowrap"
            qaid="notification-grid-header.stockwatch"
          >
            {{ $t('text.etaWatch') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="notification-grid-header.price">
            {{ $t('order.price') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(item, itemIndex) in paginatedData">
          <tr :key="item.id">
            <td class="b2b-grid__cell lg:table-cell">
              <label class="checkbox">
                <input
                  :id="`item-${itemIndex}`"
                  v-model="item.checkboxActive"
                  type="checkbox"
                  :disabled="!item.etaWatchPk"
                />
                <span class="control-indicator"></span>
              </label>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="notification-grid.sku">
              <div class="b2b-grid__label uppercase">{{ $t('text.account.savedLists.sku') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.product.code}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="notification-grid.itemname">
              <div class="b2b-grid__label uppercase">{{ $t('productCompare.description') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.product.itemName}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="notification-grid.upc">
              <div class="b2b-grid__label uppercase">{{ $t('text.account.savedLists.upc') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.product.upc}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="notification-grid.warehouse">
              <div class="b2b-grid__label uppercase">{{ $t('text.warehouse') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.warehouse}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="notification-grid.warehouse">
              <div class="b2b-grid__label uppercase">{{ $t('text.originalETA') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.etaDate}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="notification-grid.warehouse">
              <div class="b2b-grid__label uppercase">{{ $t('text.originalETA') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.estimatedShipDate}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="notification-grid.stockwatch">
              <div class="b2b-grid__label uppercase">{{ $t('text.account.savedLists.stockWatch') }}</div>
              <div class="b2b-grid__content">
                <grid-eta-watch :eta-item="item" />
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="notification-grid.price">
              <div class="b2b-grid__label uppercase">{{ $t('order.price') }}</div>
              <div class="b2b-grid__content">
                <grid-price :data="{dealerCost: item.basePrice.formattedValue}"></grid-price>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <div class="md:flex md:justify-end">
      <div class="md:flex md:grow text-center">
        <pagination-controls
          v-if="pageCount > 1"
          ref="pagination"
          qaid="notification-grid.pagination"
          :page-number="pageNumber"
          :page-count="pageCount"
          @page-change="pageChange"
          @prev-page="prevPage"
          @next-page="nextPage"
        ></pagination-controls>
      </div>

      <div class="buttons buttons--right">
        <trek-button
          primary
          qaid="notification-grid.removebutton"
          :disabled="selectedSkus.length === 0"
          @click="openDialog"
        >
          <span>{{ $t('notifications.B2B.removeWatch') }}</span>
        </trek-button>
      </div>
    </div>

    <pdl-dialog
      :visible.sync="dialogVisible"
      :title="$t('notifications.B2B.delete.check')"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      :z-index="16000011"
      qaid="notification-remove-watch-dialog"
      @close="closeDialog"
    >
      <template slot="footer">
        <div class="buttons buttons--right">
          <trek-button alert qaid="notification-grid.delete.yes" @click="confirmDelete">
            <span>{{ $t('notifications.B2B.delete.yesDelete') }}</span>
          </trek-button>
          <trek-button secondary qaid="notification-grid.delete.no" @click="closeDialog">
            <span>{{ $t('notifications.B2B.delete.noSave') }}</span>
          </trek-button>
        </div>
      </template>
    </pdl-dialog>
  </div>
  <div v-else>
    <pdl-callout>
      <template #content>
        {{ noItemsMessage }}
      </template>
    </pdl-callout>
  </div>
</template>

<script>
import Vue from 'vue';
import pagination from '../../../mixins/pagination';
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlCallout} from '@pedal/pdl-callout';
import GridText from '@/components/grid/GridText';
import GridPrice from '@/components/grid/GridPrice';
import GridEtaWatch from '@/components/grid/GridEtaWatch';
import PaginationControls from '@/components/PaginationControls';
import TrekButton from '@/components/TrekButton';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';
import {PdlToastType} from '@/constants/pdl-toast-type';
import AllocatedBackorderAPI from '@/api/allocated-backorder';
import OrderItemApi from '@/api/order-item-api.js';

export default {
  components: {PdlDialog, GridText, PdlCallout, GridPrice, GridEtaWatch, PaginationControls, TrekButton},
  mixins: [pagination],
  props: {
    productType: {
      type: String,
      required: true,
    },
    noItemsDefaultMessage: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      required: false,
      default: 15,
    },
  },

  data() {
    return {
      selectAllCheckboxes: false,
      dialogVisible: false,
      items: [],
      noItemsMessage: '',
    };
  },
  computed: {
    listData() {
      return this.items;
    },
    selectedSkus() {
      return this.items.filter((item) => item.checkboxActive === true && item.etaWatchPk);
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    toggleSelectAll(event) {
      this.items.map((item) => {
        item.checkboxActive = event.target.checked;
        return item;
      });
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    confirmDelete() {
      this.dialogVisible = false;
      let successCallCounter = 0,
        failureCallCounter = 0,
        totalCallsToMake = this.selectedSkus.length;

      this.selectedSkus.map((mappedItem) => {
        OrderItemApi.deleteEtaWatch(mappedItem.etaWatchPk)
          .then((response) => {
            if (checkMetaFeedbackType(response.data, MetaFeedbackType.SUCCESS)) {
              successCallCounter++;
            } else {
              failureCallCounter++;
            }

            /* NOTE: Show success message when all selected items have successfully updated */
            if (successCallCounter === totalCallsToMake) {
              this.fetchData();
              this.$notify({
                type: PdlToastType.SUCCESS,
                message: this.$t('notifications.B2B.delete.confirmation'),
              });
            } else if (successCallCounter + failureCallCounter === totalCallsToMake) {
              /* NOTE: Just update the table when at least one item failed to be updated */
              this.fetchData();
            }
          })
          .catch((error) => console.log(error));
      });
    },
    async fetchData() {
      let {data, meta} = await AllocatedBackorderAPI.fetchProductTypeETA(this.productType);
      if (data.length > 0) {
        data.forEach((element) => {
          Vue.set(element, 'checkboxActive', false);
          this.items = data;
        });
      } else {
        this.items = [];
        this.noItemsMessage = meta.feedback?.message?.basePropertyValue ?? this.noItemsDefaultMessage;
      }
    },
  },
};
</script>
