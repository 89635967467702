<template>
  <div class="grid-x grid-margin-x justify-between gap-y-4">
    <div class="cell small-auto medium-shrink">
      <dl class="description-list inline-grid gap-x-16 text-base">
        <dt qaid="packing-slip-distribution-center">{{ $t('shipmentInquiry.B2B.distCenter') }}</dt>
        <dd v-html="shipmentDetails.distributionCenter" />

        <dt qaid="packing-slip-packing-slip-number">{{ $t('text.packingSlipNumber') }}</dt>
        <dd v-html="shipmentDetails.packingSlipNumber" />

        <dt qaid="packing-slip-shipment-date">{{ $t('test.shipDate') }}</dt>
        <dd v-html="shipmentDetails.actualShipDate" />

        <dt qaid="packing-slip-bill-number">{{ $t('text.billTo') }}</dt>
        <dd v-html="shipmentDetails.billToNumber" />

        <dt qaid="packing-slip-carrier-name">{{ $t('virtualPackingSlip.B2B.carrier') }}</dt>
        <dd v-html="shipmentDetails.shipmentCarrierName" />

        <dt qaid="packing-slip-tracking-number">{{ $t('text.trackingNumber') }}</dt>
        <dd>
          <trek-link
            id="shipment-tracking-number"
            target="_blank"
            underline
            :href="shipmentDetails.shipmentTrackingUrl"
          >
            <span v-html="shipmentDetails.shipmentTrackingNumber" />
          </trek-link>
        </dd>

        <dt qaid="packing-slip-grand-total-units">{{ $t('virtualPackingSlip.B2B.totalUnits') }}</dt>
        <dd v-html="shipmentDetails.grandTotalUnits" />

        <dt qaid="packing-slip-total-amount">{{ $t('virtualPackingSlip.B2B.totalAmount') }}</dt>
        <dd v-html="shipmentDetails.grandTotalAmount" />
        <dt qaid="packing-slip-billing-address" class="lg:hidden block">{{ $t('text.billToAddress') }}</dt>
        <dd class="lg:hidden block">
          <p v-show="shipmentDetails.billTo" v-html="shipmentDetails.billTo" />
          <p v-show="shipmentDetails.billToAddress1" v-html="shipmentDetails.billToAddress1" />
          <p>
            <span v-show="shipmentDetails.billToCity" v-html="`${shipmentDetails.billToCity}, `" />
            <span v-show="shipmentDetails.billToStateOrProvince" v-html="shipmentDetails.billToStateOrProvince" />
            <span v-show="shipmentDetails.billToPostalCode" class="ml-25" v-html="shipmentDetails.billToPostalCode" />
          </p>
        </dd>
        <dt qaid="packing-slip-shipping-address" class="lg:hidden block">{{ $t('text.shipToAddress') }}</dt>
        <dd class="lg:hidden block">
          <p v-show="shipmentDetails.shipTo" v-html="shipmentDetails.shipTo" />
          <p v-show="shipmentDetails.shipToAddress1" v-html="shipmentDetails.shipToAddress1" />
          <p>
            <span v-show="shipmentDetails.shipToCity" v-html="`${shipmentDetails.shipToCity}, `" />
            <span v-show="shipmentDetails.shipToStateOrProvince" v-html="shipmentDetails.shipToStateOrProvince" />
            <span v-show="shipmentDetails.billToPostalCode" class="ml-25" v-html="shipmentDetails.billToPostalCode" />
          </p>
        </dd>
      </dl>
    </div>
    <div class="cell small-auto medium-shrink hidden lg:block">
      <dl class="description-list inline-grid gap-x-16 text-base">
        <dt qaid="packing-slip-billing-address">{{ $t('text.billToAddress') }}</dt>
        <dd>
          <p v-show="shipmentDetails.billTo" v-html="shipmentDetails.billTo" />
          <p v-show="shipmentDetails.billToAddress1" v-html="shipmentDetails.billToAddress1" />
          <p>
            <span v-show="shipmentDetails.billToCity" v-html="`${shipmentDetails.billToCity}, `" />
            <span v-show="shipmentDetails.billToStateOrProvince" v-html="shipmentDetails.billToStateOrProvince" />
            <span v-show="shipmentDetails.billToPostalCode" class="ml-25" v-html="shipmentDetails.billToPostalCode" />
          </p>
        </dd>
        <dt qaid="packing-slip-shipping-address">{{ $t('text.shipToAddress') }}</dt>
        <dd>
          <p v-show="shipmentDetails.shipTo" v-html="shipmentDetails.shipTo" />
          <p v-show="shipmentDetails.shipToAddress1" v-html="shipmentDetails.shipToAddress1" />
          <p>
            <span v-show="shipmentDetails.shipToCity" v-html="`${shipmentDetails.shipToCity}, `" />
            <span v-show="shipmentDetails.shipToStateOrProvince" v-html="shipmentDetails.shipToStateOrProvince" />
            <span v-show="shipmentDetails.billToPostalCode" class="ml-25" v-html="shipmentDetails.billToPostalCode" />
          </p>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shipmentDetails: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
.description-list {
  grid-template-columns: auto;
}

@screen md {
  .description-list {
    grid-template-columns: auto 1fr;
  }
}
</style>
