/**
 * Created by gpierce on 3/3/16.
 */
'use strict';

/* globals requestReturnLabelValidationMessages */
export default function () {
  function isValid(input) {
    var val = $('#' + input.id).val();
    return val && val.trim().length >= input.minLength && (!input.regex || input.regex.test(val));
  }

  function showInvalidMessages(ids) {
    if (ids.length === 0) {
      return;
    }
    var $errorBox = $('#validation-errors');
    for (var i = 0; i < ids.length; i++) {
      var message = requestReturnLabelValidationMessages[ids[i]];
      $errorBox.append('<li>' + message + '</li>');
    }
    $errorBox.removeClass('hide');
  }

  function clearMessages() {
    $('#validation-errors').empty().addClass('hide');
    $('#submission-success').addClass('hide');
    $('li').removeClass('form__item--error').removeClass('alert-icon-after');
  }

  function validate() {
    var invalidInputs = [];
    var inputsToValidate = [
      {
        id: 'name',
        minLength: 1,
      },
      {
        id: 'order-code',
        minLength: 1,
      },
      {
        id: 'postal-code',
        minLength: 1,
      },
      {
        id: 'address-line1',
        minLength: 1,
      },
      {
        id: 'city',
        minLength: 1,
      },
      {
        id: 'email',
        minLength: 6,
        regex:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
    ];
    for (var i = 0; i < inputsToValidate.length; i++) {
      var input = inputsToValidate[i];
      if (!isValid(input)) {
        var element = $('#' + input.id);
        element.closest('li').addClass('form__item--error alert-icon-after');
        invalidInputs.push(input.id);
      }
    }
    showInvalidMessages(invalidInputs);
    return invalidInputs.length === 0;
  }

  function disableSubmit() {
    $('#form-request-return-label').find("button[type='submit']").prop('disabled', true).addClass('disabled');
  }

  function enableSubmit() {
    $('#form-request-return-label').find("button[type='submit']").prop('disabled', false).removeClass('disabled');
  }

  function buildConcatenatedField() {
    var val =
      $('#name').val() +
      '\n' +
      $('#address-line1').val() +
      '\n' +
      $('#address-line2').val() +
      '\n' +
      $('#city').val() +
      ', ' +
      $('#region').val() +
      ' ' +
      $('#postal-code').val();
    $('#description-field').val(val);
  }

  $('#form-request-return-label').on('submit', function (e) {
    disableSubmit();
    clearMessages();
    if (validate()) {
      buildConcatenatedField();
      //submit
    } else {
      enableSubmit();
      var top = $('#validation-errors').offset().top;
      $('body').animate({
        scrollTop: top - 20,
      });
      //prevent submission
      e.preventDefault();
    }
  });
}
