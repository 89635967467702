<template>
  <form-group
    v-model="bankName"
    type="select"
    :multi-items="bankItems"
    name="bankName"
    :label="$t('text.bank')"
    required
    force-display-error
    show-error-messages
    :error="fieldError('bankName')"
  />
</template>

<script>
import FormGroup from '@/components/FormGroup.vue';
import {mapState, mapMutations} from 'vuex';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  components: {FormGroup},
  mixins: [TrekValidationMixin],
  inject: ['validationErrors'],
  props: {
    bankList: {
      type: Array,
      default: () => [],
    },
  },
  validations() {
    return {
      bankName: {
        required: this.trekValidators.required('text.bank'),
      },
    };
  },
  computed: {
    ...mapState('checkout', ['paymentData']),
    bankName: {
      get() {
        return this.paymentData.bankName;
      },
      set(bankName) {
        this.setBankName(bankName);
      },
    },
    bankItems() {
      return this.bankList?.map(({id, label}) => ({
        value: id,
        name: id,
        displayName: label,
      }));
    },
  },
  mounted() {
    this.setBankName(this.bankList?.[0]?.id);
  },
  methods: {
    ...mapMutations('checkout', ['setBankName']),
    fieldError(fieldName) {
      const theError =
        this.validationErrors.find((item) => item.field === fieldName)?.msg ?? this.getErrorMessage(fieldName);

      return theError;
    },
  },
};
</script>
