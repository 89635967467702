/**
 * Service Package module
 */
'use strict';

export default function () {
  $(window).on('changed.zf.mediaquery', function (event, newSize) {
    trekMessageLocator(newSize);
  });

  function trekMessageLocator(newSize) {
    var trekMessage = $('.ServicePackage__trekmessage');
    var trekMessageTarget = null;
    trekMessage.detach();
    if (newSize.indexOf('large') >= 0) {
      trekMessageTarget = $('.ServicePackage__header');
    } else {
      trekMessageTarget = $('.ServicePackage__tile-grid');
    }
    trekMessageTarget.after(trekMessage);
  }

  // Trigger TrekMessage locator on initial page load then only on breakpoint changes
  trekMessageLocator(window.Foundation.MediaQuery.current);
}
