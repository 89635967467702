import {ItemAvailability} from '@/constants/markup-item-availability';
import {stockStatusConfig} from '@/constants/stock-status';

export const MARKUP_ID = 'schemaMarkupJson';

const getRichResultsPrice = (consumerPrice) => {
  return consumerPrice?.price?.high?.value ?? consumerPrice?.price?.low?.value;
};

const getStockEnum = (stockStatus) => {
  return stockStatusConfig[stockStatus]?.markupItemAvailability || ItemAvailability.OUT_OF_STOCK;
};

const SchemaMarkup = {
  markup: undefined,
  init: function () {
    let schemaMarkupJson;
    //if markup already exists on the page, load it.
    if (document.getElementById(MARKUP_ID)) {
      schemaMarkupJson = JSON.parse(document.getElementById(MARKUP_ID).innerHTML);
    } else if (this.markup) {
      schemaMarkupJson = this.markup;
    } else {
      schemaMarkupJson = {
        '@context': 'https://schema.org',
      };
    }
    this.markup = schemaMarkupJson;
    return this;
  },
  addProductDetails: function (productData) {
    this.markup['@type'] = 'Product';
    if (productData.name) this.markup['name'] = productData.name;
    if (productData.image) this.markup['image'] = productData.image;

    //add variants to 'offers' property
    if (productData.variants && productData.variants.length > 0) {
      this.markup['offers'] = productData.variants.map((variant) => {
        const offer = {'@type': 'Offer'};
        if (variant.consumerPrice) offer['price'] = getRichResultsPrice(variant.consumerPrice);
        if (variant.code) offer['sku'] = variant.code;
        if (variant.name) offer['name'] = variant.name;
        offer['availability'] = getStockEnum(variant.globalStockStatus);
        return offer;
      });
    } else {
      if (productData.consumerPrice) this.markup['price'] = getRichResultsPrice(productData.price);
    }
    return this;
  },
  addPowerReviews: function (powerReviewsData) {
    const aggregateData = powerReviewsData?.results[0]?.rollup;
    if (!aggregateData || !aggregateData.average_rating || !aggregateData.review_count) return this;

    this.markup['aggregateRating'] = {
      '@type': 'AggregateRating',
      ratingValue: aggregateData.average_rating,
      reviewCount: aggregateData.review_count,
    };

    return this;
  },
  injectMarkup: function () {
    if (!this.markup) throw new Error('Markup not initialized');
    if (document.getElementById(MARKUP_ID)) {
      const schemaMarkupScript = document.getElementById(MARKUP_ID);
      schemaMarkupScript.innerHTML = JSON.stringify(this.markup);
      return this;
    }
    const schemaMarkupScript = document.createElement('script');
    schemaMarkupScript.type = 'application/ld+json';
    schemaMarkupScript.id = MARKUP_ID;
    schemaMarkupScript.innerHTML = JSON.stringify(this.markup);
    document.head.appendChild(schemaMarkupScript);
    return this;
  },
};

export default SchemaMarkup;
