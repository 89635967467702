<template>
  <ul :class="[defaultClasses, customClasses]" :qaid="qaid">
    <li v-if="fullName">{{ fullName }}</li>
    <li>{{ address.line1 }}</li>
    <li v-if="address.line2">{{ address.line2 }}</li>
    <li v-if="locale === 'kr'">{{ address.postalCode }}</li>
    <li v-else-if="isAlternateAddressFormat">{{ address.postalCode }}&nbsp;{{ address.town }}</li>
    <li v-else-if="locale === 'jp'">
      <span v-if="regionName">{{ regionName }}</span>
      <span v-else-if="address.regionText">{{ address.regionText }}</span>
      <span>&nbsp;{{ address.postalCode }}</span>
    </li>
    <li v-else class="flex flex-wrap">
      <span>{{ address.town }}</span>
      <span v-if="regionName">,&nbsp;{{ regionName }}</span>
      <span v-else-if="address.regionText">,&nbsp;{{ address.regionText }}</span>
      <span>&nbsp;{{ address.postalCode }}</span>
    </li>
  </ul>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'AddressSummary',
  props: {
    address: {
      type: Object,
      default: () => ({}),
    },
    qaid: {
      type: String,
      default: '',
    },
    customClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      reverseFullNameLocale: ['jp'],
    };
  },
  computed: {
    ...mapState('backend', ['locale']),
    isAlternateAddressFormat() {
      return this.locale === 'de' || this.locale === 'at' || this.locale === 'deelectra' || this.locale === 'atelectra';
    },
    regionName() {
      return this.address.region?.name;
    },
    fullName() {
      let fullNameValue = '';

      if (this.address.firstName && this.address.lastName) {
        if (this.reverseFullNameLocale.includes(this.locale)) {
          fullNameValue = `${this.address.lastName} ${this.address.firstName}`;
        } else {
          fullNameValue = `${this.address.firstName} ${this.address.lastName}`;
        }
      }
      return fullNameValue;
    },
    defaultClasses() {
      return {
        'mb-2': !this.customClasses,
      };
    },
  },
};
</script>
