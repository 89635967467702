<template>
  <div id="pr-write" class="ml-5 mb-3" />
</template>
<script>
import {mapState} from 'vuex';

export default {
  name: 'ProductReviewsWriteReviewComponent',
  data() {
    return {
      isWriteReviewComponentRendered: false,
    };
  },
  computed: {
    ...mapState('powerReviews', ['productDetails', 'isWindowPwrLoaded']),
  },
  watch: {
    isWindowPwrLoaded(val) {
      if (val) {
        this.initPowerReviews();
      }
    },
  },
  methods: {
    initPowerReviews() {
      if (window.pwr && !this.isWriteReviewComponentRendered) {
        window.pwr('render', {
          merchant_group_id: this.productDetails.pr_merchant_group_id,
          merchant_id: this.productDetails.pr_merchant_id,
          page_id: this.productDetails.pr_page_id,
          locale: this.productDetails.pr_locale,
          components: {
            Write: 'pr-write',
          },
        });
        window.addEventListener('load', function () {
          if ($('.hero-product__container').length) {
            $('.hero-product__container').addClass('hide');
          }
        });
        this.isWriteReviewComponentRendered = true;
      }
    },
  },
};
</script>
