<template>
  <pdl-loading :is-loading="isMapLoading">
    <mapbox-map :map-classes="mapClasses" qaid="locator-page-map-canvas" />
  </pdl-loading>
</template>

<script>
import MapFactory from '../utils/mapbox';
import MapboxMap from '../components/MapboxMap';
import {PdlLoading} from '@pedal/pdl-loading';
import {mapState} from 'vuex';

const DEFAULT_MAP_ZOOM = 16;

export default {
  name: 'LocatorPageMapContainer',
  components: {
    MapboxMap,
    PdlLoading,
  },
  props: {
    mapClasses: {
      type: String,
      default: '',
    },
    storeCoordinates: {
      type: Object,
      default: () => null,
    },
    zoom: {
      type: Number,
      default: DEFAULT_MAP_ZOOM,
    },
    storeDetails: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      map: undefined,
      marker: undefined,
      markerOptions: {draggable: false},
      zoomLevel: DEFAULT_MAP_ZOOM,
      isMapLoading: true,
    };
  },
  computed: {
    ...mapState('backend', ['mapboxApiKey']),
  },
  watch: {
    zoomLevel() {
      this.map.mapInstance.setZoom(this.zoomLevel);
    },
  },
  async mounted() {
    this.map = await MapFactory({
      accessToken: this.mapboxApiKey,
      center: [this.storeCoordinates?.lng, this.storeCoordinates?.lat],
      zoom: this.zoom,
      cooperativeGestures: true,
    });
    this.map.includeZoomControl();
    // popup html content
    const popupHtml = `<p class="font-semibold">${this.storeDetails.name}</p>
      <p>${this.storeDetails.address}</p>
      <p>${this.storeDetails.city}</p>
      <p>${this.storeDetails.zip}</p>
      <p>${this.storeDetails.country}</p>
      <p>${this.storeDetails.url}</p>`;
    // Added Marker on map
    this.marker = this.map.addMarker({
      position: [this.storeCoordinates?.lng, this.storeCoordinates?.lat],
      markerOptions: this.markerOptions,
    });
    this.map.addPopupToMarker({
      popupHtml: popupHtml,
      marker: this.marker,
      eventListenerObj: {},
    });
    // Added full screen control on map
    this.map.addFullscreenControl();
    // smoothly resizes map instance to screen height & width
    this.map.mapInstance.on('load', () => {
      this.map.mapInstance.resize();
      this.isMapLoading = false;
    });
  },
  destroyed() {
    // Clean-up of mapbox & marker
    this.map.removeAllMapComponents();
  },
};
</script>
