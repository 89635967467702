<template>
  <div class="contents">
    <div :id="`p1-tooltip-icon-${id}`" v-tippy-html>{{ $t('projectOne.B2B.order.btoIcon.hover') }}</div>
    <pdl-icon
      v-tippy="{
        html: `#p1-tooltip-icon-${id}`,
        interactive: true,
        reactive: true,
        trigger: 'mouseenter focus',
        flip: true,
        sticky: true,
        theme: 'light left-align',
      }"
      name="project_one_colorwheel"
      size="18"
      class="filter grayscale flex-none self-center cursor-pointer mr-1"
      :qaid="`p1-tooltip-icon-${id}`"
    />
  </div>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {
    PdlIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>
