<template>
  <div class="form-group--inline">
    <pdl-button
      qaid="decrease-input-button"
      button
      tertiary
      icon="remove"
      icon-only
      class="button--inline button-style"
      :label="decreaseButtonLabel"
      :disabled="disabled || !canDecrease"
      @click.prevent="decrease"
    />
    <input
      id="quantity-input-field"
      v-model="inputValue"
      qaid="quantity-input-field"
      type="number"
      class="form-control p-25 w-16 text-center"
      :disabled="disabled"
      :aria-label="inputLabel"
    />
    <pdl-button
      qaid="increase-input-button"
      button
      tertiary
      icon="add"
      icon-only
      class="button--inline button-style"
      :label="increaseButtonLabel"
      :disabled="disabled || !canIncrease"
      @click.prevent="increase"
    />
  </div>
</template>

<script>
import {PdlButton} from '@pedal/pdl-button';

export default {
  name: 'QuantityInput',
  components: {PdlButton},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    decreaseButtonLabel: {
      type: String,
      required: true,
    },
    increaseButtonLabel: {
      type: String,
      required: true,
    },
    inputLabel: {
      type: String,
      required: true,
    },
    maximum: {
      type: Number,
      default: 9999,
    },
    value: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    canDecrease() {
      return this.inputValue > 1;
    },
    canIncrease() {
      return this.maximum < 1 || this.inputValue < this.maximum;
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(value, oldValue) {
        value = parseInt(value);

        if (!value || value < 1) {
          value = 1;
        } else if (this.maximum > 0 && value > this.maximum) {
          value = this.maximum;
        }

        if (value !== oldValue) {
          this.$emit('input', value);
        }
      },
    },
  },
  methods: {
    decrease() {
      this.inputValue--;
    },
    increase() {
      this.inputValue++;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control[disabled] {
  background-color: transparent;
}

.button-style {
  padding: 0 0.25rem;
}
</style>
