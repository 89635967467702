<template>
  <div class="mb-1">
    <div class="buying-zone__form-label-group">
      <span class="font-semibold" v-html="label" />
    </div>
    <div v-if="items.length">
      <div ref="grid" :style="gridStyle">
        <product-attribute-button
          v-for="option in items"
          :key="option.value"
          :base-properties="baseProperties"
          :option="option"
          :product="product"
          :attribute="attribute"
          :selected-attributes="selectedAttributes"
          :update-selected-attributes="updateSelectedAttributes"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {Attribute} from '@/components/containers/pdp/attributes/mixins/attribute';
import ProductAttributeButton from '@/components/containers/pdp/attributes/ProductAttributeButton';
import {mapState} from 'vuex';

export default {
  name: 'ProductAttributeButtonGrid',
  components: {ProductAttributeButton},
  mixins: [Attribute],
  props: {
    gap: {
      type: Number,
      default: 8,
    },
  },

  data() {
    return {
      isCalculated: false,
      columns: 0,
      maxWidth: 0,
      resizeTimeout: null,
    };
  },
  computed: {
    ...mapState('backend', ['isB2Bsite']),

    items() {
      let items = [];

      this.attribute.forEach((item) => {
        const selected = {
          ...this.selectedAttributes,
          [this.code]: item.value,
        };

        const variant = this.product.variants.find((v) => {
          return Object.entries(selected).every(([key, value]) => {
            return v.variantAttributes[key] === value;
          });
        });

        if (this.isB2Bsite && !variant) return;

        items.push(item);
      });

      return items;
    },
    classes() {
      return {
        'product-attribute-button-grid': this.isCalculated,
      };
    },
    gridStyle() {
      return {
        display: this.isCalculated ? 'grid' : 'block',
        'grid-template-columns': `repeat(${this.columns}, 1fr)`,
        gap: `${this.gap}px`,
      };
    },
  },
  mounted() {
    this.observeGridSize();
  },
  updated() {
    this.observeGridSize();
  },
  unmounted() {
    this.gridObserver && this.gridObserver.disconnect();
  },

  methods: {
    observeGridSize() {
      if (!this.$refs.grid || this.gridObserver) {
        return;
      }
      this.gridObserver = new ResizeObserver(this.calculateColumns);
      this.gridObserver.observe(this.$refs.grid);
      this.calculateColumns();
    },
    initSizes() {
      if (this.isCalculated) {
        return;
      }
      let widths = Array.from(this.$refs.grid?.children).map((b) => b.offsetWidth);
      this.maxWidth = Math.max(...widths) + this.gap;
    },
    calculateColumns() {
      this.initSizes();
      let containerWidth = this.$refs.grid.clientWidth;
      this.columns = Math.floor(containerWidth / this.maxWidth);
      if (this.columns) {
        //We have a valid calculation so set iscalculated so the widths/maxwidths don't get recalculated
        this.isCalculated = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.product-attribute-button-grid {
  display: grid;
  grid-template-columns: 1fr;
}
</style>
