<template>
  <tr
    ref="elementLocation"
    :class="[
      {hidden: hideUnavailable && (!isPurchasable || product.isOutOfStockAtAllWareHouses)},
      {'b2b-grid__row--match': isExactSearchMatch},
    ]"
    :data-search="isExactSearchMatch ? 'search-match' : ''"
    :qaid="`sku-grid.item-row-${rowIndex}`"
  >
    <td v-if="column.ICON" class="b2b-grid__cell lg:hidden xl:hidden xxl:table-cell" qaid="sku-grid.icon" />
    <td v-if="column.SKU" class="b2b-grid__cell lg:table-cell" qaid="sku-grid.sku">
      <div class="b2b-grid__label">
        {{ $t('text.account.savedLists.sku') }}
      </div>
      <div class="b2b-grid__content" :qaid="`sku-grid.sku-${product.code}`">
        <div class="flex items-center">
          <!-- If we have 'hasLink' as true, then only show link or tooltip redirecting to other pages  -->
          <a
            v-if="hasLink"
            :href="`${encodedContextPath}/my-account/orders?partNumber=${product.code}`"
            class="leading-none mr-1"
            qaid="sku-grid.orderHistory"
          >
            <pdl-icon name="history" :label="$t('orders')" />
          </a>
          <project-one-bto-icon v-if="product.isBTO" :id="`${rowIndex}`" />
          <div>
            <div v-if="isPdp">
              <grid-text :data="{value: product.code, tooltip: hasLink ? getTooltipText(product, false) : null}" />
            </div>
            <div v-else>
              <grid-text
                :data="{
                  value: product.code,
                  tooltip: getTooltipText(product, false),
                  link: encodedContextPath + '/p/' + product.code,
                }"
              />
            </div>
            <div v-if="product.upc" qaid="sku-grid.upc" class="hidden lg:flex">
              <span class="text-gray-70">{{ product.upc }}</span>
            </div>
          </div>
        </div>
      </div>
    </td>

    <!-- Variable product variants -->
    <template v-if="column.VARIANT">
      <td
        v-for="(variant, variantKey) in product.categorization"
        :key="`variant-${variantKey}`"
        class="b2b-grid__cell lg:table-cell"
        :qaid="`sku-grid.sku-${variant.name}`"
      >
        <!-- Labels (small view only) -->
        <div class="b2b-grid__label">
          <span>{{ variant.name }}</span>
        </div>

        <div class="b2b-grid__content" :qaid="`sku-grid.sku-${variant.name}-${variant.value}`">
          <grid-text :data="{value: variant.value}" />
        </div>
      </td>
    </template>

    <td v-if="column.UPC_EAN" class="b2b-grid__cell lg:hidden" qaid="sku-grid.upc">
      <div class="b2b-grid__label">
        {{ $t('text.account.savedLists.upc') }}
      </div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: product.upc}" />
      </div>
    </td>

    <td v-if="column.ALLOC" class="b2b-grid__cell lg:table-cell h-auto lg:text-right" qaid="sku-grid.allocated">
      <div class="b2b-grid__label">
        {{ $t('text.allocated') }}
      </div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: quantityAllocated}" />
      </div>
    </td>

    <td v-if="column.BO" class="b2b-grid__cell lg:table-cell h-auto lg:text-right" qaid="sku-grid.backordered">
      <div class="b2b-grid__label">
        {{ $t('text.backordered') }}
      </div>
      <div class="b2b-grid__content b2b-grid__content--right">
        <pdl-link
          v-if="quantityBackordered"
          qaid="sku-grid-backordered-link"
          standalone
          xsmall
          :href="backorderAllocatedUrl"
        >
          <grid-text :data="{value: quantityBackordered}" />
        </pdl-link>
      </div>
    </td>

    <!-- Variable product warehouses -->
    <template v-if="column.WAREHOUSE">
      <template v-if="btoLeadTime">
        <td
          v-if="hasWarehouses(product.warehouses)"
          :colspan="product.warehouses.length"
          class="b2b-grid__cell lg:table-cell"
          qaid="sku-grid.warehouse"
        >
          <div class="b2b-grid__label">
            {{ product.warehouses && product.warehouses.length ? product.warehouses[0].id : '' }}
          </div>
          <div class="b2b-grid__content text-center" qaid="sku-grid.btoLeadTime" v-html="btoLeadTime" />
        </td>
      </template>

      <template v-else-if="productEtaAvailable">
        <td :colspan="product.warehouses.length" class="b2b-grid__cell lg:table-cell" qaid="sku-grid.warehouse">
          <div class="b2b-grid__label">
            {{ getWarehouseTitle(product) }}
          </div>
          <div
            class="b2b-grid__content lg:text-center text-red font-medium"
            qaid="sku-grid.newProductEta"
            v-html="productEtaAvailable"
          />
        </td>
      </template>

      <template v-else-if="hasWarehouses(product.warehouses)">
        <td
          v-for="(warehouse, warehouseKey) in getWarehouses(product)"
          :key="`warehouse-${warehouseKey}`"
          class="b2b-grid__cell lg:table-cell"
          qaid="sku-grid.warehouse"
        >
          <div class="b2b-grid__label">
            {{ warehouse.id }}
          </div>
          <div class="b2b-grid__content">
            <grid-warehouse
              :warehouse="warehouse"
              :is-bto-purchasable-b2b="product.isPurchasableB2B"
              :no-eta-text="$t('text.buyingZone.noEta')"
              :bto-text="$t('product.stockStatus.bto')"
              :sold-out-text="$t('text.buyingZone.soldOut')"
              :not-available-text="$t('text.na')"
            />
          </div>
        </td>
      </template>
    </template>

    <td
      v-if="hasWarehouses(product.warehouses) && column.STOCK_WATCH"
      class="b2b-grid__cell lg:table-cell h-auto"
      qaid="sku-grid.stock-watch"
    >
      <div class="b2b-grid__label">
        {{ $t('text.account.savedLists.stockWatch') }}
      </div>
      <div class="b2b-grid__content">
        <grid-stock-watch
          :warehouses="getWarehouseStockWatchOptions(product.stockWatch, productGroup.products)"
          :product-index="product.code"
          @update-stock-watch-state="(payload) => $emit('update-stock-watch-state', payload)"
        />
      </div>
    </td>

    <td
      v-if="displayPackageQuantity && column.PKG_QTY"
      class="b2b-grid__cell lg:table-cell"
      qaid="sku-grid.package-quantity"
    >
      <div class="b2b-grid__label">
        {{ $t('skuGrid.B2B.pkgQty') }}
      </div>
      <div class="b2b-grid__content text-left lg:text-right">
        <grid-text :data="{value: product.packageQty}" />
      </div>
    </td>

    <td v-if="!isConsumerFriendlyMode && column.MSRP" class="b2b-grid__cell lg:table-cell" qaid="sku-grid-msrp">
      <div class="b2b-grid__label">
        {{ $t('text.msrp') }}
      </div>
      <div class="b2b-grid__content text-left lg:text-right">
        <grid-advertised-price :price="priceData.consumerPrice" />
      </div>
    </td>
    <td
      v-if="!isConsumerFriendlyMode && column.RETAILER_COST"
      class="b2b-grid__cell lg:table-cell text-left lg:text-right"
      qaid="sku-grid.order-price"
    >
      <div class="b2b-grid__label">
        {{ $t('order.price') }}
      </div>
      <div class="b2b-grid__content">
        <grid-price
          :data="{
            dealerCost,
            discount,
          }"
        />
      </div>
    </td>
    <td v-if="column.LIST" qaid="sku-grid.list" class="b2b-grid__cell lg:table-cell lg:text-right">
      <div class="b2b-grid__label">
        <pdl-icon size="18" name="list" />
      </div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: product.skuQtyInSavedList > 0 ? product.skuQtyInSavedList : ''}" />
      </div>
    </td>
    <td v-if="column.CART" qaid="sku-grid.shopping_cart" class="b2b-grid__cell lg:table-cell lg:text-right">
      <div class="b2b-grid__label">
        <pdl-icon size="18" name="shopping_cart" />
      </div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: product.skuQtyInCart}" />
      </div>
    </td>
    <td v-if="column.QTY" class="b2b-grid__cell lg:table-cell mb-4 md:mb-0 w-16" qaid="sku-grid.qty">
      <div class="b2b-grid__label">
        {{ $t('text.account.savedLists.qty') }}
      </div>
      <div class="b2b-grid__content" :class="{'has-warning': minQtyError}">
        <numerical-input
          v-if="minQtyError"
          ref="quantityInput"
          v-tippy="{
            html: `#qty-tooltip-${productGroupIndex}-${rowIndex}`,
            theme: 'light',
            maxWidth: '20rem',
            placement: 'top',
          }"
          :data-sku="product.code"
          :disabled="!isPurchasable"
          :min="0"
          :max="9999"
          :should-clear="true"
          :sku="product.code"
        />
        <numerical-input
          v-else
          ref="quantityInput"
          :data-sku="product.code"
          :disabled="!isPurchasable"
          :min="0"
          :max="9999"
          :should-clear="true"
          :sku="product.code"
          @update-variant-qty="updateVariantQty"
        />
      </div>
    </td>

    <div v-show="false" :id="`qty-tooltip-${productGroupIndex}-${rowIndex}`" v-tippy-html class="hidden">
      <span v-html="minQtyErrorMessage" />
    </div>
  </tr>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {mapState, mapActions} from 'vuex';
import GridText from '@/components/grid/GridText';
import GridWarehouse from '@/components/grid/GridWarehouse';
import GridStockWatch from '@/components/grid/GridStockWatch';
import GridPrice from '@/components/grid/GridPrice';
import GridAdvertisedPrice from '@/components/grid/GridAdvertisedPrice';
import NumericalInput from '@/components/NumericalInput';
import {PdlLink} from '@pedal/pdl-link';
import warehouseMixin from '@/mixins/warehouse';
import ProjectOneBtoIcon from '@/components/grid/icons/ProjectOneBtoIcon.vue';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {
    GridText,
    GridWarehouse,
    GridStockWatch,
    GridPrice,
    GridAdvertisedPrice,
    NumericalInput,
    PdlLink,
    ProjectOneBtoIcon,
    PdlIcon,
  },

  mixins: [warehouseMixin],

  props: {
    hideUnavailable: Boolean,
    product: {
      type: Object,
      default: () => null,
    },
    productGroupIndex: {
      type: Number,
      default: 0,
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    productGroup: {
      type: Object,
      default: () => null,
    },
    minQtyError: Boolean,
    minQtyErrorMessage: {
      type: String,
      default: null,
    },
    isPdp: Boolean,
    isPlp: Boolean,
    isCustomProduct: {
      type: Boolean,
      default: false,
    },
    displayPackageQuantity: {
      type: Boolean,
      required: true,
    },
    warehouseCodes: {
      type: Array,
      required: true,
    },
    hasLink: {
      type: Boolean,
      default: true,
    },
    column: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      searchResultReference: String,
    };
  },

  computed: {
    ...mapState('backend', ['isSpectrumEnabled', 'encodedContextPath', 'isConsumerFriendlyMode']),
    ...mapState('skuGrid', ['allocatedCounts']),
    ...mapState('pdp', ['requestedProductCode', 'pdpDetailsLoaded']),

    quantityBackordered() {
      const value = get(this.allocatedCounts, `${this.product.code}.quantityBackordered`, 0);
      return value !== 0 ? value : null;
    },

    quantityAllocated() {
      const value = get(this.allocatedCounts, `${this.product.code}.quantityAllocated`, 0);
      return value !== 0 ? value : null;
    },

    dealerCost() {
      return this.priceData?.retailerPrice?.wasPrice?.low.formatted;
    },

    discount() {
      return this.priceData?.retailerPrice?.price?.low.formatted;
    },

    isExactSearchMatch() {
      return this.requestedProductCode === this.product.code;
    },

    productEtaAvailable() {
      const newProductETAMonthTranslation = this.$t(`general.month.${this.product.newProductETAMonth}`);
      if (this.product.newProductETAYear && newProductETAMonthTranslation) {
        const newProductETADate = newProductETAMonthTranslation + ' ' + this.product.newProductETAYear;
        return this.$t('skuGrid.B2B.newProductETA', [newProductETADate]);
      }
      return false;
    },

    btoLeadTime() {
      if (this.product.btoLeadTime && this.product.isPurchasableB2B) {
        return this.$t('skuGrid.B2B.leadTime', [this.product.btoLeadTime]);
      }

      return false;
    },

    isPurchasable() {
      return (
        !isEmpty(this.dealerCost) &&
        !isEmpty(this.product.code) &&
        this.product.isPurchasableB2B &&
        !this.isSpectrumEnabled &&
        !this.isCustomProduct
      );
    },

    backorderAllocatedUrl() {
      const redirectPath = `${this.encodedContextPath}/my-account/backorders-allocated-items?productType=${this.product.productType}&skuSearchTerm=${this.product.code}`;
      return redirectPath;
    },
    priceData() {
      return this.product.prices || this.product;
    },
  },

  watch: {
    pdpDetailsLoaded() {
      if (this.$refs['elementLocation'].getAttribute('data-search') === 'search-match') {
        window.addEventListener('load', this.getSearchResultReference());
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.$emit('register-qty-input', this.$refs['quantityInput']);
    });
  },
  beforeDestroy() {
    window.removeEventListener('load', this.getSearchResultReference());
  },
  methods: {
    getSearchResultReference() {
      this.$nextTick(() => {
        const loadDOMDelay = 1500;
        setTimeout(() => {
          this.searchResultReference = this.$refs['elementLocation'];
          this.updateSearchResultReference(this.searchResultReference);
        }, loadDOMDelay);
      });
    },

    getTooltipText(product, onlyMFR) {
      const replacementProduct = product.replacementProduct ?? null;
      const mfr = product.mfr ?? null;
      const replacementProductText = this.$t('productArchive.viewCurrent');
      let toolTipData = {};
      if (mfr !== null) {
        toolTipData['MFR'] = mfr;
      }
      if (replacementProduct && !onlyMFR) {
        toolTipData['replacementProductText'] = replacementProductText;
        toolTipData['replacementProductUrl'] = replacementProduct.url;
      }
      return toolTipData;
    },

    getWarehouseTitle(product) {
      /**This will be used to determine title of warehouse columm in lower viewports**/
      if (product.warehouses && product.warehouses.length > 0) {
        let warehousesNumber = product.warehouses.length - 1;
        let warehouseTitle = ''; //initializing empty string, to work with below concatenation
        while (warehousesNumber >= 0) {
          warehouseTitle += product.warehouses[warehousesNumber].id + ' ';
          warehousesNumber--;
        }
        return warehouseTitle;
      }
    },

    getWarehouses(product) {
      return this.fillWarehousesByCodes(this.warehouseCodes, product.warehouses);
    },

    updateVariantQty(payload) {
      this.$emit('update-variant-qty', {qty: payload, sku: this.product.code, input: this.$refs['quantityInput']});
    },

    ...mapActions('pdp', ['updateSearchResultReference']),
  },
};
</script>
<style scoped>
.b2b-grid .b2b-grid__row--match {
  background-color: var(--green-10);
}
</style>
