<template>
  <input-autocomplete
    :input-classes="inputClasses"
    :container-classes="containerClasses"
    :input-id="inputId"
    :input-qaid="inputQaid"
    :input-placeholder="inputPlaceholder"
    :input-name="inputName"
    :input-required="inputRequired"
    :input-max-length="inputMaxLength"
    class="flex flex-1"
    :suggestion-list="suggestionList"
    :initial-value="setInitialValue"
    :is-auto-location-supported="isAutoLocationSupported"
    :query-geolocation="queryGeolocation"
    :is-geolocation-query-scheduled="isGeolocationQueryScheduled"
    :near-location-value="nearLocationValue"
    :is-auto-detecting-location="isAutoDetectingLocation"
    @input-updated="updateInputValue($event)"
  ></input-autocomplete>
</template>
<script>
import InputAutocomplete from '@/components/InputAutocomplete';
import {getSearchSuggestionList} from '@/components/mapbox/utils/mapbox-api';
import debounce from 'lodash/debounce';
import {mapState, mapGetters} from 'vuex';

const DEFAULT_SUGGESTION_AMOUNT = 5;
const MIN_CHARACTER_SEARCH_COUNT = 3;
const SEARCH_SUGGESTIONS_DEBOUNCE_TIME = 250;

export default {
  components: {
    InputAutocomplete,
  },
  props: {
    suggestionAmount: {
      type: Number,
      default: DEFAULT_SUGGESTION_AMOUNT,
    },
    inputClasses: {
      type: String,
      default: '',
    },
    containerClasses: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      default: '',
    },
    inputQaid: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      default: '',
    },
    inputRequired: {
      type: String,
      default: '',
    },
    inputPlaceholder: {
      type: String,
      default: '',
    },
    inputMaxLength: {
      type: Number,
      default: 150,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    nearLocationValue: {
      type: String,
      default: '',
    },
    isAutoLocationSupported: {
      type: Boolean,
      default: false,
    },
    queryGeolocation: {
      type: Function,
      default: null,
    },
    isGeolocationQueryScheduled: {
      type: Boolean,
      default: false,
    },
    isAutoDetectingLocation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: '',
      inputData: {},
      inputCenter: [],
      isSearching: false,
      suggestionList: [],
      isShowingSuggestionList: false,
    };
  },
  computed: {
    ...mapState('backend', ['geolocation', 'mapboxApiKey']),
    ...mapGetters('backend', ['countryCode']),
    setInitialValue() {
      return !this.nearLocationValue.length > 0 ? this.geolocation.address : this.nearLocationValue;
    },
  },
  watch: {
    inputValue() {
      this.updateInputAndSuggestions();
    },
  },
  methods: {
    updateInputValue({inputValue = '', data = {}, isSearching = false}) {
      this.inputValue = inputValue;
      this.inputData = data;
      this.isSearching = isSearching;
      if (isSearching) {
        this.unchangedInputValueSearch();
      }
    },
    unchangedInputValueSearch() {
      this.updateInputAndSuggestions();
    },
    updateInputAndSuggestions() {
      this.$emit('input-updated', {
        inputValue: this.inputValue,
        data: this.inputData,
        isSearching: this.isSearching,
      });

      if (this.inputValue.length < MIN_CHARACTER_SEARCH_COUNT) {
        this.isShowingSuggestionList = false;
        this.suggestionList = [];
        this.$emit('input-suggestions-updated', this.suggestionList);
        return;
      }
      this.isShowingSuggestionList = true;
      this.updateSearchSuggestions(this.inputValue);
    },
    updateSearchSuggestions: debounce(async function (newValue) {
      try {
        await getSearchSuggestionList({
          search: newValue,
          accessToken: this.mapboxApiKey,
          country: this.countryCode,
        }).then((suggestionList) => {
          if (this.isShowingSuggestionList) this.suggestionList = suggestionList;
        });
      } catch (error) {
        console.error(`Error with Mapbox API: ${error}`);
        this.suggestionList = [];
        this.isShowingSuggestionList = false;
      } finally {
        this.$emit('input-suggestions-updated', this.suggestionList);
      }
    }, SEARCH_SUGGESTIONS_DEBOUNCE_TIME),
  },
};
</script>
