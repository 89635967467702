'use strict';

export default function () {
  var searchPopup = $('[data-search-reveal-target]');

  searchPopup.on('opened', function () {
    window.console.log('searchPopup opened fired.');
    $('.js-site-search-input').focus();
  });

  $('[data-search-close]').on('click', function () {
    window.console.log('searchPopup click fired.');
    searchPopup.trigger('close');
  });
}
