<template>
  <pdl-icon size="48">
    <svg
      id="amex-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 256 256"
      xml:space="preserve"
    >
      <g>
        <rect fill="#fff" width="290" height="250" />
        <path
          fill="#006fcf"
          d="M0,0v256h256v-40.4h-35.3l-13.1-15.3L194,215.6H93.7v-81.3H60.6L102,40.5h40.3l9.7,21.2V40.5h50l8.4,22.3
		l8.2-22.3H256V0H0z M227.1,53.8l-13.1,34.6l-3.5,9.4l-3.6-9.4l-13.2-34.6h-28.9V122h17.4V77.6l-0.1-9l3.4,9l16.6,44.5h16.6
		l16.7-44.5l3.2-8.9V122H256V53.8H227.1z M111.4,53.8L81.2,122h19.8l5.3-13.1h33.1l5.3,13.1h20.1l-30-68.3H111.4z M119.4,76.9
		l3.4-8.5l3.4,8.5l7.1,17.2h-21L119.4,76.9z M228.4,134.3l-20.6,22.1l-20.5-22.1h-22.7h-57v68h57v-14.8h-39.6v-11.9h38.8V161h-38.8
		v-12h39.6v-14.8l31.8,34.1l-31.8,33.9h22l20.7-22.2l20.6,22.2h22.6l-31.9-34.1l31.9-33.9H228.4z M236.7,168.1l19.3,19.6v-39
		L236.7,168.1z"
        />
      </g>
    </svg>
  </pdl-icon>
</template>
<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {PdlIcon},
};
</script>
