'use strict';

import selectBox from '../modules/SelectBox';

export default function () {
  selectBox('regionIso', 'name');

  $(document).delegate('#chooseDeliveryMethodBtn', 'click', function () {
    $('#selectedAddressCode').val($('.selected #deliveryAddressCode').val());
  });

  $('select[name=regionIso]').change(function (e) {
    e.preventDefault();
    var selectedVal = $('select[name=regionIso] option:selected').text();
    $('#select_regionIso').html(selectedVal);
  });
  $(document).ready(function () {
    // Check on page ready to see if there was a previous selection made.
    var matchingInput = $('[id*=CLICK-AND-COLLECT-NO-BIKE]');
    if (matchingInput && matchingInput.is(':checked')) {
      $('.conditionalOption').removeClass('hide');
    }

    $('input:radio[name="delivery_method"]').change(function () {
      if (this.checked && this.value.indexOf('CLICK-AND-COLLECT-NO-BIKE') >= 0) {
        $('.conditionalOption').removeClass('hide');
      } else {
        $('.conditionalOption').addClass('hide');
      }
    });
  });
}
