import occInstance from '@/api/instances/occ';

const rootUrl = 'account/bike-garage';

const BikeGarageApi = {
  async getBikeCategories() {
    const {data: response} = await occInstance.get('grand-categories');
    return response;
  },
  async createBike(payload) {
    const {data: response} = await occInstance.post(`${rootUrl}`, payload);
    return response;
  },
  async getBikes() {
    const {data: response} = await occInstance.get(`${rootUrl}/list`);
    return response;
  },
  async updateBike(payload) {
    const {data: response} = await occInstance.put(`${rootUrl}`, payload);
    return response;
  },
};

export default BikeGarageApi;
