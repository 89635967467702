<script>
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlButton} from '@pedal/pdl-button';
import FindRetailerDialog from '@/components/containers/my-account/FindRetailerDialog.vue';
import UserOccApi from '@/api/user-occ.js';
import {PdlToastType} from '@/constants/pdl-toast-type.js';
import {mapActions, mapMutations, mapState} from 'vuex';

export default {
  /* eslint-disable vue/no-unused-components */
  components: {
    PdlDialog,
    PdlButton,
    FindRetailerDialog,
  },
  data() {
    return {
      hasFindRetailerDialogVisible: false,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapState('user', ['userPreferredRetailer', 'userProfile']),
    hasPreferredRetailer() {
      return Boolean(this.userPreferredRetailer);
    },
  },
  async mounted() {
    await this.fetchUserProfile();
  },
  methods: {
    ...mapActions('user', ['fetchUserProfile']),
    ...mapMutations('user', ['setUserPreferredRetailer']),
    openFindRetailerDialog() {
      this.hasFindRetailerDialogVisible = true;
    },
    closeFindRetailerDialog() {
      this.hasFindRetailerDialogVisible = false;
    },
    checkRideClubUser() {
      if (this.userProfile?.rideClubAppFirstLogin) {
        this.showDeleteModal = true;
      } else {
        this.removeUserPreferredRetailer();
      }
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    async removeUserPreferredRetailer() {
      const payloadObj = {
        preferredRetailerCode: '',
      };
      try {
        await UserOccApi.patchUserProfile(payloadObj);
        this.setUserPreferredRetailer(null);
        this.$notify({
          type: PdlToastType.SUCCESS,
          message: this.$t('account.confirmation.preferredRetailer.removed'),
        });
      } catch (e) {
        this.$notify({
          type: PdlToastType.ERROR,
          message: this.$t('errorMessage.unableToProcess'),
        });
      } finally {
        this.closeDeleteModal();
      }
    },
  },
};
</script>
