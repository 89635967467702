<template>
  <div class="text-center pb-4">
    {{ $t('text.search.noResults') }}
  </div>
</template>
<script>
export default {
  name: 'PlpNoResults',
};
</script>
