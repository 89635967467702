<template>
  <div v-if="checkboxConfig">
    <template v-if="!isKcp">
      <div class="mb-3" qaid="privacy-policy-text">
        {{ $t('checkout.summary.termsAndConditions6') }}
        <pdl-link qaid="privacy-policy-link" :href="termsLink" target="_blank">
          {{ $t('page_PrivacyPolicy') }}
        </pdl-link>
      </div>
    </template>
    <form-group
      qaid="consent-checkboxes"
      class="form__fieldset mb-3"
      :disabled="false"
      error=""
      :multi-items="items"
      name="termsAndConditionsCheckboxes"
      :required="true"
      show-error-messages
      show-label
      type="checkbox"
      :label-classes="'text-sm'"
      :warning="false"
      @change="handleCheck"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import FormGroup from '@/components/FormGroup';
import isEmpty from 'lodash/isEmpty';
import CheckboxConfiguration from '@/modules/consent-checkboxes';
import {PdlLink} from '@pedal/pdl-link';

const TERMS_CHECKED_EVENT = 'requiredTermsChecked';

export default {
  components: {FormGroup, PdlLink},
  data: () => {
    return {
      checkboxConfig: undefined,
    };
  },
  computed: {
    ...mapState('backend', ['locale', 'encodedContextPath']),
    items() {
      return this.checkboxConfig?.getCheckboxList();
    },
    isTranslationLoaded() {
      return !isEmpty(window.VueI18n.messages);
    },
    isKcp() {
      return this.locale === 'kr';
    },
    termsLink() {
      return `${this.encodedContextPath}/company/legal_policies/privacy_policy_terms_of_use/`;
    },
  },
  watch: {
    isTranslationLoaded(updateValue, prevValue) {
      if (!prevValue && updateValue) {
        this.checkboxConfig = CheckboxConfiguration(this.locale).getRegistrationConfiguration();
        if (this.checkboxConfig.getIsEmpty()) this.$emit(TERMS_CHECKED_EVENT, true);
      }
    },
  },
  methods: {
    handleCheck(ev) {
      if (ev.srcElement.name === 'allTermsCheck') {
        this.checkboxConfig?.toggleAll();
      } else {
        this.checkboxConfig?.toggleCheckbox(ev.srcElement.name);
      }
      this.$emit(TERMS_CHECKED_EVENT, this.checkboxConfig.getIsAllRequiredChecked());
    },
  },
};
</script>
