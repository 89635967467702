<template>
  <!-- A duplication of VendorSelector.vue to have new styles/behavior -->
  <div class="order-0 mt-2 flex items-stretch lg:mt-0">
    <button
      id="vendorSelectorToggle"
      ref="vendorSelectorToggle"
      v-tippy="{
        html: '#vendor-selector-popover',
        interactive: true,
        reactive: true,
        trigger: 'click',
        theme: 'flush',
        placement: 'top-start',
        flipBehavior: ['top', 'bottom-end'],
      }"
      class="flex items-center text-lg outline-none leading-none text-md lg:font-medium"
    >
      <pdl-icon class="mr-1" name="business" />
      <span id="vendorSelectorToggle_unitName">{{ currentVendorName }}</span>
      <pdl-icon id="vendorSelectorToggle_dropDown" size="18" class="ml-25" name="arrow_drop_down" />
    </button>

    <!-- Dropdown Content -->
    <div v-cloak id="vendor-selector-popover" class="text-left p-2">
      <!-- Header -->
      <pdl-section-header size="sm">
        <template slot="content">
          <pdl-heading>{{ $t('distributor.B2B.vendor.select') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <!-- Search Box (If needed) -->
      <div v-if="vendorList && vendorList.length >= searchDisplayThreshold">
        <label for="vendor-search" class="form-label text-md">{{ $t('distributor.B2B.vendor.search') }}</label>
        <div class="form-group">
          <input id="vendor-search" v-model="search" type="text" class="form-control" />
          <span class="form-help">{{ $t('distributor.B2B.vendor.enter') }}</span>
        </div>
      </div>

      <!-- Scrollable vendor list -->
      <div v-if="filteredVendorList && filteredVendorList.length" class="b2b-scroll-list-container min-w-xs">
        <div class="b2b-scroll-list">
          <div
            v-for="(vendor, index) in filteredVendorList"
            :key="index"
            role="button"
            tabindex="0"
            class="b2b-scroll-list__item"
            @click="selectVendor(vendor)"
            @keyup.enter="selectVendor(vendor)"
          >
            <p class="font-bold">{{ vendor.name }} - {{ vendor.id }}</p>
            <p v-if="vendor.notes" class="italic mt-1">{{ vendor.notes }}</p>
          </div>
        </div>
      </div>
      <pdl-callout v-else id="vendorSelectorDropdown_emptyState">
        <template #content>
          <span>{{ $t('distributor.B2B.vendor.noneFound') }}</span>
        </template>
      </pdl-callout>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import storefrontInstance from '@/api/instances/storefront';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {PdlSectionHeader, PdlHeading, PdlCallout, PdlIcon},

  props: {
    initialVendor: {
      type: Object,
      default: null,
    },
    vendorList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      currentVendor: this.initialVendor,
      search: '',
      // This can be used to control when the Search Box appears on the modal depending
      // on how many vendors there are
      searchDisplayThreshold: 2,
    };
  },

  computed: {
    currentVendorName() {
      return this.currentVendor ? this.currentVendor.name || this.currentVendor.id : null;
    },

    filteredVendorList() {
      if (typeof this.search === 'string' && this.search.length && Array.isArray(this.vendorList)) {
        return this.vendorList.filter((vendor) =>
          `${vendor.name} - ${vendor.id}`.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return this.vendorList;
      }
    },

    ...mapState('backend', ['encodedContextPath']),
  },

  methods: {
    selectVendor(vendor) {
      if (this.currentVendor && this.currentVendor.pk === vendor.pk) return;
      this.currentVendor = vendor;
      this.$nextTick(async () => {
        await storefrontInstance.post('/my-account/setVendor', new URLSearchParams({vendorPk: vendor.pk}));

        if (location.pathname === `${this.encodedContextPath}/`) {
          location.reload();
        } else {
          location.replace(this.encodedContextPath);
        }

        this.$refs.vendorSelectorToggle._tippy.hide();
      });
    },
  },
};
</script>
