<script>
import {mapState} from 'vuex';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {PdlSectionHeader, PdlHeading},

  props: {
    orderDetails: {
      type: Object,
      default: () => null,
    },
    partType: {
      type: String,
      default: '',
    },
    isDist: {
      type: Boolean,
      default: false,
    },
    allocatedItemsExistenceMap: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    ...mapState('checkout', ['b2bCartData']),
    ...mapState('backend', ['isConsumerFriendlyMode']),

    deliveryMethodName() {
      return (
        this.orderDetails.shippingMethods.find((method) => method.code === this.orderDetails.selectedShipping).name ||
        ''
      );
    },

    defaultPickerDate() {
      let defaultDate = new Date();

      if (this.orderDetails?.shipmentInfo?.deliveryDate) {
        defaultDate = new Date(this.orderDetails.shipmentInfo.deliveryDate);
      }

      // Ensures the defaultDate is always higher than the minDate (new Date()) so the default
      // value actually populates in flatpickr
      defaultDate.setSeconds(59);
      defaultDate.setMinutes(59);

      return defaultDate;
    },

    // The logic is very similar to defaultPickerDate
    distDeliveryDate() {
      let distDefaultDate = this.defaultPickerDate;
      distDefaultDate.setHours(23); // for potential edge cases

      return distDefaultDate;
    },
  },

  watch: {
    orderDetails: {
      deep: true,
      handler() {
        if (this.$refs.dateRangePicker) {
          if (this.isDist) {
            this.$refs.dateRangePicker.fp.setDate(this.distDeliveryDate);
          } else {
            this.$refs.dateRangePicker.fp.setDate(this.defaultPickerDate);
          }
        }
      },
    },
  },

  methods: {
    onDateInput({event}) {
      this.$emit('update-shipping-date', {value: event, partType: this.partType});
    },
  },
};
</script>
