<template>
  <div>
    <a
      v-if="productType !== enumProductTypes.DIProjectOne"
      :href="`${encodedContextPath}/p/${sku}`"
      class="underline hover:no-underline"
    >
      {{ sku }}
    </a>
    <span v-else>{{ sku }}</span>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import {ProductType} from '@/constants/product';

export default {
  props: {
    sku: {
      type: String,
      required: true,
    },
    productType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      enumProductTypes: ProductType,
    };
  },
  computed: {
    ...mapState('backend', ['encodedContextPath']),
  },
};
</script>
