<template>
  <div>
    <pdl-loading top :is-loading="loading">
      <div v-cloak class="grid-container text-base">
        <pdl-callout v-if="showEmptyWarning" kind="warning">
          <template #content>
            <span v-html="getBaseProperty('error')" />
          </template>
        </pdl-callout>
        <pdl-section-header size="xl" :is-dividing="true" class="mt-4">
          <template slot="content">
            <pdl-heading :level="1">{{ getBaseProperty('collectionOrderForm') }}</pdl-heading>
          </template>
        </pdl-section-header>

        <bulk-product-grid-container
          v-if="!showEmptyWarning"
          :base-properties="baseProperties"
          :product-groups="productGroups"
          @update-stock-watch-state="fetchProducts"
        />
      </div>
    </pdl-loading>
    <pdl-loading :is-loading="!loading && loadingMore" />
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import get from 'lodash/get';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import ProductGroups from '@/components/containers/bulk-product/utils/product-groups';
import {PdlLoading} from '@pedal/pdl-loading';
import BulkProductGridContainer from '@/components/containers/bulk-product/BulkProductGridContainer';
import {PdlCallout} from '@pedal/pdl-callout';
import {mapActions} from 'vuex';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {
    PdlLoading,
    BulkProductGridContainer,
    PdlCallout,
    PdlSectionHeader,
    PdlHeading,
  },
  props: {
    baseProperties: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      loading: true,
      loadingMore: false,
      productGroups: [],
      currentPage: 0,
      noDataOnBackend: false,
    };
  },

  computed: {
    showEmptyWarning() {
      return !(this.productGroups && Object.keys(this.productGroups).length);
    },
  },

  created() {
    this.fetchProducts();
  },

  mounted() {
    window.onscroll = () => this.bottomOfPageWatcher();
  },

  methods: {
    ...mapActions('skuGrid', ['loadAllocatedCounts']),

    getBaseProperty(name) {
      let baseProperty = find(this.baseProperties, (baseProperty) => baseProperty.name === name);
      return baseProperty ? baseProperty.label : '';
    },

    getCollectionIdFromUrl() {
      let pathArray = window.location.pathname.split('/');
      let index = findIndex(pathArray, (x) => x == 'bulk-order-forms');

      if (index > -1 && pathArray.length > index + 1) {
        return pathArray[index + 1];
      }

      return null;
    },

    fetchProducts() {
      let bulkOrderId = this.getCollectionIdFromUrl();

      if (bulkOrderId) {
        storefrontInstance
          .get(`/bulk-order-forms/b/${bulkOrderId}/?page=${this.currentPage}`)
          .then(({data}) => {
            if (data) {
              const records = get(data, 'data.results', []);
              const totalPages = get(data, 'pagination.numberOfPages', 0);

              if (records && records.length > 0) {
                const ids = [];
                records.forEach((record) => {
                  ids.push(record.code);
                  this.productGroups.push(...new ProductGroups(record).getProductGroups());
                });

                this.currentPage += 1;
                if (!totalPages || this.currentPage >= totalPages) {
                  this.noDataOnBackend = true;
                }

                this.loadAllocatedCounts(ids);
              }
            }
          })
          .finally(() => {
            this.loading = false;
            this.loadingMore = false;
          });
      } else {
        this.loading = false;
        this.loadingMore = false;
      }
    },

    bottomOfPageWatcher() {
      const triggerDistanceFromBottom = 1000;
      let triggerPointReached =
        window.pageYOffset + window.innerHeight + triggerDistanceFromBottom >= document.documentElement.scrollHeight;

      if (triggerPointReached && !this.noDataOnBackend) {
        // disable watcher for 2 seconds to avoid multiple fetches in case of slow response
        this.loadingMore = true;
        window.onscroll = null;
        this.fetchProducts();
        setTimeout(() => {
          window.onscroll = () => this.bottomOfPageWatcher();
        }, 2000);
      }
    },
  },
};
</script>
