const Constants = {
  /**
   * Get a store placeholder image.
   * @param width
   * @param height
   * @return {string}
   */
  storePlaceholder(width, height) {
    return `https://media.trekbikes.com/image/upload/f_auto,q_auto,c_fill,w_${width},h_${height}/Store_Placeholder_4x3`;
  },
};

export {Constants};
