<template>
  <div :role="roleType" class="list-item-component">
    <!-- custom configuration -->
    <slot />

    <!-- named configuration slots -->
    <div class="list-item-pre">
      <slot name="pre-content" />
    </div>
    <div class="list-item-group">
      <p class="list-item-group__content"><slot name="content" /></p>
      <p class="list-item-group__subtext"><slot name="sub-text" /></p>
      <p class="list-item-group__highlight"><slot name="high-light-text" /></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListItemComponent',

  props: {
    /**
     * Sets the role for element and accessibility
     */
    roleType: {
      type: String,
      default: 'list-item',
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item-component {
  display: flex;

  @apply mb-4;

  .list-item-group__content,
  .list-item-group__subtext {
    @apply font-normal;
  }

  .list-item-group__subtext {
    @apply italic;
    @apply text-sm;
  }

  .list-item-group__highlight {
    @apply text-red;
    @apply font-bold;
  }
}
</style>
