<script>
import CheckoutConfirmationContainer from '@/components/checkout/b2c/CheckoutConfirmationContainer';
import CheckoutSummaryContainer from '@/components/containers/checkout/CheckoutSummaryContainer';
import CheckoutMultiStepContainer from '@/components/checkout/b2c/CheckoutMultiStepContainer';
import CartRestoration from '@/components/cart/header/CartRestoration';
import B2cCheckoutNav from '@/components/checkout/B2cCheckoutNav';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
import TrekFooter from '@/components/TrekFooter';
import LiveChat from '@/components/LiveChat';
import {PdlLoading} from '@pedal/pdl-loading';
import {mapMutations, mapState} from 'vuex';
import headTag from '@/utils/head-tag';

export default {
  name: 'CheckoutContainer',
  components: {
    CheckoutConfirmationContainer,
    CheckoutSummaryContainer,
    CheckoutMultiStepContainer,
    CartRestoration,
    B2cCheckoutNav,
    TrekFooter,
    PdlButton,
    PdlLink,
    LiveChat,
    PdlLoading,
  },
  props: {
    savedContinueShoppingUrl: {
      type: String,
      default: '',
    },
  },
  created() {
    this.setContinueShoppingUrl(this.savedContinueShoppingUrl);
  },
  computed: {
    ...mapState('backend', ['isProduction']),
  },
  async mounted() {
    const GR4VY_FINGERPRINT_URL = this.isProduction
      ? 'https://api.trek.gr4vy.app/f.js'
      : 'https://api.sandbox.trek.gr4vy.app/f.js';
    await headTag.appendAsyncScript('deivce-fingerprints', GR4VY_FINGERPRINT_URL);
  },
  methods: {
    ...mapMutations('checkout', ['setContinueShoppingUrl']),
  },
};
</script>
