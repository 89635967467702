<template>
  <fieldset class="mb-5">
    <legend class="text-lg font-bold">{{ $t('checkout.address.details') }}</legend>
    <address-autofill-input
      v-if="isAddressAutofillEnabled && !isAddressFieldsShowing"
      :on-address-selected="handleAddressSelected"
    />
    <slot v-if="isPostcodeLookupEnabled" name="postcode-autofill" />
    <p v-if="hasDeliveryRestrictionsMessage" class="form__shipping mb-3">
      <span>{{ $t('text.shipping') }} </span><span class="font-bold">{{ $t('text.shipping.US') }}</span>
    </p>
    <ul v-if="isAddressFieldsShowing" class="mb-5">
      <li
        v-for="(componentConfig, index) in fieldList"
        :key="`${componentConfig.field}-${index}`"
        :qaid="componentConfig.qaid"
      >
        <component
          :is="componentConfig.component"
          v-bind="getFieldProps(componentConfig.field)"
          v-model="form[componentConfig.field]"
        />
      </li>
      <li>
        <form-group
          v-if="isUserLoggedIn"
          type="checkbox"
          :multi-items="defaultAddressCheckbox"
          :label-classes="'text-sm'"
          name="defaultAddress"
          show-label
          :required="false"
          @change="handleCheckboxChange"
        />
      </li>
    </ul>
  </fieldset>
</template>

<script>
import {Line1Field, Line2Field, TownCityField, RegionField, PostcodeField} from '@/components/checkout/fields/address';
import AddressAutofillInput from '@/components/checkout/fields/address-autofill/AddressAutofillInput';
import {mapState} from 'vuex';
import {getAddressFormConfig, AddressFormComponents} from '@/config/checkout/address-form-config';
import FormGroup from '@/components/FormGroup';

export default {
  components: {Line1Field, Line2Field, TownCityField, RegionField, PostcodeField, FormGroup, AddressAutofillInput},
  props: {
    isAddressAutofillEnabled: {
      type: Boolean,
      default: false,
    },
    isPostcodeLookupEnabled: {
      type: Boolean,
      default: false,
    },
    regions: {
      type: Array,
      default: () => [],
    },
    postcodeMaxLength: {
      type: Number,
      required: true,
    },
    postcodeFormat: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isAddressFieldsShowing: !this.isAddressAutofillEnabled,
      isDefaultAddressChecked: false,
      form: {
        line1: '',
        line2: '',
        postcode: '',
        regionIso: '',
        townCity: '',
      },
    };
  },
  computed: {
    ...mapState('backend', ['locale', 'isUserLoggedIn']),
    fieldList() {
      return getAddressFormConfig(this.locale).addressDetails;
    },
    hasDeliveryRestrictionsMessage() {
      return (this.locale === 'gb' || this.locale === 'nl') && !this.isPostcodeLookupEnabled;
    },
    defaultAddressCheckbox() {
      return [
        {
          name: 'defaultAddress',
          label: 'default-address-checkbox',
          displayName: this.$t('text.default.address'),
          checked: this.isDefaultAddressChecked,
        },
      ];
    },
  },
  methods: {
    handleAddressSelected(suggestion) {
      this.isAddressFieldsShowing = true;
      this.populateAddressFields(suggestion);
    },
    populateAddressFields(suggestion) {
      let regionProp = this.locale === 'jp' ? 'name' : 'isocodeShort';
      Object.entries(suggestion).forEach(([key, value]) => {
        if (key === 'region') {
          this.form[key] = this.regions.find((region) => region[regionProp] === value).isocode;
          return;
        }
        this.form[key] = value;
      });
    },
    handleCheckboxChange(event) {
      this.$emit('checkbox-change', event.target.checked);
    },
    getFieldProps(field) {
      const propConfig = {
        [AddressFormComponents.REGION.field]: {
          regions: this.regions,
        },
        [AddressFormComponents.POSTCODE.field]: {
          postcodeMaxLength: this.postcodeMaxLength,
          postcodeFormat: this.postcodeFormat,
        },
      };
      return propConfig[field] ?? {};
    },
  },
};
</script>
