<template>
  <div class="flex flex-wrap w-full">
    <div class="flex justify-between w-full" :qaid="`cart-totals-${productType}-title`">
      <span class="font-medium">
        {{ $t(`cart.totals.title.${productType}`) }}
        <span v-if="isQuantityVisible"> ({{ subtotal.quantity }})</span>
      </span>
      <span>{{ subtotal.prices.totalPrice.formattedValue }}</span>
    </div>
    <slot name="subtitle" :product-type="productType" />
    <div v-if="minAmountPresent" class="flex justify-end w-full mt-1">
      <div>
        <span :class="{'text-warning': highlightMinAmount}">{{ subtotal.prices.totalPrice.formattedValue }}</span>
        / {{ minAmountValue }}
        {{ $t('distributor.B2B.minOrderValue') }}
      </div>
    </div>
  </div>
</template>

<script>
import {ProductTypesWithVisibleQuantity} from '@/constants/product';
export default {
  name: 'CartTotalsTitle',
  props: {
    productType: {
      type: String,
      required: true,
    },
    subtotal: {
      type: Object,
      required: true,
      validator(value) {
        return ['quantity', 'prices'].every((prop) => Object.hasOwn(value, prop));
      },
    },
    validationRules: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    highlightMinAmount() {
      return (
        this.subtotal?.prices?.totalPrice?.value <
        this.validationRules.find((rule) => rule.type === 'SUBTOTAL_MIN_TOTAL_PRICE').totalPrice?.value
      );
    },
    minAmountPresent() {
      return (
        this.subtotal?.prices?.totalPrice?.value &&
        this.validationRules.some((rule) => rule.type === 'SUBTOTAL_MIN_TOTAL_PRICE')
      );
    },
    minAmountValue() {
      return this.validationRules.find((rule) => rule.type === 'SUBTOTAL_MIN_TOTAL_PRICE').totalPrice?.formattedValue;
    },
    isQuantityVisible() {
      return ProductTypesWithVisibleQuantity.includes(this.productType);
    },
  },
};
</script>
