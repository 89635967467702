<template>
  <div v-if="value && Object.keys(value).length > 0" :qaid="`store-locator-card__store-${value.id}`">
    <div v-if="value.isMyStore" class="mt-1 lg:ml-2 flex" qaid="store-locator-card__favorite_indicator">
      <pdl-icon name="favorite" /> <span class="ml-1 font-bold">{{ $t('storeFinder.v2.yourStore') }}</span>
    </div>
    <div
      role="button"
      tabindex="0"
      class="locator-store-card cursor-pointer"
      :class="{hovered: pinHovered}"
      qaid="store-locator-card__card"
      @click.prevent="storeClicked"
      @keyup.enter="storeClicked"
    >
      <pdl-media-object>
        <div slot="media">
          <img
            :src="storeImage"
            class="w-24 h-auto lazyload"
            qaid="store-locator-card__image"
            alt=""
            @error="usePlaceholder"
          />
        </div>
        <div slot="content">
          <div class="text-md font-bold" qaid="store-locator-card__name" v-html="value.name"></div>
          <p qaid="store-locator-card__address" v-html="value.address"></p>
          <div class="flex">
            <span qaid="store-locator-card__distance">{{ value.distance }}</span>
            <span class="inline-block mx-25">&bull;</span>
            <span class="font-bold mr-25" qaid="store-locator-card__open-status">
              {{ getStoreStatus(value.statusText) }}
            </span>
            <div qaid="store-locator-card__hours">
              <div v-for="(hour, i) in value.statusHours" :key="`hour-${value.id}${i}`">{{ hour }}</div>
            </div>
          </div>
          <pdl-label-group class="mt-1" qaid="store-locator-card-labels">
            <pdl-label v-if="hasBestSelection" kind="positive" qaid="store-locator-best-selection-label">
              {{ $t('storelocator.bestSelection') }}
            </pdl-label>
            <pdl-label v-if="hasSale" kind="prominent" qaid="store-locator-sale-label">
              {{ $t('text.buyingZone.sale') }}
            </pdl-label>
          </pdl-label-group>
        </div>
      </pdl-media-object>
    </div>
  </div>
</template>
<script>
/* Mixins */
import trackLocatorStore from '@/mixins/tracking/track-locator-store';

/* Components */
import {Constants} from '@/components/locator/mixins/constants';
import {PdlLabel, PdlLabelGroup} from '@pedal/pdl-label';
import {PdlMediaObject} from '@pedal/pdl-media-object';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'LocatorCard',
  components: {
    PdlLabel,
    PdlLabelGroup,
    PdlMediaObject,
    PdlIcon,
  },
  mixins: [trackLocatorStore],
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    pinHovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasBestSelection() {
      return !!this.value?.badges?.bestSelection;
    },
    hasSale() {
      return !!this.value?.badges?.sale;
    },

    storeImage() {
      return this.value.thumbnail ?? this.usePlaceholder();
    },
  },
  methods: {
    usePlaceholder(e) {
      if (e && e.target) {
        e.target.src = Constants.storePlaceholder(292, 219);
      } else {
        return Constants.storePlaceholder(292, 219);
      }
    },

    storeClicked() {
      this.$emit('store-clicked', this.value.id, this.value.distance);
      this.trackStoreClick(this.value);
    },

    getStoreStatus(status) {
      if (!status) return null;

      if (status.toLowerCase() == 'closed') {
        return this.$t(`storeDetails.table.opening.${status}`);
      } else if (status.toLowerCase() == 'today' || status.toLowerCase() == 'tomorrow') {
        return this.$t(`storeDetails.openingSchedule.${status}`);
      } else {
        return this.$t(`storeFinder.v2.open.${status}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pdl-media-object {
  .locator-store-card ::v-deep & {
    margin: 0;
    padding: 1.5rem 0;

    @include breakpoint(large) {
      padding: 1.5rem calc(var(--grid-column-gutter) / 2);
    }
  }
}

.pdl-media-object__media {
  .locator-store-card ::v-deep & {
    display: none;

    @include breakpoint(medium) {
      display: block;
    }
  }
}

.pdl-media-object__content {
  .locator-store-card ::v-deep & {
    @apply text-sm;
  }
}
</style>
