<script>
export default {
  props: {
    size: {
      type: String,
      default: '',
    },
  },
};
</script>
