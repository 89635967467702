<template>
  <label :class="className" :qaid="qaidValue">
    <input
      ref="input"
      type="checkbox"
      class="v-switch-input"
      :name="name"
      :checked="value"
      @change="toggle"
      @input="inputEvent"
    />
    <div class="v-switch-core" :style="coreStyle">
      <div class="v-switch-button" :style="buttonStyle" />
    </div>
    <template v-if="labels">
      <span v-if="toggled" class="v-switch-label v-left" :style="labelStyle" v-html="labelChecked" />
      <span v-else class="v-switch-label v-right" :style="labelStyle" v-html="labelUnchecked" />
    </template>
    <span v-if="textLabel" :class="labelClassName" class="v-switch-text">{{ textLabel }}</span>
  </label>
</template>

<script>
const constants = {
  colorChecked: 'var(--gray-10)',
  colorUnchecked: 'var(--gray-10)',
  cssColors: true,
  labelChecked: 'on',
  labelUnchecked: 'off',
  width: 32,
  height: 16,
  margin: 2,
  switchColor: '#fff',
};

const contains = (object, title) => {
  return typeof object === 'object' && Object.prototype.hasOwnProperty.call(object, title);
};

const px = (v) => v + 'px';

export default {
  name: 'ToggleSwitch',
  inheritAttrs: false,
  props: {
    monochrome: {
      type: Boolean,
      default: false,
    },

    labelLeft: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      default: '',
    },

    sync: {
      type: Boolean,
      default: false,
    },

    speed: {
      type: Number,
      default: 300,
    },

    qaid: {
      type: String,
      default: '',
    },

    // #nomoretwovalueprops - needs refactor to one value
    /*eslint-disable */
    color: {
      type: [String, Object],
      validator(value) {
        return typeof value === 'object'
          ? value.checked || value.unchecked || value.disabled
          : typeof value === 'string';
      },
    },
    // #nomoretwovalueprops - needs refactor to one value
    switchColor: {
      type: [String, Object],
      validator(value) {
        return typeof value === 'object' ? value.checked || value.unchecked : typeof value === 'string';
      },
    },
    /*eslint-enable */

    cssColors: {
      type: Boolean,
      default: true,
    },

    labels: {
      type: [Boolean, Object],
      default: false,
      validator(value) {
        return typeof value === 'object' ? value.checked || value.unchecked : typeof value === 'boolean';
      },
    },

    textLabel: {
      type: String,
      default: String,
    },

    height: {
      type: Number,
      default: constants.height,
    },

    width: {
      type: Number,
      default: constants.width,
    },
  },

  data() {
    return {
      toggled: !!this.value,
    };
  },

  computed: {
    className() {
      let {toggled, disabled, monochrome, labelLeft} = this;
      return [
        'vue-js-switch',
        {
          toggled,
          disabled,
          'vue-js-switch--monochrome': monochrome,
          'flex-row-reverse': labelLeft,
        },
      ];
    },
    labelClassName() {
      let {labelLeft} = this;
      return [
        {
          'mr-1': labelLeft,
          'ml-1': !labelLeft,
        },
      ];
    },

    qaidValue() {
      return `${this.qaid}${this.toggled ? '-toggled' : ''}${this.disabled ? '-disabled' : ''}`;
    },

    coreStyle() {
      return {
        width: px(this.width),
        height: px(this.height),
        backgroundColor: this.cssColors ? null : this.disabled ? this.colorDisabled : this.colorCurrent,
        borderRadius: px(Math.round(this.height / 2)),
      };
    },
    buttonRadius() {
      return this.height - constants.margin * 2;
    },
    distance() {
      return px(this.width - this.height + constants.margin);
    },
    buttonStyle() {
      const background = this.switchColor ? this.switchColorCurrent : null;

      return {
        width: px(this.buttonRadius),
        height: px(this.buttonRadius),
        transition: `transform ${this.speed}ms`,
        transform: this.toggled ? `translate3d(${this.distance}, 2px, 0px)` : null,
        background,
      };
    },
    labelStyle() {
      return {
        lineHeight: px(this.height),
      };
    },
    colorChecked() {
      let {color} = this;
      if (typeof color !== 'object') {
        return color || constants.colorChecked;
      }
      return contains(color, 'checked') ? color.checked : constants.colorChecked;
    },
    colorUnchecked() {
      let {color} = this;
      return contains(color, 'unchecked') ? color.unchecked : constants.colorUnchecked;
    },
    colorDisabled() {
      let {color} = this;
      return contains(color, 'disabled') ? color.disabled : this.colorCurrent;
    },
    colorCurrent() {
      return this.toggled ? this.colorChecked : this.colorUnchecked;
    },
    labelChecked() {
      return contains(this.labels, 'checked') ? this.labels.checked : constants.labelChecked;
    },
    labelUnchecked() {
      return contains(this.labels, 'unchecked') ? this.labels.unchecked : constants.labelUnchecked;
    },
    switchColorChecked() {
      let {switchColor} = this;
      return contains(switchColor, 'checked') ? switchColor.checked : constants.switchColor;
    },
    switchColorUnchecked() {
      let {switchColor} = this;
      return contains(switchColor, 'unchecked') ? switchColor.unchecked : constants.switchColor;
    },
    switchColorCurrent() {
      let {switchColor} = this;
      if (typeof switchColor !== 'object') {
        return switchColor || constants.switchColor;
      }
      return this.toggled ? this.switchColorChecked : this.switchColorUnchecked;
    },
  },

  watch: {
    value(value) {
      if (this.sync) {
        this.toggled = !!value;
      }
    },
  },

  methods: {
    toggle(event) {
      this.toggled = !this.toggled;
      this.$emit('input', this.toggled);
      this.$emit('change', {
        value: this.toggled,
        srcEvent: event,
      });
    },

    inputEvent() {
      this.$emit('input', this.toggled);
    },
  },
};
</script>
