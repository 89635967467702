<template>
  <pdl-collapse
    :key="`listViewExpandGroup${itemId}`"
    :icon-active="collapseOptions.iconActive"
    :icon-inactive="collapseOptions.iconInactive"
    :icon-position="collapseOptions.iconPosition"
    align-icon="start"
    class="border-b border-gray-20 product-list-view-item"
    :default-active="false"
    qaid="plpListViewItemContainer"
  >
    <pdl-collapse-item
      :name="`listViewExpandGroup${itemId}`"
      :qaid="`listViewExpandGroup${itemId}`"
      :is-active="isActive"
      :disabled="product && product.isArchived"
      @state-changed="stateChanged"
    >
      <template slot="title">
        <pdl-media-object>
          <div slot="media" qaid="plpListViewItemImage">
            <a :href="productUrl">
              <img class="w-full h-auto lazyload" :src="resizedImageUrl" :alt="product.displayName" />
            </a>
            <product-compare-button
              v-if="isCompareButtonVisible"
              small
              :product="product"
              class="mt-1"
              qaid="plpListViewItemCompareButton"
            />
          </div>
          <div slot="content" qaid="plpListViewItemInfo">
            <product-label-year
              :product="product"
              class="mb-1"
              :product-url-customizer-open="productUrlCustomizerOpen"
              :qaid="`listViewItem${itemId}`"
            />
            <p class="font-medium text-lg mb-0 mt-25 leading-tight" qaid="plpListViewItemName">
              <a :href="productUrl">{{ product.displayName }}</a>
            </p>
            <p class="product-card__price text-sm mt-0 mb-2">
              <a :href="productUrl" class="no-underline" qaid="plpListViewItemPrice">
                <span class="product-card__price--sale">{{ price.sale }}</span>
                <span v-if="price.adv" class="product-card__price--adv">{{ price.adv }}</span>
              </a>
            </p>
            <div v-show="shouldDisplaySwatches" class="product-card__swatches mb-0" qaid="plpListViewItemSwatches">
              <pdl-swatch-group
                :options="swatches"
                :radio-name="`swatches-${product.code}`"
                :is-small="true"
                :initial-selected-option="swatches[0]"
                @color-changed="changeImage"
              />
            </div>
          </div>
        </pdl-media-object>
      </template>

      <pdl-callout
        v-if="showCallout && (!product.productGroups || !product.isReady)"
        kind="warning"
        qaid="sku-grid-no-data-callout"
      >
        <!-- Ideally, this callout is never seen, if data loads promptly -->
        <template #content>
          <span v-html="bp('productError')" />
        </template>
      </pdl-callout>

      <pdl-loading :is-loading="isLoading">
        <div v-if="product.productGroups && product.isReady" data-plp-grid>
          <div
            v-for="(productGroup, productGroupIndex) in product.productGroups"
            :key="productGroupIndex"
            class="b2b-grid-group__section pl-4"
            :product-group-index="productGroupIndex"
          >
            <pdl-collapse
              :icon-active="collapseOptions.iconActive"
              :icon-inactive="collapseOptions.iconInactive"
              :icon-position="collapseOptions.iconPosition"
              align-icon="center"
              :default-active="false"
              :value="productGroupIndex"
            >
              <bulk-product-grid
                :hide-unavailable="hideUnavailable"
                :image-size="{width: 192, height: 144}"
                :is-plp="true"
                :base-product="product.code"
                :product-group-index="productGroupIndex"
                :product-type="productGroup.productType"
                :product-group="productGroup"
                :is-custom-product="isCustomProduct"
                :products="products"
                @add-to-cart="handleAddToCart"
                @update-stock-watch-state="updateStocks"
                @update-cart-or-list-column="updateColumns"
              />
            </pdl-collapse>
          </div>
        </div>
      </pdl-loading>
    </pdl-collapse-item>
  </pdl-collapse>
</template>

<script>
import productCard from './mixins/product-card';
import BulkProductGrid from '@/components/containers/bulk-product/BulkProductGrid';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {mapMutations, mapActions, mapState} from 'vuex';
import get from 'lodash/get';
import {updateStockWatchStatus} from '@/components/containers/bulk-product/utils/warehouses';
import {PdlMediaObject} from '@pedal/pdl-media-object';
import {PdlCallout} from '@pedal/pdl-callout';

export default {
  name: 'ProductListViewItem',
  components: {
    PdlLoading,
    BulkProductGrid,
    PdlCollapse,
    PdlCollapseItem,
    PdlMediaObject,
    PdlCallout,
  },
  mixins: [productCard],

  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      collapseOptions: {
        iconActive: 'indeterminate_check_box',
        iconInactive: 'add_box',
        iconPosition: 'left',
      },
      hideUnavailable: false,
      showCallout: false,
    };
  },

  computed: {
    ...mapState('plp', ['collapseState', 'isColorSwatchAllowed']),
    ...mapState('skuGrid', ['baseProductsSkus']),
    ...mapState('productBundle', ['bundleTemplateIds']),
    ...mapState('viewSize', ['viewBreakpoint']),

    isActive() {
      return get(this.collapseState, this.product.code, false);
    },

    isLoading() {
      return (
        get(this.collapseState, this.product.code) === true && (!this.product.productGroups || !this.product.isReady)
      );
    },

    resizedImageUrl() {
      return this.currentImageUrl.replace('1360', '192').replace('1020', '144');
    },

    shouldDisplaySwatches() {
      return this.swatches.length > 0 && this.isColorSwatchAllowed;
    },
    isCustomProduct() {
      // For spectrum enabled products, used to hide apple labels in BulkProductGridTabs.vue
      return !!(this.product.spectrumUrl && this.product.customProductStartingPoint);
    },
  },

  methods: {
    displayErrorCallout() {
      setTimeout(() => {
        // Only display callout if data has not returned after reasonable wait
        if (this.isLoading) {
          this.showCallout = true;
        } else {
          this.showCallout = false;
        }
      }, 10000);
    },

    updateStocks(payload) {
      if (payload && payload.item) {
        updateStockWatchStatus(this.product.productGroups, payload.item);
      }
    },

    stateChanged(isActive) {
      this.$emit('state-changed', isActive);
      this.displayErrorCallout();
    },

    handleAddToCart() {
      let bundleIds = [];

      //   Get the respective bundletemplateId
      this.baseProductsSkus.forEach((code) => {
        let id = this.bundleTemplateIds.find((id) => id.includes(code));
        id && bundleIds.push(id);
        this.setActiveIds(bundleIds);
      });

      if (bundleIds.length > 0) this.setBundleDialogVisibility(true);
    },

    updateColumns(resData) {
      const responseEntries = resData.response;
      const colName = resData.colName;
      this.product.productGroups.forEach((productGroup) => {
        const products = productGroup.products;
        this.$store.dispatch('skuGrid/updateCartOrListColumn', {products, responseEntries, colName}).then((res) => {
          productGroup.products = res;
        });
      });
    },

    ...mapMutations('plp', ['setBundleDialogVisibility']),
    ...mapActions('skuGrid', ['updateCartOrListColumn']),
    ...mapMutations('skuGrid', ['updateCollapseState', 'clearBaseProduct']),
    ...mapMutations('productBundle', ['setActiveIds']),
  },
};
</script>

<style lang="scss">
.product-list-view-item {
  .pdl-media-object__media {
    @apply w-32;
  }
}

.b2b-grid-group__section:last-child {
  .pdl-collapse-item__header,
  .pdl-collapse-item__wrap {
    border-bottom-color: transparent;
  }
}
</style>
