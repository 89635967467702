<template>
  <div id="zoomed-gallery" class="pdp-product-image-zoom">
    <swiper-container id="pdpImageZoom" ref="sliderZoom" init="false">
      <swiper-slide v-for="(item, index) in items" :key="`${item.data.assetId}-${index}`" lazy="true">
        <img
          :srcset="getSrcSet(item.data.assetId, {c: 'pad'})"
          class="swiper-lazy"
          :alt="item.data.assetId"
          loading="lazy"
        />
        <div v-if="item.data.isAccessorized" class="accessories-disclaimer">
          <span v-if="productType === 'bike'">{{ $t('product.detail.image.accessorized.caption') }}</span>
          <span v-else>{{ $t('product.detail.image.accessorizedAftermarket.caption') }}</span>
        </div>
      </swiper-slide>
    </swiper-container>
    <pdl-button
      text
      icon-only
      large
      icon="close"
      class="pdp-product-image-zoom__close-button"
      :aria-label="$t('popup.close')"
      @click="close"
    />
  </div>
</template>
<script>
import {ProductImages} from '@/components/containers/pdp/utils/product-images';
import {PdlButton} from '@pedal/pdl-button';
import {register} from 'swiper/element/bundle';
import {PdpImageZoomSwiperStyles, PdpImageCommonSwiperStyles} from '@/constants/pdp-panel-and-zoom.js';

// register Swiper custom elements
register();

export default {
  name: 'PdpProductImageZoom',
  components: {
    PdlButton,
  },
  props: {
    productType: {
      type: String,
      default: '',
    },
    images: {
      type: Array,
      default: () => [],
    },
    currentImageIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentSlide: 0,
    };
  },
  computed: {
    items() {
      return this.images.filter((item) => item.type !== 'video');
    },
    swiper() {
      return this.$refs.sliderZoom.swiper;
    },
  },
  watch: {
    currentSlide(value) {
      this.swiper.slideTo(value);
    },
  },
  mounted() {
    this.startListeningKeyboard();
    this.currentSlide = this.currentImageIndex;
    this.initialiseSwiper();
  },
  beforeDestroy() {
    this.stopListeningKeyboard();
  },
  methods: {
    getSrcSet: (assetId, options = {}) => ProductImages.getSrcSet(assetId, options),
    startListeningKeyboard() {
      this.stopListeningKeyboard();
      document.addEventListener('keydown', this.keyPressed);
    },
    stopListeningKeyboard() {
      document.removeEventListener('keydown', this.keyPressed);
    },
    keyPressed(ev) {
      let nextItemIndex = this.currentSlide;
      if (ev.keyCode === 37) {
        nextItemIndex--;
      } else if (ev.keyCode === 39) {
        nextItemIndex++;
      }

      if (nextItemIndex < 0) {
        nextItemIndex = this.items.length - 1;
      } else if (nextItemIndex >= this.items.length) {
        nextItemIndex = 0;
      }

      this.currentSlide = nextItemIndex;
    },
    close() {
      this.$emit('close');
    },
    initialiseSwiper() {
      const swiperEl = document.querySelector('swiper-container#pdpImageZoom');
      if (!swiperEl) return;
      const params = {
        pagination: {
          clickable: true,
        },
        navigation: true,
        injectStyles: [...PdpImageCommonSwiperStyles, ...PdpImageZoomSwiperStyles],
      };

      Object.assign(swiperEl, params);

      swiperEl.initialize();
    },
  },
};
</script>
<style lang="scss" scoped>
.pdp-product-image-zoom {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  swiper-slide {
    display: block;
    position: relative;
    width: 100vw;
    height: 100vh;

    img {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      padding-left: 10vw;
      padding-right: 10vw;
    }
  }
}

.pdp-product-image-zoom__close-button {
  position: absolute;
  z-index: 2;
  top: 1.5rem;
  right: 1.5rem;
  color: var(--gray-20);
}
</style>
