<!-- B2C My Account Integrations Page -->
<script>
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
import MapboxAddressSuggestionsInput from '../../mapbox/components/MapboxAddressSuggestionsInput.vue';
import FindRetailerDialog from '@/components/containers/my-account/FindRetailerDialog.vue';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  /* Imported Pedal components give accountIntegrationsLayoutPage.jsp access to Pedal components, but es-lint does 
  not recognize the components are used and errors. The comment below prevents the es-lint error for this file */
  /* eslint-disable vue/no-unused-components */
  components: {
    PdlDialog,
    PdlButton,
    PdlLink,
    MapboxAddressSuggestionsInput,
    FindRetailerDialog,
    PdlIcon,
  },
  data() {
    return {
      eulaDialogVisible: false,
      hasFindRetailerDialogVisible: false,
    };
  },

  methods: {
    openEulaDialog() {
      this.eulaDialogVisible = true;
    },
    openFindRetailerDialog() {
      this.hasFindRetailerDialogVisible = true;
    },
    closeFindRetailerDialog() {
      this.hasFindRetailerDialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
//used within pdl-dialog in accountIntegrationsLayoutPage.jsp
.b2b-scroll-list {
  max-height: 50vh;
}
</style>
