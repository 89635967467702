<template>
  <ul class="absolute bg-white w-full border-solid border-gray-20 border border-t-0 divide-y divide-gray-20">
    <address-autofill-list-item
      v-for="(suggestion, index) in suggestionResults"
      :key="`autofill-suggestion-${index}`"
      :suggestion="suggestion"
    />
  </ul>
</template>
<script>
import AddressAutofillListItem from '@/components/checkout/fields/address-autofill/AddressAutofillListItem';
export default {
  components: {AddressAutofillListItem},
  props: {
    suggestionResults: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
