function registerClearForm(alertContainer) {
  $('[data-clear-form]').click(function () {
    alertContainer.empty();

    $('#powf_EEFA8C8C08D3E41180CE00155D477A56')
      .children()
      .children()
      .children('li')
      .removeClass('form__item--error alert-icon-after');
  });
}

function registerSubmitButton(handlerFunc) {
  $('#SubmitContactUsBtn').on('click', function (e) {
    e.preventDefault();
    handlerFunc();
  });
}

function initializeValidation(alertContainer, contactUsMessages) {
  // jQuery validate block
  // To reference this documentation go to:
  // https://jqueryvalidation.org/
  $('#ContactUsForm').validate({
    // Set the rules that we want to test
    rules: {
      // First Name
      firstName: {
        required: true,
      },
      // Last Name
      lastName: {
        required: true,
      },
      // Zip Code
      postalCode: {
        required: true,
        zipCode: true,
      },
      // Email
      email: {
        required: true,
        email: true,
      },
      // Topic
      topic: {
        required: true,
      },
      // Questions
      messageText: {
        required: true,
      },
      // reCAPTCHA
      captcha: {
        required: true,
      },
      // Honeypot
      hp_input: {
        required: true,
      },
    },

    invalidHandler: function () {
      $('html, body').animate({
        scrollTop: $('.title_holder').offset().top - 150,
      });
    },

    messages: contactUsMessages,

    errorPlacement: function (error, element) {
      // Since the .jsp has two different areas for the alerts to show,
      // we need to make sure that if the confirmation page is active we
      // only submit the alerts to the globalMessages container
      if ($('#globalMessages .alert').length) {
        alertContainer = $('#globalMessages .alert');
      } else {
        alertContainer = $('#ContactUsErrors.alert');
      }

      if (error[0] && error[0].innerHTML !== '') {
        // show the alert dialog its hidden by default
        alertContainer.removeClass('hide');

        // add the error styles to the form field
        $(element).parent().parent().addClass('form__item--error alert-icon-after');

        var errorLabel = alertContainer.find('#' + error[0].id);

        if (!errorLabel || errorLabel.length === 0) {
          // place the error message in the alert div.
          error.appendTo(alertContainer);
        }
      } else {
        $(element).parent().parent().removeClass('form__item--error').removeClass('alert-icon-after');
      }
    },

    onfocusout: false,
    onkeyup: false,
    onclick: false,
    success: function (label) {
      if ($('#globalMessages .alert').length) {
        alertContainer = $('#globalMessages .alert');
      } else {
        alertContainer = $('#ContactUsErrors.alert');
      }

      alertContainer.find('#' + label[0].id).remove();

      ACC.track.pushToDataLayer({
        event: 'contact-us',
        Action: 'Contact Us Submitted',
        Category: 'Contact Us',
      });
    },
  });
}

function submitForm() {
  if ($('#ContactUsForm').valid() === true) {
    $('#ContactUsForm').trigger('submit');
  }
}

function createTokenError(captchaMessage) {
  $.validator.messages.captcha = captchaMessage;
}

async function submitFormWithToken(tokenData, errorMessage) {
  tokenData.isTokenValid = true;
  try {
    const token = await tokenData.createToken();
    $('#reCaptchaToken').val(token);
    submitForm();
  } catch (error) {
    createTokenError(errorMessage);
  }
}

function registerPrivacyPolicyHandler(checkboxElement) {
  const privacyPolicyChangeHandler = (e) => {
    if (e.target.checked) {
      $('#SubmitContactUsBtn').removeClass('disabled').attr('disabled', false);
    } else {
      $('#SubmitContactUsBtn').addClass('disabled').attr('disabled', true);
    }
  };

  $('#SubmitContactUsBtn').addClass('disabled');
  $('#SubmitContactUsBtn').attr('disabled', true);

  checkboxElement.addEventListener('change', privacyPolicyChangeHandler);
}

function addLocaleFieldValidation(locale) {
  const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
  const phoneNumberRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z1-9.-]+\.[A-Za-z]{2,63}/;
  $.validator.addMethod('email', function (value, element) {
    return this.optional(element) || emailRegex.test(value);
  });

  if (locale !== 'en_US') {
    $.validator.addMethod('zipCode', function () {
      return true;
    });

    $.validator.addMethod('phoneNumber', function () {
      return true;
    });
    return;
  }

  $.validator.addMethod('zipCode', function (value, element) {
    return this.optional(element) || zipCodeRegex.test(value);
  });

  $.validator.addMethod('phoneNumber', function (value, element) {
    return this.optional(element) || phoneNumberRegex.test(value);
  });
}

function addHoneypotValidation() {
  $.validator.addMethod('hp_input', function (value) {
    return value === '' || value === null;
  });
}

class ContactUsForm {
  constructor({tokenData, locale, contactUsMessages}) {
    this.alertContainer = undefined;
    this.locale = locale;
    this.contactUsMessages = contactUsMessages;
    this.tokenData = tokenData;
    this.init();
  }
  init() {
    initializeValidation(this.alertContainer, this.contactUsMessages);
    addLocaleFieldValidation(this.locale);
    addHoneypotValidation();

    registerClearForm(this.alertContainer);

    const PRIVACY_POLICY_ID = 'privacyPolicyAccept';
    const privacyPolicyAcceptCheckbox = document.getElementById(PRIVACY_POLICY_ID);
    if (privacyPolicyAcceptCheckbox) registerPrivacyPolicyHandler(privacyPolicyAcceptCheckbox);

    const submitHandler = () => {
      this.tokenData?.createToken
        ? submitFormWithToken(this.tokenData, this.contactUsMessages.invalidCode)
        : submitForm();
    };
    registerSubmitButton(submitHandler);
  }
}

export default ContactUsForm;
