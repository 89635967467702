<template>
  <pdl-loading :is-loading="isLoading">
    <div class="mb-3">
      <label class="form-label inline" :for="cardNumberId">{{ $t('payment.cardNumber') }}</label>
      <span class="text-red ml-25">
        *
        <span class="show-for-sr">(required)</span>
      </span>
      <div :class="{'has-error': !isCardNumberValid}">
        <input :id="cardNumberId" />
      </div>
      <span v-if="!isCardNumberValid" class="text-red" qaid="invalid-card-number-error">
        {{ $t('payment.cardNumber.invalid') }}
      </span>
    </div>
    <div class="mb-3 flex gap-x-4">
      <div>
        <label class="form-label inline" :for="expiryDateId">{{ $t('checkout.expiration.date') }}</label>
        <span class="text-red ml-25">
          *
          <span class="show-for-sr">(required)</span>
        </span>
        <div :class="{'has-error': !isExpiryDateValid}">
          <input :id="expiryDateId" />
        </div>
        <span v-if="!isExpiryDateValid" class="text-red" qaid="invalid-expiry-date-error">
          {{ $t('payment.expiryDate.invalid') }}
        </span>
      </div>
      <div>
        <label class="form-label inline" :for="securityCodeId">{{ $t('payment.cvvNumber') }}</label>
        <span class="text-red ml-25">
          *
          <span class="show-for-sr">(required)</span>
        </span>
        <div :class="{'has-error': !isSecurityCodeValid}">
          <input :id="securityCodeId" />
        </div>
        <span v-if="!isSecurityCodeValid" class="text-red" qaid="invalid-security-code-error">
          {{ $t('payment.cvv.invalid') }}
        </span>
        <em class="form__help text-small-emphasized cvv_code--msg mt-1">
          <span>{{ $t('checkout.payment.card.back.number') }}</span>
        </em>
      </div>
    </div>
    <form-group
      v-if="userEmail"
      type="checkbox"
      :multi-items="shouldSaveBillingInfoCheckbox"
      :label-classes="'text-sm'"
      name="shouldSaveBillingInfo"
      show-label
      :required="false"
      @change="handleCheckboxChange"
    />
  </pdl-loading>
</template>

<script>
import headTag from '@/utils/head-tag.js';
import {mapActions, mapMutations, mapState} from 'vuex';
import {PdlLoading} from '@pedal/pdl-loading';
import FormGroup from '@/components/FormGroup.vue';

const getIsFieldValid = (field) => {
  if (!field || field.valid === null) return true;
  if (!field.dirty) return true;
  return field.valid;
};

export default {
  components: {
    PdlLoading,
    FormGroup,
  },
  data() {
    return {
      isLoading: false,
      cardNumberId: 'card-number',
      securityCodeId: 'security-code',
      expiryDateId: 'expiry-date',
    };
  },
  computed: {
    ...mapState('checkout', ['paymentData', 'gr4vyFormValidations', 'gr4vyGlobalError']),
    ...mapState('user', ['userEmail']),
    ...mapState('backend', ['isProduction']),
    isCardNumberValid() {
      return getIsFieldValid(this.gr4vyFormValidations?.number);
    },
    isSecurityCodeValid() {
      return getIsFieldValid(this.gr4vyFormValidations?.securityCode);
    },
    isExpiryDateValid() {
      return getIsFieldValid(this.gr4vyFormValidations?.expiryDate);
    },
    shouldSaveBillingInfo: {
      get() {
        return this.paymentData.shouldSaveBillingInfo;
      },
      set(shouldSaveBillingInfo) {
        this.setShouldSaveBillingInfo(shouldSaveBillingInfo);
      },
    },
    shouldSaveBillingInfoCheckbox() {
      return [
        {
          name: 'shouldSaveBillingInfo',
          label: 'should-save-billing-info-checkbox',
          value: 'shouldSaveBillingInfo',
          displayName: this.$t('checkout.multi.paymentMethod.addPaymentDetails.savePaymentDetailsInAccount'),
          checked: this.shouldSaveBillingInfo,
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;

    const GR4VY_CDN_SCRIPT_URL = this.isProduction
      ? 'https://cdn.trek.gr4vy.app/secure-fields/latest/secure-fields.js'
      : 'https://cdn.sandbox.trek.gr4vy.app/secure-fields/latest/secure-fields.js';
    const GR4VY_CDN_STYLES_URL = this.isProduction
      ? 'https://cdn.trek.gr4vy.app/secure-fields/latest/secure-fields.css'
      : 'https://cdn.sandbox.trek.gr4vy.app/secure-fields/latest/secure-fields.css';

    await headTag.appendAsyncScript('secure-fields', GR4VY_CDN_SCRIPT_URL);
    headTag.appendLink('secure-fields-styles', GR4VY_CDN_STYLES_URL, 'stylesheet');

    const gr4vyFields = await this.createGr4vyFields({
      SecureFieldsModule: window.SecureFields,
    });

    const handleFieldChange = ({id, valid}) => {
      this.setIsGr4vyFieldValid({id, valid, dirty: true});
    };

    await gr4vyFields
      .addCardNumberField(`#${this.cardNumberId}`)
      .addSecurityCodeField(`#${this.securityCodeId}`)
      .addExpiryDateField(`#${this.expiryDateId}`)
      .build({
        handleCardNumberChange: (data) => {
          handleFieldChange(data);
          if (data.schema) this.setCardSchema(data.schema);
        },
        handleSecurityCodeChange: handleFieldChange,
        handleExpiryDateChange: handleFieldChange,
      });

    gr4vyFields.getFields().forEach((id) => {
      this.setIsGr4vyFieldValid({id, valid: null, dirty: false});
    });
    this.isLoading = false;
  },
  methods: {
    ...mapMutations('checkout', ['setIsGr4vyFieldValid', 'setCardSchema', 'setShouldSaveBillingInfo']),
    ...mapActions('checkout', ['createGr4vyFields']),
    handleCheckboxChange(e) {
      this.shouldSaveBillingInfo = e.target.checked;
    },
  },
};
</script>

<style scoped lang="scss">
/* Applied to each field */
.secure-fields__input {
  display: block;
  position: relative;
  width: 100%;
  min-width: 0;
  height: calc(2.25rem + 2px);
  margin: 0;
  padding: 0;
  background-color: var(--white);
  border: 1px solid var(--gray-20);
  border-radius: 2px;
  box-shadow: none;
  color: var(--gray-100);
  font-size: 1rem;
  line-height: 1.25;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  .has-error & {
    border-color: var(--red-100);
  }
}

.secure-fields__input[data-secure-fields-invalid] {
  border-color: var(--red-100);
}
</style>
