'use strict';

export default function () {
  $('.storeNewsletterSignupButton').on('click', function (event) {
    event.preventDefault();

    var storeNewsletterEmail = $(event.target).parent().find('.storeNewsletterSignupText').val();
    var storeNewsletterSignupUrl = $(event.target).data('email-signup-url');
    var storeNewsletterEmailListID = $(event.target).data('email-list-id');
    var updatesFromTrekBikes = $('#trekbikesUpdates').is(':checked');

    $.ajax({
      url: storeNewsletterSignupUrl,
      data: {
        email: storeNewsletterEmail,
        emailListId: storeNewsletterEmailListID,
        sendUpdatesFromTrekBikes: updatesFromTrekBikes,
      },
      type: 'GET',
      dataType: 'json',
      error: function () {
        //Get it from the component...
        $('.newsletterSignupError').text($('.newsletter-signup__error_prop_subtitle').val());
        $('.newsletter-signup__wrap').addClass('error');
      },
      success: function (data) {
        if (data.STATUS === 'OK') {
          $('.newsletter-signup__header').text(data.TITLE);
          $('.newsletter-signup__subtext').text(data.SUBTITLE);
          $('.newsletter-signup__form-error .newsletterSignupError').html('');
          $('.newsletter-signup__message').text(data.EMAIL);
          $('#trekbikesUpdates').prop('checked', true);
          $('.newsletter-signup__wrap').removeClass('error');
          $('.newsletter-signup__wrap').addClass('success');
          $('.storeNewsletterSignupText').val('');
          $('.newsletter-signup__form-container').hide();
          $('.newsletter-signup__form-checkbox').hide();
          $('.newsletter-signup__resubmit-container').show();
          $('.newsletter-signup__consent_message').hide();
        } else if (data.STATUS === 'INVALID') {
          $('.newsletterSignupError').html(data.SUBTITLE);
          $('.newsletter-signup__wrap').addClass('error');
        } else if (data.STATUS === 'ERROR') {
          $('.newsletterSignupError').text($('.newsletter-signup__error_prop_subtitle').val());
          $('.newsletter-signup__wrap').addClass('error');
        }
      },
    });
  });

  $('.newsletter-signup__resubmit-button').on('click', function () {
    $('.newsletter-signup__form-container').show();
    $('.newsletter-signup__form-checkbox').show();
    $('#storeNewsletterSignupLabel').show();
    $('.newsletter-signup__header').text($('.newsletter-signup__header_prop_title').val());
    $('.newsletter-signup__subtext').text($('.newsletter-signup__subtext_prop_subtitle').val());
    $('.newsletter-signup__message').text('');
    $('.newsletter-signup__resubmit-container').hide();
    $('.newsletterSignupError').html('');
    $('.newsletter-signup__consent_message').show();
  });

  $('.storeNewsletterSignupText').on('focus', function () {
    $(this).parent().addClass('has-focus');
    $('.newsletter-signup__wrap').removeClass('error');
    $('.newsletterSignupError').html('');
    $('#storeNewsletterSignupLabel').hide();
  });
  $('.storeNewsletterSignupText').on('focusout', function () {
    if ($(this).val().length === 0) {
      $(this).parent().removeClass('has-focus');
      $('#storeNewsletterSignupLabel').show();
    }
  });

  $('.newsletter-signup__checkbox-label').click(function () {
    $('.newsletter-signup__trek-check').click();
  });
}
