<template>
  <div v-cloak class="block-list">
    <div class="block-list__item w-full" qaid="order-summary.subtotal">
      <span>{{ $t('text.order.subtotal') }}</span>
      <span>{{ orderData.prices.subtotal.formattedValue }}</span>
    </div>
    <div class="block-list__item w-full" qaid="order-summary.estimatedShipping">
      <span>{{ $t('text.estimatedShipping') }}</span>
      <span>{{ orderData.prices.deliveryCost.formattedValue }}</span>
    </div>
    <div class="block-list__item w-full" qaid="order-summary.estimatedShipping">
      <span>{{ $t('text.shippingDeductions') }}</span>
      <span>{{ orderData.prices.freightDeduction.formattedValue }}</span>
    </div>
    <div class="block-list__item flex justify-between text-lg py-2" qaid="order-summary.cartTotal">
      <span class="font-medium">{{ $t('text.orderTotal') }}</span>
      <span class="font-medium">{{ orderData.prices.totalPrice.formattedValue }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderData: {
      type: Object,
      required: true,
      default: () => null,
    },
  },
};
</script>
