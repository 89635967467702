<script>
import pagination from '../../mixins/pagination';
import {mapState} from 'vuex';
export default {
  mixins: [pagination],
  inject: ['container'],
  provide() {
    return {
      headers: this.headers,
    };
  },
  props: {
    size: {
      type: Number,
      required: false,
      default: ACC.allocatedItemsPageSize || 50,
    },
    items: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    status: {
      type: String,
      default: '',
    },
    subtotal: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      listData: this.items,
    };
  },
  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode']),
  },
  methods: {
    getHeaders() {
      return this.headers.filter((header) => {
        return !(this.isConsumerFriendlyMode && (header.key === 'unitPrice' || header.key === 'lineSubtotal'));
      });
    },
    generateItemKey(item) {
      return `${item.sku}-${Math.random()}`;
    },
  },
};
</script>
