<template>
  <div v-if="hasWarehouses(warehouses)">
    <table qaid="grid-warehouse" class="b2b-buying-zone__availability-table my-2">
      <caption class="sr-only">
        {{
          $t('text.warehouse')
        }}
      </caption>
      <thead>
        <tr>
          <th v-for="(warehouse, index) in warehouses" :key="index">{{ warehouse.id }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!isBaseProduct">
          <template v-if="btoLeadTime">
            <td colspan="3">
              <div class="b2b-grid__warehouse">
                <span qaid="warehouses.btoLeadTime" v-html="btoLeadTime"></span>
              </div>
            </td>
          </template>
          <template v-else-if="newETAProductMsg">
            <td colspan="3">
              <div class="text-red font-medium">
                <span qaid="warehouses__new-ETA-product-msg" v-html="newETAProductMsg"></span>
              </div>
            </td>
          </template>
          <template v-else>
            <td v-for="(warehouse, index) in warehouses" :key="index">
              <div class="b2b-grid__warehouse">
                <span
                  class="font-bold"
                  :data-level="getWarehouseLevel(warehouse)"
                  qaid="grid-warehouse.value"
                  v-html="warehouse.stockLevel"
                ></span>
                <span qaid="grid-warehouse.label" v-html="getETALabel(warehouse)"></span>
              </div>
            </td>
          </template>
        </tr>
        <tr v-else>
          <td :colspan="warehouses.length" class="text-gray-30 text-base">
            {{ $t('text.buyingZone.pleaseSelectASku') }}
          </td>
        </tr>
      </tbody>
    </table>

    <table qaid="allocated-backorder-grid" class="b2b-buying-zone__availability-table my-2">
      <thead>
        <tr>
          <th id="grid-header-backordered">{{ $t('text.backordered') }}</th>
          <th id="grid-header-allocated">{{ $t('text.allocated') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-if="quantityBackorderedDI == 0">{{ null }}</td>
          <td v-else>
            <pdl-link qaid="pdp-product-warehouse-backordered-link" standalone medium :href="backorderAllocatedUrl"
              >{{ quantityBackorderedDI }}
            </pdl-link>
          </td>

          <td>{{ quantityAllocatedDI == 0 ? null : quantityAllocatedDI }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {mapState} from 'vuex';
import {PdlLink} from '@pedal/pdl-link';
import warehouseMixin from '@/mixins/warehouse';

export default {
  name: 'PdpProductWarehouses',
  components: {PdlLink},
  mixins: [warehouseMixin],

  props: {
    value: {
      type: String,
      required: true,
    },
    productType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      warehouses: [],
      lastProductId: null,
      isBaseProduct: true,
      leadTime: null,
      newProductETAMonth: null,
      newProductETAYear: null,
      quantityAllocatedDI: null,
      quantityBackorderedDI: null,
      isBTOPurchasableB2B: null,
    };
  },

  computed: {
    btoLeadTime() {
      let btoLeadTime = this.$t('skuGrid.B2B.leadTime');

      if (this.leadTime && this.isBTOPurchasableB2B && btoLeadTime && btoLeadTime.length) {
        return btoLeadTime.replace('{0}', this.leadTime);
      }

      return false;
    },

    newETAProductMsg() {
      let newProductETAMonthTranslation = this.$t(`${this.newProductETAMonth}`);
      let newProductEtaMsg = this.$t('skuGrid.B2B.newProductETA');
      if (newProductEtaMsg && this.newProductETAYear && this.newProductETAMonth && newProductETAMonthTranslation) {
        let newProductEtaDate = newProductETAMonthTranslation + ' ' + this.newProductETAYear;
        return newProductEtaMsg.replace('{0}', newProductEtaDate);
      }
      return false;
    },

    backorderAllocatedUrl() {
      const redirectPath = `${this.encodedContextPath}/my-account/backorders-allocated-items?productType=${this.productType}&skuSearchTerm=${this.pdpSku}`;
      return redirectPath;
    },

    ...mapState('pdp', ['pdpSku']),
    ...mapState('backend', ['isB2BPurchasable', 'encodedContextPath']),
  },

  watch: {
    pdpSku: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (this.lastProductId !== this.pdpSku && this.pdpSku) {
            this.retrieveWarehouses(this.pdpSku);
          }
        });
      },
    },
  },

  methods: {
    retrieveWarehouses(productId) {
      this.lastProductId = productId;
      storefrontInstance.get('/p/availability/' + productId + '/').then(({data}) => {
        if (data) {
          this.warehouses = data.warehouses;
          this.isBaseProduct = data.isBaseProduct;
          this.leadTime = data.btoLeadTime;
          this.newProductETAMonth = data.newProductETAMonth;
          this.newProductETAYear = data.newProductETAYear;
          this.quantityAllocatedDI = data.quantityAllocatedDI;
          this.quantityBackorderedDI = data.quantityBackorderedDI;
          this.$emit('availability-change', data.isPurchasableB2B);
          this.$emit('is-base-product', this.isBaseProduct);
          this.isBTOPurchasableB2B = data.isPurchasableB2B;
        }
      });
    },

    getETALabel(warehouse) {
      if (warehouse.futureDate) {
        return warehouse.futureDate;
      } else if (warehouse.soldOut) {
        return this.$t('text.buyingZone.soldOut');
      }

      return this.$t('text.buyingZone.noEta');
    },

    getWarehouseLevel(warehouse) {
      const defaultLevel = 'zero';

      if (warehouse && warehouse.stockLevelLabel) {
        return warehouse.stockLevelLabel;
      }

      return defaultLevel;
    },
  },
};
</script>
