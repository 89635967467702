<template>
  <div class="mt-3">
    <table class="b2b-grid b2b-grid--collapse is-compact" qaid="order-details-sku-grid">
      <caption class="sr-only">
        {{
          $t('text.backorderedItems')
        }}
      </caption>
      <thead class="b2b-grid__header">
        <th
          v-for="header in getColumns(sectionKey)"
          :key="header.label"
          class="b2b-grid__cell b2b-grid__head"
          :class="[{'whitespace-nowrap': header.key !== 'etaDate'}, header.cssClasses]"
          :data-type="header.key === 'etaDate' ? 'eta-date' : false"
        >
          {{ header.label }}
        </th>
      </thead>

      <tbody>
        <template v-for="(item, itemIndex) in paginatedData">
          <account-order-details-backordered-item :key="itemIndex" :headers="getColumns(sectionKey)" :item="item" />
        </template>
      </tbody>
    </table>

    <div class="grid-x grid-margin-x mb-5">
      <div class="cell medium-6 large-8">
        <pagination-controls
          v-if="pageCount > 1"
          ref="pagination"
          :page-number="pageNumber"
          :page-count="pageCount"
          @page-change="pageChange"
          @prev-page="prevPage"
          @next-page="nextPage"
        />
      </div>
      <div class="cell medium-6 large-4">
        <div v-if="!isConsumerFriendlyMode" v-cloak class="block-list text-base">
          <div class="block-list__item w-full" qaid="backordered-section-subtotal">
            <span>{{ $t('text.order.subtotal') }}</span>
            <span>{{ subtotal }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pagination from '../../mixins/pagination';
import {mapState} from 'vuex';

export default {
  mixins: [pagination],

  inject: [],
  props: {
    orderData: {
      type: Object,
      default: () => null,
    },
    size: {
      type: Number,
      required: false,
      default: ACC.allocatedItemsPageSize || 50,
    },
    warehouse: {
      type: Object,
      default: () => null,
    },
    shipmentInfo: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
    sectionKey: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      listData: this.items,
      pageSize: this.size,
      addPrice: String,
    };
  },
  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode']),
    subtotal() {
      return this.orderData ? this.orderData.subtotals[this.sectionKey]?.prices?.subtotal?.formattedValue : '';
    },
  },

  methods: {
    getColumns() {
      // The "Backordered" shouldn't have the Allocated or Cancel Date columns
      return this.headers.filter(this.filterColumns);
    },
    filterColumns(column) {
      if (this.sectionKey === 'notAllocatedItems') {
        if (column.key === 'quantityAllocatedOrShipped' || column.key === 'cancelDate') return false;
      }
      if (this.sectionKey === 'allocatedItems') {
        if (column.key === 'etaDate') return false;
      }
      return !(this.isConsumerFriendlyMode && (column.key === 'unitPrice' || column.key == 'lineSubtotal'));
    },
  },
};
</script>
