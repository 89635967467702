<template>
  <div class="grid-x grid-margin-x">
    <div class="cell medium-6 large-4">
      <slot name="side-content"></slot>
    </div>

    <div class="cell medium-6 large-4 large-offset-4">
      <pdl-collapse
        v-if="!isConsumerFriendlyMode"
        icon-active="indeterminate_check_box"
        icon-inactive="add_box"
        icon-position="left"
        align-icon="start"
        icon-classes="md-18"
        qaid="cart-totals-collapse"
        :default-active="false"
        :value="activeTabs"
        @change="setActiveTabs"
      >
        <template v-for="productType in productTypes">
          <pdl-collapse-item v-if="hasProductSubtotal(productType)" :key="productType" :name="allTabs[productType]">
            <template #title>
              <cart-totals-product-title
                :subtotal="visibleTotals.subtotals[productType]"
                :product-type="productType"
                :validation-rules="validationRulesByType(productType)"
              >
                <template #subtitle="subtitleProps">
                  <slot name="subtitle" :product-type="subtitleProps.productType" />
                </template>
              </cart-totals-product-title>
            </template>

            <cart-totals-product-subtotal
              :product-type="productType"
              :subtotal="visibleTotals.subtotals[productType]"
              :show-delivery-cost="!!freightTotals"
              :checkout-step="checkoutStep"
              @calculate-freight-totals="$emit('calculate-freight-totals')"
            />
          </pdl-collapse-item>
        </template>
      </pdl-collapse>

      <div v-if="!isConsumerFriendlyMode" class="flex justify-between text-lg py-2" qaid="cart-totals-total-price">
        <span class="font-medium">{{ $t('myCart.B2B.cartTotal') }}</span>
        <span>{{ totalPrice }}</span>
      </div>

      <div class="buttons mt-2">
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import CartTotalsProductTitle from '@/components/cart/totals/CartTotalsProductTitle';
import CartTotalsProductSubtotal from '@/components/cart/totals/CartTotalsProductSubtotal';
import {sortList} from '@/utils/entries-helpers';
import {ProductTypeOrder} from '@/constants/product';

export default {
  name: 'CartTotals',
  components: {
    PdlCollapse,
    PdlCollapseItem,
    CartTotalsProductTitle,
    CartTotalsProductSubtotal,
  },
  props: {
    totals: {
      type: Object,
      required: true,
      validator(value) {
        return ['prices', 'subtotals'].every((prop) => Object.hasOwn(value, prop));
      },
    },
    freightTotals: {
      type: Object,
      default: null,
      validator(value) {
        return value ? ['prices', 'subtotals'].every((prop) => Object.hasOwn(value, prop)) : true;
      },
    },
    checkoutStep: {
      type: String,
      default: '',
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allTabs: {},
      activeTabs: [],
    };
  },
  computed: {
    visibleTotals() {
      return this.freightTotals ?? this.totals;
    },
    productTypes() {
      return sortList(Object.keys(this.visibleTotals?.subtotals ?? {}), [ProductTypeOrder, (entry) => entry]);
    },
    totalPrice() {
      return this.visibleTotals?.prices?.totalPrice.formattedValue;
    },
    ...mapState('backend', ['isConsumerFriendlyMode']),
  },
  mounted() {
    this.allTabs = this.buildTabs(this.productTypes);

    if (this.isExpanded) {
      this.setActiveTabs(Object.values(this.allTabs));
    }
  },
  methods: {
    buildTabs(productTypes = []) {
      return productTypes.reduce(
        (tabs, productType) => ({
          ...tabs,
          [productType]: `cart-totals-${productType}`,
        }),
        {}
      );
    },
    setActiveTabs(tabs = []) {
      this.activeTabs = tabs;
    },
    hasProductSubtotal(productType) {
      return this.visibleTotals?.subtotals?.[productType]?.quantity > 0;
    },
    validationRulesByType(productType) {
      return this.visibleTotals?.validationRules?.filter((rule) => rule.productType === productType);
    },
  },
};
</script>
