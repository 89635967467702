<template>
  <div class="bg-gray-05 p-2">
    <div class="text-md">
      <div class="flex justify-between">
        <span qaid="order-totals-subtotal-label">{{ $t('text.order.subtotal') }}</span>
        <span qaid="order-totals-subtotal-value">{{ subtotalPrice }}</span>
      </div>
      <div v-if="hasDiscounts" class="flex justify-between">
        <span qaid="order-totals-discount-label">{{ discountLabel }}</span>
        <span qaid="order-totals-discount-value">
          {{ `- ${discountPrice}` }}
        </span>
      </div>
    </div>
    <div v-if="!isVatEnabled" class="flex justify-between text-md">
      <span qaid="order-totals-tax-label">{{ `${$t('text.order.estimatedTax')}*` }}</span>
      <span qaid="order-totals-tax-value">-</span>
    </div>
    <div class="flex justify-between mb-2 pb-2 border-b border-gray-60 text-md">
      <span qaid="order-totals-shipping-label">{{ `${$t('basket.page.shipping')}` }}</span>
      <span qaid="order-totals-shipping-value">{{ shippingGrossPrice }}</span>
    </div>
    <div class="flex justify-between mb-1 font-bold text-lg">
      <span qaid="order-totals-total-label">{{ $t('basket.page.total') }}</span>
      <span qaid="order-totals-total-value">{{ totalPrice }}</span>
    </div>
    <div v-if="isVatEnabled" class="text-md">
      <div class="flex justify-between">
        <span qaid="order-totals-net-total-label">{{ $t('basket.page.totals.netTotal') }}</span>
        <span qaid="order-totals-net-total-value">{{ netTotal }}</span>
      </div>
      <div class="flex justify-between">
        <span qaid="order-totals-vat-label">{{ $t('basket.page.totals.estimatedVAT') }}</span>
        <span qaid="order-totals-vat-value">{{ cartTaxes }}</span>
      </div>
    </div>
    <p qaid="cart-tax-message" class="text-xs mb-2" v-html="taxMessage" />
  </div>
</template>

<script setup>
import {useCartSummary} from '@/composables/checkout-summary.js';
import {useStore} from '@/store';
import {toRefs} from 'vue';

const store = useStore();
const cartRef = toRefs(store.state.cart);

const {
  hasDiscounts,
  subtotalPrice,
  discountPrice,
  discountLabel,
  cartTaxes,
  taxMessage,
  isVatEnabled,
  netTotal,
  totalPrice,
  shippingGrossPrice,
} = useCartSummary(cartRef.cartData);
</script>
