<!-- This is the Locator Page container, used on B2C retailer pages (non-DOT stores)  -->
<template>
  <locator-page-map-container
    :store-coordinates="storeCoordinates"
    :store-details="storeDetails"
    map-classes="mapbox_b2c_canvas"
    qaid="locator-page-map-container"
  />
</template>

<script>
import LocatorPageMapContainer from '@/components/mapbox/containers/LocatorPageMapContainer.vue';

export default {
  name: 'LocatorPageContainer',
  components: {
    LocatorPageMapContainer,
  },

  props: {
    storeCoordinates: {
      type: Object,
      default: () => null,
    },
    storeDetails: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style lang="scss">
.mapbox_b2c_canvas {
  aspect-ratio: 4/3;
}
</style>
