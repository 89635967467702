<template>
  <div>
    <button
      qaid="build-a-bundle-cta"
      class="bg-gray-05 flex justify-between items-center p-2 my-2"
      @click="showAccessoryFinder"
    >
      <div>
        <div class="font-bold">{{ $t('text.buyingZone.buildBundle') }}</div>
        <p class="text-sm leading-normal">
          {{ $t('electra.accessoryFinder.addTo', [product.name]) }}
        </p>
      </div>
      <pdl-icon name="chevron_right" />
    </button>
    <div>
      <pdl-dialog
        fullscreen
        custom-class="zoovu-dialog"
        :visible.sync="dialogVisible"
        :z-index="16000011"
        :close-dialog-tool-tip="$t('popup.close')"
        qaid="build-bundle-dialog"
        @close="close"
      >
        <div id="accessory-finder-container" />
      </pdl-dialog>
    </div>
    <div v-show="false" id="accessory-finder-loading-container">
      <div id="zoovu-assistant" />
    </div>
  </div>
</template>

<script>
import {PdlDialog} from '@pedal/pdl-dialog';
import zoovuAddToCart, {ZOOVU_LOADED} from '@/zoovu/mixins/addToCart';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'PdpBuildABundleCta',
  components: {PdlDialog, PdlIcon},
  mixins: [zoovuAddToCart],
  props: {
    product: {
      type: Object,
      required: true,
    },
    variantCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      hasScriptLoaded: false,
    };
  },
  watch: {
    variantCode() {
      window.history.replaceState(null, null, `?vc=1475272&esp=${this.variantCode}`);
      // the accessory finder is not responsive to changes in the url params so it must be rebuilt every time a new bike variant is selected
      this.rebuildAccessoryFinder();
    },
  },
  created() {
    this.$on(ZOOVU_LOADED, this.close);
  },
  methods: {
    async loadAccessoryFinder(variantCode) {
      return new Promise((resolve) => {
        window.history.replaceState(null, null, `?vc=1475272&esp=${variantCode}`);

        const accessoryFinderScript = document.createElement('script');
        accessoryFinderScript.setAttribute(
          'src',
          'https://tiger-cdn.zoovu.com/advisor-fe-web/api/v1/advisors/Mrt59zjL/js-loader?locale=en-US'
        );
        accessoryFinderScript.setAttribute('id', 'accessory-finder-script');
        accessoryFinderScript.addEventListener('load', () => {
          resolve(true);
        });

        document.head.appendChild(accessoryFinderScript);
      });
    },

    async showAccessoryFinder() {
      if (!this.hasScriptLoaded) {
        this.hasScriptLoaded = await this.loadAccessoryFinder(this.variantCode);
      }

      this.dialogVisible = true;
      let accessoryFinderNode = document.getElementById('zoovu-assistant');
      this.$nextTick(() => {
        document.getElementById('accessory-finder-container').appendChild(accessoryFinderNode);
      });
    },
    rebuildAccessoryFinder() {
      // it rebuilds in a hidden div because it is slow to compile and this dramatically reduces the amount of time a user will watch a loading spinner
      let accessoryFinderNode = document.getElementById('zoovu-assistant');
      accessoryFinderNode.parentElement.removeChild(accessoryFinderNode);
      let newAccessoryFinderNode = document.createElement('div');
      newAccessoryFinderNode.id = 'zoovu-assistant';
      document.getElementById('accessory-finder-loading-container').appendChild(newAccessoryFinderNode);
      this.rerunAccessoryFinderScript();
    },
    rerunAccessoryFinderScript() {
      let oldScript = document.getElementById('accessory-finder-script');
      oldScript.parentNode.removeChild(oldScript);
      let accessoryFinderScript = document.createElement('script');
      accessoryFinderScript.setAttribute(
        'src',
        'https://tiger-cdn.zoovu.com/advisor-fe-web/api/v1/advisors/Mrt59zjL/js-loader?locale=en-US'
      );
      accessoryFinderScript.setAttribute('id', 'accessory-finder-script');
      document.head.appendChild(accessoryFinderScript);
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
button:focus {
  outline: none;
  box-shadow: 0 0 0.125rem 0.0625rem var(--white), 0 0 0.125rem 0.125rem var(--blue-100);
}

::v-deep .zoovu-dialog {
  .zoovu-configurator {
    &__main-content {
      flex: 1;
    }

    .product-stage-footer {
      flex-wrap: wrap;
    }
  }
}
</style>
