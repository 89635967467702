/* global getFrameQueryParameters, getQueryParameters, storeFinderAjaxHelper */

export default function () {
  var storeFinderAjaxUrl = $('#storeFinderFormAction_js_id').val();
  var themeResourcePath = $('#themeResourcePath_js_id').val();
  var geoIPLocationtext = $('#geoIPLocationtext_js_id').val();
  var filterFrameQuery = $('#frameQuery_js_id').val();

  storeFinderAjaxHelper.configure(storeFinderAjaxUrl, themeResourcePath);
  var queryParameters = getQueryParameters();

  var searchParameters = storeFinderAjaxHelper.getStoreFinderQueryData();
  if (!queryParameters.fromdetail) {
    searchParameters = {};
    if (geoIPLocationtext) {
      searchParameters.q = geoIPLocationtext;
    }
  }
  if (searchParameters.q) {
    if (searchParameters.sort) {
      $('#sortby').val(searchParameters.sort);
    }
    if (searchParameters.distance) {
      $('#distance').val(searchParameters.distance);
    }
    if (geoIPLocationtext && filterFrameQuery) {
      $.extend(searchParameters, getFrameQueryParameters(filterFrameQuery));
    }
    $('#storelocator-query').val(searchParameters.q);
    storeFinderAjaxHelper.selectFilters(searchParameters);
    storeFinderAjaxHelper.doSearch(searchParameters);
  }
}
