<template>
  <div qaid="warranty-issue-types-panel">
    <trek-button text icon-only icon="close" class="absolute top-0 right-0 mt-2 mr-2" @click="closeTooltip">
    </trek-button>

    <pdl-section-header size="sm" class="my-2">
      <template slot="content">
        <pdl-heading :level="4" class="text-left">{{ $t('form.select.empty') }}</pdl-heading>
      </template>
    </pdl-section-header>

    <div v-if="hasParentIssue" class="my-1">
      <button class="flex items-center text-left" @mouseup.prevent="goBack">
        <pdl-icon name="chevron_left" />
        <span class="text-left">{{ $t('navigation.back') }}</span>
      </button>
    </div>

    <div class="b2b-scroll-list-container min-w-xs">
      <div class="b2b-scroll-list">
        <div v-for="(issue, index) in paginatedData" :key="index" class="border-t border-gray-10">
          <button
            v-if="hasSubIssues(issue)"
            class="w-full flex justify-between items-center text-left p-1"
            @mouseup.prevent="selectSubIssue(issue)"
          >
            <span v-html="issue.warrantyUfoName" />
            <pdl-icon name="chevron_right" />
          </button>
          <button
            v-else
            class="w-full text-left p-1 pr-5"
            @mouseup.prevent="selectIssue(issue)"
            v-html="issue.warrantyUfoName"
          />
        </div>
      </div>
    </div>

    <div class="py-1">
      <pagination-controls
        v-if="pageCount > 1"
        ref="pagination"
        :page-number="pageNumber"
        :page-count="pageCount"
        @page-change="pageChange"
        @prev-page="prevPage"
        @next-page="nextPage"
      ></pagination-controls>
    </div>
  </div>
</template>

<script>
import TrekButton from '@/components/TrekButton';
import pagination from '@/mixins/pagination';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {PdlSectionHeader, PdlHeading, TrekButton, PdlIcon},
  mixins: [pagination],

  props: {
    warrantyIssues: {
      type: Array,
      required: true,
    },
    resetIssues: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: Number,
      default: 100,
      required: false,
    },
  },

  data() {
    return {
      currentIssues: this.warrantyIssues,
      parentIssues: [],
      parentPage: [],
    };
  },

  computed: {
    listData() {
      return this.currentIssues ? this.currentIssues : [];
    },

    hasParentIssue() {
      return this.parentIssues && this.parentIssues.length;
    },
  },

  watch: {
    resetIssues() {
      if (this.resetIssues) this.currentIssues = this.warrantyIssues;
    },
  },

  methods: {
    hasSubIssues(issue) {
      return issue.warrantyIssueTypes && issue.warrantyIssueTypes.length;
    },

    selectIssue(issue) {
      this.parentIssues = [];
      this.parentPage = [];
      this.currentIssues = this.warrantyIssues;
      this.pageNumber = 0;

      this.$emit('issue-selected', issue.warrantyUfoName);
    },

    selectSubIssue(issue) {
      this.parentIssues.push(this.currentIssues);
      this.parentPage.push(this.pageNumber);
      this.pageNumber = 0;
      this.currentIssues = issue.warrantyIssueTypes;
    },

    goBack() {
      this.currentIssues = this.parentIssues.pop();
      this.pageNumber = this.parentPage.pop();
    },

    closeTooltip() {
      let el = this.$parent.$refs.tippy;

      if (el && el._tippy) {
        el._tippy.hide();
      }
    },
  },
};
</script>
