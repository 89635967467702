<template>
  <fieldset class="form-fieldset">
    <legend for="brands" class="form-fieldset__legend" qaid="store-locator__filters__brands__label">
      <span>{{ $t('text.brand') }}</span>
    </legend>
    <div>
      <div class="b2b-badges">
        <tk-badge
          :is-dismissible="false"
          :has-logo="true"
          class="b2b-badge--secondary-light b2b-badge--toggle"
          :is-toggled="brandValues.trek"
          qaid="store-locator__filters__brands__trek"
          @click="toggle('trek')"
        >
          <template #logo>
            <div v-html="trekLogo" />
          </template>
        </tk-badge>
        <tk-badge
          :is-dismissible="false"
          :has-logo="true"
          class="b2b-badge--secondary-light b2b-badge--toggle"
          :is-toggled="brandValues.electra"
          qaid="store-locator__filters__brands__electra"
          @click="toggle('electra')"
        >
          <template #logo>
            <div v-html="electraLogo"></div>
          </template>
        </tk-badge>
      </div>
    </div>
  </fieldset>
</template>
<script>
import TkBadge from '@/components/TkBadge';
import {trekLogo, electraLogo} from '@/components/locator/assets/logos';

export default {
  name: 'LocatorFiltersBrands',
  components: {TkBadge},
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      trekLogo,
      electraLogo,
      brandValues: {
        electra: false,
        trek: false,
      },
    };
  },
  watch: {
    brandValues: {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    this.brandValues = this.value;
  },
  methods: {
    toggle(target) {
      this.$set(this.brandValues, target, !this.brandValues[target]);
    },
  },
};
</script>
