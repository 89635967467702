<template>
  <div class="cell medium-6 large-4" :class="{'large-offset-4': isDeliverableProductType}">
    <h5 class="font-bold" qaid="shipping-info-ship-to-header">
      {{ $t('text.shipTo') }}
    </h5>
    <div class="text-base leading-normal" qaid="shipping-info-ship-to-address">
      <p>{{ shipmentsInfo.b2bUnit.name }}</p>
      <p>{{ shipmentsInfo.deliveryAddress.line1 }}</p>
      <p>{{ shipmentsInfo.deliveryAddress.line2 }}</p>
      <p>{{ shipmentsInfo.deliveryAddress.town }}, {{ shipmentsInfo.deliveryAddress.country.isocode }}</p>
      <p>{{ shipmentsInfo.deliveryAddress.postalCode }} {{ shipmentsInfo.deliveryAddress.country.name }}</p>
    </div>

    <checkout-shipping-totals v-if="isShippingTotalsShown" :type="type" />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {ProductType} from '@/constants/product';
import CheckoutShippingTotals from '@/components/checkout/v2/CheckoutShippingTotals';

export default {
  components: {CheckoutShippingTotals},

  props: {
    type: {
      type: String,
      required: true,
    },
    isDropShipCustomer: {
      type: Boolean,
      default: false,
    },
    shipmentsInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      productType: ProductType,
    };
  },

  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode']),

    isDeliverableProductType() {
      return this.type === ProductType.Bike || this.type === ProductType.Aftermarket;
    },

    isShippingTotalsShown() {
      return this.isDeliverableProductType && !this.isDist && !this.isConsumerFriendlyMode;
    },
  },
};
</script>
