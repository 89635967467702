<template>
  <img :src="srcUrl" :srcset="srcset" alt="" @load="$emit('image-loaded')" />
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    assetId: {
      type: String,
      default: '',
    },
    /**
     * An array of layers needed to create a P1 bike url
     */
    imageLayers: {
      type: Array,
      default: () => [],
    },
    /**
     * An array of numbers which represent the width of each image in the srcset
     */
    breakpoints: {
      type: Array,
      default: () => [480, 640, 768, 1024, 1200, 1440, 1920],
    },
    /**
     * An aspect ratio in Width x Height. Used to determine the height of images in the srcset
     */
    aspectRatio: {
      type: String,
      default: undefined,
      validator: (val) => ['1x1', '4x3'].includes(val),
    },
    format: {
      type: String,
      default: 'auto',
    },
  },

  computed: {
    ...mapState('projectOne', ['baseImageUrl']),

    heightFromWidthFactor() {
      const aspect = this.aspectRatio?.split('x');
      return this.aspectRatio ? Number(aspect[1]) / Number(aspect[0]) : undefined;
    },
    imageLayersUrlString() {
      return `l_${this.imageLayers.join('/l_')}`;
    },
    srcUrl() {
      let src = '';
      if (this.imageLayers.length) {
        src = `${this.baseImageUrl}/${this.imageLayersUrlString}/f_auto/p1_base`;
      } else if (this.assetId) {
        src = `${this.baseImageUrl}/f_auto,c_fill${this.assetId}`;
      } else if (this.src) {
        src = this.src;
      }
      return src;
    },
    srcset() {
      const srcset = this.breakpoints.map((width) => {
        const height = this.aspectRatio ? `,h_${width * this.heightFromWidthFactor}` : `,c_fill`;

        if (this.imageLayers.length) {
          return `${this.baseImageUrl}/${this.imageLayersUrlString}/f_auto,w_${width}${height}/p1_base ${width}w`;
        } else if (this.assetId) {
          return `${this.baseImageUrl}/f_auto,w_${width}${height}${this.assetId} ${width}w`;
        }
      });
      return srcset.join(',');
    },
  },
};
</script>
