import { render, staticRenderFns } from "./MechanicNotes.vue?vue&type=template&id=71bbd262&scoped=true&"
import script from "./MechanicNotes.vue?vue&type=script&lang=js&"
export * from "./MechanicNotes.vue?vue&type=script&lang=js&"
import style0 from "./MechanicNotes.vue?vue&type=style&index=0&id=71bbd262&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71bbd262",
  null
  
)

export default component.exports