<template>
  <div>
    <div class="form-group--inline justify-start">
      <label for="selectSortby" class="form-label">{{ $t('text.sortBy') }}:</label>
      <div class="select select--clear w-auto inline-block">
        <select
          id="select-sortby"
          v-model="sortCode"
          qaid="select-sortby-backorders"
          class="form-control w-auto"
          @change="updateSort(sortCode)"
        >
          <option
            v-for="(column, colIndex) in sortOptions"
            :key="colIndex"
            :value="column.code"
            :qaid="`sort-list.option-${column.code}`"
          >
            {{ $t(`${column.name}`) }}
          </option>
        </select>
        <div class="focus-indicator"></div>
      </div>
    </div>
    <div v-if="gridData && gridData.length">
      <table id="order-history-grid" class="b2b-grid b2b-grid--collapse mb-0 is-compact">
        <thead class="b2b-grid__header">
          <tr>
            <template v-for="(column, index) in filteredColumns">
              <th
                :key="index"
                class="b2b-grid__cell b2b-grid__head text-xs"
                :class="[column.cssClasses]"
                :qaid="`allocatedBackorder.${column.key}Header`"
              >
                {{ column.label }}
              </th>
            </template>
          </tr>
        </thead>

        <tbody>
          <!-- Each Row -->
          <template v-for="(row, rowIndex) in paginatedData">
            <order-item :key="rowIndex" :row="row" :class="getRowClasses(rowIndex)" class="order-item" />

            <transition :key="`child-${rowIndex}`" name="fade">
              <order-item-details :row="row" />
            </transition>
          </template>
        </tbody>
      </table>
      <div class="grid-x grid-margin-x mb-5 mt-2">
        <div class="cell medium-6 large-8">
          <pagination-controls
            v-if="pagination.numberOfPages > 1"
            ref="pagination"
            :page-number="pagination.currentPage"
            :page-count="pagination.numberOfPages"
            @page-change="pageChange"
            @prev-page="prevPage"
            @next-page="nextPage"
          />
        </div>
        <div class="cell medium-6 large-4">
          <div class="buttons buttons--right-for-md">
            <trek-link id="manage-allocated-items-link" button primary :href="`${encodedContextPath}/checkout/`">
              <span>{{ $t('text.manageAllocatedItems') }}</span>
            </trek-link>
          </div>
        </div>
      </div>
    </div>
    <pdl-callout v-else>
      <template #content>
        <span>{{ $t('text.search.noResults') }}</span>
      </template>
    </pdl-callout>
  </div>
</template>

<script>
import pagination from '../../../mixins/pagination';
import {mapState, mapGetters} from 'vuex';
import TrekLink from '@/components/TrekLink';
import PaginationControls from '@/components/PaginationControls';
import {PdlCallout} from '@pedal/pdl-callout';
import OrderItem from '@/components/order-item/OrderItem';
import OrderItemDetails from '@/components/containers/my-account/OrderItemDetails';
import AllocatedBackorderSortOptionsMixin from '@/components/containers/my-account/mixins/constants';
import {AllocatedBackorderSortCode, AllocatedBackorderSortOption} from '@/constants/allocated-backorder';

export default {
  components: {TrekLink, PaginationControls, OrderItem, OrderItemDetails, PdlCallout},
  mixins: [pagination, AllocatedBackorderSortOptionsMixin],
  inject: ['container'],

  provide() {
    return {
      grid: this,
      container: this.container,
    };
  },

  props: {
    size: {
      type: Number,
      required: false,
      default: 50,
    },
    gridData: {
      type: Array,
      default: () => [],
    },
    sortKeys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageIndex: 0,
      sortCode: AllocatedBackorderSortCode.QuantityAllocatedOrShipped,
    };
  },
  computed: {
    ...mapState('backend', ['encodedContextPath', 'isConsumerFriendlyMode']),
    ...mapState('allocatedBackorder', ['pagination']),
    ...mapGetters('backend', ['isRestrictedCustomer']),

    listData() {
      return this.gridData;
    },

    headers() {
      return [
        {label: this.$t('text.sold'), key: 'sold', cssClasses: ''},
        {label: '', key: 'notShippable', cssClasses: ''},
        {
          label: this.$t('text.backordered'),
          key: 'quantityBackordered',
          cssClasses: '',
        },
        {
          label: this.$t('text.account.savedLists.sku'),
          key: 'sku',
          cssClasses: '',
        },
        {label: '', key: 'lineNote', cssClasses: ''},
        {
          label: this.$t('productCompare.description'),
          key: 'itemDescription',
          cssClasses: '',
        },
        {
          label: this.$t('text.account.orderHistory.mobile.page.sort.byOrderNumber'),
          key: 'orderNumber',
          cssClasses: '',
        },
        {label: this.$t('text.poNumber'), key: 'customerPo', cssClasses: ''},
        {label: this.$t('text.shipTo'), key: 'shipToNumber', cssClasses: ''},
        {label: this.$t('text.orderDate'), key: 'orderDate', cssClasses: ''},
        {
          label: this.$t('text.requestDate'),
          key: 'requestedDate',
          cssClasses: '',
        },
        {label: this.$t('basket.page.price'), key: 'price', cssClasses: ''},
        {label: this.$t('text.stock'), key: 'warehouses', cssClasses: ''},
        {label: this.$t('text.etaDate'), key: 'etaDate', cssClasses: ''},
        {label: this.$t('text.etaWatch'), key: 'etaWatch', cssClasses: ''},
        {
          label: this.$t('text.allocated'),
          key: 'quantityAllocatedOrShipped',
          cssClasses: '',
        },
        {
          label: this.$t('text.cancellationDate'),
          key: 'cancelDate',
          cssClasses: '',
        },
      ];
    },

    filteredColumns() {
      let columns = this.headers.filter((column) => !(this.isConsumerFriendlyMode && column.key == 'price'));

      columns = columns.filter((column) => !(this.isRestrictedCustomer && column.key === 'sold'));

      if (!this.listData.some((item) => item.isEligibleForProductPriority)) {
        return columns.filter((column) => column.key !== 'sold');
      }
      return columns;
    },
  },

  methods: {
    pageChange(num) {
      this.pagination.currentPage = num;
      this.container.fetchOrders(this.container.selectedProductTypeOrTab, this.sort);
    },
    updateSort(sortCode) {
      const sort = Object.values(AllocatedBackorderSortOption).find((sortOption) => sortOption.code === sortCode);
      this.$emit('update-sort-type', sort);
    },
    getRowClasses(rowIndex) {
      return {
        'bg-gray-02': (rowIndex + 1) % 2 === 0,
      };
    },
  },
};
</script>
