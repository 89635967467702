const ClaimStatuses = Object.freeze({
  ENUMCLAIMSTATUSSAVED: 'ENUMCLAIMSTATUSSAVED',
  ENUMCLAIMSTATUSACTIONREQUIRED: 'ENUMCLAIMSTATUSACTIONREQUIRED',
  ENUMCLAIMSTATUSSUBMITTED: 'ENUMCLAIMSTATUSSUBMITTED',
  ENUMCLAIMSTATUSCOMPLETED: 'ENUMCLAIMSTATUSCOMPLETED',
  ENUMCLAIMSTATUSCANCELLED: 'ENUMCLAIMSTATUSCANCELLED',
});

const DefaultGridData = {
  entries: [],
  pagination: {},
};

export {ClaimStatuses, DefaultGridData};
