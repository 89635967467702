<template>
  <div class="locator-container" qaid="store-locator" :class="{'no-results': !hasStores}">
    <div class="p-2 grid-container fluid">
      <div>
        <pdl-section-header size="xl">
          <template slot="content">
            <pdl-heading :level="1" qaid="store-locator__title">{{ $t('storeFinder.find.a.store') }}</pdl-heading>
          </template>
          <template slot="actions">
            <pdl-link
              v-if="viewBreakpointMoreThan('medium')"
              button
              text
              icon-right
              icon="arrow_forward"
              href="/store-finder/allRetailers/"
              qaid="store-locator__all-locations"
            >
              <span>{{ $t('storelocator.view.locations') }}</span>
            </pdl-link>
          </template>
        </pdl-section-header>
      </div>
      <div v-if="isTrekLocator">
        <template v-if="viewBreakpointMoreThan('medium')">
          <!-- Desktop -->
          <div class="grid-x grid-margin-x locator-search-wrapper">
            <div class="cell large-6 xlarge-5 xxlarge-4 flex flex-col relative locator-sidebar">
              <locator-search
                v-show="!quickViewId"
                ref="locatorSearch"
                v-model="textSearchQuery"
                :is-loading="isLoading"
                :initial-coordinates="initialCoordinates"
                :geoip-location="geoipLocation"
                qaid="store-locator__search"
                @search="newSearch"
                @open-filters="openFilters"
                @filter-removed="removeFilter"
                @query-center="updateMapCoords"
              />
              <locator-current-filters-list
                v-show="!quickViewId"
                :filters="currentFiltersList"
                qaid="store-locator__current-filters"
                @filter-removed="removeFilter"
              />
              <locator-store-list
                v-show="!quickViewId"
                v-model="stores"
                :display-empty-message="!isLoading"
                :is-loading="isLoading"
                :pin-hover-id="pinHoverId"
                qaid="store-locator__store-list"
                @store-clicked="openQuickView"
                @card-hover="cardHover"
              />
              <pagination-controls
                v-show="!quickViewId"
                v-if="totalPages > 1 && stores.length"
                class="max-w-xs w-full m-auto"
                container-class="justify-between"
                :display-pages="false"
                :page-number="pagination.page"
                :page-count="totalPages"
                @page-change="pageChange"
                @prev-page="prevPage"
                @next-page="nextPage"
              >
                <template #previous>
                  <span class="text-md ml-1">{{ $t('carousel.controls.previous') }}</span>
                </template>
                <template #next>
                  <span class="text-md mr-1">{{ $t('carousel.controls.next') }}</span>
                </template>
              </pagination-controls>
              <locator-filters
                v-if="isFiltersPanelVisible"
                :filters="filters"
                :service-filters="allowedServiceFilters"
                :sale-filters="saleFilters"
                qaid="store-locator__filters"
                @apply-filters="applyFilters"
                @close-filters="closeFilters"
              />
              <locator-quick-view
                v-if="isQuickViewVisible"
                :value="quickViewId"
                :store="quickViewStoreData"
                qaid="store-locator__quick-view"
                @close-quick-view="closeQuickView"
                @go-to-store-page="goToStorePage"
                @reload-data="loadData"
                @set-my-store="quickViewStoreData.isMyStore = true"
              />
            </div>
            <div class="cell large-6 xlarge-7 xxlarge-8">
              <locator-map
                :card-hover-id="cardHoverId"
                :quick-view-id="quickViewId"
                :stores="stores"
                :center-coords="centerCoords"
                :zoom-level="zoom"
                :bounds-to-set="boundsToSet"
                :bounds-to-set-by-center-and-zoom="boundsToSetByCenterAndZoom"
                :show-debug="false"
                :is-loading="isLoading"
                map-classes="rl-map"
                class="relative"
                qaid="store-locator__map"
                @new-coords="updateMapCoords"
                @pin-hover="pinHover"
                @user-changed-map="doBoundsBasedQuery"
                @store-clicked="openQuickView"
              />
            </div>
          </div>
        </template>

        <template v-else>
          <!-- Mobile presentation -->
          <locator-search
            v-show="!isQuickViewVisible"
            ref="locatorSearch"
            v-model="textSearchQuery"
            :is-loading="isLoading"
            :geoip-location="geoipLocation"
            qaid="store-locator__search--mobile"
            @search="newSearch"
            @open-filters="openFilters"
            @filter-removed="removeFilter"
            @query-center="updateMapCoords"
          />
          <locator-current-filters-list
            v-show="!isQuickViewVisible"
            :filters="currentFiltersList"
            class="overflow-auto whitespace-nowrap flex-nowrap"
            qaid="store-locator__current-filters--mobile"
            @filter-removed="removeFilter"
          />
          <pdl-tabs v-show="!quickViewId" v-model="currentMode" qaid="store-locator__tabs">
            <pdl-tab-pane name="list" :label="$t('storelocator.list')" class="store-locator__tab-pane flex flex-col">
              <locator-store-list
                v-show="!quickViewId"
                v-model="stores"
                :display-empty-message="!isLoading"
                :is-loading="isLoading"
                :pin-hover-id="pinHoverId"
                qaid="store-locator__store-list--mobile"
                @store-clicked="openQuickView"
                @card-hover="cardHover"
              />
              <pagination-controls
                v-if="totalPages > 1 && stores.length"
                container-class="justify-between"
                :display-pages="false"
                :page-number="pagination.page"
                :page-count="totalPages"
                @page-change="pageChange"
                @prev-page="prevPage"
                @next-page="nextPage"
              >
                <template #previous>
                  <span class="text-md ml-1">{{ $t('carousel.controls.previous') }}</span>
                </template>
                <template #next>
                  <span class="text-md mr-1">{{ $t('carousel.controls.next') }}</span>
                </template>
              </pagination-controls>
            </pdl-tab-pane>
            <pdl-tab-pane name="map" :label="$t('storeDetails.map.link')" class="store-locator__tab-pane">
              <locator-map
                :card-hover-id="cardHoverId"
                :quick-view-id="quickViewId"
                :stores="stores"
                :center-coords="centerCoords"
                :zoom-level="zoom"
                :bounds-to-set="currentMode === 'map' ? boundsToSet : null"
                :bounds-to-set-by-center-and-zoom="currentMode === 'map' ? boundsToSetByCenterAndZoom : null"
                :show-debug="false"
                :is-loading="isLoading"
                map-classes="rl-map"
                class="h-full"
                qaid="store-locator__map--mobile"
                @new-coords="updateMapCoords"
                @pin-hover="pinHover"
                @user-changed-map="doBoundsBasedQuery"
                @store-clicked="openQuickView"
              />
            </pdl-tab-pane>
          </pdl-tabs>
          <locator-filters
            v-if="isFiltersPanelVisible"
            :filters="filters"
            :service-filters="allowedServiceFilters"
            :sale-filters="saleFilters"
            qaid="store-locator__filters--mobile"
            @apply-filters="applyFilters"
            @close-filters="closeFilters"
          />
          <locator-quick-view
            v-if="isQuickViewVisible"
            :value="quickViewId"
            :store="quickViewStoreData"
            qaid="store-locator__quick-view--mobile"
            @close-quick-view="closeQuickView"
            @go-to-store-page="goToStorePage"
            @reload-data="loadData"
            @set-my-store="quickViewStoreData.isMyStore = true"
          />
        </template>
      </div>
      <locally-locator v-else :options="options.locally" />
      <div v-if="viewBreakpointLessThan('large')" class="text-center mt-2">
        <pdl-link
          button
          text
          icon-right
          icon="arrow_forward"
          href="/store-finder/allRetailers/"
          qaid="store-locator__all-locations"
        >
          <span v-html="$t('storelocator.view.locations')"></span>
        </pdl-link>
      </div>
      <div class="max-w-md mx-auto my-4 md:my-6 text-sm text-gray-60" qaid="store-locator__description">
        <p class="mb-2 font-bold" v-html="$t('storeFinder.v2.who.title')"></p>
        <p class="mb-2" v-html="$t('storeFinder.v2.who.body')"></p>
        <p class="mb-2 font-bold" v-html="$t('storeFinder.v2.why.title')"></p>
        <p class="mb-2" v-html="$t('storeFinder.v2.why.body1')"></p>
        <p class="mb-2" v-html="$t('storeFinder.v2.why.body2')"></p>
        <p class="mb-2" v-html="$t('storeFinder.v2.why.body3')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import isEmpty from 'lodash/isEmpty';
import {Url} from '@/utils/url';
import LocatorSearch from '@/components/locator/components/LocatorSearch';
import LocatorStoreList from '@/components/locator/components/LocatorStoreList';
import {QuickView} from '@/components/locator/mixins/quick-view';
import {Filters} from '@/components/locator/mixins/filters';
import {LocatorApi} from '@/components/locator/mixins/locator-api';
import LocatorCurrentFiltersList from '@/components/locator/components/LocatorCurrentFiltersList';
import LocatorMap from '@/components/locator/components/LocatorMap';
import PaginationControls from '@/components/PaginationControls';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import LocallyLocator from '@/components/locator/components/LocallyLocator';
import {mapState, mapGetters} from 'vuex';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlLink} from '@pedal/pdl-link';
import headTag from '@/utils/head-tag';

export default {
  name: 'LocatorContainer',

  components: {
    LocatorCurrentFiltersList,
    LocatorStoreList,
    LocatorSearch,
    PdlSectionHeader,
    PdlHeading,
    LocatorMap,
    PaginationControls,
    PdlTabs,
    PdlTabPane,
    LocallyLocator,
    PdlLink,
  },

  mixins: [QuickView, Filters, LocatorApi],

  props: {
    baseProperties: {
      type: Array,
      default: () => [],
    },
    geoipLocation: {
      type: String,
      default: undefined,
    },
    geoipLat: {
      type: Number,
      default: 43.1790849, // Trek HQ, Waterloo, WI
    },
    geoipLng: {
      type: Number,
      default: -89.0058727,
    },
    isTrekLocator: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      currentMode: 'list',
      quickViewStoreData: Object,
      textSearchQuery: '',
      stores: [],
      cardHoverId: -1,
      pinHoverId: -1,
      quickViewId: null,
      centerCoords: {},
      initialZoom: 11,
      zoom: 11,
      initialCoordinates: {lng: this.geoipLng, lat: this.geoipLat},
      boundsToSet: null,
      boundsToSetByCenterAndZoom: null,
      boundsToSetByTextQuery: null,
      pagination: {},
      isRestoringState: false,
      contextPath: ACC.config.encodedContextPath || '',
    };
  },

  computed: {
    ...mapState('viewSize', ['viewBreakpoint']),
    ...mapGetters('viewSize', ['viewBreakpointMoreThan', 'viewBreakpointLessThan']),

    totalPages() {
      if (!this.pagination.pageSize) {
        return 1;
      }
      return Math.ceil(this.pagination.totalResults / this.pagination.pageSize);
    },
  },

  watch: {
    textSearchQuery() {
      if (!this.isRestoringState) {
        this.newSearch();
      }
    },
  },

  created() {
    this.centerCoords = this.initialCoordinates;
    if (this.filterParams) {
      this.$set(this.filters, 'services', this.filterParams.join(','));

      // Remove filters param from URL so that vuex state can be restored upon returning to the page
      window.history.replaceState(
        null,
        document.title,
        Url.update(window.location.href, undefined, {
          removeKeys: ['filters'],
        })
      );
    }

    // Restore state from vuex
    else if (
      this.$store.getters['storeLocator/textSearchQuery'] &&
      !isEmpty(this.$store.getters['storeLocator/mapCenterCoords'])
    ) {
      this.isRestoringState = true;
      this.restoreState();
    } else {
      this.$store.dispatch('storeLocator/resetCache');
    }
  },

  mounted() {
    headTag.appendLink('mapboxStyles', 'https://api.mapbox.com/mapbox-gl-js/v2.10.0/mapbox-gl.css', 'stylesheet');
    if (!this.isRestoringState && this.isTrekLocator) {
      this.$refs.locatorSearch.scheduleGeolocationQuery();
    } else {
      this.$nextTick(() => {
        this.isRestoringState = false;
      });
    }
  },

  methods: {
    doBoundsBasedQuery(bounds) {
      this.resetBoundsToSet();
      this.searchBounds = bounds;
      this.loadData();
    },

    updateMapCoords(center, zoom) {
      this.resetBoundsToSet();
      this.centerCoords = {...center};
      if (zoom) {
        this.zoom = zoom;
      }
    },

    resetBoundsToSet() {
      this.boundsToSet = null;
      this.boundsToSetByCenterAndZoom = null;
      this.boundsToSetByTextQuery = null;
    },

    closeQuickView() {
      this.quickViewId = null;
    },

    openQuickView(storeId, distance) {
      const timeStamp = new Date().getTime();
      const url = `/store-finder/store?timestamp=${timeStamp}&id=${storeId}&distance=${distance}`;
      storefrontInstance
        .get(url)
        .then(({data}) => {
          if (!data.data) return;
          this.quickViewStoreData = data.data;
          this.quickViewId = storeId;
        })
        .catch((e) => {
          console.error(e);
        });
    },

    goToStorePage(storeId) {
      // Save state to vuex before navigating away
      this.saveState();
      this.$nextTick(() => {
        window.location.href = `${this.contextPath}/store/${storeId}/`;
      });
    },

    cardHover(index) {
      this.cardHoverId = index;
    },

    pinHover(index) {
      this.pinHoverId = index;
    },

    pageChange(page) {
      this.loadData(page);
    },

    prevPage() {
      if (this.pagination.page > 1) {
        this.pageChange(this.pagination.page - 1);
      }
    },

    nextPage() {
      if (this.pagination.page < this.totalPages) {
        this.pageChange(this.pagination.page + 1);
      }
    },

    setMapBounds(payload) {
      if (
        !isEmpty(payload.geoPointForSearchTerm) &&
        (payload.isNewTextSearch || (payload.previousPage > 0 && payload.currentPage === 0) || payload.isFilterChange)
      ) {
        /**
         * When user is doing a new text-based query,
         * or is returning to first page,
         * or has changed their filters,
         * we want to set a happy medium zoom level for displaying results
         * and then zoom out if necessary to ensure at least one store is shown on the map
         */

        let storeToIncludeOnMap = this.stores[0].id;
        if (this.stores.length > 1 && this.stores[0].isMyStore) {
          storeToIncludeOnMap = this.stores[1].id;
        }
        this.boundsToSet = null;
        this.boundsToSetByCenterAndZoom = {
          centerCoords: {
            lat: payload.geoPointForSearchTerm.latitude,
            lng: payload.geoPointForSearchTerm.longitude,
          },
          zoom: this.initialZoom,
          extendBoundsForStore: storeToIncludeOnMap,
        };
      } else if (payload.previousPage != payload.currentPage && !isEmpty(payload.displayBounds)) {
        /**
         * Otherwise, when user is changing pages we'll use the boundaries provided by backend that encompass
         * all the stores in the result set.
         */
        this.boundsToSetByCenterAndZoom = null;
        this.boundsToSet = payload.displayBounds;
      } else if (isEmpty(payload.geoPointForSearchTerm) && isEmpty(payload.displayBounds)) {
        // We can use geocoder request as fallback
        this.zoom = this.initialZoom;
        this.boundsToSetByTextQuery = this.textSearchQuery;
      }
    },

    saveState() {
      /**
       * Overcome back-forward caching on safari
       * Subject to webkit bug https://bugs.webkit.org/show_bug.cgi?id=156356
       * (Returning to this page with back button more than once fails to fire the 'pageshow' event)
       */
      document.getElementsByTagName('BODY')[0].onpageshow = (e) => {
        if (e.persisted) {
          this.restoreState();
        }
      };
      this.$store.commit('storeLocator/setTextSearchQuery', this.textSearchQuery);
      this.$store.commit('storeLocator/setActiveFilters', this.filters);
      this.$store.commit('storeLocator/setPagination', this.pagination);
      this.$store.commit('storeLocator/setMapCenterCoords', this.centerCoords);
      this.$store.commit('storeLocator/setMapZoom', this.zoom);
      this.$store.commit('storeLocator/setSearchBounds', this.searchBounds);
    },

    restoreState() {
      this.isLoading = true;
      this.quickViewId = null;
      this.textSearchQuery = this.$store.getters['storeLocator/textSearchQuery'];
      this.filters = this.$store.getters['storeLocator/activeFilters'];
      this.pagination = this.$store.getters['storeLocator/pagination'];
      this.initialCoordinates = this.$store.getters['storeLocator/mapCenterCoords'];
      this.centerCoords = this.$store.getters['storeLocator/mapCenterCoords'];
      this.searchBounds = this.$store.getters['storeLocator/searchBounds'];
      this.zoom = this.$store.getters['storeLocator/mapZoom'];
      this.$store.dispatch('storeLocator/resetCache');

      this.loadData(this.pagination.page);
    },
  },
};
</script>
