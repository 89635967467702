/**
 * Utility to update warehouse's stock watch status.
 * @param productGroups
 * @param stock
 */
const updateStockWatchStatus = (productGroups, stock) => {
  if (
    !productGroups ||
    !stock ||
    typeof stock !== 'object' ||
    !('stockWatchProduct' in stock) ||
    !('stockWatchWarehouse' in stock) ||
    !('stockWatchActive' in stock)
  ) {
    return;
  }

  for (const group of productGroups) {
    const product = group.products.find((product) => product.code === stock.stockWatchProduct);
    if (product) {
      const stockWatchItem = product.stockWatch.find((item) => item.id === stock.stockWatchWarehouse);
      if (stockWatchItem) {
        stockWatchItem.stockWatchActive = stock.stockWatchActive;
        break;
      }
    }
  }
};

export {updateStockWatchStatus};
