import {FulfillmentOptionTypes} from './fulfillment-options';

const DeliveryModeTypes = Object.freeze({
  BIKE_TO_HOME: 'BIKE-TO-HOME',
  BOPIS: 'BOPIS',
  BOPIS_NO_BIKE: 'BOPIS-NO-BIKE',
  CLICK_AND_COLLECT: 'CLICK-AND-COLLECT',
  CLICK_AND_COLLECT_GROSS: 'CLICK-AND-COLLECT_GROSS',
  CLICK_AND_COLLECT_BIKE_OVERRIDE: 'CLICK-AND-COLLECT-BIKE-OVERRIDE',
  CLICK_AND_COLLECT_NO_BIKE: 'CLICK-AND-COLLECT-NO-BIKE',
  CLICK_AND_COLLECT_NO_BIKE_GROSS: 'CLICK-AND-COLLECT-NO-BIKE_GROSS',
  DEALER_DELIVERY: 'DEALER-DELIVERY',
  DEALER_DELIVERY_GROSS: 'DEALER-DELIVERY_GROSS',
  DEALER_DELIVERY_OVERRIDE: 'DEALER-DELIVERY-OVERRIDE',
  GROUND: 'GROUND',
  GROUND_GROSS: 'GROUND_GROSS',
  OVERNIGHT: 'OVERNIGHT',
  RETAILER_PICKUP: 'RETAILER_PICKUP',
  TWO_DAY: 'TWO-DAY',
  TWO_DAY_GROSS: 'TWO-DAY_GROSS',
});

const ShipToHomeMessages = Object.freeze({
  GROUND: 'checkout.multi.deliveryMethod.upgrade.fast',
  GROUND_GROSS: 'checkout.multi.deliveryMethod.upgrade.fast',
  'TWO-DAY': 'checkout.multi.deliveryMethod.upgrade.faster',

  'TWO-DAY_GROSS': 'checkout.multi.deliveryMethod.upgrade.faster',
  OVERNIGHT: 'checkout.multi.deliveryMethod.upgrade.fastest',
});
const DefaultShipToHomeModes = [DeliveryModeTypes.GROUND, DeliveryModeTypes.GROUND_GROSS];
const UpgradedShipToHomeModes = [
  DeliveryModeTypes.TWO_DAY,
  DeliveryModeTypes.TWO_DAY_GROSS,
  DeliveryModeTypes.OVERNIGHT,
];

const DeliveryModeFulfillmentOptionMap = Object.freeze({
  [DeliveryModeTypes.BIKE_TO_HOME]: FulfillmentOptionTypes.SHIP_TO_HOME,
  [DeliveryModeTypes.BOPIS]: FulfillmentOptionTypes.PICK_UP_IN_STORE,
  [DeliveryModeTypes.BOPIS_NO_BIKE]: FulfillmentOptionTypes.PICK_UP_IN_STORE,
  [DeliveryModeTypes.CLICK_AND_COLLECT]: FulfillmentOptionTypes.PICK_UP_IN_STORE,
  [DeliveryModeTypes.CLICK_AND_COLLECT_GROSS]: FulfillmentOptionTypes.PICK_UP_IN_STORE,
  [DeliveryModeTypes.CLICK_AND_COLLECT_BIKE_OVERRIDE]: FulfillmentOptionTypes.PICK_UP_IN_STORE,
  [DeliveryModeTypes.CLICK_AND_COLLECT_NO_BIKE]: FulfillmentOptionTypes.PICK_UP_IN_STORE,
  [DeliveryModeTypes.CLICK_AND_COLLECT_NO_BIKE_GROSS]: FulfillmentOptionTypes.PICK_UP_IN_STORE,
  [DeliveryModeTypes.DEALER_DELIVERY]: FulfillmentOptionTypes.DEALER_DELIVERY,
  [DeliveryModeTypes.DEALER_DELIVERY_GROSS]: FulfillmentOptionTypes.DEALER_DELIVERY,
  [DeliveryModeTypes.DEALER_DELIVERY_OVERRIDE]: FulfillmentOptionTypes.DEALER_DELIVERY,
  [DeliveryModeTypes.GROUND]: FulfillmentOptionTypes.SHIP_TO_HOME,
  [DeliveryModeTypes.GROUND_GROSS]: FulfillmentOptionTypes.SHIP_TO_HOME,
  [DeliveryModeTypes.OVERNIGHT]: FulfillmentOptionTypes.SHIP_TO_HOME,
  [DeliveryModeTypes.RETAILER_PICKUP]: FulfillmentOptionTypes.PICK_UP_IN_STORE,
  [DeliveryModeTypes.TWO_DAY]: FulfillmentOptionTypes.SHIP_TO_HOME,
  [DeliveryModeTypes.TWO_DAY_GROSS]: FulfillmentOptionTypes.SHIP_TO_HOME,
});

export {
  DeliveryModeTypes,
  ShipToHomeMessages,
  DefaultShipToHomeModes,
  UpgradedShipToHomeModes,
  DeliveryModeFulfillmentOptionMap,
};
