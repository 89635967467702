import {DownloadBackordersApi} from '@/api/download-backorders';
import {PdlToastType} from '@/constants/pdl-toast-type';

/**
 * Download timeout (value in milliseconds).
 * @type {number}
 */
const DownloadTimeout = 120000; // 2 minutes

/**
 * Time to wait before next status verification.
 * @type {number}
 */
const VerifyStatusDelay = 5000; // 5 seconds

/**
 * Delay to enable download button again after successful download.
 * @type {number}
 */
const ProceedWithDownloadDelay = 5000; // 5 seconds

const DownloadBackorders = {
  watch: {
    backorderDownloading(value) {
      if (value) {
        this.clearTimeouts();
        this.backorderDownloadInProgress();
      }
    },
  },

  methods: {
    getExportOptions() {
      return this.searchEntriesPayload(this.currentTab);
    },

    /**
     * Export backorders.
     */
    async downloadBackorders() {
      this.backorderDownloading = true;
      try {
        const options = this.getExportOptions();
        const exportId = await DownloadBackordersApi.obtainExportId(options);

        await this.waitUntilExportReadyForDownload(exportId);
        this.downloadFile(exportId);
        this.clearTimeouts();

        setTimeout(this.backorderDownloadIsSuccessful, ProceedWithDownloadDelay);
      } catch (e) {
        this.backorderDownloadHasFailed();
        console.error(e);
      }
    },

    /**
     * Wait until the exported file is ready to be downloaded.
     * @return {Promise<string|null>}
     */
    waitUntilExportReadyForDownload(exportId) {
      if (!exportId) {
        throw new Error('Export ID is invalid');
      }

      return new Promise((resolve, reject) => {
        const verifyStatus = () => {
          DownloadBackordersApi.isFileReady(exportId).then((isReady) => {
            if (!isReady) {
              this.checkStatusTimeout = setTimeout(verifyStatus, VerifyStatusDelay);
            } else {
              resolve();
            }
          });
        };

        // Start verification for maximum wait time.
        this.downloadTimeout = setTimeout(() => reject(new Error('Reached wait timeout')), DownloadTimeout);

        // Check download status.
        this.checkStatusTimeout = setTimeout(verifyStatus, VerifyStatusDelay);
      });
    },

    /**
     * Redirect to the file for download.
     * @param exportId
     */
    downloadFile(exportId) {
      if (!exportId) {
        throw new Error('Export ID is invalid');
      }

      window.open(DownloadBackordersApi.getFileDownloadUrl(exportId), '_self');
    },
    backorderDownloadInProgress() {
      this.$notify({
        type: PdlToastType.INFO,
        message: this.$t('backordersAllocated.B2B.downloading'),
      });
    },

    backorderDownloadIsSuccessful() {
      this.backorderDownloading = false;
      this.$notify({
        type: PdlToastType.SUCCESS,
        message: this.$t('backordersAllocated.B2B.ready'),
      });
    },

    backorderDownloadHasFailed() {
      this.backorderDownloading = false;
      this.$notify({
        type: PdlToastType.ERROR,
        message: this.$t('errorMessage.unableToProcess'),
      });
    },
    clearTimeouts() {
      if (this.checkStatusTimeout) {
        clearTimeout(this.checkStatusTimeout);
      }

      if (this.downloadTimeout) {
        clearTimeout(this.downloadTimeout);
      }
    },
  },

  data() {
    return {
      checkStatusTimeout: null,
      downloadTimeout: null,
      exportId: '',
    };
  },
  beforeDestroy() {
    this.clearTimeouts();
  },
};

export default DownloadBackorders;
