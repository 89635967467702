<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import TrekButton from '@/components/TrekButton';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import CartProductsList from '@/components/cart/CartProductsList';

export default {
  components: {
    TrekButton,
    PdlTabs,
    PdlTabPane,
    CartProductsList,
  },
  props: {
    bikes: {
      type: Array,
      default: () => [],
    },
    parts: {
      type: Array,
      default: () => [],
    },
    customWaterBottles: {
      type: Array,
      default: () => [],
    },
    projectOneBikes: {
      type: Array,
      default: () => [],
    },
    cartSummary: {
      type: Object,
      default: () => null,
    },
    tabSelected: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dialogVisible: false,
      itemToDeleteElement: null,
      itemDeleted: false,
    };
  },

  computed: {
    bikesAreAvailable() {
      return this.bikes && this.bikes.length;
    },

    partsAreAvailable() {
      return this.parts && this.parts.length;
    },

    customWaterBottlesAreAvailable() {
      return this.customWaterBottles && this.customWaterBottles.length;
    },

    projectOneBikesAreAvailable() {
      return this.projectOneBikes && this.projectOneBikes.length;
    },

    cartIsEmpty() {
      return (
        isEmpty(this.parts) && isEmpty(this.bikes) && isEmpty(this.customWaterBottles) && isEmpty(this.projectOneBikes)
      );
    },

    currentCartProductsCombined() {
      return {
        bikes: this.bikes,
        parts: this.parts,
        customWaterBottles: this.customWaterBottles,
        projectOneBikes: this.projectOneBikes,
      };
    },
  },

  methods: {
    onTabClick(paneName) {
      this.$emit('tab-selected', paneName);
    },

    getGroupDisplayColor(product) {
      let colorCode = get(product, 'entries[0].variantValueCategories[0].Color.code');
      return colorCode ? 'swatch-' + colorCode : null;
    },

    getGroupDisplayColorName(product) {
      return get(product, 'entries[0].variantValueCategories[0].Color.name');
    },

    getCollapseItemName(prefix, index) {
      return prefix + '-' + index;
    },

    // lazy loading of these translations make a dynamic key required
    // for tab panes to update
    headingKey(productType) {
      let key;
      switch (productType) {
        case 'bikes':
          key = this.$t('text.bikes');
          break;
        case 'parts':
          key = this.$t('text.accessories');
          break;
        case 'customWaterBottles':
          key = this.$t('checkout.custom');
          break;
        case 'projectOneBikes':
          key = this.$t('checkout.projectOne');
          break;
        default:
          console.error('productType is undefined');
          break;
      }
      return key;
    },
  },
};
</script>
