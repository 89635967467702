'use strict';

export default function (source) {
  var mediaQuery = '(min-width: 960px)';

  // Check if it is iOS
  var isiOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;

  var overlay = $('[data-overlay]');
  var target = $(source.attr('data-popup-reveal'));

  source.on('click', function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (!target.hasClass('open')) {
      target.triggerHandler('open');

      if ($(this).data('ga-cart')) {
        ACC.track.trackMiniCart(this);
      }
    } else {
      target.triggerHandler('close');
    }
  });

  $(document).click(function (e) {
    if (target.hasClass('open')) {
      var container = target;

      if (
        !container.is(e.target) && // if the target of the click isn't the container...
        container.has(e.target).length === 0
      ) {
        // ... nor a descendant of the container
        target.triggerHandler('close');

        e.stopPropagation();
      }
    }
  });

  target.on('open', function () {
    if (!(target.is('[data-popup-large]') && !window.matchMedia(mediaQuery).matches)) {
      var openPopup = $('body').attr('popup');
      if (openPopup) {
        $('#' + openPopup).triggerHandler('close');
      }

      overlay.addClass('open');
      if (isiOS === true) {
        $('body').css('cursor', 'pointer');
      }
      $('body').attr('popup', target.attr('id'));
    }
    target.addClass('open');
    target.trigger('opened');
  });

  target.on('close', function () {
    if (!(target.is('[data-popup-large]') && !window.matchMedia(mediaQuery).matches)) {
      $('body').removeAttr('popup');

      overlay.removeClass('open');
      if (isiOS === true) {
        $('body').css('cursor', 'default');
      }
    }
    target.removeClass('open');
    target.trigger('closed');
  });

  window.matchMedia(mediaQuery).addListener(function () {
    // close any popups that are open when switching between mobile / desktop view
    var openPopup = $('body').attr('popup');
    if (openPopup) {
      $('#' + openPopup).triggerHandler('close');
      overlay.removeClass('open');
    }
  });
}
