<template>
  <tr v-if="forPrint && productHasNote" class="b2b-grid__secondary-row">
    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid.icon">
      <pdl-icon name="note_add" :label="$t('text.addANote')" />
    </td>

    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid.note" colspan="9">
      <div class="b2b-grid__content italic text-gray-80">
        {{ item.lineNote }}
      </div>
    </td>
  </tr>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {
    PdlIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    forPrint: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    productHasNote() {
      return this.item && !isEmpty(this.item.lineNote);
    },
  },
};
</script>
