<template>
  <pdl-button button text icon-right small underline icon="straighten" qaid="size-dialog-button" @click="openDialog">
    <span>{{ $t('text.buyingZone.sizeLink') }}</span>
    <size-dialog :is-dialog-visible="isDialogVisible" :size-link-guide="sizeLinkGuide" @close="closeDialog" />
  </pdl-button>
</template>

<script>
import {PdlButton} from '@pedal/pdl-button';
import SizeDialog from '@/components/dialog/size/SizeDialog';

export default {
  components: {PdlButton, SizeDialog},
  props: {
    sizeLinkGuide: {
      type: String,
      default: undefined,
    },
  },
  data: function () {
    return {
      isDialogVisible: false,
    };
  },
  methods: {
    openDialog() {
      this.isDialogVisible = true;
    },
    closeDialog() {
      this.isDialogVisible = false;
    },
  },
};
</script>
