<template>
  <div qaid="order-totals-container" class="bg-gray-05 p-2 mt-1">
    <div class="text-md">
      <div class="flex justify-between">
        <span qaid="order-totals-subtotal-label">{{ $t('text.order.subtotal') }}</span>
        <span qaid="checkout-product-price-value">{{ subtotalPrice }}</span>
      </div>
      <div v-if="hasDiscounts" class="flex justify-between">
        <span qaid="order-totals-discount-label">{{ discountLabel }}</span>
        <span qaid="order-totals-discount-value">
          {{ `- ${discountPrice}` }}
        </span>
      </div>
    </div>
    <div class="mb-2 pb-2 border-b border-gray-60 text-md">
      <div v-if="isDeliveryModeSet" class="flex justify-between">
        <span qaid="order-totals-delivery-label">{{ deliveryModeName }}</span>
        <span qaid="order-totals-delivery-value">{{ shippingGrossPrice }}</span>
      </div>
      <div v-if="isCalculatedTaxEnabled" class="flex justify-between">
        <span qaid="order-totals-total-tax-label">
          {{ isEstimatedTaxShowing ? $t('text.order.estimatedTax') : $t('text.order.vat') }}
        </span>
        <span qaid="order-totals-total-tax-value">
          {{ isCalculatedTaxVisible ? totalTax : '-' }}
        </span>
      </div>
      <alabama-tax-icon-dialog v-if="isCalculatedTaxEnabled && isAlabama" />
    </div>
    <div class="flex justify-between pb-1 font-bold text-lg">
      <span qaid="order-totals-total-label">{{ $t('text.order.total') }}</span>
      <span qaid="order-totals-total-value">{{ isCalculatedTaxVisible ? totalPriceWithTax : totalPrice }}</span>
    </div>
    <div v-if="isVatEnabled" class="text-md">
      <div class="flex justify-between">
        <span qaid="order-totals-net-total-label">{{ $t('basket.page.totals.netTotal') }}</span>
        <span qaid="order-totals-net-total-value">{{ orderSubtotal }}</span>
      </div>
      <div class="flex justify-between">
        <span qaid="order-totals-vat-label">{{ $t('basket.page.totals.estimatedVAT') }}</span>
        <span qaid="order-totals-vat-value">{{ totalTax }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import AlabamaTaxIconDialog from '@/components/cart/AlabamaTaxIconDialog.vue';
import {useCartSummary, useOrderSummary} from '@/composables/checkout-summary.js';

export default {
  components: {AlabamaTaxIconDialog},
  props: {
    cartData: {
      type: Object,
      required: true,
    },
    isCalculatedTaxVisible: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const {
      subtotalPrice,
      isDeliveryModeSet,
      deliveryModeName,
      shippingGrossPrice,
      isCalculatedTaxEnabled,
      isEstimatedTaxShowing,
      isAlabama,
      hasDiscounts,
      discountLabel,
      discountPrice,
      totalPrice,
      totalPriceWithTax,
      isVatEnabled,
      totalTax,
    } = useCartSummary(props.cartData);
    const {orderSubtotal} = useOrderSummary(props.cartData);

    return {
      subtotalPrice,
      isDeliveryModeSet,
      deliveryModeName,
      shippingGrossPrice,
      isCalculatedTaxEnabled,
      isEstimatedTaxShowing,
      isAlabama,
      hasDiscounts,
      discountLabel,
      discountPrice,
      totalPrice,
      totalPriceWithTax,
      isVatEnabled,
      totalTax,
      orderSubtotal,
    };
  },
};
</script>
