import {OrderHistoryDetailsApi} from '@/api/order-history-details';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import extend from 'lodash/extend';
import {ProductDeliveryStatus} from '@/constants/product';

const shippingType = 'SHIPPING';
const bikeType = 'BIKES';
const accessoriesType = 'ACCESSORIES';
const miscType = 'MISCELLANEOUS';
const groupingType = 'groupingType';

const LoadOrderDetails = {
  data() {
    return {
      loading: true,
      orderData: {},
      errorMessage: '',
      tabs: [
        {
          id: 'tab-bikes',
          label: this.textBikes,
          key: bikeType,
        },
        {
          id: 'tab-accessories',
          label: this.textAccessories,
          key: accessoriesType,
        },
        {
          id: 'tab-miscellaneous',
          label: this.textNonStock,
          key: miscType,
        },
      ],
      sections: [
        {
          id: 'backordered-allocated',
          label: this.textAllocatedItems,
          key: ProductDeliveryStatus.ALLOCATED,
        },
        {
          id: 'backordered-notAllocated',
          label: this.textBackorderedItems,
          key: ProductDeliveryStatus.BACKORDERED,
        },
        {
          id: 'backordered-allocated',
          label: ProductDeliveryStatus.SHIPPED,
          key: ProductDeliveryStatus.SHIPPED,
        },
        {
          id: 'backordered-notShipped',
          label: ProductDeliveryStatus.NOT_SHIPPED,
          key: ProductDeliveryStatus.NOT_SHIPPED,
        },
      ],
    };
  },
  methods: {
    async loadData(url) {
      try {
        this.loading = true;
        const results = await OrderHistoryDetailsApi.getOrderHistoryDetailsData(url);
        this.orderData = results;
        this.groupData();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.loading = false;
      }
    },
    groupData() {
      let grouped = this.groupByLineType(this.orderData.entries, (entry) => entry.lineType);
      forEach(grouped, function (value, key) {
        grouped[key] = groupBy(value, groupingType);
      });

      extend(this.orderData, grouped);
    },

    groupByLineType(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        let key = keyGetter(item);
        let collection = map.get(key);
        if (key.toUpperCase() == shippingType.toUpperCase()) {
          key = miscType;
          collection = map.get(miscType);
        }
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return Array.from(map).reduce((obj, [key, value]) => Object.assign(obj, {[key]: value}), {});
    },
  },
};

export default LoadOrderDetails;
