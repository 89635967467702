<template>
  <main class="search-container search-results grid-container fluid">
    <section class="grid-x grid-margin-x not-empty-search-results hidden">
      <h1 class="cell search-results__search-term header--lg my-3" qaid="search-term">
        {{ $t(`search.page.searchText`, [searchTerm]) }}
      </h1>

      <ul id="search-results__tabs" class="tabs cell" data-tabs>
        <li id="search-results__products-tab" class="tabs-title is-active">
          <a data-tabs-target="search-results__products" href="#search-results__products" aria-selected="true">
            {{ $t(`search.page.tab.product`) }}
          </a>
        </li>
        <li v-if="isArchiveEnabled" id="search-results__archive-tab" class="tabs-title">
          <a data-tabs-target="search-results__archive" href="#search-results__archive">
            {{ $t(`productArchive.archivedProducts`) }}
          </a>
        </li>
        <li id="search-results__content-tab" class="tabs-title">
          <a data-tabs-target="search-results__content" href="#search-results__content">
            {{ $t(`search.page.tab.content`) }}
          </a>
        </li>
      </ul>

      <!-- CMS default components -->
      <slot />
      <section class="empty-search-results"></section>
    </section>
  </main>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'SearchContainer',
  props: {
    searchTerm: {
      type: String,
      default: '',
    },
    isArchiveEnabled: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.updateLastPlp();
  },

  methods: {
    ...mapActions('storage', ['updateLastPlp']),
  },
};
</script>
