<template>
  <div class="dropship-capacity-indicator-container relative">
    <template v-if="isAddButton">
      <button
        ref="config_tooltip"
        v-tippy="containerConfigTippyConfig"
        class="dropship-capacity-indicator dropship-capacity-indicator--add text-sm text-gray-60"
        qaid="dropshipIndicatorAddButton"
        @mouseup.prevent="$emit('add-container')"
        @hidden="onConfigHidden"
      >
        {{ $t('distributor.B2B.container.add') }}
        <pdl-icon class="ml-25" size="18" name="add_circle_outline" />
      </button>

      <!-- Container configuration tooltip -->
      <div v-if="showTooltipConfig" :id="containerConfigId" qaid="containerConfigTooltip">
        <container-configuration
          ref="containerConfiguration"
          class="m-2"
          :drop-ship-container="dropShipContainer"
          @close="closeTooltip"
        />
      </div>
    </template>
    <template v-else-if="isLimitWarning">
      <div class="dropship-capacity-indicator dropship-capacity-indicator--max text-xs align-middle">
        {{ $t('distributor.B2B.container.max') }}
      </div>
    </template>
    <template v-else>
      <div class="dropship-capacity-indicator" :class="containerClass">
        <div v-for="index in 5" :key="index" class="dropship-bar" :class="dropshipBarClass(index)"></div>
      </div>
      <div v-if="container.label" class="text-secondary text-left mt-1" v-html="container.label"></div>
      <trek-button
        v-if="displayDeleteButton"
        text
        icon-only
        icon-alert
        icon="cancel"
        class="dropship-capacity-indicator-container--remove-container"
        qaid="dropshipCapacity-removeButton"
        aria-label="Delete container"
        @click="setContainerToDelete(containerSize)"
      >
      </trek-button>
    </template>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';
import TrekButton from '@/components/TrekButton';
import ContainerConfiguration from '@/components/containers/drop-ship/ContainerConfiguration';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'ContainerIndicator',

  components: {TrekButton, ContainerConfiguration, PdlIcon},

  props: {
    container: {
      type: Object,
      default: () => null,
    },
    dropShipContainer: {
      type: Object,
      default: () => null,
    },
    totalContainersAmount: {
      type: Number,
      required: false,
      default: 0,
    },
    isAddButton: Boolean,
    isLimitWarning: Boolean,
    showTooltipConfig: Boolean,
    showDeleteButton: Boolean,
    isMiniCart: Boolean,
  },

  data() {
    return {
      dialogVisible: false,
    };
  },

  computed: {
    fillPercentage() {
      return parseInt((this.container.ownConsumedVolume / this.container.volume) * 100) || 0;
    },

    containerClass() {
      if (this.fillPercentage === 100) return 'dropship-capacity-indicator--full';
      else if (this.fillPercentage > 0) return ' dropship-capacity-indicator--partial';
      return null;
    },

    containerSize() {
      if (this.container?.type === 'TWENTY_FEET') return '20';
      if (this.container?.type === 'FORTY_FEET') return '40';
      if (this.container?.type === 'FORTY_HI_FEET') return '40HC';
      return null;
    },

    displayDeleteButton() {
      return this.showDeleteButton && this.fillPercentage === 0 && this.totalContainersAmount > 1;
    },
    containerConfigTippyConfig() {
      return {
        html: `#${this.containerConfigId}`,
        theme: 'light',
        trigger: 'click',
        reactive: true,
        interactive: true,
      };
    },
    containerConfigId() {
      return `container-config-tooltip${this.isMiniCart ? '-mini-cart' : ''}`;
    },
  },

  methods: {
    dropshipBarClass(index) {
      // If container is empty return nothing (default is empty styling)
      if (this.fillPercentage === 0) return '';

      // If container is full then return full styling
      if (this.fillPercentage === 100) return 'dropship-bar--full';

      // If we're anywhere between 1 and 40 percent then the first bar
      // is partial styling. The middle bars fill when the percentage threshold is met.
      // The last bar will never be partial. It simply fills to 'full' styling when the fill
      // is at 100%
      if (
        (index === 1 && this.fillPercentage > 0) ||
        (index === 2 && this.fillPercentage >= 40) ||
        (index === 3 && this.fillPercentage >= 60) ||
        (index === 4 && this.fillPercentage >= 80)
      )
        return 'dropship-bar--partial';
    },

    closeTooltip() {
      let el = this.$refs['config_tooltip'];

      if (el && el._tippy) {
        el._tippy.hide();
      }
    },

    onConfigHidden() {
      if (this.$refs.containerConfiguration) this.$refs.containerConfiguration.resetState();
    },

    ...mapMutations('backend', ['setContainerToDelete']),
  },
};
</script>
