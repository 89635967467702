<template>
  <div qaid="dropShipOrdersContainer">
    <!-- MiniCart Header -->
    <div v-if="isMiniCart" class="flex justify-between">
      <pdl-heading
        id="miniCart__callout--empty"
        class="mb-3"
        :level="1"
        size="sm"
        qaid="dropShipOrdersContainer--header"
      >
        {{ $t('distributor.B2B.container.your') }}
        <span class="text-md text-secondary ml-1">{{ fullCountText }}</span>
      </pdl-heading>
      <div v-if="volumeHTML" class="text-md text-secondary mb-3">{{ volCountText }}</div>
    </div>

    <!-- Cart Header -->
    <pdl-section-header v-else :is-dividing="true" size="xl">
      <template slot="content">
        <div class="flex items-end">
          <pdl-heading :level="1" qaid="dropShipOrdersContainer--header">
            {{ $t('distributor.B2B.container.your') }}
          </pdl-heading>
          <span class="heading--xl text-md text-secondary ml-3">{{ fullCountText }}</span>
          <span v-if="volumeHTML" class="flex content-end heading--xl text-md text-secondary ml-2">{{
            volCountText
          }}</span>
          <pdl-icon v-tippy="warningTippyConfig" name="info" size="18" class="text-black ml-25 pt-px cursor-pointer" />
        </div>

        <!-- Calculation Warning tooltip -->
        <div id="warning-tooltip" v-tippy-html qaid="warningTooltip" class="text-left">
          {{ $t('distributor.B2B.container.dimension') }}
        </div>
      </template>
    </pdl-section-header>

    <!-- Containers -->
    <template v-if="isMiniCart">
      <!-- Mini Cart Paginated containers -->
      <div class="flex flex-wrap" qaid="dropShipOrdersContainer--containers">
        <ContainerIndicator
          v-for="(indicator, index) in paginatedData"
          :key="index"
          class="mr-1 mb-2"
          :container="indicator.container"
          :drop-ship-container="dropShipContainer"
          :total-containers-amount="totalContainersAmount"
          :is-add-button="indicator.isAddButton"
          :is-limit-warning="indicator.isLimitWarning"
          :show-delete-button="true"
          qaid="dropShipOrdersContainer--containerIndicator"
          :is-mini-cart="true"
          @add-container="$emit('add-container')"
        />
      </div>

      <div v-if="pageCount > 1" class="flex justify-center mb-3">
        <pagination-controls
          ref="pagination"
          :page-number="pageNumber"
          :page-count="pageCount"
          @page-change="pageChange"
          @prev-page="prevPage"
          @next-page="nextPage"
        />
      </div>
    </template>
    <template v-else>
      <!-- Cart non-paginated containers -->
      <div class="flex flex-wrap mb-3" qaid="dropShipOrdersContainer--containers">
        <ContainerIndicator
          v-for="(indicator, index) in listData"
          :key="`${index}-${indicator.container ? indicator.container.type : ''}`"
          class="mr-2 mb-2"
          :container="indicator.container"
          :drop-ship-container="dropShipContainer"
          :total-containers-amount="totalContainersAmount"
          :is-add-button="indicator.isAddButton"
          :is-limit-warning="indicator.isLimitWarning"
          :show-tooltip-config="true"
          :show-delete-button="!isCheckout"
          qaid="dropShipOrdersContainer--containerIndicator"
        />
      </div>
    </template>

    <p v-if="isCheckout && !isPrint">
      {{ $t('distributor.B2B.container.adjust.message') }}
      <a :href="`${encodedContextPath}/cart`">{{ $t('distributor.B2B.container.adjust.cta') }}</a>
    </p>

    <div class="mt-3">
      <pdl-callout v-if="!isMiniCart && dropShipContainer && dropShipContainer.vendorNotificationText" kind="warning">
        <template #content>
          <span v-html="dropShipContainer.vendorNotificationText" />
        </template>
      </pdl-callout>
    </div>
  </div>
</template>

<script>
import pagination from '../../../mixins/pagination';
import {mapState} from 'vuex';
import ContainerIndicator from '@/components/containers/drop-ship/ContainerIndicator';
import PaginationControls from '@/components/PaginationControls';
import {PdlCallout} from '@pedal/pdl-callout';
import reduce from 'lodash/reduce';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {
    PdlSectionHeader,
    PdlHeading,
    ContainerIndicator,
    PaginationControls,
    PdlCallout,
    PdlIcon,
  },

  mixins: [pagination],

  props: {
    dropShipContainer: {
      type: Object,
      default: () => null,
    },
    isMiniCart: Boolean,
    isCheckout: Boolean,
    isPrint: Boolean,
    size: {
      type: Number,
      default: 4,
    },
  },

  data() {
    return {
      maxContainers: 15,
      warningTippyConfig: {
        html: '#warning-tooltip',
        theme: 'light',
        trigger: 'mouseenter',
        maxWidth: '20rem',
        reactive: true,
      },
    };
  },

  computed: {
    listData() {
      let result = [];

      if (this.dropShipContainer) {
        this.dropShipContainer.containers?.forEach((container) => {
          let containersQuantity = 0;
          while (containersQuantity < container.totals.amount) {
            let ownConsumedVolume =
              Math.floor(container.totals.consumedVolume / container.volume) > containersQuantity
                ? container.volume
                : container.totals.consumedVolume - container.volume * containersQuantity;
            result.push({
              container: {...container, ownConsumedVolume: ownConsumedVolume > 0 ? ownConsumedVolume : 0},
              isAddButton: false,
              isLimitWarning: false,
            });
            containersQuantity++;
          }
        });

        if (!this.isCheckout && this.totalContainersAmount < this.maxContainers) {
          result.push({container: null, isAddButton: true, isLimitWarning: false});
        } else if (!this.isCheckout && this.totalContainersAmount >= this.maxContainers) {
          result.push({container: null, isAddButton: false, isLimitWarning: true});
        }
      }

      return result;
    },

    totalFullContainersAmount() {
      return reduce(
        this.dropShipContainer.containers,
        (totalAmount, container) =>
          container.volume > 0
            ? totalAmount + Math.floor(container.totals.consumedVolume / container.volume)
            : totalAmount,
        0
      );
    },

    volumeHTML() {
      return this.dropShipContainer?.consumedVolume && this.dropShipContainer?.totalVolume;
    },

    fullCountText() {
      return this.$t('distributor.B2B.container.fullCount', [
        this.totalFullContainersAmount,
        this.totalContainersAmount,
      ]);
    },

    volCountText() {
      return this.$t('distributor.B2B.container.volCount', [
        parseFloat(Math.ceil(this.dropShipContainer.consumedVolume * 100) / 100).toFixed(2),
        parseFloat(Math.floor(this.dropShipContainer.totalVolume * 100) / 100).toFixed(2),
      ]);
    },

    totalContainersAmount() {
      return (
        this.dropShipContainer.containers.reduce(
          (totalAmount, container) => (totalAmount += container.totals.amount),
          0
        ) || null
      );
    },

    ...mapState('backend', ['encodedContextPath']),
  },
};
</script>
