import get from 'lodash/get';

/**
 * Get product's weight.
 * @param product
 * @param fieldName
 * @return {null|*}
 */
const getProductWeight = (product, fieldName) => {
  if (!product || !product.weights) {
    return null;
  }

  const weight = product.weights.find((item) => item.code === fieldName);
  if (!weight) {
    return null;
  }

  return weight.weight;
};

/**
 * Get weights.
 * @param productA
 * @param productB
 * @param sortBy
 * @return {{a: null, b: null}|null}
 */
const getWeights = (productA, productB, sortBy) => {
  const a = getProductWeight(productA, sortBy);
  const b = getProductWeight(productB, sortBy);

  if (!a || !b) {
    return null;
  }

  return {a, b};
};

const SortedProducts = {
  methods: {
    getSortedProducts(products) {
      let productsClone = [...products];
      const categories = this.getSortableCategories(productsClone);
      if (!categories || !categories.length) {
        return productsClone;
      }

      return this.getSortedProductsByCategories(productsClone, categories);
    },

    /**
     * Get an array of products sorted by given list of keys.
     * @param products
     * @param sortByFields
     * @return {*}
     */
    getSortedProductsByCategories(products, sortByFields) {
      if (!products || !products.length || !sortByFields || !sortByFields.length) {
        return products;
      }

      return products.sort((a, b) => {
        for (const sortBy of sortByFields) {
          const weights = getWeights(a, b, sortBy);
          if (!weights || weights.a === weights.b) {
            continue;
          }

          return weights.a - weights.b;
        }
      });
    },

    /**
     * Get an array of categories we can sort by.
     * @param products
     * @return {[]|*[]}
     */
    getSortableCategories(products) {
      const categories = get(products, '[0].variantValueCategories[0]');
      if (!categories) {
        return [];
      }

      const sortableCategories = [];
      for (let categoryId in categories) {
        if (typeof categories[categoryId] !== 'object') {
          sortableCategories.push(categoryId);
        }
      }

      return sortableCategories;
    },
  },
};

export {SortedProducts};
