<template>
  <pdl-icon class="norton-secured-icon" size="48">
    <svg
      id="norton-secure-icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 300 1000 500"
      xml:space="preserve"
    >
      <g>
        <path
          fill="#FFFFFF"
          d="M246,350.2c-68.7,0-124.6,55.9-124.6,124.6c0,68.7,55.9,124.6,124.6,124.6h519.3   c68.7,0,124.6-55.9,124.6-124.6c0-68.7-55.9-124.6-124.6-124.6H246z"
        />
        <path
          fill="#C7C8CA"
          d="M765.3,610H246c-74.5,0-135.2-60.7-135.2-135.2S171.5,339.6,246,339.6h519.3c74.5,0,135.2,60.7,135.2,135.2   S839.8,610,765.3,610 M246,350.2c-68.7,0-124.6,55.9-124.6,124.6c0,68.7,55.9,124.6,124.6,124.6h519.3   c68.7,0,124.6-55.9,124.6-124.6c0-68.7-55.9-124.6-124.6-124.6H246z"
        />
        <path
          d="M703,399.1c-20.2,0-36.5,16.3-36.5,36.5c0,20.2,16.3,36.5,36.5,36.5c20.2,0,36.5-16.3,36.5-36.5   C739.5,415.4,723.2,399.1,703,399.1 M703,454.3c-10.3,0-18.7-8.4-18.7-18.7c0-10.4,8.4-18.7,18.7-18.7c10.3,0,18.7,8.4,18.7,18.7   C721.7,446,713.3,454.3,703,454.3"
        />
        <path
          d="M535.6,399.1c-20.2,0-36.5,16.3-36.5,36.5c0,20.2,16.3,36.5,36.5,36.5c20.2,0,36.5-16.3,36.5-36.5   C572.1,415.4,555.7,399.1,535.6,399.1 M535.6,454.3c-10.3,0-18.7-8.4-18.7-18.7c0-10.4,8.4-18.7,18.7-18.7   c10.4,0,18.7,8.4,18.7,18.7C554.3,446,545.9,454.3,535.6,454.3"
        />
        <path
          d="M664.2,455.4c-2.3,0.7-5.8,1.3-7.2,1.3c-6.2,0-7.6-2.2-7.6-8.1v-33.7h14.9v-14.1h-14.9v-19.9h-16.8l-3.5,19.9h-11.7v0h-1   c-9.6,0-16.8,5.9-21.4,15l-5.8-15h-12v70.1h20.6v-30.5c0-15.4,9.1-25.2,19.8-25.5h11.3v34.8c0,16.4,8.4,22.5,22.1,22.5   c7.1,0,12.5-1.5,15.7-2.6L664.2,455.4z"
        />
        <polyline points="764.5,422.9 764.5,470.8 744.4,470.8 744.4,400.7 756.6,400.7 760.7,411.4  " />
        <path
          d="M760.6,411.5c6.1-7.1,14.4-12.4,24.2-12.4c16.7,0,23.5,13.3,23.5,25.6v46.1h-20.6v-45.5c0-6-5-9.3-10.4-9.3   c-5.8,0-9.5,3.3-12.7,6.8"
        />
        <polygon
          points="418.8,375.4 441.7,375.4 473.2,435.4 473.2,375.4 492.2,375.4 492.2,470.8 471.4,470.8 437.8,404.8 437.8,470.8    418.8,470.8  "
        />
        <path
          fill="#A7A9AC"
          d="M413,555.4l4.8-7.4c3.9,2.9,9.7,5.5,16.8,5.5c6.9,0,11.1-3.1,11.1-8.5c0-6.2-5.4-7.9-14.2-10.9   c-7.9-2.8-15.7-6.8-15.7-18c0-10.5,8.3-16.2,19.4-16.2c7.4,0,12.9,1.5,17.3,4l-3.8,7.9c-3.7-1.9-8.1-3.2-13.3-3.2   c-6.2,0-9.9,2.3-9.9,7.3c0,5.8,4.3,7.2,12.4,9.9c8.5,2.7,17.8,6.7,17.8,19.1c0,10.5-7.5,17.4-21,17.4   C424.7,562.2,417.4,558.7,413,555.4"
        />
        <polygon
          fill="#A7A9AC"
          points="473.7,500.8 509.8,500.8 509.8,509.1 484,509.1 484,525.7 504.4,525.7 504.4,533.9 484,533.9 484,553    511.4,553 511.4,561.4 473.7,561.4  "
        />
        <path
          fill="#A7A9AC"
          d="M526.1,531.1c0-17.7,8.5-31.3,25.4-31.3c7.6,0,13.6,2.4,17.8,7.3l-6,6.9c-3.6-3.5-6.7-5.3-11.6-5.3   c-9.9,0-14.5,8.1-14.5,23.1c0,14.1,4.8,21.2,14.6,21.2c5.4,0,9.2-2.8,12.6-6.4l6,6.7c-4.4,5.2-9.9,8.7-18.7,8.7   C534.2,562.2,526.1,548.9,526.1,531.1"
        />
        <path
          fill="#A7A9AC"
          d="M587,538.2v-37.4h10.3v39.1c0,8.3,2.1,13.8,10.9,13.8c8.7,0,10.9-5.5,10.9-13.8v-39.1h10.4v37.4   c0,15-5.8,24-21.4,24C592.8,562.2,587,553.2,587,538.2"
        />
        <path
          fill="#A7A9AC"
          d="M692.8,561.4h-11.2L670,535.7h-9.6v25.7h-10.3v-60.6h19.8c12.2,0,21.8,4.4,21.8,17.7c0,9.1-5.4,13.6-11.5,15.8   L692.8,561.4z M669.5,527.8c7.7,0,11.6-2.5,11.6-9.5c0-6.7-4-9.1-11-9.1h-9.7v18.6H669.5z"
        />
        <polygon
          fill="#A7A9AC"
          points="710.4,500.8 746.4,500.8 746.4,509.1 720.6,509.1 720.6,525.7 741,525.7 741,533.9 720.6,533.9    720.6,553 748,553 748,561.4 710.4,561.4  "
        />
        <path
          fill="#A7A9AC"
          d="M765.3,500.8h15.1c19.8,0,29.3,10.7,29.3,30.1c0,19.8-9.7,30.5-28.7,30.5h-15.7V500.8z M779.9,553   c14.2,0,19-6.5,19-22.2c0-13.7-3.8-21.7-17.7-21.7h-5.5V553H779.9z"
        />
        <path
          fill="#FFC20E"
          d="M245.4,620.6c-80.4,0-145.9-65.5-145.9-145.9c0-80.4,65.5-145.9,145.9-145.9c80.4,0,145.9,65.4,145.9,145.9   C391.2,555.2,325.8,620.6,245.4,620.6 M245.4,375c-26.6,0-51.7,10.4-70.5,29.2c-18.8,18.8-29.2,43.9-29.2,70.5   c0,26.6,10.4,51.7,29.2,70.5c18.8,18.8,43.9,29.2,70.5,29.2c26.6,0,51.7-10.4,70.5-29.2c18.8-18.8,29.2-43.9,29.2-70.5   c0-26.6-10.4-51.7-29.2-70.5C297,385.4,272,375,245.4,375"
        />
        <rect x="404.3" y="306.4" fill="#FFC20E" width="11.4" height="11.4" />
        <rect x="404.3" y="283.7" fill="#FFC20E" width="11.4" height="11.4" />
        <rect x="372.3" y="317.7" fill="#FFC20E" width="10.6" height="11.1" />
        <rect x="351.2" y="339.5" width="10.7" height="10.6" />
        <rect x="382.9" y="328.8" width="11.9" height="10.7" />
        <rect x="340.6" y="329.3" width="10.7" height="10.2" />
        <rect x="372.3" y="339.5" width="10.6" height="10.6" />
        <rect x="393" y="295.1" width="11.3" height="11.3" />
        <rect x="372.3" y="306.4" width="20.7" height="11.3" />
        <rect x="361.9" y="317.7" width="10.3" height="21.8" />
        <path
          d="M361.9,350.1v10.7h-10.7v-10.7h-17.8v7.7h-9.5v9.5h10.6V378h-10.6v-10.6h-11.3V384h-10.7v10.6h-7.1v10.7H287   c-8.8,13-30.7,53.7-37.4,70.2c-23.9-33.3-42.5-46.5-57.1-51.3c-7.9-2.4-17.4,6.2-4.3,19.7c30.1,33.1,39.6,60.5,48.7,82.4   c4.8,11.5,26.5,13.3,30.9,1.6c9.3-24.8,22.7-52.7,38.6-75.6v-11.9h8.5v-10.7h8.9v-12.4h10.7v-12h-10.8v-10.9h10.8v10.9h10.6l0-10.8   h8.5v-13h9.5v-8.9h9.2v-22.5H361.9z"
        />
        <path
          fill="#A7A9AC"
          d="M156.2,670.6h8v5.8c2.6-3.6,6.2-6.5,11.8-6.5c8.2,0,16.2,6.5,16.2,18.1v0.1c0,11.6-7.9,18.1-16.2,18.1   c-5.7,0-9.4-2.9-11.8-6.1v16h-8V670.6z M184.1,688.1V688c0-6.7-4.6-11.2-10-11.2c-5.4,0-10.2,4.5-10.2,11.2v0.1   c0,6.7,4.8,11.2,10.2,11.2C179.6,699.3,184.1,695,184.1,688.1"
        />
        <path
          fill="#A7A9AC"
          d="M198.7,688.3v-0.1c0-10,7.9-18.2,18.6-18.2s18.5,8.1,18.5,18.1v0.1c0,9.9-7.9,18.2-18.7,18.2   C206.6,706.3,198.7,698.2,198.7,688.3 M227.9,688.3v-0.1c0-6.1-4.4-11.2-10.7-11.2c-6.4,0-10.5,5-10.5,11.1v0.1   c0,6.1,4.4,11.2,10.6,11.2C223.8,699.4,227.9,694.3,227.9,688.3"
        />
        <polygon
          fill="#A7A9AC"
          points="239.7,670.6 247.9,670.6 254.9,694.5 262.6,670.5 269.4,670.5 277.2,694.5 284.3,670.6 292.3,670.6    280.9,705.8 273.8,705.8 266,681.9 258.1,705.8 251,705.8  "
        />
        <path
          fill="#A7A9AC"
          d="M296.4,688.2v-0.1c0-10,7.1-18.2,17-18.2c11.1,0,16.7,8.7,16.7,18.8c0,0.7-0.1,1.4-0.1,2.2h-25.6   c0.9,5.7,4.9,8.8,10,8.8c3.9,0,6.7-1.4,9.4-4.2l4.7,4.2c-3.3,4-7.9,6.5-14.3,6.5C304.2,706.3,296.4,699,296.4,688.2 M322.2,685.6   c-0.5-5.1-3.6-9.2-8.8-9.2c-4.9,0-8.3,3.8-9,9.2H322.2z"
        />
        <path
          fill="#A7A9AC"
          d="M338.5,670.6h8v7.9c2.2-5.2,6.2-8.8,12.3-8.5v8.5h-0.5c-7,0-11.9,4.6-11.9,13.8v13.3h-8V670.6z"
        />
        <path
          fill="#A7A9AC"
          d="M363.4,688.2v-0.1c0-10,7.1-18.2,17-18.2c11.1,0,16.7,8.7,16.7,18.8c0,0.7-0.1,1.4-0.1,2.2h-25.6   c0.9,5.7,4.9,8.8,10,8.8c3.9,0,6.7-1.4,9.4-4.2l4.7,4.2c-3.3,4-7.9,6.5-14.3,6.5C371.2,706.3,363.4,699,363.4,688.2 M389.2,685.6   c-0.5-5.1-3.6-9.2-8.8-9.2c-4.9,0-8.3,3.8-9,9.2H389.2z"
        />
        <path
          fill="#A7A9AC"
          d="M403.7,688.1V688c0-11.5,7.9-18.1,16.2-18.1c5.7,0,9.4,2.9,11.9,6.1v-18.7h8v48.2h-8v-5.8   c-2.6,3.6-6.2,6.5-11.9,6.5C411.6,706.2,403.7,699.7,403.7,688.1 M431.8,688.1V688c0-6.7-4.8-11.2-10.1-11.2c-5.5,0-10,4.2-10,11.2   v0.1c0,6.7,4.6,11.2,10,11.2C427.1,699.3,431.8,694.8,431.8,688.1"
        />
        <path
          fill="#A7A9AC"
          d="M477.4,700.1v5.4h-8v-48.2h8v19.1c2.6-3.6,6.2-6.5,11.8-6.5c8.2,0,16.2,6.5,16.2,18.1v0.1   c0,11.6-7.9,18.1-16.2,18.1C483.5,706.2,479.9,703.3,477.4,700.1 M497.3,688.1V688c0-6.7-4.6-11.2-10-11.2   c-5.4,0-10.2,4.5-10.2,11.2v0.1c0,6.7,4.8,11.2,10.2,11.2C492.9,699.3,497.3,695,497.3,688.1"
        />
        <path
          fill="#A7A9AC"
          d="M510.9,714.1l2.7-5.9c1.5,0.8,3,1.3,4.7,1.3c2.2,0,3.6-1,4.9-4l-14.5-34.9h8.5l9.9,25.9l9-25.9h8.3l-13.9,35.8   c-2.8,7.1-5.9,9.8-11.5,9.8C515.8,716.3,513.4,715.5,510.9,714.1"
        />
        <polygon
          fill="#A30046"
          points="568.1,659.3 577.1,659.3 591,694.8 604.9,659.3 613.6,659.3 594.4,705.8 587.3,705.8  "
        />
        <path
          fill="#A30046"
          d="M615.3,688.2v-0.1c0-10,7.1-18.2,17-18.2c11.1,0,16.7,8.7,16.7,18.8c0,0.7-0.1,1.4-0.1,2.2h-25.6   c0.9,5.7,4.9,8.8,10,8.8c3.9,0,6.7-1.4,9.4-4.2l4.7,4.2c-3.3,4-7.9,6.5-14.3,6.5C623.1,706.3,615.3,699,615.3,688.2 M641.1,685.6   c-0.5-5.1-3.6-9.2-8.8-9.2c-4.9,0-8.3,3.8-9,9.2H641.1z"
        />
        <path
          fill="#A30046"
          d="M657.3,670.6h8v7.9c2.2-5.2,6.2-8.8,12.3-8.5v8.5h-0.5c-7,0-11.9,4.6-11.9,13.8v13.3h-8V670.6z"
        />
        <path fill="#A30046" d="M684.2,657.7h8.6v7.6h-8.6V657.7z M684.5,670.6h8v34.9h-8V670.6z" />
        <path
          fill="#A30046"
          d="M700.8,698.8l4.9-5.8c4.4,3.8,8.8,6,14.6,6c5,0,8.2-2.3,8.2-5.8V693c0-3.3-1.8-5.1-10.4-7.1   c-9.8-2.4-15.4-5.3-15.4-13.8V672c0-7.9,6.6-13.4,15.8-13.4c6.7,0,12.1,2,16.8,5.8l-4.4,6.1c-4.2-3.1-8.3-4.8-12.5-4.8   c-4.8,0-7.5,2.4-7.5,5.5v0.1c0,3.6,2.1,5.1,11,7.3c9.8,2.4,14.8,5.9,14.8,13.5v0.1c0,8.7-6.8,13.8-16.5,13.8   C713,706.1,706.3,703.7,700.8,698.8"
        />
        <path fill="#A30046" d="M745.4,657.7h8.6v7.6h-8.6V657.7z M745.7,670.6h8v34.9h-8V670.6z" />
        <path
          fill="#A30046"
          d="M763.7,712l3-6c3.7,2.3,7.7,3.7,12.4,3.7c7.1,0,11-3.7,11-10.7v-2.7c-2.9,3.8-6.5,6.4-12.3,6.4   c-8.2,0-15.8-6.1-15.8-16.3v-0.1c0-10.3,7.7-16.4,15.8-16.4c5.9,0,9.5,2.7,12.2,5.9v-5.2h8v27.7c0,5.9-1.5,10.2-4.4,13.1   c-3.2,3.2-8.1,4.7-14.3,4.7C773.6,716.2,768.2,714.7,763.7,712 M790.2,686.4v-0.1c0-5.7-4.7-9.6-10.2-9.6c-5.5,0-9.9,3.8-9.9,9.6   v0.1c0,5.7,4.4,9.6,9.9,9.6C785.5,696,790.2,692,790.2,686.4"
        />
        <path
          fill="#A30046"
          d="M808.3,670.6h8v5.4c2.2-3.3,5.5-6.1,10.9-6.1c7.9,0,12.4,5.3,12.4,13.4v22.2h-8v-19.8c0-5.4-2.7-8.5-7.5-8.5   c-4.6,0-7.9,3.2-7.9,8.6v19.7h-8V670.6z"
        />
      </g>
    </svg>
  </pdl-icon>
</template>
<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {PdlIcon},
};
</script>
