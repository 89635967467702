'use strict';

export default function () {
  $('.menu-item').on('click', function () {
    $('#secondary-nav').foundation('toggle');
  });

  var currentActiveElement = $('.active').attr('href');
  $(document).on('update.zf.magellan', function (e, params) {
    if ($(params).attr('href') != currentActiveElement) {
      currentActiveElement = $(params).attr('href');
      $('.contains-active').removeClass('contains-active');
      $(params).parent().addClass('contains-active');
    }
  });
}
