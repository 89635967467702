<template>
  <pdl-dialog
    :visible.sync="dialogVisible"
    :title="title"
    :z-index="16000011"
    :close-dialog-tool-tip="$t('popup.close')"
    qaid="advanced-ordering-dialog"
    @close="close"
  >
    <div>
      <p class="text-left mb-3" qaid="modalHelperText">
        {{ body }}
      </p>
      <div class="b2b-scroll-list-container w-full">
        <div class="b2b-scroll-list">
          <table class="b2b-grid">
            <caption class="sr-only">
              {{
                $t('order.orderItems')
              }}
            </caption>
            <thead class="b2b-grid__header">
              <tr>
                <th class="b2b-grid__cell b2b-grid__head" qaid="skuColumn">
                  {{ $t('distributor.B2B.advancedOrder.sku') }}
                </th>
                <th class="b2b-grid__cell b2b-grid__head" qaid="descColumn">
                  {{ $t('distributor.B2B.advancedOrder.name') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in nonAOItems" :key="index" :qaid="`dataRow${index}`">
                <td class="b2b-grid__cell table-cell">
                  {{ item.sku }}
                </td>
                <td class="b2b-grid__cell table-cell">
                  {{ item.itemName }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <template slot="footer">
      <div class="buttons buttons--right">
        <trek-button v-if="!displayAddErrorModal" primary @click="redirectToCart">
          {{ $t('distributor.B2B.advancedOrder.cart') }}
        </trek-button>
        <trek-button secondary qaid="backButton" @click="close">
          {{ $t('navigation.back') }}
        </trek-button>
      </div>
    </template>
  </pdl-dialog>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import TrekButton from '@/components/TrekButton';
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlToastType} from '@/constants/pdl-toast-type';

export default {
  components: {PdlDialog, TrekButton},

  data() {
    return {
      dialogVisible: false,
    };
  },

  computed: {
    title() {
      if (this.displayAddErrorModal) {
        // When the user tries to add a non-AO product to cart in AO mode:
        // Sorry, these SKU(s) cannot be added to your cart.
        return this.$t('distributor.B2B.advancedOrder.error.cannotAdd');
      } else if (this.advancedOrderingMode) {
        // When AO only products are in cart and user tries to toggle AO:
        // Advanced ordering cannot be turned off
        return this.$t('distributor.B2B.advancedOrder.off.cannot');
      } else {
        // When the user has non-AO products in cart and tries to toggle AO switch:
        // Advanced ordering cannot be turned on
        return this.$t('distributor.B2B.advancedOrder.title');
      }
    },

    body() {
      if (this.displayAddErrorModal) {
        // When the user tries to add a non-AO product to cart in AO mode
        return this.$t('distributor.B2B.advancedOrder.notAvailable');
      } else if (this.advancedOrderingMode) {
        // When AO only products are in cart and user tries to toggle AO
        return this.$t('distributor.B2B.advancedOrder.off.sku');
      } else {
        // When the user has non-AO products in cart and tries to toggle AO switch
        return this.$t('distributor.B2B.advancedOrder.body');
      }
    },

    ...mapState('backend', ['encodedContextPath']),
    ...mapState('advancedOrdering', [
      'nonAOItems',
      'advancedOrderingError',
      'advancedOrderingMode',
      'displayAddErrorModal',
    ]),
  },

  watch: {
    nonAOItems(value) {
      if (value && value.length) this.dialogVisible = true;
      else this.close();
    },

    advancedOrderingError(value) {
      if (value) {
        this.$notify.closeAll();

        this.$notify({
          type: PdlToastType.ERROR,
          message: this.advancedOrderingMode
            ? this.$t('distributor.B2B.advancedOrder.off.unable')
            : this.$t('distributor.B2B.advancedOrder.unable'),
          showClose: true,
          duration: 0,
        });

        this.setAdvancedOrderingError(false);
      }
    },
  },

  methods: {
    redirectToCart() {
      window.location = `${this.encodedContextPath}/cart`;
    },

    close() {
      this.setDisplayAddErrorModal(false);
      this.dialogVisible = false;
    },

    ...mapMutations('advancedOrdering', ['setAdvancedOrderingError', 'setDisplayAddErrorModal']),
  },
};
</script>
