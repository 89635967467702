<template>
  <div>
    <p v-if="hasNotSelectedMode" class="form__global-error alert-icon text-emphasized">
      {{ $t('checkout.multi.deliverymethod.error') }}
    </p>
    <ul
      :class="['form__retailer-options', 'deliveryMethodList', hasNotSelectedMode ? 'errorDiv has-delivery-error' : '']"
    >
      <template v-if="hasModes">
        <li v-for="(mode, index) in deliveryModes" :key="index">
          <delivery-mode-list-item :mode="mode">
            <template #delivery-method-specifics>
              <slot name="delivery-method-specifics" />
            </template>
          </delivery-mode-list-item>
        </li>
      </template>
      <li v-else>{{ $t('checkout.multi.deliveryMethod.noExistingDeliveryMethod') }}</li>
    </ul>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import DeliveryModeListItem from '@/components/checkout/b2c/dealers/DeliveryModeListItem';
import {useVuelidate} from '@vuelidate/core';

export default {
  components: {DeliveryModeListItem},
  setup() {
    return {v$: useVuelidate()};
  },
  computed: {
    ...mapState('checkout', ['deliveryModes']),
    hasModes() {
      return this.deliveryModes?.length > 0;
    },
    hasNotSelectedMode() {
      return !!this.v$.$errors.length;
    },
  },
};
</script>
