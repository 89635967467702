import storefrontInstance from '@/api/instances/storefront';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';

const updateEtaWatch = (payload) => {
  return storefrontInstance
    .post('/my-account/etawatch', payload)
    .then(({data}) => {
      if (checkMetaFeedbackType(data, MetaFeedbackType.SUCCESS)) {
        return {
          etaWatchPk: data.data.etaWatchPk,
        };
      }
    })
    .catch((error) => console.log(error));
};

const deleteEtaWatch = (etaWatchPk) =>
  storefrontInstance.delete(`/my-account/etawatch/${etaWatchPk}`).catch((error) => console.log(error));

const updateComment = (payload) =>
  storefrontInstance
    .post(`/allocated/item/comment/update`, payload)
    .then(({data}) => {
      if (checkMetaFeedbackType(data, MetaFeedbackType.SUCCESS)) {
        return payload.lineComment;
      }
    })
    .catch((error) => {
      console.log(error);
    });

const backorderPrioritize = (payload) =>
  storefrontInstance
    .put('/backorder/entry/prioritize', payload)
    .then(({data}) => {
      if (checkMetaFeedbackType(data, MetaFeedbackType.SUCCESS)) {
        return data;
      }
    })
    .catch((error) => {
      console.log(error);
    });

export default {
  updateEtaWatch,
  deleteEtaWatch,
  updateComment,
  backorderPrioritize,
};
