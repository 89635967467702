<template>
  <div v-if="hasFilters" class="b2b-badges shrink-0">
    <tk-badge
      v-for="filter in filters"
      :key="filter.name"
      :is-dismissible="true"
      :has-logo="filter.hasLogo"
      :badge-text="filter.name"
      class="b2b-badge--secondary-light"
      :qaid="`store-locator__current-filters__${filter.id}`"
      @close="removeFilter(filter)"
    >
      <template v-if="filter.hasLogo" #logo>
        <div v-html="getLogo(filter)"></div>
      </template>
    </tk-badge>
  </div>
</template>
<script>
import TkBadge from '@/components/TkBadge';
import {trekLogo, electraLogo} from '@/components/locator/assets/logos';

export default {
  name: 'LocatorCurrentFiltersList',
  components: {TkBadge},
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      trekLogo,
      electraLogo,
    };
  },
  computed: {
    hasFilters() {
      return this.filters && this.filters.length > 0;
    },
  },
  methods: {
    removeFilter(filter) {
      this.$emit('filter-removed', filter);
    },
    getLogo(filter) {
      return this[`${filter.name}Logo`] || '';
    },
  },
};
</script>
