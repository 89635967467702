<script>
export default {
  props: {},

  data() {
    return {
      productType: 'typebike',
    };
  },
};
</script>
