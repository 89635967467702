<template>
  <div>
    <pdl-tabs :value="defaultTab">
      <pdl-tab-pane :name="defaultTab">
        <span slot="label" qaid="notification-settings-grid-stockwatch-bike-label">
          {{ tabNames.find((item) => item.name === 'bikesStockWatch').translation }}
        </span>
        <notification-settings-stockwatch-grid
          :items="stockWatchBikeData.stockwatches || []"
          :no-item="bikesStockWatchNoItemMessage"
          qaid="notification-settings-grid-stockwatch-bike"
          @update-data-list="fetchStockWatchBikeData"
        />
      </pdl-tab-pane>
      <pdl-tab-pane>
        <span slot="label" qaid="notification-settings-grid-stockwatch-am-label">
          {{ tabNames.find((item) => item.name === 'accessoriesStockWatch').translation }}
        </span>
        <notification-settings-stockwatch-grid
          :items="stockWatchAftermarketData.stockwatches || []"
          :no-item="partsStockWatchNoItemMessage"
          qaid="notification-settings-grid-stockwatch-am"
          @update-data-list="fetchStockWatchAftermarketData"
        />
      </pdl-tab-pane>
      <pdl-tab-pane>
        <span slot="label" qaid="notification-settings-grid-eta-bike-label">
          {{ tabNames.find((item) => item.name === 'bikesEta').translation }}
        </span>
        <notification-settings-eta-grid
          :product-type="productType.Bike"
          :no-items-default-message="noItemsDefaultMessage"
          qaid="notification-settings-grid-eta-bike"
        />
      </pdl-tab-pane>
      <pdl-tab-pane>
        <span slot="label" qaid="notification-settings-grid-eta-am-label">
          {{ tabNames.find((item) => item.name === 'accessoriesEta').translation }}
        </span>
        <notification-settings-eta-grid
          :product-type="productType.Aftermarket"
          :no-items-default-message="noItemsDefaultMessage"
          qaid="notification-settings-grid-eta-am"
        />
      </pdl-tab-pane>
      <pdl-tab-pane>
        <span slot="label" qaid="notification-settings-grid-prefs-label">
          {{ tabNames.find((item) => item.name === 'emailPrefs').translation }}
        </span>
        <notification-settings-email-preferences :columns="emailPrefColumns" :groupings="notificationPrefsData" />
      </pdl-tab-pane>
    </pdl-tabs>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import Vue from 'vue';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import NotificationSettingsEtaGrid from '@/components/containers/my-account/NotificationSettingsEtaGrid';
import NotificationSettingsStockwatchGrid from '@/components/containers/my-account/NotificationSettingsStockwatchGrid';
import NotificationSettingsEmailPreferences from '@/components/containers/notifications/NotificationSettingsEmailPreferences';
import {ProductType} from '@/constants/product';

export default {
  components: {
    PdlTabs,
    PdlTabPane,
    NotificationSettingsEtaGrid,
    NotificationSettingsStockwatchGrid,
    NotificationSettingsEmailPreferences,
  },
  data() {
    return {
      stockWatchBikeData: [],
      stockWatchAftermarketData: [],
      bikeEtaWatchOrderEntries: [],
      aftermarketEtaWatchOrderEntries: [],
      notificationPrefsData: [],
      noItemsDefaultMessage: 'Sorry, you have no watched items to display',
      bikesEtaNoItemMessage: '',
      partsEtaNoItemMessage: '',
      bikesStockWatchNoItemMessage: '',
      partsStockWatchNoItemMessage: '',
      productType: ProductType,
    };
  },
  computed: {
    emailPrefColumns() {
      return [
        {label: this.$t('text.event')},
        {
          label: this.$t('text.online'),
          cssClasses: 'text-right',
        },
        {
          label: this.$t('address.email'),
          cssClasses: 'text-right',
        },
      ];
    },

    defaultTab() {
      return this.tabNames[0].translation;
    },

    tabNames() {
      // Use computed to make BP translation reactive in pdl-tab-pane
      return [
        {
          name: 'bikesStockWatch',
          translation: this.$t('notifications.B2B.bikes.stockWatch'),
        },
        {
          name: 'accessoriesStockWatch',
          translation: this.$t('notifications.B2B.accessories.stockWatch'),
        },
        {
          name: 'bikesEta',
          translation: this.$t('notifications.B2B.bikes.eta'),
        },
        {
          name: 'accessoriesEta',
          translation: this.$t('notifications.B2B.accessories.eta'),
        },
        {
          name: 'emailPrefs',
          translation: this.$t('notifications.B2B.emailPreferences'),
        },
      ];
    },
  },
  mounted() {
    this.fetchStockWatchBikeData();
    this.fetchStockWatchAftermarketData();
    this.fetchNotificationPrefsEmailData();
  },
  methods: {
    /* Should probably put these fetchers in a loop */
    fetchStockWatchBikeData() {
      return storefrontInstance
        .get('/notification-settings/stock-watch?producttype=bike')
        .then(({data: {data, meta}}) => {
          if (data) {
            data?.stockwatches.forEach((element) => {
              Vue.set(element, 'active', false);
            });
            this.stockWatchBikeData = data;
            this.bikesStockWatchNoItemMessage =
              meta.feedback.message && meta.feedback.message != null
                ? meta.feedback.message.basePropertyValue
                : this.noItemsDefaultMessage;
          } else if (data === null && meta != null) {
            this.bikesStockWatchNoItemMessage = meta.feedback.message.basePropertyValue;
          }
        })
        .catch((error) => console.error(error));
    },
    fetchStockWatchAftermarketData() {
      return storefrontInstance
        .get('/notification-settings/stock-watch?producttype=am')
        .then(({data: {data, meta}}) => {
          if (data) {
            data?.stockwatches.forEach((element) => {
              Vue.set(element, 'active', false);
            });
            this.stockWatchAftermarketData = data;
            this.partsStockWatchNoItemMessage =
              meta.feedback.message && meta.feedback.message != null
                ? meta.feedback.message.basePropertyValue
                : this.noItemsDefaultMessage;
          } else if (data === null && meta != null) {
            this.partsStockWatchNoItemMessage = meta.feedback.message.basePropertyValue;
          }
        })
        .catch((error) => console.error(error));
    },
    fetchNotificationPrefsEmailData() {
      return storefrontInstance.get('notification-settings/user-preferences/').then(({data: {data}}) => {
        if (data) {
          this.notificationPrefsData = data;
        }
      });
    },
  },
};
</script>
