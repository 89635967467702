<template>
  <button v-tippy="{placement: 'top', arrow: true}" :title="label" @click.prevent="onClick">
    <pdl-icon qaid="bulk-expand-button" :name="icon" />
  </button>
</template>
<script>
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'PlpBulkExpandButton',
  components: {
    PdlIcon,
  },
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      return this.isExpanded ? 'indeterminate_check_box' : 'add_box';
    },
    label() {
      return this.isExpanded ? this.$t('skuGrid.B2B.collapse') : this.$t('skuGrid.B2B.expand');
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
