<template>
  <div class="space-y-1" qaid="ship-to-home-fulfillment-block">
    <div class="flex gap-x-1 items-center">
      <ship-to-home-icon />
      <span qaid="ship-to-home-header" class="text-md font-bold">{{ $t(option.name) }}</span>
    </div>
    <div v-if="hasMessaging || hasRichMessaging" class="leading-normal text-sm">
      <i18n v-if="baseMessage" :path="baseMessage" tag="p" qaid="ship-to-home-content">
        <template #postalCode>
          <pdl-button small underline text label="Open postcode drawer" @click="setIsDrawerOpen(true)">
            {{ selectedPostcode }}
          </pdl-button>
        </template>
        <template v-if="isSingleDate" #date>{{ startDate }}</template>
        <template v-if="!isSingleDate" #startDate>
          <span class="font-bold">{{ startDate }}</span>
        </template>
        <template v-if="!isSingleDate" #endDate>
          <span class="font-bold">{{ endDate }}</span>
        </template>
      </i18n>
      <p v-if="shippingDetailsMessage && !hasRichMessaging" qaid="ship-to-home-shipping-details-message">
        {{ $t(shippingDetailsMessage) }}
      </p>
      <div v-if="hasRichMessaging">
        <fulfillment-option-dialog
          v-if="hasRichMessaging"
          id="ship-to-home-popover"
          :is-visible="isLearnMoreVisible"
          :component-id="richMessaging.learnMoreComponentId"
          @close="setIsLearnMoreVisible(false)"
        />
        <i18n v-if="richMessaging.message" :path="richMessaging.message" tag="p">
          <template>
            <pdl-button
              v-if="richMessaging.learnMoreMessage"
              qaid="ship-to-home-learn-more-button"
              small
              underline
              text
              @click="setIsLearnMoreVisible(true)"
            >
              {{ $t(richMessaging.learnMoreMessage) }}
            </pdl-button>
          </template>
        </i18n>
        <pdl-button
          v-else-if="richMessaging.learnMoreMessage"
          qaid="ship-to-home-learn-more-button"
          small
          underline
          text
          @click="setIsLearnMoreVisible(true)"
        >
          {{ $t(richMessaging.learnMoreMessage) }}
        </pdl-button>
      </div>
    </div>
    <pdl-button v-else button small underline text qaid="select-postcode-button" @click="setIsDrawerOpen(true)">
      {{ $t('fulfillment.shipToHome.enterZip') }}
    </pdl-button>
  </div>
</template>

<script>
import {PdlButton} from '@pedal/pdl-button';
import ShipToHomeIcon from '@/components/containers/pdp/b2c/fulfillment-options/home-shipping/ShipToHomeIcon.vue';
import {mapState} from 'vuex';
import {useFulfillmentOptionMessaging} from '@/composables/fulfillment-option-messaging.js';
import FulfillmentOptionDialog from '@/components/containers/pdp/b2c/fulfillment-options/FulfillmentOptionDialog.vue';
import {toRefs} from 'vue';

export default {
  components: {
    ShipToHomeIcon,
    PdlButton,
    FulfillmentOptionDialog,
  },
  props: {
    setIsDrawerOpen: {
      type: Function,
      default: () => {},
    },
    option: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const {option} = toRefs(props);
    const {
      hasRichMessaging,
      richMessaging,
      hasMessaging,
      isSingleDate,
      startDate,
      endDate,
      baseMessage,
      shippingDetailsMessage,
    } = useFulfillmentOptionMessaging(option);

    return {
      hasRichMessaging,
      richMessaging,
      hasMessaging,
      isSingleDate,
      startDate,
      endDate,
      baseMessage,
      shippingDetailsMessage,
    };
  },
  data() {
    return {
      isLearnMoreVisible: false,
    };
  },
  computed: {
    ...mapState('user', ['selectedLocation']),
    selectedPostcode() {
      return this.selectedLocation?.postcode;
    },
  },
  methods: {
    setIsLearnMoreVisible(value) {
      this.isLearnMoreVisible = value;
    },
  },
};
</script>
