<template>
  <div qaid="editorial-flair-body" class="flair__body" :class="classes">
    <div v-if="isBodyVisible" class="flex flex-col justify-between h-full gap-y-4 md:gap-y-8">
      <div class="flex flex-col gap-y-2">
        <pdl-heading qaid="editorial-flair-title" :size="headerSize" :style="titleStyle" level="2">{{
          title
        }}</pdl-heading>
        <p :style="bodyStyle" class="text-md">{{ body }}</p>
      </div>
      <div class="flex flex-col">
        <pdl-link
          v-for="(cta, index) of ctas"
          :key="`flair-cta-${index}`"
          :qaid="`flair-cta-${index}`"
          :href="cta.href"
          :style="ctaStyle"
          icon="chevron_right"
          standalone
          @click="!cta.href && play()"
        >
          {{ cta.title }}
        </pdl-link>
      </div>
    </div>
    <div id="placeholder" />
  </div>
</template>

<script>
import {PdlHeading} from '@pedal/pdl-section-header';
import {ViewSize} from '@/constants/view-breakpoints';
import {mapGetters} from 'vuex';
import {PdlLink} from '@pedal/pdl-link';

export default {
  components: {PdlHeading, PdlLink},
  props: {
    title: {
      type: String,
      default: () => '',
    },
    titleColor: {
      type: String,
      default: () => '',
    },
    bodyColor: {
      type: String,
      default: () => '',
    },
    ctaColor: {
      type: String,
      default: () => '',
    },
    body: {
      type: String,
      default: () => '',
    },
    ctas: {
      type: Array,
      default: () => [],
    },
    classes: {
      type: Array,
      default: () => [],
    },
    isBodyVisible: {
      type: Boolean,
      default: () => false,
    },
    play: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('viewSize', ['viewBreakpointMoreThan']),
    headerSize() {
      const {viewBreakpointMoreThan} = this;
      return viewBreakpointMoreThan(ViewSize.SMALL) ? 'xl' : 'lg';
    },
    titleStyle() {
      const {titleColor} = this;
      return {
        color: titleColor,
      };
    },
    bodyStyle() {
      const {bodyColor} = this;
      return {
        color: bodyColor,
      };
    },
    ctaStyle() {
      const {ctaColor} = this;
      return {
        color: ctaColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.flair__body {
  flex-basis: 0%;

  @include breakpoint(large up) {
    margin-top: 3rem;
  }
}

.active {
  flex-grow: 1;
}

.inactive {
  flex-grow: 0.001;
}
</style>
