<script>
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import {mapGetters, mapMutations, mapState, mapActions} from 'vuex';
import {ProductApi} from '@/api/product';
import TabsContentContainer from '@/components/tabs/TabsContentContainer';
import pdpBuyingZoneContainer from '@/components/containers/pdp/PdpBuyingZoneContainer';
import productCardItem from '@/components/containers/plp/ProductCardItem';
import bulkProductGridContainer from '@/components/containers/bulk-product/BulkProductGridContainer';
import techInfoContainer from '@/components/containers/tech-info/TechInfoContainer';
import GridSwitch from '@/components/grid/GridSwitch';
import ToggleSwitch from '@/components/ToggleSwitch';
import {PdlLoading} from '@pedal/pdl-loading';
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';
import ProductGroups from '@/components/containers/bulk-product/utils/product-groups';
import {updateStockWatchStatus} from '@/components/containers/bulk-product/utils/warehouses';
import ProductReviewsWriteReviewComponent from '@/components/power-reviews/ProductReviewsWriteReviewComponent';
import ProductReviewsComponent from '@/components/power-reviews/ProductReviewsComponent';
import ProductReviewsHeader from '@/components/power-reviews/ProductReviewsHeader';
import SizeTable from '@/components/SizeTable';
import GeometryContainer from '@/components/containers/pdp/GeometryContainer';
import ProductReferencesContainer from '@/components/containers/product-references/ProductReferencesContainer';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';

export default {
  components: {
    pdpBuyingZoneContainer,
    TabsContentContainer,
    productCardItem,
    bulkProductGridContainer,
    techInfoContainer,
    ToggleSwitch,
    PdlLoading,
    GridSwitch,
    GeometryContainer,
    SizeTable,
    TrekButton,
    TrekLink,
    ProductReviewsWriteReviewComponent,
    ProductReviewsComponent,
    ProductReviewsHeader,
    PdlCollapse,
    PdlCollapseItem,
    ProductReferencesContainer,
  },

  props: {
    isBike: Boolean,
    baseProductCode: {
      type: String,
      required: true,
    },
    requestedProductCode: {
      type: String,
      default: () => '',
    },
    pdpFunctionsMapping: {
      type: Object,
      required: false,
      default: () => null,
    },
    rpaModalViewStockToggle: Boolean,
    electraAccessoryFinderToggle: Boolean,
    isElectraBrand: Boolean,
    hasCustomWaterBottleRestriction: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Object,
      required: false,
      default: () => null,
    },
  },

  data() {
    return {
      productGroups: [],
      isPdp: true,
      archived: '',
      productStatus: {},
    };
  },

  computed: {
    ...mapGetters('storage', ['purchaseMode']),
    ...mapState('backend', ['unitId', 'isConsumerFriendlyMode']),
    ...mapState('pdp', ['searchResultReference']),
    ...mapState('compare', ['racks']),
    ...mapState('storage', ['purchaseMode']),

    isRackFull() {
      return this.racks[this.unitId].length === 4;
    },

    isInRack() {
      return !!find(this.racks[this.unitId], {productCode: this.productGroups.code});
    },
  },
  watch: {
    searchResultReference() {
      this.searchResultReference.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    },
  },

  mounted() {
    if (this.unitId !== 'b2c') {
      this.fetchProducts();
    }
    this.setCategory(this.category);
    this.setIsBike(this.isBike);
    this.setRpaViewInStockModalValue(this.rpaModalViewStockToggle);
    this.updateRequestedProductCode(this.requestedProductCode);
    this.setElectraAccessoryFinderToggle(this.electraAccessoryFinderToggle);
    this.setPdpFunctionsMapping(this.pdpFunctionsMapping);
    this.setIsElectraBrand(this.isElectraBrand);
    this.setHasCustomWaterBottleRestriction(this.hasCustomWaterBottleRestriction);
    this.setPdpBaseProductCode(this.baseProductCode);
    // We have to do this manual 'uncloaking' of the component because of the way
    // we are mixing static JSP and vue components inside this vue 'inline-template'.
    // Once we fully convert the PDP page to Vue we can use standard binding to show/hide
    // sections.
    if (this.$refs.pdpSection) {
      this.$refs.pdpSection.classList.remove('h-screen');
      this.$refs.pdpSection.classList.remove('invisible');
    }
  },
  methods: {
    ...mapActions('skuGrid', ['loadAllocatedCounts']),
    ...mapActions('pdp', ['setRpaViewInStockModalValue']),

    /**
     * Get product's ID from current URL.
     * @return {string|*}
     */
    getProductIdFromUrl() {
      const pathArray = window.location.pathname.split('/');
      const index = findIndex(pathArray, (x) => x === 'p');

      return index > -1 && pathArray.length > index + 1 ? pathArray[index + 1] : '';
    },

    /**
     * Update stock data.
     * @param payload
     */
    updateStocks(payload) {
      if (payload && payload.item) {
        updateStockWatchStatus(this.productGroups, payload.item);
      }
    },

    /**
     * Fetch product data.
     */
    fetchProducts() {
      const productId = this.getProductIdFromUrl();
      this.setBikeTechSKU(productId);

      if (this.baseProductCode) {
        ProductApi.loadProduct(this.baseProductCode).then((data) => {
          const productGroups = new ProductGroups(data);
          this.productGroups = productGroups.getProductGroups();

          this.productStatus = data.status;
        });

        this.loadAllocatedCounts([this.baseProductCode]);
      }
    },

    /**
     * Switch purchase mode  (consumer friendly on / off)
     * @param e
     */
    switchPurchaseMode(e) {
      this.togglePurchaseMode(e);
    },

    archivedValue(e) {
      this.archived = e;
    },

    ...mapActions('storage', ['togglePurchaseMode']),
    ...mapActions('pdp', ['updateRequestedProductCode']),
    ...mapMutations('pdp', [
      'setIsBike',
      'setElectraAccessoryFinderToggle',
      'setRpaViewInStock',
      'setPdpFunctionsMapping',
      'setIsElectraBrand',
      'setCategory',
      'setPdpBaseProductCode',
    ]),
    ...mapMutations('backend', ['setHasCustomWaterBottleRestriction']),
    ...mapMutations('storage', ['setBikeTechSKU']),
  },
};
</script>
<style lang="scss" scoped>
//applied at trekproductspecificationcomponentbom.jsp
::v-deep .pdp-spec-collapse {
  .pdl-collapse-item {
    &__header {
      @apply text-md;
      @apply font-bold;

      color: inherit;
      border-bottom: 2px solid var(--gray-100);

      &.is-active {
        border-bottom: 2px solid var(--gray-100);
      }
    }

    &__wrap {
      border-bottom: none;
    }

    &__content {
      padding-bottom: 0;
    }
  }
}
</style>
