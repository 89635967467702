<template>
  <form-group
    v-model="line1"
    :help-text="$t('text.UPSDelivery')"
    :maxlength="40"
    name="line1"
    :label="$t('address.line1')"
    :error="getErrorMessage('line1')"
    required
    show-error-messages
    force-display-error
  />
</template>

<script>
import FormGroup from '@/components/FormGroup';
import {mapState, mapMutations} from 'vuex';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
export default {
  components: {FormGroup},
  mixins: [TrekValidationMixin],
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  validations() {
    return {
      line1: {
        required: this.trekValidators.requiredWithCustomMessage('address.line1.invalid'),
        maxLength: this.trekValidators.maxLength('address.line1', 40),
      },
    };
  },
  computed: {
    ...mapState('checkout', ['billingAddress']),
    line1: {
      get() {
        return this.billingAddress.line1 ? this.billingAddress.line1 : this.value;
      },
      set(line1) {
        this.setLine1(line1);
      },
    },
    fieldName() {
      return 'line1';
    },
  },
  methods: {
    ...mapMutations('checkout', ['setLine1']),
  },
};
</script>
