export default function () {
  // Store the search text as the user types it in so it is available when they click an auto-complete link
  var searchvalue = '';
  var gaTrackingId = 'googleTagManagerId';
  if (window.trackingEnabled) {
    window.ga('create', gaTrackingId, 'auto');
  }

  $('body').on('click', '.autocompletelink', function () {
    if (!gaTrackingId || gaTrackingId === undefined || gaTrackingId.trim() === '' || gaTrackingId.trim().length === 0) {
      return;
    }
    var pos = $(this).attr('href').split('/', 3).join('/').length; // get position of 3rd slash to extract locale
    var locale = $(this).attr('href').substring(0, pos);
    var galink = locale + '/search/?text=' + encodeURIComponent(searchvalue);
    if (window.trackingEnabled) {
      window.ga('send', 'pageview', galink);
    }
  });

  $('.siteSearchInput').keyup(function () {
    SaveSearchTerm(this);
  });

  function SaveSearchTerm(textinput) {
    searchvalue = textinput.value;
  }
}
