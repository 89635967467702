<template>
  <div>
    <h5 class="font-bold mt-5 mb-3">{{ titleMap[type] }}</h5>
    <div
      class="flex flex-wrap md:flex-nowrap justify-between pb-2 border-solid border-0 border-b border-gray-10"
      qaid="shipping-info-estimated-shipping"
    >
      <span>
        {{ $t('text.estimatedTotalShipping') }}
      </span>
      <span class="font-medium">{{ deliveryCostValue }}</span>
    </div>
    <div class="flex flex-wrap md:flex-nowrap justify-between pt-2" qaid="shipping-info-total-shipping">
      <span>
        {{ $t('checkout.B2B.totalShippingDeduction') }}
      </span>
      <span class="font-medium">{{ freightDeductionValue }}</span>
    </div>
  </div>
</template>

<script>
import {ProductType} from '@/constants/product';
import {mapState} from 'vuex';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    titleMap() {
      return {
        [ProductType.Bike]: this.$t('text.bikeShippingTotal'),
        [ProductType.Aftermarket]: this.$t('text.accessoriesShippingTotal'),
      };
    },
    deliveryCostValue() {
      return this.totals?.subtotals[this.type]?.prices?.deliveryCost.formattedValue ?? '';
    },
    freightDeductionValue() {
      return this.totals?.subtotals[this.type]?.prices?.freightDeduction.formattedValue ?? '';
    },
    ...mapState('shipments', ['totals']),
  },
};
</script>
