<script>
import {PdlToastType} from '@/constants/pdl-toast-type';

export default {
  data() {
    return {
      showCartEmpty: false,
    };
  },
  created() {
    this.$on('switchTab', this.test);
  },
  methods: {
    test() {
      $('#tab-1').click();
    },
    moveToList() {
      return this.$notify({
        type: PdlToastType.SUCCESS,
        message: 'Item (fake) moved to list!',
      });
    },
    moveAllToList() {
      return this.$notify({
        type: PdlToastType.SUCCESS,
        message: 'All Items (fake) moved to list!',
      });
    },
  },
};
</script>
