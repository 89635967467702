import storefrontInstance from '@/api/instances/storefront';
import {PdlToastType} from '@/constants/pdl-toast-type';
import {miniCart} from '@/store/modules';

// Event emitted by zoovu into the window event bus
export const ZOOVU_CART_UPDATE_EVENT = 'trek:zoovu-cart-update';

// Component level events
export const ZOOVU_LOADING = 'zoovu-loading';
export const ZOOVU_LOADED = 'zoovu-loaded';

const TOAST_SUCCESS = 'electraAccessoryFinderAdded';
const TOAST_ERROR = 'electraAccessoryFinderUnableToAdd';

export default {
  created() {
    window.addEventListener(ZOOVU_CART_UPDATE_EVENT, this.cartUpdate);
  },
  destroyed() {
    window.removeEventListener(ZOOVU_CART_UPDATE_EVENT, this.cartUpdate);
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async saveCartData(products) {
      const data = {
        cartEntries: [...products],
      };

      const response = await storefrontInstance.post('/cart/entries', data).catch();
      return response?.data?.data ?? {};
    },
    transformCartData(products) {
      const processed = [];

      products.forEach(({sku, quantity, name}) => {
        if (sku && quantity && name) {
          processed.push({
            sku: sku,
            qty: quantity,
            name: name,
          });
        }
      });

      return processed;
    },
    async cartUpdate(event) {
      if (this.isLoading) return;
      this.isLoading = true;

      // Lets emit some events that the components can listen to (ie dialog)
      this.$emit(ZOOVU_LOADING);

      // Transform and save to BE
      const products = event?.detail?.products ?? [];
      const processed = this.transformCartData(products);
      const saved = await this.saveCartData(processed);

      // Update minicart count badge if there was a successful add
      if (saved?.totalCartQty) {
        window.dispatchEvent(new CustomEvent(miniCart.UPDATE_CART_EVENT));
      }

      // Trigger notifications and release the lock
      this.cartSavedNotifications(saved);
      this.isLoading = false;

      this.$emit(ZOOVU_LOADED);
    },
    async cartSavedNotifications({successQty = 0, failQty = 0}) {
      [
        {type: PdlToastType.SUCCESS, key: TOAST_SUCCESS, qty: parseInt(successQty)},
        {type: PdlToastType.ERROR, key: TOAST_ERROR, qty: parseInt(failQty)},
      ]
        .filter((n) => n.qty > 0)
        .forEach(async ({type, key, qty}, index) => {
          // Prevent the toasts from stacking on top of each other
          await new Promise((resolve) => setTimeout(resolve, index * 100));

          this.$notify({
            type: type,
            message: this.bp(key, qty),
            qaid: `zoovu-add-to-cart-toast-${type}`,
          });
        });
    },
  },
};
