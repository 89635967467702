<template>
  <div class="mb-2">
    <div v-if="hasPromotionApplied" class="flex bg-gray-05 p-2 items-start justify-between">
      <div class="flex gap-x-1">
        <pdl-icon name="local_offer" />
        <div class="flex flex-col">
          <span class="font-medium text-md">{{ promotionId }}</span>
          <span v-if="promotionName" class="text-sm">{{ promotionName }}</span>
        </div>
      </div>
      <pdl-button
        qaid="promotion-remove-button"
        class="promotion-remove-button"
        :disabled="isSubmittingPromotion"
        tertiary
        icon-only
        icon="remove_circle_outline"
        :label="$t('text.remove')"
        @click="handlePromotionRemove(promotionId)"
      />
    </div>
    <pdl-collapse
      v-else
      qaid="promo-collapse"
      header-classes="promotions"
      content-classes="flex flex-col bg-gray-05 p-2"
      default-active
    >
      <pdl-collapse-item>
        <template #title>
          <div class="text-md font-medium">
            <pdl-icon name="local_offer" />
            <span>{{ $t('basket.couponCode.havePromo') }}</span>
          </div>
        </template>
        <form id="promoSubmit" class="form-group--inline gap-x-4" @submit.prevent="handlePromotionSubmit(couponCode)">
          <form-group
            v-model="couponCode"
            name="couponCode"
            :placeholder="$t('basket.couponCode.placeholder')"
            class="mb-0 w-full"
          >
            <template #label>
              <label for="couponCode" class="sr-only">{{ $t('text.promotions') }}</label>
            </template>
          </form-group>
          <pdl-button qaid="promo-apply-button" primary form-inline :disabled="isSubmittingPromotion" type="submit">
            {{ $t('text.button.apply') }}
          </pdl-button>
        </form>
      </pdl-collapse-item>
    </pdl-collapse>
  </div>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';
import {PdlButton} from '@pedal/pdl-button';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import FormGroup from '@/components/FormGroup';
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import {PdlToastType} from '@/constants/pdl-toast-type';

export default {
  components: {
    FormGroup,
    PdlButton,
    PdlIcon,
    PdlCollapse,
    PdlCollapseItem,
  },
  props: {
    hasMixedPromoItems: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      couponCode: '',
      isSubmittingPromotion: false,
    };
  },
  computed: {
    ...mapState('cart', ['cartData']),
    ...mapGetters('cart', ['hasDeliveryModeSelectionsMade']),
    hasPromotionApplied() {
      return (
        Boolean(this.cartData?.appliedOrderPromotions[0]?.promotion?.code) ||
        Boolean(this.cartData?.appliedProductPromotions[0]?.promotion?.code)
      );
    },
    promotionId() {
      return this.cartData?.appliedVouchers[0]?.voucherCode;
    },
    promotionName() {
      return this.cartData?.appliedVouchersList?.[0]?.name;
    },
  },
  watch: {
    cartData(currentCart, updatedCart) {
      const isFirstUpdate = !updatedCart && Boolean(currentCart);
      if (isFirstUpdate && !this.hasPromotionApplied && this.promotionId) {
        this.handlePromotionRemove(this.promotionId);
      }
    },
  },
  methods: {
    ...mapMutations('cart', ['setHasClickedProceedWithoutDeliveryModeSelected']),
    ...mapActions('cart', ['applyPromotion', 'removePromotion']),
    async handlePromotionSubmit(couponCode) {
      if (!this.hasDeliveryModeSelectionsMade) {
        this.setHasClickedProceedWithoutDeliveryModeSelected(true);
        this.couponCode = '';
        return;
      }
      const trimmedCode = couponCode.trim();
      const couponName = `'${trimmedCode}'`;
      const errMessage = this.$t('basket.couponCode.error.notapplied', [couponName]);
      await this.handlePromotion(
        () => this.applyPromotion(trimmedCode),
        errMessage,
        this.$t('basket.couponCode.applied', [couponName])
      );
    },
    async handlePromotionRemove(promotionId) {
      const promotionName = `'${promotionId}'`;
      const errMessage = this.$t('basket.couponCode.error.notremoved', [promotionName]);
      await this.handlePromotion(
        () => this.removePromotion(promotionId),
        errMessage,
        this.$t('basket.couponCode.removed', [promotionName])
      );
    },
    async handlePromotion(promoFunc, defaultErrorMessage, defaultSuccessMessage) {
      this.isSubmittingPromotion = true;
      try {
        await promoFunc();
        this.$notify({
          type: PdlToastType.SUCCESS,
          message: this.hasMixedPromoItems ? this.$t('basket.couponCode.success.message') : defaultSuccessMessage,
          ...(this.hasMixedPromoItems && {duration: 0}),
          showClose: true,
        });
      } catch (error) {
        this.$notify({
          type: PdlToastType.ERROR,
          message: defaultErrorMessage,
          showClose: true,
        });
      } finally {
        this.couponCode = '';
        this.isSubmittingPromotion = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .pdl-collapse-item__wrap,
::v-deep .pdl-collapse-item__header {
  border-bottom: none;
}

::v-deep .promotions {
  background-color: var(--gray-05);
  padding: 1rem 0.5rem 0.5rem 1rem;
}

.promotion-remove-button {
  padding: 0.25rem;
}
</style>
