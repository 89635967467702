<template>
  <div>
    <pdl-section-header :is-dividing="true" size="md">
      <template slot="content">
        <pdl-heading :level="2" qaid="allocated-backorder-filters-heading">
          {{ $t('backordersAllocated.B2B.filter') }}
        </pdl-heading>
      </template>
    </pdl-section-header>

    <form @submit.prevent="filterChanged">
      <div class="lg:flex flex-col">
        <div class="md:flex">
          <div class="flex flex-col md:w-1/3">
            <form-group
              v-model="description"
              type="text"
              name="description"
              :maxlength="30"
              class="md:mr-4 md:grow"
              :label="`${$t('contactUs.model')}`"
              qaid="allocated-backorder-description-filter"
              :help-text="descriptionHelpText"
            />
            <form-group
              v-model="orderNumber"
              type="text"
              name="orderNumber"
              :maxlength="30"
              class="md:mr-4 md:grow"
              :label="`${$t('text.account.orderHistory.page.sort.byOrderNumber')}`"
              qaid="allocated-backorder-order-number-filter"
            />
            <form-group
              v-model="shipToNumber"
              type="text"
              name="shipToNumber"
              :maxlength="8"
              class="md:mr-4 md:grow"
              :label="`${$t('text.storeNumber')}`"
              qaid="allocated-backorder-store-number-filter"
              @input="updateShipToNumberValue"
            />
          </div>
          <div class="flex flex-col md:w-1/3">
            <form-group
              v-model="customerPo"
              type="text"
              name="CustomerPo"
              :maxlength="30"
              class="md:mr-4"
              :label="`${$t('text.customerPo')}`"
              qaid="allocated-backorder-customer-po-filter"
            />
            <form-group
              v-model="sku"
              type="text"
              name="sku"
              :maxlength="30"
              class="md:mr-4 md:grow"
              :label="`${$t('product.variants.sku')}`"
              qaid="allocated-backorder-sku-filter"
            />
          </div>
          <div class="flex flex-col md:w-1/3">
            <form-group
              type="checkbox"
              :multi-items="[
                {value: 'hideUnallocated', checked: hideUnallocated, displayName: $t('backordersAllocated.B2B.hide')},
              ]"
              class="mb-0"
              :label="`${$t('text.orders')}`"
              name="UNALLOCATED"
              qaid="allocated-backorder-hide-unallocated-filter"
              @change="handleCheckboxChange"
            />
            <form-group
              type="checkbox"
              :multi-items="[
                {
                  value: 'hideWarranties',
                  checked: hideWarranties,
                  displayName: $t('backordersAllocated.B2B.hideWarrantyRelatedItems'),
                },
              ]"
              name="WARRANTIES"
              class="mb-0"
              qaid="allocated-backorder-hide-warranties-filter"
              @change="handleCheckboxChange"
            />
            <form-group
              type="checkbox"
              :multi-items="[
                {
                  value: 'hideLinesWithoutComments',
                  checked: hideLinesWithoutComments,
                  displayName: $t('backordersAllocated.B2B.showLineNotes'),
                },
              ]"
              name="LINES_WITHOUT_COMMENTS"
              class="mb-0"
              qaid="allocated-backorder-hide-lines-without-comments-filter"
              @change="handleCheckboxChange"
            />
            <form-group
              type="checkbox"
              :multi-items="[
                {value: 'showSoldOut', checked: showSoldOut, displayName: $t('backordersAllocated.B2B.showSoldOut')},
              ]"
              class="mb-0"
              name="SOLD_OUT"
              qaid="allocated-backorder-show-sold-out-filter"
              @change="handleCheckboxChange"
            />
            <form-group
              type="checkbox"
              :multi-items="[
                {
                  value: 'showProjectOne',
                  checked: showProjectOne,
                  displayName: $t('backordersAllocated.B2B.showProjectOne'),
                },
              ]"
              class="md:mb-0"
              name="show-project-one"
              qaid="allocated-backorder-show-project-one-filter"
              @change="handleCheckboxChange"
            />
          </div>
        </div>

        <div class="buttons">
          <trek-button primary qaid="allocated-backorder-apply-filters" @click="filterChanged">
            {{ $t('backordersAllocated.B2B.filter') }}
          </trek-button>
          <trek-button secondary qaid="allocated-backorder-clear-filters" @click="clearFilters">
            {{ $t('text.clearFilters') }}
          </trek-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import FormGroup from '@/components/FormGroup';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import TrekButton from '@/components/TrekButton';

export default {
  name: 'AllocatedBackorderHistoryFilters',
  components: {
    TrekButton,
    FormGroup,
    PdlSectionHeader,
    PdlHeading,
  },
  props: {
    redirectFilterSku: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hideUnallocated: false,
      hideWarranties: false,
      hideLinesWithoutComments: false,
      showSoldOut: false,
      showProjectOne: false,
      description: '',
      orderNumber: '',
      customerPo: '',
      shipToNumber: '',
      sku: '',
    };
  },
  computed: {
    descriptionHelpText() {
      return this.$store.state.locale === 'ko_KR' ? this.$t('backordersAllocated.B2B.helpText.korea') : '';
    },
  },
  mounted() {
    this.sku = this.redirectFilterSku ? this.redirectFilterSku : this.sku;
  },
  methods: {
    filterChanged() {
      this.$emit('changed', {
        hideUnallocated: this.hideUnallocated,
        hideWarranties: this.hideWarranties,
        hideLinesWithoutComments: this.hideLinesWithoutComments,
        showSoldOut: this.showSoldOut,
        showProjectOne: this.showProjectOne,
        description: this.description,
        orderNumber: this.orderNumber,
        customerPo: this.customerPo,
        shipToNumber: this.shipToNumber,
        sku: this.sku,
      });
    },
    clearFilters() {
      this.resetFilters();
      this.filterChanged();
    },
    resetFilters() {
      this.hideUnallocated = false;
      this.hideWarranties = false;
      this.hideLinesWithoutComments = false;
      this.showSoldOut = false;
      this.showProjectOne = false;
      this.description = '';
      this.orderNumber = '';
      this.customerPo = '';
      this.shipToNumber = '';
      this.sku = '';
    },
    handleCheckboxChange(event) {
      const eventValue = event?.target?.value;

      switch (eventValue) {
        case 'hideUnallocated':
          this.hideUnallocated = event.target.checked;
          break;
        case 'hideWarranties':
          this.hideWarranties = event.target.checked;
          break;
        case 'hideLinesWithoutComments':
          this.hideLinesWithoutComments = event.target.checked;
          break;
        case 'showSoldOut':
          this.showSoldOut = event.target.checked;
          break;
        case 'showProjectOne':
          this.showProjectOne = event.target.checked;
          break;
      }
    },
    updateShipToNumberValue() {
      this.shipToNumber = this.shipToNumber.replace(/[^\d]/g, '');
    },
  },
};
</script>
