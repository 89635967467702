const defaultMask = '#### #### #### ####';
const CreditCardMaskPatterns = Object.freeze({
  visa: defaultMask,
  mastercard: defaultMask,
  master: defaultMask,
  maestro: defaultMask,
  amex: '#### ###### #####',
});

const getCardPattern = (cardType) => {
  return CreditCardMaskPatterns[cardType] ?? defaultMask;
};

export default getCardPattern;
