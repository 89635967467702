<template>
  <div class="prod-compare" qaid="productCompareContainer">
    <pdl-section-header size="lg" qaid="productCompareHeaderContainer">
      <template slot="content">
        <pdl-heading qaid="productCompareHeader">{{ bp('compareTitle') }}</pdl-heading>
      </template>
      <template slot="actions">
        <trek-link button text small :href="lastPlpUrl" icon="add_circle_outline" qaid="productCompareHeaderAddProduct">
          <span v-html="bp('addProduct')"></span>
        </trek-link>
      </template>
    </pdl-section-header>
    <pdl-collapse
      v-if="!loading"
      v-cloak
      icon-position="left"
      :default-active="false"
      :value="activeTabs"
      qaid="product-compare-collapse"
    >
      <div class="side-scroll-wrapper" qaid="productCompareSectionsWrapper">
        <div class="side-scroll-wrapper--inner" :class="[bikeNumClass]">
          <template v-for="(section, key) in compareSections">
            <pdl-collapse-item v-if="showSection(key, section)" :key="key" :name="key">
              <div slot="title" class="heading heading--sm" :qaid="`productCompareTitle-${section.label}`">
                {{ section.label }}
              </div>
              <component
                :is="whichSection(key)"
                :reviews-rendered="reviewsRendered"
                :base-properties="baseProperties"
                :meta-data="metaData"
                :section-data="section"
                :section-key="key"
                @reviews-rendered="reviewsRendered = true"
              ></component>
            </pdl-collapse-item>
          </template>
          <compare-overview
            :reviews-rendered="reviewsRendered"
            :base-properties="baseProperties"
            :meta="true"
            :meta-data="metaData"
            :section-data="compareSections.overview"
            section-key="overview"
            @reviews-rendered="reviewsRendered = true"
          ></compare-overview>
          <product-reviews-header :base-properties="pRBaseProperties" :options="pROptions" />
        </div>
      </div>
    </pdl-collapse>
    <trek-link
      button
      secondary
      icon="arrow_back"
      :href="lastPlpUrl"
      class="mt-5 mb-2"
      qaid="productCompareContinueShoppingButton"
    >
      <span v-html="bp('continueShopping')"></span>
    </trek-link>
    <div>
      <trek-button text icon="delete" qaid="productCompareDeleteRackButton" @click="deleteRack">
        <span v-html="bp('deleteRack')"></span>
      </trek-button>
    </div>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {mapActions, mapState, mapGetters} from 'vuex';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import CompareOverview from '@/components/compare/CompareOverview';
import CompareTechSpecs from '@/components/compare/CompareTechSpecs';
import CompareWhyWeLoveIt from '@/components/compare/CompareWhyWeLoveIt';
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';
import ProductReviewsHeader from '@/components/power-reviews/ProductReviewsHeader';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  name: 'CompareContainer',

  components: {
    PdlCollapse,
    PdlCollapseItem,
    CompareOverview,
    CompareTechSpecs,
    CompareWhyWeLoveIt,
    TrekButton,
    TrekLink,
    ProductReviewsHeader,
    PdlSectionHeader,
    PdlHeading,
  },
  props: {
    pRBaseProperties: {
      type: Array,
      required: true,
    },
    pROptions: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      activeTabs: ['overview', 'techSpecs'],
      compareSections: {},
      metaData: {},
      productList: [],
      reviewsRendered: false,
    };
  },

  computed: {
    bikeNumClass() {
      return this.currentProducts ? `products${this.currentProducts.length}` : null;
    },

    compareUrl() {
      if (!this.currentRack) return '';
      return `/compareJson/?productList=${this.currentProducts.join(',')}`;
    },

    currentProducts() {
      return this.currentRack.reduce((acc, {productCode}) => {
        return acc.concat(productCode);
      }, []);
    },

    lastPlpUrl() {
      return this.lastPlp ? this.lastPlp : this.encodedContextPath;
    },

    productSkus() {
      return Object.keys(this.metaData);
    },

    ...mapState('backend', ['encodedContextPath']),
    ...mapState('storage', ['lastPlp']),
    ...mapGetters('compare', ['currentRack']),
  },

  watch: {
    currentProducts() {
      if (this.currentProducts.length) {
        this.fetchData();
      } else {
        window.location = this.lastPlpUrl;
      }
    },
  },

  mounted() {
    if (this.currentProducts.length) {
      this.fetchData();
    }
  },

  methods: {
    showSection(key, section) {
      switch (key) {
        case 'overview':
          return section.compareItems && section.compareItems.length;
        case 'techSpecs':
          if (
            section &&
            section.specGroups &&
            section.specGroups.length === 1 &&
            section.specGroups[0].compareItems &&
            section.specGroups[0].compareItems.length === 0
          ) {
            return false;
          }
          return section.specGroups && section.specGroups.length;
        case 'why_we_love_it':
          return section.compareItems && section.compareItems.length;
        default:
          return true;
      }
    },

    deleteRack() {
      this.emptyRack().then(() => (window.location = this.lastPlpUrl));
    },

    fetchData() {
      if (this.compareUrl.length) {
        storefrontInstance
          .get(this.compareUrl, {headers: {'X-Requested-With': 'XMLHttpRequest'}})
          .then(({data}) => {
            if (data?.data) {
              this.compareSections = data.data.compareSections || {};
              this.metaData = data.data.metaData || {};
            }
          })
          .finally((this.loading = false));
      }
    },

    getData(key) {
      switch (key) {
        case 'overview':
          return this.compareSections.overview.compareItems;
        case 'techSpecs':
          return this.compareSections.techSpecs.specGroups;
        case 'why_we_love_it':
          return this.compareSections.techSpecs.specGroups;
        default:
          return [];
      }
    },

    whichSection(key) {
      switch (key) {
        case 'overview':
          return 'compareOverview';
        case 'techSpecs':
          return 'compareTechSpecs';
        case 'why_we_love_it':
          return 'compareWhyWeLoveIt';
        default:
          return null;
      }
    },

    ...mapActions('compare', ['emptyRack']),
  },
};
</script>
