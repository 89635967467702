<template>
  <div>
    <div v-if="gridData && gridData.items && gridData.items.length">
      <table id="order-history-grid" class="b2b-grid b2b-grid--collapse mb-0 is-compact">
        <thead class="b2b-grid__header">
          <tr>
            <template v-for="(column, index) in columns">
              <th
                :key="index"
                class="b2b-grid__cell b2b-grid__head"
                :class="[column.cssClasses]"
                :qaid="`shipmentsInquiry.${column.key}Header`"
              >
                {{ column.label }}
              </th>
            </template>
          </tr>
        </thead>

        <tbody>
          <!-- Each Row -->
          <template v-for="(row, rowIndex) in paginatedData">
            <shipments-inquiry-grid-item
              :key="rowIndex"
              :row="row"
              :row-id="rowIndex"
              :class="[{'bg-gray-02': (rowIndex + 1) % 2 === 0}]"
            />
          </template>
        </tbody>
      </table>
      <div class="grid-x grid-margin-x mb-5 mt-2">
        <div class="cell medium-6 large-8">
          <pagination-controls
            v-if="gridData.pageCount > 1"
            ref="pagination"
            :page-number="gridData.pageIndex"
            :page-count="gridData.pageCount"
            @page-change="pageChange"
            @prev-page="prevPage"
            @next-page="nextPage"
          ></pagination-controls>
        </div>
      </div>
    </div>
    <pdl-callout v-else>
      <template #content>
        <span>{{ $t('text.search.noResults') }}</span>
      </template>
    </pdl-callout>
  </div>
</template>

<script>
import pagination from '../../../mixins/pagination';
import PaginationControls from '@/components/PaginationControls';
import ShipmentsInquiryGridItem from '@/components/containers/my-account/ShipmentsInquiryGridItem';
import {PdlCallout} from '@pedal/pdl-callout';

export default {
  components: {PaginationControls, ShipmentsInquiryGridItem, PdlCallout},
  mixins: [pagination],

  inject: ['container'],

  provide() {
    return {
      grid: this,
      container: this.container,
    };
  },

  props: {
    size: {
      type: Number,
      required: false,
      default: 50,
    },

    gridData: {
      type: Object,
      default: () => null,
    },

    columns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      pageIndex: 0,
    };
  },

  computed: {
    listData() {
      return this.gridData.items;
    },

    headers() {
      return this.columns;
    },
  },

  methods: {
    pageChange(num) {
      this.$emit('page-change', {pageIndex: num, type: this.gridData.type});
    },
  },
};
</script>
