<template>
  <div class="form-group" :class="{'has-error has-delivery-error': hasEmptyZipcode}">
    <label for="zipcode" class="form-label" qaid="zipcode-label">
      {{ $t('address.zipcode') }}
      <span class="text-red ml-25">*</span>
    </label>
    <div class="form-group--inline is-required mr-0">
      <input
        id="zipcode"
        v-model="postcode"
        type="text"
        class="form-control m-auto md:mr-1"
        qaid="zipcode-value"
        name="zipcode"
        @blur="v$.$touch"
      />
      <pdl-button primary qaid="zipcode-search" @click="getDealersByZipcode()">
        {{ $t('text.search') }}
      </pdl-button>
    </div>
    <div v-show="hasEmptyZipcode" class="form-feedback is-invalid">
      {{ $t('form.enterZip') }}
    </div>
  </div>
</template>

<script>
import {PdlButton} from '@pedal/pdl-button';
import {mapState, mapActions, mapMutations} from 'vuex';
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';

export default {
  components: {PdlButton},
  setup() {
    return {v$: useVuelidate()};
  },
  validations() {
    return {
      selectedPostcode: {required, autoDirty: true},
    };
  },
  computed: {
    ...mapState('checkout', ['dealerPostcode', 'hasBikes']),
    hasEmptyZipcode() {
      return !!this.v$.$errors.length;
    },
    selectedPostcode: {
      get() {
        return this.dealerPostcode;
      },
      set(value) {
        this.setDealerPostcode(value);
      },
    },
  },

  methods: {
    ...mapMutations('checkout', ['setDealerPostcode']),
    ...mapActions('checkout', ['getDealers', 'getDeliveryModes']),
    async getDealersByZipcode() {
      if (this.hasBikes) {
        await this.getDealers();
        this.getDeliveryModes();
      } else {
        this.getDealers();
      }
    },
  },
};
</script>
