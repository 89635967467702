<template>
  <p v-if="showShippingText" class="mb-3 text-sm italic" :qaid="`overnight-shipping-text-${locale}`">
    {{ overnightShippingText }}
  </p>
</template>
<script>
import {mapState} from 'vuex';
export default {
  computed: {
    ...mapState('backend', ['language', 'locale']),
    showShippingText() {
      const shippingTextLocales = ['us', 'ca'];
      return shippingTextLocales.includes(this.locale);
    },
    overnightShippingText() {
      const texts = {
        fr_CA:
          "Les commandes sont expédiées du lundi au vendredi et elles doivent être passées avant 14 h (HNC) afin d'être expédiées le jour même.",
        en_CA: 'Orders ship Monday through Friday and must be placed before 2pm CST to ship same day.',
        en_US: 'Orders ship Monday through Friday and must be placed before 2pm CST to ship same day.',
      };
      return texts[this.language] || '';
    },
  },
};
</script>
