<template>
  <div class="b2b-grid__warehouse text-left lg:text-right">
    <span :data-level="displayLevel" qaid="grid-warehouse.value">{{ displayValue }}</span>
    <span qaid="grid-warehouse.label">{{ getETALabel(localEtaLabel) }}</span>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  props: {
    warehouses: {
      type: Array,
      default: () => [],
    },
    warehouse: {
      type: Object,
      default: () => null,
    },
    type: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    level: {
      type: String,
      default: '',
    },
    etaLabel: {
      type: String,
      default: '',
    },
    isBtoPurchasableB2b: Boolean,
    btoText: {
      type: String,
      default: '',
    },
    noEtaText: {
      type: String,
      default: 'No ETA',
    },
    soldOutText: {
      type: String,
      default: '',
    },
    notAvailableText: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      localValue: 0,
      localLevel: 'zero',
      localEtaLabel: false,
    };
  },

  computed: {
    displayValue() {
      // If back end responds with N/A, replace with localized text
      if (this.value) {
        return this.value;
      } else if (this.localValue === 'N/A') {
        return this.notAvailableText;
      } else {
        return this.localValue;
      }
    },

    displayLevel() {
      return this.level ? this.level : this.localLevel;
    },

    ...mapState('backend', ['isB2BPurchasable']),
  },

  watch: {
    // Processes an array of warehouses
    warehouses() {
      this.updateData();
    },
  },

  beforeMount() {
    this.updateData();
  },

  methods: {
    getETALabel(localEtaLabel) {
      if (localEtaLabel) {
        return localEtaLabel;
      } else if (this.warehouse.soldOut) {
        return this.soldOutText;
      } else if (!this.warehouse.isAvailable) {
        return this.notAvailableText;
      }

      return this.noEtaText;
    },

    updateData() {
      if (this.warehouse != null) {
        // Transform data when passed in as a single warehouse object
        this.localValue = this.warehouse.stockLevel;
        this.localEtaLabel = this.warehouse.futureDate;
        this.localLevel = this.warehouse.stockLevelLabel;
      } else if (this.type != null && this.warehouses != null && this.warehouses.length > 0) {
        // Transform data when passed in as 'type' and an array of 'warehouses'

        // Defaults
        this.localLevel = 'zero';
        this.localValue = 0;

        for (var i = 0; i < this.warehouses.length; i++) {
          // If current looping warehouse matches the warehouse type passed in
          if (this.warehouses[i].id == this.type) {
            this.localLevel = this.warehouses[i].stockLevelLabel;
            this.localValue = this.warehouses[i].stockLevel;
            this.localEtaLabel = this.warehouses[i].futureDate;
          }
        }
      }
    },
  },
};
</script>
