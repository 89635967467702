<template>
  <div id="zoovu-assistant"></div>
</template>

<script>
export default {
  props: {
    geometryChartScriptUrl: {
      type: String,
      default: undefined,
    },
  },
  created() {
    this.appendScript();
  },
  methods: {
    appendScript() {
      const script = document.createElement('script');
      script.src = this.geometryChartScriptUrl;
      document.head.appendChild(script);
    },
  },
};
</script>
