<template>
  <pdl-dialog
    :visible.sync="dialogVisible"
    :title="$t('distributor.B2B.container.delete')"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="false"
    body-class="hidden"
    :z-index="16000011"
    qaid="container-delete-dialog"
    @close="closeDialog"
  >
    <template slot="footer">
      <div class="buttons buttons--right">
        <trek-button alert qaid="deleteDialog.confirmButton" @click.stop="deleteContainer">
          {{ $t('distributor.B2B.container.deleteConfirm') }}
        </trek-button>
        <trek-button secondary qaid="deleteDialog.cancelButton" @click.stop="closeDialog">
          {{ $t('text.cancel') }}
        </trek-button>
      </div>
    </template>
  </pdl-dialog>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {mapActions, mapMutations, mapState} from 'vuex';
import TrekButton from '@/components/TrekButton';
import {PdlDialog} from '@pedal/pdl-dialog';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';
import {PdlToastType} from '@/constants/pdl-toast-type';

export default {
  components: {PdlDialog, TrekButton},

  data() {
    return {
      dialogVisible: false,
    };
  },

  computed: {
    ...mapState('backend', ['containerToDelete']),
  },

  watch: {
    containerToDelete(value) {
      if (value && value.length) this.dialogVisible = true;
      else this.dialogVisible = false;
    },
  },

  methods: {
    deleteContainer() {
      storefrontInstance
        .patch('/containers', {
          containerType: this.containerToDelete,
        })
        .then((response) => {
          // If the response was not successful then throw the error (warning toast below)
          if (!checkMetaFeedbackType(response.data, MetaFeedbackType.SUCCESS)) {
            throw new Error('Unsuccessful response');
          }
        })
        .catch(() => {
          this.$notify({
            type: PdlToastType.WARNING,
            message: this.$t('distributor.B2B.container.delete.tryAgain'),
          });
        })
        .finally(() => {
          this.fetchCart();
          this.fetchMiniCart();
        });

      this.closeDialog();
    },

    closeDialog() {
      this.setContainerToDelete(null);
    },

    ...mapActions('cart', ['fetchCart']),
    ...mapActions('miniCart', ['fetchMiniCart']),
    ...mapMutations('backend', ['setContainerToDelete']),
  },
};
</script>
