<template>
  <div>
    <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1" qaid="warranty-claim-header">{{ claimHeader }}</pdl-heading>
      </template>
    </pdl-section-header>

    <!-- Contact Info -->
    <section class="my-6">
      <pdl-section-header size="md" :is-dividing="true" class="mb-3">
        <template slot="content">
          <pdl-heading :level="3" qaid="warranty-claim-contact-info">{{
            $t('warrantyClaims.B2B.claimContactInfo')
          }}</pdl-heading>
        </template>
      </pdl-section-header>

      <dl class="description-list grid-x grid-margin-x max-w-sm">
        <dt class="cell small-5" qaid="warranty-claim-person-label">{{ $t('text.contactPerson') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-person" v-html="claimData.contactName" />
        <dt class="cell small-5" qaid="warranty-claim-phone-label">{{ $t('text.address.phone') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-phone" v-html="claimData.contactPhoneNumber" />
        <dt class="cell small-5" qaid="warranty-claim-email-label">{{ $t('text.address.email') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-email" v-html="claimData.contactEmail" />
      </dl>
    </section>

    <!-- Claim Details -->
    <section class="my-6">
      <pdl-section-header size="md" :is-dividing="true" class="mb-3">
        <template slot="content">
          <pdl-heading :level="3" qaid="warranty-claim-details">{{
            $t('warrantyClaims.B2B.claimDetails')
          }}</pdl-heading>
        </template>
      </pdl-section-header>

      <dl class="description-list grid-x grid-margin-x max-w-sm">
        <dt class="cell small-5" qaid="warranty-claim-number-label">{{ $t('warrantyClaims.B2B.claimNumber') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-number" v-html="claimData.id" />
        <dt class="cell small-5" qaid="warranty-claim-date-label">{{ $t('text.dateSubmitted') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-date" v-html="claimData.lastSavedDate" />
        <dt class="cell small-5" qaid="warranty-claim-po-label">{{ $t('warrantyClaims.B2B.claimPO') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-po" v-html="claimData.poNumber" />
        <dt class="cell small-5" qaid="warranty-claim-notes-label">{{ $t('warrantyClaims.B2B.claimNotes') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-notes" v-html="claimData.notes" />
        <dt class="cell small-5" qaid="warranty-claim-service-label">
          {{ $t('warrantyClaims.B2B.serviceCenterRepair') }}
        </dt>
        <dd class="cell small-7" qaid="warranty-claim-service" v-html="claimData.nonWarClaimFlagValue" />

        <dt v-if="displayWarrantyMakeTheCall" class="cell small-5" qaid="warranty-claim-make-the-call-label">
          {{ $t('warrantyClaims.B2B.makeTheCall') }}
        </dt>
        <dd v-if="displayWarrantyMakeTheCall" class="cell small-7" qaid="warranty-claim-make-the-call">
          {{ isWarrantyCallMade ? $t('profile.optIn.yes') : $t('profile.optIn.no') }}
        </dd>
      </dl>
    </section>

    <!-- Claim Lines -->
    <section class="my-6">
      <pdl-section-header size="lg" :is-dividing="true">
        <template slot="content">
          <pdl-heading :level="2" qaid="warranty-claim-lines">{{ $t('warrantyClaims.B2B.claimLines') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <pdl-collapse
        icon-active="indeterminate_check_box"
        icon-inactive="add_box"
        :default-active="false"
        :value="expandedClaims"
        qaid="warranty-claim-view-collapse"
        @change="claimLineExpansionChange"
      >
        <pdl-collapse-item
          v-for="(claimLine, claimIndex) in claimData.claimLines"
          :key="claimIndex"
          :name="`line${claimIndex}`"
        >
          <div slot="title" class="heading heading--md" qaid="warranty-claim-line-title">
            {{ $t('warrantyClaims.B2B.claimLineNumber', [claimIndex + 1]) }}
          </div>

          <warranty-claim-view-Line :claim-line="claimLine" :claim-data="claimData" :claim-index="claimIndex" />
        </pdl-collapse-item>
      </pdl-collapse>
    </section>
  </div>
</template>

<script>
import WarrantyClaimViewLine from '@/components/containers/warranty-claim/WarrantyClaimViewLine';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {PdlSectionHeader, PdlHeading, WarrantyClaimViewLine},
  props: {
    claimData: {
      type: Object,
      required: true,
    },
    displayWarrantyMakeTheCall: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      expandedClaims: ['line0'],
    };
  },

  computed: {
    claimHeader() {
      return this.$t('warrantyClaims.B2B.claim.number', [this.claimData.id]);
    },

    isWarrantyCallMade() {
      return this.claimData?.isWarrantyCallMade ?? false;
    },
  },

  methods: {
    claimLineExpansionChange(payload) {
      this.expandedClaims = payload;
    },
  },
};
</script>
