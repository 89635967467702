import {ProductType} from '@/constants/product';

const map = {
  DI: {
    bike: ProductType.Bike,
    am: ProductType.Aftermarket,
  },
  Historical: {
    [ProductType.Bike]: 'bikes',
    [ProductType.Aftermarket]: 'parts',
    [ProductType.CustomWaterBottles]: 'customWaterBottles',
    [ProductType.ProjectOne]: 'projectOneBikes',
  },
  Deprecated: {
    [ProductType.Aftermarket]: 'accessories',
  },
};

/**
 * Maps new product types to old ones.
 * Mapping have to be on Hybris side.
 * FE and BE must always use one mapping which is defined in enum ProductType.java
 */
const ProductTypeConverter = {
  convert(productType) {
    const di = this.convertToDI(productType);
    const historical = this.convertToHistorical(productType);
    const deprecated = this.convertToDeprecated(productType);

    return di ?? historical ?? deprecated ?? productType;
  },
  convertToDI(productType) {
    if (productType in map.DI) {
      return map.DI[productType];
    }
  },
  convertToHistorical(productType) {
    if (productType in map.Historical) {
      return map.Historical[productType];
    }
  },
  convertToDeprecated(productType) {
    if (productType in map.Deprecated) {
      return map.Deprecated[productType];
    }
  },
};

export {ProductTypeConverter};
