import {DownloadEtaReportApi} from '@/api/download-eta-report';
import storefrontInstance from '@/api/instances/storefront';
import {PdlToastType} from '@/constants/pdl-toast-type';

const DownloadTimeoutEta = 120000; // 2 minutes
const VerifyStatusDelayEta = 1000; // 1 second
const ProceedWithDownloadDelayEta = 5000; // 5 seconds

const DownloadEtaReport = {
  watch: {
    etaDownloading(value) {
      if (value) {
        this.clearTimeouts();
        this.etaDownloadInProgress();
      }
    },
  },
  methods: {
    async downloadEtaReport() {
      try {
        this.etaDownloading = true;
        await this.verifyFileStatus();
        this.downloadEtaReportFile();
        await this.waitUntilExcelHasBeenDownloaded();
        this.clearTimeouts();
        setTimeout(this.etaDownloadIsSuccessful, ProceedWithDownloadDelayEta);
      } catch (e) {
        if (this.emptyFile) {
          this.noEtaChanges();
        } else {
          this.etaDownloadHasFailed();
        }
        console.error(e);
      }
    },

    async verifyFileStatus() {
      const config = {responseType: 'blob'};
      await storefrontInstance.get('/my-account/backorder/etachanges/export/', config).then((response) => {
        if (response.request.status === 200) {
          this.emptyFile = false;
        } else if (response.status === 204) {
          this.emptyFile = true;
          throw new Error();
        } else {
          throw new Error();
        }
      });
    },

    downloadEtaReportFile() {
      this.report = window.open(DownloadEtaReportApi.getFileDownloadUrl(), '_self');
    },

    /**
     * Check for download with a custom promise
     */
    waitUntilExcelHasBeenDownloaded() {
      return new Promise((resolve, reject) => {
        const verifyDownload = () => {
          if (this.report) {
            clearInterval(this.checkForDownload);
            resolve();
          }
        };
        verifyDownload();
        this.checkForDownload = setInterval(verifyDownload, VerifyStatusDelayEta);
        setTimeout(() => {
          clearInterval(this.checkForDownload);
          reject();
        }, DownloadTimeoutEta);
      });
    },
    etaDownloadInProgress() {
      this.$notify({
        type: PdlToastType.INFO,
        message: this.$t('backordersAllocated.B2B.downloading'),
      });
    },

    etaDownloadIsSuccessful() {
      this.etaDownloading = false;
      this.$notify({
        type: PdlToastType.SUCCESS,
        message: this.$t('backordersAllocated.B2B.ready'),
      });
    },

    etaDownloadHasFailed() {
      this.etaDownloading = false;
      this.$notify({
        type: PdlToastType.ERROR,
        message: this.$t('errorMessage.unableToProcess'),
      });
    },

    noEtaChanges() {
      this.etaDownloading = false;
      this.$notify({
        type: PdlToastType.WARNING,
        message: this.$t('backordersAllocated.B2B.noEtaChanges'),
      });
    },
  },

  data() {
    return {
      checkStatusTimeout: null,
      checkForDownload: null,
      report: null,
      emptyFile: true,
    };
  },
  beforeDestroy() {
    this.clearTimeouts();
  },
};

export default DownloadEtaReport;
