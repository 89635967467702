<template>
  <account-order-details-grid
    inline-template
    :headers="tableHeaders"
    :items="tableData"
    :table-data="tableData"
    :page-size="50"
    :status="status"
    :subtotal="subtotal"
  >
    <div class="text-base">
      <div v-if="status" class="heading heading--sm mb-2 mt-6">{{ $t(`${status}`) }}</div>

      <table class="b2b-grid b2b-grid--collapse is-compact" qaid="order-details-sku-grid">
        <caption class="sr-only">
          {{
            $t('text.shipments')
          }}
        </caption>
        <thead class="b2b-grid__header">
          <th
            v-for="header in getHeaders()"
            :key="header.label"
            class="b2b-grid__cell b2b-grid__head"
            :class="[{'whitespace-nowrap': header.key !== 'etaDate'}, header.cssClasses]"
            :data-type="header.key === 'etaDate' ? 'eta-date' : false"
          >
            {{ header.label }}
          </th>
        </thead>

        <tbody>
          <template v-for="item in paginatedData">
            <account-order-details-shipment-item :key="generateItemKey(item)" :item="item" :selectable="false" />
          </template>
        </tbody>
      </table>

      <div class="grid-x grid-margin-x mb-5">
        <div class="cell medium-6 large-8">
          <pagination-controls
            v-if="pageCount > 1"
            ref="pagination"
            :page-number="pageNumber"
            :page-count="pageCount"
            @page-change="pageChange"
            @prev-page="prevPage"
            @next-page="nextPage"
          />
        </div>
        <div v-if="!isConsumerFriendlyMode" class="cell medium-6 large-4">
          <div v-cloak class="block-list text-base">
            <div class="block-list__item w-full" qaid="order.shipment.subtotal">
              <span>{{ $t('text.order.subtotal') }}</span>
              <span>{{ subtotal }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </account-order-details-grid>
</template>

<script>
export default {
  props: {
    orderData: {
      type: Object,
      required: true,
      default: () => null,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    shipment: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      status: '',
    };
  },
  computed: {
    subtotal() {
      return this.orderData ? this.orderData.subtotals[this.shipment]?.prices?.subtotal?.formattedValue : '';
    },
  },
};
</script>
