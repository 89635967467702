<template>
  <div class="mt-6 lg:mt-0">
    <pdl-section-header size="sm" :is-dividing="true" class="pt-2 lg:pt-0">
      <template slot="content">
        <pdl-heading :level="3">
          {{ title }}
        </pdl-heading>
      </template>
    </pdl-section-header>
    <trek-button
      v-if="!totalFiltersSelected"
      secondary
      block
      icon-only
      icon="highlight_off"
      class="hide-for-large mt-2"
      :aria-label="$t('search.nav.done.button')"
      data-close
    >
    </trek-button>
    <trek-button v-else primary block class="hide-for-large mt-2" data-close>
      <span>{{ $t('search.nav.done.button') }}</span>
    </trek-button>
  </div>
</template>

<script>
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import TrekButton from '@/components/TrekButton';

export default {
  name: 'FacetsHeader',

  components: {PdlSectionHeader, PdlHeading, TrekButton},

  props: {
    selectedFilters: {
      type: [Array, Object],
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    totalFiltersSelected() {
      return !this.selectedFilters ? 0 : this.selectedFilters.length;
    },
  },
};
</script>
