<template>
  <div v-if="!!deliveryMode.length">
    <pdl-label :qaid="`${qaid}-title`" class="mb-1">{{ $t('checkout.summary.delivery_method') }}</pdl-label>
    <p :qaid="`${qaid}-name`">{{ deliveryMode }}</p>

    <checkout-edit-link :qaid="qaid" :link-config="linkConfig" />
  </div>
</template>

<script>
import {PdlLabel} from '@pedal/pdl-label';
import CheckoutEditLink from '@/components/checkout/CheckoutEditLink.vue';

export default {
  name: 'DeliveryMethod',
  components: {PdlLabel, CheckoutEditLink},
  props: {
    deliveryMode: {
      type: String,
      default: '',
    },
    linkConfig: {
      type: Object,
      default: () => null,
    },
    qaid: {
      type: String,
      default: '',
    },
  },
};
</script>
