<template>
  <div class="form-group md:w-1/2 lg:w-1/3">
    <fieldset class="form-fieldset">
      <legend class="form-fieldset__legend" qaid="hold-options-header">
        {{ $t('text.holdOptions') }}
      </legend>
      <label
        v-for="(code, index) in shipmentsEntry.holdOptions.codes"
        :key="index"
        class="radio"
        :qaid="shipmentsEntry.holdOptions[index]"
      >
        <input
          v-model="selectedHoldOption"
          type="radio"
          :qaid="`hold-option-${code}`"
          :value="code"
          :name="`${shipmentsEntry.type}HoldRadio`"
          :disabled="isRepHold(code)"
          required="required"
        />
        <span class="control-indicator"></span>
        {{ holdOptionsMap[code] }}
      </label>
      <p class="form-help">* {{ $t('checkout.B2B.hold.cannotShipAllocated') }}</p>
    </fieldset>
    <div :class="['required', {'has-error': !!v$.note.$error}]" qaid="order-details-customer-notes">
      <label :for="`${shipmentsEntry.type}-customer-care-notes`">
        {{ $t('checkout.B2B.customerCareNotes') }}
        <span v-if="isCustomerCare" class="text-red ml-25">
          *
          <span class="show-for-sr">(required)</span>
        </span>
      </label>
      <textarea
        :id="`${shipmentsEntry.type}-customer-care-notes`"
        v-model="note"
        :name="`${shipmentsEntry.type}-customer-care-notes`"
        rows="6"
        maxlength="250"
        class="form-control"
        show-error-messages
        force-display-error
      >
      </textarea>
      <div class="form-feedback is-invalid">
        {{ getErrorMessage('note') }}
      </div>
    </div>
  </div>
</template>
<script>
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  mixins: [TrekValidationMixin],
  props: {
    shipmentsEntry: {
      type: Object,
      required: true,
    },
  },
  validations() {
    const validations = {
      note: {
        maxLength: this.trekValidators.maxLength('checkout.B2B.customerCareNotes', 250),
      },
    };

    const applyConditionalRequiredFieldValidation = () => {
      validations.note.required = this.trekValidators.required('checkout.B2B.customerCareNotes');
    };

    if (this.isCustomerCare) applyConditionalRequiredFieldValidation();

    return validations;
  },

  computed: {
    holdOptionsMap() {
      return {
        shipNow: this.$t('checkout.B2B.holdOrder.shipNow'),
        releaseLater: this.$t('checkout.B2B.holdOrder.releaseLater'),
        customerCare: this.$t('checkout.B2B.holdOrder.customerCare'),
      };
    },
    isCustomerCare() {
      return this.shipmentsEntry.holdOptions.selected === 'customerCare';
    },
    selectedHoldOption: {
      get() {
        return this.shipmentsEntry.holdOptions.selected;
      },
      set(value) {
        this.$emit('update-hold-options', {property: 'selected', value});
      },
    },
    note: {
      get() {
        return this.shipmentsEntry.holdOptions.note;
      },
      set(value) {
        this.$emit('update-hold-options', {property: 'note', value});
      },
    },
  },
  methods: {
    isRepHold(code) {
      return this.shipmentsEntry.holdOptions.isRepHold && code !== 'customerCare';
    },
  },
};
</script>
