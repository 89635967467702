<template>
  <div id="buying-zone" class="pdp-product-container">
    <section :class="{'h-screen': loading}">
      <div v-if="!loading">
        <pdp-no-product-data v-if="!productData" @tryAgain="loadProductData" />
        <component
          :is="productDetailsComponent"
          v-else
          :product="productData"
          :options="options"
          :copy-positioning-statement="copyPositioningStatement"
          :stock-message="stockMessage"
          :retailer-data="retailerData"
          :is-location-postal-code-correct="isLocationPostalCodeCorrect"
        >
          <template #consumer-messages>
            <slot name="consumer-messages"></slot>
          </template>
        </component>
      </div>
    </section>
  </div>
</template>

<script>
import {ProductApi} from '@/api/product';
import SchemaMarkup from '@/utils/schema-markup';
import BreadCrumbMarkup from '@/utils/richSearchResults/breadcrumb-markup';
import PdpB2bProductDetails from '@/components/containers/pdp/b2b/PdpProductDetails';
import PdpB2cProductDetails from '@/components/containers/pdp/b2c/PdpProductDetails';
import PdpNoProductData from '@/components/containers/pdp/PdpNoProductData';
import {PdlLoading} from '@pedal/pdl-loading';
import {mapActions, mapState, mapMutations} from 'vuex';

export default {
  name: 'PdpBuyingZoneContainer',

  components: {PdpNoProductData, PdlLoading},

  props: {
    copyPositioningStatement: {
      type: String,
      default: '',
    },
    productId: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      productData: null,
    };
  },
  computed: {
    ...mapState('backend', ['b2b']),
    ...mapState('productStockData', ['stockMessage']),
    ...mapState('retailerSearch', ['isLocationPostalCodeCorrect', 'retailerData', 'isLoadingRetailerData']),
    ...mapState('pdp', ['category']),
    productDetailsComponent() {
      return this.b2b ? PdpB2bProductDetails : PdpB2cProductDetails;
    },
  },
  watch: {
    async productData(updatedProductData) {
      if (!updatedProductData || this.b2b) return;

      BreadCrumbMarkup.watch(updatedProductData, this.category);

      if (!updatedProductData.powerReviewsID) return;
      this.fetchProductReviews(updatedProductData.powerReviewsID).then((reviewsData) => {
        SchemaMarkup.init().addProductDetails(updatedProductData).addPowerReviews(reviewsData).injectMarkup();
      });
    },
  },
  mounted() {
    this.loadProductData();
  },

  methods: {
    ...mapMutations('pdp', ['setProduct']),
    ...mapActions('powerReviews', ['fetchProductReviews']),
    ...mapActions('productBundle', ['addBundleTemplates']),
    initializeLegacyPdpHandlers() {
      // This method must be refactored.
      const event = new Event('init-pdp');
      window.dispatchEvent(event);
    },
    loadProductData() {
      this.loading = true;

      ProductApi.loadProduct(this.productId)
        .then((data) => {
          this.productData = data;
          const variantsWithBundle = this.productData.variants.filter(
            (variant) => variant.bundleTemplateIdList?.length > 0
          );

          if (variantsWithBundle.length > 0) {
            const bundleTemplateIdList = [];
            const skusWithBundle = [];
            variantsWithBundle.forEach((variant) => {
              skusWithBundle.push(variant.code);
              bundleTemplateIdList.push(variant.bundleTemplateIdList[0]);
            });

            this.addBundleTemplates({id: bundleTemplateIdList, sku: skusWithBundle});
          }

          this.$nextTick(() => {
            this.initializeLegacyPdpHandlers();
            this.loading = false;
          });
          this.$emit('is-archived', this.productData.isArchived);
          this.setProduct(this.productData);
        })
        .catch((reason) => {
          console.error(reason.message);
          this.loading = false;
          this.productData = null;
        });
    },
  },
};
</script>
