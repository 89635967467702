<template>
  <div qaid="pdp-pre-order" class="my-4">
    <div class="flex mb-25 gap-1 items-center">
      <pdl-icon name="shopping_cart_checkout" size="24" />
      <span class="font-semibold text-md" v-text="$t('text.preOrder')" />
    </div>
    <span qaid="pdp-pre-order-availability" class="text-blue font-medium">
      {{ $t('text.estimatedAvailability') }} {{ prioritizedDate }}
    </span>
  </div>
</template>
<script>
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {PdlIcon},
  props: {
    variant: {
      type: Object,
      required: true,
    },
  },
  computed: {
    prioritizedDate() {
      const {variant} = this;
      const {startDate, endDate} = variant.futureStock;
      return `${startDate} - ${endDate}`;
    },
  },
};
</script>
