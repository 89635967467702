<template>
  <pdl-icon size="18">
    <svg
      id="credit-card-sprite"
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="32px"
      height="32px"
      viewBox="0 0 74.000000 66.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,66.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path
          d="M94 546 c-17 -13 -19 -30 -22 -206 -3 -162 -1 -195 13 -216 l15 -24 264 0 c233 0 265 2 277 17 11 12 14 61 14 215 0 186 -1 201 -19 214 -29 21 -513 21 -542 0z m506 -161 l0 -55 -235 0 -235 0 0 55 0 55 235 0 235 0 0 -55z"
        />
      </g>
    </svg>
  </pdl-icon>
</template>
<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {PdlIcon},
};
</script>
