import storefrontInstance from '@/api/instances/storefront';
import {ProductType} from '@/constants/product';
import {
  AllocatedBackorderFileExportStatus,
  AllocatedBackorderSortCode,
  AllocatedBackorderSortOption,
} from '@/constants/allocated-backorder';
import {Url} from '@/utils/url';

const DownloadBackordersApi = {
  getDefaultExportOptions() {
    return {
      productTypes: [ProductType.Bike],
      pagination: {
        pageSize: 50,
        currentPage: 0,
      },
      filters: null,
      sort: AllocatedBackorderSortOption[AllocatedBackorderSortCode.QuantityAllocatedOrShipped],
    };
  },

  /**
   * Obtain ID of export request.
   * @return {Promise<string>}
   */
  async obtainExportId(payload) {
    const params = Object.assign(this.getDefaultExportOptions(), payload);
    const requestUrl = Url.create('/allocated/items/export/');
    const response = await storefrontInstance.post(requestUrl, params);
    const exportId = response?.data?.data ?? '';

    if (!exportId || !exportId.length) {
      throw new Error('Export ID is empty.');
    }

    return exportId;
  },

  /**
   * Checks export status and returns whether the exported file is ready to be downloaded or not.
   * @param exportId
   * @return {Promise<boolean>}
   */
  async isFileReady(exportId) {
    const requestUrl = `/allocated/items/export/status/${exportId}/`;
    const response = await storefrontInstance.get(requestUrl);
    const status = response?.data?.data ?? '';

    return status === AllocatedBackorderFileExportStatus.Completed;
  },

  /**
   * Get URL to download the file by export ID.
   * @param exportId
   * @return {string}
   */
  getFileDownloadUrl(exportId) {
    return Url.withContextPath(`/allocated/items/export/${exportId}/`);
  },
};

export {DownloadBackordersApi};
