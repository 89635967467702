<template>
  <section class="grid-x grid-margin-x">
    <div class="cell small-12 medium-6 large-8">
      <ol class="order-items-checkout">
        <li class="order-items-checkout__column-headers row">
          <div class="order-items-checkout__column-header text large-6 columns invisible">
            {{ $t('basket.page.item') }}
          </div>
          <div class="order-items-checkout__column-header text large-2 columns">{{ $t('order.price') }}</div>
          <div class="order-items-checkout__column-header text large-2 columns">{{ $t('text.quantity') }}</div>
          <div class="order-items-checkout__column-header text large-2 columns">{{ $t('text.total') }}</div>
        </li>
        <order-items-table-entry
          v-for="entry in orderGroup.entries"
          :key="entry.product.code"
          :entry="entry"
          :applied-order-promotions="order.appliedOrderPromotions"
        />
      </ol>
    </div>
    <order-items-table-totals :order="order" />
  </section>
</template>

<script>
import OrderItemsTableEntry from '@/components/order-items-table/OrderItemsTableEntry.vue';
import OrderItemsTableTotals from '@/components/order-items-table/OrderItemsTableTotals.vue';

export default {
  name: 'OrderItemsTable',
  components: {OrderItemsTableEntry, OrderItemsTableTotals},
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    orderGroup: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
