import {CartApi, CustomCartApi} from '@/api/cart';
import {mapActions, mapState, mapMutations, mapGetters} from 'vuex';
import {ProductDetails} from '@/components/containers/pdp/utils/product-details';
import {PdlToastType} from '@/constants/pdl-toast-type';

const AddToCart = {
  mixins: [ProductDetails],

  computed: {
    ...mapState('backend', ['b2b']),
    ...mapState('productBundle', ['bundleTemplateIds']),
    ...mapState('skuGrid', ['isBundleModal']),
    ...mapGetters('miniCart', ['totalItems']),

    addToCartButtonCaption() {
      return this.$t(`${this.addToCartButtonCaptionText}`);
    },
  },

  methods: {
    ...mapActions('miniCart', ['fetchMiniCart']),
    ...mapActions('skuGrid', ['clearRegisterInputs']),
    ...mapMutations('pdp', ['setBundleDialogVisibility']),
    ...mapMutations('productBundle', ['setActiveIds']),
    ...mapMutations('backend', ['setBackendProps']),

    displayRichError(data) {
      const message = data.message.replace(/<[^>]+>/g, '');
      this.$notify({
        type: PdlToastType.ERROR,
        duration: 5000,
        showClose: true,
        message: message,
        action: data.action,
        dangerouslyUseHTMLString: true,
      });
    },

    displaySimpleError(message) {
      this.$notify({
        duration: 5000,
        type: PdlToastType.ERROR,
        message,
      });
    },

    displaySpectrumWarning(data) {
      this.$notify({
        duration: 5000,
        type: PdlToastType.WARNING,
        message: data.message,
        dangerouslyUseHTMLString: true,
      });
    },

    addToCart(code, quantity) {
      if (!quantity || !code) {
        return;
      }

      let bundleIds = [];
      let id = this.bundleTemplateIds.find((id) => id.includes(code));
      id && bundleIds.push(id);
      this.setActiveIds(bundleIds);

      if (bundleIds.length > 0 && quantity > 0) {
        this.getBuyingZoneBundleProductData(code);
        this.setBundleDialogVisibility({type: 'BUYING', isVisible: true});
      } else {
        this.addToCartNow(code, quantity);
      }
    },

    async addToCartNow(code, quantity) {
      this.setBundleDialogVisibility({type: 'BUYING', isVisible: false});
      this.addToCartButtonCaptionText = 'product.details.message.adding';
      this.isAddingToCart = true;
      this.cartSuccessMessage = '';

      try {
        let data = await CartApi.add({quantity, code});

        //response varies from B2B vs B2C- B2C using api JSON response, B2B using model response
        if (!this.b2b) data = data.data;

        if (data?.cartGuid?.length) {
          this.setBackendProps({
            cartGuid: data.cartGuid,
          });
        }

        if (data?.cartCode?.length) {
          this.setBackendProps({
            cartCode: data.cartCode,
          });
        }

        if (!data.quantityAdded) {
          // Failed to add product to cart. Display message.
          if (data.message) {
            this.displayRichError(data);
          }
        } else {
          // Product successfully added.
          if (this.b2b) {
            this.cartSuccessMessage = this.$t('basket.added.to.basket.quantity', [data.quantityAdded]);
          } else {
            this.cartSuccessMessage = this.$t('basket.added.to.basket.quantity.finalPricingCart', [data.quantityAdded]);
          }
          await this.fetchMiniCart();
        }
      } catch {
        this.displaySimpleError(this.$t('product.details.error.system'));
      } finally {
        this.isAddingToCart = false;
        this.addToCartButtonCaptionText = 'basket.add.to.basket';
      }
    },

    showToastMessage() {
      if (!this.isBundleModal) {
        this.$notify({
          type: PdlToastType.ERROR,
          showClose: true,
          duration: 0,
          message: this.$t('product.modal.bundle.cancelMessage'),
        });
      }
    },

    addToCartCustom(payload) {
      if (!payload) {
        return;
      }

      this.addToCartButtonCaptionText = this.$t('product.details.message.adding');
      this.isAddingToCart = true;
      this.cartSuccessMessage = '';

      CustomCartApi.add(payload)
        .then(async (data) => {
          if (!data.quantityAdded) {
            // Failed to add product to cart. Display message.
            if (data.customProductAddFailed) {
              this.displaySpectrumWarning(data);
            } else if (data.message) {
              this.displayRichError(data);
            }
          } else {
            // Product successfully added.
            this.cartSuccessMessage = this.$t('basket.added.to.basket.quantity', [data.quantityAdded]);

            this.fetchMiniCart();
          }
        })
        .catch(() => {
          this.displaySimpleError(this.$t('product.details.error.system'));
        })
        .finally(() => {
          this.isAddingToCart = false;
          this.addToCartButtonCaptionText = 'basket.add.to.basket';
        });
    },
  },
  data() {
    return {
      isAddingToCart: false,
      cartSuccessMessage: '',
      addToCartButtonCaptionText: 'basket.add.to.basket',
    };
  },
};

export {AddToCart};
