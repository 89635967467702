import store from '@/store';

export const CHECKBOX_CONFIG_OPTIONS = Object.freeze({
  REGISTRATION: 'REGISTRATION',
  ORDER_REVIEW: 'ORDER_REVIEW',
});

const getCheckboxUtilityFunctions = (isLoggedIn) => {
  return {
    getIsAllChecked: function () {
      return this.checkboxList.every((checkbox) => checkbox.checked);
    },
    getIsAllTermsChecked: function () {
      return this.checkboxList
        .filter((checkbox) => checkbox.name !== 'allTermsCheck')
        .every((checkbox) => checkbox.checked);
    },
    getIsAllRequiredChecked: function () {
      return this.getCheckboxList()
        .filter((checkbox) => this.required.includes(checkbox.name))
        .every((filteredCheckbox) => filteredCheckbox.checked);
    },
    toggleCheckbox: function (name) {
      const toggleCheckbox = this.checkboxList.find((checkbox) => checkbox.name === name);
      if (!toggleCheckbox) return;
      toggleCheckbox.setChecked(!toggleCheckbox.checked);

      const acceptAllCheckbox = this.checkboxList.find((checkbox) => checkbox.name === 'allTermsCheck');
      if (acceptAllCheckbox) {
        acceptAllCheckbox.setChecked(this.getIsAllTermsChecked());
      }
    },
    toggleAll: function () {
      if (this.getIsAllChecked()) {
        for (let checkbox of this.checkboxList) {
          checkbox.setChecked(!checkbox.checked);
        }
      } else {
        for (let checkbox of this.checkboxList) {
          checkbox.setChecked(true);
        }
      }
    },
    getCheckboxList: function () {
      return this.checkboxList.filter((checkbox) => {
        return isLoggedIn ? !checkbox.isGuestOnly : true;
      });
    },
    getIsEmpty: function () {
      return this.checkboxList.length <= 0;
    },
  };
};

const CheckboxConfiguration = (locale, isLoggedIn) => {
  const i18n = window.VueI18n;
  const encodedContextPath = store.state.backend.encodedContextPath ?? '';

  const specificLocalesConfig = {
    [CHECKBOX_CONFIG_OPTIONS.REGISTRATION]: {
      checkboxList: [
        {
          name: 'termsCheck',
          displayName: i18n.t('privacyPolicy.accept'),
          isGuestOnly: true,
        },
      ],
      required: ['termsCheck'],
    },
    [CHECKBOX_CONFIG_OPTIONS.ORDER_REVIEW]: {
      checkboxList: [
        {
          name: 'termsCheck',
          displayName: `${i18n.t('checkout.summary.termsAndConditions1')} ${i18n.t(
            'checkout.summary.termsAndConditions2'
          )}`,
          isGuestOnly: false,
        },
      ],
      required: ['termsCheck'],
    },
  };

  const checkboxConfigurationListTarget = {
    kr: {
      [CHECKBOX_CONFIG_OPTIONS.REGISTRATION]: {
        checkboxList: [
          {
            name: 'termsCheck',
            displayName: `${i18n.t('checkout.summary.termsAndConditions1')} ${i18n.t(
              'checkout.summary.termsAndConditions2'
            )}`,
            isGuestOnly: false,
            linkText: `${encodedContextPath}/terms-of-use/`,
          },
          {
            name: 'terms2Check',
            displayName: i18n.t('checkout.summary.termsAndConditions3'),
            isGuestOnly: true,
            linkText: `${encodedContextPath}/privacy-policy/`,
          },
          {
            name: 'terms3Check',
            displayName: i18n.t('checkout.summary.termsAndConditions4'),
            isGuestOnly: true,
            linkText: `${encodedContextPath}/privacy-policy/`,
          },
          {
            name: 'terms4Check',
            displayName: i18n.t('checkout.summary.termsAndConditions5'),
            isGuestOnly: true,
          },
        ],
        required: ['termsCheck', 'terms2Check', 'terms3Check', 'terms4Check'],
      },
      [CHECKBOX_CONFIG_OPTIONS.ORDER_REVIEW]: {
        checkboxList: [
          {
            name: 'termsCheck',
            displayName: `${i18n.t('checkout.summary.termsAndConditions1')} ${i18n.t(
              'checkout.summary.termsAndConditions2'
            )}`,
            isGuestOnly: false,
            linkText: `${encodedContextPath}/terms-of-use/`,
          },
          {
            name: 'terms2Check',
            displayName: i18n.t('checkout.summary.termsAndConditions3'),
            isGuestOnly: true,
            linkText: `${encodedContextPath}/privacy-policy/`,
          },
          {
            name: 'terms3Check',
            displayName: i18n.t('checkout.summary.termsAndConditions4'),
            isGuestOnly: true,
            linkText: `${encodedContextPath}/privacy-policy/`,
          },
          {
            name: 'terms4Check',
            displayName: i18n.t('checkout.summary.termsAndConditions5'),
            isGuestOnly: true,
          },
        ],
        required: ['termsCheck', 'terms2Check', 'terms3Check', 'terms4Check'],
      },
    },
    gb: specificLocalesConfig,
    at: specificLocalesConfig,
    de: specificLocalesConfig,
    default: {
      [CHECKBOX_CONFIG_OPTIONS.REGISTRATION]: {
        checkboxList: [
          {
            name: 'termsCheck',
            displayName: i18n.t('privacyPolicy.accept'),
            isGuestOnly: true,
          },
        ],
        required: ['termsCheck'],
      },
      [CHECKBOX_CONFIG_OPTIONS.ORDER_REVIEW]: {
        checkboxList: [
          {
            name: 'termsCheck',
            displayName: `${i18n.t('checkout.summary.termsAndConditions1')} ${i18n.t(
              'checkout.summary.termsAndConditions2'
            )}`,
            isGuestOnly: false,
          },
        ],
        required: ['termsCheck'],
      },
    },
  };

  const checkboxDefaultConfiguration = {
    checkboxList: [],
    required: [],
  };

  const addAcceptAll = (configObj) => {
    let filteredCheckboxList = configObj.checkboxList.filter((checkbox) => {
      return isLoggedIn ? !checkbox.isGuestOnly : true;
    });
    if (filteredCheckboxList.length > 1) {
      const checkAllConfig = addPropertiesToCheckbox({
        name: 'allTermsCheck',
        displayName: i18n.t('checkout.summary.acceptAll'),
      });

      //add check all option
      configObj.checkboxList?.unshift(checkAllConfig);
    }
    const modifiedConfigObject = {...configObj, checkboxList: addPropertiesToCheckboxList(configObj.checkboxList)};
    return modifiedConfigObject;
  };

  const addPropertiesToCheckboxList = (checkboxList) => {
    return checkboxList.map(addPropertiesToCheckbox);
  };

  const addPropertiesToCheckbox = (checkbox) => {
    return {
      ...checkbox,
      checked: false,
      setChecked: function (isChecked) {
        this.checked = isChecked;
        this.value = isChecked;
      },
    };
  };

  const defaultConfigHandler = {
    get(target, property) {
      return {...addAcceptAll(target[property]), ...getCheckboxUtilityFunctions(isLoggedIn)};
    },
  };

  let localeConfiguration = checkboxConfigurationListTarget[locale];
  if (!localeConfiguration) {
    localeConfiguration =
      checkboxConfigurationListTarget.default ??
      Object.values(CHECKBOX_CONFIG_OPTIONS).reduce((obj, configName) => {
        obj[configName] = checkboxDefaultConfiguration;
        return obj;
      }, {});
  }

  return new Proxy(localeConfiguration, defaultConfigHandler);
};

export default CheckboxConfiguration;
