<template>
  <div qaid="editorial-flair-media" class="image-container bg-black -mt-4 md:-mt-7 lg:mt-0 transform">
    <image-component
      :alt-text="$t(`altText.${assetId.replaceAll('_', '-')}`)"
      css-class="w-full"
      :asset-id="assetId"
      sizes="auto"
      transformation="fill"
      aspect-ratio="3x2"
      :is-responsive="true"
    />
  </div>
</template>

<script>
import ImageComponent from '@/components/ImageComponent';

export default {
  components: {ImageComponent},
  props: {
    assetId: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  --tw-translate-y: 3.5rem;

  @include breakpoint(large up) {
    transition: height 0.3s linear;
    flex: 1 1 0%;
  }
}
</style>
