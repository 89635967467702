<template>
  <li
    v-if="value.visible && items.length > 0"
    class="facet-group facet-group__list-item mb-25"
    :qaid="`${filterItemQaid}FilterType`"
    :class="{'is-active': isOverflowExpanded}"
  >
    <pdl-collapse :default-active="value.initiallyOpen" qaid="filter-facet-collapse">
      <pdl-collapse-item :name="name" :qaid="filterItemQaid">
        <template slot="title">
          {{ value.displayText }}
        </template>
        <ul class="facet-group__list">
          <li
            v-for="(item, i) in items"
            v-show="!isOverflow || (isOverflow && (i < overflowCount || (i >= overflowCount && isOverflowExpanded)))"
            :key="item.id"
            class="facet-group__list-item mb-25"
            :class="{'is-active': item.selected}"
          >
            <form class="flex justify-between" :class="{'label-selected': item.selected}">
              <facet-item-color
                v-if="value.facetCode === 'colorKeyValue'"
                :item-id="`${itemId}-${filterItemQaid}-${item.id}`"
                :qaid="`${toQaid(filterItemQaid + item.displayText)}`"
                :item="item"
              />
              <facet-item-text
                v-else
                :item-id="`${itemId}-${filterItemQaid}-${item.id}`"
                :qaid="`${toQaid(filterItemQaid + item.displayText)}`"
                :item="item"
              />
              <div class="facet-group__row-right flex items-center">
                <input
                  :id="`${itemId}-${filterItemQaid}-${item.id}`"
                  v-model="item.selected"
                  :disabled="item.facetCode === 'facetVendorJDE'"
                  class="facet-group__checkbox"
                  :class="`${itemId}-${filterItemQaid}-${item.id}`"
                  type="checkbox"
                  @click="itemStateChanged(item)"
                />
                <span v-show="item.selected" class="facet-group__decorator-refinement"></span>
              </div>
            </form>
          </li>
        </ul>
        <button
          v-if="isOverflow"
          class="w-full pt-2 flex items-center cursor-pointer"
          qaid="facet-overflow-toggle"
          @click="toggleOverflow"
        >
          <pdl-icon :name="overflowIcon" />
          <span class="text-sm italic">{{ overflowText }}</span>
        </button>
      </pdl-collapse-item>
    </pdl-collapse>
  </li>
</template>

<script>
import FacetItemText from './FacetItemText';
import FacetItemColor from './FacetItemColor';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {qaidMixin} from '@/utils/qaid-helper';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'FacetItem',

  components: {
    FacetItemText,
    FacetItemColor,
    PdlCollapse,
    PdlCollapseItem,
    PdlIcon,
  },

  mixins: [qaidMixin],

  props: {
    itemId: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      items: [],
      isOverflowExpanded: false,
      overflowCount: 7,
    };
  },

  computed: {
    filterItemQaid() {
      return this.toQaid(this.value.displayText);
    },

    selectedCount() {
      return this.items.map((item) => (item.selected ? 1 : 0)).reduce((acc, selected) => acc + selected, 0);
    },

    isOverflow() {
      return this.items.length > this.overflowCount;
    },

    overflowText() {
      return this.isOverflowExpanded ? this.$t('review.show.less') : this.$t('review.show.all');
    },

    overflowIcon() {
      return this.isOverflowExpanded ? 'expand_less' : 'expand_more';
    },
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.prepareItems();
      },
    },
  },

  mounted() {
    this.prepareItems();
  },

  methods: {
    toggleOverflow() {
      this.isOverflowExpanded = !this.isOverflowExpanded;
    },

    clearFilters() {
      let filters = [];
      this.items.forEach((item) => {
        if (item.selected) {
          item.selected = false;
          filters.push(item);
        }
      });
      this.$emit('state-changed', filters);
    },

    itemStateChanged(item) {
      if ('categoryURL' in item && item.categoryURL && item.categoryURL.length > 0) {
        window.location.href = item.categoryURL;
      } else if (!this.value.multiSelect) {
        this.items.forEach((item) => {
          item.selected = false;
        });
        item.selected = true;
        this.$emit('state-changed', this.items);
      } else {
        this.$emit('state-changed', item);
      }
    },

    prepareItems() {
      this.items = [];
      if (!this.value.facetOptionsValueDataList || !this.value.facetOptionsValueDataList.length) {
        return;
      }
      this.value.facetOptionsValueDataList.forEach((item) => {
        this.items.push({
          ...item,
          facetCode: this.value.facetCode,
          id: this.getItemId(item),
        });
      });
    },

    getItemId(item) {
      return `${item.value.toLowerCase().replace(/(\W|\s)/g, '-')}-product`;
    },
  },
};
</script>

<style lang="scss" scoped>
.facet-item-text + .facet-group__row-right {
  .facet-group__decorator-refinement {
    top: 4px;
  }
}
</style>
