<template>
  <div class="grid-container">
    <form :class="{'opacity-50': isWorking}" @submit.prevent="submit">
      <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="1">{{ $t('updatePwd.title') }}</pdl-heading>
        </template>
      </pdl-section-header>
      <p class="mb-3 text-base">{{ $t('updatePwd.description') }}</p>
      <pdl-callout v-if="message" :kind="messageType">
        <template #content>
          <span v-html="message" />
        </template>
      </pdl-callout>
      <div class="max-w-sm mb-6">
        <form-group
          ref="password"
          v-model="password"
          type="password"
          name="password"
          :label="$t('updatePwd.pwd')"
          :error="getErrorMessage('password')"
          :help-text="passwordHelpText"
          :show-error-messages="true"
          required
          force-display-error
          @focus="getErrorMessage('password') ? '' : (passwordHelpText = $t('register.infoPassword'))"
          @blur="passwordHelpText = ''"
        />
        <form-group
          v-model="passwordConfirmation"
          type="password"
          name="resetPasswordConfirmation"
          :label="$t('updatePwd.checkPwd')"
          :error="getErrorMessage('resetPasswordConfirmation')"
          :show-error-messages="true"
          required
          force-display-error
        />

        <div class="buttons">
          <trek-button primary type="submit" :disabled="isWorking" @click="submit">
            {{ $t('updatePwd.submit') }}
          </trek-button>
          <trek-button secondary @click="cancel">{{ $t('text.account.profile.cancel') }}</trek-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import {PdlCallout} from '@pedal/pdl-callout';
import FormGroup from '@/components/FormGroup';
import TrekButton from '@/components/TrekButton';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {UserAccountBase} from '@/components/containers/my-account/utils/user-account-base';

export default {
  name: 'ForgotPasswordResetContainer',
  components: {PdlCallout, FormGroup, PdlSectionHeader, PdlHeading, TrekButton},
  extends: UserAccountBase,
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      isWorking: false,
      passwordHelpText: '',
    };
  },
  validations() {
    return {
      password: {
        required: this.trekValidators.required('updatePwd.pwd'),
        password: this.trekValidators.password(),
      },
      passwordConfirmation: {
        required: this.trekValidators.required('updatePwd.checkPwd'),
        sameAsPassword: this.trekValidators.sameAs('updatePwd.checkPwd', this.password, 'updatePwd.pwd'),
      },
    };
  },

  methods: {
    /**
     * Handle email update.
     * @return {Promise<void>}
     */
    async processUpdate() {
      this.isWorking = true;
      try {
        let data = {
          pwd: this.password,
          checkPwd: this.passwordConfirmation,
          token: this.options.token,
        };

        if (this.options.CSRFParamName) {
          data[this.options.CSRFParamName] = this.options.CSRFToken;
        }

        const response = await this.api.recoveryUpdatePassword(window.location.href, data);

        this.displayMessage(response?.data);
      } catch (e) {
        this.displayError(e.message);
      } finally {
        this.isWorking = false;
      }
    },
  },
};
</script>
