<template>
  <div>
    <pdl-callout v-if="error" kind="warning">
      <template #content>
        <span v-html="error" />
      </template>
    </pdl-callout>
    <form action="#" :class="{'opacity-50': isLoading}" autocomplete="on" @submit.prevent="onSubmit">
      <form-group
        v-model="email"
        type="email"
        name="email"
        :label="bp('guest.email')"
        required
        force-display-error
        show-error-messages
        :error="getErrorMessage('email')"
      />
      <trek-button primary :disabled="isLoading" type="submit" @click="onSubmit">
        <span v-html="bp('checkout.guest')"></span>
      </trek-button>
    </form>
  </div>
</template>
<script>
import TrekButton from '@/components/TrekButton';
import FormGroup from '@/components/FormGroup';
import {PdlCallout} from '@pedal/pdl-callout';
import {UserApi} from '@/api/user';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  name: 'GuestCheckout',
  components: {FormGroup, TrekButton, PdlCallout},
  mixins: [TrekValidationMixin],
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  validations() {
    return {
      email: {
        required: this.trekValidators.required('login.email'),
        email: this.trekValidators.email('login.email'),
      },
    };
  },
  data() {
    return {
      isLoading: false,
      email: '',
      error: '',
    };
  },
  watch: {
    isLoading(value) {
      if (value) {
        this.error = '';
      }
    },
  },
  methods: {
    getFormData() {
      return {
        email: this.email,
        CSRFToken: this.options.CSRFToken,
      };
    },
    async processGuestLogin() {
      try {
        this.isLoading = true;
        this.handleResponse(await UserApi.guestLogin(this.getFormData()));
      } catch (e) {
        this.error = this.bp('server.error');
        this.isLoading = false;
      }
    },
    handleResponse(redirectUrl) {
      if (!redirectUrl || redirectUrl.indexOf('error=true') > -1) {
        this.error = this.bp('server.error');
        this.isLoading = false;
      } else {
        window.location.href = redirectUrl;
      }
    },
    onSubmit() {
      this.v$.$validate().then((isValid) => {
        if (isValid) {
          this.processGuestLogin();
        }
      });
    },
  },
};
</script>
