<template>
  <div class="grid-container">
    <form :class="{'opacity-50': isWorking}" @submit.prevent="submit">
      <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="1">
            {{ $t('profile.update.languagePreference') }}
          </pdl-heading>
        </template>
      </pdl-section-header>

      <pdl-callout v-if="message" :kind="messageType">
        <template #content>
          <span v-html="message" />
        </template>
      </pdl-callout>
      <div class="max-w-sm mb-6">
        <form-group
          v-if="hasData"
          v-model="preferredLanguage"
          name="language"
          type="select"
          :label="$t('countryselector.language')"
          :multi-items="countryData"
        />
        <div class="buttons">
          <trek-button primary type="submit" qaid="language-save-btn" :disabled="isWorking" @click="submit">
            <span>{{ $t('text.button.save') }}</span>
          </trek-button>
          <trek-button secondary qaid="language-cancel-btn" @click="cancel">
            <span>{{ $t('text.account.profile.cancel') }}</span>
          </trek-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import get from 'lodash/get';
import {PdlCallout} from '@pedal/pdl-callout';
import FormGroup from '@/components/FormGroup';
import TrekButton from '@/components/TrekButton';
import {UserAccountBase} from '@/components/containers/my-account/utils/user-account-base';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  name: 'EditLanguageContainer',
  components: {PdlCallout, FormGroup, TrekButton, PdlSectionHeader, PdlHeading},
  extends: UserAccountBase,
  props: {
    customerPreferredLanguage: {
      type: String,
      required: false,
      default: 'en',
    },
  },
  data() {
    return {
      countryData: {},
      preferredLanguage: '',
    };
  },
  computed: {
    hasData() {
      return Object.keys(this.countryData).length > 0;
    },
  },
  async created() {
    try {
      this.isWorking = true;
      const response = await this.api.retrieveAvailableLanguages();
      this.countryData = this.getCountryData(get(response, 'data.data'));
      this.preferredLanguage = this.customerPreferredLanguage;

      if (!this.preferredLanguage && this.hasData) {
        this.preferredLanguage = this.countryData[0].isocode;
      }
    } finally {
      this.isWorking = false;
    }
  },
  methods: {
    async processUpdate() {
      try {
        this.isWorking = true;
        const response = await this.api.updateLanguage(this.preferredLanguage);
        this.displaySuccess(get(response.data, 'meta.feedback.message.basePropertyValue'));
      } catch (e) {
        this.displayError(get(e.response.data, 'meta.feedback.message.basePropertyValue'));
      } finally {
        this.isWorking = false;
      }
    },

    /**
     * Prepare country data in format needed by select.
     * @param data
     * @return {[]}
     */
    getCountryData(data) {
      let countryData = [];
      if (!data || !data.length) {
        return countryData;
      }

      data.forEach((item) => {
        countryData.push({
          value: item.isocode,
          displayName: item.name,
        });
      });

      return countryData;
    },
  },
};
</script>
