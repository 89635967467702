<template>
  <li class="order-items-checkout__item row">
    <div class="small-12 large-6 columns">
      <section class="order-tile">
        <div class="small-4 columns">
          <div class="order-tile__image">
            <pdl-image
              :src="entry.product.primaryImages.thumbnail.url"
              :alt="entry.product.primaryImages.thumbnail.altText"
            />
          </div>
        </div>
        <div class="order-tile__details small-8 columns">
          <p :qaid="`order-confirmation-product-title`" class="text-sm font-bold">{{ entry.product.name }}:</p>
          <p class="order-tile__code text-xsmall-regular" qaid="order-confirmation-product-sku">
            {{ $t('product.variants.sku') }}&nbsp;{{ entry.product.code }}
          </p>
          <dl
            v-for="(variantCategory, index) in entry.product.variantMatrix"
            :key="index"
            class="order-tile-checkout__list"
          >
            <dt
              class="order-tile-checkout__term text-small-strong"
              :qaid="`order-confirmation-product-${variantCategory.parentVariantCategory.code}-title`"
            >
              {{ variantCategory.parentVariantCategory.name }}:&nbsp;
            </dt>
            <dd
              class="order-tile-checkout__definition text-small"
              :qaid="`order-confirmation-product-${variantCategory.parentVariantCategory.code}-value`"
            >
              {{ variantCategory.variantValueCategory.name }}
            </dd>
          </dl>
          <dl class="order-tile__quantity order-tile__list">
            <dt class="order-tile__term text-small-strong">{{ $t('basket.page.qty') }}:</dt>
            <dd class="order-tile__definition text-small">{{ entry.quantity }}</dd>
          </dl>
          <dl class="order-tile__price order-tile__list">
            <dt class="order-tile__term">{{ $t('order.price') }}:</dt>
            <dd class="order-tile__definition text-small-xstrong">
              {{ displayPriceOrFree(entry.totalPrice) }}
            </dd>
          </dl>
        </div>
      </section>
    </div>
    <div
      class="order-items-checkout__detail order-items-checkout__detail--hide-medium text-small-regular large-2 columns"
      qaid="order-confirmation-product-price"
    >
      <div v-if="entry.isLineLevelPromotionPresent">
        <div
          v-if="appliedOrderPromotions.length"
          class="order-items-checkout__detail order-items-checkout__detail--hide-medium text-small-regular large-2 columns"
        >
          <span class="promoPrice">{{ entry.formattedAdjustedUnitPrice }}</span>
          <br />
          <span class="beforeSalePrice">
            {{ displayPriceOrFree(entry.basePrice) }}
          </span>
        </div>
        <div v-else>
          {{ entry.formattedAdjustedUnitPrice }}
        </div>
      </div>
      <div v-else>
        {{ displayPriceOrFree(entry.basePrice) }}
      </div>
    </div>
    <div
      class="order-items-checkout__detail order-items-checkout__detail--hide-medium text-small-regular large-2 columns"
      qaid="order-confirmation-product-quantity"
    >
      {{ entry.quantity }}
    </div>
    <div
      class="order-items-checkout__detail order-items-checkout__detail--hide-medium text-small-xstrong large-2 columns"
      qaid="order-confirmation-product-total-price"
    >
      {{ displayPriceOrFree(entry.totalPrice) }}
    </div>
  </li>
</template>

<script>
import {PdlImage} from '@pedal/pdl-image';

export default {
  name: 'OrderItemsTableEntry',
  components: {PdlImage},
  props: {
    entry: {
      type: Object,
      default: () => ({}),
    },
    appliedOrderPromotions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    displayPriceOrFree(priceData) {
      return priceData.value ? priceData.formattedValue : this.$t('text.free');
    },
  },
};
</script>
