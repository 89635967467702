<template>
  <form :class="{'opacity-50': isWorking}" autocomplete="on" @submit.prevent="submit">
    <pdl-callout v-if="message" :kind="messageType">
      <template #content>
        <span v-html="message" />
      </template>
    </pdl-callout>
    <form-group
      v-model="firstName"
      type="text"
      name="firstName"
      :label="$t('register.firstName')"
      :error="getErrorMessage('firstName')"
      show-error-messages
      force-display-error
    />
    <form-group
      v-model="lastName"
      type="text"
      name="lastName"
      :label="$t('register.lastName')"
      :error="getErrorMessage('lastName')"
      show-error-messages
      force-display-error
    />
    <form-group
      v-model="email"
      type="email"
      name="email"
      :label="$t('register.email')"
      :error="getErrorMessage('email')"
      show-error-messages
      force-display-error
    />
    <form-group
      ref="password"
      v-model="password"
      type="password"
      name="password"
      :label="$t('register.pwd')"
      :error="getErrorMessage('password')"
      :autocomplete="autocompleteAttributeValue"
      :help-text="passwordHelpText"
      show-error-messages
      force-display-error
      @focus="getErrorMessage('password') ? '' : (passwordHelpText = $t('register.infoPassword'))"
      @blur="passwordHelpText = ''"
    />
    <form-group
      v-model="passwordConfirmation"
      type="password"
      name="registerPasswordConfirmation"
      :label="$t('register.checkPwd')"
      :error="getErrorMessage('passwordConfirmation')"
      :autocomplete="autocompleteAttributeValue"
      show-error-messages
      force-display-error
    />
    <div class="text-sm mb-2">{{ $t('register.emailOptIn') }}</div>
    <form-group
      type="checkbox"
      name="emailOptInOptions"
      :multi-items="emailOptInOptions"
      @change="
        (e) => {
          $set(this, e.target.name, e.target.checked);
        }
      "
    />
    <register-consent-checkboxes @requiredTermsChecked="handleChecked" />
    <trek-button primary type="submit" :disabled="isSubmitDisabled" class="mb-4">
      <span>{{ $t('register.submit') }}</span>
    </trek-button>
    <div class="text-xs leading-tight text-gray-70">
      <slot name="privacy-notice"></slot>
    </div>
  </form>
</template>

<script>
import {mapState} from 'vuex';
import {PdlCallout} from '@pedal/pdl-callout';
import FormGroup from '@/components/FormGroup';
import TrekButton from '@/components/TrekButton';
import {UserAccountBase} from '@/components/containers/my-account/utils/user-account-base';
import RegisterConsentCheckboxes from '@/components/containers/my-account/registration/RegisterConsentCheckboxes.vue';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';

const AUTOCOMPLETE_ATTRIBUTE_VALUE = 'new-password';

export default {
  name: 'UserRegister',
  components: {PdlCallout, FormGroup, TrekButton, RegisterConsentCheckboxes},
  extends: UserAccountBase,
  props: {
    distanceUnit: {
      type: String,
      default: '',
      required: true,
    },
  },
  validations() {
    return {
      firstName: {
        required: this.trekValidators.required('register.firstName'),
      },
      lastName: {
        required: this.trekValidators.required('register.lastName'),
      },
      email: {
        required: this.trekValidators.required('register.email'),
        email: this.trekValidators.email('register.email'),
      },
      password: {
        required: this.trekValidators.required('register.pwd'),
        password: this.trekValidators.password(),
      },
      passwordConfirmation: {
        sameAsPassword: this.trekValidators.sameAs('register.checkPwd', this.password, 'register.pwd'),
      },
    };
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      optInTrek: false,
      optInElectra: false,
      passwordConfirmation: '',
      titleCode: 'mr',
      autocompleteAttributeValue: AUTOCOMPLETE_ATTRIBUTE_VALUE,
      passwordHelpText: '',
      isAllConsentChecked: false,
    };
  },

  computed: {
    emailOptInOptions() {
      return this.options.electraOptinToggle
        ? [
            {displayName: this.$t('text.trek'), name: 'optInTrek', checked: false, value: true},
            {displayName: this.$t('text.electra'), name: 'optInElectra', checked: false, value: true},
          ]
        : [{displayName: this.$t('text.trek'), name: 'optInTrek', checked: false, value: true}];
    },

    privacyPageUrl() {
      return `${this.encodedContextPath}/company/legal_policies/privacy_policy_terms_of_use/`;
    },
    isSubmitDisabled() {
      return !this.isAllConsentChecked || this.isWorking;
    },

    ...mapState('backend', ['encodedContextPath']),
  },

  methods: {
    /**
     * Handle response from API.
     */
    handleResponse(response) {
      const message = response?.data?.meta?.feedback?.message?.basePropertyValue;

      if (checkMetaFeedbackType(response?.data, MetaFeedbackType.ERROR)) {
        if (message !== this.$t('500error.message')) {
          this.errorFields = response?.data?.meta?.feedback?.errorFields;
        }
        this.displayError(message);
      } else {
        this.displaySuccess(message);
        this.resetForm();
      }
    },

    /**
     * Reset form data
     */
    resetForm() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.password = '';
      this.optInTrek = true;
      this.optInElectra = true;
      this.passwordConfirmation = '';
      this.v$.$reset();
    },

    /**
     * Perform signup.
     * @return {Promise<void>}
     */
    async processUpdate() {
      try {
        this.isWorking = true;
        this.errorFields = {};
        this.handleResponse(await this.api.register(this.getFormData()));
      } catch (e) {
        this.displayError(e?.response?.data?.meta?.feedback?.message?.basePropertyValue);
      } finally {
        this.isWorking = false;
      }
    },

    /**
     * Get object containing data to be sent to API.
     * @return object
     */
    getFormData() {
      return {
        titleCode: this.titleCode,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        pwd: this.password,
        checkPwd: this.passwordConfirmation,
        CSRFToken: this.options.CSRFToken,
        optin: this.optInTrek,
        optinElectra: this.options.electraOptinToggle && this.optInElectra,
      };
    },
    handleChecked(isAllChecked) {
      this.isAllConsentChecked = isAllChecked;
    },
  },
};
</script>
