<template>
  <div class="b2b-grid__stock-watch lg:justify-center">
    <button
      v-for="(warehouse, index) in warehouses"
      :key="index"
      :disabled="warehouse.stockWatchEnabled === false"
      class="b2b-grid__stock-watch__indicator"
      :class="[
        {'text-success': warehouse.stockWatchActive === true},
        {disabled: warehouse.stockWatchEnabled === false},
        {'cursor-not-allowed': warehouse.stockWatchEnabled === false},
        {enabled: warehouse.stockWatchEnabled === true},
      ]"
      @click="updateStockWatchState(productIndex, warehouse, index)"
    >
      <pdl-icon size="18" :name="warehouse.stockWatchEnabled === true ? 'visibility' : 'visibility_off'" />
      <span :class="`b2b-grid__stock-watch__label`">
        {{ warehouse.id }}
      </span>
    </button>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {
    PdlIcon,
  },
  props: {
    warehouses: {
      type: Array,
      default: () => [],
    },
    productIndex: {
      type: String,
      default: '',
    },
    isForNotifications: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      endpoint: '/products/stock-watch',
    };
  },

  methods: {
    updateStockWatchState(productIndex, warehouse, index) {
      if (this.isForNotifications) {
        this.endpoint = '/notification-settings/stock-watch';
      }
      storefrontInstance
        .put(this.endpoint, {
          stockWatchProduct: productIndex,
          stockWatchWarehouse: warehouse.id,
          stockWatchActive: !warehouse.stockWatchActive,
        })
        .then(({data: {data: item}}) => {
          if (this.isForNotifications) {
            this.$emit('update-stock-watch-state', {item, productIndex});
          } else {
            this.$emit('update-stock-watch-state', {item, index});
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
