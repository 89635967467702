import {Url} from '@/utils/url';
import {mapActions} from 'vuex';
import {StockStatus} from '@/constants/stock-status';

function getFirstVariant(product) {
  const parentGroup = product.variantAttributesDirectory[0];
  const options = getVariantsForGroup(product, parentGroup.options?.[0]?.colorSwatchName);
  return options?.[0];
}

function getVariantsForGroup(product, value) {
  const group = product.variantAttributesDirectory[0];
  const groupOption = group.options.find((option) => option.colorSwatchName === value);
  return product.variants.filter((item) => item.variantAttributes?.[group.code] === groupOption?.value);
}

function findFirstInStock(product, attributeIndex = 0, optionIndex = 0) {
  const {variantAttributesDirectory, variants} = product;
  if (attributeIndex >= variantAttributesDirectory.length) return null;

  const currentAttribute = variantAttributesDirectory[attributeIndex];
  const currentOption = currentAttribute.options[optionIndex];
  const variant = variants.find((item) => {
    return (
      item.variantAttributes[currentAttribute.code] === currentOption.value &&
      item.globalStockStatus === StockStatus.IN_STOCK
    );
  });

  if (variant) return variant;
  if (optionIndex < currentAttribute.options.length - 1) {
    return findFirstInStock(product, attributeIndex, optionIndex + 1);
  }

  return findFirstInStock(product, attributeIndex + 1, 0);
}

function findFirstInStockVariant(product, colorCode) {
  const firstInStockVariant = findFirstInStock(product);
  const variantsForColor = getVariantsForGroup(product, colorCode);
  const inStockVariantsForColor = variantsForColor.filter(
    (variant) => variant.globalStockStatus === StockStatus.IN_STOCK
  );

  return inStockVariantsForColor?.[0] ?? firstInStockVariant;
}

function findFirstColorVariant(product, colorCode) {
  const options = getVariantsForGroup(product, colorCode);
  return options?.[0];
}

const InitProductAttributes = {
  created() {
    this.initProductAttributes();
  },

  watch: {
    initAttributeSelection(value) {
      this.setInitAttributeSelection(value);
    },
  },

  methods: {
    ...mapActions('pdp', ['setInitAttributeSelection']),
    selectVariantFromQueryString() {
      const queryProductId = window.location.href.match(/\/[ap]\/([a-zA-Z0-9-]+)\//i)?.[1] ?? '';
      if (!queryProductId || !this.product.variants || this.product.code === queryProductId) {
        return false;
      }

      let setFromVariant = false;
      const setInitProductAttributes = (variants) => {
        if (!variants?.length) {
          return false;
        }

        this.setProductAttributesFromVariantId(queryProductId);
        setFromVariant = true;
      };

      setInitProductAttributes(this.product.variants);
      return setFromVariant;
    },

    setProductAttributesFromVariantId(variantId) {
      const {$set, product, initAttributeSelection} = this;
      const variant = product.variants.find((item) => item.code === variantId);
      if (!variant?.variantAttributes) return;

      Object.keys(variant.variantAttributes).forEach((code) => {
        $set(initAttributeSelection, code, variant.variantAttributes[code]);
      });
    },

    initProductAttributes() {
      const {product} = this;
      if (!product.variants || !product.variants.length || this.selectVariantFromQueryString()) {
        return;
      }

      const params = Url.parse(window.location.search);
      const inStockVariant =
        this.options.eCommAvailable || this.b2b ? findFirstInStockVariant(product, params.colorCode) : null;

      let variant;
      if (inStockVariant) {
        variant = inStockVariant;
      } else if (params.colorCode) {
        variant = findFirstColorVariant(product, params.colorCode);
      } else {
        variant = getFirstVariant(product);
      }

      if (variant?.code) {
        this.setProductAttributesFromVariantId(variant.code);
      }
    },
  },

  data() {
    return {
      initAttributeSelection: {},
      initVariant: '',
    };
  },
};

export {InitProductAttributes};
