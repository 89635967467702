<template>
  <div class="content">
    <form-group
      v-if="paymentProviderItemList.length > 1"
      v-model="paymentProvider"
      label-classes="items-center"
      name="payment-option-radio"
      type="radio"
      :multi-items="paymentProviderItemList"
      @change="handleChange"
    />
    <credit-card-form
      v-if="paymentProvider === PaymentProvider.CYBERSOURCE"
      :month-list="monthList"
      :year-list="yearList"
      :card-type-list="cardTypeList"
    />
    <secure-fields v-if="paymentProvider === PaymentProvider.GR4VY" />
    <ideal-form v-if="paymentProvider === PaymentProvider.CYBERSOURCE_IDEAL" :bank-list="bankList" />
    <kcp-payment-form v-if="paymentProvider === PaymentProvider.CYBERSOURCE_KCP" />
  </div>
</template>

<script>
import FormGroup from '@/components/FormGroup';
import {mapMutations, mapState} from 'vuex';
import {PaymentProviderConfig, PaymentProvider} from '@/constants/payment-provider.js';
import CreditCardForm from '@/components/checkout/forms/billing/CreditCardForm.vue';
import IdealForm from '@/components/checkout/forms/billing/IdealForm.vue';
import KcpPaymentForm from '@/components/checkout/providers/kcp/KcpPaymentForm.vue';
import SecureFields from '@/components/checkout/forms/billing/SecureFields.vue';

export default {
  components: {FormGroup, CreditCardForm, IdealForm, KcpPaymentForm, SecureFields},
  props: {
    paymentProviders: {
      type: Object,
      default: () => ({}),
    },
    bankList: {
      type: [Array, String],
      default: () => [],
    },
    cardTypeList: {
      type: [Array, String],
      default: () => [],
    },
    monthList: {
      type: [Array, String],
      default: () => [],
    },
    yearList: {
      type: [Array, String],
      default: () => [],
    },
  },
  data() {
    return {
      PaymentProvider,
    };
  },
  computed: {
    ...mapState('checkout', ['selectedPaymentProvider']),
    paymentProviderItemList() {
      const providerItems = Object.keys(this.paymentProviders)
        .map((provider) => {
          const providerConfig = PaymentProviderConfig[provider];
          if (!providerConfig) return;

          return {
            component: providerConfig.component,
            value: providerConfig.value,
          };
        })
        .filter((provider) => provider);
      return providerItems.filter(({value}) => PaymentProvider.applyProviderFilters(value));
    },
    paymentProvider: {
      get() {
        return this.selectedPaymentProvider?.value;
      },
      set(paymentProvider) {
        this.setSelectedPaymentProvider(PaymentProviderConfig[paymentProvider]);
      },
    },
  },
  mounted() {
    this.setSelectedPaymentProvider(this.getDefaultSelection(this.paymentProviderItemList));
  },
  methods: {
    ...mapMutations('checkout', ['setSelectedPaymentProvider']),
    getDefaultSelection(paymentProviderItemList) {
      return PaymentProviderConfig[paymentProviderItemList?.[0]?.value];
    },
    handleChange(event) {
      this.setSelectedPaymentProvider(PaymentProviderConfig[event.target.value]);
    },
  },
};
</script>
