const Attribute = {
  props: {
    product: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    attribute: {
      type: Array,
      required: true,
    },
    initAttributeSelection: {
      type: Object,
      default: () => null,
    },
    selectedAttributes: {
      type: Object,
      default: () => {},
    },
    updateSelectedAttributes: {
      type: Function,
      default: () => {},
    },
    option: {
      type: Object,
      default: () => {},
    },
    code: {
      type: String,
      default: '',
    },
  },
};

export {Attribute};
