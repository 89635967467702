<template>
  <div class="grid-container pb-5">
    <pdl-section-header size="xl" :is-dividing="true">
      <template slot="content">
        <pdl-heading :level="1" qaid="techtext-header">{{ $t('text.tech') }}</pdl-heading>
      </template>
    </pdl-section-header>

    <section>
      <pdl-section-header size="md" class="mb-3">
        <template slot="content">
          <pdl-heading :level="3" qaid="tech-select-product-header">{{ $t('text.selectAProduct') }}</pdl-heading>
        </template>
      </pdl-section-header>
    </section>

    <section>
      <div class="grid-x grid-margin-x grid-margin-y">
        <div class="cell medium-4">
          <fieldset qaid="techtext-fieldset" class="form-fieldset max-w-sm">
            <label for="select-year" class="form-label">
              {{ $t('contactUs.year') }}
            </label>
            <div class="select mb-2">
              <select
                id="select-year"
                v-model="selectedYear"
                qaid="tech-year-dropdown"
                class="form-control"
                @change="retrieveModelOptions"
              >
                <option :value="null" disabled>{{ $t('form.select.empty') }}</option>
                <option v-for="(year, index) in yearData" :key="index" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>

            <label for="select-model" class="form-label">
              {{ $t('garage.bike.type') }}
            </label>
            <tech-model-tippy-container
              :options-list="modelData"
              :please-select-model="$t('bike.model.notSelected')"
              :popup-close-tooltip="$t('popup.close')"
              @selection-made="retrieveColorOptions"
            ></tech-model-tippy-container>

            <label for="select-size" class="form-label">
              {{ $t('text.variant.color') }}
            </label>
            <div class="select mb-2">
              <select
                id="select-size"
                v-model="selectedColor"
                qaid="tech-color-dropdown"
                class="form-control"
                @change="retrieveSizeOptions"
              >
                <option :value="null" disabled>{{ $t('form.select.empty') }}</option>
                <option v-for="(color, index) in colorData" :key="index" :value="index">
                  {{ color.name }}
                </option>
              </select>
            </div>

            <label for="select-size" class="form-label">
              {{ $t('text.variant.size') }}
            </label>
            <div class="select mb-2">
              <select
                id="select-size"
                v-model="selectedSize"
                qaid="tech-size-dropdown"
                class="form-control"
                @change="setSkuAndEnableSubmit"
              >
                <option :value="null" disabled>{{ $t('form.select.empty') }}</option>
                <option v-for="(item, index) in sizeData" :key="index" :value="item.id">
                  {{ item.size }}
                </option>
              </select>
            </div>
          </fieldset>
          <div class="buttons">
            <trek-button
              primary
              :disabled="readyToSubmit === false"
              qaid="tech-view-product-btn"
              @click="setSkuVuexAndRelocate"
            >
              {{ $t('text.viewProduct') }}
            </trek-button>
          </div>
        </div>
        <div class="cell sm:hidden md:inline-block medium-8" qaid="tech-static-image">
          <img :src="staticImageUrl" alt="" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {mapMutations, mapState} from 'vuex';
import TrekButton from '@/components/TrekButton';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {TrekButton, PdlSectionHeader, PdlHeading},
  props: {
    staticImageUrl: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loading: true,
      yearData: [],
      modelData: [{id: '-1'}],
      colorData: [],
      sizeData: [],
      selectedYear: null,
      selectedModel: null,
      selectedSize: null,
      selectedColor: null,
      readyToSubmit: false,
      selectedSku: '0',
    };
  },

  computed: {
    ...mapState('backend', ['encodedContextPath']),
  },

  created() {
    this.resetModelData();
    this.retrieveYearOptions();
  },

  methods: {
    resetSizeData() {
      this.setFormReadyStatus(false);
      this.sizeData = [];
      this.selectedSize = null;
      this.selectedSku = '0';
    },

    resetColorData() {
      this.colorData = [];
      this.selectedColor = null;
      this.resetSizeData();
    },

    resetModelData() {
      this.modelData = [{id: '-1'}];
      this.selectedModel = null;
      this.resetColorData();
    },

    setFormReadyStatus(val) {
      this.readyToSubmit = val;
    },

    retrieveYearOptions() {
      this.loading = true;
      storefrontInstance
        .get('/technical-specification/selector/bikes')
        .then(({data}) => {
          this.yearData = data.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    retrieveModelOptions() {
      this.loading = true;
      this.resetModelData();
      storefrontInstance
        .get(`/technical-specification/selector/bikes/${this.selectedYear}`)
        .then(({data}) => {
          this.modelData = [{id: '-1'}, ...data.data];
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    retrieveColorOptions(model) {
      this.loading = true;
      this.resetColorData();
      this.selectedModel = model.id;
      storefrontInstance
        .get('/technical-specification/selector/matrix/' + this.selectedModel)
        .then(({data}) => {
          this.colorData = data.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    retrieveSizeOptions() {
      // No need for an Ajax call here as size data is already included with each color
      this.resetSizeData();
      this.sizeData = this.colorData[this.selectedColor].sizes;
    },

    setSkuAndEnableSubmit() {
      this.setFormReadyStatus(true);
      this.selectedSku = this.selectedSize;
    },

    setSkuVuexAndRelocate() {
      this.setBikeTechSKU(this.selectedSku);
      window.location = `${this.encodedContextPath}/tech-details/${this.selectedModel}`;
    },

    ...mapMutations('storage', ['setBikeTechSKU']),
  },
};
</script>
