const parseJson = (value) => JSON.parse(value.replaceAll("'", '"'));

export const parseInput = (input, defaults = {}) => {
  const opts = {...defaults};
  if (input.dataset.mask != null && input.dataset.mask !== '') {
    opts.mask = parseMask(input.dataset.mask);
  }
  if (input.dataset.maskEager != null) {
    opts.eager = parseOpts(input.dataset.maskEager);
  }
  if (input.dataset.maskReversed != null) {
    opts.reversed = parseOpts(input.dataset.maskReversed);
  }
  if (input.dataset.maskTokensReplace != null) {
    opts.tokensReplace = parseOpts(input.dataset.maskTokensReplace);
  }
  if (input.dataset.maskTokens != null) {
    opts.tokens = parseTokens(input.dataset.maskTokens);
  }

  return opts;
};

const parseOpts = (value) => (value !== '' ? Boolean(JSON.parse(value)) : true);

const parseMask = (value) => (value.startsWith('[') && value.endsWith(']') ? parseJson(value) : value);

const parseTokens = (value) => {
  if (value.startsWith('{') && value.endsWith('}')) {
    return parseJson(value);
  }

  const tokens = {};
  value.split('|').forEach((token) => {
    const parts = token.split(':');
    tokens[parts[0]] = {
      pattern: new RegExp(parts[1]),
      optional: parts[2] === 'optional',
      multiple: parts[2] === 'multiple',
      repeated: parts[2] === 'repeated',
    };
  });

  return tokens;
};
