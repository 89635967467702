<template>
  <pdl-media-object-list :class="[dynamicClasses]">
    <checkout-summary-item
      v-for="(entry, i) of cartEntries"
      :key="`cart-item-${entry.product.code}-${i}`"
      :is-e-comm-available="isECommAvailable"
      :entry="entry"
      :retailer-for-delivery="retailerForDelivery"
      :is-quantity-control-enabled="false"
      :is-checkout-confirmation="isCheckoutConfirmation"
      :has-mixed-promo-items="hasMixedPromoItems"
    />
  </pdl-media-object-list>
</template>

<script>
import CheckoutSummaryItem from '@/components/cart/b2c/CheckoutSummaryItem.vue';
import {PdlMediaObjectList} from '@pedal/pdl-media-object';

export default {
  components: {
    CheckoutSummaryItem,
    PdlMediaObjectList,
  },
  props: {
    cartData: {
      type: Boolean,
      required: true,
    },
    cartEntries: {
      type: Array,
      required: true,
    },
    isCheckoutConfirmation: {
      type: Boolean,
      default: false,
    },
    isECommAvailable: {
      type: Boolean,
      required: true,
    },
    isQuantityControlEnabled: {
      type: Boolean,
      required: true,
    },
    retailerForDelivery: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    dynamicClasses() {
      return {
        'border-b': !this.cartEntries.length,
        'border-gray-10': !this.cartEntries.length,
      };
    },
    hasMixedPromoItems() {
      return (
        this.cartData?.entries?.length > 1 &&
        !!this.cartData?.appliedProductPromotions.length &&
        !this.cartData?.entries?.every((entry) => entry.isLineLevelPromotionPresent)
      );
    },
  },
};
</script>
