<template>
  <div class="grid-container text-base">
    <pdl-section-header size="xl" :is-dividing="true">
      <template slot="content">
        <pdl-heading qaid="warranty-details-heading" :level="1"
          >{{ $t('warrantyClaims.B2B.claim') }} {{ claimId }}</pdl-heading
        >
      </template>
    </pdl-section-header>

    <div v-for="(claim, index) in warrantyDetails" :key="index">
      <div v-if="Array.isArray(claim.consignments) && claim.consignments.length">
        <div v-for="(consignment, consignmentIndex) in claim.consignments" :key="consignmentIndex">
          <warranty-details-description v-if="consignment.code" :warranty-details="claim" :consignment="consignment" />
          <warranty-details-grid :warranty-details="claim" :consignment="consignment" />
        </div>
      </div>
      <warranty-details-grid v-else :warranty-details="claim" />
    </div>
  </div>
</template>

<script>
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import storefrontInstance from '@/api/instances/storefront';
import WarrantyDetailsDescription from '@/components/containers/warranty-claim/WarrantyDetails/WarrantyDetailsDescription';
import WarrantyDetailsGrid from '@/components/containers/warranty-claim/WarrantyDetails/WarrantyDetailsGrid';

export default {
  components: {PdlSectionHeader, PdlHeading, WarrantyDetailsDescription, WarrantyDetailsGrid},

  props: {
    claimId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      warrantyDetails: [],
    };
  },

  created() {
    this.getWarrantyDetails();
  },

  methods: {
    getWarrantyDetails() {
      storefrontInstance
        .get(`/claims/json/v2/${this.claimId}/`)
        .then((resp) => {
          if (resp?.data?.data) {
            this.warrantyDetails = resp.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
