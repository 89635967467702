//Implementation following https://www.w3.org/TR/WCAG20-TECHS/G17.html
function hexToRgb(hex) {
  if (hex.length !== 7) throw new TypeError('Failed to convert hex color - unexpected length');
  return {
    r: parseInt(hex.slice(1, 3), 16),
    g: parseInt(hex.slice(3, 5), 16),
    b: parseInt(hex.slice(5, 7), 16),
  };
}

function computeRelativeLuminance(rgb) {
  const calcChannelLuminance = (channel) => {
    const sRGB = channel / 255;
    return sRGB <= 0.03928 ? sRGB / 12.92 : Math.pow((sRGB + 0.055) / 1.055, 2.4);
  };
  const [R, G, B] = Object.values(rgb).map(calcChannelLuminance);
  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
}

function getContrastRatio(firstHex, secondHex) {
  const l1 = computeRelativeLuminance(hexToRgb(firstHex));
  const l2 = computeRelativeLuminance(hexToRgb(secondHex));

  return ((Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05)).toFixed(2);
}

export {getContrastRatio};
