<template>
  <div>
    <table class="mb-0">
      <thead>
        <!-- Each Header -->
        <tr>
          <th
            v-for="column in columns"
            :key="`${column.key}-header`"
            :data-type="`${column.type}`"
            :class="[column.cssClasses]"
            class="b2b-grid__cell b2b-grid__head"
          >
            {{ column.label }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(item, itemIndex) in items">
          <tr :key="item.id">
            <td
              v-for="(column, index) in columns"
              :key="`${item.id}-${column.key}-cell`"
              class="b2b-grid__cell table-cell"
              :class="[
                column.cssClasses,
                item.cssClasses,
                {
                  'font-bold': item.selectAll,
                  'bg-gray-02': (itemIndex + 1) % 2 === 0,
                },
              ]"
            >
              <div class="b2b-grid__content">
                <div v-if="column.type === 'checkbox' && !item.selectAll" class="text-right">
                  <label class="checkbox justify-end">
                    <input
                      :id="`${item.id}-${column.key}`"
                      type="checkbox"
                      :disabled="column.key === 'online'"
                      :checked="item[column.key]"
                    />
                    <span class="control-indicator mr-0"></span>
                  </label>
                </div>

                <span v-else-if="!item.selectAll || index === 0">{{ item[column.key] }}</span>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },
};
</script>
