<template>
  <div :ref="tippyId" v-tippy="tippyConfig" @hidden="onHidden" @show="onShow">
    <!-- Tooltip -->
    <div :id="tippyId" v-tippy-html qaid="sku-grid-sold-options">
      <div class="text-left p-1">
        <div class="mb-2">
          <h2 class="font-bold text-lg" qaid="sold-option-title">{{ tooltipTitle }}</h2>

          <!-- Real Button -->
          <pdl-button
            small
            button
            tertiary
            icon-only
            :label="$t('popup.close')"
            icon="close"
            class="absolute top-0 right-0"
            qaid="sold-option-close-btn"
            @click="closeTooltip"
          >
          </pdl-button>
        </div>
        <div class="lg:max-w-sm lg:min-w-sm min-w-xs max-w-xs">
          <span class="text-gray-60 text-xs font-medium" qaid="sold-option-cross-step">
            {{ $t('product.modal.sold.step', {'1': processStep, '2': '2'}) }}
          </span>

          <div v-show="processStep === 1">
            <div class="flex gap-x-2 md:gap-x-4 my-2 h-40 lg:h-32">
              <div class="w-16">
                <span class="form-label">{{ $t('product.modal.sold.soldQuantity') }}</span>
                <numerical-input
                  ref="soldOptionInputQty"
                  :initial-value="1"
                  :data-initial-value="1"
                  :should-clear="true"
                  name="qty"
                  qaid="sold-option-qty-input"
                  :disabled="inputboxDisabled"
                  @updated="updateSoldQty"
                />
              </div>

              <div class="flex-1">
                <span class="form-label">{{ $t('product.modal.sold.skuSummary') }}</span>
                <div class="bg-gray-05 p-2">
                  <div class="flex gap-4 flex-col md:flex-row">
                    <div>
                      <div class="text-gray-70 text-xs">{{ $t('product.variants.sku') }}</div>
                      <div class="text-gray-70 text-xs font-semibold" qaid="sold-option-sku">{{ item.sku }}</div>
                    </div>

                    <div>
                      <div class="text-gray-70 text-xs">{{ $t('text.description') }}</div>
                      <div class="text-gray-70 text-xs font-semibold" qaid="sold-option-item">{{ item.itemName }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="buttons buttons--right mt-1">
              <pdl-button primary small qaid="sold-option-next-btn" :disabled="isNextStepValid" @click="nextStep">
                {{ $t('product.modal.sold.next') }}
              </pdl-button>
              <pdl-button secondary small qaid="sold-option-cancel-btn" @click="closeTooltip">
                {{ $t('product.modal.sold.cancel') }}
              </pdl-button>
            </div>
          </div>

          <div v-show="processStep === 2">
            <div class="h-40 lg:h-32 my-2">
              <span class="form-label">{{ $t('product.modal.sold.orderNote') }}</span>
              <textarea
                :id="rowId + '-input'"
                v-model="noteContent"
                :maxlength="120"
                :placeholder="$t('checkout.B2B.sold.noteRequired')"
                class="form-control"
                qaid="sold-option-linenote-input"
                rows="4"
              >
              </textarea>
            </div>

            <div class="buttons buttons--right mt-1">
              <pdl-button primary small qaid="sold-option-add-note-btn" :disabled="isFinalStepValid" @click="addNote">
                {{ $t('product.modal.sold.finish') }}
              </pdl-button>
              <pdl-button secondary small qaid="sold-option-prev-btn" @click="prevStep">
                {{ $t('product.modal.sold.previous') }}
              </pdl-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {PdlButton} from '@pedal/pdl-button';
import NumericalInput from '@/components/NumericalInput';

export default {
  components: {PdlButton, NumericalInput},
  props: {
    rowId: {type: [Number, String], default: 0},
    item: {type: Object, default: () => {}},
    maxLength: {
      type: Number,
      default: 0,
    },
    placeholderText: {
      type: String,
      default: '',
    },
    inputboxDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      processStep: 1,
      noteContent: '',
      qty: 1,
    };
  },

  computed: {
    noteId() {
      return `note-${this.rowId}`;
    },
    tippyId() {
      return `tippy_gridSoldOption${this.rowId}_${this.sanitizedSku}`;
    },
    tooltipTitle() {
      return this.processStep === 1
        ? this.$t('product.modal.sold.markQuantity')
        : this.$t('product.modal.sold.addNote');
    },
    tippyConfig() {
      return {
        html: `#${this.tippyId}`,
        theme: 'light',
        interactive: true,
        sticky: true,
        trigger: 'click',
        reactive: true,
      };
    },

    // + in SKU for P1 bikes was breaking document.querySelector
    sanitizedSku() {
      if (typeof this.item.sku === 'string') {
        return this.item.sku.replace(/\+/g, '_');
      }

      return this.item.sku;
    },

    isNextStepValid() {
      return !(this.qty > 0 && this.qty <= this.item.backorderedQty);
    },

    isFinalStepValid() {
      return !this.noteContent?.length > 0;
    },
  },

  methods: {
    openTooltip() {
      let el = this.$refs[this.tippyId];

      if (el && el._tippy) {
        el._tippy.show();
      }
    },

    closeTooltip() {
      let el = this.$refs[this.tippyId];

      if (el && el._tippy) {
        el._tippy.hide();
      }
    },

    updateSoldQty(value) {
      this.qty = Number(value);
    },

    prevStep() {
      --this.processStep;
    },

    nextStep() {
      ++this.processStep;
    },

    resetFields() {
      this.processStep = 1;
      this.noteContent = this.item.lineNote;
      this.qty = 1;
      this.$refs.soldOptionInputQty.value = '1';
    },

    addNote() {
      this.$emit('add-note');
      this.closeTooltip();
    },

    onShow() {
      this.noteContent = this.item.lineNote;
    },

    onHidden() {
      this.$emit('tooltip-closed', {qty: this.qty, noteContent: this.noteContent});
      this.resetFields();
    },
  },
};
</script>
