<template>
  <div v-if="!isConsumerFriendlyMode" class="w-full">
    <section class="section">
      <div class="grid-x grid-margin-x">
        <div class="cell medium-6 large-4"></div>

        <div class="cell medium-6 large-4 large-offset-4">
          <pdl-collapse
            icon-active="indeterminate_check_box"
            icon-inactive="add_box"
            icon-position="left"
            align-icon="start"
            icon-classes="md-18"
            :default-active="false"
            qaid="saved-list-collapse"
          >
            <pdl-collapse-item v-if="list.bikesEntries && list.bikesEntries.length > 0" name="saved-list-summary.bikes">
              <template slot="title">
                <div class="flex flex-wrap w-full">
                  <div class="flex justify-between w-full" qaid="saved-list-bikes-title">
                    <span class="font-medium"> {{ $t('text.bikes') }} ({{ list.bikesEntries.length }}) </span>
                    <span>{{ list.bikesTotal }}</span>
                  </div>
                </div>
              </template>

              <div v-cloak class="block-list">
                <div class="block-list__item w-full" qa="saved-list-bikes-subtotal">
                  <span>{{ $t('text.bikesSubtotal') }}</span>
                  <span>{{ list.bikesSubtotal }}</span>
                </div>
                <div class="block-list__item w-full" qa="saved-list-bikes-promotions">
                  <span>{{ $t('text.promotionsSubtotal') }}</span>
                  <span class="text-alert">{{ list.bikesPromotionSubTotal }}</span>
                </div>
                <div class="block-list__item w-full" qa="saved-list-bikes-total">
                  <span class="font-medium">{{ $t('myCart.B2B.bikesTotal') }}</span>
                  <span>{{ list.bikesTotal }}</span>
                </div>
              </div>
            </pdl-collapse-item>

            <pdl-collapse-item
              v-if="list.partsEntries && list.partsEntries.length > 0"
              name="saved-list-summary.accessories"
            >
              <template slot="title">
                <div class="flex flex-wrap w-full">
                  <div class="flex justify-between w-full" qaid="saved-list-accessories-title">
                    <span class="font-medium">
                      {{ $t('text.accessories') }}
                    </span>
                    <span>{{ list.aftermarketsTotal }}</span>
                  </div>
                </div>
              </template>

              <div v-cloak class="block-list">
                <div class="block-list__item w-full" qa="saved-list-accessories-subtotal">
                  <span>{{ $t('text.accessoriesSubtotal') }}</span>
                  <span>{{ list.aftermarketsSubtotal }}</span>
                </div>
                <div class="block-list__item w-full" qa="saved-list-accessories-promotions">
                  <span>{{ $t('text.promotionsSubtotal') }}</span>
                  <span class="text-alert">{{ list.aftermarketsPromotionSubTotal }}</span>
                </div>
                <div class="block-list__item w-full" qa="saved-list-accessories-total">
                  <span class="font-medium">{{ $t('myCart.B2B.partsAccessories.total') }}</span>
                  <span>{{ list.aftermarketsTotal }}</span>
                </div>
              </div>
            </pdl-collapse-item>
          </pdl-collapse>

          <div class="flex justify-between text-lg py-2" qa="cart-total">
            <span class="font-medium">{{ $t('text.order.subtotal') }}</span>
            <span>{{ list.subtotal }}</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';

export default {
  name: 'SavedListSummary',
  components: {PdlCollapse, PdlCollapseItem},
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode']),
  },
};
</script>
