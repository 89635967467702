<template>
  <div v-if="address">
    <pdl-label v-if="!isMyAccountPage" :qaid="`${qaid}-title`" class="mb-1">
      {{ $t('checkout.summary.delivery_details') }}
    </pdl-label>
    <address-summary v-if="!isMyAccountPage" :address="address" :qaid="qaid" />
    <div v-if="address.phone || emailAddress" class="mt-1">
      <div v-if="address.phone" :qaid="`${qaid}-phone-title`">
        <p v-if="!isMyAccountPage">{{ $t('checkout.summary.phone_number') }}</p>
        {{ formattedPhoneNumber }}
      </div>
      <p v-if="emailAddress" :qaid="`${qaid}-email-address`" class="break-words">
        {{ $t('checkout.summary.email_address') }} {{ emailAddress }}
      </p>
    </div>

    <checkout-edit-link v-if="!isMyAccountPage" custom-class="mt-1" :qaid="qaid" :link-config="linkConfig" />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {PdlLabel} from '@pedal/pdl-label';
import AddressSummary from '@/components/AddressSummary';
import CheckoutEditLink from '@/components/checkout/CheckoutEditLink.vue';
import {getFormattedPhoneNumber} from '@/utils/masks/phone-mask-patterns.js';

export default {
  name: 'DeliveryDetails',
  components: {AddressSummary, PdlLabel, CheckoutEditLink},
  props: {
    address: {
      type: Object,
      default: () => null,
    },
    linkConfig: {
      type: Object,
      default: () => ({}),
    },
    emailAddress: {
      type: String,
      default: '',
    },
    qaid: {
      type: String,
      default: '',
    },
    isMyAccountPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('backend', ['encodedContextPath', 'locale']),
    formattedPhoneNumber() {
      return getFormattedPhoneNumber(this.address.phone, this.locale);
    },
    linkSetup() {
      return {
        id: this.linkConfig?.id,
        href: `${this.encodedContextPath}${this.linkConfig?.href}`,
        text: this.$t(this.linkConfig?.text),
      };
    },
  },
};
</script>
