<template>
  <div
    v-if="isApplePayAvailable"
    :disabled="(!hasExpressCheckoutData && isExpressCheckout) || !isAppleButtonEnabled"
    qaid="apple-pay-button"
    :class="['apple-pay-button button', computedClasses]"
  >
    <apple-pay-button :buttonstyle="buttonStyleComputed" @click="handleClick" />
  </div>
</template>

<script>
import {ApplePayProvider} from '@/utils/checkout/payment-providers/apple-pay-provider.js';
import {PaymentProvider} from '@/constants/payment-provider.js';
import {mapState, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'ApplePayPaymentButton',

  props: {
    buttonStyle: {
      type: String,
      default: 'black',
      validator: (style) => ['black', 'white', 'white-outline'].includes(style),
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    isExpressCheckout: {
      type: Boolean,
      default: false,
    },
    isFormValid: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: undefined,
    },
  },

  computed: {
    ...mapState('cart', ['cartData', 'dealerAddress']),
    ...mapState('checkout', ['b2cCartData', 'b2cCartDataFull', 'selectedStore', 'isAppleButtonEnabled']),
    ...mapGetters('checkout', ['hasExpressCheckoutData']),
    ...mapGetters('backend', ['getSupportedCardTypeCodes', 'getSupportedPaymentMethodCodes']),

    buttonStyleComputed() {
      if (this.buttonStyle === 'white' || this.buttonStyle === 'white-outline') {
        return 'white';
      }
      return 'black';
    },
    computedClasses() {
      return {
        'button--block': this.isFullWidth,
        'apple-pay-button--white': this.buttonStyle === 'white',
        'apple-pay-button--black': this.buttonStyle === 'black',
        'apple-pay-button--white-outline': this.buttonStyle === 'white-outline',
        disabled: !this.hasExpressCheckoutData && this.isExpressCheckout,
      };
    },
    isApplePayAvailable() {
      return ApplePayProvider.canMakePayments();
    },
    applePaymentDetails() {
      if (this.isExpressCheckout) {
        return this.cartData;
      }
      return this.b2cCartDataFull;
    },
    shipToStoreOption() {
      if (this.selectedStore) {
        return this.dealerAddress;
      } else if (this.b2cCartDataFull?.retailerForDelivery) {
        return this.b2cCartDataFull?.retailerForDelivery;
      }
      return null;
    },
  },

  watch: {
    isFormValid: {
      handler(value) {
        if (!value) return;
        this.startApplePay();
      },
    },
  },

  methods: {
    ...mapMutations('checkout', ['setIsAppleButtonEnabled']),

    async handleApplePay() {
      if (this.isExpressCheckout) {
        this.$emit('handle-express-validation');

        if (this.isFormValid) {
          this.startApplePay();
        }
        return;
      }
      this.startApplePay();
    },
    async startApplePay() {
      this.setIsAppleButtonEnabled(false);
      ApplePayProvider.setup({
        isExpressCheckout: this.isExpressCheckout,
        paymentDetails: this.applePaymentDetails,
        paymentMethodOptions: {
          retailer: this.shipToStoreOption,
          deliveryAddress: this.applePaymentDetails.deliveryAddress,
        },
        supportedNetworks: this.getSupportedCardTypeCodes,
        isCybersourceTransaction: this.getSupportedPaymentMethodCodes.includes(PaymentProvider.CYBERSOURCE_APPLE),
        isGr4vyTransaction: this.getSupportedPaymentMethodCodes.includes(PaymentProvider.GR4VY_APPLE),
      });
      await ApplePayProvider.begin();
    },
    handleClick() {
      this.onClick || !this.isAppleButtonEnabled ? this.onClick?.() : this.handleApplePay();
    },
  },
};
</script>

<style lang="scss" scoped>
.apple-pay-button {
  padding: 0.45rem 1.5rem;

  &--white {
    background: var(--white);
  }

  &--black {
    background: var(--black);
  }

  &--white-outline {
    background: var(--white);
    border: 1px solid var(--black);
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    position: relative;

    &::after {
      pointer-events: all;
      cursor: not-allowed;
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      bottom: -1px;
      right: -1px;
    }
  }
}

apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-box-sizing: border-box;
}
</style>
