<template>
  <div>
    <pdl-section-header size="xl" :is-dividing="true">
      <template slot="content">
        <pdl-heading :level="1" qaid="order-copy-paste-heading">{{ $t('orderCopyPaste.B2B.bulkOrder') }}</pdl-heading>
      </template>
    </pdl-section-header>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-16">
      <div class="grid grid-cols-4 gap-x-4">
        <form-group
          v-model="qty"
          name="qty"
          type="textarea"
          :rows="20"
          :label="$t('text.account.savedLists.qty')"
          :maxlength="-1"
          class="col-span-1"
          qaid="order-copy-paste-qty"
          @blur="formatContent('qty')"
        />
        <form-group
          v-model="skus"
          name="skus"
          type="textarea"
          :rows="20"
          :label="$t('orderCopyPaste.B2B.SKUs')"
          :maxlength="-1"
          class="col-span-3"
          qaid="order-copy-paste-skus"
          @blur="formatContent('skus')"
        />
        <div class="buttons buttons--right-for-md col-span-2 md:col-span-4">
          <trek-button primary :disabled="isDisabled" qaid="order-copy-check-skus" @click="checkSkus">
            {{ $t('orderCopyPaste.B2B.check') }}
          </trek-button>
        </div>
      </div>
      <div class="lg:col-span-2 order-first md:order-last">
        <ul class="text-base" qaid="order-copy-instructions-list">
          <li>{{ $t('orderCopyPaste.B2B.instructions1') }}</li>
          <li>{{ $t('orderCopyPaste.B2B.instructions2') }}</li>
          <li>{{ $t('orderCopyPaste.B2B.instructions3') }}</li>
          <li>{{ $t('orderCopyPaste.B2B.instructions4') }}</li>
        </ul>
      </div>
    </div>
    <check-skus-grid
      :products="products"
      @undo-saved-lists="returnRemovedProducts"
      @on-added-products="removeCheckedProducts"
      @update-stock-watch-state="updateStocks"
      @after-add-to-cart="removeCheckedProducts"
      @update-variant-quantity="updateProductQuantity"
      @update-product-quantity="updateProductQuantity"
    />
  </div>
</template>

<script>
import get from 'lodash/get';
import union from 'lodash/union';
import differenceBy from 'lodash/differenceBy';
import dropRightWhile from 'lodash/dropRightWhile';
import intersectionBy from 'lodash/intersectionBy';
import {ProductApi} from '@/api/product';
import TrekButton from '@/components/TrekButton';
import FormGroup from '@/components/FormGroup.vue';
import CheckSkusGrid from '@/components/order-copy-paste/CheckSkusGrid';
import {updateStockWatchStatus} from '@/components/containers/bulk-product/utils/warehouses';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlToastType} from '@/constants/pdl-toast-type';

export default {
  name: 'OrderCopyPaste',

  components: {PdlSectionHeader, PdlHeading, FormGroup, TrekButton, CheckSkusGrid},

  props: {
    baseProperties: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      qty: '',
      skus: '',
      products: [],
      removedProducts: [],
      isCheckSkusDisabled: true,
    };
  },

  computed: {
    isDisabled() {
      return this.qty.length === 0 || this.skus.length === 0;
    },
  },

  methods: {
    returnRemovedProducts() {
      this.products = [...this.removedProducts, ...this.products];
    },

    removeCheckedProducts(checkboxList) {
      this.removedProducts = intersectionBy(this.products, checkboxList, 'sku');
      this.products = differenceBy(this.products, checkboxList, 'sku');
    },

    updateProductQuantity(product) {
      this.products.forEach((item, i) => {
        if (item.code === product.sku) {
          this.products[i].skuQty = product.qty;
        }
      });
    },

    updateStocks(payload) {
      if (payload && payload.item) {
        const productGroups = [{products: this.products}];
        updateStockWatchStatus(productGroups, payload.item);
      }
    },

    triggerNotification(message) {
      const options = {
        type: PdlToastType.ERROR,
        message,
        showClose: true,
        duration: 0,
      };

      setTimeout(() => {
        this.$notify(options);
      }, 0);
    },

    formatContent(fieldName) {
      const maxRowsQuantity = 998;
      const rowsQuantity = this[fieldName].split('\n').length;
      if (rowsQuantity > maxRowsQuantity) {
        this[fieldName] = this[fieldName].split('\n').splice(0, maxRowsQuantity).join('\n');
        this.$notify({
          type: PdlToastType.ERROR,
          title: 'Error',
          message: this.$t('orderCopyPaste.B2B.rowMax'),
          showClose: true,
        });
      }
    },

    getInvalidQty(quantities) {
      const maxQtyValue = 9999;
      // list of failed qty indexes
      let indexes = [];
      // list of failed quantities
      let failedQuantities = [];
      // if the user inputs a non-numeric value into the QTY column
      let nonNumericQtyIndexes = [];
      // the user will not be able to enter in a value for quantity that is greater than 9999
      let tooBigQtyIndexes = [];
      // if a user enters in a valid SKU number with a quantity of 0
      let qtyEqualsZeroIndexes = [];

      quantities.forEach((qty, qtyIndex) => {
        const currentQuantity = Number(qty);
        if (!currentQuantity && currentQuantity !== 0) {
          failedQuantities = [...failedQuantities, qty];
          nonNumericQtyIndexes = [...nonNumericQtyIndexes, qtyIndex];
          indexes = [...indexes, qtyIndex];
        }

        if (currentQuantity > maxQtyValue) {
          failedQuantities = [...failedQuantities, qty];
          tooBigQtyIndexes = [...tooBigQtyIndexes, qtyIndex];
          indexes = [...indexes, qtyIndex];
        }

        if (currentQuantity === 0 || currentQuantity < 0) {
          failedQuantities = [...failedQuantities, qty];
          qtyEqualsZeroIndexes = [...qtyEqualsZeroIndexes, qtyIndex];
          indexes = [...indexes, qtyIndex];
        }
      });

      return {
        failedQuantities,
        nonNumericQtyIndexes,
        tooBigQtyIndexes,
        qtyEqualsZeroIndexes,
        indexes,
      };
    },

    getInvalidSkus(skus, incorrectSkus, unbuyableSkus) {
      // list of failed sku indexes
      let indexes = [];

      skus.forEach((sku, skuIndex) => {
        if (incorrectSkus.includes(sku)) {
          indexes = [...indexes, skuIndex];
        }

        if (unbuyableSkus.includes(sku)) {
          indexes = [...indexes, skuIndex];
        }
      });

      return {indexes};
    },

    async incorrectUnbuyableSkus(skus) {
      // skus not exists in database
      let incorrectSkus = [];
      // fields from the backend: notAllowedToPurchase = true or isArchieved = true
      let unbuyableSkus = [];
      let invalidSkus = {unbuyableSkus, incorrectSkus};
      await ProductApi.loadSkuGrid(skus)
        .then((products) => {
          // find "not allowed to purchase" skus
          products.data.forEach((product) => {
            if (product.isArchived || !product.isPurchasableB2B) {
              unbuyableSkus = [...unbuyableSkus, product.code];
            }
          });

          // find incorrect skus
          if (products.meta.feedback.errorFields) {
            incorrectSkus = products.meta.feedback.errorFields.failedSKUs.split(',');
          }

          if (unbuyableSkus.length > 0 || incorrectSkus.length > 0) {
            const failedSkus = union(unbuyableSkus, incorrectSkus);
            let failedSkusErrorMessage;
            if (ACC.language2CharacterCode === 'en' && failedSkus.length > 1) {
              failedSkusErrorMessage = this.$t('orderCopyPaste.B2B.skuInvalid', [failedSkus.join(', ')])
                .replace('SKU', 'SKUs')
                .replace('is', 'are');
            } else {
              failedSkusErrorMessage = this.$t('orderCopyPaste.B2B.skuInvalid', [failedSkus.join(', ')]);
            }

            this.triggerNotification(failedSkusErrorMessage);
          }

          invalidSkus.unbuyableSkus = unbuyableSkus;
          invalidSkus.incorrectSkus = incorrectSkus;
        })
        .catch((reason) => {
          console.error(reason);
        });

      return invalidSkus;
    },

    validateQty(skusList, invalidQtyIndexes, errorText, replaceTemplateInErrorMsg) {
      // skus for failed quantities
      let skusForInvalidQty = [];
      invalidQtyIndexes.forEach((qtyIndex) => {
        skusForInvalidQty = [...skusForInvalidQty, skusList[qtyIndex]];
      });

      const errorMsg = `${errorText}: ${skusForInvalidQty.join(', ')}`;
      const errorMsgWithReplace = `${errorText.replace('{0}', skusForInvalidQty.join(', '))}`;

      if (skusForInvalidQty.length !== 0) {
        if (replaceTemplateInErrorMsg) {
          this.triggerNotification(errorMsgWithReplace);
        } else {
          this.triggerNotification(errorMsg);
        }
      }
    },

    mergeSkusQuantities(qtyList, skusList, failedQtySkus) {
      const failedQuantitiesSkus = {
        quantities: [],
        skus: [],
      };
      const failedItemsIndexes = union(failedQtySkus.qty, failedQtySkus.skus);
      failedItemsIndexes.forEach((failedItemIndex) => {
        const qty = qtyList[failedItemIndex];
        const sku = skusList[failedItemIndex];
        if (qty || qty === '') failedQuantitiesSkus.quantities.push(qty);
        if (sku || sku === '') failedQuantitiesSkus.skus.push(sku);
      });

      let validQuantities = [];
      if (failedQuantitiesSkus.quantities.length > 0) {
        failedQuantitiesSkus.quantities.forEach((qty) => {
          const index = qtyList.findIndex((el) => el === qty);
          if (index !== -1) qtyList.splice(index, 1);
        });

        validQuantities = qtyList;
      } else {
        validQuantities = qtyList;
      }

      let validSkus = [];
      if (failedQuantitiesSkus.skus.length > 0) {
        failedQuantitiesSkus.skus.forEach((sku) => {
          const index = skusList.findIndex((el) => el === sku);
          if (index !== -1) skusList.splice(index, 1);
        });

        validSkus = skusList;
      } else {
        validSkus = skusList;
      }

      const validQuantitiesSkus = {};
      validQuantities.forEach((qty, qtyIndex) => {
        const validSku = validSkus[qtyIndex];
        const existingQty = validQuantitiesSkus[validSku];

        // if skus duplicated in skus field, sum qty of this skus
        validQuantitiesSkus[validSku] = existingQty ? Number(existingQty) + Number(qty) : qty;
      });

      this.qty = failedQuantitiesSkus.quantities.join('\n');
      this.skus = failedQuantitiesSkus.skus.join('\n');

      return {validQuantitiesSkus, validSkus};
    },

    async validateInput() {
      // remove all line breaks from qty, skus for further convenience of work
      let skusList = this.skus.split('\n');
      let qtyList = this.qty.split('\n');

      let hasUndefinedQty;
      const undefinedQtyIndexes = [];
      // /\S/gim - find non-whitespace character
      const isElementWithChar = (str) => /\S/gim.test(str);

      // drop elements from the end untill it will have even one character(remove all empty qty, skus from the end qty/sku fields)
      qtyList = dropRightWhile(
        qtyList,
        (qty, i) => !isElementWithChar(qty) || !isElementWithChar(skusList[i]) || skusList[i] === undefined
      );
      qtyList.forEach((qty, i) => {
        if (!isElementWithChar(qty) && !isElementWithChar(skusList[i])) {
          qtyList.splice(i, 1);
          skusList.splice(i, 1);
        } else {
          qtyList[i] = qty.trim();
        }
      });
      skusList = dropRightWhile(skusList, (sku, i) => {
        const qty = qtyList[i];
        if (qty === undefined || !isElementWithChar(qty)) {
          undefinedQtyIndexes.push(i);
          if (isElementWithChar(sku)) {
            hasUndefinedQty = true;
          }
        }
        return !isElementWithChar(sku);
      });
      skusList.forEach((sku, i) => (skusList[i] = sku.trim()));

      // if the user has input an incorrect SKU value or is not allowed to purchase the SKU
      const invalidQtySkus = await this.incorrectUnbuyableSkus(skusList);
      const invalidQty = this.getInvalidQty(qtyList);
      const invalidSkus = this.getInvalidSkus(skusList, invalidQtySkus.incorrectSkus, invalidQtySkus.unbuyableSkus);

      // list of failed quantities and skus indexes
      let failedQtySkus = {
        qty: [...undefinedQtyIndexes, ...invalidQty.indexes],
        skus: [...undefinedQtyIndexes, ...invalidSkus.indexes],
      };

      if (hasUndefinedQty) {
        invalidQty.nonNumericQtyIndexes = [...invalidQty.nonNumericQtyIndexes, ...undefinedQtyIndexes];
      }

      this.validateQty(skusList, invalidQty.nonNumericQtyIndexes, this.$t('orderCopyPaste.B2B.numValueOnly'));
      this.validateQty(skusList, invalidQty.tooBigQtyIndexes, this.$t('orderCopyPaste.B2B.skuMaxQty'));
      this.validateQty(skusList, invalidQty.qtyEqualsZeroIndexes, this.$t('orderCopyPaste.B2B.skuMinQty'), true);

      return this.mergeSkusQuantities(qtyList, skusList, failedQtySkus, invalidQtySkus); // return valid qty/skus
    },

    async checkSkus() {
      this.$notify.closeAll();

      const skusRows = {};
      // build skus object with key = sku, value = qty
      // to easily get product previous quantity and sum up with current quantity
      this.products.forEach((product) => {
        skusRows[product.code] = product.skuQty;
      });

      const quantitiesSkus = await this.validateInput();
      const skuGrid = await ProductApi.loadSkuGrid(quantitiesSkus.validSkus);

      let allvariantCodes = [];
      let modifiedProducts;
      const skuGridList = skuGrid.data;

      if (skuGridList && skuGridList.length > 0) {
        modifiedProducts = skuGridList.map((product) => {
          allvariantCodes = [...allvariantCodes, product.code];

          return {
            ...product,
            sku: product.code,
            skuQty: skusRows[product.code]
              ? Number(skusRows[product.code]) + Number(quantitiesSkus.validQuantitiesSkus[product.code])
              : quantitiesSkus.validQuantitiesSkus[product.code],
          };
        });
      }

      const variantsPrices = await ProductApi.fetchVariantsPrices(allvariantCodes);
      modifiedProducts.forEach((product, index) => {
        variantsPrices.find((item) => {
          if (item.code === product.code) {
            modifiedProducts[index] = {
              ...modifiedProducts[index],
              retailerPrice: item.retailerPrice,
              consumerPrice: item.consumerPrice,
              appleLabels: item.appleLabels,
              isAllowedAddToCart: get(item, 'retailerPrice.wasPrice.low.formatted', false) ? true : false,
            };
          }
        });
      });

      this.products = modifiedProducts;
    },
  },
};
</script>
