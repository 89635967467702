var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',[_vm._l((_vm.filterHeaders()),function(column){return [(column.key === 'icons')?_c('td',{key:column.key,staticClass:"b2b-grid__cell lg:table-cell",class:[
        {
          'whitespace-nowrap': column.key === 'icons',
        },
        column.cssClasses,
      ],attrs:{"qaid":"shipment.grid.row.notes"}},[_c('div',{staticClass:"flex flex-nowrap"},[(_vm.item.isSplitWarehouse)?_c('pdl-icon',{attrs:{"name":"call_split","size":"18"}}):_vm._e(),_vm._v(" "),_c('grid-note',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.lineComment),expression:"item.lineComment"}],attrs:{"row-id":_vm.item.id,"data":{
            value: 'insert_drive_file',
            content: _vm.item.lineComment,
            editable: false,
            cssClass: 'ml-1',
          }}})],1)]):(column.key === 'sku')?_c('grid-cell',{key:column.key,attrs:{"cell-key":column.key,"label":column.label,"qaid":`order-detail-item-${column.key}`,"value":_vm.itemData.product.code,"classes":['w-1', column.cssClasses]}}):(column.key === 'itemName')?_c('grid-cell',{key:column.key,attrs:{"cell-key":column.key,"label":column.label,"qaid":`order-detail-item-${column.key}`,"value":_vm.itemData.product.itemName,"classes":[column.cssClasses]}}):(column.key === 'upc')?_c('grid-cell',{key:column.key,attrs:{"cell-key":column.key,"label":column.label,"qaid":`order-detail-item-${column.key}`,"value":_vm.itemData.product.upc,"classes":['w-1', column.cssClasses]}}):_c('grid-cell',{key:column.key,attrs:{"cell-key":column.key,"label":column.label,"qaid":`order-detail-item-${column.key}`,"value":column.key !== 'lineStatus' ? _vm.itemData[column.key] : null,"classes":[
        {
          'whitespace-nowrap': column.key === 'lineStatus' || column.key === 'icons',
          'w-1': column.key !== 'itemName',
        },
        column.cssClasses,
      ]}},[(column.key === 'lineStatus')?_c('pdl-label',{staticClass:"align-self-top mr-0",attrs:{"kind":_vm.calculateLabelKind(_vm.item[column.key])}},[_vm._v("\n        "+_vm._s(_vm.$t(`${_vm.statusNamesMap[_vm.item[column.key]]}`))+"\n      ")]):_vm._e()],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }