<template>
  <div>
    <div v-for="(day, index) in days" :key="day.id">
      <div class="font-medium">{{ dayLabels[index] }}</div>
      <div class="mb-3 text-gray-80">
        <template v-for="(range, indx) in day.ranges">
          <div v-if="range.startTime && range.endTime" :key="indx">{{ range.startTime }} - {{ range.endTime }}</div>
          <div v-else :key="indx">{{ closedMessage }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisplayStoreHours',

  props: {
    dayData: {
      type: Object,
      default: () => null,
    },
    closedMessage: {
      type: String,
      required: false,
      default: 'Closed',
    },
  },

  data() {
    return {
      days: [],
      dayLabels: [],
    };
  },

  mounted() {
    this.days = this.dayData.days;
    this.dayLabels = this.dayData.dayLabels;
  },
};
</script>
