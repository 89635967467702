<template>
  <ul class="mb-4 border-t border-gray-10">
    <li v-for="(item, itemIndex) in items" :key="`item-${itemIndex}`" class="border-b border-gray-10 my-2 pb-2">
      <div class="flex">
        <label class="checkbox">
          <input :id="`item-${itemIndex}`" type="checkbox" :checked="item.active" />
          <span class="control-indicator"></span>
        </label>

        <div class="notification__button mr-1 mt-25">
          <button
            v-show="!item.read"
            v-tippy="{
              theme: 'light',
              interactive: true,
              trigger: 'mouseenter focus',
              reactive: true,
            }"
            class="notification__hitbox"
            title="Mark as read"
            @click="item.read = true"
          >
            <span class="notification__indicator"></span>
          </button>
        </div>

        <div v-if="image" class="flex w-full">
          <div class="sm:hidden md:block flex-none md:w-1/5 lg:w-1/6 mr-2">
            <a href="" class="block">
              <img
                src="https://media.trekbikes.com/image/upload/w_400,h_300,f_auto,q_auto,c_pad/Trek_logo_B2B_news"
                alt=""
                class="w-full"
              />
            </a>
          </div>
          <div class="flex-auto" :class="{'font-bold': !item.read}">
            {{ item.text }}
          </div>
        </div>
        <div v-else :class="{'font-bold': !item.read}">
          {{ item.text }}
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    image: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },
};
</script>
