<template>
  <div v-if="isUpdateEnabled" class="max-w-sm" qaid="cart-product-quantity-controls">
    <div class="inline-flex items-stretch">
      <pdl-button
        class="quantity-button"
        qaid="cart-item-decrease-quantity"
        :disabled="!isControlEnabled"
        secondary
        icon-only
        :icon="decrementIcon"
        :label="decrementLabel"
        @click.prevent="decrementQuantity"
      />
      <input
        v-model.number="quantity"
        :min="min"
        :max="max"
        aria-label="quantity"
        type="number"
        class="form-control p-1 mx-1 font-medium text-center"
        qaid="cart-product-quantity"
        @focus="$event.target.select()"
        @keyup.enter="setQuantity"
        @blur.prevent="setQuantity"
        @input.prevent="validateInput"
      />
      <pdl-button
        class="quantity-button"
        qaid="cart-item-increase-quantity"
        :disabled="!isControlEnabled"
        secondary
        icon-only
        icon="add"
        :label="$t('basket.page.quantity.increase')"
        @click.prevent="incrementQuantity"
      />
    </div>
    <div class="mt-2">
      <pdl-button
        qaid="cart-item-remove-button"
        :icon="'remove_circle'"
        :text="true"
        :underline="true"
        xsmall
        :aria-label="$t('basket.page.remove')"
        @click="removeEntry(entryNumber, handleRemoveFromCart)"
      >
        {{ $t('basket.page.remove') }}
      </pdl-button>
    </div>
  </div>
</template>

<script>
import {PdlToastType} from '@/constants/pdl-toast-type.js';
import {PdlButton} from '@pedal/pdl-button';
import {CartApi} from '@/api/cart.js';
import trackCartItem from '@/mixins/tracking/track-cart-item.js';
import {mapActions, mapGetters} from 'vuex';
import debounce from 'lodash/debounce';
import {handleCartModifications} from '@/utils/cart/cart-modification-config';

export default {
  components: {
    PdlButton,
  },
  mixins: [trackCartItem],
  props: {
    isUpdateEnabled: {
      type: Boolean,
      required: true,
    },
    initialQuantity: {
      type: Number,
      required: true,
    },
    entryNumber: {
      type: Number,
      required: true,
    },
    productCode: {
      type: String,
      required: true,
    },
    itemData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      quantity: this.initialQuantity,
      isControlEnabled: true,
      min: 0,
      max: 99,
    };
  },
  computed: {
    ...mapGetters('backend', ['occUrlParams']),
    decrementIcon() {
      return this.quantity > 1 ? 'remove' : 'delete';
    },
    decrementLabel() {
      return this.quantity > 1 ? this.$t('basket.page.quantity.decrease') : this.$t('text.delete');
    },
  },
  watch: {
    initialQuantity(updatedValue) {
      if (this.quantity === updatedValue) return;
      this.quantity = updatedValue;
    },
  },
  methods: {
    ...mapActions('cart', ['getCartData']),
    debouncedUpdateQuantity: debounce(async function (payload, cb) {
      this.isControlEnabled = false;
      try {
        if (payload.quantity < 1) return this.removeEntry(payload.entryNumber, cb);
        const modificationResult = await CartApi.updateQuantity(payload);
        const quantityUpdateModification = {...modificationResult, isQuantityUpdate: true};

        handleCartModifications([quantityUpdateModification]);
        cb(quantityUpdateModification.quantity, Math.abs(quantityUpdateModification.quantityAdded));
      } catch (error) {
        console.error(error);
        this.displayQuantityUpdateError();
      } finally {
        this.isControlEnabled = true;
      }
    }, 500),
    getQuantityPayload(quantity) {
      return {
        entryNumber: this.entryNumber,
        productCode: this.productCode,
        quantity: quantity,
        ...this.occUrlParams,
      };
    },
    async handleRemoveFromCart() {
      this.trackRemoveFromCart(this.quantity);
      this.getCartData();
    },
    async removeEntry(entryNumber, cb) {
      await CartApi.remove({...this.occUrlParams, entryNumber});
      this.displayDeleteItemToast();
      return cb();
    },
    async incrementQuantity() {
      const calculatedQuantity = Math.min(this.quantity + 1, this.max);
      this.quantity = calculatedQuantity;
      await this.debouncedUpdateQuantity(
        this.getQuantityPayload(calculatedQuantity),
        (updatedQuantity, quantityAdded) => {
          this.quantity = updatedQuantity;
          this.trackAddToCart(quantityAdded);
          this.getCartData();
        }
      );
    },
    async decrementQuantity() {
      const calculatedQuantity = Math.max(this.min, this.quantity - 1);
      this.quantity = calculatedQuantity;
      await this.debouncedUpdateQuantity(
        this.getQuantityPayload(calculatedQuantity),
        (updatedQuantity, quantityRemoved) => {
          this.quantity = updatedQuantity;
          this.trackRemoveFromCart(quantityRemoved);
          this.getCartData();
        }
      );
    },
    async setQuantity() {
      if (this.quantity === this.initialQuantity) return;
      await this.debouncedUpdateQuantity(this.getQuantityPayload(this.quantity), (updatedQuantity, quantityChanged) => {
        this.quantity = updatedQuantity;
        this.quantity > this.initialQuantity
          ? this.trackAddToCart(quantityChanged)
          : this.trackRemoveFromCart(quantityChanged);
        this.getCartData();
      });
    },
    displayQuantityUpdateError() {
      this.$notify({
        type: PdlToastType.ERROR,
        message: this.$t('errorMessage.unableToProcess'),
        showClose: true,
      });
    },
    displayDeleteItemToast() {
      this.$notify({
        type: PdlToastType.SUCCESS,
        message: this.$t('basket.page.message.remove'),
        showClose: true,
      });
    },
    validateInput(event) {
      const value = parseInt(event.target.value);

      const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

      const updatedValue = isNaN(value) ? this.min : clamp(value, this.min, this.max);
      event.target.value = updatedValue;
      this.quantity = updatedValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.quantity-button {
  @apply bg-gray-05;
  @apply border-transparent;

  padding: 0 0.5rem;
}
</style>
