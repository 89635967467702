/* Store */
import {mapGetters, mapMutations} from 'vuex';

const trackCartItem = {
  computed: {
    ...mapGetters('cart', ['trackCartEntries']),
  },

  methods: {
    ...mapMutations('cart', ['setTrackEntries']),

    trackAddToCart(quantity = 1) {
      const type = 'add';

      //updates track data for items sent to proceed checkout
      this.setTrackEntries(this.handleCartUpdate(type));

      //handles add to cart analytics event
      const addToCartData = {
        event: 'AddToCart',
        variant_product_id: this.itemData.productCode, // Custom variables (FB event)
        product_name: this.itemData.productName, // Custom variables (FB event)
        ecommerce: {
          currencyCode: this.itemData.isoCode,
          add: {
            products: [
              {
                id: this.itemData.productCode,
                name: this.itemData.productName,
                price: this.itemData.productPrice,
                brand: this.itemData.productBrand,
                category: this.itemData.productCategory,
                variant: this.itemData.productColor,
                size: this.itemData.productSize,
                quantity,
              },
            ],
          },
        },
      };

      ACC.track.trackEvent(addToCartData);
    },

    trackRemoveFromCart(quantity = 1) {
      //updates track data for items sent to proceed checkout
      this.setTrackEntries(this.handleCartUpdate());

      //handles remove from cart analytics event
      const removeCartData = {
        event: 'removeFromCart',
        variant_product_id: this.itemData.productCode, // Custom variables (FB event)
        product_name: this.itemData.productName, // Custom variables (FB event)
        ecommerce: {
          currencyCode: this.itemData.isoCode,
          remove: {
            products: [
              {
                id: this.itemData.productCode,
                name: this.itemData.productName,
                price: this.itemData.productPrice,
                brand: this.itemData.productBrand,
                category: this.itemData.productCategory,
                variant: this.itemData.productColor,
                size: this.itemData.productSize,
                quantity,
              },
            ],
          },
        },
      };

      ACC.track.trackEvent(removeCartData);
    },

    handleCartUpdate(type) {
      return this.trackCartEntries.map((item) => {
        if (item.id === this.itemData.productCode) {
          type === 'add' ? item.quantity++ : item.quantity--;
        }
        return item;
      });
    },
  },
};

export default trackCartItem;
