import RefLink from '@/components/ref-link/RefLink';
import PreSeasonOverviewHeader from '@/preSeason/components/PreSeasonOverviewHeader';
import PreSeasonSummaryLayout from '@/preSeason/layouts/PreSeasonSummaryLayout.vue';
import BikeGarageApp from '@/bikeGarage/BikeGarageApp.vue';
import TrekMarquee from '@/components/TrekMarquee';
import ImageComponent from '@/components/ImageComponent';
import ApplePayIcon from '@/icons/ApplePayIcon.vue';
import IdealIcon from '@/icons/IdealIcon.vue';
import LiveChat from '@/components/LiveChat';
import B2cMiniCart from '@/components/mini-cart/b2c/B2cMiniCart.vue';
import B2bMiniCart from '@/components/mini-cart/b2b/B2bMiniCart.vue';
import {I18n, loadLanguageAsync} from '@/utils/i18n-setup-utils';
import {PdlBadge} from '@pedal/pdl-badge';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {PdlLink} from '@pedal/pdl-link';
import {PdlMediaObject, PdlMediaObjectList} from '@pedal/pdl-media-object';
import {PdlTabBar, PdlTabNav, PdlTabPane, PdlTabs} from '@pedal/pdl-tabs';
import {PdlIcon} from '@pedal/pdl-icon';
import {PdlSectionHeader, PdlHeading, PdlSubheading} from '@pedal/pdl-section-header';
import {PdlToast} from '@pedal/pdl-toast';
import camelCase from 'lodash/camelCase';
import first from 'lodash/first';
import upperFirst from 'lodash/upperFirst';
import Vue from 'vue';
import tippy from 'vue-tippy';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import store from './store';

// Vue Nav Components
import NavCategoriesLink from '@/navigation/components/NavCategoriesLink';
import NavCompactContainer from '@/navigation/containers/NavCompactContainer';
import NavLargeContainer from '@/navigation/containers/NavLargeContainer';
import NavUtilityBarB2cContainer from '@/navigation/containers/NavUtilityBarB2cContainer';
import NavUtilityBarControl from '@/navigation/components/NavUtilityBarControl';
import NavCategoriesLayout from '@/navigation/layouts/NavCategoriesLayout';
import NavOverlayCompactLayout from '@/navigation/layouts/NavOverlayCompactLayout';
import NavApp from '@/navigation/NavApp';

// Directives
import DebugHighlight from '@/directives/debug-highlight.directive';

// Mixins
import Common from './mixins/common';
import trackGlobalEvents from './mixins/tracking/track-global-events';

// Sets initial vue container and upon data-vue-dynamic-content, passes in element to be rendered
const vueConfig = function (container = '#app') {
  if (!container?.attributes?.['data-vue-dynamic-content']) {
    if (!document.getElementById(container?.split('#')?.[1])) {
      container = '#page';
    }
  }

  // Directives
  Vue.directive('debug-highlight', DebugHighlight);

  //Enable Vue error and warning on dev and test Environment
  Vue.config.silent = window.ACC.config.isProduction;

  // Load axios configuration and interceptors
  require('./utils/axios/index');

  Vue.use(tippy, {
    animateFill: false,
    arrow: true,
    theme: 'light',
    flipDuration: 0,
    flip: true,
    flipBehavior: ['top', 'right', 'bottom'],
    popperOptions: {
      modifiers: {
        preventOverflow: {
          enabled: true,
        },
      },
    },
  });

  Vue.mixin(Common);

  Vue.prototype.$notify = PdlToast;

  const requireComponent = require.context('./components', true, /[A-Z]\w+\.(vue|js)$/);

  requireComponent.keys().forEach((fileName) => {
    const component = requireComponent(fileName);
    const name = upperFirst(
      camelCase(
        first(
          fileName
            .replace(/^\.\/(.*)\.\w+$/, '$1')
            .split('/')
            .reverse()
        )
      )
    );

    Vue.component(name, component.default || component);
  });

  Vue.component('PreSeasonOverviewHeader', PreSeasonOverviewHeader);
  Vue.component('PreSeasonSummaryLayout', PreSeasonSummaryLayout);
  Vue.component('BikeGarageApp', BikeGarageApp);
  Vue.component('TrekMarquee', TrekMarquee);
  Vue.component('ImageComponent', ImageComponent);
  Vue.component('ApplePayIcon', ApplePayIcon);
  Vue.component('IdealIcon', IdealIcon);
  Vue.component('LiveChat', LiveChat);
  Vue.component('PdlBadge', PdlBadge);
  Vue.component('PdlCollapse', PdlCollapse);
  Vue.component('PdlCollapseItem', PdlCollapseItem);
  Vue.component('PdlLink', PdlLink);
  Vue.component('PdlMediaObject', PdlMediaObject);
  Vue.component('PdlMediaObjectList', PdlMediaObjectList);
  Vue.component('PdlTabs', PdlTabs);
  Vue.component('PdlTabPane', PdlTabPane);
  Vue.component('PdlTabNav', PdlTabNav);
  Vue.component('PdlTabBar', PdlTabBar);
  Vue.component('RefLink', RefLink);
  Vue.component('PdlSectionHeader', PdlSectionHeader);
  Vue.component('PdlHeading', PdlHeading);
  Vue.component('PdlSubheading', PdlSubheading);
  Vue.component('PdlIcon', PdlIcon);
  Vue.component('B2bMiniCart', B2bMiniCart);
  Vue.component('B2cMiniCart', B2cMiniCart);

  // Navigation
  Vue.component('NavApp', NavApp);
  Vue.component('NavLargeContainer', NavLargeContainer);
  Vue.component('NavCategoriesLayout', NavCategoriesLayout);
  Vue.component('NavCategoriesLink', NavCategoriesLink);
  Vue.component('NavCompactContainer', NavCompactContainer);
  Vue.component('NavOverlayCompactLayout', NavOverlayCompactLayout);
  Vue.component('NavUtilityBarB2cContainer', NavUtilityBarB2cContainer);
  Vue.component('NavUtilityBarControl', NavUtilityBarControl);

  const vm = new Vue({
    store,
    i18n: I18n,

    el: container,
    mixins: [trackGlobalEvents],

    data() {
      return {
        isLoaded: false,
      };
    },

    computed: {
      ...mapGetters('backend', ['advancedOrderingMode']),
      ...mapState('backend', ['isConsumerFriendlyMode']),

      user() {
        return this.$store.state.user;
      },
    },
    beforeMount() {
      loadLanguageAsync(window.ACC.languageISO).then(() => {
        this.isLoaded = true;
      });
    },

    created() {
      window.addEventListener('resize', this.handleResize);
      this.getCurrentBreakpoint();
    },

    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },

    methods: {
      ...mapActions('backend', ['toggleConsumerFriendlyMode', 'toggleAdvancedOrderingMode']),
      ...mapMutations('backend', ['setConsumerFriendlyMode', 'setAdvancedOrderingMode']),
      ...mapActions('viewSize', ['handleResize', 'getCurrentBreakpoint']),

      toast(options) {
        return this.$notify(options);
      },

      checkForm(id) {
        const formData = new FormData(document.forms[id]);
        console.table([...formData]);
      },
    },
  });

  window.store = store;
  window.vm = vm;

  window.toast = store.state.backend.isB2Bsite && vm.toast ? vm.toast : console.log;
};

export default vueConfig;
