<template>
  <div>
    <div class="flex items-end">
      <div class="mb-0 mr-2">
        <label :for="`${startName}-${index}`" class="text-secondary" v-html="$t('text.start')" />
        <pdl-date-picker
          :input-id="`${startName}-${index}`"
          class="mb-0"
          :allow-input="true"
          :enable-time="true"
          :no-calendar="true"
          date-format="h:i K"
          alt-format="h:i K"
          :name="startName"
          :value.sync="localRange.startTime"
          :minute-increment="30"
          qaid="store-start-time-input"
          @change="updateTime($event, 'startTime')"
        />
      </div>

      <div class="mb-0">
        <label :for="`${endName}-${index}`" class="text-secondary" v-html="$t('text.end')" />
        <pdl-date-picker
          :input-id="`${endName}-${index}`"
          class="mb-0"
          :allow-input="true"
          :enable-time="true"
          :no-calendar="true"
          date-format="h:i K"
          alt-format="h:i K"
          :name="endName"
          :value.sync="localRange.endTime"
          :minute-increment="30"
          qaid="store-end-time-input"
          @change="updateTime($event, 'endTime')"
        />
      </div>

      <trek-button tertiary icon-only form-inline icon="delete" @click="$emit('remove', index)"> </trek-button>
    </div>
  </div>
</template>

<script>
import {PdlDatePicker} from '@pedal/pdl-date-picker';
import TrekButton from '@/components/TrekButton';

export default {
  name: 'StoreHourRange',

  components: {PdlDatePicker, TrekButton},

  props: {
    index: {
      type: Number,
      default: 0,
    },
    day: {
      type: Object,
      default: () => null,
    },
    range: {
      type: Object,
      default: () => ({
        startTime: '8:00 AM',
        endTime: '5:00 PM',
      }),
    },
  },
  data() {
    return {
      localRange: {
        startTime: '',
        endTime: '',
      },
    };
  },

  computed: {
    startName() {
      return `${this.day.name}-range-${this.range.id}-startTime`;
    },
    endName() {
      return `${this.day.name}-range-${this.range.id}-endTime`;
    },
  },

  watch: {
    localRange(range) {
      this.$emit('update', range);
    },
  },

  mounted() {
    this.localRange = this.range;
  },

  methods: {
    updateTime({event}, time) {
      this.localRange[time] = event;
    },
  },
};
</script>

<style lang="scss" scoped>
// Hide the clock icon on mobile
::v-deep .pdl-datepicker .form-label {
  @include breakpoint(large down) {
    display: none;
  }
}
</style>
