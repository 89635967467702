<template>
  <div class="b2b-mini-cart__item flex justify-between items-start">
    <pdl-heading :level="1" size="sm">
      {{ cartHeading }}
    </pdl-heading>
    <div class="text-right text-md">
      <div class="mb-1">
        <span v-html="`${$t('basket.page.qty')}: `" />
        <span qaid="miniCartItem--bikes__quantity" v-html="quantity" />
      </div>
      <div>
        <span v-html="`${$t('text.total')}: `" />
        <span qaid="miniCartItem--bikes__total" v-html="priceValue" />
      </div>
    </div>
  </div>
</template>

<script>
import {PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {PdlHeading},
  props: {
    baseProperties: {
      type: Array,
      required: false,
      default: () => [],
    },
    cartHeading: {
      type: String,
      default: '',
    },
    quantity: {
      type: Number,
      default: 0,
    },
    priceValue: {
      type: String,
      default: '',
    },
  },
};
</script>
