/**
 * Track checkout mixin is used to track cart data for our Analytics team.
 */

import {mapGetters, mapState} from 'vuex';

const trackCart = {
  created() {
    // Fire tracking page event after initial parse
    window.addEventListener('DOMContentLoaded', () => this.trackCartPageEvent());
  },

  computed: {
    ...mapGetters('cart', ['trackCartEntries']),
    ...mapState('backend', ['currencyIso']),
  },

  methods: {
    trackCartPageEvent() {
      if (!this.cartData?.entries?.length) {
        return;
      }

      const entries = this.cartData?.entries.map((entry) => {
        let variantMatrix = entry.product.variantMatrix; // hybris array splits color and size
        let variantColor = variantMatrix[0].variantValueCategory.name;
        let variantSize = variantMatrix.length > 1 ? variantMatrix[1].variantValueCategory.name : 'No Size Available';

        return {
          item_id: entry.product.code,
          currency: entry.basePrice.currencyIso,
          price: entry.basePrice.value,
          item_brand: entry.product.brandNameFull,
          item_category: entry.product.defaultCategory,
          item_color: variantColor,
          item_size: variantSize,
          item_list_name: entry.product.defaultCategory,
          quantity: entry.quantity,
        };
      });

      const cartPageEventData = {
        event: 'view_cart',
        ecommerce: {
          items: entries,
        },
      };

      ACC.track.trackEvent(cartPageEventData);
    },

    trackProceedCheckout() {
      const proceedCheckoutData = {
        event: 'checkout',
        ecommerce: {
          checkout: {
            currencyCode: this.currencyIso,
            actionField: {option: 'Begin Checkout'},
            products: this.trackCartEntries,
          },
        },
      };

      ACC.track.trackEvent(proceedCheckoutData);
    },
  },
};

export default trackCart;
