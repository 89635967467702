<template>
  <div qaid="pre-season-summary" class="flex flex-wrap gap-6 bg-gray-02 p-2 lg:p-4 mb-2">
    <div class="flex gap-6 w-full md:w-auto leading-normal">
      <div>
        <div :qaid="`pre-season-summary-qty-header`" class="text-sm text-gray-80" v-html="bp('summaryTotalQty')" />
        <div :qaid="`pre-season-summary-qty`" class="text-xl text-gray-90" v-text="summaryData.totalQty" />
      </div>
      <div class="md:pr-4">
        <div :qaid="`pre-season-summary-total-header`" class="text-sm text-gray-80" v-html="bp('total')" />
        <div :qaid="`pre-season-summary-total`" class="text-xl text-gray-90" v-text="summaryData.subTotal" />
      </div>
    </div>
    <div v-for="shipment in summaryData.orderShipments" :key="shipment.shipmentNumber">
      <div
        :qaid="`pre-season-summary-shipment-number-${shipment.shipmentNumber}`"
        class="text-sm leading-normal text-gray-80"
        v-html="`${bp('shipment')} ${parseInt(shipment.shipmentNumber) + 1}`"
      />
      <div
        v-if="shipment.deliveryDate !== ''"
        :qaid="`pre-season-summary-shipment-${shipment.shipmentNumber}-date`"
        class="text-xs leading-tight text-gray-80"
        v-html="shipment.deliveryDate"
      />
      <div
        v-else
        :qaid="`pre-season-summary-shipment-${shipment.shipmentNumber}-date`"
        class="text-xs leading-tight text-gray-80"
        v-html="bp('immediateShip')"
      />
      <div
        :qaid="`pre-season-summary-shipment-${shipment.shipmentNumber}-quantity`"
        class="text-md lg:text-sm leading-normal font-medium text-gray-90"
        v-html="shipment.shipmentQty"
      />
      <div
        :qaid="`pre-season-summary-shipment-${shipment.shipmentNumber}-cost`"
        class="text-md lg:text-sm leading-normal font-medium text-gray-90"
        v-html="shipment.formattedShipmentSubTotal"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreSeasonSummary',
  props: {
    orderFormType: {
      // Like Trek
      type: String,
      default: null,
    },
    bulkOrderFormId: {
      // Like Emonda
      type: String,
      default: null,
    },
    summaryData: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
