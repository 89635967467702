<template>
  <tr>
    <td class="b2b-grid__cell lg:table-cell">
      <grid-checkbox
        :qaid="`pk-grid-checkbox-${product.cartEntryPk}`"
        :label="String(product.cartEntryPk)"
        :checked="isChecked"
        @change="handleCheck"
      />
    </td>

    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid.icon"></td>

    <td v-if="isProjectOne" class="b2b-grid__cell lg:table-cell" qaid="sku-grid.sku">
      <div class="b2b-grid__label" v-html="bp('sku')" />
      <div class="b2b-grid__content">
        <grid-text :data="{value: product.sku}" />
      </div>
    </td>

    <td v-else class="b2b-grid__cell lg:table-cell" qaid="sku-grid.sku">
      <div class="b2b-grid__label" v-html="bp('sku')" />
      <div class="b2b-grid__content">
        <div class="flex items-center">
          <a
            :href="`${encodedContextPath}/my-account/orders?partNumber=${product.sku}`"
            class="leading-none mr-1"
            qaid="sku-grid.orderHistory"
          >
            <pdl-icon name="history" :label="$t('orders')" />
          </a>
          <grid-text
            class="flex"
            :data="{
              value: product.sku,
              tooltip: getTooltipText(product, false),
              link: encodedContextPath + '/p/' + product.sku,
            }"
          />
        </div>
      </div>
    </td>

    <td v-if="isProjectOne" class="b2b-grid__cell lg:table-cell" qaid="sku-grid.item">
      <div class="b2b-grid__label" v-html="bp('item')"></div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: baseProduct.itemName}"></grid-text>
      </div>
    </td>
    <td v-else class="b2b-grid__cell lg:table-cell" qaid="sku-grid.upc">
      <div class="b2b-grid__label" v-html="bp('upc')"></div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: product.ean ? product.ean : product.upc}"></grid-text>
      </div>
    </td>

    <td v-if="isCustomWaterBottle" class="b2b-grid__cell lg:table-cell h-auto lg:text-right" qaid="sku-grid-custom-id">
      <div class="b2b-grid__label" v-html="bp('customId')" />
      <div class="b2b-grid__content b2b-grid__content--right">
        <pdl-link qaid="cart-grid-custom-id-link" standalone :href="spectrumCustomizerUrl">
          <grid-text :data="{value: product.productRecipeID}" />
        </pdl-link>
      </div>
    </td>

    <td v-else-if="isProjectOne" class="b2b-grid__cell lg:table-cell h-auto lg:text-right" qaid="sku-grid-custom-id">
      <div class="b2b-grid__label" v-html="bp('customId')" />
      <div class="b2b-grid__content b2b-grid__content--right">
        <pdl-link qaid="cart-grid-custom-id-link" standalone :href="projectOneSummaryPageUrl">
          <grid-text :data="{value: product.p1ConfigurationId}" />
        </pdl-link>
      </div>
    </td>

    <template v-else>
      <td class="b2b-grid__cell lg:table-cell h-auto lg:text-right" qaid="sku-grid.allocated">
        <div class="b2b-grid__label" v-html="bp('allocated')"></div>
        <div class="b2b-grid__content">
          <grid-text :data="{value: product.quantityAllocated == 0 ? null : product.quantityAllocated}"></grid-text>
        </div>
      </td>

      <td class="b2b-grid__cell lg:table-cell h-auto lg:text-right" qaid="sku-grid.backordered">
        <div class="b2b-grid__label" v-html="bp('backordered')"></div>
        <div class="b2b-grid__content b2b-grid__content--right">
          <pdl-link
            v-if="product.quantityBackordered"
            qaid="cart-grid-backordered-link"
            standalone
            xsmall
            :href="backorderAllocatedUrl"
          >
            <grid-text :data="{value: product.quantityBackordered}" />
          </pdl-link>
        </div>
      </td>
    </template>

    <!-- Variable product variants -->
    <template v-for="(variant, variantIndex) in variantList">
      <td :key="variantIndex" class="b2b-grid__cell lg:table-cell">
        <!-- Labels (small view only) -->
        <div class="b2b-grid__label">
          <span v-if="variant.name == 'Size'" v-html="bp('size')"></span>

          <span v-else-if="variant.name == 'Color'" v-html="bp('color')"></span>

          <span v-else>{{ variant.name }}</span>
        </div>

        <div class="b2b-grid__content">
          <grid-text :data="{value: variant.value}"></grid-text>
        </div>
      </td>
    </template>

    <td
      v-if="!isConsumerFriendlyMode && !isProjectOne"
      class="b2b-grid__cell lg:table-cell text-left lg:text-right"
      qaid="sku-grid.order-price"
    >
      <div class="b2b-grid__label" v-html="bp('price')"></div>
      <div class="b2b-grid__content">
        <grid-price
          :data="{
            dealerCost: product.price.dealerCost,
            discount: discount,
          }"
        />
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell mb-4 md:mb-0 w-16" qaid="sku-grid.qty">
      <div class="b2b-grid__label" v-html="bp('qty')"></div>
      <div class="b2b-grid__content" :class="{'has-warning': minQtyError}">
        <span v-if="isProjectOne">1</span>
        <numerical-input
          v-else-if="minQtyError"
          :key="product.productRecipeID || product.sku"
          v-tippy="{
            html: `#qty-tooltip-${baseProductIndex}-${rowIndex}-${product.cartEntryPk}`,
            theme: 'light',
            maxWidth: '20rem',
            placement: 'top',
          }"
          :sku="product.sku"
          :initial-value="productQuantity"
          :data-sku="product.sku"
          :data-pk="product.cartEntryPk"
          :data-product-recipe-id="product.productRecipeID"
          :min="0"
          :max="9999"
          :data-initial-value="productQuantity"
          @blur.native="updateItemQuantity"
        />
        <numerical-input
          v-else
          :key="product.cartEntryPk"
          :sku="product.sku"
          :initial-value="productQuantity"
          :data-sku="product.sku"
          :data-pk="product.cartEntryPk"
          :data-product-recipe-id="product.productRecipeID"
          :min="0"
          :max="9999"
          :data-initial-value="productQuantity"
          @blur.native="updateItemQuantity"
        />
      </div>
    </td>

    <div
      v-show="false"
      :id="`qty-tooltip-${baseProductIndex}-${rowIndex}-${product.cartEntryPk}`"
      v-tippy-html
      class="hidden"
    >
      <span v-html="minQtyErrorMsg"></span>
    </div>

    <td v-if="!isConsumerFriendlyMode" class="b2b-grid__cell lg:table-cell" qaid="sku-grid.sku">
      <div class="b2b-grid__label" v-html="bp('sku')"></div>
      <div class="b2b-grid__content lg:text-right">
        <grid-text :data="{value: product.lineTotal}"></grid-text>
      </div>
    </td>

    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid.icon">
      <button
        :data-sku="product.sku"
        :data-pk="product.cartEntryPk"
        :data-product-recipe-id="product.productRecipeID"
        @click="deleteItem"
      >
        <pdl-icon size="18" name="delete" :label="$t('text.delete')" class="cursor-pointer" />
      </button>
    </td>
  </tr>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {mapActions, mapGetters, mapState} from 'vuex';
import GridCheckbox from '@/components/grid/GridCheckbox';
import GridText from '@/components/grid/GridText';
import GridPrice from '@/components/grid/GridPrice';
import NumericalInput from '@/components/NumericalInput';
import {PdlLink} from '@pedal/pdl-link';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {GridCheckbox, GridText, GridPrice, NumericalInput, PdlLink, PdlIcon},
  props: {
    baseProduct: {
      type: Object,
      default: () => null,
    },
    baseProductIndex: {
      type: Number,
      default: 0,
    },
    baseProperties: {
      type: Array,
      default: () => [],
    },
    product: {
      type: Object,
      default: () => null,
    },
    minQtyError: Boolean,
    minQtyErrorMsg: {
      type: String,
      default: '',
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    isCustomWaterBottle: {
      type: Boolean,
      default: false,
    },
    isProjectOne: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('cart', ['selectedProducts']),
    ...mapState('advancedOrdering', ['advancedOrderingMode']),
    ...mapState('backend', ['encodedContextPath', 'isConsumerFriendlyMode']),

    productQuantity() {
      return parseInt(this.product.skuQtyInCart);
    },
    variantList() {
      const variantProduct = this.baseProduct;
      const categoriesObject = get(this.product, 'variantValueCategories[0]');

      if (categoriesObject) {
        const categories = Object.keys(categoriesObject);
        let variants = [];

        categories.forEach(function (category) {
          if (variantProduct.groupBy != category) {
            if (category == 'Color') {
              variants.push({name: category, value: categoriesObject[category].name});
            } else {
              variants.push({name: category, value: categoriesObject[category]});
            }
          }
        });

        return variants;
      }

      return null;
    },

    isChecked() {
      return this.selectedProducts.some((product) => product.cartEntryPk === this.product.cartEntryPk);
    },

    discount() {
      if (this.product && this.product.price) {
        if (this.advancedOrderingMode && this.product.price.advancedPrice) {
          return this.product.price.advancedPrice;
        } else if (this.product.price.sale && Number(this.product.price.sale.replace(/[^0-9.-]+/g, '')) > 0) {
          return this.product.price.sale;
        }
      }

      return 0;
    },

    backorderAllocatedUrl() {
      const redirectPath = `${this.encodedContextPath}/my-account/backorders-allocated-items?productType=${this.product.productType}&skuSearchTerm=${this.product.sku}`;
      return redirectPath;
    },

    spectrumCustomizerUrl() {
      return this.product.sku && this.product.productRecipeID
        ? `${this.encodedContextPath}/p/${this.product.sku}?hasSpectrumOpen=true&recipeId=${this.product.productRecipeID}`
        : null;
    },

    projectOneSummaryPageUrl() {
      return this.product.p1ConfigurationId
        ? `${this.encodedContextPath}/project-one-builder/#/${this.product.p1ConfigurationId}/summary`
        : null;
    },
  },

  methods: {
    ...mapActions('cart', ['addSelectedProduct', 'removeSelectedProduct', 'updateSelectedProduct']),
    handleCheck(event) {
      if (event) {
        const payload = {
          sku: this.product.sku,
          qty: this.productQuantity,
          productType: this.product.productType,
          lineNote: this.product.lineNote,
          productRecipeID: this.product.productRecipeID,
          cartEntryPk: this.product.cartEntryPk,
        };
        this.addSelectedProduct(payload);
      } else {
        this.removeSelectedProduct(this.product.cartEntryPk);
      }
    },

    deleteItem(event) {
      let itemSku = this.product?.sku;
      let itemProductRecipeID = this.product?.productRecipeID;
      let cartEntryPk = this.product?.cartEntryPk;

      if (!isEmpty(itemSku)) {
        this.updateCartQuantity(itemSku, 0, event.currentTarget, itemProductRecipeID, cartEntryPk);
      }
    },

    getTooltipText(product, onlyMFR) {
      const replacementProduct = product.replacementProduct ?? null;
      const mfr = product.mfr ?? null;
      const replacementProductText = this.$t('productArchive.viewCurrent');
      let toolTipData = {};
      if (mfr !== null) {
        toolTipData['MFR'] = mfr;
      }
      if (replacementProduct && !onlyMFR) {
        toolTipData['replacementProductText'] = replacementProductText;
        toolTipData['replacementProductUrl'] = replacementProduct.url;
      }
      return toolTipData;
    },

    updateItemQuantity(event) {
      let itemSku = event.target.dataset.sku;
      let newQty = event.target.value;
      let itemProductRecipeID = event.target.dataset.productRecipeId;
      let cartEntryPk = event.target.dataset.pk;

      if (isEmpty(itemSku)) return;
      if (isEmpty(newQty)) newQty = 0;

      return this.updateCartQuantity(itemSku, newQty, event.target, itemProductRecipeID, cartEntryPk);
    },

    updateCartQuantity(itemSku, newQty, element, itemProductRecipeID, cartEntryPk) {
      if (newQty < 1) {
        this.$emit('open-confirm-delete-dialog', element);
      } else {
        // Update local warehouse qty first so that Vue updates correctly once the ajax cart updates finishes
        this.product.warehouses.forEach((warehouse, index) => {
          warehouse.orderedQty = index < 1 ? newQty : 0;
        });

        this.$emit('send-cart-update', [
          {
            sku: itemSku,
            qty: newQty,
            productRecipeID: itemProductRecipeID,
            cartEntryPk: cartEntryPk,
          },
        ]);
        if (this.isChecked) {
          this.updateSelectedProduct({
            sku: itemSku,
            qty: newQty,
            productRecipeID: itemProductRecipeID,
            cartEntryPk: cartEntryPk,
          });
        }
      }
    },
  },
};
</script>
