<template>
  <ul class="flex flex-col md:flex-row justify-center mb-2 items-center gap-4">
    <li v-for="platform in platforms" :key="platform.id">
      <pdl-button
        v-bind="getButtonAttributes(platform)"
        :qaid="`dst-download-button-${platform.id}`"
        @click="() => startDownload(platform)"
      >
        {{ getButtonLabel(platform) }}
      </pdl-button>
    </li>
  </ul>
</template>

<script>
const SUPPORTED_PLATFORMS_WIN = {
  id: 'win',
  isMac: false,
  path: '/dst/executable/download/WIN',
};
const SUPPORTED_PLATFORMS_MAC = {
  id: 'mac',
  isMac: true,
  path: '/dst/executable/download/MAC',
};
const SUPPORTED_PLATFORMS = Object.freeze([SUPPORTED_PLATFORMS_WIN, SUPPORTED_PLATFORMS_MAC]);

import {PdlButton} from '@pedal/pdl-button';
import occInstance from '@/api/instances/occ';

export default {
  name: 'DstDownload',
  components: {PdlButton},
  data: function () {
    return {
      platforms: SUPPORTED_PLATFORMS,
      userPlatform: SUPPORTED_PLATFORMS_WIN,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    getButtonAttributes({id}) {
      const current = this.userPlatform;
      if (current.id === id)
        return {
          primary: true,
        };
      return {
        text: true,
        underline: true,
      };
    },
    getButtonLabel(platform) {
      if (this.userPlatform.isMac && !platform.isMac) {
        return this.$t('dst.download.windows.redirect');
      } else if (this.userPlatform.isMac && platform.isMac) {
        return this.$t('dst.download.mac');
      }

      if (!this.userPlatform.isMac && platform.isMac) {
        return this.$t('dst.download.mac.redirect');
      } else if (!this.userPlatform.isMac && !platform.isMac) {
        return this.$t('dst.download.windows');
      }
    },
    init() {
      this.detectPlatform();
      this.sortPlatforms();
    },
    sortPlatforms() {
      this.platforms = [...SUPPORTED_PLATFORMS].sort((first, second) => {
        if (first.id === this.userPlatform.id) return -1;
        if (second.id === this.userPlatform.id) return 1;
      });
    },
    detectPlatform() {
      const userAgent = navigator.userAgent;
      const isMac = userAgent.toLowerCase().includes('mac');

      this.userPlatform = this.platforms.find((p) => p.isMac == isMac);
    },
    async startDownload({path}) {
      try {
        const response = await occInstance.get(path);
        if (!response?.data?.downloadUrl?.length > 0) {
          this.requestDownloadError();
          return;
        }

        const {downloadUrl} = response.data;
        window.location.href = downloadUrl;
      } catch (error) {
        this.requestDownloadError();
      }
    },
    requestDownloadError() {
      this.$notify({type: 'error', message: this.$t('errorMessage.unableToProcess'), showClose: true});
    },
  },
};
</script>
