<template>
  <tr>
    <td class="b2b-grid__cell lg:table-cell" qaid="businessUnitDetails.grid.cell.storeName">
      <div class="b2b-grid__label">{{ getBaseProperty('storeName') }}</div>
      <div class="b2b-grid__content">
        <a
          class="underline hover:no-underline mr-1"
          :href="storeNameLink"
          qaid="businessUnitDetails.grid.cell.storeName.link"
        >
          <span>{{ store.name }}</span>
        </a>
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="businessUnitDetails.grid.cell.address">
      <div class="b2b-grid__label">{{ getBaseProperty('address') }}</div>
      <div class="b2b-grid__content">{{ address.line1 }}</div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="businessUnitDetails.grid.cell.townCity">
      <div class="b2b-grid__label">{{ getBaseProperty('townCity') }}</div>
      <div class="b2b-grid__content">{{ address.town }}</div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="businessUnitDetails.grid.cell.state">
      <div class="b2b-grid__label">{{ getBaseProperty('state') }}</div>
      <div class="b2b-grid__content">{{ address.regionIso }}</div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="businessUnitDetails.grid.cell.country">
      <div class="b2b-grid__label">{{ getBaseProperty('country') }}</div>
      <div class="b2b-grid__content">{{ address.countryName }}</div>
    </td>
    <td
      class="b2b-grid__cell lg:table-cell"
      qaid="businessUnitDetails.grid.cell.delete"
      @click="$emit('delete-store', {id: store.id})"
    >
      <pdl-icon
        name="delete"
        :label="$t('text.delete')"
        class="cursor-pointer"
        qaid="businessUnitDetails.grid.cell.delete.button"
      />
    </td>
  </tr>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';
import {mapState} from 'vuex';

export default {
  components: {PdlIcon},
  props: {
    baseProperties: {
      type: Array,
      default: () => [],
    },
    store: {
      type: Object,
      default: () => null,
    },
    viewPageUrl: {
      type: String,
      default: '',
    },
  },

  computed: {
    address() {
      return this.store && this.store.address ? this.store.address : {};
    },

    storeNameLink() {
      return `${this.encodedContextPath}${this.viewPageUrl}/${this.store.id}`;
    },

    ...mapState('backend', ['encodedContextPath']),
  },
};
</script>
