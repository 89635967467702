<template>
  <fieldset class="max-w-xs" qaid="select-list.field">
    <label for="select" class="form-label">
      Select a list
      <span class="text-secondary">(optional)</span>
    </label>
    <div class="select">
      <select id="select" class="form-control" qaid="select-list.select" @change="onChange">
        <optgroup v-if="userLists.length" label="User Lists">
          <option v-for="(list, listId) in userLists" :key="listId" :value="list.id" qaid="select-list.option">
            {{ list.name }} ({{ list.totalEntries }})
          </option>
        </optgroup>
        <optgroup v-if="accountLists.length" label="Account Lists">
          <option v-for="(list, listId) in accountLists" :key="listId" :value="list.id" qaid="select-list.option">
            {{ list.name }} ({{ list.totalEntries }})
          </option>
        </optgroup>
      </select>
    </div>
  </fieldset>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    lists: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState('savedLists', ['userLists', 'accountLists']),
  },

  methods: {
    onChange(event) {
      this.$emit('list-changed', event.target.value);
    },
  },
};
</script>
