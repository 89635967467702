import {trackEvent} from '@/utils/tracking';

const trackPdp = {
  data() {
    return {
      trackEventData: {},
    };
  },

  computed: {
    isTrackDataReady() {
      const trackDataChecks = [
        Object.keys(this.product).length,
        Object.keys(this.initAttributeSelection).length,
        this.variantCode?.length,
        Object.keys(this.options).length,
        this.isConsumerPriceReady,
      ];

      return trackDataChecks.every((check) => !!check);
    },
  },

  watch: {
    isTrackDataReady() {
      // init trackEventData
      let pdpData = {
        pageEvent: 'productDetail',
        productSource: 'pdp',
        productName: this.product.name,
        productId: this.product.code,
        productPrice: this.consumerPrice.displayPriceValue.calculable,
        productBrand: this.product.variants[0].brandNameFull,
        productCategory: this.category.name,
        productColor: Object.values(this.initAttributeSelection)[0],
        productSize: this.productSizeText(this.variantCode),
        variantCode: this.variantCode,
        currencyIso: this.currencyIso,
        position: 'page visit',
      };

      this.setTrackData(pdpData);
      this.trackPageEvent(this.trackEventData);
    },

    selectedColor(value) {
      // Updates trackEventData selected color variant
      let colorData = {productColor: value};
      this.setTrackData(colorData);
    },
  },

  methods: {
    trackPageEvent() {
      const trackPdpPageData = {
        event: this.trackEventData.pageEvent,
        ecommerce: {
          detail: {
            actionField: {list: this.trackEventData.productSource},
            products: [
              {
                name: this.trackEventData.productName,
                id: this.trackEventData.productId,
                price: this.trackEventData.productPrice,
                brand: this.trackEventData.productBrand,
                category: this.trackEventData.productCategory,
                variant: this.trackEventData.productColor,
                position: this.trackEventData.position,
              },
            ],
          },
        },
      };

      trackEvent(trackPdpPageData);
    },

    /**
     * track data helper makes a copy of trackEventData and spreads a data object
     * @params data <Object>
     */
    setTrackData(data) {
      this.trackEventData = {...this.trackEventData, ...data};
    },

    /**
     * Takes the variant code and formats the sizing option from the productData sans underscore
     * @params code <String>
     */
    productSizeText(code) {
      if (!code?.length && !Object.keys(this.product).length) return;

      const variantAttributes = this.product.variants.find((item) => item.code === code)?.variantAttributes ?? {};
      const groupValue = Object.values(variantAttributes)?.[1] ?? '';
      return groupValue?.replace(/_/g, ' ') ?? '';
    },
  },
};

export default trackPdp;
