import {FunctionalityTogglesApi} from '@/api/functionality-toggles.js';
import {onMounted, ref} from 'vue';

export function useFunctionalityToggle(toggleId) {
  const isToggleActive = ref(false);

  onMounted(async () => {
    try {
      const toggle = await FunctionalityTogglesApi.getToggleById(toggleId);
      isToggleActive.value = toggle?.active;
    } catch (error) {
      console.error('Failed to fetch toggle configuration', toggleId, error);
    }
  });

  return {isToggleActive};
}
