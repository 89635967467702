'use strict';
import facets from '../modules/facets';
import {Url} from '@/utils/url';
import get from 'lodash/get';
import store from '@/store';

export default function () {
  $(document).ready(function () {
    const isSearch = $('.page-search').length;
    const search_string = $('#searchTabSearchString').val();
    const content_search_url = ACC.config.encodedContextPath + '/search/contentsearch/';
    const b2b_articles_search_url = ACC.config.encodedContextPath + '/search/articlessearch/';
    const b2b_other_content_search_url = ACC.config.encodedContextPath + '/search/othercontentsearch/';
    const archive_search_url = ACC.config.encodedContextPath + '/search/archive/';
    const error_loading_msg = '<div>Sorry, there was a problem loading search results.</div>'; // a base property would be nice!
    let tab_fetched = {archive: false, content: false, articles: false, 'other-content': false};

    if (isSearch) {
      main();
    }

    function getDefaultViewOptions(url) {
      let pageSize = ACC.paginationsort.articlesPageSize;
      let currentPage = ACC.paginationsort.articlesCurrentPage;
      let sort = ACC.paginationsort.articlesSort;

      if (url === b2b_other_content_search_url) {
        pageSize = ACC.paginationsort.otherContentPageSize;
        currentPage = ACC.paginationsort.otherContentCurrentPage;
        sort = ACC.paginationsort.otherContentSort;
      }

      return {
        pageSize,
        currentPage,
        sort,
      };
    }

    function performSearchAjax(url, options) {
      options = Object.assign({skipColumns: []}, getDefaultViewOptions(url), options);
      let params = {
        q: search_string,
        pageSize: options.pageSize,
        page: options.currentPage,
        sort: options.sort,
      };

      let paramsList = [];
      for (let param in params) {
        if (options.skipColumns && options.skipColumns.indexOf(param) > -1) {
          continue;
        }

        paramsList.push(`${param}=${params[param]}`);
      }

      return $.ajax({
        url: url,
        type: 'get',
        data: paramsList.join('&'),
        dataType: 'json',
      });
    }

    function populateTab(tab, data) {
      try {
        if (tab === '#search-results__archive') {
          $('[data-archive-list]').html($(data)).foundation();
          facets($(data));
        } else if (tab === '#search-results__articles') {
          $('[data-articles-list]').html($(data)).foundation();
          facets($(data));
        } else {
          $(tab).html($(data)).foundation();
          const articleList = $('[data-article-list]');
          facets(articleList);
        }
      } catch (error) {
        console.log(error);
      }
    }

    function revealTabs() {
      $('.not-empty-search-results').removeClass('hidden');
      $('.empty-search-results').addClass('hidden');
    }

    function revealTabsAndOpen(tabname) {
      revealTabs();
      $(`#search-results__${tabname}-tab`).click();
      window.loadingIndicator.hide();
    }

    function endLoadAnimationAndShowError(target = '.empty-search-results', content = error_loading_msg) {
      populateTab(target, content);
      window.loadingIndicator.hide();
    }

    function handleActiveTab() {
      if (!window.location.hash) {
        return;
      }

      let $tab = $(window.location.hash.replace('-filter', '-tab') + ' a');
      if (!$tab || !$tab.length) {
        return;
      }

      setTimeout(function () {
        $tab.click();
        window.location.hash = '';
      }, 1);
    }

    function configurePageControls() {
      ACC.paginationsort.bindAll();

      let $elements = $(
        'form[name="sort_form1"], form[name="sort_form2"], form[name="page_form1"], form[name="page_form2"]'
      );
      let $activeTab = $('#search-results__tabs .tabs-title.is-active a');
      if (!$elements || !$elements.length || !$activeTab || !$activeTab.length) {
        return;
      }

      const currentHash = $activeTab.attr('href') + '-filter';
      $elements.each(function () {
        $(this).attr('action', currentHash);
      });
    }

    function getViewOptionsByTabType(tab) {
      let options = {};
      const urlParams = Url.parse(window.location.href);

      if (tab === '#search-results__archive') {
        options['sort'] = get(urlParams, 'sort', 'relevance');
      }

      return options;
    }

    function tabClickHandler(tab, url, flag) {
      if (tab === '#search-results__archive' && store.state.backend.isB2Bsite) {
        return;
      }

      if (ACC && ACC.paginationsort) ACC.paginationsort.currentTab = flag;
      if (tab_fetched[flag] === false) {
        let skipColumns = [];
        if (!ACC.paginationsort.passPageSize) {
          skipColumns.push('pageSize');
        }

        window.loadingIndicator.show();
        $.when(performSearchAjax(url, {skipColumns: skipColumns, ...getViewOptionsByTabType(tab)}))
          .done(function (data) {
            populateTab(tab, data.html);
            const ev = new CustomEvent('init-vue-dynamic-content');
            window.dispatchEvent(ev);
            $(document).foundation();
            window.loadingIndicator.hide();
            tab_fetched[flag] = true;
            configurePageControls();
          })
          .fail(function () {
            endLoadAnimationAndShowError();
          });
      }
    }

    $(document).on(
      'click',
      '#search-results__archive .pagination__button, #search-results__archive .pagination__link',
      function (event) {
        event.preventDefault();
        loadResults('archive', $(event.target).attr('href'));
      }
    );

    $('#search-results__tabs li.tabs-title').on('click', () => {
      // Update active tab and bind events after switching to the tab.
      setTimeout(configurePageControls, 100);
    });

    if (store.state.backend.isB2Bsite) {
      $('#search-results__products-tab, #search-results__archive-tab').on('click', function (e) {
        const ev = new CustomEvent('plp-set-api-url', {
          detail: {
            url: e.target.id === 'search-results__archive-label' ? '/search/archive/' : '/search/productResults/',
          },
        });
        window.dispatchEvent(ev);
      });
    } else {
      $('#search-results__archive-tab').on('click', function () {
        tabClickHandler('#search-results__archive', archive_search_url, 'archive');
      });
    }

    $('#search-results__content-tab').click(() =>
      tabClickHandler('#search-results__content', content_search_url, 'content')
    );

    $('#search-results__articles-tab').click(() => {
      tab_fetched.articles = false;
      tabClickHandler('#search-results__articles', b2b_articles_search_url, 'articles');
    });

    $('#search-results__other-content-tab').click(() => {
      tab_fetched['other-content'] = false;
      tabClickHandler('#search-results__other-content', b2b_other_content_search_url, 'other-content');
    });

    function decodePlpPaginationHref() {
      $('.pagination__link[href]').each((index, item) => {
        $(item).attr('href', decodeURIComponent($(item).attr('href')));
      });
    }

    // On every reload or ajax call finish call decodePlpPaginationHref()
    decodePlpPaginationHref();
    $(window).ajaxComplete(() => {
      decodePlpPaginationHref();
    });

    // Start chained ajax searches if content-related results are 0
    // If the solr search results for products are 0, the backend sets the
    // value of #contentTabSearchResults, a hidden input to 0, and it starts
    // AJAX calls for content and retailers if necessary

    function checkAndLoadContentTab() {
      const nextTab = store.state.backend.isB2Bsite ? 'articles' : 'content';
      const nextTabAjaxUrl = store.state.backend.isB2Bsite ? b2b_articles_search_url : content_search_url;
      if (!$('#' + nextTab + 'TabSearchResults').val()) {
        $('.facetNavRefinements').hide();
        window.loadingIndicator.show();
        loadResults(nextTab, nextTabAjaxUrl);
      } else {
        // products found, reveal tabs and leave product tab open
        revealTabs();
      }
    }

    function main() {
      handleActiveTab();
      if (store.state.backend.isB2Bsite) {
        window.addEventListener('plp-data-loaded', (ev) => {
          if (
            ev.detail &&
            ev.detail.options &&
            !ev.detail.options.totalCount &&
            ev.detail.settings.locationId !== 'archive'
          ) {
            checkAndLoadContentTab();
          }
        });
        revealTabs();
      } else {
        checkAndLoadContentTab();
      }
    }

    function loadResults(tab, ajaxUrl) {
      $.when(performSearchAjax(ajaxUrl))
        .done(function (data) {
          populateTab('#search-results__' + tab, data.html);
          tab_fetched[tab] = true;

          if (data.resultcount !== 0) {
            revealTabsAndOpen(tab);
          } else {
            switch (tab) {
              case 'archive':
                loadResults('archive', archive_search_url);
                break;
              case 'articles':
                loadResults('other-content', b2b_other_content_search_url);
                break;
              case 'other-content':
                // present zero results section and hide everything else
                $('.empty-search-results').html(data.searchEmptyhtml);
                window.loadingIndicator.hide();
                break;
            }
          }
        })
        .fail(function () {
          // content ajax fail
          endLoadAnimationAndShowError();
        });
    }
  });
}
