<template>
  <section>
    <div class="grid-x grid-margin-x">
      <div class="cell large-4">
        <pdl-section-header size="sm" class="mb-3">
          <template slot="content">
            <pdl-heading :level="4" qaid="warranty-claim-line-issue-details">
              {{ $t('warrantyClaims.B2B.issue.details') }}
            </pdl-heading>
          </template>
        </pdl-section-header>

        <div
          class="form-group"
          qaid="warranty-claim-line-issue-type-label"
          :class="{
            'has-error': getErrorMessage('localClaimLine.issueTypeNameSelected'),
            'is-required': true,
          }"
        >
          <label class="form-label" :for="issueTypeInput" qaid="warranty-claim-line-issue-type-label__label">
            <span>{{ $t('contactUs.issueType') }}</span>
            <span v-if="issueDetailsEnabled" class="text-red ml-25"> * </span>
          </label>
          <input-autocomplete
            :initial-value="localClaimLine.issueTypeNameSelected"
            :input-id="issueTypeInput"
            input-classes="form-control"
            :input-qaid="issueTypeInput"
            :input-name="issueTypeInput"
            :suggestion-list="autocompleteOptionsList"
            :input-error-name="$t('contactUs.issueType')"
            :disabled="!issueDetailsEnabled"
            :force-suggestion-selection="true"
            @input-updated="handleAutocompleteUpdate"
            @valid-selection="issueSelected"
          />
          <span
            v-show="getErrorMessage('localClaimLine.issueTypeNameSelected')"
            class="form-feedback is-invalid"
            v-html="getErrorMessage('localClaimLine.issueTypeNameSelected')"
          />
        </div>

        <div class="form-group">
          <span v-show="!issueDetailsEnabled" class="text-secondary cursor-not-allowed">{{
            $t('warrantyClaims.B2B.issue.descriptionChart')
          }}</span>
          <a
            v-show="issueDetailsEnabled"
            ref="tippy"
            v-tippy="{
              html: '#issue-types-panel',
              interactive: true,
              reactive: true,
              trigger: 'click',
              placement: 'right',
            }"
            class="underline hover:no-underline"
            qaid="warranty-claim-line-issue-chart"
            @hidden="resetTippy"
            >{{ $t('warrantyClaims.B2B.issue.descriptionChart') }}</a
          >
        </div>

        <warranty-claim-line-issue-types
          id="issue-types-panel"
          class="text-md p-1"
          :warranty-issues="warrantyIssues"
          :reset-issues="resetTippyIssues"
          @issue-selected="issueSelected"
        />

        <form-group
          v-model="localClaimLine.selectedDamagedOccurred"
          type="radio"
          :label="$t('warrantyClaims.B2B.issue.when')"
          qaid="warranty-claim-line-issue-when"
          :multi-items="damageOccurredMethodOptions"
          :name="inputName('issue-damage-occurred-method')"
          :error="getErrorMessage('localClaimLine.selectedDamagedOccurred')"
          required
          show-error-messages
          force-display-error
          @change="handleDamageOccurredMethodChange"
        >
          <template #form-errors>
            <span class="form-feedback">{{ $t('warrantyClaims.B2B.allFieldsRequired') }}</span>
          </template>
        </form-group>
        <form-group
          v-model="localClaimLine.selectedRequestedAction"
          type="radio"
          :label="$t('warrantyClaims.B2B.issue.requestedAction')"
          qaid="warranty-claim-line-issue-action"
          :multi-items="requestedActionOptions"
          :name="inputName('issue-requested-action')"
          :error="getErrorMessage('localClaimLine.selectedRequestedAction')"
          required
          show-error-messages
          force-display-error
          @change="handleRequestedActionChange"
        >
          <template #form-errors>
            <span class="form-feedback">{{ $t('warrantyClaims.B2B.allFieldsRequired') }}</span>
          </template>
        </form-group>

        <form-group
          v-if="displayWarrantyShipmentOptions"
          v-model="localClaimLine.warrantyConsumerWaiting"
          type="radio"
          :error="getErrorMessage('localClaimLine.warrantyConsumerWaiting')"
          required
          show-error-messages
          force-display-error
          qaid="warranty-claim-line-issue-shipment"
          :multi-items="getShipmentOptions"
          :name="inputName('shipment-option')"
          @change="handleShipmentOptionChange"
        >
          <template #label>
            <legend class="form-fieldset__legend">
              <span>{{ $t('warrantyClaims.B2B.shipmentOptions') }}</span>
              <pdl-icon
                v-tippy="{
                  html: `#warranty-claim-line-issue-shipment-options-tooltip `,
                  placement: 'top-start',
                  arrow: true,
                  maxWidth: '20rem',
                  interactive: true,
                }"
                class="ml-25 self-center"
                size="18"
                name="help"
              />
              <div id="warranty-claim-line-issue-shipment-options-tooltip" v-tippy-html class="hidden">
                <span
                  class="inline-block text-left"
                  v-html="$t('warrantyClaims.B2B.shipmentOptions.customerWaiting')"
                />
              </div>
              <span class="text-red ml-25">
                *
                <span class="show-for-sr">(required)</span>
              </span>
            </legend>
          </template>
          <template #form-errors>
            <span class="form-feedback">{{ $t('warrantyClaims.B2B.allFieldsRequired') }}</span>
          </template>
        </form-group>
      </div>
    </div>
  </section>
</template>

<script>
import FormGroup from '@/components/FormGroup';
import find from 'lodash/find';
import {mapActions, mapMutations, mapState} from 'vuex';
import WarrantyClaimLineIssueTypes from '@/components/containers/warranty-claim/WarrantyClaimLineIssueTypes';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import InputAutocomplete from '@/components/InputAutocomplete';
import {PdlIcon} from '@pedal/pdl-icon';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
import useVuelidate from '@vuelidate/core';

export default {
  components: {FormGroup, PdlSectionHeader, PdlHeading, WarrantyClaimLineIssueTypes, InputAutocomplete, PdlIcon},
  mixins: [TrekValidationMixin],

  props: {
    claimLine: {
      type: Object,
      required: true,
    },
    claimIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    issueDetailsEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    warrantyIssues: {
      type: Array,
      required: true,
    },
    filterIssuesThreshold: {
      type: Number,
      default: 100,
    },
    displayWarrantyShipmentOptions: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },

  data() {
    return {
      resetTippyIssues: false,
      flatWarrantyIssues: null,
      localClaimLine: this.claimLine,
    };
  },

  validations() {
    return {
      localClaimLine: {
        selectedDamagedOccurred: {
          required: this.trekValidators.required('warrantyClaims.B2B.issue.when'),
        },
        selectedRequestedAction: {
          required: this.trekValidators.required('warrantyClaims.B2B.issue.requestedAction'),
        },
        warrantyConsumerWaiting: {
          requiredIf: this.trekValidators.requiredIf(
            'warrantyClaims.B2B.shipmentOptions',
            this.displayWarrantyShipmentOptions
          ),
        },
        issueTypeNameSelected: {
          requiredIf: this.trekValidators.requiredIf('contactUs.issueType', this.issueDetailsEnabled),
        },
      },
    };
  },

  computed: {
    ...mapState('warrantyClaims', ['claimData']),
    issueTypeNameSelected() {
      return this.localClaimLine.issueTypeNameSelected;
    },

    // Pre-filters the warranty issues available to dropdown list
    filteredWarrantyIssues() {
      if (!this.warrantyIssues) return [];
      let tempIssues = this.getFlatWarrantyIssues(this.warrantyIssues, null, true);

      if (tempIssues?.length > this.filterIssuesThreshold && this.claimLine.issueTypeNameSelected) {
        tempIssues = tempIssues.filter((issue) => {
          return issue?.toLowerCase().startsWith(this.claimLine.issueTypeNameSelected.toLowerCase());
        });
      }
      return tempIssues;
    },
    autocompleteOptionsList() {
      const MIN_CHARACTER_SEARCH_COUNT = 3;
      const issueType = this.localClaimLine?.issueTypeNameSelected;
      if (issueType === null || issueType?.length < MIN_CHARACTER_SEARCH_COUNT) return;
      return this.filteredWarrantyIssues.map((suggestion, index) => {
        return {id: index, value: suggestion};
      });
    },
    getShipmentOptions() {
      return [
        {value: false, displayName: this.$t('warrantyClaims.B2B.shipmentOptions.nextScheduled')},
        {value: true, displayName: this.$t('warrantyClaims.B2B.shipmentOptions.shipNow')},
      ];
    },
    requestedActionOptions() {
      if (!this.claimData.requestedActionMethods) {
        return [];
      }

      return this.claimData.requestedActionMethods.map((requestedAction) => ({
        value: requestedAction.code,
        displayName: requestedAction.name,
      }));
    },

    damageOccurredMethodOptions() {
      if (!this.claimData.damageOccurredMethods) {
        return [];
      }

      return this.claimData.damageOccurredMethods.map((damageMethod) => ({
        value: damageMethod.code,
        displayName: damageMethod.name,
      }));
    },

    issueTypeInput() {
      return this.inputName('issueTypeNameSelected');
    },
  },

  watch: {
    warrantyIssues() {
      this.flatWarrantyIssues = this.getFlatWarrantyIssues(this.warrantyIssues);
      this.resetTippy();
    },
    claimLine: {
      deep: true,
      handler(value) {
        this.localClaimLine = value;
      },
    },
    localClaimLine: {
      deep: true,
      handler(value) {
        if (this.localClaimLine.issueTypeNameSelected === null) this.localClaimLine.issueTypeCodeSelected = null;
        this.updateClaimLine({index: this.claimIndex, value});
      },
    },
  },

  mounted() {
    this.flatWarrantyIssues = this.getFlatWarrantyIssues(this.warrantyIssues);
  },

  methods: {
    ...mapActions('warrantyClaims', ['saveClaim', 'updateClaimLineProperty']),
    ...mapMutations('warrantyClaims', ['updateClaimLine']),

    inputName(propertyName) {
      return `cl${this.claimIndex}_${propertyName}`;
    },

    getFlatWarrantyIssues(warrantyIssues, options, ufoNameOnly) {
      if (!options) options = [];
      if (!warrantyIssues) return options;

      warrantyIssues.forEach((issue) => {
        if (issue.warrantyIssueTypes && issue.warrantyIssueTypes.length) {
          // Issue has sub-issues. Recusively add them
          options = this.getFlatWarrantyIssues(issue.warrantyIssueTypes, options, ufoNameOnly);
        } else if (issue.warrantyUfoName && issue.warrantyUfoCode) {
          if (ufoNameOnly) {
            options.push(issue.warrantyUfoName);
          } else {
            options.push(issue);
          }
        }
      });

      return options;
    },

    issueSelected(input) {
      this.localClaimLine.issueTypeNameSelected = input;

      let issueObject = find(this.flatWarrantyIssues, (issue) => issue.warrantyUfoName === input);

      if (issueObject && issueObject.warrantyUfoCode && issueObject.salesCategorySRP5) {
        this.localClaimLine.issueTypeCodeSelected = issueObject.warrantyUfoCode;
        this.localClaimLine.salesCategorySRP5Code = issueObject.salesCategorySRP5;

        this.localClaimLine.warrantyRequiredImagesList = issueObject.warrantyRequiredImages;
      } else {
        this.localClaimLine.issueTypeCodeSelected = null;
        this.localClaimLine.salesCategorySRP5Code = null;

        this.localClaimLine.warrantyRequiredImagesList = [];
      }

      this.$forceUpdate();
      this.closeTooltip();

      this.$nextTick(() => this.v$.localClaimLine.issueTypeNameSelected.$touch());

      this.saveClaim();
    },

    resetTippy() {
      this.resetTippyIssues = true;

      // Reset boolean on a timer so we can use it again later
      setTimeout(() => {
        this.resetTippyIssues = false;
      }, 1000);
    },

    closeTooltip() {
      let el = this.$refs.tippy;

      if (el && el._tippy) {
        el._tippy.hide();
      }
    },

    handleRequestedActionChange(e) {
      this.updateClaimLineProperty({
        claimLineId: this.claimLine.id,
        propName: 'selectedRequestedAction',
        value: e.target.value,
      });
      this.v$.localClaimLine.selectedRequestedAction.$touch();
    },

    handleDamageOccurredMethodChange(e) {
      this.updateClaimLineProperty({
        claimLineId: this.claimLine.id,
        propName: 'selectedDamagedOccurred',
        value: e.target.value,
      });
      this.v$.localClaimLine.selectedDamagedOccurred.$touch();
    },

    handleShipmentOptionChange(e) {
      this.updateClaimLineProperty({
        claimLineId: this.claimLine.id,
        propName: 'warrantyConsumerWaiting',
        value: e.target._value,
      });
      this.v$.localClaimLine.warrantyConsumerWaiting.$touch();
    },

    handleAutocompleteUpdate(event) {
      this.localClaimLine.issueTypeNameSelected = event.inputValue;
    },
  },
};
</script>
