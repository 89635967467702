<template>
  <div class="sprocket__toggle-switch">
    <input
      :id="`toggle-switch-${data.id}`"
      class="switch-input"
      type="checkbox"
      :name="`toggle-switch-${data.id}`"
      :checked="isChecked"
      @click="giveFocus"
      @change="handleChange"
    />
    <label class="switch-paddle" :for="`toggle-switch-${data.id}`"> </label>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isChecked: this.data.checked,
    };
  },
  methods: {
    handleChange(event) {
      this.isChecked = event.target.checked;
      this.$emit('switch-change', event.target.checked);
    },
    giveFocus(event) {
      event.target.focus();
    },
  },
};
</script>
