<template>
  <pdl-icon :size="size">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.713 5.999c0-.16.126-.286.286-.286h8.704v6.145h-8.99v-5.86Zm9.846 5.848h6.693s-.045-.08-.08-.114l-1.325-1.211a2.102 2.102 0 0 1-.468-.628l-1.884-4.02a.279.279 0 0 0-.263-.16h-2.684v6.144l.011-.011Zm-9.846.857h16.562v2.147h-.72a2.575 2.575 0 0 0-2.42-1.713c-1.12 0-2.068.72-2.422 1.713H9.276a2.575 2.575 0 0 0-2.422-1.713c-1.119 0-2.067.72-2.421 1.713h-.72v-2.147ZM2 13.412V5.999C2 4.89 2.89 4 3.999 4h12.256c.776 0 1.485.445 1.816 1.154l1.884 4.02s.035.069.069.092l1.325 1.21c.411.377.651.914.651 1.474v3.187c0 .788-.64 1.427-1.428 1.427h-1.005c-.16.457-.445.846-.81 1.142H20c.468 0 .857.389.857.857a.863.863 0 0 1-.857.857H4a.863.863 0 0 1-.857-.857c0-.468.389-.857.857-.857h1.245a2.563 2.563 0 0 1-.811-1.142H3.428c-.788 0-1.428-.64-1.428-1.428v-1.724Zm12.713 3.14c.16.458.445.846.81 1.143H8.477c.366-.297.651-.685.811-1.142h5.437-.011Zm-6.716-.856c0 .628-.514 1.142-1.143 1.142a1.146 1.146 0 0 1-1.142-1.142c0-.628.514-1.142 1.142-1.142.629 0 1.143.514 1.143 1.142Zm10.28 0c0 .628-.515 1.142-1.143 1.142a1.146 1.146 0 0 1-1.142-1.142c0-.628.514-1.142 1.142-1.142.628 0 1.142.514 1.142 1.142Z"
        fill="#000"
      />
    </svg>
  </pdl-icon>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {PdlIcon},
  props: {
    size: {
      type: String,
      default: '',
    },
  },
};
</script>
