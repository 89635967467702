import {Mask} from './mask';
import {parseInput} from './parser';

export class MaskInput {
  items = new Map();

  constructor(target, options) {
    if (typeof target === 'string') {
      this.init(Array.from(document.querySelectorAll(target)), this.getMaskOpts(options));
    } else {
      this.init('length' in target ? Array.from(target) : [target], this.getMaskOpts(options));
    }
  }

  destroy() {
    for (const input of this.items.keys()) {
      input.removeEventListener('input', this.inputEvent);
      input.removeEventListener('beforeinput', this.beforeinputEvent);
    }
    this.items.clear();
  }

  needUpdateOptions(input, opts) {
    const mask = this.items.get(input);
    const maskNew = new Mask(parseInput(input, this.getMaskOpts(opts)));
    return JSON.stringify(mask.opts) !== JSON.stringify(maskNew.opts);
  }

  needUpdateValue(input) {
    const value = input.dataset.maskValue;

    return (value == null && input.value !== '') || (value != null && value !== input.value);
  }

  getMaskOpts(options) {
    // eslint-disable-next-line no-unused-vars
    const {onMask, preProcess, postProcess, ...opts} = options;
    return opts;
  }

  init(inputs, defaults) {
    for (const input of inputs) {
      const mask = new Mask(parseInput(input, defaults));
      this.items.set(input, mask);

      if (input.value !== '') {
        this.setMaskedValue(input, input.value);
      }

      input.addEventListener('input', this.inputEvent);
      input.addEventListener('beforeinput', this.beforeinputEvent);
    }
  }

  beforeinputEvent = (e) => {
    const input = e.target;
    const mask = this.items.get(input);

    // delete first character in eager mask when it's the only left
    if (
      mask.isEager() &&
      'inputType' in e &&
      e.inputType.startsWith('delete') &&
      mask.unmasked(input.value).length <= 1
    ) {
      this.setMaskedValue(input, '');
    }
  };

  inputEvent = (e) => {
    if (
      e instanceof CustomEvent &&
      e.type === 'input' &&
      e.detail != null &&
      typeof e.detail === 'object' &&
      'masked' in e.detail
    ) {
      return;
    }

    const input = e.target;
    const mask = this.items.get(input);
    const valueOld = input.value;
    const ss = input.selectionStart;
    const se = input.selectionEnd;
    let value = valueOld;

    if (mask.isEager()) {
      const masked = mask.masked(valueOld);
      const unmasked = mask.unmasked(valueOld);

      if (unmasked === '' && 'data' in e && e.data != null) {
        // empty state and something like `space` pressed
        value = e.data;
      } else if (unmasked !== mask.unmasked(masked)) {
        value = unmasked;
      }
    }

    this.setMaskedValue(input, value);

    // set caret position
    if ('inputType' in e) {
      if (e.inputType.startsWith('delete') || (ss != null && ss < valueOld.length)) {
        try {
          // see https://github.com/beholdr/maska/issues/118
          input.setSelectionRange(ss, se);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  setMaskedValue(input, value) {
    const mask = this.items.get(input);

    const masked = mask.masked(value);
    const unmasked = mask.unmasked(mask.isEager() ? masked : value);
    const completed = mask.completed(value);
    const detail = {masked, unmasked, completed};

    value = masked;

    input.value = value;
    input.dataset.maskValue = value;

    input.dispatchEvent(new CustomEvent('mask', {detail}));
    input.dispatchEvent(new CustomEvent('input', {detail}));
  }
}
