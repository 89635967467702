<template>
  <dl class="description-list grid-x grid-margin-x max-w-md">
    <template v-if="!isCreditMemo">
      <dt class="cell small-6">{{ $t('text.trackingNumber') }}</dt>
      <dd class="cell small-6">
        <trek-link
          button
          text
          underline
          icon-right
          icon="launch"
          target="_blank"
          :href="shipmentData.tracking.shipmentTrackingUrl"
        >
          {{ shipmentData.tracking.shipmentTrackingNumber }}
        </trek-link>
      </dd>

      <dt class="cell small-6">{{ $t('text.deliveryMethod') }}</dt>
      <dd class="cell small-6">{{ shipmentData.shipmentMethod }}</dd>

      <dt class="cell small-6">{{ $t('test.shipDate') }}</dt>
      <dd class="cell small-6">{{ shipmentData.shipDate }}</dd>

      <dt class="cell small-6">{{ $t('text.shipToAddress') }}</dt>
      <dd class="cell small-6">
        <div v-show="shipmentData.shippingAddress.companyName">{{ shipmentData.shippingAddress.companyName }}</div>
        <div v-show="shipmentData.shippingAddress.line1">{{ shipmentData.shippingAddress.line1 }}</div>
        <div v-show="shipmentData.shippingAddress.line2">{{ shipmentData.shippingAddress.line2 }}</div>
        <div v-show="shipmentData.shippingAddress.line3">{{ shipmentData.shippingAddress.line3 }}</div>
        <div v-show="shipmentData.shippingAddress.line4">{{ shipmentData.shippingAddress.line4 }}</div>
        <div v-show="shipmentData.shippingAddress.town">{{ shipmentData.shippingAddress.town }}</div>
        ,
        <span v-show="shipmentData.shippingAddress.region.isocodeShort">
          {{ shipmentData.shippingAddress.region.isocodeShort }}
        </span>
        <div v-show="shipmentData.shippingAddress.postalCode">{{ shipmentData.shippingAddress.postalCode }}</div>
        <span v-show="shipmentData.shippingAddress.region.countryIso">{{ shipment.shipToCountry }}</span>
      </dd>

      <dt class="cell small-6">{{ $t('text.warehouse') }}</dt>
      <dd class="cell small-6">{{ shipmentData.dc }}</dd>
    </template>
    <template v-if="isPackingSlipVisible">
      <dt class="cell small-6">{{ $t('text.packingList') }}</dt>
      <dd class="cell small-6">
        <trek-link
          v-if="shipmentData.packingListURL"
          button
          text
          icon-right
          icon="insert_drive_file"
          :href="shipmentData.packingListURL"
        >
          {{ shipmentData.packingSlip }}
        </trek-link>
        <span v-else>{{ shipmentData.packingSlip }}</span>
      </dd>
    </template>
    <template v-if="isInvoiceVisible">
      <dt class="cell small-6">{{ $t('text.invoice') }}</dt>
      <dd class="cell small-6">
        <div v-for="invoice in shipmentData.invoices" :key="invoice.invoiceNumber">
          <trek-link
            v-if="hasInvoiceUrl(invoice)"
            button
            text
            icon-right
            icon="insert_drive_file"
            :href="invoice.invoiceUrl"
          >
            {{ invoice.invoiceNumber }}
          </trek-link>
          <span v-else>{{ invoice.invoiceNumber }}</span>
        </div>
      </dd>
    </template>
  </dl>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Array,
      required: true,
      default: () => [],
    },
    orderData: {
      type: Object,
      required: true,
      default: () => null,
    },
    name: {
      type: String,
      default: '',
    },
    isCreditMemo: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    shipmentData() {
      let result = {};
      const tabName = this.name;
      this.orderData.consignments.forEach((el) => {
        if (el.code == tabName || this.isCreditMemo) {
          result = el;
        }
      });
      return result;
    },
    isInvoiceVisible() {
      const IGNORE_ORDER_TYPE = 'ET';
      return this.shipmentData?.invoices?.length && this.orderData?.details?.orderType !== IGNORE_ORDER_TYPE;
    },
    isPackingSlipVisible() {
      return this.shipmentData.packingSlip && !this.isCreditMemo;
    },
  },
  methods: {
    hasInvoiceUrl(invoice) {
      return Boolean(invoice.invoiceUrl);
    },
  },
};
</script>
