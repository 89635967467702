<template>
  <tr class="table-row">
    <!-- Each Cell -->
    <template v-for="column in grid.headers">
      <td
        :key="`${row[rowId]}-${row[rowKey]}-${column.key}`"
        :class="[column.key, column.cssClasses]"
        class="b2b-grid__cell lg:table-cell"
      >
        <div class="b2b-grid__label" v-html="column.label"></div>

        <div class="b2b-grid__content" :qaid="`shipmentsInquiry.${column.key}-${rowId}`">
          <template v-if="column.key === 'hangTag'">
            <button @click="downloadHangTags">
              <pdl-icon
                class="cursor-pointer md:ml-2"
                :qaid="`shipmentsInquiry.downloadHangTags-${rowId}`"
                name="local_offer"
                size="18"
              />
            </button>
          </template>
          <template v-else-if="column.key === 'packingSlipNumber'">
            <div class="flex justify-start items-center">
              <trek-link
                qaid="virtual-packing-slip-url"
                underline
                :href="`${encodedContextPath}/my-account/review-shipments/virtual-packing-slip/${row.packingSlipNumber}`"
              >
                <span v-html="row[column.key]" />
              </trek-link>
              <trek-link v-if="row.packingSlipAvailable" :href="downloadPackingSlip">
                <pdl-icon
                  name="get_app"
                  class="cursor-pointer ml-1 text-secondary"
                  :qaid="`shipmentsInquiry.downloadPackingSlip-${rowId}`"
                  :label="$t('shipmentInquiry.B2B.packingList.download')"
                  size="18"
                />
              </trek-link>
            </div>
          </template>
          <template v-else-if="column.key === 'shipmentTrackingNumber'">
            <div class="flex justify-start items-center">
              <span v-html="row[column.key]" />
              <button @click="openTrackingLink">
                <pdl-icon
                  v-if="row.shipmentTrackingUrl"
                  name="launch"
                  class="cursor-pointer ml-1 text-secondary"
                  :qaid="`shipmentsInquiry.openTrackingLink-${rowId}`"
                  :label="$t('shipmentInquiry.B2B.trackinglink.open')"
                  size="18"
                />
              </button>
            </div>
          </template>
          <!-- All other cells -->
          <span v-else v-html="row[column.key]" />
        </div>
      </td>
    </template>
  </tr>
</template>

<script>
import {mapState} from 'vuex';
import TrekLink from '@/components/TrekLink';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {PdlIcon, TrekLink},

  inject: ['grid'],

  props: {
    row: {
      type: [Object, Array],
      default: () => [],
    },
    rowId: {
      type: [String, Number],
      default: '',
    },
    rowKey: {
      type: String,
      default: 'id',
    },
  },

  computed: {
    ...mapState('backend', ['encodedContextPath']),

    downloadPackingSlip() {
      return `${this.encodedContextPath}/my-account/packingslip-data/${this.row.packingSlipNumber}/${this.row.shipToNumber}`;
    },
  },

  methods: {
    downloadHangTags() {
      window.open(`${this.encodedContextPath}/hang-tags/${this.row.packingSlipNumber}?shipments=true`, '_blank');
    },

    openTrackingLink() {
      window.open(this.row.shipmentTrackingUrl, '_blank');
    },
  },
};
</script>
