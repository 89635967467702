<template>
  <tr class="b2b-grid__product-row">
    <td v-if="isBackorder && !isRestrictedCustomer" class="b2b-grid__cell lg:table-cell" qaid="sku-grid.sold">
      <div class="b2b-grid__label">{{ $t('text.sold') }}</div>
      <div class="b2b-grid__content">
        <toggle-switch
          v-if="item.isEligibleForProductPriority"
          :id="`${item.product.code}-sold-toggle`"
          :name="`${item.product.code}-sold-toggle`"
          :value="alreadySoldToConsumer"
          :sync="true"
          qaid="sku-grid-sold-toggle"
          :disabled="checkoutStep === 'confirmation'"
          @change="onSoldToggle"
        />
      </div>
    </td>

    <td v-if="isBackorder && !isRestrictedCustomer" class="b2b-grid__cell lg:table-cell" qaid="sku-grid-sold-qty">
      <div class="b2b-grid__label">{{ $t('text.checkout.soldQuantity') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: prioritizedQty}" />
      </div>
    </td>

    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid.qty">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.qty') }}</div>
      <div class="b2b-grid__content lg:flex-row lg:justify-start">
        <grid-text v-if="isBackorder" :data="{value: item.backorderedQty}" />
        <grid-text v-else :data="{value: item.nonBackorderedQty}" />
        <grid-sold-options
          v-if="checkoutStep !== 'confirmation'"
          ref="soldOptionRef"
          :item="item"
          :row-id="rowIndex"
          :inputbox-disabled="!!item.p1ConfigurationId"
          @tooltip-closed="updateBackOrderQty"
          @add-note="onNoteAdd"
        />
      </div>
    </td>

    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid.split">
      <pdl-icon v-if="item.isSplitWarehouse" name="call_split" size="18" />
    </td>

    <td v-if="isProjectOne" class="b2b-grid__cell lg:table-cell" qaid="sku-grid.sku">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.sku') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: item.product.code}" />
      </div>
    </td>

    <td v-else class="b2b-grid__cell lg:table-cell" qaid="sku-grid.sku">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.sku') }}</div>
      <div class="b2b-grid__content">
        <div class="flex items-center">
          <a
            :href="`${encodedContextPath}/my-account/orders?partNumber=${item.product.code}`"
            class="leading-none mr-1"
            qaid="sku-grid.orderHistory"
          >
            <pdl-icon name="history" :label="$t('orders')" />
          </a>
          <grid-text
            class="flex"
            :data="{
              value: item.product.code,
              tooltip: getTooltipText(item, false),
              link: encodedContextPath + '/p/' + item.product.code,
            }"
          ></grid-text>
        </div>
      </div>
    </td>

    <td v-if="!forPrint" class="b2b-grid__cell lg:table-cell" qaid="sku-grid.note">
      <grid-note
        ref="gridNote"
        :data="{
          value: 'note_add',
          editable: !isConfirmationStep,
          content: productNote,
          hasNotification: productHasNote,
        }"
        :item-id="item.sku"
        :row-id="rowIndex"
        :max-length="120"
        :placeholder-text="$t('checkout.B2B.sold.noteRequired')"
        @note-added="addLineNote"
      />
    </td>

    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid.item">
      <div class="b2b-grid__label">{{ $t('order.item') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: item.itemName}"></grid-text>
      </div>
    </td>

    <td v-if="!isProjectOne" class="b2b-grid__cell lg:table-cell" qaid="sku-grid.upc">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.upc') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: item.ean ? item.ean : item.upc}"></grid-text>
      </div>
    </td>

    <td v-if="isCustomWaterBottle" class="b2b-grid__cell lg:table-cell h-auto lg:text-right" qaid="sku-grid-custom-id">
      <div class="b2b-grid__label">{{ $t('checkout.custom.ID') }}</div>
      <div class="b2b-grid__content b2b-grid__content--right">
        <pdl-link qaid="cart-grid-custom-id-link" standalone :href="spectrumCustomizerUrl">
          <grid-text :data="{value: item.productRecipeID}" />
        </pdl-link>
      </div>
    </td>

    <td v-else-if="isProjectOne" class="b2b-grid__cell lg:table-cell h-auto lg:text-right" qaid="sku-grid-custom-id">
      <div class="b2b-grid__label">{{ $t('checkout.custom.ID') }}</div>
      <div class="b2b-grid__content b2b-grid__content--right">
        <pdl-link qaid="cart-grid-custom-id-link" standalone :href="projectOneSummaryPageUrl">
          <grid-text :data="{value: item.p1ConfigurationId}" />
        </pdl-link>
      </div>
    </td>

    <template v-else>
      <td
        v-if="!isConfirmationStep"
        class="b2b-grid__cell lg:table-cell text-left lg:text-right"
        qaid="sku-grid.allocated"
      >
        <div class="b2b-grid__label">{{ $t('text.allocated') }}</div>
        <div class="b2b-grid__content">
          <grid-text :data="{value: item.quantityAllocatedDI == 0 ? null : item.quantityAllocatedDI}"></grid-text>
        </div>
      </td>

      <td
        v-if="!isConfirmationStep && !isProjectOne"
        class="b2b-grid__cell lg:table-cell text-left lg:text-right"
        qaid="sku-grid.backordered"
      >
        <div class="b2b-grid__label">{{ $t('text.backordered') }}</div>
        <div class="b2b-grid__content b2b-grid__content--right">
          <pdl-link
            v-if="item.quantityBackorderedDI"
            qaid="checkout-grid-backordered-link"
            standalone
            xsmall
            :href="backorderAllocatedUrl"
          >
            <grid-text :data="{value: item.quantityBackorderedDI}" />
          </pdl-link>
        </div>
      </td>
    </template>

    <td
      v-if="!isConsumerFriendlyMode && !isProjectOne"
      class="b2b-grid__cell lg:table-cell text-left lg:text-right"
      qaid="sku-grid.order-price"
    >
      <div class="b2b-grid__label">{{ $t('order.price') }}</div>
      <div class="b2b-grid__content">
        <grid-price :data="{dealerCost: item.price?.dealerCost, discount: discount}"></grid-price>
      </div>
    </td>

    <td v-if="!isConsumerFriendlyMode" class="b2b-grid__cell lg:table-cell text-right" qaid="sku-grid.subtotal">
      <div class="b2b-grid__label">{{ $t('text.order.subtotal') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: item.formattedLineTotal}"></grid-text>
      </div>
    </td>
  </tr>
</template>

<script>
import {updatePrioritizationData} from '@/api/cart';
import {ShipmentsApi} from '@/api/shipments';
import {mapGetters, mapMutations, mapState} from 'vuex';
import GridNote from '@/components/grid/GridNote';
import GridText from '@/components/grid/GridText';
import GridPrice from '@/components/grid/GridPrice';
import GridSoldOptions from '@/components/grid/GridSoldOptions';
import {PdlLink} from '@pedal/pdl-link';
import ToggleSwitch from '@/components/ToggleSwitch';
import {CheckoutStep} from '../../constants/checkout';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {GridNote, GridText, GridPrice, PdlLink, ToggleSwitch, PdlIcon, GridSoldOptions},
  props: {
    warehouse: {
      type: String,
      default: '',
    },
    itemKey: {
      type: String,
      default: '',
    },
    isBackorder: {
      type: Boolean,
      default: false,
    },
    forPrint: {
      type: Boolean,
      default: false,
    },
    checkoutStep: {
      type: String,
      default: '',
    },
    partTypeKey: {
      type: String,
      default: '',
    },
    isCustomWaterBottle: {
      type: Boolean,
      default: false,
      required: false,
    },
    isProjectOne: {
      type: Boolean,
      default: false,
      required: false,
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapState('advancedOrdering', ['advancedOrderingMode']),
    ...mapState('backend', ['encodedContextPath', 'isConsumerFriendlyMode']),
    ...mapState('checkout', ['savedState']),
    ...mapGetters('backend', ['isRestrictedCustomer']),
    ...mapGetters('checkout', ['bikes', 'parts', 'customWaterBottles', 'projectOneBikes']),

    productNote: {
      get() {
        return this.item.lineNote;
      },
      set(value) {
        this.setAttributesByKey({value, sku: this.item.sku, partTypeKey: this.partTypeKey, key: 'lineNote'});
      },
    },

    prioritizedQty: {
      get() {
        return this.item.prioritizedQty;
      },
      set(value) {
        this.setAttributesByKey({value, sku: this.item.sku, partTypeKey: this.partTypeKey, key: 'prioritizedQty'});
      },
    },

    alreadySoldToConsumer: {
      get() {
        return this.item.alreadySoldToConsumer;
      },
      set(value) {
        this.setAttributesByKey({
          value,
          sku: this.item.sku,
          partTypeKey: this.partTypeKey,
          key: 'alreadySoldToConsumer',
        });
      },
    },

    item() {
      return this[this.partTypeKey]?.warehouses
        .find((warehouse) => warehouse.id === this.warehouse)
        ?.entries.find((item) => item.sku === this.itemKey);
    },

    productHasNote() {
      return this.productNote?.length > 0;
    },

    isConfirmationStep() {
      return this.checkoutStep === CheckoutStep.Confirmation;
    },

    discount() {
      if (this.item && this.item.price) {
        if (this.item.price.orderedPrice) {
          return this.item.price.orderedPrice;
        } else if (this.advancedOrderingMode && this.item.price.advancedPrice) {
          return this.item.price.advancedPrice;
        } else if (this.item.price.sale && Number(this.item.price.sale.replace(/[^0-9.-]+/g, '')) > 0) {
          return this.item.price.sale;
        }
      }

      return 0;
    },

    backorderAllocatedUrl() {
      const productType = this.partTypeKey === 'bikes' ? 'bike' : 'am';
      const redirectPath = `${this.encodedContextPath}/my-account/backorders-allocated-items?productType=${productType}&skuSearchTerm=${this.item.product.code}`;
      return redirectPath;
    },

    spectrumCustomizerUrl() {
      return this.item.product.code && this.item.product.productRecipeID
        ? `${this.encodedContextPath}/p/${this.item.sku}?hasSpectrumOpen=true&recipeId=${this.item.product.productRecipeID}`
        : null;
    },

    projectOneSummaryPageUrl() {
      return this.item.product.p1ConfigurationId
        ? `${this.encodedContextPath}/project-one-builder/#/${this.item.product.p1ConfigurationId}/summary`
        : null;
    },
  },

  methods: {
    ...mapMutations('checkout', ['setAttributesByKey', 'setSavedState']),

    addLineNote({content, dirtyContent}) {
      if (!content) {
        this.alreadySoldToConsumer = false;
        this.setSavedState(false);
        this.send({noteContent: content, qty: 0});
      }

      const payload = {
        sku: this.item.sku,
        lineNote: content,
        qty: this.item.backorderedQty,
        alreadySoldToConsumer: this.alreadySoldToConsumer,
        cartEntryPk: this.item.entryPK,
      };

      ShipmentsApi.updateCartEntries(payload)
        .then(() => {
          this.productNote = content;
        })
        .catch(() => {
          if (!dirtyContent) {
            this.alreadySoldToConsumer = true;
          }
        });
    },

    onNoteAdd() {
      this.setSavedState(true);
    },

    getTooltipText(item, onlyMFR) {
      const replacementProduct = item.replacementProduct ?? null;
      const mfr = item.mfr ?? null;
      const replacementProductText = this.$t('productArchive.viewCurrent');
      let toolTipData = {};
      if (mfr !== null) {
        toolTipData['MFR'] = mfr;
      }
      if (replacementProduct && !onlyMFR) {
        toolTipData['replacementProductText'] = replacementProductText;
        toolTipData['replacementProductUrl'] = replacementProduct.url;
      }
      return toolTipData;
    },

    onSoldToggle(event) {
      if (event?.value) {
        this.alreadySoldToConsumer = true;
        this.$refs?.soldOptionRef.openTooltip();
      } else {
        this.alreadySoldToConsumer = false;
        this.setSavedState(false);
        this.send({noteContent: this.productNote, qty: 0});
      }
    },

    updateBackOrderQty({noteContent, qty}) {
      if (!this.savedState) {
        this.alreadySoldToConsumer = false;
        return;
      }
      this.alreadySoldToConsumer = true;

      this.send({noteContent, qty});
    },

    send({noteContent, qty}) {
      updatePrioritizationData({
        cartEntryPk: this.item.entryPK,
        lineNote: noteContent ?? this.item.lineNote,
        prioritizedQty: qty,
        qty: this.item.backorderedQty,
        alreadySoldToConsumer: this.alreadySoldToConsumer,
      }).then(() => {
        this.productNote = noteContent;
        this.prioritizedQty = qty;
        this.setSavedState(false);
      });
    },
  },
};
</script>
