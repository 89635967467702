<template>
  <div>
    <p v-if="hasNotSelectedStore" class="form__global-error alert-icon text-emphasized has-delivery-error">
      {{ $t('checkout.multi.retailer.error') }}
    </p>
    <ul v-if="hasStores" :class="['form__retailer-options', 'retailerResults', hasNotSelectedStore ? 'errorDiv' : '']">
      <template>
        <li v-for="(store, index) in stores" :key="index">
          <dealer-list-item :store="store" :index="index" />
        </li>
      </template>
    </ul>
    <p v-else class="form__global-error alert-icon text-emphasized has-delivery-error">{{ $t('text.noRetailers') }}</p>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import DealerListItem from '@/components/checkout/b2c/dealers/DealerListItem';
import {useVuelidate} from '@vuelidate/core';

export default {
  components: {DealerListItem},
  inject: ['setImpossibleZipcode'],
  setup() {
    return {v$: useVuelidate()};
  },
  computed: {
    ...mapState('checkout', ['stores']),
    hasStores() {
      !this.stores?.length ? this.setImpossibleZipcode(true) : this.setImpossibleZipcode(false);
      return this.stores?.length > 0;
    },
    hasNotSelectedStore() {
      return !!this.v$.$errors.length;
    },
  },
};
</script>
