<script>
import sortBy from 'lodash/sortBy';
import {mapActions} from 'vuex';
import NotificationItem from '@/components/containers/notifications/NotificationItem';
import TrekLink from '@/components/TrekLink';
import TrekButton from '@/components/TrekButton';

export default {
  name: 'NotificationsDropdown',

  components: {NotificationItem, TrekLink, TrekButton},

  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
    textNew: {
      type: String,
      default: 'new',
    },
  },

  computed: {
    count() {
      return this.notifications !== null
        ? this.notifications.filter((notification) => !notification.readStatus).length
        : '0';
    },

    countText() {
      return ` (${this.count} ${this.textNew})`;
    },

    sorted() {
      return sortBy(this.notifications, (e) => new Date(e.dateReceived)).slice(0, 20);
    },
  },

  methods: {
    markAllRead() {
      return this.notifications.forEach((notification) => this.updateNotificationRead(notification.id));
    },

    markRead(notification) {
      notification.readStatus = true;
      return notification;
    },

    updateNotification(id) {
      // axios.put()
      this.updateNotificationRead(id);
    },

    ...mapActions('notifications', ['updateNotificationRead']),
  },
};
</script>
