<template>
  <div class="family-highlight">
    <div class="family-highlight__media flex self-start">
      <div class="item">
        <img :src="thankYouImage" alt="" />
      </div>
    </div>
    <div class="family-highlight__text self-stretch flex items-center justify-center md:w-1/2">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 py-2 lg:py-0">
          <header class="section-header section-header--lg mt-3">
            <div>
              <h1 class="heading" qaid="page-title">{{ getBaseProperty('registrationSubmitted') }}</h1>
            </div>
          </header>
          <p class="mb-3">{{ getBaseProperty('confirmationMessage') }}</p>
          <div class="buttons">
            <trek-link button primary :href="registerNewBikeUrl">
              <span v-html="getBaseProperty('registerNewBike')"></span>
            </trek-link>
            <trek-link button secondary :href="encodedContextPath">
              <span v-html="getBaseProperty('continueShopping')"></span>
            </trek-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import TrekLink from '@/components/TrekLink';

export default {
  components: {TrekLink},
  props: {
    baseProperties: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      thankYouImage:
        'https://media.trekbikes.com/image/upload/w_1024,h_768,f_auto,q_auto,c_fit/ProductRegistration_Thankyou_Highlight_BT18_Aeolus_XXX_Wheels',
    };
  },
  computed: {
    registerNewBikeUrl() {
      return `${this.encodedContextPath}/${this.b2b ? 'bike-registration' : 'productRegistration'}`;
    },
    ...mapState('backend', ['encodedContextPath']),
  },
};
</script>
