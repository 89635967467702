'use strict';

import toggler from '../modules/Toggler';
import debounce from 'lodash/debounce';

export default function (element) {
  const isSearch = $('.page-search').length;
  var seq = 0;
  const isArticlesPage = $('body').hasClass('template-pages-articles-articlesAndNewsPage');
  let resetPage = false;
  let activeTab = '.tabs-panel.is-active';

  function fireRequest(form, lastSeq) {
    window.loadingIndicator.show();

    var q = form.find('input[name=q]').val();
    if ($('#sortOptions1').val()) {
      q = form.find('input[name=text]').val() + ':' + $('#sortOptions1').val();

      // needs to work on both pages with tabs like search results
      // and pages without tabs like the PLP
      if (isSearch) {
        $(activeTab + ' .plp-sidebar')
          .find(':checked')
          .each(function () {
            q = q + ':' + $(this).data('q');
          });
      } else {
        $('.plp-sidebar')
          .find(':checked')
          .each(function () {
            q = q + ':' + $(this).data('q');
          });
      }

      form.find('input[name=q]').val(q);
    }

    // Setting the page and sort attributes before filters ajax call
    if (ACC && ACC.paginationsort) {
      if (ACC.paginationsort.currentTab === 'articles') {
        form.find('input[name=pageSize]').val(ACC.paginationsort.articlesPageSize);
        form.find('input[name=page]').val(ACC.paginationsort.articlesCurrentPage);
        form.find('input[name=sort]').val(ACC.paginationsort.articlesSort);
      } else if (ACC.paginationsort.currentTab === 'other-content') {
        form.find('input[name=pageSize]').val(ACC.paginationsort.otherContentPageSize);
        form.find('input[name=page]').val(ACC.paginationsort.otherContentCurrentPage);
        form.find('input[name=sort]').val(ACC.paginationsort.otherContentSort);
      } else if (isArticlesPage) {
        form.find('input[name=pageSize]').val(getParameterByName('pageSize') || 24);
        // TBC-10104: page # should be set to zero when a filter is changed
        form.find('input[name=page]').val(0);
        form.find('input[name=sort]').val(getParameterByName('sort') || 'newest');
      }
    }

    $.ajax({
      url: $(form).attr('action'),
      type: $(form).attr('method'),
      data: $(form).serialize(),
      dataType: 'json',

      success: function (data) {
        // Resetting current page to zero if there are no results and we're not at zero already
        if (ACC && ACC.paginationsort) {
          if (ACC.paginationsort.currentTab === 'articles') {
            if (ACC.paginationsort.articlesCurrentPage > 0 && data && data.resultcount === 0) {
              ACC.paginationsort.articlesCurrentPage = 0;
              fireRequest(form, lastSeq);
              return;
            }
          } else if (ACC.paginationsort.currentTab === 'other-content') {
            if (ACC.paginationsort.otherContentCurrentPage > 0 && data && data.resultcount === 0) {
              ACC.paginationsort.otherContentCurrentPage = 0;

              fireRequest(form, lastSeq);
              return;
            }
          }
        }

        // Only update if a subsequent request has NOT been issued
        if (seq == lastSeq) {
          let $container = null;

          data.html = data.html.replace(/<c:set([a-zA-Z0-9"'/\\?% =]*)\/>/gi, '');
          let processedHTML = processHTML($(data.html));
          if (isSearch) {
            if ($(activeTab + ' [data-articles-list]').length) {
              $container = $(activeTab + ' [data-articles-list] > section > div');
            } else {
              $container = $(activeTab + ' > section');
            }
            $container.html(processedHTML).foundation();
          } else {
            // HTML needs to be processed to trigger and remove script2 tags when
            // the html is being loaded dynamically. See processHTML for full explanation
            $container = $('[data-product-list]');
            if (!$container.length) {
              $container = $('[data-article-list]');
            }

            $container.html(processedHTML).foundation();
          }

          $container.find('.product-list').attr('data-vue-dynamic-content', true);

          const ev = new CustomEvent('init-vue-dynamic-content');
          window.dispatchEvent(ev);

          $('.facetNavRefinements').foundation();
          keepFacetCatsWithSelectionsOpen();

          if ($(activeTab).length) {
            $(activeTab + ' .search-results__result-count').html(element.find('#results-count--product').attr('value'));
          } else {
            $('.search-results__result-count').html(element.find('#results-count--product').attr('value'));
          }

          //resize grid layout
          element.trigger('resize');
          // rebind all the toggles in the facet nav
          element.find('[data-toggler]').each(function (i, t) {
            toggler($(t));
          });

          ACC.paginationsort.bindAll();
          showHideFiltersList();
          $('[data-video]').magnificPopup({
            disableOn: 300,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false,
          });

          closeDefaultFacetCatsForSmallMedium();
          if (localStorage.reopenFacet === 'true') {
            $('.js-off-canvas-facet').addClass('notransition'); // there should be no transition animation if the facet was already open
            $('.js-off-canvas-facet').addClass('is-open');
            $('.js-off-canvas-facet').removeClass('is-closed');
            $('.js-off-canvas-facet').on({
              'down.zf.accordion up.zf.accordion': debounce(function () {
                if (localStorage.facetReloadScrollPosition) {
                  var elOffset = $(
                    "[name='" +
                      localStorage.facetReloadScrollPosition.replace(/[!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~]/g, '\\$&') +
                      "']"
                  ).offset();

                  var elOffCanvasFacetOffset = $('.js-off-canvas-facet').offset();

                  if (elOffset && elOffCanvasFacetOffset) {
                    var locator =
                      elOffset.top -
                      elOffCanvasFacetOffset.top +
                      $('.js-off-canvas-facet').scrollTop() -
                      0.5 * $('.js-off-canvas-facet').height();
                  }
                }

                if (localStorage.facets.indexOf(true) > 0) {
                  // No need to adjust screen position if there's nothing selected
                  $('.js-off-canvas-facet').scrollTop(locator);
                }
              }, 500),
            });

            setTimeout(function () {
              // disable expand listener and enable transition after 1 sec - when facet cats have expanded
              $('.js-off-canvas-facet').off('down.zf.accordion up.zf.accordion');
              $('.js-off-canvas-facet').removeClass('notransition');
            }, 1000);

            localStorage.reopenFacet = false;
          }

          window.loadingIndicator.hide();
        }
      },
    });
  }

  /* This processing is needed because of the way we load script tags with Vue:

    Vue doesn't like any <script> tags within its templates. To get around this limitation
    we implemented the <script2> library. It essentially takes any <script2> tag within the
    Vue template and places it outside of the template so it runs correctly. The issue is that
    script2 only runs correctly on initial page load. If the html/script2 is being loaded dynamically,
    as is the case with PLP/facets, then the script2 tag doesn't work correctly and it just gets inserted
    into the HTML as any other html tag. So in order to prevent the js to show as plain text on the page
    and, more importantly, for the script to run correctly when a new facet is selected, we have to
    mimic script2's functionality.

    This method:
      1. Looks for all divs with a class of 'js-script2-reload' and iterates through them
      2. Creates a new script tag programmatically and inserts the script inside the div found in #1 as its script body.
      3. Inserts the new script at the head so it gets executed.
      4. Removes all 'js-script2-reload' divs from the html

    Once the PLPs are converted to Vue components this should no longer be needed.
  */
  function processHTML(html) {
    html.find('.js-script2-reload').each(function (index, element) {
      var scriptContent = $(element).find('script2').html();
      var scriptTag = document.createElement('script');
      scriptTag.innerText = scriptContent;
      var head = document.getElementsByTagName('head')[0];
      head.appendChild(scriptTag);
    });

    html.find('.js-script2-reload').remove();
    return html;
  }

  function closeDefaultFacetCatsForSmallMedium() {
    if ($(window).width() < 1024) {
      // close the open-by-default facet cats on small/medium screens
      $('.pdl-collapse-item.open-desktop-only .pdl-collapse-item__header').each(function () {
        $(this).click();
      });
    }
  }

  function keepFacetCatsWithSelectionsOpen() {
    $('.pdl-collapse-item.facet-selected:not(.default-open) .pdl-collapse-item__header').each(function () {
      $(this).click();
    });
  }

  function parentKey() {
    let elementLocator = $(activeTab).find('[data-facetParent]').first()[0];

    if (isArticlesPage) {
      return 'articles';
    }

    return isSearch
      ? // elementLocator being undefined means no results were returned
        elementLocator
        ? elementLocator.attributes['data-facetParent'].value
        : null
      : 'product';
  }

  function showHideFiltersList() {
    let activeTab = isSearch ? '.tabs-panel.is-active' : $('[data-product-list]');
    var selectedFacetCount = 0;
    if (typeof activeTab === 'object' && !activeTab.length) {
      activeTab = $('#off-canvas-facet-articles');
      selectedFacetCount = $(activeTab).find('.label-selected').length;
    } else {
      selectedFacetCount = $(activeTab).find('.facet-group__wrap').find('input:checked').length;
    }
    if (selectedFacetCount > 0) {
      $(activeTab).find('#facetsCount').innerText = '(' + selectedFacetCount + ')';
      $(activeTab).find('#facetsCountForCanvas').innerText = '(' + selectedFacetCount + ')';
      $(activeTab).find('.js-filtersList').removeClass('hide');
      $(activeTab).find('.js-off-canvas-done-btn').removeClass('hide');
      $(activeTab).find('.js-off-canvas-clear-all-btn').removeClass('hide');
    } else {
      $(activeTab).find('.js-filtersList').addClass('hide');
      $(activeTab).find('.js-off-canvas-done-btn').addClass('hide');
      $(activeTab).find('.js-off-canvas-clear-all-btn').addClass('hide');
    }

    $('.js-facet-filter-list li').click(function () {
      var checkboxId = $(this)
        .attr('id')
        .replace('filter-', '')
        .concat('-' + parentKey());

      $('[id="' + checkboxId.replace(/"/g, '\\"') + '"]')
        .filter(function () {
          return this.id === checkboxId;
        })
        .first()
        .click();
    });
  }

  function getFacets() {
    return JSON.parse(localStorage.facets);
  }

  function saveFacets(facets) {
    localStorage.facets = JSON.stringify(facets);
  }

  function saveFacetCats(facetcats) {
    localStorage.facetcats = JSON.stringify(facetcats);
  }

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  $(document).on('click', '[data-facet-clear]', function (event) {
    event.preventDefault();

    localStorage.reopenFacet = true;

    // uncheck all in the same group
    var groupWrap = $(this).closest('.facet-group');

    //analytics
    ACC.track.checkFacetClear(groupWrap);

    var facets = getFacets();
    groupWrap.find('input:checked').each(function () {
      var id = $(this).attr('id');
      if (facets[parentKey()] && id in facets[parentKey()]) {
        facets[parentKey()][id] = false;
      }
    });

    saveFacets(facets);

    localStorage.facetReloadScrollPosition = $(this).closest('.pdl-collapse-item').attr('name');

    groupWrap.find('.label-selected').removeClass('label-selected');

    groupWrap.find(':checked').prop('checked', false);
    seq = seq + 1;

    fireRequest(groupWrap.find('form').first(), seq);
  });

  $(document).on('click', '[data-facet-clear-all]', function (event) {
    let facetSidebar = isSearch ? activeTab : '.plp-sidebar';
    let facets = getFacets();

    event.preventDefault();

    localStorage.reopenFacet = true;

    //analytics
    ACC.track.checkFacetClearAll();

    // find all the selected facets in the sidebar. and remove the selected styles
    $(facetSidebar).find('.label-selected').removeClass('label-selected');
    // find all the selected facets checkboxes and actually uncheck them
    $(facetSidebar).find(':checked').prop('checked', false);

    seq = seq + 1;
    facets[parentKey()] = {};
    saveFacets(facets);
    fireRequest($(facetSidebar).find('.facet-group form').first(), seq);
  });

  $(document).on('click', '[data-facet-show-all]', function (event) {
    event.preventDefault();

    //analytics
    ACC.track.checkFacetShowAll($(this));

    var groupWrap = $(this).closest('.facet-group__wrap');
    groupWrap.find('.facet-group__list-item').css({
      display: 'list-item',
    });

    $(this).hide();
  });

  // Handle click on checkmark
  $(document)
    .off('click', '.facet-group__decorator-refinement')
    .on('click', '.facet-group__decorator-refinement', function () {
      const $checkBox = $(this).prev('.facet-group__check');
      if (!$checkBox || !$checkBox.length) {
        return;
      }

      $checkBox.prop('checked', !$checkBox.prop('checked')).change();
    });

  $(document)
    .off('change', '.facet-group__check')
    .on('change', '.facet-group__check', function () {
      let facets = getFacets();

      localStorage.reopenFacet = true;

      seq = seq + 1;
      facets[parentKey()][$(this).attr('id')] = $(this).prop('checked');
      saveFacets(facets);

      var itemWrap = $(this).closest('form');
      if ($(this).prop('checked')) {
        itemWrap.addClass('label-selected');
        localStorage.facetReloadScrollPosition = $(this).closest('li').attr('name');
      } else {
        itemWrap.removeClass('label-selected');
        localStorage.facetReloadScrollPosition = $(this).closest('.pdl-collapse-item').attr('name');
      }

      //analytics
      ACC.track.checkFacet($(this));

      if (isArticlesPage && resetPage) {
        window.location = `${window.location.origin}${window.location.pathname}?pageSize=${
          getParameterByName('pageSize') || 24
        }&sort=${getParameterByName('sort') || 'newest'}`;
      } else {
        fireRequest($(this).closest('form'), seq);
      }
    });

  $(document).ready(function () {
    window.loadingIndicator.hide(); // get rid of loading animation in case it wasn't cleared
    $('.js-off-canvas-facet').scrollTop(0);

    localStorage.reopenFacet = false;
    showHideFiltersList();

    closeDefaultFacetCatsForSmallMedium();

    var currPathName = window.location.pathname;
    var prevPathName = localStorage.pathname;

    if (currPathName != prevPathName) {
      localStorage.pathname = currPathName;
      //clear all facets when changing URLs.
      //eg. when you change category from road to mountain etc.
      saveFacets({product: {}, archive: {}, articles: {}});
      saveFacetCats({});
      localStorage.facetReloadScrollPosition = {};
    } else {
      var facets = getFacets();

      // This checked-remover line has been written with the assumption that handling of facet data being pre-selected was only
      // happening on front-end and not backend; however BBD-4660 does exactly that. Therefore this line was causing conflicts.
      // $(':checked').prop('checked', false);

      if (isArticlesPage) {
        resetPage = false;
      }

      for (var tab in facets) {
        if (tab === parentKey()) {
          for (var facet in facets[tab]) {
            //check if the facet's value is true
            const $facetElement = $("[id='" + facet.replace(/[!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~]/g, '\\$&') + "']");
            if ($facetElement.length > 0 && facets[tab][facet] && !$facetElement.is(':checked')) {
              $facetElement.click();
            }
          }
        }
      }
    }
  });
}
