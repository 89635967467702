<template>
  <div class="mb-5">
    <form-group
      v-model="autofillInput"
      name="address-autofill"
      :help-text="$t('checkout.multi.deliveryAddress.findAddress')"
      :placeholder="$t('checkout.multi.deliveryAddress.startTyping')"
      qaid="address-autocomplete-input"
      :error="getErrorMessage('autofillInput')"
      required
      show-error-messages
      force-display-error
      @input="handleInput"
      @blur="hideDropdown"
      @focus="showDropdown"
    >
      <template #controls>
        <address-autofill-list
          v-show="hasDropdownOpen"
          :suggestion-results="suggestionResults"
          @suggestion-selected="handleAddressSelected"
        />
      </template>
    </form-group>
    <pdl-button button text small underline qaid="address-autocomplete-manual-input" @click="handleAddressSelected">
      {{ $t('checkout.multi.deliveryAddress.enterManually') }}
    </pdl-button>
  </div>
</template>
<script>
import FormGroup from '@/components/FormGroup';
import {mapState} from 'vuex';
import debounce from 'lodash/debounce';
import AddressAutofillList from '@/components/checkout/fields/address-autofill/AddressAutofillList';
import {AddressAutofillApi} from '@/api/checkout/address-autofill';
import {PdlButton} from '@pedal/pdl-button';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
const INPUT_DELAY = 150;
export default {
  components: {FormGroup, AddressAutofillList, PdlButton},
  mixins: [TrekValidationMixin],
  props: {
    onAddressSelected: {
      type: Function,
      required: true,
    },
  },
  validations() {
    return {
      autofillInput: {
        required: this.trekValidators.required('checkout.multi.deliveryAddress.findAddress'),
      },
    };
  },
  data() {
    return {
      autofillInput: '',
      suggestionResults: [],
      hasSuggestionDropdownOpen: false,
    };
  },
  computed: {
    ...mapState('backend', ['siteUid']),
    hasDropdownOpen() {
      return this.suggestionResults?.length && this.hasSuggestionDropdownOpen;
    },
  },
  methods: {
    handleAddressSelected(suggestion) {
      this.suggestionResults = [];
      this.onAddressSelected(suggestion);
    },
    hideDropdown() {
      const DELAY_FOR_SUGGESTION_CLICK = 200;
      setTimeout(() => {
        this.hasSuggestionDropdownOpen = false;
      }, DELAY_FOR_SUGGESTION_CLICK);
    },
    showDropdown() {
      this.hasSuggestionDropdownOpen = true;
    },
    handleInput: debounce(async function (value) {
      const options = {
        query: value,
      };
      try {
        const suggestions = (await AddressAutofillApi.getAddressSuggestions(options))?.data?.suggestions;
        this.suggestionResults = suggestions;
      } catch (error) {
        this.suggestionResults = [];
        console.error(error);
      }
    }, INPUT_DELAY),
  },
};
</script>
