<template>
  <div class="sku-test-trek__cta mb-1 mt-1">
    <trek-link block button secondary :href="url" :target="target" :class="cssClass">
      <span v-html="linkName"></span>
    </trek-link>
  </div>
</template>
<script>
import get from 'lodash/get';
import TrekLink from '@/components/TrekLink';

export default {
  name: 'PdpBuyingZoneCmsLink',
  components: {TrekLink},
  props: {
    options: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    linkName() {
      return get(this.options, 'linkName');
    },
    cssClass() {
      if (!this.options) {
        return [];
      }

      let cssClass = [];
      if (this.options.trekLinkStyle) {
        cssClass.push(this.options.trekLinkStyle);
      }

      if (this.options.cssClass) {
        cssClass.push(this.options.cssClass);
      }

      return cssClass;
    },
    url() {
      return get(this.options, 'url');
    },
    target() {
      return get(this.options, 'target') === 'SAMEWINDOW' ? '' : '_blank';
    },
  },
};
</script>
