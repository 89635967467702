<template>
  <pdl-dialog
    :visible="isDialogVisible"
    :append-to-body="true"
    :width="breakpointConfig.width"
    :top="breakpointConfig.top"
    :fullscreen="breakpointConfig.fullscreen"
    :z-index="160000011"
    :close-dialog-tool-tip="$t('popup.close')"
    qaid="size-dialog"
    @close="$emit('close')"
  >
    <pdl-tabs alignment="center" :value="initialTab">
      <pdl-tab-pane v-if="sizeChartData" name="size-chart">
        <h3 slot="label" class="heading heading--md m-0">{{ $t('product.modal.size.sizeChart') }}</h3>
        <size-dialog-chart-pane-content :size-chart-data="sizeChartData" />
      </pdl-tab-pane>
      <pdl-tab-pane v-if="isSizeFinderVisible" name="size-finder">
        <h3 slot="label" class="heading heading--md m-0">{{ $t('product.modal.size.sizeFinder') }}</h3>
        <size-dialog-finder-pane-content :size-link-guide="sizeLinkGuide" />
      </pdl-tab-pane>
    </pdl-tabs>
  </pdl-dialog>
</template>

<script>
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import {mapState, mapGetters} from 'vuex';
import SizeDialogChartPaneContent from '@/components/dialog/size/SizeDialogChartPaneContent';
import SizeDialogFinderPaneContent from '@/components/dialog/size/SizeDialogFinderPaneContent';

export default {
  components: {
    PdlDialog,
    PdlTabs,
    SizeDialogChartPaneContent,
    SizeDialogFinderPaneContent,
    PdlTabPane,
  },
  props: {
    sizeLinkGuide: {
      type: String,
      default: undefined,
    },
    isDialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('pdp', ['sizeChartData']),
    ...mapState('viewSize', ['viewBreakpoint']),
    ...mapGetters('viewSize', ['viewBreakpointMoreThan']),
    breakpointConfig() {
      return this.viewBreakpointMoreThan('small')
        ? {width: '75vw', top: '15vh', fullscreen: false}
        : {width: '100vw', top: '0vh', fullscreen: true};
    },
    isSizeFinderVisible() {
      return this.sizeLinkGuide === 'size-finder';
    },
    initialTab() {
      if (this.sizeChartData) {
        return 'size-chart';
      } else if (this.isSizeFinderVisible) {
        return 'size-finder';
      }
      return undefined;
    },
  },
};
</script>
