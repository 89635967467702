'use strict';

export default function (element) {
  var $win = $(window);

  var slidesContainer = element.find('[data-hero-carousel-slides]');
  var slides = slidesContainer.children();
  var slidesTotal = slides.length;
  var controls = element.find('[data-carousel-controls]').find('li');
  var slidesWidth; // varies on resize

  var index = 0;
  var breakPoint = 768; // behaviour changes here, halfway between medium
  // and large
  var offset = 0; // Amount of the first bit of content we want to see in
  // the viewport

  // Margin is the distance between carousel and first bit of content
  // Overhang is the amount content box hangs off the bottom of the slides
  // Min is the minimum height of the slide component
  var settings = {
    small: {
      margin: 30,
      overhang: getOverhang(65),
      min: 530,
    },
    large: {
      margin: 130,
      overhang: 43,
      min: 600,
    },
  };

  slidesContainer.on('transitionend', function () {
    updateControls();
  });

  element.find('[data-carousel-next]').on('click', function (event) {
    event.preventDefault();
    index = index < slidesTotal - 1 ? index + 1 : 0;
    updatePosition(true);
  });

  element.find('[data-carousel-prev]').on('click', function (event) {
    event.preventDefault();
    index = index > 0 ? index - 1 : slidesTotal - 1;
    updatePosition(true);
  });

  controls.on('click', function (event) {
    event.preventDefault();
    index = $(this).index();
    updatePosition(true);
  });

  $(window).on('resize', function () {
    updateDimensions();
  });

  updateControls();
  updateDimensions();

  function updatePosition(animate) {
    var e = slidesContainer[0];
    var position = index * slidesWidth * -1;

    e.style['transition-duration'] = (animate ? 0.5 : 0) + 's';
    e.style['-webkit-transform'] = 'translate3d(' + position + 'px, 0px, 0px)';
    e.style['-moz-transform'] = 'translate3d(' + position + 'px, 0px, 0px)';
    e.style['-ms-transform'] = 'translate3d(' + position + 'px, 0px, 0px)';
    e.style['-o-transform'] = 'translate3d(' + position + 'px, 0px, 0px)';
    e.style.transform = 'translate3d(' + position + 'px, 0px, 0px)';
  }

  function updateControls() {
    controls.each(function () {
      var cl = 'hero-carousel-nav__link--selected';
      var item = $(this);
      item.find('a').removeClass(cl);
      if (item.index() === Math.abs(index)) {
        item.find('a').addClass(cl);
      }
    });
  }

  function updateDimensions() {
    var w = $win.innerWidth();
    var h = $win.innerHeight();

    var size = w < breakPoint ? 'small' : 'large';

    var overhang = $.isFunction(settings[size].overhang)
      ? settings[size].overhang(element, w, h)
      : settings[size].overhang;
    var margin = settings[size].margin;
    var min = settings[size].min;

    h = h - margin - offset;
    h = h < min ? min : h;

    slidesWidth = w;
    element.height(h);

    slidesContainer.width(slidesWidth * slidesTotal).height(h - overhang);
    slides.width(slidesWidth).height(h - overhang);

    updatePosition(false);
  }

  function getOverhang(modifier) {
    return function (el) {
      var decorator = el.find('[data-decorator]'),
        dOffset = parseInt(decorator.css('bottom'));

      return dOffset < 0 ? dOffset * -1 + modifier : 0;
    };
  }
}
