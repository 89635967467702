<template>
  <div v-if="tableData.items">
    <h2 v-if="displayName" class="text-center heading heading--md">{{ displayName }}</h2>
    <div class="size-table">
      <trek-table :table-data="tableData" show-head has-row-headers :variants="tableVariants"></trek-table>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import TrekTable from '@/components/table/TrekTable';

export default {
  components: {TrekTable},
  props: {
    rowHeaderFallback: {
      type: String,
      default: '',
    },
    productId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: {},
      tableVariants: ['hstripe', 'borderless', 'centered', 'small', 'sticky-headers'],
    };
  },
  computed: {
    displayName() {
      return this.sizeChartData?.displayName;
    },
    ...mapState('pdp', ['sizeChartData']),
  },
  async mounted() {
    //if size chart data is already available (for example if there is another table on the page)
    //use the existing table data.
    if (this.sizeChartData) {
      this.tableData = this.convertData(this.sizeChartData);
      return;
    }
    if (!this.productId) return;
    const status = await this.fetchSizeChartData(this.productId);
    if (!status) return;
    this.tableData = this.convertData(this.sizeChartData);
  },
  methods: {
    ...mapActions('pdp', ['fetchSizeChartData']),
    convertData(data) {
      const converted = {
        heads: {
          cols: [],
        },
        items: {},
        qaid: 'size-table',
      };

      const rows = data?.sizeChartGroups || [];

      if (!data || !data.sizeChartGroups || Object.keys(data.sizeChartGroups).length === 0) {
        console.warn('Product size chart information malformed.');
        return converted;
      }
      // Set up first column header for the rows header label.
      // rowsHeader is TEMP name for this.
      const columnHeaders = [data.rowsHeader || this.rowHeaderFallback];
      // Create an initial row UID.
      let rowId = 100;

      rows.forEach((value) => {
        const row = [value.size];
        if (columnHeaders.length === 1) {
          value.sizeChartGroups.forEach((cellInfo) => {
            columnHeaders.push(cellInfo.sizeChartGroupName || '');
          });
        }

        value.sizeChartGroups.forEach((cellInfo) => {
          row.push(
            this.formatCellInfo(cellInfo, {
              convertedSizes: data.convertedSizeChartFlag,
            })
          );
        });

        converted.items[rowId] = row;
        // Bump the row UID/key.
        rowId++;
      });

      converted.heads.cols = columnHeaders;

      return converted;
    },
    formatCellInfo(info, options = {}) {
      if (!options.convertedSizes) {
        return this.addCellWrapper(`${info.sizeChartMinCm}${info.sizeChartMaxCm ? ' - ' + info.sizeChartMaxCm : ''}`);
      }

      const baseRange = this.addCellWrapper(
        `${info.sizeChartMinCm}${info.sizeChartMaxCm ? ' - ' + info.sizeChartMaxCm : ''} cm`
      );
      // Extra conditional check to make sure we have any formatted size.
      const formattedRange = info.formattedSizeChartMin
        ? this.addCellWrapper(
            `${info.formattedSizeChartMin}${info.formattedSizeChartMax ? ' - ' + info.formattedSizeChartMax : ''}`
          )
        : '';

      return baseRange + formattedRange;
    },
    addCellWrapper(string) {
      const cellTextClasses = 'm-0';

      return `
        <p class="${cellTextClasses}">${string}</p>
      `;
    },
  },
};
</script>

<style lang="scss" scoped>
.size-table {
  width: 100%;
  overflow: auto;
  // setting a max-height to ensure the sticky headers work since the size table isnt within the main scrolling content
  max-height: 60vh;
}

::v-deep .trek-table {
  width: auto;
  min-width: 100%;
  max-width: none;
  table-layout: fixed;
}

::v-deep th,
::v-deep td {
  min-width: 6.25rem;
}
</style>
