<template>
  <div class="grid-container text-base">
    <!-- Header -->
    <pdl-callout v-show="b2bUnitShippingDays" qaid="b2b-unit-shipping-days">
      <template #content>
        {{ $t('checkout.B2B.shippingDays', [b2bUnitShippingDays]) }}
      </template>
    </pdl-callout>
    <pdl-section-header :is-dividing="true" size="xl" class="md:block lg:flex">
      <template slot="content">
        <pdl-heading :level="1" qaid="allocated-backorder-heading">
          {{ $t('backordersAllocated.B2B.heading') }}
        </pdl-heading>
      </template>
      <template slot="actions">
        <div class="buttons buttons--right-for-lg md:inline-flex lg:flex">
          <trek-link
            v-show="!isEveryEntryAllowedToShip"
            button
            primary
            small
            qaid="manage-allocated-items-top-link"
            :href="`${encodedContextPath}/checkout/`"
          >
            <span>{{ $t('text.manageAllocatedItems') }}</span>
          </trek-link>
        </div>
      </template>
    </pdl-section-header>
    <p class="mt-3" qaid="allocated-backorder-description">{{ $t('backordersAllocated.B2B.description') }}</p>
    <div v-show="!isTabETA" class="lg:flex mt-6">
      <div class="lg:w-2/3">
        <allocated-backorder-history-filters
          ref="filters"
          :redirect-filter-sku="redirectSku"
          @changed="onFilterChanged"
        />
      </div>
      <div class="hidden md:flex lg:justify-center md:mt-4 lg:mt-0 lg:w-1/3">
        <allocated-backorder-history-reports
          :current-tab="selectedProductTypeOrTab"
          :filters="filters"
          :eta-tab="isTabETA"
        />
      </div>
    </div>

    <div>
      <pdl-loading page :is-loading="loading">
        <!-- Orders Tabs -->
        <section class="my-5">
          <pdl-tabs ref="tabs" :value="selectedProductTypeOrTab" @input="changeTab">
            <pdl-tab-pane
              v-for="productType in productTypes"
              :key="productType"
              :name="productType"
              :label="tabLabelMap[productType]"
              qaid="allocated-backorder-bikes-tab"
            >
              <pdl-callout>
                <template #content>
                  <strong
                    :class="['font-bold', {['hidden']: filters[productType].hideUnallocated}]"
                    :qaid="`${productType}-backordered-item-count-label`"
                  >
                    {{ $t('text.backordered') }}:
                  </strong>
                  <span
                    v-if="subTotals"
                    :class="['ml-25', {['hidden']: filters[productType].hideUnallocated}]"
                    :qaid="`${productType}-backordered-item-count`"
                  >
                    {{ subTotals.BACKORDERED.quantity }}
                  </span>
                  <span
                    v-if="!isConsumerFriendlyMode && !filters[productType].hideUnallocated && subTotals"
                    :qaid="`${productType}-backordered-item-price-total`"
                  >
                    {{ `(${subTotals.BACKORDERED.prices.totalPrice.formattedValue})` }}
                  </span>
                  <span :class="['mx-1', {['hidden']: filters[productType].hideUnallocated}]" v-text="'|'" />
                  <strong :qaid="`${productType}-allocated-item-count-label`" class="font-bold">
                    {{ $t('text.allocated') }}:
                  </strong>
                  <span v-if="subTotals" :qaid="`${productType}-allocated-item-count`" class="ml-25">
                    {{ subTotals.ALLOCATED.quantity }}
                  </span>
                  <span v-if="!isConsumerFriendlyMode && subTotals" :qaid="`${productType}-allocated-item-price-total`">
                    {{ `(${subTotals.ALLOCATED.prices.totalPrice.formattedValue})` }}
                  </span>
                </template>
              </pdl-callout>
              <allocated-backorder-history-grid
                :grid-data="entries"
                :sort-keys="productType.sortCodes"
                @update-sort-type="sortEntries"
              />
            </pdl-tab-pane>
            <pdl-tab-pane
              v-for="productType in productTypes"
              :key="`${productType}-ETA`"
              :name="`${productType}-ETA`"
              :label="tabETALabelMap[productType]"
              :qaid="`allocated-backorder-tab-eta-${productType}`"
            >
              <allocated-backorder-history-eta-grid
                :items="ETA[productType] || []"
                :no-item="ETAMessage[productType]"
                :qaid="`allocated-backorder-grid-eta-${productType}`"
                @update-data-list="fetchETA(productType)"
              />
            </pdl-tab-pane>
          </pdl-tabs>
          <div class="flex md:hidden justify-center">
            <allocated-backorder-history-reports
              v-show="!isTabETA"
              :current-tab="selectedProductTypeOrTab"
              :filters="filters"
            />
          </div>
        </section>
      </pdl-loading>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {PdlTabPane, PdlTabs} from '@pedal/pdl-tabs';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlLoading} from '@pedal/pdl-loading';
import TrekLink from '@/components/TrekLink';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import AllocatedBackorderHistoryGrid from '@/components/containers/my-account/AllocatedBackorderHistoryGrid';
import AllocatedBackorderHistoryFilters from '@/components/containers/my-account/AllocatedBackorderHistoryFilters';
import AllocatedBackorderHistoryReports from '@/components/containers/my-account/AllocatedBackorderHistoryReports';
import AllocatedBackorderHistoryEtaGrid from '@/components/containers/my-account/AllocatedBackorderHistoryEtaGrid';
import {allocatedBackorderTabNamesETA} from '@/constants/allocated-backorder';
import {ProductType} from '@/constants/product';
import {ProductTypeConverter} from '@/constants/product-type-converter';
import {Url} from '@/utils/url';

export default {
  components: {
    PdlTabs,
    PdlTabPane,
    PdlCallout,
    PdlLoading,
    TrekLink,
    PdlSectionHeader,
    PdlHeading,
    AllocatedBackorderHistoryGrid,
    AllocatedBackorderHistoryFilters,
    AllocatedBackorderHistoryReports,
    AllocatedBackorderHistoryEtaGrid,
  },
  provide() {
    return {
      container: this,
    };
  },
  props: {
    redirectSku: {
      type: String,
      default: '',
    },
    redirectProductType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedProductTypeOrTab: ProductType.Bike,
      redirectFilterSku: '',
      redirectFilterProductType: '',
      loading: false,
    };
  },
  computed: {
    ...mapState('allocatedBackorder', ['entries', 'subTotals', 'ETA', 'ETAMessage', 'filters', 'pagination', 'sort']),
    ...mapState('backend', ['encodedContextPath', 'isConsumerFriendlyMode']),
    ...mapGetters('allocatedBackorder', ['firstProductType', 'productTypes', 'isEveryEntryAllowedToShip']),
    ...mapGetters('user', ['b2bUnitShippingDays']),

    defaultTab() {
      return this.redirectProduct ?? this.firstProductType;
    },
    redirectProduct() {
      if (ProductTypeConverter.convertToDI(this.redirectProductType) === ProductType.Aftermarket) {
        return ProductTypeConverter.convertToDI(this.redirectProductType);
      }

      return ProductType.Bike;
    },
    isTabETA() {
      return allocatedBackorderTabNamesETA.includes(this.selectedProductTypeOrTab);
    },
    tabLabelMap() {
      return {
        [ProductType.Bike]: this.$t('backordersAllocated.B2B.bikes'),
        [ProductType.Aftermarket]: this.$t('text.accessories'),
      };
    },
    tabETALabelMap() {
      return {
        [ProductType.Bike]: this.$t('notifications.B2B.bikes.eta'),
        [ProductType.Aftermarket]: this.$t('notifications.B2B.accessories.eta'),
      };
    },
    noItemDefaultMessage() {
      return this.$t('notifications.B2B.noWatched');
    },
  },
  watch: {
    selectedProductTypeOrTab(value) {
      if (this.isTabETA) {
        if (`${[ProductType.Bike]}-ETA` == value) {
          this.fetchETA([ProductType.Bike]);
        } else {
          this.fetchETA([ProductType.Aftermarket]);
        }
      } else if (this.redirectFilterSku) {
        this.removeRedirectParametersFromUrl();
        this.fetchOrders(this.selectedProductTypeOrTab);
        this.redirectFilterProductType = '';
        this.redirectFilterSku = '';
      } else {
        this.$refs.filters.resetFilters();
        this.resetFilters(this.productTypes);
        this.searchEntries({productType: value});
      }
    },
  },
  async created() {
    this.loading = true;

    await this.getSearchHeader();

    this.changeTab(this.defaultTab);
    this.applyRedirectDataToFilterData();
    if (this.defaultTab === ProductType.Bike) await this.fetchOrders(this.selectedProductTypeOrTab);

    this.loading = false;
  },
  methods: {
    async fetchOrders(productType) {
      this.loading = true;

      await this.searchEntries({productType});
      if (this.redirectFilterSku) {
        this.redirectFilterProductType = '';
        this.redirectFilterSku = '';
      }
      this.loading = false;
    },
    async fetchETA(productType) {
      await this.getProductTypeETA(productType);

      this.ETA[productType]?.forEach((orderLine) => {
        Vue.set(orderLine, 'checkboxActive', false);
      });
    },
    sortEntries(sort) {
      this.changeSortEntries({productType: this.selectedProductTypeOrTab, sort: sort});
      this.fetchOrders(this.selectedProductTypeOrTab);
    },
    onFilterChanged(filters) {
      this.redirectFilterProductType = '';
      this.redirectFilterSku = '';

      this.filterEntries({productType: this.selectedProductTypeOrTab, filters: filters});
    },
    onResetFilters() {
      this.resetFilters(this.productTypes);
      this.fetchOrders(this.selectedProductTypeOrTab);
    },
    changeTab(productTypeOrTab) {
      if (this.pagination) this.pagination.currentPage = 0;
      this.selectedProductTypeOrTab = productTypeOrTab;
    },
    applyRedirectDataToFilterData() {
      this.redirectFilterSku = this.redirectSku ?? this.filters[this.selectedProductTypeOrTab].sku;
      this.filters[this.selectedProductTypeOrTab].sku =
        this.redirectFilterSku ?? this.filters[this.selectedProductTypeOrTab].sku;
      this.redirectFilterProductType = this.redirectProductType ?? this.redirectFilterProductType;
    },
    removeRedirectParametersFromUrl() {
      window.history.replaceState(
        null,
        document.title,
        Url.update(window.location.href, undefined, {
          removeKeys: ['productType', 'skuSearchTerm'],
        })
      );
    },
    ...mapMutations('allocatedBackorder', ['resetFilters']),
    ...mapActions('allocatedBackorder', [
      'getSearchHeader',
      'getProductTypeETA',
      'searchEntries',
      'filterEntries',
      'changeSortEntries',
    ]),
  },
};
</script>

<style scoped lang="scss">
.page-myAccountBackordersAllocatedB2B .account-section-content .grid-container {
  max-width: 100%;
  margin: 0 auto;
}

// Ensure that style is passed down into child component via ::v-deep, otherwise it only impacts parent
::v-deep .pdl-tabs .pdl-tabs__item {
  @apply font-heading;

  @include breakpoint(medium) {
    @apply text-xl;
  }

  @apply m-0;
}
</style>
