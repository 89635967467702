<template>
  <div>
    <!-- Notification Tabs -->
    <section id="notification-tabs" class="mb-5">
      <pdl-tabs>
        <pdl-tab-pane
          v-for="(category, index) in categories"
          :key="`${index}-${count[category.key]}`"
          :label="`${category.label} (${count[category.key]})`"
        >
          <notification-tab
            :tab-data="filteredTabData[category.key]"
            :no-item="noItemMessages[index]"
            :mark-as-read-msg="$t('text.markAsRead')"
            @update:notification-read="updateNotification"
            @update:notification-read-all-marked="updateAllMarkedAsRead"
          />
        </pdl-tab-pane>
      </pdl-tabs>
    </section>
  </div>
</template>

<script>
import NotificationTab from '@/components/containers/notifications/NotificationTab';
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {
    NotificationTab,
  },
  props: {
    selectedIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    categories() {
      return [
        {label: this.$t('text.announcements'), key: 'announcements'},
        {label: this.$t('text.orders'), key: 'orders'},
        {label: this.$t('text.watchList'), key: 'stockwatch'},
        {label: this.$t('contactUs.topic.warranty'), key: 'warranty'},
      ];
    },
    noItemMessages() {
      return [
        this.$t('notifications.B2B.noAnnouncement'),
        this.$t('notifications.B2B.noOrder'),
        this.$t('notifications.B2B.noWatchList'),
        this.$t('notifications.B2B.noWarranty'),
      ];
    },
    filteredTabData() {
      return this.notifications !== null
        ? {
            warranty: this.notifications.filter((notification) => notification.category.name === 'Warranty'),
            orders: this.notifications.filter((notification) => notification.category.name === 'Orders'),
            stockwatch: this.notifications.filter((notification) => notification.category.name === 'Stock Watch'),
            announcements: this.notifications.filter((notification) => notification.category.name === 'Announcements'),
          }
        : {};
    },
    count() {
      return this.notifications
        ? {
            warranty: this.filteredTabData.warranty.filter((notification) => !notification.readStatus).length,
            orders: this.filteredTabData.orders.filter((notification) => !notification.readStatus).length,
            stockwatch: this.filteredTabData.stockwatch.filter((notification) => !notification.readStatus).length,
            announcements: this.filteredTabData.announcements.filter((notification) => !notification.readStatus).length,
          }
        : {
            warranty: 0,
            orders: 0,
            stockwatch: 0,
            announcements: 0,
          };
    },

    ...mapGetters('notifications', ['notifications']),
  },
  created() {
    this.fetchNotifications(true);
  },
  methods: {
    updateNotification(id) {
      this.updateNotificationRead(id);
    },
    updateAllMarkedAsRead() {
      let selectedIds = this.notifications.filter((item) => item.active === true).map((item) => item.id);
      return selectedIds.forEach((id) => this.updateNotificationRead(id));
    },
    ...mapActions('notifications', ['fetchNotifications']),
    ...mapActions('notifications', ['updateNotificationRead']),
  },
};
</script>
