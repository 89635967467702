<template>
  <li class="border-0 form-control hover:bg-gray-05 cursor-pointer">
    <button @click="handleSuggestionClick">
      {{ formattedAddress }}
    </button>
  </li>
</template>
<script>
import {mapState} from 'vuex';
import {getAddressFormConfig} from '@/config/checkout/address-form-config';
export default {
  props: {
    suggestion: {
      type: Object,
      required: true,
      default: () => null,
    },
  },
  computed: {
    ...mapState('backend', ['locale']),
    formattedAddress() {
      const configuration = getAddressFormConfig(this.locale).addressDetails.map(({field}) => field);
      return configuration
        .map((field) => this.suggestion[field])
        .filter((value) => value)
        .join(' ');
    },
  },
  methods: {
    handleSuggestionClick() {
      this.$parent.$emit('suggestion-selected', this.suggestion);
    },
  },
};
</script>
