import CheckboxConfiguration, {CHECKBOX_CONFIG_OPTIONS} from '@/modules/consent-checkboxes/checkbox-configuration';

export default (locale, isLoggedIn) => {
  return {
    getRegistrationConfiguration() {
      return CheckboxConfiguration(locale, isLoggedIn)[CHECKBOX_CONFIG_OPTIONS.REGISTRATION];
    },
    getOrderReviewConfiguration() {
      return CheckboxConfiguration(locale, isLoggedIn)[CHECKBOX_CONFIG_OPTIONS.ORDER_REVIEW];
    },
  };
};
