export default {
  methods: {
    getUniqueWarehouseCodesFromAllProductVariants(variants = []) {
      let warehouseIds = [];
      variants.forEach((variant) => {
        variant.warehouses?.map((item) =>
          item.isPrimary ? warehouseIds.unshift(item.id) : warehouseIds.push(item.id)
        );
        return warehouseIds;
      });
      return [...new Set(warehouseIds)];
    },
    fillWarehousesByCodes(codes = [], variantWarehouses = []) {
      return codes.map(
        (code) =>
          variantWarehouses.find((warehouse) => warehouse.id === code) || {
            id: '',
            isAvailable: false,
          }
      );
    },
    getWarehouseStockWatchOptions(stocks, products) {
      let stockOptions = [];
      products.forEach((product) => {
        product.warehouses?.map((item) => {
          item.isPrimary
            ? stockOptions.unshift(stocks.find((item) => item.id !== 'Any'))
            : stockOptions.push(stocks.find((item) => item.id === 'Any'));
        });
      });
      return [...new Set(stockOptions)];
    },
    hasWarehouses(warehouseList = []) {
      return warehouseList?.length > 0;
    },
  },
};
