import {onMounted} from 'vue';
import {ApplePayProvider} from '@/utils/checkout/payment-providers/apple-pay-provider.js';
import headTag from '@/utils/head-tag.js';

/**
 * Setup for using the apple sdk url around our app.
 * Can only be called once per page with our headTag.
 * use on page containers for Apple Pay usage.
 */
export function useApplePaySdk() {
  onMounted(() => {
    if (!ApplePayProvider.canMakePayments()) return;
    headTag.appendScript('apple-pay', 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js');
  });
}
