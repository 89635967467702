<template>
  <pdl-steps id="productRegistrationProgress" v-model="currentStep" @change="navigate">
    <template v-for="step in steps">
      <pdl-steps-item :key="step.stepId" :qaid="`${step.stepId}-nav-link`" v-html="step.text" />
    </template>
  </pdl-steps>
</template>

<script>
import {PdlSteps, PdlStepsItem} from '@pedal/pdl-steps';
import {mapState} from 'vuex';

export default {
  components: {PdlSteps, PdlStepsItem},

  props: {
    registrationStep: {
      type: String,
      default: '',
      required: true,
    },
    baseProperties: {
      type: Array,
      default: () => [],
      required: true,
    },
    serialNumber: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      currentStep: 0,
    };
  },

  computed: {
    steps() {
      return [
        {
          stepId: 'findBike',
          text: this.bp('findBike'),
          url: `/${this.b2b ? 'bike-registration' : 'productRegistration'}`,
        },
        {
          stepId: 'verify',
          text: this.bp('verify'),
          url: `/${this.b2b ? 'bike-registration' : 'productRegistration'}/serial/${this.serialNumber}/`,
        },
        {stepId: 'register', text: this.bp('register')},
      ];
    },
    ...mapState('backend', ['encodedContextPath', 'b2b']),
  },

  created() {
    const currentStep = this.steps.indexOf(this.steps.find((item) => item.stepId === this.registrationStep));
    this.currentStep = currentStep < 0 ? 0 : currentStep;
  },

  methods: {
    navigate(stepIndex) {
      if (this.steps.length > stepIndex) {
        window.location.href = `${this.encodedContextPath}${this.steps[stepIndex].url}`;
      }
    },
  },
};
</script>
