<template>
  <div>
    <form
      ref="form"
      action="/j_spring_security_check"
      :class="{'opacity-50': isWorking}"
      autocomplete="on"
      method="post"
      @submit.prevent="submit"
    >
      <pdl-callout v-if="hasErrorCallout" kind="error">
        <template #content>
          <span>{{ $t('login.error.account.not.found.title') }}</span>
        </template>
      </pdl-callout>
      <form-group
        v-model="email"
        type="email"
        name="j_username"
        :label="$t('login.email')"
        :error="getErrorMessage('email')"
        show-error-messages
        force-display-error
      />
      <form-group
        v-model="password"
        type="password"
        name="j_password"
        :label="$t('login.password')"
        :error="getErrorMessage('password')"
        :autocomplete="autocompleteAttributeValue"
        show-error-messages
        force-display-error
      />
      <form-group
        type="checkbox"
        name="_spring_security_remember_me"
        :multi-items="[{displayName: $t('login.remember'), checked: rememberMe, value: true}]"
        qaid="login-remember-me"
        @change="
          (e) => {
            rememberMe = e.target.checked;
          }
        "
      />

      <trek-button primary :disabled="isWorking" type="submit" class="mb-4">
        <span>{{ $t('login.login') }}</span>
      </trek-button>
    </form>
    <pdl-link small standalone :href="withContextPath('/login/pw/request/external/')">
      {{ $t('forgottenPwd.title') }}
    </pdl-link>
  </div>
</template>
<script>
import {Url} from '@/utils/url';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlLink} from '@pedal/pdl-link';
import FormGroup from '@/components/FormGroup';
import TrekButton from '@/components/TrekButton';
import {UserAccountBase} from '@/components/containers/my-account/utils/user-account-base';
const AUTOCOMPLETE_ATTRIBUTE_VALUE = 'current-password';

export default {
  name: 'UserLogin',
  components: {PdlCallout, PdlLink, FormGroup, TrekButton},
  extends: UserAccountBase,
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isWorking: false,
      rememberMe: false,
      email: '',
      password: '',
      autocompleteAttributeValue: AUTOCOMPLETE_ATTRIBUTE_VALUE,
      hasErrorCallout: false,
    };
  },
  validations() {
    return {
      email: {
        required: this.trekValidators.required('login.email'),
        email: this.trekValidators.email('login.email'),
      },
      password: {
        required: this.trekValidators.required('login.password'),
      },
    };
  },
  mounted() {
    this.isLoginErrorCalloutVisible();
  },

  methods: {
    withContextPath: (url) => Url.withContextPath(url),

    isLoginErrorCalloutVisible() {
      if (window.location.search && window.location.search.indexOf('error=true') > -1) {
        this.hasErrorCallout = true;
      }
    },

    /**
     * Handle form submission & validate data.
     * @return {Promise<void>}
     */
    submit() {
      this.hasErrorCallout = false;
      this.v$.$validate().then((isValid) => {
        if (isValid) {
          this.isWorking = true;
          this.$refs.form.submit();
        }
      });
    },
  },
};
</script>
