<template>
  <div qaid="virtual-packing-slip-order-grid" class="my-6">
    <div v-for="orderGroup in allPackingOrders" :key="orderGroup.orderNumber">
      <div v-if="orderGroup" class="flex flex-col md:flex-row justify-between">
        <p>
          <span qaid="packing-slip-order-number" class="font-medium">
            {{ $t('text.account.packingslip.ordernumber') }}:
          </span>
          <trek-link id="shipment-tracking-number" underline :href="orderGroup.orderUrl">
            {{ orderGroup.orderNumber }}
          </trek-link>
        </p>
        <p>
          <span qaid="packing-slip-order-grid-invoice" class="font-medium"> {{ $t('text.invoiceNumber') }}: </span>
          <span v-if="orderGroup.orderType === 'SM'">{{ orderGroup.invoiceNumber }}</span>
          <trek-button
            v-else
            id="shipment-invoice-number"
            text
            underline
            @click="downloadInvoice(orderGroup.invoiceUrl)"
          >
            {{ orderGroup.invoiceNumber }}
          </trek-button>
        </p>
      </div>
      <table class="b2b-grid b2b-grid--collapse is-compact table-fixed" qaid="packing-slip-order-grid">
        <caption class="sr-only">
          {{
            $t('text.account.packingslip.ordernumber')
          }}
          {{
            orderGroup.orderNumber
          }}
        </caption>
        <thead class="b2b-grid__header">
          <tr>
            <th
              v-for="(item, key) in colHeaders"
              :id="`virtual-packing-slip-order-grid-header-${key}`"
              :key="key"
              :qaid="`virtual-packing-slip-order-grid-header-${key}`"
              :class="['b2b-grid__cell', 'b2b-grid__head', {'lg:w-1/2': key === 'description'}]"
            >
              <span>{{ item }}</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="order in orderGroup.orders" :key="order.id">
            <td qaid="packing-order-grid-sku" class="b2b-grid__cell lg:table-cell">
              <div class="b2b-grid__label">{{ $t('text.itemNumber') }}</div>
              <div class="b2b-grid__content">
                <trek-link
                  v-if="order.productType !== 'Project One Bikes'"
                  id="item-sku-pdp-url"
                  underline
                  :href="order.pdpUrl"
                >
                  <grid-text
                    :data="{
                      value: order.itemSku,
                    }"
                  />
                </trek-link>
                <grid-text
                  v-else
                  :data="{
                    value: order.itemSku,
                  }"
                />
              </div>
            </td>

            <td qaid="packing-order-grid-quantity" class="b2b-grid__cell lg:table-cell">
              <div class="b2b-grid__label">{{ $t('text.quantity') }}</div>
              <div class="b2b-grid__content">
                <grid-text
                  :data="{
                    value: order.quantityAllocatedOrShipped,
                  }"
                />
              </div>
            </td>

            <td qaid="packing-order-grid-description" class="b2b-grid__cell lg:table-cell">
              <div class="b2b-grid__label">{{ $t('distributor.B2B.advancedOrder.name') }}</div>
              <div v-if="order.itemDescription" class="b2b-grid__content">
                <grid-text
                  :data="{
                    value: order.itemDescription,
                  }"
                />
              </div>
            </td>

            <td qaid="packing-order-grid-unit-price" class="b2b-grid__cell lg:table-cell">
              <div class="b2b-grid__label">{{ $t('text.unitPrice') }}</div>
              <div class="b2b-grid__content">
                <grid-text
                  :data="{
                    value: order.unitPrice,
                  }"
                />
              </div>
            </td>

            <td qaid="packing-order-grid-sku-extended-price" class="b2b-grid__cell lg:table-cell">
              <div class="b2b-grid__label">{{ $t('text.extendedPrice') }}</div>
              <div class="b2b-grid__content">
                <grid-text
                  :data="{
                    value: order.extendedPrice,
                  }"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pdl-callout v-if="allPackingOrders.length === 0" kind="default">
      <template #content>
        <span>{{ $t('assistedService.B2B.saved.noItems') }}</span>
      </template>
    </pdl-callout>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import GridText from '@/components/grid/GridText';
import {PdlCallout} from '@pedal/pdl-callout';

export default {
  components: {GridText, PdlCallout},

  props: {
    size: {
      type: Number,
      default: 50,
    },
    packingOrders: {
      type: Array,
      required: true,
    },
    billToNumber: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('preSeason', ['orderReview']),
    ...mapState('backend', ['encodedContextPath']),

    allPackingOrders() {
      return this.packingOrders.map((orderList) => {
        const orderData = orderList[0];
        return orderData ? this.createPackingOrder(orderList, orderData) : null;
      });
    },

    colHeaders() {
      return {
        itemNumber: this.$t('text.itemNumber'),
        quantity: this.$t('text.quantity'),
        description: this.$t('distributor.B2B.advancedOrder.name'),
        unitPrice: this.$t('text.unitPrice'),
        extendedPrice: this.$t('text.extendedPrice'),
      };
    },
  },
  methods: {
    isHeaderAlignRight(item) {
      return item === 'subtotal';
    },
    downloadInvoice(invoiceUrl) {
      window.location.href = invoiceUrl;
    },
    createPackingOrder(orderList, orderData) {
      return {
        orderNumber: orderData.orderNumber,
        orderUrl: `${this.encodedContextPath}${orderData.orderUrl}`,
        invoiceNumber: orderData.invoiceNumber,
        invoiceUrl: `/my-account/invoices/${orderData.invoiceNumber}_${orderData.invoiceDocumentType}/${this.billToNumber}/${orderData.orderType}`,
        orderType: orderData.orderType,
        orders: orderList.map((order) => ({
          ...order,
          pdpUrl: `${this.encodedContextPath}/p/${order.itemSku}`,
        })),
      };
    },
  },
};
</script>
