/*global trackAddToCart_google, trackRemoveFromCart, trackUpdateCart*/
export default function () {
  // Check whether analytics mediator has loaded.
  if (!('mediator' in window) || window.mediator === null || typeof window.mediator !== 'object') {
    return;
  }

  window.mediator.subscribe('trackAddToCart', function (data) {
    if (data.productCode && data.quantity) {
      trackAddToCart_google(
        data.productCode,
        data.quantity,
        data.productName,
        data.productPrice,
        data.productBrand,
        data.productCategory,
        data.productColor,
        data.productDimension
      );
    }
  });

  window.mediator.subscribe('trackUpdateCart', function (data) {
    if (data.productCode && data.initialCartQuantity && data.newCartQuantity) {
      trackUpdateCart(
        data.productCode,
        data.initialCartQuantity,
        data.newCartQuantity,
        data.productName,
        data.productPrice,
        data.productBrand,
        data.productCategory,
        data.productColor,
        data.productSize
      );
    }
  });

  window.mediator.subscribe('trackRemoveFromCart', function (data) {
    if (data.productCode && data.initialCartQuantity) {
      trackRemoveFromCart(
        data.productCode,
        data.initialCartQuantity,
        data.productName,
        data.productPrice,
        data.productBrand,
        data.productCategory,
        data.productColor,
        data.productSize
      );
    }
  });
}
