<template>
  <div class="locator-filters" tabindex="0">
    <div class="flex justify-between p-2 border-b border-gray-10">
      <p class="text-lg" v-html="$t('storeFinder.v2.filter')"></p>
      <pdl-button
        text
        icon-only
        large
        icon="highlight_off"
        :aria-label="$t('storeFinder.v2.closeFilter')"
        qaid="store-locator__filters__close"
        @click.prevent="close"
      >
      </pdl-button>
    </div>
    <div class="b2b-scroll-list flex-auto pt-2 px-2 w-auto m-0" qaid="store-locator__filters__scrollable">
      <locator-filters-brands v-model="brandValues" qaid="store-locator__filters__brands" />
      <form-group
        :ref="selectionAndRating"
        name="selectionAndRating"
        type="checkbox"
        :label="$t('storeFinder.sortBy.productSelection')"
        :multi-items="selectionAndRating"
        qaid="store-locator__filters__product-selection"
        @change="
          (ev) => {
            onChange('selectionAndRatingValues', ev.target);
          }
        "
      />
      <fieldset
        v-if="serviceFilters && serviceFilters.length > 0"
        class="form-fieldset"
        qaid="store-locator__filters__services"
      >
        <legend for="selectionAndRating" class="form-fieldset__legend" qaid="store-locator__filters__services__label">
          <span>{{ $t('storeFinder.v2.filter.byServices') }}</span>
        </legend>
        <div>
          <label
            v-for="(service, index) in serviceFilters.filter(
              (serviceFilter) => serviceFilter.title && serviceFilter.title.length
            )"
            :key="service.code"
            class="checkbox relative"
            :for="service.code"
            :qaid="`store-locator__filters__services__${index}`"
          >
            <input
              :id="service.code"
              v-model="serviceFiltersValues[service.code]"
              type="checkbox"
              :name="service.code"
            />
            <span class="control-indicator"></span>
            <span>
              <span class="block">{{ service.title }}</span>
              <span class="block text-xs">{{ service.body }}</span>
            </span>
          </label>
        </div>
      </fieldset>
      <fieldset v-if="saleFilters && saleFilters.length > 0" class="form-fieldset" qaid="store-locator__filters__sale">
        <legend for="saleFilters" class="form-fieldset__legend" qaid="store-locator__filters__sale__label">
          <span>{{ $t('storelocator.saleFilter') }}</span>
        </legend>
        <div>
          <label
            v-for="sale in saleFilters.filter((saleFilter) => saleFilter.title && saleFilter.title.length)"
            :key="sale.code"
            class="checkbox relative"
            :for="sale.code"
            :qaid="`store-locator__filters__sale__${sale.code}`"
          >
            <input :id="sale.code" v-model="saleFiltersValues[sale.code]" type="checkbox" :name="sale.code" />
            <span class="control-indicator"></span>
            <span>
              <span class="block">{{ sale.title }}</span>
              <span class="block text-xs">{{ sale.body }}</span>
            </span>
          </label>
        </div>
      </fieldset>
    </div>
    <div class="px-2 py-1 border-t border-gray-10">
      <div class="buttons buttons--right-for-md">
        <pdl-button primary qaid="store-locator__filters__apply-button" @click.prevent="applyFilters">
          <span v-html="$t('storeFinder.product.refineResults.search')"></span>
        </pdl-button>
        <pdl-button secondary qaid="store-locator__filters__cancel-button" @click.prevent="close">
          <span v-html="$t('text.cancel')"></span>
        </pdl-button>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from '@/components/FormGroup';
import LocatorFiltersBrands from '@/components/locator/components/LocatorFiltersBrands';
import {PdlButton} from '@pedal/pdl-button';
import get from 'lodash/get';

export default {
  name: 'LocatorFilters',
  components: {LocatorFiltersBrands, FormGroup, PdlButton},
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    serviceFilters: {
      type: Array,
      default: () => [],
    },
    saleFilters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      brandValues: {
        electra: false,
        trek: false,
      },
      selectionAndRatingValues: {
        bestSelection: false,
      },
      saleFiltersValues: {},
      serviceFiltersValues: {},
    };
  },
  computed: {
    selectionAndRating() {
      return [
        {
          displayName: this.$t('storelocator.bestSelection'),
          value: 'bestSelection',
          checked: this.selectionAndRatingValues.bestSelection,
        },
      ];
    },
  },
  watch: {
    serviceFilters() {
      this.initFilters('serviceFilters', 'serviceFiltersValues', 'services');
    },
    saleFilters() {
      this.initFilters('saleFilters', 'saleFiltersValues', 'sales');
    },
  },
  created() {
    if (!this.filters) {
      return;
    }

    for (let filter in this.filters) {
      [
        this,
        this.brandValues,
        this.selectionAndRatingValues,
        this.serviceFiltersValues,
        this.saleFiltersValues,
      ].forEach((target) => {
        if (filter in target) {
          target[filter] = this.filters[filter];
        }
      });
    }
  },
  mounted() {
    this.initFilters('serviceFilters', 'serviceFiltersValues', 'services');
    this.initFilters('saleFilters', 'saleFiltersValues', 'sales');
  },
  methods: {
    initFilters(filtersList, valuesList, valuesKey) {
      if (!this[filtersList] || !this[filtersList].length) {
        return;
      }

      const services = get(this.filters, valuesKey, '');
      const enabledServices = services.split(',');
      this[valuesList] = {};
      this[filtersList].forEach((item) => {
        this.$set(this[valuesList], item.code, enabledServices.indexOf(item.code) > -1);
      });
    },
    close() {
      this.$emit('close-filters');
    },
    onChange(target, el) {
      this[target][el.value] = el.checked;
    },
    getCommaSeparatedValues(valuesList) {
      let services = [];
      if (this[valuesList]) {
        for (let service in this[valuesList]) {
          if (this[valuesList][service]) {
            services.push(service);
          }
        }
      }

      return services.join(',');
    },
    applyFilters() {
      let filters = {};
      const appendFilter = (name, value, all = false) => {
        if (value || all) {
          filters[name] = value;
        }
      };

      const appendGroupFilter = (items, all = false) => {
        for (let item in items) {
          appendFilter(item, items[item], all);
        }
      };

      appendFilter('services', this.getCommaSeparatedValues('serviceFiltersValues'));
      appendFilter('sales', this.getCommaSeparatedValues('saleFiltersValues'));
      appendGroupFilter(this.brandValues, true);
      appendGroupFilter(this.selectionAndRatingValues);

      this.$emit('apply-filters', filters);
      this.$emit('close-filters');
    },
  },
};
</script>
