<script>
import {PdlDialog} from '@pedal/pdl-dialog';
import FindRetailerDialog from '@/components/containers/my-account/FindRetailerDialog.vue';

export default {
  /* eslint-disable vue/no-unused-components */
  components: {
    PdlDialog,
    FindRetailerDialog,
  },
  data() {
    return {
      hasFindRetailerDialogVisible: false,
    };
  },
  methods: {
    openFindRetailerDialog() {
      this.hasFindRetailerDialogVisible = true;
    },
    closeFindRetailerDialog() {
      this.hasFindRetailerDialogVisible = false;
    },
  },
};
</script>
