<template>
  <div :qaid="`cart-entry-${entryNumber}-fulfillment-option-container`">
    <p v-if="isEnterZipMessageShown">{{ $t('checkout.multi.deliveryAddress.enterZip') }}</p>
    <div v-if="!Object.keys(fulfillmentOptionsData).length" class="flex flex-col border border-gray-10 p-1">
      <trek-skeleton width="55%"></trek-skeleton>
      <trek-skeleton width="60%"></trek-skeleton>
      <trek-skeleton width="50%"></trek-skeleton>
      <trek-skeleton width="20%"></trek-skeleton>
    </div>
    <pdl-card
      v-for="(item, i) in validFulfillmentOptions"
      :key="`fulfillment-option-${item.code}`"
      :qaid="`cart-entry-${entryNumber}-${item.code}-fulfillment-card`"
      is-padded
      class="relative"
    >
      <template #bodyContent>
        <input
          :id="`option-${entryNumber}-${i}`"
          type="radio"
          :name="`cart-entry-${entryNumber}`"
          :checked="isDeliverySpeedSelected(item)"
          class="absolute block opacity-0 pointer-events-auto cursor-pointer"
          @change="selectFulfillment(getDeliveryMode(item))"
        />
        <shipping-option-title
          :shipping-type="item.code"
          :qaid="`cart-entry-${entryNumber}-${item.code}-fulfillment-option-icon`"
          :custom-class="'relative'"
        >
          <label
            :for="`option-${entryNumber}-${i}`"
            :qaid="`cart-entry-${entryNumber}-${item.code}-fulfillment-option-title`"
            class="inline-block text-sm font-bold"
          >
            {{ $t(item.name) }}
          </label>
        </shipping-option-title>
        <cart-fulfillment-option-messaging
          :fulfillment-option="item"
          :delivery-mode="getDeliveryMode(item)"
          :entry-number="entryNumber"
        />
      </template>
    </pdl-card>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import {PdlCard} from '@pedal/pdl-card';
import TrekSkeleton from '@/components/TrekSkeleton.vue';
import CartFulfillmentOptionMessaging from '@/components/cart/b2c/CartFulfillmentOptionMessaging.vue';
import {PdlToastType} from '@/constants/pdl-toast-type';
import ShippingOptionTitle from './ShippingOptionTitle.vue';
import {fulfillmentOptionComparator} from '@/utils/fulfillment-options-config.js';

export default {
  components: {
    ShippingOptionTitle,
    PdlCard,
    TrekSkeleton,
    CartFulfillmentOptionMessaging,
  },
  props: {
    fulfillmentOptionsData: {
      type: Object,
      default: () => ({}),
    },
    entry: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hasShownRetailerPricingToast: false,
    };
  },
  computed: {
    ...mapState('user', ['selectedRetailer', 'selectedLocation']),
    ...mapState('cart', ['cartFulfillmentSpeed']),
    isEnterZipMessageShown() {
      return (
        (!this.selectedRetailer?.name || !this.selectedLocation?.postcode) &&
        !Object.keys(this.fulfillmentOptionsData).length
      );
    },
    validFulfillmentOptions() {
      return this.fulfillmentOptionsData?.fulfillmentOptions
        ?.filter((option) => option.isSelectable && option.deliveryModes.length)
        ?.sort(fulfillmentOptionComparator);
    },
    entryNumber() {
      return this.entry.entryNumber;
    },
  },
  watch: {
    validFulfillmentOptions(options) {
      options.forEach((option) => {
        if (this.isDeliverySpeedSelected(option)) {
          this.showPricingAwarnessMsg(option);
        }
      });
    },
  },
  methods: {
    ...mapActions('cart', ['updateCartEntryOptions', 'updateCartEmail']),
    isDeliverySpeedSelected(item) {
      return this.getDeliveryMode(item)?.code === this.entry.deliveryMode?.code;
    },
    getDeliveryMode(item) {
      return item?.deliveryModes.find((m) => m.code === this.cartFulfillmentSpeed) || item.deliveryModes[0];
    },
    async selectFulfillment(deliveryMode) {
      const response = await this.updateCartEntryOptions({
        dealerCode: this.selectedRetailer.name,
        deliveryMode: deliveryMode.code,
        entries: [this.entry],
      });
      if (!response) {
        this.$notify({
          type: PdlToastType.ERROR,
          message: this.$t('errorMessage.unableToProcess'),
        });
      }
    },
    showPricingAwarnessMsg(item) {
      if (item.deliveryModes[0]?.code.includes('BOPIS') && this.hasShownRetailerPricingToast === false) {
        this.$emit('pick-up-in-store-selected', true);
        this.hasShownRetailerPricingToast = true;
      } else if (!item.deliveryModes[0]?.code.includes('BOPIS')) {
        this.$emit('pick-up-in-store-selected', false);
        this.hasShownRetailerPricingToast = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .pdl-card {
  &:last-of-type {
    margin-bottom: 0;
  }
}

::v-deep .pdl-card__body {
  pointer-events: none;
}

::v-deep .pdl-card--padded .pdl-card__body {
  padding: 8px 12px 8px 8px;
}

input[type='radio'] {
  --focus-shadow: 0;

  appearance: none;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  line-height: 0;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  transition: opacity 0.2s, background-color 0.2s, box-shadow 0.2s;
  border: 2px solid var(--blue-100, #007ab8);
  border-radius: 2px;
  background-color: #e6f2f800;

  &:checked {
    opacity: 1;
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--blue-10, #e6f2f8);
      opacity: 1;
    }
  }

  &:focus {
    --focus-shadow: 0.125rem;

    opacity: 1;
    border: 1px solid var(--blue-100, #007ab8);
    box-shadow: 0 0 0 var(--focus-shadow) var(--blue-10, #e6f2f8);
  }

  &:focus:checked {
    border-width: 2px;
  }
}
</style>
