<template>
  <size-table :row-header-fallback="$t('text.variant.size')" />
</template>

<script>
import SizeTable from '@/components/SizeTable.vue';
export default {
  components: {SizeTable},
};
</script>
