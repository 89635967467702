'use strict';

export default function (element) {
  // ------------------------------------------------------------------------
  // specific accordion logic for login/registration/guest login accordions.
  // accordion target is html form but since we are using SPRING forms
  // assigning data attribute 'data-accordion-toggler-target' will not
  // work and is ignored.
  // ------------------------------------------------------------------------
  var buttons = element.find('[data-login-accordion-toggler-click]');
  var mediaQuery = '(min-width: 600px)';

  // default
  if (!window.matchMedia(mediaQuery).matches) {
    reset();
  }

  // temporarily limit these changes to login page until future stories address checkout-login
  if ($(document).find('#login-page').length) {
    // errors within an accordion's fields indicate previous user interaction with that accordion
    var errorsFound;
    $('.form--accordion').each(function () {
      if ($(this).find('.form__item--error').length) {
        var tempButton = $(this).parent().find('.accordion-button');
        $(this).addClass('expand-login');
        tempButton.addClass('active');
        $(document, window).scrollTop($('.form__item--error').first().offset().top - 60);
        errorsFound = true;
      }
    });
    // open login accordion if the page was just opened
    if (!errorsFound) {
      $('#trekLoginForm').addClass('expand-login');
      $('#trekLoginForm').prev().addClass('active');
    }
  }

  buttons.on('click', function (event) {
    event.preventDefault();
    handleStates($(this));
  });

  function handleStates(button) {
    // temporarily limit these changes to login page until future stories address checkout-login
    if (!$(document).find('#login-page').length) {
      buttons.each(function (index, el) {
        var next = button.next(); //accesses login form
        if (el === button.get(0)) {
          // processing login ?
          button.toggleClass('active');

          if (button.hasClass('active')) {
            next.addClass('expand-login'); //show it
          } else {
            next.removeClass('expand-login'); //hide it
          }
        } else {
          $(el).removeClass('active'); //registration button
          $(el).next().removeClass('expand-login'); // hide it
        }
      });
    } else {
      var active_el;
      buttons.each(function (index, el) {
        $(el).toggleClass('active');
        if ($(el).hasClass('active')) {
          $(el).next().addClass('expand-login');
          active_el = $(el);
        } else {
          $(el).next().removeClass('expand-login');
        }
      });
      $(document, window).scrollTop($(active_el).offset().top - 60);
    }
  }

  function reset() {
    buttons.each(function (index, el) {
      $(el).removeClass('active'); //remove active from login and registration accordion
      $(el).next().removeClass('expand-login'); // hide associated forms
    });

    //Show first accordion open when on /login/checkout
    if ($('#checkout-login-page').length) {
      handleStates($(buttons.get(0))); //Passes thru login accordion
    }
  }

  var media = window.matchMedia(mediaQuery);

  media.addListener(function (data) {
    if (data.matches === true) {
      //if tablet or smaller

      //going from small to large
      //Make sure all forms are shown
      buttons.each(function (index, el) {
        $(el).next().addClass('expand-login'); //show all forms
      });
    } else {
      reset();
    }
  });
}
