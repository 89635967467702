<template>
  <pdl-steps v-model="currentStep" class="mt-2 mb-4" @change="navigate">
    <template v-for="link in steps">
      <pdl-steps-item
        :key="link.progressBarId"
        :qaid="`${link.progressBarId}-nav-link`"
        v-html="bp(link.progressBarId)"
      >
      </pdl-steps-item>
    </template>
  </pdl-steps>
</template>
<script>
import {PdlSteps, PdlStepsItem} from '@pedal/pdl-steps';

export default {
  components: {PdlSteps, PdlStepsItem},

  props: {
    steps: {
      type: Array,
      default: () => [],
      required: true,
    },
    progressBarId: {
      type: String,
      default: '',
      required: false,
    },
  },

  data() {
    return {
      contextPath: ACC.config.encodedContextPath || '',
    };
  },

  created() {
    this.currentStep = this.steps.indexOf(this.steps.find((item) => item.progressBarId === this.progressBarId));
  },

  methods: {
    navigate(stepIndex) {
      if (this.steps.length > stepIndex) {
        window.location.href = `${this.contextPath}${this.steps[stepIndex].url}`;
      }
    },
  },
};
</script>
