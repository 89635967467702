<template>
  <div class="grid-container mt-3">
    <pdl-section-header size="xl" :is-dividing="true">
      <template slot="content">
        <pdl-heading :level="1">{{ $t('text.account.orderHistory') }}</pdl-heading>
      </template>
    </pdl-section-header>
    <div>
      <!-- Filters & Buttons -->
      <section class="mb-4">
        <p id="section-description" v-html="bp('orderHistoryDescription')"></p>

        <p class="font-bold text-base my-4" v-html="bp('filterOrders')"></p>

        <div class="grid-x grid-margin-x">
          <div class="cell medium-5 large-3">
            <form-group
              v-model="options.orderNumber"
              type="text"
              name="orderNumber"
              :label="bp('orderNumber')"
              :maxlength="8"
              qaid="orderHistoryOrderNumber"
            ></form-group>

            <form-group
              v-model="options.invoiceNumber"
              type="text"
              name="invoiceNumber"
              :label="bp('invoiceNumber')"
              :maxlength="8"
              qaid="orderHistoryInvoiceNumber"
            ></form-group>

            <form-group
              v-model="options.partNumber"
              type="text"
              name="partNumber"
              :label="bp('partNumber')"
              :maxlength="25"
              qaid="orderHistoryPartNumber"
            ></form-group>

            <form-group
              v-model="options.customerPo"
              type="text"
              name="customerPo"
              :label="bp('customerPo')"
              :maxlength="25"
              qaid="orderHistoryCustomerPo"
            ></form-group>

            <form-group
              v-model="options.shipToNumber"
              type="text"
              name="shipToNumber"
              :label="bp('storeNumber')"
              :maxlength="8"
              qaid="orderHistoryStoreNumber"
              @input="updateShipToValue"
            ></form-group>
          </div>
          <div class="cell medium-7 large-6 md:flex">
            <fieldset class="form-fieldset mr-4">
              <form-group name="orderDate" :label="bp('orderDate')">
                <template #input>
                  <pdl-date-picker
                    id="order-date-input"
                    ref="orderPicker"
                    qaid="order-date-input"
                    :allow-input="true"
                    mode="range"
                    @change="orderDateInput"
                  />
                </template>
              </form-group>

              <form-group name="orderDate" :label="bp('invoiceDate')">
                <template #input>
                  <pdl-date-picker
                    id="invoice-date-input"
                    ref="invoicePicker"
                    qaid="invoice-date-input"
                    :allow-input="true"
                    mode="range"
                    @change="invoiceDateInput"
                  />
                </template>
              </form-group>
            </fieldset>

            <fieldset id="credit-memos-field" class="form-fieldset">
              <form-group
                type="radio"
                name="creditMemos"
                qaid="credit-memos-filter-radio"
                :label="bp('creditMemos')"
                :multi-items="creditMemosOptions"
                :value="options.creditMemoFilter"
                @change="handleCreditMemoUpdate"
              />

              <form-group
                type="radio"
                name="orders"
                qaid="order-filter-radio"
                :label="bp('orders')"
                :multi-items="orderOptions"
                :value="options.productTypeFilter"
                @change="handleOrderUpdate"
              />

              <form-group
                class="lg:hidden"
                type="radio"
                name="orderStatusMobile"
                qaid="order-status-filter-radio"
                :label="bp('orderStatus')"
                :multi-items="orderStatusOptions"
                :value="options.orderStatusFilter"
                @change="handleOrderStatusUpdate"
              />
            </fieldset>
          </div>
          <div class="cell medium-12 large-3">
            <fieldset id="order-status-field" class="form-fieldset hidden lg:block">
              <form-group
                type="radio"
                name="orderStatus"
                qaid="order-status-filter-radio"
                :label="bp('orderStatus')"
                :multi-items="orderStatusOptions"
                :value="options.orderStatusFilter"
                @change="handleOrderStatusUpdate"
              />
            </fieldset>
            <div class="buttons buttons--right-for-md">
              <trek-button
                id="filter-orders-button"
                primary
                class="lg:w-full"
                qaid="filter-orders-button"
                @click="fetchAllOrders"
              >
                <span v-html="bp('filterOrders')"></span>
              </trek-button>
              <trek-button
                id="clear-filters-button"
                secondary
                class="lg:w-full"
                qaid="clear-filters-button"
                @click="clearChanges"
              >
                <span v-html="bp('clearFilters')"></span>
              </trek-button>
            </div>
          </div>
        </div>
      </section>

      <pdl-loading page :is-loading="loading" />
      <!-- Orders Tabs -->
      <section id="order-tabs" class="my-5">
        <pdl-tabs id="order-tabs" ref="tabs" :value="defaultTab">
          <pdl-tab-pane
            v-for="tab in activeTabs"
            :key="`${tab.key}-${orderData[tab.key].totalItemCount}`"
            :name="tab.id"
          >
            <h3 v-if="tab.id == 'tab-past-orders'" slot="label" class="heading heading--md m-0">
              <span v-html="tab.label" />
            </h3>
            <h3 v-else slot="label" class="heading heading--md m-0">
              <span v-html="tab.label" />
              ({{ orderData[tab.key].totalItemCount }})
            </h3>

            <order-history-grid
              :caption="tab.label"
              :grid-data="orderData[tab.key]"
              :columns="columns"
              :part-search-columns="partSearchColumns"
              :order-status="tab.key"
              :child-columns="childColumns"
              :child-data-key="childDataKey"
              :link-manage-allocated-items="linkManageAllocatedItems"
              :is-part-search="isPartSearch"
              @expand-order="expandOrder"
            ></order-history-grid>
          </pdl-tab-pane>
        </pdl-tabs>
      </section>
    </div>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import pickBy from 'lodash/pickBy';
import {Url} from '@/utils/url';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlDatePicker} from '@pedal/pdl-date-picker';
import OrderHistoryGrid from '@/components/containers/my-account/OrderHistoryGrid';
import TrekButton from '@/components/TrekButton';
import {PdlLoading} from '@pedal/pdl-loading';
import FormGroup from '@/components/FormGroup';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import dayjs from 'dayjs';
import {DateFormats} from '@/constants/date-formats';

const ALL = 'All';
const INITIAL_OPT = {
  pageSize: 10,
  creditMemoFilter: ALL,
  invoiced: null,
  invoiceDateStart: null,
  invoiceDateEnd: null,
  onlyCreditMemos: null,
  orderDateStart: null,
  orderDateEnd: null,
  orderNumber: null,
  invoiceNumber: null,
  partNumber: null,
  productTypeFilter: ALL,
  customerPo: null,
  shipToNumber: null,
  orderStatusFilter: ALL,
};

export default {
  components: {
    PdlLoading,
    OrderHistoryGrid,
    PdlSectionHeader,
    PdlHeading,
    PdlDatePicker,
    FormGroup,
    PdlTabs,
    PdlTabPane,
    TrekButton,
  },

  provide() {
    return {
      container: this,
    };
  },
  props: {
    baseProperties: {
      type: Array,
      default() {
        return [];
      },
    },
    isCartCheckout: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    partSearchColumns: {
      type: Array,
      default() {
        return [];
      },
    },
    idKey: {
      type: String,
      default: undefined,
    },
    childColumns: {
      type: Array,
      default() {
        return [];
      },
    },
    childDataKey: {
      type: String,
      default: undefined,
    },
    linkManageAllocatedItems: {
      type: String,
      default: undefined,
    },
    initialOrderNumber: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loading: true,
      openOrders: {rows: [], pageIndex: 0, pageCount: 0, totalItemCount: 0},
      pastOrders: {rows: [], pageIndex: 0, pageCount: 0, totalItemCount: 0},
      pageSize: 10,
      options: structuredClone(INITIAL_OPT),
      initialOptions: structuredClone(INITIAL_OPT),
      isPartSearch: false,
      urlParams: {},
    };
  },

  computed: {
    creditMemosOptions() {
      return [
        {value: ALL, displayName: this.bp('showAllOrders')},
        {value: 'OnlyCreditMemos', displayName: this.bp('creditMemos')},
        {value: 'HideCreditMemos', displayName: this.bp('hideCreditMemos')},
      ];
    },

    orderOptions() {
      return [
        {value: ALL, displayName: this.bp('showAllOrders')},
        {value: 'ProjectOneBikes', displayName: this.bp('p1Orders')},
        {value: 'Consumer', displayName: this.bp('consumerOrders')},
        {value: 'Bikes', displayName: this.bp('bikes')},
        {value: 'Accessories', displayName: this.bp('accessories')},
      ];
    },

    orderStatusOptions() {
      return [
        {value: ALL, displayName: this.bp('showAllOrders')},
        {value: 'WithAllocatedItems', displayName: this.bp('allocatedItems')},
        {value: 'InWarehouse', displayName: this.bp('inWarehouseItems')},
        {value: 'Processing', displayName: this.bp('orderProcessing')},
      ];
    },

    activeTabs() {
      return this.tabs.filter((tab) => tab.active);
    },

    tabs() {
      return [
        {
          id: 'tab-open-orders',
          label: this.bp('openOrders'),
          data: this.openOrders,
          key: 'openOrders',
          active: this.isCartCheckout,
        },
        {
          id: 'tab-past-orders',
          label: this.bp('invoicedOrders'),
          data: this.pastOrders,
          key: 'pastOrders',
          active: true,
        },
      ];
    },

    defaultTab() {
      return this.isCartCheckout && this.openOrders.rows.length ? 'tab-open-orders' : 'tab-past-orders';
    },

    openOrdersData() {
      return this.openOrders;
    },

    orderData() {
      return {
        openOrders: this.openOrders,
        pastOrders: this.pastOrders,
      };
    },
  },

  created() {
    this.setFiltersFromUrlParams();
    this.options.orderNumber = this.initialOrderNumber ? this.initialOrderNumber : this.options.orderNumber;
    this.fetchAllOrders();
  },

  methods: {
    handleCreditMemoUpdate(event) {
      this.$set(this.options, 'creditMemoFilter', event.currentTarget.value);
    },

    handleOrderUpdate(event) {
      this.$set(this.options, 'productTypeFilter', event.currentTarget.value);
    },

    handleOrderStatusUpdate(event) {
      this.$set(this.options, 'orderStatusFilter', event.currentTarget.value);
    },

    clearChanges() {
      this.options = structuredClone(this.initialOptions);
      this.$refs.orderPicker.fp.clear();
      this.$refs.invoicePicker.fp.clear();
      this.fetchAllOrders();
    },

    fetchAllOrders() {
      this.fetchOrders('openOrders');
      this.fetchOrders('pastOrders');
    },

    fetchOrders(status = 'openOrders') {
      this.loading = true;
      let param = {
        pageIndex: this[status].pageIndex,
        ...this.options,
      };
      param = pickBy(param); // Removing null properties
      Url.removeUrlParameterObjectFromUrl(this.options);
      Url.appendObjectToUrlParameters(this.options);

      storefrontInstance
        .get(`/my-account/${status.toString().toLowerCase()}`, {
          params: new URLSearchParams(param),
        })
        .then(
          ({
            data: {
              data: {orders, paging},
            },
          }) => {
            this[status] = {
              rows: orders.map((row) => ({
                ...row,
                expanded: row.expanded === undefined ? false : row.expanded,
                allocatedItems: row.allocatedItems || [],
                notAllocatedItems: row.notAllocatedItems || [],
              })),
              pageIndex: paging.pageIndex || 0,
              pageCount: paging.pageCount || 0,
              totalItemCount: paging.totalItemCount || 0,
            };

            this.isPartSearch = this.options.partNumber?.length > 0;
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },

    invoiceDateInput({startDate, endDate}) {
      if (startDate) {
        this.options.invoiceDateStart = dayjs(startDate).format(DateFormats.MM_DD_YYYY);
      } else {
        this.options.invoiceDateStart = null;
      }
      if (endDate) {
        this.options.invoiceDateEnd = dayjs(endDate).format(DateFormats.MM_DD_YYYY);
      } else {
        this.options.invoiceDateEnd = null;
      }
    },

    orderDateInput({startDate, endDate}) {
      if (startDate) {
        this.options.orderDateStart = dayjs(startDate).format(DateFormats.MM_DD_YYYY);
      } else {
        this.options.orderDateStart = null;
      }
      if (endDate) {
        this.options.orderDateEnd = dayjs(endDate).format(DateFormats.MM_DD_YYYY);
      } else {
        this.options.orderDateEnd = null;
      }
    },
    updateShipToValue() {
      this.options.shipToNumber = this.options.shipToNumber.replace(/[^\d]/g, '');
    },
    setFiltersFromUrlParams() {
      this.urlParams = Url.parse(window.location.search);
      for (const urlParameter in this.urlParams) {
        for (const filterOption in this.options) {
          if (urlParameter === filterOption && this.urlParams[urlParameter] !== null) {
            //url only uses strings, so we need to convert string booleans back to booleans
            if (this.urlParams[urlParameter] === 'true') {
              this.urlParams[urlParameter] = true;
            } else if (this.urlParams[urlParameter] === 'false') {
              this.urlParams[urlParameter] = false;
            }
            this.options[urlParameter] = this.urlParams[urlParameter];
          }
        }
      }
    },
    expandOrder({rowIndex, isExpanded, orderType}) {
      this[orderType].rows[rowIndex].expanded = isExpanded;
    },
  },
};
</script>
