<template>
  <div class="grid-container">
    <form :class="{'opacity-50': isWorking}" @submit.prevent="submit">
      <pdl-section-header class="my-3" :is-dividing="true" size="xl">
        <template slot="content">
          <pdl-heading :level="1">
            {{ $t('updatePwd.title') }}
          </pdl-heading>
        </template>
      </pdl-section-header>
      <pdl-callout v-if="message" :kind="messageType">
        <template #content>
          {{ message }}
        </template>
      </pdl-callout>
      <div class="max-w-sm mb-6">
        <form-group
          v-model="currentPassword"
          type="password"
          name="currentPassword"
          :label="$t('profile.currentPassword')"
          :error="getErrorMessage('currentPassword')"
          force-display-error
          show-error-messages
          required
        />

        <form-group
          ref="newPassword"
          v-model="newPassword"
          type="password"
          name="newPassword"
          :label="$t('profile.newPassword')"
          :error="getErrorMessage('newPassword')"
          :help-text="passwordHelpText"
          force-display-error
          show-error-messages
          required
          @focus="getErrorMessage('newPassword') ? '' : (passwordHelpText = $t('password.strength.minchartext'))"
          @blur="passwordHelpText = ''"
        />

        <form-group
          v-model="checkNewPassword"
          type="password"
          name="checkNewPassword"
          :label="$t('profile.checkNewPassword')"
          :error="getErrorMessage('checkNewPassword')"
          force-display-error
          show-error-messages
          required
        />
        <div class="buttons">
          <trek-button primary type="submit" qaid="update-password-button" :disabled="isWorking" @click="submit">
            <span>{{ $t('profile.submit') }}</span>
          </trek-button>
          <trek-button secondary qaid="cancel-update-password-button" @click="cancel">
            <span>{{ $t('text.account.profile.cancel') }}</span>
          </trek-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import {PdlCallout} from '@pedal/pdl-callout';
import FormGroup from '@/components/FormGroup';
import TrekButton from '@/components/TrekButton';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {UserAccountBase} from '@/components/containers/my-account/utils/user-account-base';
import {PdlToastType} from '@/constants/pdl-toast-type';

export default {
  name: 'UpdatePasswordContainer',
  components: {PdlCallout, FormGroup, TrekButton, PdlSectionHeader, PdlHeading},
  extends: UserAccountBase,
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      checkNewPassword: '',
      passwordHelpText: '',
      message: '',
      messageType: '',
    };
  },
  validations() {
    return {
      currentPassword: {
        required: this.trekValidators.required('profile.currentPassword'),
      },
      newPassword: {
        required: this.trekValidators.required('profile.newPassword'),
        password: this.trekValidators.password(),
      },
      checkNewPassword: {
        sameAsPassword: this.trekValidators.sameAs('profile.checkNewPassword', this.newPassword, 'profile.newPassword'),
      },
    };
  },
  methods: {
    /**
     * Handle email update.
     * @return {Promise<void>}
     */
    async processUpdate() {
      this.isWorking = true;
      this.errorFields = {};

      try {
        await this.api.updatePassword({
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          chkPassword: this.checkNewPassword,
        });
        this.messageType = PdlToastType.SUCCESS;
        this.message = this.$t('text.account.confirmation.password.updated');
        this.resetForm();
      } catch (error) {
        this.messageType = PdlToastType.ERROR;
        this.message = this.$t(error.cause?.data?.errors[0].message);
      } finally {
        this.isWorking = false;
      }
    },

    /**
     * Reset form data.
     */
    resetForm() {
      this.errorFields = {};
      this.currentPassword = '';
      this.newPassword = '';
      this.checkNewPassword = '';
      this.v$.$reset();
    },
  },
};
</script>
