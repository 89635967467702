<template>
  <tr class="table-row">
    <!-- Each Cell -->
    <td
      v-for="column in grid.columns"
      :key="`${row[rowId]}-${row[rowKey]}-${column.key}`"
      class="b2b-grid__cell lg:table-cell"
      :class="[column.key, column.cssClasses]"
    >
      <div class="b2b-grid__label">{{ column.label }}</div>
      <div class="b2b-grid__content">
        <a v-if="column.key === 'name'" :href="nameLinkUrl" class="underline hover:no-underline">
          {{ row['name'] }}
        </a>
        <div v-else-if="column.key === 'userStatus'">
          <span v-if="row['userStatus'] === true">{{ $t('text.active') }}</span>
          <span v-else class="text-red">{{ $t('text.inactive') }}</span>
        </div>
        <div v-else-if="column.key === 'rideClubRole' || column.key === 'role'">
          {{ translateRoles(row[column.key]) }}
        </div>
        <span v-else>{{ row[column.key] }}</span>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  inject: ['grid'],
  provide() {
    return {
      item: this,
    };
  },
  props: {
    row: {
      type: [Object, Array],
      default: () => null,
    },
    rowId: {
      type: String,
      default: 'uid',
    },
    rowKey: {
      type: String,
      default: 'uid',
    },
  },

  data() {
    return {};
  },

  computed: {
    nameLinkUrl() {
      return `./details?user=${this.row.uid}`;
    },
  },
  methods: {
    translateRoles(role) {
      if (!role) return '';
      return this.$t(`${role}`);
    },
  },
};
</script>
