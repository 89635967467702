<template>
  <div class="w-full">
    <toggle-switch
      :id="`${day.name}-active-toggle`"
      v-model="localDay.active"
      :name="`${day.name}-active-toggle`"
      class="text-black"
      :sync="true"
      :qaid="`${day.name}-store-hours.toggle`"
      :text-label="day.name"
    />

    <!-- If the toggle is on -->
    <pdl-collapse-transition>
      <div v-if="day.active">
        <!-- Hour Range Datepickers -->
        <transition-group name="flip-ranges" tag="div">
          <div v-for="(range, index) in day.ranges" :key="range.id" class="day__range flex items-center mb-1">
            <store-hour-range
              :index="index"
              :day="day"
              :range="range"
              @remove="removeRange(index)"
              @update="updateRange($event, index)"
            />
          </div>
        </transition-group>

        <!-- Add New Range -->
        <trek-button v-if="canAddRange" text small icon="add" class="mt-2" @click.prevent="addRange">
          <span v-html="addRangeText"></span>
        </trek-button>
      </div>
    </pdl-collapse-transition>
    <hr class="rule" />
  </div>
</template>

<script>
import has from 'lodash/has';
import ToggleSwitch from '@/components/ToggleSwitch';
import {PdlCollapseTransition} from '@pedal/pdl-collapse';
import StoreHourRange from '@/components/containers/my-company/StoreHourRange';
import TrekButton from '@/components/TrekButton';

export default {
  name: 'StoreDay',
  components: {ToggleSwitch, PdlCollapseTransition, StoreHourRange, TrekButton},
  props: {
    day: {
      type: Object,
      default: () => null,
    },
    addRangeText: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      localDay: {},
    };
  },

  computed: {
    canAddRange() {
      return this.day.ranges.length < 2;
    },
  },
  watch: {
    localDay(day) {
      this.$emit('update', day);
    },
  },
  mounted() {
    this.localDay = this.day;
  },
  methods: {
    toggleDay() {
      if (this.localDay.name && this.localDay.ranges && !has(this, 'localDay.ranges[0].dayName')) {
        this.localDay.ranges[0].dayName = this.localDay.name;
      }
    },

    addRange() {
      this.localDay.ranges.push({
        id: this.day.ranges.length,
        dayName: this.day.name,
        startTime: '8:00 AM',
        endTime: '5:00 PM',
      });
    },

    removeRange(index) {
      this.localDay.ranges.splice(index, 1);
    },

    updateRange(range, index) {
      this.localDay.ranges[index] = range;
    },
  },
};
</script>
