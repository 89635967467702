<template>
  <div v-if="data.value" class="flex items-center lg:justify-end">
    <pdl-icon v-if="data.warn" class="text-warning mr-25" size="18" name="warning" />
    {{ data.value }}
  </div>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {
    PdlIcon,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
