<template>
  <div class="content">
    <pdl-heading :level="1" size="xl" class="mb-2">{{ $t('checkout.user') }}</pdl-heading>
    <checkout-personal-information :phone-max-length="phoneMaxLength" />
    <delivery-address
      :postcode-format="postcodeFormat"
      :postcode-max-length="postcodeMaxLength"
      :is-postcode-lookup-enabled="isPostcodeLookupEnabled"
      :regions="regions"
      :is-address-autofill-enabled="isAddressAutofillEnabled"
      @checkbox-change="handleCheckboxChange"
    >
      <template v-if="isPostcodeLookupEnabled" #postcode-autofill>
        <slot name="postcode-autofill" />
      </template>
    </delivery-address>
    <div class="grid grid-flow-row gap-y-2 md:hidden">
      <pdl-button block primary type="submit" qaid="choose-payment-method-button">
        {{ $t('checkout.summary.select.payment.method') }}
      </pdl-button>
      <pdl-link
        button
        tertiary
        block
        icon="chevron_right"
        icon-right
        :href="continueShoppingUrl"
        qaid="checkout-continue-link"
      >
        {{ $t('text.continue.shopping') }}
      </pdl-link>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core';
import {mapState, mapMutations, mapActions} from 'vuex';
import CheckoutPersonalInformation from '@/components/checkout/forms/CheckoutPersonalInformation';
import DeliveryAddress from '@/components/checkout/forms/delivery/DeliveryAddress.vue';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
import {PdlHeading} from '@pedal/pdl-section-header';
import {DeliveryAddressApi, convertToJSON} from '@/api/checkout/delivery-address';
import {scrollToElement} from '@/utils/scroll-to';

const FORM_ID = 'trekAddressForm';

export default {
  components: {CheckoutPersonalInformation, DeliveryAddress, PdlButton, PdlLink, PdlHeading},
  inject: ['validationErrors', 'setValidationErrors', 'clearValidationErrors'],
  props: {
    formAddressId: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    postcodeFormat: {
      type: String,
      required: true,
    },
    postcodeMaxLength: {
      type: Number,
      required: true,
    },
    previousPage: {
      type: String,
      required: true,
    },
    isPhoneRequired: {
      type: Boolean,
      default: true,
    },
    isFormInvalid: {
      type: String,
      default: '',
    },
    isPostcodeLookupEnabled: {
      type: Boolean,
      default: false,
    },
    addressValidationConfig: {
      type: Object,
      default: () => null,
    },
    phoneMaxLength: {
      type: Number,
      default: () => null,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    regions: {
      type: Array,
      default: () => [],
    },
    isAddressAutofillEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      shouldSetDefaultAddress: false,
    };
  },
  computed: {
    ...mapState('checkout', ['continueShoppingUrl']),
    ...mapState('backend', ['encodedContextPath']),
  },
  mounted() {
    const form = document.getElementById(FORM_ID);
    form.addEventListener('submit', this.handleSubmit);
  },
  beforeDestroy() {
    document.getElementById(FORM_ID).removeEventListener('submit', this.handleSubmit);
  },
  methods: {
    ...mapMutations('checkout', ['setOutOfDealerDeliveryRangeToast']),
    ...mapActions('user', ['setSelectedLocationFromPostcode']),

    handleCheckboxChange(isChecked) {
      this.shouldSetDefaultAddress = isChecked;
    },
    scrollToError() {
      const firstErrorElement = document.querySelector('.has-error');
      if (firstErrorElement) {
        scrollToElement(firstErrorElement, 20);
      }
    },
    redirectToCartPage() {
      window.location = `${this.encodedContextPath}/cart`;
    },
    async handleSubmit(event) {
      event.preventDefault();

      if (this.validationErrors.length > 0) {
        this.clearValidationErrors();
      }

      const formData = new FormData(document.getElementById(FORM_ID));
      formData.append('titleCode', 'mr');
      formData.append('fireTag', true);
      this.shouldSetDefaultAddress && formData.append('defaultAddress', this.shouldSetDefaultAddress);
      this.countries.forEach((country) => {
        formData.append('countryIso', country.isocode);
      });

      let isValid = true;

      try {
        isValid = await this.v$.$validate();
        if (!isValid) {
          this.scrollToError();
          return;
        }
        const dataToSubmit = convertToJSON(formData);
        this.setSelectedLocationFromPostcode(dataToSubmit.postcode);
        await DeliveryAddressApi.addDeliveryAddress(dataToSubmit);
      } catch (error) {
        const {errorFields} = error.response?.data?.meta?.feedback ?? {};
        this.setValidationErrors(errorFields);

        if (
          error.response?.data?.meta?.feedback?.message?.basePropertyKey === 'checkout.multi.deliveryMethod.outOfRange'
        ) {
          this.setOutOfDealerDeliveryRangeToast(true);
          this.redirectToCartPage();
        }
        return;
      }
      this.setOutOfDealerDeliveryRangeToast(false);
      const REDIRECT_URL = 'next';
      window.location.href = REDIRECT_URL;
    },
  },
};
</script>
