<template>
  <div v-if="warehouses && warehouses.length">
    <div v-for="warehouse in warehouses" :key="warehouse.id" class="b2b-grid__warehouse no-wrap">
      {{ warehouse.id }},
      <span :data-level="warehouse.stockLevelLabel" class="inline-block">{{ warehouse.stockLevel || 'zero' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    warehouses: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
