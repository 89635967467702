<template>
  <tr class="b2b-grid__product-row">
    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid-qty">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.qty') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: item.subtotalsByWarehouse[item.warehouse].quantity}"></grid-text>
      </div>
    </td>

    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid-split">
      <pdl-icon v-if="isSplitWarehouse" name="call_split" size="18" />
    </td>

    <td v-if="isProjectOne" class="b2b-grid__cell lg:table-cell" qaid="sku-grid-sku">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.sku') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: item.product.code}" />
      </div>
    </td>

    <td v-else class="b2b-grid__cell lg:table-cell" qaid="sku-grid-sku">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.sku') }}</div>
      <div class="b2b-grid__content">
        <div class="flex items-center">
          <a
            :href="`${encodedContextPath}/my-account/orders?partNumber=${item.product.code}`"
            class="leading-none mr-1"
            qaid="sku-grid-orderHistory"
          >
            <pdl-icon name="history" :label="$t('orders')" />
          </a>
          <grid-text
            class="flex"
            :data="{
              value: item.product.code,
              tooltip: getTooltipText(),
              link: encodedContextPath + '/p/' + item.product.code,
            }"
          />
        </div>
      </div>
    </td>

    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid-item">
      <div class="b2b-grid__label">{{ $t('order.item') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: item.product.itemName}"></grid-text>
      </div>
    </td>

    <td v-if="!isProjectOne" class="b2b-grid__cell lg:table-cell" qaid="sku-grid-upc">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.upc') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: item.product.upc}"></grid-text>
      </div>
    </td>

    <td v-if="isCustomWaterBottle" class="b2b-grid__cell lg:table-cell h-auto lg:text-right" qaid="sku-grid-custom-id">
      <div class="b2b-grid__label">{{ $t('checkout.custom.ID') }}</div>
      <div class="b2b-grid__content b2b-grid__content--right">
        <pdl-link qaid="cart-grid-custom-id-link" standalone :href="spectrumCustomizerUrl">
          <grid-text :data="{value: item.product.productRecipeID}" />
        </pdl-link>
      </div>
    </td>

    <td v-else-if="isProjectOne" class="b2b-grid__cell lg:table-cell h-auto lg:text-right" qaid="sku-grid-custom-id">
      <div class="b2b-grid__label">{{ $t('checkout.custom.ID') }}</div>
      <div class="b2b-grid__content b2b-grid__content--right">
        <pdl-link qaid="cart-grid-custom-id-link" standalone :href="projectOneSummaryPageUrl">
          <grid-text :data="{value: item.product.p1ConfigurationId}" />
        </pdl-link>
      </div>
    </td>

    <template v-else>
      <td class="b2b-grid__cell lg:table-cell text-left lg:text-right" qaid="sku-grid-allocated">
        <div class="b2b-grid__label">{{ $t('text.allocated') }}</div>
        <div class="b2b-grid__content">
          <grid-text :data="{value: item.quantityAllocatedOrShipped == 0 ? null : item.quantityAllocatedOrShipped}" />
        </div>
      </td>

      <td v-if="!isProjectOne" class="b2b-grid__cell lg:table-cell text-left lg:text-right" qaid="sku-grid-backordered">
        <div class="b2b-grid__label">{{ $t('text.backordered') }}</div>
        <div class="b2b-grid__content b2b-grid__content--right">
          <pdl-link
            v-if="item.quantityBackordered"
            qaid="checkout-grid-backordered-link"
            standalone
            xsmall
            :href="backorderedAllocatedUrl"
          >
            <grid-text :data="{value: item.quantityBackordered == 0 ? null : item.quantityBackordered}" />
          </pdl-link>
        </div>
      </td>
    </template>

    <td
      v-if="!isConsumerFriendlyMode && !isProjectOne"
      class="b2b-grid__cell lg:table-cell text-left lg:text-right"
      qaid="sku-grid-order-price"
    >
      <div class="b2b-grid__label">{{ $t('order.price') }}</div>
      <div v-if="item.product" class="b2b-grid__content">
        <grid-price
          :data="{dealerCost: item.product.prices.retailerPrice.wasPrice.low.formatted, discount: discountPrice}"
        ></grid-price>
      </div>
    </td>

    <td v-if="!isConsumerFriendlyMode" class="b2b-grid__cell lg:table-cell text-right" qaid="sku-grid-subtotal">
      <div class="b2b-grid__label">{{ $t('text.order.subtotal') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: item.subtotalsByWarehouse[item.warehouse].prices.totalPrice.formattedValue}" />
      </div>
    </td>
  </tr>
</template>

<script>
import {mapState} from 'vuex';
import GridText from '@/components/grid/GridText';
import GridPrice from '@/components/grid/GridPrice';
import {PdlLink} from '@pedal/pdl-link';
import {ProductType} from '@/constants/product';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {GridText, GridPrice, PdlLink, PdlIcon},
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isCustomWaterBottle: {
      type: Boolean,
      default: false,
    },
    isProjectOne: {
      type: Boolean,
      default: false,
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapState('backend', ['encodedContextPath', 'isConsumerFriendlyMode']),

    discountPrice() {
      return this.item?.product?.prices?.retailerPrice?.wasPrice?.low?.value !==
        this.item?.product?.prices?.retailerPrice?.price?.low?.value
        ? this.item?.product?.prices?.retailerPrice?.price?.low?.formatted
        : 0;
    },

    backorderedAllocatedUrl() {
      const productType = this.item.groupingType === ProductType.Bike ? 'bike' : 'am';
      const redirectPath = `${this.encodedContextPath}/my-account/backorders-allocated-items?productType=${productType}&skuSearchTerm=${this.item.product.code}`;
      return redirectPath;
    },

    spectrumCustomizerUrl() {
      return this.item.product.code && this.item.product.productRecipeID
        ? `${this.encodedContextPath}/p/${this.item.product.code}?hasSpectrumOpen=true&recipeId=${this.item.product.productRecipeID}`
        : null;
    },

    projectOneSummaryPageUrl() {
      return this.item.product.p1ConfigurationId
        ? `${this.encodedContextPath}/project-one-builder/#/${this.item.product.p1ConfigurationId}/summary`
        : null;
    },

    isSplitWarehouse() {
      return Object.keys(this.item.subtotalsByWarehouse).length > 1;
    },
  },

  methods: {
    getTooltipText() {
      return this.item?.mfr ? {MFR: this.item.mfr} : null;
    },
  },
};
</script>
