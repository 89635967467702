<template>
  <pdl-icon size="48">
    <svg id="VISA_Logo" xml:space="preserve" x="0" y="0" version="1.1" viewBox="0 0 216 100.7">
      <linearGradient id="SVGID_1_" x1="-281.167" x2="-280.46" y1="382.554" y2="381.847" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#33992a" />
        <stop offset=".983" stop-color="#141e0f" />
      </linearGradient>
      <path
        fill="#1334cb"
        d="M82 1.2 53.7 68.7H35.3L21.4 14.9c-.8-3.3-1.6-4.5-4.1-5.9C13 6.6 6.1 4.5 0 3.2l.4-2h29.7c3.8 0 7.2 2.5 8 6.9l7.4 39L63.7 1.2H82zm72.3 45.5c.1-17.8-24.6-18.8-24.5-26.7.1-2.4 2.4-5 7.4-5.7 2.5-.3 9.4-.6 17.2 3L157.5 3c-4.2-1.5-9.6-3-16.3-3-17.3 0-29.4 9.2-29.5 22.3-.1 9.7 8.7 15.1 15.3 18.4 6.8 3.3 9.1 5.4 9.1 8.4 0 4.5-5.4 6.5-10.4 6.6-8.8.1-13.9-2.4-17.9-4.3l-3.2 14.8c4.1 1.9 11.6 3.5 19.4 3.6 18.3 0 30.3-9.1 30.3-23.1m45.6 22H216L201.9 1.2H187c-3.4 0-6.2 1.9-7.4 4.9l-26.2 62.5h18.3l3.6-10.1h22.4l2.2 10.2zm-19.5-23.9 9.2-25.3 5.3 25.3h-14.5zM107 1.2 92.5 68.7H75.1L89.5 1.2H107z"
      />
      <g fill="#1334cb">
        <path
          d="M117.5 100.4V82.1h11.4V84h-9.1v5.8h7.2v1.9h-7.2v6.6h9.1v1.9h-11.4zM134.7 81.4v19h-2.1v-19h2.1zM144.9 99c1.2 0 2.2-.1 3-.4.8-.3 1.4-.7 2.1-1.2l-.2 1.9c-.4.3-1 .7-1.9 1-.8.3-1.9.5-3.2.5-1.4 0-2.6-.3-3.6-.9-1-.6-1.8-1.4-2.4-2.5-.6-1.1-.9-2.3-.9-3.7 0-1.4.3-2.6.9-3.7.6-1.1 1.3-1.9 2.3-2.5 1-.6 2.1-.9 3.3-.9 1.4 0 2.6.3 3.5.8.9.5 1.5 1.2 1.9 2.1.4.9.6 2 .6 3.2v.6c0 .2 0 .4-.1.6H140c0 1.6.5 2.9 1.3 3.8.9.8 2.1 1.3 3.6 1.3zm-.5-10.9c-1.2 0-2.2.4-2.9 1.1-.7.7-1.2 1.8-1.3 3.1h8.2V92c0-.8-.1-1.5-.4-2.1-.2-.6-.6-1-1.2-1.4-.7-.2-1.4-.4-2.4-.4zM152.6 93.6c0-1.4.3-2.6.8-3.7.6-1.1 1.4-1.9 2.4-2.5 1-.6 2.2-.9 3.6-.9 1.2 0 2.2.1 2.9.4.7.3 1.2.6 1.6.9l.2 2.3c-.6-.6-1.2-1-2-1.3-.7-.3-1.6-.5-2.5-.5-.9 0-1.7.2-2.5.6-.7.4-1.3 1-1.7 1.8-.4.8-.6 1.8-.6 2.9 0 1.7.5 3 1.4 3.9.9.9 2.1 1.4 3.5 1.4.8 0 1.4-.1 1.9-.2s1-.3 1.4-.6c.4-.2.8-.5 1.2-.9h.1l-.2 1.9c-.4.4-1 .7-1.7 1-.8.3-1.7.5-2.9.5-1.4 0-2.6-.3-3.6-.9-1-.6-1.8-1.4-2.4-2.5-.6-1-.9-2.2-.9-3.6zM170.2 96.7c0 .9.2 1.4.6 1.7.4.3.8.4 1.4.4.5 0 1 0 1.4-.1.4-.1.7-.2 1-.3h.1l-.2 1.8c-.2.1-.6.2-1.1.3-.5.1-1 .1-1.5.1-1.2 0-2.1-.3-2.8-.9-.7-.6-1-1.5-1-2.8v-8.5h-2.5l.3-1.4 2.2-.3V84l2.1-.3v3.2h4.2l-.3 1.8h-3.9v8zM182.6 88.7c-1.1 0-1.9.3-2.5 1-.6.7-.9 1.7-.9 3v7.7h-2.1V86.8h2v2.8h.1c.3-1.2.8-2 1.6-2.5.7-.5 1.5-.7 2.4-.7h.7c.2 0 .4.1.5.1l-.3 2.3h-.1c-.1 0-.3-.1-.6-.2-.2.1-.5.1-.8.1zM192.5 86.5c1.3 0 2.5.3 3.5.9 1 .6 1.8 1.5 2.4 2.5.6 1.1.9 2.3.9 3.7 0 1.4-.3 2.6-.9 3.7-.6 1.1-1.4 1.9-2.4 2.5-1 .6-2.2.9-3.5.9-1.3 0-2.5-.3-3.5-.9-1-.6-1.8-1.4-2.4-2.5-.6-1.1-.9-2.3-.9-3.7 0-1.4.3-2.6.9-3.7.6-1.1 1.4-1.9 2.4-2.5 1-.6 2.2-.9 3.5-.9zm0 12.4c1.5 0 2.6-.5 3.4-1.4.8-1 1.1-2.3 1.1-3.9 0-1.7-.4-3-1.1-3.9-.8-1-1.9-1.4-3.4-1.4s-2.6.5-3.4 1.4c-.8 1-1.2 2.3-1.2 3.9s.4 2.9 1.2 3.9c.7 1 1.9 1.4 3.4 1.4zM209.4 86.5c1.5 0 2.6.4 3.5 1.1.8.8 1.2 1.9 1.2 3.5v9.2H212v-8.6c0-1.1-.2-2-.7-2.6-.5-.6-1.4-.9-2.7-.9-.8 0-1.4.2-2.1.5-.6.3-1.1.7-1.5 1.3-.4.6-.5 1.2-.5 2v8.3h-2.1V86.8h2v2.9h.1c.3-.8.6-1.4 1.2-1.9.5-.5 1.1-.8 1.8-1 .6-.2 1.2-.3 1.9-.3z"
        />
      </g>
    </svg>
  </pdl-icon>
</template>
<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {PdlIcon},
};
</script>
