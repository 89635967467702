<template>
  <tr>
    <template v-for="column in headers">
      <td
        v-if="column.key === 'icons'"
        :key="column.key"
        class="b2b-grid__cell lg:table-cell whitespace-nowrap"
        :class="column.cssClasses"
      >
        <div class="flex flex-nowrap">
          <pdl-icon v-if="item.isSplitWarehouse" name="call_split" size="18" />
          <grid-note
            v-show="item.lineComment || false"
            :row-id="item.id"
            :data="{
              value: 'insert_drive_file',
              content: item.lineComment,
              editable: false,
              cssClass: 'ml-1',
            }"
          />
        </div>
      </td>

      <grid-cell
        v-else-if="column.key === 'sku'"
        :key="column.key"
        :cell-key="column.key"
        :label="column.label"
        :qaid="`order-detail-item-${column.key}`"
        :value="itemData.product.code"
        :classes="[
          {
            'whitespace-nowrap': column.key === 'icons',
            'w-1': column.key !== 'itemName',
          },
          column.cssClasses,
        ]"
      />

      <grid-cell
        v-else-if="column.key === 'itemName'"
        :key="column.key"
        :cell-key="column.key"
        :label="column.label"
        :qaid="`order-detail-item-${column.key}`"
        :value="itemData.product.itemName"
        :classes="[
          {
            'whitespace-nowrap': column.key === 'icons',
            'w-1': column.key !== 'itemName',
          },
          column.cssClasses,
        ]"
      />

      <grid-cell
        v-else-if="column.key === 'upc'"
        :key="column.key"
        :cell-key="column.key"
        :label="column.label"
        :qaid="`order-detail-item-${column.key}`"
        :value="itemData.product.upc"
        :classes="[
          {
            'whitespace-nowrap': column.key === 'icons',
            'w-1': column.key !== 'itemName',
          },
          column.cssClasses,
        ]"
      />

      <grid-cell
        v-else-if="isConsumerFriendlyMode && (column.key != 'unitPrice' || column.key != 'lineSubtotal')"
        :key="column.key"
        :cell-key="column.key"
        :label="column.label"
        :qaid="`order-detail-item-${column.key}`"
        :value="itemData[column.key]"
        :classes="[
          {
            'whitespace-nowrap': column.key === 'icons',
            'w-1': column.key !== 'itemName',
          },
          column.cssClasses,
        ]"
      >
      </grid-cell>

      <grid-cell
        v-else-if="column.key === 'lineStatus'"
        :key="column.key"
        :cell-key="column.key"
        :label="column.label"
        :qaid="`order-detail-item-${column.key}`"
        :value="itemData[column.key]"
        class="whitespace-nowrap w-1"
        :class="column.cssClasses"
      >
        <pdl-label kind="default" class="align-self-top mr-0">
          {{ $t(`${statusNamesMap[item[column.key]]}`) }}
        </pdl-label>
      </grid-cell>
      <grid-cell
        v-else
        :key="column.key"
        :cell-key="column.key"
        :label="column.label"
        :qaid="`order-detail-item-${column.key}`"
        :value="itemData[column.key]"
        :classes="[
          {
            'whitespace-nowrap': column.key === 'icons',
            'w-1': column.key !== 'itemName',
          },
          column.cssClasses,
        ]"
      />
    </template>
  </tr>
</template>

<script>
import {mapState} from 'vuex';
import {PdlLabel} from '@pedal/pdl-label';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {
    PdlLabel,
    PdlIcon,
  },
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      statusNamesMap: {
        AWAITING_RETURN: 'text.account.order.status.awaitingReturn',
        CANCELLED: 'text.account.order.status.display.cancelled',
        INVOICED: 'text.account.order.status.invoiced',
        IN_WAREHOUSE: 'text.account.order.status.inWarehouse',
        PROCESSING: 'text.account.order.status.display.processing',
        SHIPPED: 'text.account.order.consignment.status.shipped',
        PLACED: 'text.account.order.status.placed',
      },
    };
  },

  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode']),
    itemData() {
      const data = this.item;
      data['unitPrice'] = data.basePrice?.formattedValue;
      data['lineSubtotal'] = data.totalPrice?.formattedValue;
      if (data.etaDate && this.$t(`${data.etaDate}`)) {
        data.etaDate = this.$t(`${data.etaDate}`);
      }
      return data;
    },
  },
};
</script>
