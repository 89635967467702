<template>
  <div>
    <pdl-file-upload
      aspect-ratio="4/3"
      button-id="MyCompanyUpdateLogo"
      qaid="my-company--user-details-logo"
      :in-preset-image-file-url="imageUrl"
      :upload-on-input="true"
      :upload-button="false"
      :file-too-large-msg="$t('myCompany.imageUpload.fileTooLarge')"
      :wrong-file-type-msg="$t('orderUpload.B2B.fileTypeError')"
      @file-uploaded="setImage($event)"
    >
      <template slot="help">
        {{ $t('myCompany.imageUpload.fileTooLarge') }}
      </template>
    </pdl-file-upload>
    <trek-button secondary qaid="click-to-upload-button" @click="postImage">
      {{ $t('bulkOrder.upload.cta') }}
    </trek-button>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import get from 'lodash/get';
import {Url} from '@/utils/url';
import TrekButton from '@/components/TrekButton';
import {PdlFileUpload} from '@pedal/pdl-file-upload';

export default {
  components: {PdlFileUpload, TrekButton},

  props: {
    storeId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      imageData: {},
    };
  },

  computed: {
    imageUrl() {
      const url = get(this.imageData, 'url', '');
      return url.length > 0 ? Url.appendUrl(url, new Date().getTime().toString()) : '';
    },
  },

  mounted() {
    this.fetchStoreData();
  },

  methods: {
    fetchStoreData() {
      return storefrontInstance.get(`/b2bunit/getStoreLogoUrl/${this.storeId}`).then(({data: {data}}) => {
        if (data) {
          this.imageData = data;
        }
      });
    },

    postImage() {
      storefrontInstance.post(`/b2bunit/uploadStoreLogo/${this.storeId}`, this.imageData).then(({data: {data}}) => {
        if (data) {
          this.imageData = data;
        }
      });
    },

    setImage({name, encodedFile}) {
      this.imageData.name = name;
      this.imageData.storeLogo = encodedFile;
    },
  },
};
</script>
