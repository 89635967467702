<template>
  <div>
    <pdl-section-header size="md" :is-dividing="true" class="mt-5 mb-4">
      <template slot="content">
        <pdl-heading :level="3" qaid="shipping-info-header">{{ $t('text.shippingInformation') }}</pdl-heading>
      </template>
    </pdl-section-header>
    <div class="grid-x grid-margin-x">
      <div v-if="isDeliverableProductType" class="cell medium-6 large-4">
        <div class="form-group max-w-xs" qaid="shipping-info-ship-date">
          <label for="shippingDatePicker" class="form-label">
            {{ $t('text.shippingDate') }}
          </label>
          <pdl-date-picker
            input-id="shippingDatePicker"
            :allow-input="!isDist"
            :allow-icon="!isDist"
            :no-calendar="isDist"
            :default-date="defaultPickerDate"
            :placeholder="$t('text.selectADate')"
            :min-date="minPickerDate"
            qaid="shipping-date-input"
            @change="onDateInput"
          />
        </div>
        <checkout-shipping-options v-if="isShippingOptionsShown" :shipments-info="shipmentsInfo" :type="type" />
      </div>
      <checkout-delivery-info
        v-if="shipmentsInfo.deliveryAddress"
        :shipments-info="shipmentsInfo"
        :type="type"
        :is-dist="isDist"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {DateFormats} from '@/constants/date-formats';
import {PdlDatePicker} from '@pedal/pdl-date-picker';
import {ProductType} from '@/constants/product';
import CheckoutShippingOptions from '@/components/checkout/v2/CheckoutShippingOptions';
import CheckoutDeliveryInfo from '@/components/checkout/v2/CheckoutDeliveryInfo';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {mapMutations} from 'vuex';

export default {
  components: {PdlDatePicker, CheckoutShippingOptions, CheckoutDeliveryInfo, PdlSectionHeader, PdlHeading},

  props: {
    type: {
      type: String,
      required: true,
    },
    isDist: {
      type: Boolean,
      default: false,
    },
    shipmentsInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      productType: ProductType,
    };
  },

  computed: {
    defaultPickerDate() {
      let defaultDate = this.shipmentsInfo.shipments[this.type]?.delivery?.date
        ? new Date(this.shipmentsInfo.shipments[this.type]?.delivery?.date)
        : new Date();

      return dayjs(defaultDate).format(DateFormats.DEFAULT);
    },

    minPickerDate() {
      return dayjs(new Date()).format(DateFormats.DEFAULT);
    },

    isDeliverableProductType() {
      return this.type === ProductType.Bike || this.type === ProductType.Aftermarket;
    },

    isShippingOptionsShown() {
      return this.type !== ProductType.ProjectOne && !this.isDist;
    },
  },

  methods: {
    ...mapMutations('shipments', ['updateShipmentDeliveryDate']),
    onDateInput({event}) {
      this.updateShipmentDeliveryDate({type: this.type, value: event});
    },
  },
};
</script>
