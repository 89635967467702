<template>
  <form-group
    v-model="postcode"
    v-mask
    :data-mask="postcodeMask"
    :maxlength="postcodeMaxLength"
    :name="fieldName"
    :label="$t('address.zipcode')"
    :error="fieldError"
    required
    show-error-messages
    force-display-error
    @input="clearErrors"
  />
</template>

<script>
import FormGroup from '@/components/FormGroup.vue';
import {mask} from '@/directives/masks';
import {mapState, mapMutations} from 'vuex';
import {getPostcodeFormatOptions} from '@/constants/postcode-fields-config.js';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin.js';

export default {
  components: {FormGroup},
  directives: {mask},
  mixins: [TrekValidationMixin],
  inject: ['validationErrors', 'clearValidationErrors'],
  props: {
    postcodeMaxLength: {
      type: Number,
      required: true,
    },
    postcodeFormat: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  validations() {
    return {
      postcode: {
        required: this.trekValidators.requiredWithCustomMessage('address.postcode.invalid'),
        maxLength: this.trekValidators.maxLength('address.postcode', this.postcodeMaxLength),
      },
    };
  },
  computed: {
    ...mapState('backend', ['locale']),
    ...mapState('checkout', ['billingAddress']),
    postcode: {
      get() {
        return this.billingAddress.postcode ? this.billingAddress.postcode : this.value;
      },
      set(postcode) {
        this.setPostcode(postcode);
      },
    },
    fieldError() {
      const theError =
        this.validationErrors.find((item) => item.field === this.fieldName)?.msg ??
        this.getErrorMessage(this.fieldName);

      return theError;
    },
    fieldName() {
      return 'postcode';
    },
    postcodeMask() {
      return getPostcodeFormatOptions(this.locale);
    },
  },
  methods: {
    ...mapMutations('checkout', ['setPostcode']),
    clearErrors() {
      if (!this.validationErrors.length) return;
      this.clearValidationErrors();
    },
  },
};
</script>
