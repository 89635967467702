<template>
  <pdl-media-object-list :class="[dynamicClasses]">
    <cart-item
      v-for="(entry, i) of cartEntries"
      :key="`cart-item-${entry.product.code}-${i}`"
      :is-e-comm-available="isECommAvailable"
      :entry="entry"
      :is-quantity-control-enabled="isQuantityControlEnabled"
      :has-mixed-promo-items="hasMixedPromoItems"
    />
  </pdl-media-object-list>
</template>

<script>
import CartItem from '@/components/cart/b2c/CartItem.vue';
import {PdlMediaObjectList} from '@pedal/pdl-media-object';

export default {
  components: {
    CartItem,
    PdlMediaObjectList,
  },
  props: {
    cartData: {
      type: Object,
      required: true,
    },
    cartEntries: {
      type: Array,
      required: true,
    },
    isECommAvailable: {
      type: Boolean,
      required: true,
    },
    isQuantityControlEnabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dynamicClasses() {
      return {
        'border-b': !this.cartEntries.length,
        'border-gray-10': !this.cartEntries.length,
      };
    },
    hasMixedPromoItems() {
      return (
        this.cartData?.entries?.length > 1 &&
        !!this.cartData?.appliedProductPromotions?.length &&
        !this.cartData?.entries?.every((entry) => entry.isLineLevelPromotionPresent)
      );
    },
  },
};
</script>
