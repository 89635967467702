<template>
  <pdl-card is-padded :class="classes" class="ride-club-card" :qaid="`ride-club-card-${id}`">
    <template #bodyContent>
      <pdl-heading level="2" size="md" class="mb-1">
        <pdl-icon v-if="iconName" :name="iconName" class="text-green -mt-25" />
        {{ title }}
      </pdl-heading>
      <p class="leading-normal">{{ body }}</p>
    </template>
    <template #footer>
      <div class="buttons buttons--right">
        <pdl-link v-if="href" button primary :href="href">
          {{ ctaText }}
        </pdl-link>
        <pdl-button v-else :disabled="!isEnabled" primary @click="$emit('click', id)">
          {{ ctaText }}
        </pdl-button>
      </div>
    </template>
  </pdl-card>
</template>

<script>
import {PdlCard} from '@pedal/pdl-card';
import {PdlButton} from '@pedal/pdl-button';
import {PdlIcon} from '@pedal/pdl-icon';
import {PdlLink} from '@pedal/pdl-link';
import {PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {PdlCard, PdlIcon, PdlLink, PdlHeading, PdlButton},
  props: {
    id: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    isEnabled: {
      type: Boolean,
      default: true,
    },
    ctaText: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.ride-club-card {
  transition: all var(--pdl-transition-duration-200) var(--pdl-transition-timing-function-ease-in-out);
  overflow: hidden;

  &:hover {
    @apply shadow-lg;

    transform: translate(0, -2px);
  }
}
</style>
