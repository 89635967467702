<script>
export default {
  data() {
    return {};
  },
  mounted() {
    $('.checkout-steps__list')
      .find($('.checkout-steps__link'))
      .each((i, el) => {
        let pathName = `/${el.href.replace(/^(?:\/\/|[^/]+)*\//, '')}/`;
        if (pathName === window.location.pathname) {
          $(el).closest('.checkout-steps__item').addClass('is-active');
        }
      });
  },
};
</script>
