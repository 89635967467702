<script>
import isEmpty from 'lodash/isEmpty';
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';
import TrekButton from '@/components/TrekButton';
import CartSummary from '@/components/cart/CartSummary';
import CartProductsContainer from '@/components/cart/CartProductsContainer';
import CartProductsList from '@/components/cart/CartProductsList';
import DropShipOrdersContainer from '@/components/containers/drop-ship/DropShipOrdersContainer';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {
    PdlLoading,
    TrekButton,
    CartSummary,
    CartProductsContainer,
    CartProductsList,
    PdlTabs,
    PdlTabPane,
    PdlCallout,
    DropShipOrdersContainer,
    PdlSectionHeader,
    PdlHeading,
  },

  props: {
    isDropShipCustomer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      selectedTabName: '',
    };
  },

  computed: {
    cartIsEmpty() {
      return (
        (isEmpty(this.cartSummary) &&
          isEmpty(this.parts) &&
          isEmpty(this.bikes) &&
          isEmpty(this.customWaterBottles) &&
          isEmpty(this.projectOneBikes)) ||
        ((!this.parts || !this.parts.length) &&
          (!this.bikes || !this.bikes.length) &&
          (!this.customWaterBottles || !this.customWaterBottles.length) &&
          (!this.projectOneBikes || !this.projectOneBikes.length))
      );
    },

    tabSelected: {
      get() {
        return this.selectedTabName || (!this.cartEmpty && isEmpty(this.parts) ? 'bikes' : 'parts');
      },
      set(name) {
        this.selectedTabName = name;
      },
    },

    ...mapState('cart', ['bikes', 'parts', 'customWaterBottles', 'projectOneBikes', 'cartSummary']),
    ...mapState('miniCart', ['containers']),
    ...mapState('backend', ['isConsumerFriendlyMode']),
    ...mapGetters('user', ['b2bUnitShippingDays']),
  },

  created() {
    this.setFetchCallback(this.onCartFetchResponse);
    this.fetchCart();
    if (this.isDropShipCustomer) {
      this.fetchMiniCart();
    }
  },

  methods: {
    navigateTo(url) {
      window.location.href = url;
    },

    changeGridTab(tab) {
      this.tabSelected = tab;
      $('html, body').animate(
        {
          scrollTop: $(this.$refs.checkoutGrids.$el).offset().top - 100,
        },
        500
      );
    },

    selectTab() {
      // Logic handles selecting and changing tabs on load and deletion of items
      switch (this.selectedTabName) {
        case 'bikes':
          if (this.bikes) return;
          else if (!this.bikes && this.parts) this.selectedTabName = 'parts';
          else if (!this.bikes & !this.parts && this.customWaterBottles) this.selectedTabName = 'customWaterBottles';
          else if (!this.bikes & !this.parts && !this.customWaterBottles && this.projectOneBikes)
            this.selectedTabName = 'projectOneBikes';
          else if (this.cartIsEmpty) this.selectedTabName = 'bikes';
          break;
        case 'parts':
          if (this.parts) return;
          else if (this.bikes && !this.parts) this.selectedTabName = 'bikes';
          else if (!this.bikes & !this.parts && this.customWaterBottles) this.selectedTabName = 'customWaterBottles';
          else if (!this.bikes & !this.parts && !this.customWaterBottles && this.projectOneBikes)
            this.selectedTabName = 'projectOneBikes';
          else if (this.cartIsEmpty) this.selectedTabName = 'bikes';
          break;
        case 'customWaterBottles':
          if (this.customWaterBottles) return;
          else if (this.parts && !this.customWaterBottles) this.selectedTabName = 'parts';
          else if (!this.parts && !this.customWaterBottles && this.bikes) this.selectedTabName = 'bikes';
          else if (!this.bikes & !this.parts && !this.customWaterBottles && this.projectOneBikes)
            this.selectedTabName = 'projectOneBikes';
          else if (this.cartIsEmpty) this.selectedTabName = 'bikes';
          break;
        case 'projectOneBikes':
          if (this.projectOneBikes) return;
          else if (this.parts && !this.projectOneBikes) this.selectedTabName = 'parts';
          else if (!this.parts && !this.projectOneBikes && this.bikes) this.selectedTabName = 'bikes';
          else if (!this.bikes & !this.parts && !this.projectOneBikes && this.customWaterBottles)
            this.selectedTabName = 'customWaterBottles';
          else if (this.cartIsEmpty) this.selectedTabName = 'bikes';
          break;
        default:
          if (this.bikes) this.selectedTabName = 'bikes';
          else if (this.parts) this.selectedTabName = 'parts';
          else if (this.customWaterBottles) this.selectedTabName = 'customWaterBottles';
          else if (this.projectOneBikes) this.selectedTabName = 'projectOneBikes';
          else if (this.cartIsEmpty) this.selectedTabName = 'bikes';
          break;
      }
    },

    onCartFetchResponse() {
      this.selectTab();
      this.loading = false;
    },

    ...mapActions('cart', ['fetchCart']),
    ...mapActions('miniCart', ['fetchMiniCart']),

    ...mapMutations('cart', ['setFetchCallback']),
  },
};
</script>
